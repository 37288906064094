/** This module contains constants and utilities that are used in manipulating the entities used in 
 *      indicators, incidents and runbooks.
 *  @module
 */

import React from "react";
import { Classes, Tooltip2 } from "@blueprintjs/popover2";
import { PopoverPosition } from "@blueprintjs/core";
import { IconNames } from "@tir-ui/react-components";
import { STRINGS } from "app-strings";
import { importFlags } from "utils/runbooks/ImportFlags";
import { Unit } from "reporting-infrastructure/types/Unit.class";
import { formatBooleanValue } from "./RunbookFormatter";

const flagMap = importFlags();

/** the list of keys in the entity and metadata attributes that are JSON strings. */
export const JSON_STRINGS: Array<string> = [
    "application", "location", "network_server", "geo", "composed_of", "metadata", 
    "requestBody", "requestHeaders", "requestQueryParameters"
];

/** this interface defines the entity object. */
export interface Entity {
    /** the uuid for the entity */
    id?: string;
    /** the name of the entity. */
    name?: string;
    /** the IP address for a device.  This should now be in the attributes. */
    ipAddress?: string;
    /** the kind of entity for example network_interface. */
    kind?: string;
    /** the source object which specifies the information about the data source. */
    source?: Source;
    /** an array with the entity attributes. */
    attributes?: Array<EntityAttribute>;
    /** the parent of this entity, if any.  For example, the device is a parent of an interface. */
    parent?: EntityParent;
}

/** this interface defines the source object. */
export interface Source {
    /** the id of the data source that the entity and/or its data came from. */
    id: string;
    /** the name of the data source. */
    name?: string;
    /** the hostname of the data source. */
    host?: string;
}

/** this interface defines an attribute within the Entity object. */
export interface EntityAttribute {
    /** the key for the attribute. */
    field: string;
    /** the value of the attribute. */
    value: string;
}

/** this interfaces defines the entity parent object which is itself an entity with some additional attributes. */
export interface EntityParent extends Entity {
    /** the ip address of the device that is a parent of an interface. */
    ipAddress?: string;
    /** an optional string with the type of device that is a parent for the interface. */
    type?: string;
}

/** returns the attributes as key value pairs.
 *  @param entity the entity with the attributes.
 *  @returns an object with a set of key/value pairs. */
export function getAttributesObject(entity: Entity): {[x: string]: any} {
    const attrs: {[x: string]: string} = {};
    if (entity.attributes) {
        for (const attr of entity.attributes) {
            let value = attr.value;
            try {
                // Some of these values are now objects and metadata returns them in 
                // stringified format.  For now only do this for the ones we need to 
                // use, don't try to parse everything.  We will need to discuss with 
                // Marcello how to handle this better.  Note that for the indicators
                // the application is currently a string, but for the metadata the 
                // application is an object.
                if (JSON_STRINGS.includes(attr.field)) {
                    value = JSON.parse(value);
                }
            } catch (error) {}
            attrs[attr.field] = value;
        }    
    }
    return attrs;
}

/** returns the attribute for the specified field (key).
 *  @param field the key whose value is requested.
 *  @param entity the entity with the attributes.
 *  @returns a string with the value. */
export function getEntityAttribute(field: string, entity: Entity): any {
    if (entity && entity.attributes) {
        for (const attr of entity.attributes) {
            if (attr.field === field) {
                let value = attr.value;
                try {
                    // Some of these values are now objects and metadata returns them in 
                    // stringified format.  For now only do this for the ones we need to 
                    // use, don't try to parse everything.  We will need to discuss with 
                    // Marcello how to handle this better.  Note that for the indicators
                    // the application is currently a string, but for the metadata the 
                    // application is an object.
                    if (JSON_STRINGS.includes(attr.field)) {
                        value = JSON.parse(value);
                    }
                } catch (error) {}
                return value;
            }
        }
    }
    return null;
}

/** returns the name of the entity.  If the name is available in the entity, it returns 
 *      it, if not it uses the attributes to create a name.
 *  @param entity the entity object returned by DAL.
 *  @returns a string or JSX with the name or a React component with the name and tooltip. */
export function getEntityName(entity: Entity): JSX.Element | string {
    return getEntityContent(entity);
}

/** returns the entity content.
 *  @param entity the entity object returned by DAL.
 *  @param contentType the content type which is one of "name", "details", "nameWithTooltip"
 *  @returns a string or JSX with the name or a React component with the name and tooltip. */
export function getEntityContent(
    entity: Entity, contentType: "name" | "details" | "nameWithTooltip" = "nameWithTooltip"
): JSX.Element | string{
    let attributes: any = getAttributesObject(entity);
    let tooltipContent: Array<JSX.Element> = [];
    let name = STRINGS.noData;
    if (entity?.name) {
        name = entity.name;
    } else if (entity?.kind && entity?.attributes) {
        switch (entity.kind) {
            case "network_interface":
                if (getEntityAttribute("ipaddr", entity) && getEntityAttribute("ifindex", entity)) {
                    name = getEntityAttribute("ipaddr", entity) + ":" + getEntityAttribute("ifindex", entity);
                }
                break;
            case "network_device":
                if (getEntityAttribute("ipaddr", entity)) {
                    name = getEntityAttribute("ipaddr", entity) || "";
                }
                break;
            case "application_server":
                const ipAddress = getEntityAttribute("ipaddr", entity);
                let app: any = getEntityAttribute("application", entity);
                if (typeof app === "object") {
                    app = app.name;
                }
                let location = getEntityAttribute("location", entity);
                if (!location && attributes?.metadata?.location?.name) {
                    location = attributes.metadata.location.name;
                }
                if (ipAddress) {
                    name = ipAddress;
                    if (app || location) {
                        name += " (" + (app? app : "") + (location? " @ " + location : "") + ")";
                    }
                }
                break;
            case "application_location":
                let appLocation = getEntityAttribute("location", entity);
                if (!appLocation && attributes?.metadata?.location?.name) {
                    appLocation = attributes.metadata.location.name;
                }
                if (typeof appLocation === "object") {
                    appLocation = appLocation.name;
                }
                let appName = getEntityAttribute("application", entity);
                if (typeof appName === "object") {
                    appName = appName.name;
                }
                name  = appName + (appLocation ? " (" + appLocation + ")" : "");
                break;
            case "webhook":
                //const url = getEntityAttribute("requestPath", entity);
                name = STRINGS.entity.webhookPreText /* + (url ? " for request path: " + url : "")*/;
                break;
        }
    }

    let entityName = name;
    if (entity.kind === "network_interface") {
        if (entity.parent?.ipAddress || entity.parent?.name) {
            entityName = <>
                <span>{name}</span>
                <div className="mt-2" style={{maxWidth: "300px", whiteSpace: "nowrap", overflowX: "clip", textOverflow: "ellipsis" }}>on {entity.parent?.name ? entity.parent?.name : entity.parent?.ipAddress}
                </div>
            </>;
        }
    }

    if (contentType === "name") {
        return name;
    }
    // Add tooltips
    if (attributes.ipaddr) {
        tooltipContent.push(<tr key="ip-addr"><td className="text-right pr-2">{STRINGS.tooltips.ip}</td><td>{attributes.ipaddr}</td></tr>);
    }
    if (attributes.ifindex) {
        tooltipContent.push(<tr key="if-index"><td className="text-right pr-2">{STRINGS.tooltips.ifIndex}</td><td>{attributes.ifindex}</td></tr>);
    }
    if (attributes?.metadata?.ifalias) {
        tooltipContent.push(<tr key="if-alias"><td className="text-right pr-2">{STRINGS.tooltips.ifAlias}</td><td>{attributes.metadata.ifalias}</td></tr>);
    }
    if (attributes?.metadata?.ifdescr) {
        tooltipContent.push(<tr key="if-descr"><td className="text-right pr-2">{STRINGS.tooltips.ifDescr}</td><td>{attributes.metadata.ifdescr}</td></tr>);
    }
    if (attributes?.metadata?.ifipaddrs) {
        tooltipContent.push(<tr key="if-ips"><td className="text-right pr-2">{STRINGS.tooltips.ifIps}</td><td>{attributes.metadata.ifipaddrs.join(", ")}</td></tr>);
    }
    if ((entity.kind === "application_server" || entity.kind === "application_location") && attributes.application) {
        tooltipContent.push(<tr key="name"><td className="text-right pr-2">{STRINGS.tooltips.application}</td><td>{attributes.application}</td></tr>);
    } else {
        tooltipContent.push(<tr key="name"><td className="text-right pr-2">{STRINGS.tooltips.name}</td><td>{name}</td></tr>);
    }
    if (attributes?.metadata?.location?.name) {
        tooltipContent.push(<tr key="location"><td className="text-right pr-2">{STRINGS.tooltips.location}</td><td>{attributes.metadata.location.name}</td></tr>);
    }
    if (attributes?.metadata?.type) {
        tooltipContent.push(<tr key="type"><td className="text-right pr-2">{STRINGS.tooltips.type}</td><td>{attributes.metadata.type}</td></tr>);
    }
    if (attributes?.metadata?.vendor) {
        tooltipContent.push(<tr key="vendor"><td className="text-right pr-2">{STRINGS.tooltips.vendor}</td><td>{attributes.metadata.vendor}</td></tr>);
    }
    if (attributes?.metadata?.model) {
        tooltipContent.push(<tr key="model"><td className="text-right pr-2">{STRINGS.tooltips.model}</td><td>{attributes.metadata.model}</td></tr>);
    }
    if (attributes?.metadata?.serial_number) {
        tooltipContent.push(<tr key="serial-number"><td className="text-right pr-2">{STRINGS.tooltips.serialNumber}</td><td>{attributes.metadata.serial_number}</td></tr>);
    }
    if (attributes?.metadata?.os_version) {
        tooltipContent.push(<tr key="os-version"><td className="text-right pr-2">{STRINGS.tooltips.osVersion}</td><td>{attributes.metadata.os_version}</td></tr>);
    }
    if (attributes?.metadata?.is_gateway !== undefined && attributes?.metadata?.is_gateway !== null && attributes.metadata.is_gateway) {
        tooltipContent.push(<tr key="is-gateway"><td className="text-right pr-2">{STRINGS.tooltips.isGateway}</td><td>{formatBooleanValue(attributes?.metadata?.is_gateway)}</td></tr>);
    }
    if (attributes?.metadata?.inbound_speed) {
        const unit = Unit.parseUnit("bps");
        let value: any = parseFloat(attributes.metadata.inbound_speed);
        const result = unit.getScaledUnit(value);
        value = value / result.scale + " " + result.unit.getDisplayName();
        tooltipContent.push(<tr key="inbound-speed"><td className="text-right pr-2">{STRINGS.tooltips.inboundSpeed}</td><td>{value}</td></tr>);
    }
    if (attributes?.metadata?.outbound_speed) {
        const unit = Unit.parseUnit("bps");
        let value: any = parseFloat(attributes.metadata.outbound_speed);
        const result = unit.getScaledUnit(value);
        value = value / result.scale + " " + result.unit.getDisplayName();
        tooltipContent.push(<tr key="outbound-speed"><td className="text-right pr-2">{STRINGS.tooltips.outboundSpeed}</td><td>{value}</td></tr>);
    }
    if (attributes?.metadata?.geo?.country_code) {
        const countryCode: string = attributes.metadata.geo.country_code;
        const flagResource = flagMap["./" + countryCode.toLocaleLowerCase() + ".png"].default;
        if (flagResource) {
            tooltipContent.push(<tr key="country"><td className="text-right pr-2">{STRINGS.tooltips.country}</td><td><img alt="" src={flagResource}/></td></tr>);
        }    
    }
    if (entity.kind === "network_interface") {
        // Check for parent device information
        if (entity.parent?.ipAddress) {
            tooltipContent.push(<tr key="device-ip"><td className="text-right pr-2">{STRINGS.tooltips.deviceIp}</td><td>{entity.parent?.ipAddress}</td></tr>);
        }
        if (entity.parent?.ipAddress) {
            tooltipContent.push(<tr key="device-name"><td className="text-right pr-2">{STRINGS.tooltips.deviceName}</td><td>{entity.parent?.name}</td></tr>);
        }
        if (entity.parent) {
            let parentAttributes: any = getAttributesObject(entity.parent);
            if (parentAttributes?.location?.name) {
                tooltipContent.push(<tr key="device-location"><td className="text-right pr-2">{STRINGS.tooltips.deviceLocation}</td><td>{parentAttributes.location.name}</td></tr>);
            }
            if (parentAttributes?.type) {
                tooltipContent.push(<tr key="device-type"><td className="text-right pr-2">{STRINGS.tooltips.deviceType}</td><td>{parentAttributes.type}</td></tr>)
            }
            if (parentAttributes?.vendor) {
                tooltipContent.push(<tr key="device-vendor"><td className="text-right pr-2">{STRINGS.tooltips.deviceVendor}</td><td>{parentAttributes.vendor}</td></tr>)
            }
            if (parentAttributes?.model) {
                tooltipContent.push(<tr key="device-model"><td className="text-right pr-2">{STRINGS.tooltips.deviceModel}</td><td>{parentAttributes.model}</td></tr>)
            }
            if (parentAttributes?.os_version) {
                tooltipContent.push(<tr key="device-os-version"><td className="text-right pr-2">{STRINGS.tooltips.deviceOsVersion}</td><td>{parentAttributes.os_version}</td></tr>)
            }
            if (parentAttributes?.serial_number) {
                tooltipContent.push(<tr key="device-serial-number"><td className="text-right pr-2">{STRINGS.tooltips.deviceSerialNumber}</td><td>{parentAttributes.serial_number}</td></tr>)
            }
            if (parentAttributes?.is_gateway !== undefined && parentAttributes?.is_gateway !== null && parentAttributes?.is_gateway?.toLowerCase() === "true") {
                tooltipContent.push(<tr key="device-is-gateway"><td className="text-right pr-2">{STRINGS.tooltips.isGateway}</td><td>{formatBooleanValue(parentAttributes.is_gateway)}</td></tr>)
            }        
        }
    }
    if (contentType === "details") {
        return <>{tooltipContent}</>;
    } else {
        return tooltipContent.length > 0 ? 
            <Tooltip2 className={Classes.TOOLTIP2_INDICATOR + " border-0 d-inline"} content={<div className="px-2 py-2"><table><tbody>{tooltipContent}</tbody></table></div>} position={PopoverPosition.RIGHT} transitionDuration={50}>
                {entityName}
            </Tooltip2> : entityName;
    }
}

/** returns the name of the entity using the attributes from the metadata query.
 *  @param entity the entity object returned by DAL.
 *  @returns a string with the name or a React component with the name and tooltip. */
export function getMetadataEntityName(entity: Entity): JSX.Element | string {
    let attributes: any = getAttributesObject(entity);
    let tooltipContent: Array<JSX.Element> = [];
    let name = "";
    switch (entity.kind) {
        case "network_interface":
            if (attributes.ipaddr && attributes.ifindex) {
                name = attributes.ipaddr + ":" + attributes.ifindex;
            }
            break;
        case "network_device":
            if (attributes.ipaddr) {
                name = attributes.ipaddr;
            }
            break;
        case "application":
            name = attributes.name || "";
            break;
        case "application_server":
            name = attributes?.application?.name || "";
            break;
        case "application_location":
            name = attributes?.application?.name || "";
            break;
        case "location":
            name = attributes.name || "";
            break;
        case "network_server":
            name = attributes.ipaddr || "";
            break;
            }

    // Add tooltips
    if (attributes.ipaddr) {
        tooltipContent.push(<tr key="ip-addr"><td className="text-right pr-2">{STRINGS.tooltips.ip}</td><td>{attributes.ipaddr}</td></tr>);
    }
    if (attributes.network_server) {
        tooltipContent.push(<tr key="ip-addr"><td className="text-right pr-2">{STRINGS.tooltips.ip}</td><td>{attributes.network_server.ipaddr}</td></tr>);
    }
    if (attributes.ifindex) {
        tooltipContent.push(<tr key="if-index"><td className="text-right pr-2">{STRINGS.tooltips.ifIndex}</td><td>{attributes.ifindex}</td></tr>);
    }
    if (attributes.ifalias) {
        tooltipContent.push(<tr key="if-alias"><td className="text-right pr-2">{STRINGS.tooltips.ifAlias}</td><td>{attributes.ifalias}</td></tr>);
    }
    if (attributes.ifdescr) {
        tooltipContent.push(<tr key="if-descr"><td className="text-right pr-2">{STRINGS.tooltips.ifDescr}</td><td>{attributes.ifdescr}</td></tr>);
    }
    if (attributes.ifipaddrs) {
        let items = [];
        try {
            items = JSON.parse(attributes.ifipaddrs);
        } catch (error) {}
        tooltipContent.push(<tr key="if-ips"><td className="text-right pr-2">{STRINGS.tooltips.ifIps}</td><td>{items.join(", ")}</td></tr>);
    }
    if (attributes.hostname) {
        tooltipContent.push(<tr key="hostname"><td className="text-right pr-2">{STRINGS.tooltips.name}</td><td>{attributes.hostname}</td></tr>);
    }
    if (entity.kind === "network_interface") {
        tooltipContent.push(<tr key="ifname"><td className="text-right pr-2">{STRINGS.tooltips.name}</td><td>{attributes.name}</td></tr>);
    }
    if (attributes.vendor) {
        tooltipContent.push(<tr key="vendor"><td className="text-right pr-2">{STRINGS.tooltips.vendor}</td><td>{attributes.vendor}</td></tr>);
    }
    if (attributes.model) {
        tooltipContent.push(<tr key="model"><td className="text-right pr-2">{STRINGS.tooltips.model}</td><td>{attributes.model}</td></tr>);
    }
    if (attributes.serial_number) {
        tooltipContent.push(<tr key="serial-number"><td className="text-right pr-2">{STRINGS.tooltips.serialNumber}</td><td>{attributes.serial_number}</td></tr>);
    }
    if (attributes.os_version) {
        tooltipContent.push(<tr key="os-version"><td className="text-right pr-2">{STRINGS.tooltips.osVersion}</td><td>{attributes.os_version}</td></tr>);
    }
    if (attributes.type) {
        tooltipContent.push(<tr key="os-type"><td className="text-right pr-2">{STRINGS.tooltips.type}</td><td>{attributes.type}</td></tr>);
    }
    if (attributes.is_gateway !== undefined && attributes.is_gateway !== null) {
        tooltipContent.push(<tr key="is-gateway"><td className="text-right pr-2">{STRINGS.tooltips.isGateway}</td><td>{formatBooleanValue(attributes.is_gateway)}</td></tr>);
    }
    if (attributes.inbound_speed) {
        const unit = Unit.parseUnit("bps");
        let value: any = parseFloat(attributes.inbound_speed);
        const result = unit.getScaledUnit(value);
        value = value / result.scale + " " + result.unit.getDisplayName();
        tooltipContent.push(<tr key="inbound-speed"><td className="text-right pr-2">{STRINGS.tooltips.inboundSpeed}</td><td>{value}</td></tr>);
    }
    if (attributes.outbound_speed) {
        const unit = Unit.parseUnit("bps");
        let value: any = parseFloat(attributes.outbound_speed);
        const result = unit.getScaledUnit(value);
        value = value / result.scale + " " + result.unit.getDisplayName();
        tooltipContent.push(<tr key="outbound-speed"><td className="text-right pr-2">{STRINGS.tooltips.outboundSpeed}</td><td>{value}</td></tr>);
    }
    if (attributes?.location?.name) {
        tooltipContent.push(<tr key="location"><td className="text-right pr-2">{STRINGS.tooltips.location}</td><td>{attributes.location.name}</td></tr>);
    }
    if (attributes?.geo?.country_code) {
        const countryCode: string = attributes.geo.country_code;
        const flagResource = flagMap["./" + countryCode.toLocaleLowerCase() + ".png"].default;
        if (flagResource) {
            tooltipContent.push(<tr key="country"><td className="text-right pr-2">{STRINGS.tooltips.country}</td><td><img alt="" src={flagResource}/></td></tr>);
        }    
    }

    return tooltipContent.length > 0 ? 
        <Tooltip2 className={Classes.TOOLTIP2_INDICATOR + " border-0"} content={<div className="px-2 py-2"><table><tbody>{tooltipContent}</tbody></table></div>} position={PopoverPosition.RIGHT} transitionDuration={50}>
            {name}
        </Tooltip2> : name;
}

/** returns the icon that should be displayed for the entity.
 *  @param the entity object returned by DAL.
 *  @returns a String with the icon name. */
export function getEntityIcon(entity: Entity): string {
    let icon = "";
    const entityKindIconMap = {
        network_interface: IconNames.MERGE_LINKS,
        network_device: IconNames.DEVICES,
        application: IconNames.APPLICATIONS,
        application_server: IconNames.APPLICATIONS,
        application_location: IconNames.APPLICATIONS,
        location: IconNames.GLOBE,
    }
    if (entity?.kind && entityKindIconMap[entity.kind]) {
        icon = entityKindIconMap[entity.kind];
    }
    return icon;
}
