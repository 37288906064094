import { Node, Edge, Elements, isNode, isEdge } from "react-flow-renderer";
import { STRINGS } from "app-strings";
import { LayoutOptions } from "elkjs/lib/elk.bundled.js";
import { getLayoutedGraph, overlapRemovalOption } from "./ELKLayout";

/** this interface defines the CloudIM GraphDef object. The CloudIM GraphDef object defines the data in 
 *  a graph.  It consists of nodes and edges. */
export interface CloudIMGraphDef {
    /** the array of nodes. */
    nodes: Node[];
    /** the array of edges. */
    edges: Edge[];
}

/** outputs the react-flow elements based on the CloudIM GraphDef object.
 *  @param graphDef the CloudIM GraphDef object with the graph data.
 *  @returns the Elements with the graph nodes and edges.*/
export function getElements(graphDef: CloudIMGraphDef): Elements {
    const elements: Elements = [];

    for (const node of graphDef.nodes) {
        let nodeEl: Node<any> = node;
        elements.push(nodeEl);
    }

    for (const edge of graphDef.edges) {
        let edgeEl: Edge<any> = edge;
        elements.push(edgeEl);
    }

    return elements;
}

/** outputs the CloudIM GraphDef object based on the react-flow elements.
 *  @param the Elements with the graph nodes and edges.
 *  @returns graphDef the CloudIM GraphDef object with the graph data.*/
export function getGraphDef(elements: Elements): CloudIMGraphDef {
    let nodes: Node[] = [];
    let edges: Edge[] = [];
    if (elements) {
        for (const element of elements) {
            if (isNode(element)) {
                nodes.push(element as Node);
            } else if (isEdge(element)) {
                edges.push(element as Edge);
            }
        }
    }

    return {
        nodes: nodes,
        edges: edges
    };
}

/** this enum specifies the supported AWS types. */
export enum AWSSupportedTypes {
    ACCOUNT = "account",
    OWNER = "owner",
    VPC = "vpc",
    VPCPEERINGCONNECTION = "vpcPeeringConnection",
    SUBNET = "subnet",
    COMPUTEINSTANCE = "compute_instance",
    BLOBSTORAGE = "blob_storage",
    LAMBDA = "lambda"
}

/** a constant which maps the aws type enum value to a label. */
export const AWS_TYPES_TO_LABEL_MAP: Record<string, string> = {
    [AWSSupportedTypes.ACCOUNT]: STRINGS.cloudim.topology.aws.account,
    [AWSSupportedTypes.OWNER]: STRINGS.cloudim.topology.aws.owner,
    [AWSSupportedTypes.VPC]: STRINGS.cloudim.topology.aws.vpc,
    [AWSSupportedTypes.VPCPEERINGCONNECTION]: STRINGS.cloudim.topology.aws.vpcPeeringConnection,
    [AWSSupportedTypes.SUBNET]: STRINGS.cloudim.topology.aws.subnet,
    [AWSSupportedTypes.COMPUTEINSTANCE]: STRINGS.cloudim.topology.aws.computeInstance,
    [AWSSupportedTypes.BLOBSTORAGE]: STRINGS.cloudim.topology.aws.blobStorage,
    [AWSSupportedTypes.LAMBDA]: STRINGS.cloudim.topology.aws.lambda,
}

/** this enum specifies the operation performed on ResourceOperation function. */
enum OperationTypes {
    CHECK = "Check",
    ADD = "Add",
    CHECKANDADD = "CheckAndAdd",
}

/** outputs true if an AWS resource id already exist or if it is undefined (to prevent adding to the dataset)
 *  @param kind: what we are checking. attributes: the data. IdSet: the set we are checking.
 *  @returns boolean: based on if the resource id exist or if it is undefined.*/
function AWSIdAddable(kind: AWSSupportedTypes, attributes: any, IdSet?: Set<string> | undefined): boolean {
    let addable: boolean = false;

    if (IdSet === undefined) {
        return false;
    }

    // Ids that are named differently
    const ownerIds = [attributes.OwnerId, attributes.ownerId];
    const ownerId = ownerIds.find(id => id !== undefined);
    const vpcIds = [attributes.VpcId, attributes.vpcId];
    const vpcId = vpcIds.find(id => id !== undefined);

    switch (kind) {
        case AWSSupportedTypes.ACCOUNT:
            addable = ownerId !== undefined && !IdSet.has(ownerId);
            break;
        case AWSSupportedTypes.OWNER:
            addable = ownerId !== undefined && !IdSet.has(ownerId);
            break;
        case AWSSupportedTypes.VPC:
            addable = vpcId !== undefined && !IdSet.has(vpcId);
            break;
        case AWSSupportedTypes.VPCPEERINGCONNECTION:
            addable = attributes.VpcPeeringConnectionId !== undefined && !IdSet.has(attributes.VpcPeeringConnectionId);
            break;
        case AWSSupportedTypes.SUBNET:
            addable = attributes.SubnetId !== undefined && !IdSet.has(attributes.SubnetId);
            break;
        case AWSSupportedTypes.COMPUTEINSTANCE:
            addable = attributes.InstanceId !== undefined && !IdSet.has(attributes.InstanceId);
            break;
        case AWSSupportedTypes.BLOBSTORAGE:
            addable = attributes.S3BucketId !== undefined && !IdSet.has(attributes.S3BucketId);
            break;
        case AWSSupportedTypes.LAMBDA:
            addable = attributes.Arn !== undefined && !IdSet.has(attributes.Arn);
            break;
        default:
            break;
    }
    return addable;
}

/** outputs true if we successfully added the AWS resource into the dataset. This works by reference.
 *  @param kind: what we are checking. attributes: the data. nodes: our dataset. edges: our dataset. IdSet: the set we are checking.
 *  @returns boolean: based on if we successfully added the data.*/
function AWSAddResource(kind: AWSSupportedTypes, attributes: any, nodes: Array<any>, edges: Array<any>, IdSet?: Set<string> | undefined): boolean {
    let success: boolean = false;

    // Ids that are named differently
    const ownerIds = [attributes.OwnerId, attributes.ownerId];
    const ownerId = ownerIds.find(id => id !== undefined);
    const vpcIds = [attributes.VpcId, attributes.vpcId];
    const vpcId = vpcIds.find(id => id !== undefined);

    switch (kind) {
        case AWSSupportedTypes.ACCOUNT:
            // Check if id is null
            if (ownerId === null) return false;
            nodes.push({
                "id": ownerId,
                "type": "awsNode",
                "width": 40,
                "height": 40,
                "data": {
                    "label": ownerId,
                    "type": kind,
                }
            });

            if (IdSet) {
                IdSet.add(ownerId);
            }

            success = true;
            break;
        case AWSSupportedTypes.OWNER:
            // Check if id is null
            if (ownerId === null) return false;
            nodes.push({
                "id": ownerId,
                "type": "awsNode",
                "width": 40,
                "height": 40,
                "data": {
                    "label": attributes.OwnerName ? attributes.OwnerName : ownerId,
                    "type": kind,
                }
            });

            if (IdSet) {
                IdSet.add(ownerId);
            }

            success = true;
            break;
        case AWSSupportedTypes.VPC:
            // Check if id is null
            if (vpcId === null) return false;
            // For vpcPeeringConnections, connecting VPC may be in another region.
            const region = attributes.region ? " (" + attributes.region + ")" : "";

            nodes.push({
                "id": vpcId,
                "type": "awsNode",
                "width": 40,
                "height": 40,
                "data": {
                    "label": vpcId + region,
                    "type": kind
                }
            });

            // Check if id is null
            if (vpcId === null || ownerId === null) return false;
            edges.push({
                "id": ownerId + "-" + vpcId,
                "source": ownerId,
                "target": vpcId
            });

            if (IdSet) {
                IdSet.add(vpcId);
            }

            success = true;
            break;
        case AWSSupportedTypes.VPCPEERINGCONNECTION:
            // Check if id is null
            if (attributes.VpcPeeringConnectionId === null) return false;
            nodes.push({
                "id": attributes.VpcPeeringConnectionId,
                "type": "awsNode",
                "width": 40,
                "height": 40,
                "data": {
                    "label": attributes.VpcPeeringConnectionId,
                    "type": kind
                }
            });

            // Check if id is null
            if (attributes.VpcPeeringConnectionId === null || attributes.RequesterVpcInfo.vpcId === null) return false;
            edges.push({
                "id": attributes.RequesterVpcInfo.vpcId + "-" + attributes.VpcPeeringConnectionId,
                "source": attributes.RequesterVpcInfo.vpcId,
                "target": attributes.VpcPeeringConnectionId
            });

            // Check if id is null
            if (attributes.VpcPeeringConnectionId === null || attributes.AccepterVpcInfo.vpcId === null) return false;
            edges.push({
                "id": attributes.VpcPeeringConnectionId + "-" + attributes.AccepterVpcInfo.vpcId,
                "source": attributes.VpcPeeringConnectionId,
                "target": attributes.AccepterVpcInfo.vpcId
            });

            if (IdSet) {
                IdSet.add(attributes.VpcPeeringConnectionId);
            }

            success = true;
            break;
        case AWSSupportedTypes.SUBNET:
            // Check if id is null
            if (attributes.SubnetId === null) return false;
            nodes.push({
                "id": attributes.SubnetId,
                "type": "awsNode",
                "width": 40,
                "height": 40,
                "data": {
                    "label": attributes.SubnetId,
                    // TODO: Not sure if it is a public or private subnet, so currently using custom subnet without background
                    "type": "subnet",
                }
            });

            // Check if id is null
            if (attributes.VpcId === null || attributes.SubnetId === null) return false;
            edges.push({
                "id": attributes.VpcId + "-" + attributes.SubnetId,
                "source": attributes.VpcId,
                "target": attributes.SubnetId
            });

            if (IdSet) {
                IdSet.add(attributes.SubnetId);
            }

            success = true;
            break;
        case AWSSupportedTypes.COMPUTEINSTANCE:
            // Check if id is null
            if (attributes.InstanceId === null) return false;
            nodes.push({
                "id": attributes.InstanceId,
                "type": "awsNode",
                "width": 40,
                "height": 40,
                "data": {
                    "label": attributes.InstanceId,
                    "type": kind,
                }
            });

            // Check if id is null
            if (attributes.InstanceId === null || attributes.SubnetId === null) return false;
            edges.push({
                "id": attributes.SubnetId + "-" + attributes.InstanceId,
                "source": attributes.SubnetId,
                "target": attributes.InstanceId
            });

            if (IdSet) {
                IdSet.add(attributes.InstanceId);
            }

            success = true;
            break;
        case AWSSupportedTypes.BLOBSTORAGE:
            // Check if id is null
            if (attributes.S3BucketId === null) return false;
            nodes.push({
                "id": attributes.S3BucketId,
                "type": "awsNode",
                "width": 40,
                "height": 40,
                "data": {
                    "label": attributes.Name,
                    "type": kind,
                }
            });

            // Check if id is null
            if (attributes.S3BucketId === null || ownerId === null) return false;
            edges.push({
                "id": ownerId + "-" + attributes.S3BucketId,
                "source": ownerId,
                "target": attributes.S3BucketId
            });

            if (IdSet) {
                IdSet.add(attributes.S3BucketId);
            }

            success = true;
            break;
        case AWSSupportedTypes.LAMBDA:
            // Check if id is null
            if (attributes.Arn === null) return false;
            nodes.push({
                "id": attributes.Arn,
                "type": "awsNode",
                "width": 40,
                "height": 40,
                "data": {
                    "label": attributes.Name,
                    "type": kind,
                }
            });

            // Check if id is null
            if (attributes.Arn === null || ownerId === null) return false;
            edges.push({
                "id": ownerId + "-" + attributes.Arn,
                "source": ownerId,
                "target": attributes.Arn
            });

            if (IdSet) {
                IdSet.add(attributes.Arn);
            }

            success = true;
            break;
        default:
            break;
    }
    return success;
}

/** outputs true if the data exist in the dataset.
 *  @param operation: what we want to do. kind: what we are checking. attributes: the data. nodes: our dataset. edges: our dataset. IdSet: the set we are checking.
 *  @returns boolean: if the data currently exist in the dataset */
function AWSResourceOperation(operation: OperationTypes, kind: AWSSupportedTypes, attributes: any, nodes: Array<any>, edges: Array<any>, IdSet?: Set<string>): boolean {
    let result: boolean = false;
    switch (operation) {
        case OperationTypes.CHECK:
            result = AWSIdAddable(kind, attributes, IdSet);
            break;
        case OperationTypes.ADD:
            result = AWSAddResource(kind, attributes, nodes, edges, IdSet);
            break;
        case OperationTypes.CHECKANDADD:
            if (AWSIdAddable(kind, attributes, IdSet)) {
                result = AWSAddResource(kind, attributes, nodes, edges, IdSet);
            }
            break;
        default:
            break;
    }
    return result;
}

/** outputs the CloudIM GraphDef object based on the cloudim aws data, it also performs a layout to the data.
 *  @param AWS data, we will transform this data to fit our schema.
 *  @returns graphDef the CloudIM GraphDef object with the graph data.*/
export async function AWSLayoutGraph(data: Array<any>, options?: LayoutOptions, overlapRemoval?: boolean): Promise<CloudIMGraphDef> {
    let layoutNodes: Node[] = [];
    let layoutEdges: Edge[] = [];

    if (data) {
        let nodes: Array<any> = [];
        let edges: Array<any> = [];

        // Storing OwnerIds
        let accountIdSet = new Set<string>();

        // VPC and subnet may be missing from data, use this to prevent errors
        let vpcIdSet = new Set<string>();
        let subnetIdSet = new Set<string>();

        for (const resource of data) {
            const kind = resource?.entityKind;
            const attributes = resource?.entityAttributes.rootElement;

            // Handle each supported type
            switch (kind) {
                case AWSSupportedTypes.VPC:
                    // Save AWS account if we haven't already
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.ACCOUNT, attributes, nodes, edges, accountIdSet);
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.VPC, attributes, nodes, edges, vpcIdSet);
                    break;
                case AWSSupportedTypes.VPCPEERINGCONNECTION:
                    // Save AWS account if we haven't already
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.ACCOUNT, attributes, nodes, edges, accountIdSet);
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.ACCOUNT, attributes.RequesterVpcInfo, nodes, edges, accountIdSet);
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.VPC, attributes.RequesterVpcInfo, nodes, edges, vpcIdSet);
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.ACCOUNT, attributes.AccepterVpcInfo, nodes, edges, accountIdSet);
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.VPC, attributes.AccepterVpcInfo, nodes, edges, vpcIdSet);
                    AWSResourceOperation(OperationTypes.ADD, AWSSupportedTypes.VPCPEERINGCONNECTION, attributes, nodes, edges);
                    break;
                case AWSSupportedTypes.SUBNET:
                    // Save AWS account if we haven't already
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.ACCOUNT, attributes, nodes, edges, accountIdSet);
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.VPC, attributes, nodes, edges, vpcIdSet);
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.SUBNET, attributes, nodes, edges, subnetIdSet);
                    break;
                case AWSSupportedTypes.COMPUTEINSTANCE:
                    // Save AWS account if we haven't already
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.ACCOUNT, attributes, nodes, edges, accountIdSet);
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.VPC, attributes, nodes, edges, vpcIdSet);
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.SUBNET, attributes, nodes, edges, subnetIdSet);
                    AWSResourceOperation(OperationTypes.ADD, AWSSupportedTypes.COMPUTEINSTANCE, attributes, nodes, edges);
                    break;
                case AWSSupportedTypes.BLOBSTORAGE:
                    /** In AWS, the concept of the "owner" of an S3 bucket is different from the "account ID" used in other services like EC2. 
                     * When you create a S3 bucket, the bucket is associated with an owner. 
                     * The owner ID is a canonical user ID, which is a unique identifier for the AWS account that owns the bucket. 
                     * This canonical user ID is specific to the S3 service.
                     */
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.OWNER, attributes, nodes, edges, accountIdSet);
                    AWSResourceOperation(OperationTypes.ADD, AWSSupportedTypes.BLOBSTORAGE, attributes, nodes, edges);
                    break;
                case AWSSupportedTypes.LAMBDA:
                    // Save AWS account if we haven't already
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.ACCOUNT, attributes, nodes, edges, accountIdSet);
                    AWSResourceOperation(OperationTypes.ADD, AWSSupportedTypes.LAMBDA, attributes, nodes, edges);
                    break;
                default:
                    break;
            }
        }

        let layoutedGraph = await getLayoutedGraph(nodes, edges, options);
        if (overlapRemoval) {
            layoutedGraph = await getLayoutedGraph(layoutedGraph.nodes, layoutedGraph.edges, overlapRemovalOption);
        }

        layoutNodes = layoutedGraph.nodes;
        layoutEdges = layoutedGraph.edges;
    }

    return {
        nodes: layoutNodes,
        edges: layoutEdges
    }
}

/** this enum specifies the supported NetIM entity. */
enum NetIMSupportedEntity {
    NETWORKDEVICE = "network_device",
}

/** this enum specifies the supported NetIM types. */
export enum NetIMSupportedTypes {
    SWITCH = "Switch",
    ROUTER = "Router",
    FIREWALL = "Firewall",
    LOADBALANCER = "Load Balancer",
    HOST = "Host",
    WANACCELERATOR = "WAN Accelerator",
    MULTILAYERSWITCH = "Multi-Layer Switch",
    PRINTER = "Printer",
    UNIFIEDCOMMUNICATION = "Unified Communication",
    WIRELESS = "Wireless",
    SDWAN = "SD-WAN",
    OTHER = "Other"
}

/** a constant which maps the netim type enum value to a label. */
export const NETIM_TYPES_TO_LABEL_MAP: Record<string, string> = {
    [NetIMSupportedTypes.SWITCH]: STRINGS.cloudim.topology.netim.switch,
    [NetIMSupportedTypes.ROUTER]: STRINGS.cloudim.topology.netim.router,
    [NetIMSupportedTypes.FIREWALL]: STRINGS.cloudim.topology.netim.firewall,
    [NetIMSupportedTypes.LOADBALANCER]: STRINGS.cloudim.topology.netim.loadBalancer,
    [NetIMSupportedTypes.HOST]: STRINGS.cloudim.topology.netim.host,
    [NetIMSupportedTypes.WANACCELERATOR]: STRINGS.cloudim.topology.netim.wanAccelerator,
    [NetIMSupportedTypes.MULTILAYERSWITCH]: STRINGS.cloudim.topology.netim.multilayeredSwitch,
    [NetIMSupportedTypes.PRINTER]: STRINGS.cloudim.topology.netim.printer,
    [NetIMSupportedTypes.UNIFIEDCOMMUNICATION]: STRINGS.cloudim.topology.netim.unifiedCommunication,
    [NetIMSupportedTypes.WIRELESS]: STRINGS.cloudim.topology.netim.wireless,
    [NetIMSupportedTypes.SDWAN]: STRINGS.cloudim.topology.netim.sdwan,
    [NetIMSupportedTypes.OTHER]: STRINGS.cloudim.topology.netim.other,
}

/** outputs true if we successfully added the NetIM resource into the dataset. This works by reference.
 *  @param type: what we are checking. attributes: the data. nodes: our dataset. edges: our dataset. IdSet: the set we are checking.
 *  @returns boolean: based on if we successfully added the data.*/
function NetIMAddResource(type: NetIMSupportedEntity, attributes: any, nodes: Array<any>, edges: Array<any>, IdSet?: Set<string> | undefined): boolean {
    let success: boolean = false;

    // Check NetIM Supported Entity
    switch (type) {
        case NetIMSupportedEntity.NETWORKDEVICE:
            const id = attributes.uuid;
            const name = attributes.name;
            const deviceType = attributes.type;

            // Check NetIM Support Types
            switch (deviceType) {
                case NetIMSupportedTypes.SWITCH:
                case NetIMSupportedTypes.ROUTER:
                case NetIMSupportedTypes.FIREWALL:
                case NetIMSupportedTypes.LOADBALANCER:
                case NetIMSupportedTypes.HOST:
                case NetIMSupportedTypes.WANACCELERATOR:
                case NetIMSupportedTypes.MULTILAYERSWITCH:
                case NetIMSupportedTypes.PRINTER:
                case NetIMSupportedTypes.UNIFIEDCOMMUNICATION:
                case NetIMSupportedTypes.WIRELESS:
                case NetIMSupportedTypes.SDWAN:
                    // Check if id is null
                    if (id === null) return false;
                    nodes.push({
                        "id": id,
                        "type": "netimNode",
                        "width": 40,
                        "height": 40,
                        "data": {
                            "label": name,
                            "type": deviceType
                        }
                    });

                    if (IdSet) {
                        IdSet.add(id);
                    }

                    success = true;
                    break;
                default:
                    // Check if id is null
                    if (id === null) return false;
                    nodes.push({
                        "id": id,
                        "type": "netimNode",
                        "width": 40,
                        "height": 40,
                        "data": {
                            "label": name,
                            "type": NetIMSupportedTypes.OTHER
                        }
                    });

                    if (IdSet) {
                        IdSet.add(id);
                    }

                    success = true;
                    break;
            }
            break;
        default:
            break;
    }
    return success;
}

/** outputs true if the data exist in the dataset.
 *  @param operation: what we want to do. kind: what we are checking. attributes: the data. nodes: our dataset. edges: our dataset. IdSet: the set we are checking.
 *  @returns boolean: if the data currently exist in the dataset */
function NetIMResourceOperation(operation: OperationTypes, type: NetIMSupportedEntity, attributes: any, nodes: Array<any>, edges: Array<any>, IdSet?: Set<string>): boolean {
    let result: boolean = false;
    switch (operation) {
        case OperationTypes.ADD:
            result = NetIMAddResource(type, attributes, nodes, edges, IdSet);
            break;
        default:
            break;
    }
    return result;
}

/** outputs the CloudIM GraphDef object based on the cloudim NetIM data, it also performs a layout to the data.
 *  @param NetIM data, we will transform this data to fit our schema.
 *  @returns graphDef the CloudIM GraphDef object with the graph data.*/
export async function NetIMLayoutGraph(data: Array<any>, options?: LayoutOptions, overlapRemoval?: boolean): Promise<CloudIMGraphDef> {
    let layoutNodes: Node[] = [];
    let layoutEdges: Edge[] = [];

    if (data) {
        let nodes: Array<any> = [];
        let edges: Array<any> = [];

        for (const resource of data) {
            const kind = resource?.entityKind;
            const attributes = resource?.entityAttributes.rootElement;

            // Handle each supported type
            switch (kind) {
                case NetIMSupportedEntity.NETWORKDEVICE:
                    NetIMResourceOperation(OperationTypes.ADD, NetIMSupportedEntity.NETWORKDEVICE, attributes, nodes, edges);
                    break;
                default:
                    break;
            }
        }

        let layoutedGraph = await getLayoutedGraph(nodes, edges, options);
        if (overlapRemoval) {
            layoutedGraph = await getLayoutedGraph(layoutedGraph.nodes, layoutedGraph.edges, overlapRemovalOption);
        }

        layoutNodes = layoutedGraph.nodes;
        layoutEdges = layoutedGraph.edges;
    }

    return {
        nodes: layoutNodes,
        edges: layoutEdges
    }
}
