/** This module contains the component for the runbook list page.
 *  @module
 */
import React from 'react';
import { STRINGS, HELP } from 'app-strings';
import { PageWithHeader } from 'components/sdwan/layout/page-with-header/PageWithHeader';
import { SDWAN_ICONS } from 'components/sdwan/enums/icons';
import { RunbookListView } from './views/runbook-list/RunbookList';
import { Variant } from 'components/common/graph/types/GraphTypes';

/** Renders the On-Demand runbook list page.
 *  @param props the properties passed in.
 *  @returns JSX with the runbook list page component.*/
const OnDemandRunbookListPage = (): JSX.Element => {
    return (
        <PageWithHeader name="OnDemandRunbookListPage" title={STRINGS.runbooks.onDemandRunbooksTitle} icon={SDWAN_ICONS.RUNBOOK} addPadding={true} showTimeBar={false} helpInfo={HELP.runbookList} >
            <RunbookListView variant={Variant.ON_DEMAND}/>
        </PageWithHeader>
    );
};

export default OnDemandRunbookListPage;
