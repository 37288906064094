import React, { useState } from 'react';
import classNames from 'classnames';
import { Classes, Divider } from '@blueprintjs/core';
import { Form, InputField } from 'components/common/form';
import { STRINGS } from 'app-strings';
import * as yup from 'yup';
import { FormikProps } from 'formik';

export interface IBasicAuthParamsPanelProps {
	isPreExisting: boolean;
	authProfileUsername?: string;
	authProfilePassword?: string;
    onChangeUsername: (event) => void;
    onChangePassword: (event) => void;
}


const BasicAuthParamsPanel: React.FC<IBasicAuthParamsPanelProps> = (props) => {
	const [isPasswordChanged, setIsPasswordChanged] = useState(false);
	const validationSchema = yup.object().shape({
		profile_username: yup
			.string()
			.required()
			.max(2048)
			.label(STRINGS.thirdPartyIntegrations.addAuthProfile.panels
				.basicAuthParams.fields.profileUsername.label),
		profile_password: yup
			.string()
			.max(2048)
			.required()
			.label(STRINGS.thirdPartyIntegrations.addAuthProfile.panels
				.basicAuthParams.fields.profilePassword.label),
	});

    return (
		<div className={classNames(Classes.DIALOG_BODY)}>
			<p>
				<b>
					{
						STRINGS.thirdPartyIntegrations.addAuthProfile.panels
                            .basicAuthParams.title
					}
				</b>
			</p>
			<Divider />
			<br />
			<Form
				initialValues={{
					profile_username: props.authProfileUsername,
					profile_password: props.authProfilePassword,
				}}
				loading={false}
				validationSchema={validationSchema}
			>
				{(formProps: FormikProps<object>) => 
					<>
						<InputField
							name="profile_username"
							type="text"
							required={true}
							label={
								STRINGS.thirdPartyIntegrations.addAuthProfile.panels
									.basicAuthParams.fields.profileUsername.label
							}
							placeholder={
								STRINGS.thirdPartyIntegrations.addAuthProfile.panels
									.basicAuthParams.fields.profileUsername.placeholder
							}
							onBlur={(event) => {
								const value = event.target.value?.trim();

								formProps.handleBlur(event);
								props.onChangeUsername({target: {
									value: value
								}});
							}}
							onChange={props.onChangeUsername}
							value={props.authProfileUsername}
							disabled={false}
						/>
						<InputField
							name="profile_password"
							type="password"
							required={true}
							label={
								STRINGS.thirdPartyIntegrations.addAuthProfile.panels
									.basicAuthParams.fields.profilePassword.label
							}
							placeholder={
								STRINGS.thirdPartyIntegrations.addAuthProfile.panels
									.basicAuthParams.fields.profilePassword.placeholder
							}
							onBlur={(event) => {
								const value = event.target.value?.trim();

								setIsPasswordChanged(true);
								formProps.handleBlur(event);

								props.onChangePassword({target: {
									value: value
								}});
							}}
							onChange={(e) => {
								setIsPasswordChanged(true);
								props.onChangePassword(e)
							}}
							value={(props.isPreExisting && !isPasswordChanged) ? ('*'.repeat(props.authProfilePassword?.length || 0)) : props.authProfilePassword}
							disabled={false}
							showPasswordOption={isPasswordChanged}
						/>
					</>
				}
			</Form>
		</div>
	);
};

export { BasicAuthParamsPanel };
