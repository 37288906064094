const en = {
    and: "and",
    add: "Add",
    admin: "Admin",
    admins: "Admins",
    administration: "Administration",
    appTitle: "",
    automation: "Automation",
    explorer: "Explorer",
    cancel: "Cancel",
    cityName: "City Name",
    createObject: "Create {0}",
    CLIENT_HOSTS_PAGE: {
        title: "Client Hosts",
    },
    by: "by",
    configuration: "Configuration",
    confirm: "Confirm",
    companyName: "Riverbed",
    contact: "Contact",
    copy: "Copy",
    copyrightText: "Riverbed Technology. All rights reserved.",
    darkTheme: "Dark Mode",
    dashboard: "Dashboard",
    delete: "Delete",
    documentation: "Documentation",
    dropdownSelectText: "Select",
    edit: "Edit",
    editObject: "Edit {0}",
    ERRORS: {
        defaultTitle: "Error",
        defaultMessage: "The application encountered an error",
        LABELS: {
            code: "Code",
            message: "Message",
            suggestion: "Suggestion",
            errorId: "Error ID:",
            sessionId: "Session ID:",
        },
        supportLinkText:
            "includes essential information for reporting an issue to customer support. \n" +
            "You can submit a case online (for best results) " +
            "or contact the support team through email or phone.",
        contactSupport: "Contact support with this",
    },
    WARNINGS: {
        defaultTitle: "Warning",
    },
    events: "Events",
    hostname: "Hostname",
    ipAddress: "IP Address",
    landingText: "To access, enter your company domain and click login",
    lightTheme: "Light Mode",
    loading: "Loading",
    loadingTextIndicator: "...",
    more: "More...",
    login: "Login",
    noDataAvailable: "No data available",
    notAvailable: "N/A",
    topNavigation: {
        whatsNew: {
            title: "What's New in Riverbed IQ!",
            dontShowAgainText: "Do not show again",
            viewHelpBtn: "Take a look",
        },
        videoDialogTitle: "User Assistance Video Tour",
    },
    authentication: {
        codes: {
            popup_window_error:
                "Please allow pop-ups in order to login and then refresh the page.",
            multiple_matching_tokens: `The cache contains multiple tokens satisfying the requirements. 
                 Call AcquireToken again providing more requirements like authority.`,
            multiple_authorities:
                "Multiple authorities found in the cache. Pass authority in the API overload.",
            endpoints_resolution_error:
                "Error: could not resolve endpoints. Please check network and try again.",
            token_renewal_error:
                "Token renewal operation failed due to timeout.",
            invalid_id_token: "Invalid ID token format.",
            invalid_state_error: "Invalid state.",
            nonce_mismatch_error: "Nonce is not matching, Nonce received: ",
            login_progress_error:
                "Login_In_Progress: Error during login call - login is already in progress.",
            acquiretoken_progress_error:
                "AcquireToken_In_Progress: Error during login call - login is already in progress.",
            user_cancelled: "User cancelled the flow.",
            callback_error:
                "Error occurred in token received callback function.",
            user_login_error:
                "User login is required. For silent calls, request must contain either sid or login_hint",
            user_non_existent:
                "User object does not exist. Please call a login API.",
            client_info_decoding_error:
                "The client info could not be parsed/decoded correctly. Please review the trace to determine the root cause.",
            client_info_not_populated_error:
                "The service did not populate client_info in the response, Please verify with the service team",
            null_or_empty_id_token:
                "The idToken is null or empty. Please review the trace to determine the root cause.",
            id_token_parsing_error:
                "ID token cannot be parsed. Please review stack trace to determine root cause.",
            token_encoding_error:
                "The token to be decoded is not encoded correctly.",
            invalid_interaction_type:
                "The interaction type passed to the handler was incorrect or unknown",
            cannot_parse_cache:
                "The cached token key is not a valid JSON and cannot be parsed",
            block_token_requests: "Token calls are blocked in hidden iframes",
        },
    },
    notesBlade: {
        title: "NOTES",
        label: "Notes",
    },
    addNotes: {
        title: "Add Note",
        placeHolder: "Add a note here...",
        pinnedContent: "Pinned content",
        viewPinnedContent: "View pinned content",
        notes: "Notes",
        successMsgAdd: "Note added successfully",
        errorMsgAdd: "Error while adding a note. Please try again later.",
        successMsgEdit: "Note edited successfully",
        errorMsgEdit: "Error while editing a note. Please try again later.",
        editNoteTitle: "Edit Note",
        errorMsgDelete: "Error while deleting a note. Please try again later.",
        unknownUser: "Unknown User",
        lifecycleRunbook: "Lifecycle Runbook",
    },
    rerunRunbook: {
        successMsgTriggerRerun: "Triggered the rerun Runbook successfully",
        errorMshTriggerRerun:
            "Error while triggering the rerun Runbook. Please try again later.",
    },
    previewPassword: {
        hide: "Hide",
        show: "Show",
        password: " Password",
    },
    activityLog: {
        title: "ACTIVITY LOG",
        label: "Activity Log",
        lifecycleNoteAdded: "Lifecycle Runbook added a note",
        incidentCreated: "Incident created",
        incidentNoteAdded: "Incident note added",
        incidentNoteUpdated: "Incident note updated",
        incidentNoteDeleted: "Incident note deleted",
        incidentPriorityChanged: "Incident priority changed",
        incidentStatusChanged: "Incident status changed",
        runbookStatusChanged: "Runbook status changed",
        lifecycleRunbookStatusChanged: "Lifecycle Runbook status changed",
        incidentDesc: "Incident",
        incidentStatus: "Status changed",
        incidentStatusDesc: "Incident status changed to",
        prioritySet: "Priority set",
        priorityDesc: "Incident priority set to",
        runbookAdded: "Runbook added",
        runbookDesc: "New Runbook has been created",
        runbookStatusChange: "Runbook status changed",
        runbookStatusDesc: "Runbook status has changed to",
        noteAdded: "Note added",
        noteAddedDescPrefix: "added a",
        noteAddedDescLink: "note",
        newDetections: "New Detection",
        detectionDesc: "New Detection found",
    },
    rules: {
        ifaceHardwareProblem: "Interface HW problem",
        interfaceDown: "Interface Down",
        ifaceUtilization: "Interface Utilization",
        deviceDown: "Device Down",
        applicationProblem: "Application Problem",
        siteHealthProblem: "Multi-Device Down",
        multiDeviceDown: "Multi-Device Down",
    },
    send: "Send",
    today: "Today",
    logout: "Logout",
    notes: "Notes",
    pinNote: "Pin to notes",
    privacyPolicy: "Privacy Policy",
    rememberMe: "Remember Me",
    selectDateRange: "Select the date range",
    settings: "Settings",
    SEARCH: {
        descSitePerf: "The site performance is",
        descDevList: "Devices in this site are",
        descAppPerf: "The app performance is",
        descDevPerf: "The device performance is",
        descDevLoc: "The device is located in",
        descStatus: "and is currently",
        descTextIp: "IP:",
        descTextMod: "Model:",
        descTextVer: "Version:",
        descIncText1: "Incident source is ",
        descIncText2: "of type",
        descIncText3: "The issue was detected on",
        descDuration: "Duration:",
        descImpactedUserWithIp: "Impacted user with ip {ip}",
        descImpactedUserWithUserName: "Impacted user with name {userName}",
        descImpactedUserWithIpAndUserName:
            "Impacted user with ip {ip} and name {userName}",
        descImpactedApplication:
            "Impacted application with name {impactedApplication}",
        descImpactedLocation: "Impacted location with name {impactedLocation}",
        /* hiding per [#6657]
            inputPlaceholder: "Search for site, devices, apps, runbooks and incidents...",
        */
        inputPlaceholder: "Search for runbooks and incidents...",
        healthScore: "Health Score",
        results: "search results",
        all: "ALL",
        sites: "SITES",
        apps: "APPS",
        incidents: "INCIDENTS",
        runbooks: "RUNBOOKS",
        devices: "DEVICES",
        interfaces: "INTERFACES",
        applications: "APPLICATIONS",
        locations: "LOCATIONS",
        impactedUsers: "IMPACTED USERS",
        impactedApplications: "IMPACTED APPLICATIONS",
        impactedLocations: "IMPACTED LOCATIONS",
        customProperties: "CUSTOM PROPERTIES",
        details: "Details",
        viewIncidents: "View Incidents",
        vewOnMap: "View on map",
        configure: "Configure",
        runOnDemand: "Launch On Demand",
        seenOnText: "seen on",
        locationText: "at location",
        applicationText: "using application",
        linkTooltip: "Open link in new tab",
    },
    previous: "Previous",
    next: "Next",
    showMore: "Show more",
    show10: "Show 10",
    show20: "Show 20",
    show30: "Show 30",
    show40: "Show 40",
    show50: "Show 50",
    show100: "Show 100",
    CUSTOM_PROPERTIES_PAGE: {
        moreButton: {
            edit: "Edit",
            delete: "Delete",
        },
        confirmCustomPropertyDeleteMessage: `While this custom property is not being used in existing runbooks, it will be permanently deleted and cannot be restored. 
                                            Once deleted, it can no longer be used.\n 
                                            Are you sure you want to delete this custom property?`,
        confirmCustomPropertyInUseDeleteMessage: `This custom property is being used in existing runbooks. Are you absolutely sure
                                                 you want to delete this custom property?`,
        checkInUseLoadingMessage:
            "Checking if the custom property is in use...",
        bulkActionBtns: {
            delete: "Delete",
            import: "Import",
            export: "Export",
            create: "New",
        },
        deleteDialog: {
            selectedLabel: "Delete selected only ({0})",
            allLabel: "Delete all in search results ({0})",
        },
        toastMessages: {
            successDeleteCustomProperty:
                "Successfully deleted the custom property.",
            errorDeleteCustomProperty:
                "Could not delete the custom property. Please try again.",
            successDeleteCustomProperties:
                "Successfully deleted the custom properties.",
            errorDeleteCustomProperties:
                "There was an error deleting the custom properties.",
            successImportCustomProperties:
                "Succesfully imported {0} custom properties.",
            errorImportCustomProperties:
                "Could not import the custom properties from the file.",
            successSetCustomProperty: "Custom property succesfully set.",
            errorSetCustomProperty: "Custom property succesfully set.",
            successUnsetCustomProperty: "Custom property succesfully unset.",
            errorUnsetCustomProperty: "Custom property succesfully unset.",
        },
        customPropertyBlade: {
            name: "Name",
            description: "Description (Optional)",
            applicableTo: {
                label: "Applicable to:",
                locations: "Locations",
                applications: "Applications",
                devices: "Devices",
                interfaces: "Interfaces",
            },
            goLabel: "Go",
            values: {
                title: "Values",
                addValue: "Add Value",
            },
            buttons: {
                submitBtnText: "Save & Close",
                closeBtnText: "Close",
                delete: "Delete",
            },
            messages: {
                propertyUpdated: "Custom property successfully updated.",
                propertyUpdateFailed: "Custom property update failed.",
                uniqueValue: "Value has to be unique",
                propertyUnset: "Custom property successfully unset.",
            },
            valuePlaceholder: "Enter unique value...",
        },
        cProps: {
            name: "Name",
            ipAddress: "IP Address",
            vendor: "Vendor",
            type: "Type",
            model: "Model",
            location: "Location",
            serialNumber: "Serial Number",
            osVersion: "OS + Version",
            isGateway: "Is Gateway",
            ifIpAddresses: "IP Addresses",
            inboundSpeed: "Inbound Speed",
            outboundSpeed: "Outbound Speed",
            ifDescription: "Description",
            ifAlias: "Alias",
            ifIndex: "IfIndex",
        },
        setCPropModal: {
            title: "Set Custom Property",
            labelCProp: "Name",
            labelCPropVal: "Value",
            emptyCProp: "<Select Custom Property>",
            emptyCPropVal: "<Unset Value>",
            apply: "Apply",
            cancel: "Cancel",
            successAddMsg: "Custom property successfully matched.",
            errorAddMsg: "Failed to match custom property.",
            successRemoveMsg: "Custom property successfully unset.",
            errorRemoveMsg: "Failed to unset custom property.",
            createNewLink: "Create New",
            addNewValueLink: "Add New Value",
            selected: "Set Custom Property to selected ({0})",
            all: "Set Custom Property to all ({0})",
        },
        tabs: {
            setCPropCTA: "Set Custom Property",
        },
        tracking: {
            setCProp: "Matching a Custom Property from Modal",
        },
        importModal: {
            title: "Import Custom Properties",
            helpText:
                "Click the button below to import Custom Properties from a CSV file",
            errors: {
                GENERIC: "Error importing the CSV file",
                NO_NEW: "There are no new Custom Properties to add from this file",
                FILE_ERROR: "The file could not be read",
                FILE_TOO_BIG: "The uploaded file is too large",
            },
            success: {
                partial:
                    "From {0} custom properties existing in the file, {1} new ones have been added.",
                all: "Custom Properties have been added succesfully",
            },
            buttons: {
                submit: "Import",
                cancel: "Cancel",
            },
        },
        exportDialog: {
            title: "Export data to CSV",
            text: "Click the button below to export the custom properties to a CSV file",
            errorText: "Error exporting Custom Properties as CSV file.",
            btnText: "Export",
        },
        addDialog: {
            title: "New Custom Property",
            name: {
                label: "Name",
                placeholder: "Enter custom property name...",
            },
            description: {
                label: "Description (Optional)",
                placeholder: "Enter custom property description...",
            },
            singleType: "<Select a type>",
            errors: {
                unique: "This custom property name is already used.",
            },
            buttons: {
                submit: "Create",
                cancel: "Cancel",
            },
        },
        deleteModal: {
            checkInUseLoadingMessage:
                "Checking if any of the selected custom properties is in use in a runbook...",
            confirmCustomPropertiesInUseDeleteMessageSingle: "There is ",
            confirmCustomPropertiesInUseDeleteMessageMultiple: "There are ",
            confirmCustomPropertiesInUseDeleteMessage: `{0} custom properties being used in an existing runbook. Are you absolutely sure
                                                        you want to delete the selected custom properties?`,
        },
    },
    MAPPING_CONFIGURATION_PAGE: {
        menuTitleOld: "Automation",
        menuTitle: "Automation Management",
        pageTitle: "Automation Management",
        addButtonText: "Add Automation",
        duplicatePrefix: "Copy of {0}",
        noRunbookName: "Runbook no longer exists",
        columns: {
            order: "Order",
            automationName: "Automation Name",
            description: "Description",
            condition: "Conditions",
            runbookName: "Runbook",
            lastUpdatedBy: "Last Updated By",
            lastUpdatedOn: "Last Updated On",
            lastMatched: "Last Matched",
            enableAutomation: "Enabled",
        },
        addMappingModal: {
            title: {
                add: "Add an Automation",
                edit: "Edit an Automation",
            },
            panels: {
                selectTrigger: {
                    title: "Select Trigger",
                    subTitle: "Select Trigger",
                    triggers: {
                        triggeringEntity: "Associated triggering entity:",
                        deviceDownIssue: {
                            label: "Device Down Issue",
                            description:
                                "The Device Down Issue trigger is initiated when an individual device has been identified as unreachable or has rebooted.",
                            entity: "Device with its properties.",
                        },
                        interfacePerformanceIssue: {
                            label: "Interface Performance Issue",
                            description:
                                "The Interface Performance Issue is initiated when an interface has been identified as down/unreachable, or is exhibiting performance degradation in terms of congestion, packet errors and packet drops.",
                            entity: "Interface with its properties.",
                        },
                        multiDeviceDownIssue: {
                            label: "Multi Device Down Issue",
                            description:
                                "The Multi-Device Down trigger is initiated when multiple devices have been identified as unreachable at the same location.",
                            entity: "List of devices with their properties.",
                        },
                        applicationLocationPerformanceIssue: {
                            label: "Application Location Performance Issue",
                            description:
                                "The Application Location Performance Issue trigger is initiated when an application has exhibited possible performance degradation for a number of users at a specific location in terms of response time, retransmissions or lower network usage.",
                            entity: "Application and location.",
                        },
                        impactAnalysisReady: {
                            label: "Runbook Completed",
                            description:
                                "The Runbook Completed is initiated when the incident runbook analysis is complete and is ready for review.",
                            entity: "Event with the completion status of the runbook execution, and the date the runbook completed.",
                        },
                        incidentIndicatorsUpdated: {
                            label: "Indicators Updated",
                            description:
                                "The Indicators Updated is initiated when new indicators are added to an incident, meaning the problem continues or could be getting worse.",
                            entity: "Event with the number of new or updated indicators, and the time they were received.",
                        },
                        incidentNoteAdded: {
                            label: "Note Added",
                            description:
                                "The Note Added is initiated when a user manually adds a note to an incident.",
                            entity: "Event with the old and new incident status, the user who changed the status, and the time when the user changed it.",
                        },
                        incidentNoteUpdated: {
                            label: "Note Updated",
                            description:
                                "The Note Updated is initiated when a user updates an existing note in an incident.",
                            entity: "Event with the note content, the user who updated the note, and the time when the user updated it.",
                        },
                        incidentOngoingChanged: {
                            label: "Ongoing State Changed",
                            description:
                                "The Ongoing State Changed trigger is initiated when an incident is identified as ended, or is reopened when new indicators are received and considered to be relevant to the incident.",
                            entity: "Event with the incident end time.",
                        },
                        incidentStatusChanged: {
                            label: "Status Changed",
                            description:
                                "The Status Changed is initiated when the status of an incident changes, for example from New to Investigating.",
                            entity: "Event with the old and new incident status, the user who changed the status, and the time when the user changed it.",
                        },
                        webhook: {
                            label: "Webhook",
                            description:
                                "The Webhook trigger is initiated when a third party is using the webhook API to execute a runbook.",
                            entity: "Webhook HTTP query with HTTP headers, parameters and payload.",
                        },
                    },
                    fields: {
                        trigger: "Trigger: ",
                        selectTrigger: "Select Trigger",
                    },
                },
                setConditions: {
                    title: "Set Conditions",
                    subTitle: "Define optional conditions on trigger",
                },
                runbook: {
                    title: "Runbook",
                    subTitle: "Execute Runbook when Trigger Conditions are Met",
                    fields: {
                        createRunbook: {
                            label: "Create new Runbook",
                        },
                        executeRunbook: {
                            label: "Execute the runbook:",
                        },
                        runbookDescription: {
                            label: "Runbook description:",
                        },
                    },
                    selectRunbook: "Select the runbook",
                },
                final: {
                    title: "Finalize",
                    subTitle: "Finalize Automation",
                    fields: {
                        automationName: {
                            label: "Automation Name",
                        },
                        automationDescription: {
                            label: "Automation Description",
                        },
                        automationOrder: {
                            label: "Order",
                            description:
                                "If multiple automations are matching the trigger, only the first automation in the specified order will be performed.",
                        },
                    },
                },
            },
            buttons: {
                submitBtnText: "Submit",
            },
        },
        editMappingModal: {
            title: "Edit Mapping",
            labels: {
                associatedRunbook: "Associated Runbook",
                description: "Description",
            },
        },
        enableMappingModal: {
            title: "Enable/Disable Mapping",
            activateBodyText: "Enabling the mapping.",
            activateDisabledBodyText:
                "Are you sure you want to disable this mapping?",
            onText: "on",
            offText: "off",
        },
        deleteMappingModal: {
            title: "Delete Mapping",
            contentText: "Are you sure you want to delete this automation?",
            errorMsg: "Failed to delete automation.",
        },
        orderMappingModal: {
            title: "Change Order",
            contentText:
                "Please input the order number (Only positive numbers).",
            errorMsg: "Mapping failed to be reordered.",
            validationErrorMsg: "Only values between 1 and {0} please.",
        },
        actions: {
            edit: "Edit",
            duplicate: "Duplicate",
            delete: "Delete",
            reOrder: "Change Order",
            openRunbook: "Open Runbook",
        },
        categoryDescription: {
            INCIDENT:
                "Automation triggered by network events such as outages, network congestion, traffic anomalies, etc, detected by Riverbed IQ advanced analytics and correlation. An incident is automatically generated with analysis.",
            LIFECYCLE:
                "Automation triggered by user updating priority, adding notes, changing status, etc. Or by automatic update when incident is updated with new analysis, indicators or changes state.",
            EXTERNAL: "Automation is triggered via an external API.",
        },
        messages: {
            mappingUpdated: "Mapping successfully updated.",
            mappingUpdateFailed:
                "You cannot enable automation because you have not set a valid runbook!",
            mappingResetWarning:
                "Unsetting the Associated Runbook, will automatically turn the automation off.",
            mappingResetWarningOK: "OK",
            mappingDeleted: "Mapping(s) successfully deleted.",
            mappingDeletedFailed: "Mapping(s) failed to be deleted.",
            mappingOrdered: "Mapping successfully reordered.",
            mappingOrderFailed: "Mapping failed to be reordered.",
            mappingCreated: "Mapping successfully created.",
            mappingCreatedFailed: "Mapping failed to be created.",
            mappingCreatedFailedDup:
                "Mapping failed to be created. Name must be unique.",
            mappingCreatedFailedDupMessage:
                "Response not successful: Received status code 500",
        },
        subTypes: {
            APPLICATION_LOCATION_PERFORMANCE_ISSUE:
                "Application Location Performance Issue",
            DEVICE_DOWN_ISSUE: "Device Down Issue",
            INTERFACE_DOWN_ISSUE: "Interface Down Issue",
            INCIDENT_NOTE_ADDED: "Note Added",
            INCIDENT_NOTE_UPDATED: "Note Updated",
            INCIDENT_NOTE_DELETED: "Note Deleted",
            INCIDENT_INDICATORS_UPDATED: "Indicators Updated",
            INCIDENT_ONGOING_CHANGED: "Ongoing State Changed",
            INCIDENT_STATUS_CHANGED: "Status Changed",
            INTERFACE_PERFORMANCE_ISSUE: "Interface Performance Issue",
            IMPACT_ANALYSIS_READY: "Runbook Completed",
            SITE_OUTAGE_ISSUE: "Multi-Device Down Issue",
            // * not used SITE_APPLICATION_PERFORMANCE_ISSUE: 'Site Application Performance Issue',
            MULTI_DEVICE_DOWN_ISSUE: "Multi-Device Down Issue",
            WEBHOOK: "Webhook",
        },
    },
    HEALTH_MONITORING_PAGE: {
        menuTitle: "Monitoring Health",
        pageTitle: "Monitoring Health",
        overallStatus: "Overall Status",
        cards: {
            alluvioEdges: {
                title: "Edges",
                tooltips: {
                    edgesUp: "Number of Edges up and running",
                    edgesDown: "Number of Edges down",
                },
            },
            dataIngest: {
                title: "Data Ingest",
            },
            analytics: {
                title: "Analytics",
            },
            incidentGeneration: {
                title: "Incident Generation",
            },
            runbooks: {
                title: "Runbooks",
            },
            cloudStatus: {
                title: "Cloud Status",
            },
        },
        tabs: {
            dataIngest: {
                title: "Data Ingest",
            },
            analytics: {
                title: "Analytics",
            },
            incidentGeneration: {
                title: "Incident Generation",
            },
            runbooks: {
                title: "Runbooks",
            },
        },
        graphs: {
            analytics: {
                title: "Indicator Rate",
                metricName: "Indicators",
                date: "Date",
            },
            dataIngest: {
                title: "Metric Ingestion Rate",
                metricName: "Metrics",
                date: "Date",
            },
            incidentGeneration: {
                title: "Incident Rate",
                metricName: "Incidents",
                date: "Date",
            },
            runbookExecution: {
                successFailures: {
                    title: "Query Successes & Failures",
                    metricName: "Query Executions",
                    labels: {
                        failed: "Failed",
                        successful: "Successful",
                    },
                },
                completionTime: {
                    title: "Query Completion Time",
                    metricName: "Completion Time",
                },
            },
        },
        healthStatus: {
            operational: {
                label: "Operational",
                description: "All systems OK",
            },
            degraded: {
                label: "Degraded Performance",
                description: "One system is not OK",
            },
            partialOutage: {
                label: "Partial Outage",
                description: "Several systems are not OK",
            },
            majorOutage: {
                label: "Major Outage",
                description: "Several systems are not OK",
            },
            maintenance: {
                label: "Maintenance",
                description: "Systems are under maintenance",
            },
            unknown: {
                label: "Unknown",
                description: "The health status is not known",
            },
        },
    },
    SUBSCRIPTIONS_PAGE: {
        menuTitle: "Licensing & Usage",
        pageTitle: "Licensing & Usage",
        licensingSection: {
            title: "Licensing",
            text1: "Your subscription to Riverbed IQ is subject to a monthly allowance of unique metrics. Unique metrics include, but are not limited to, interface statistics, device statistics and summarized flow metrics.",
            text2: "Subscription limit is defined by metric packs where each metric pack represents a streaming allowance for a specific number of unique metrics.",
            metricPacksText: "Current subscription: {0} Metric Pack",
        },
        usageSection: {
            title: "Usage",
            text: "This chart shows the daily number of unique metrics streamed to Riverbed IQ over a calendar month.",
        },
        chart: {
            title: "Daily Unique Metrics",
            date: "Date",
            dailyUniqueMetrics: "Daily unique metrics",
            metricCount: "Unique Metrics",
            metricLimit: "Metric Pack Allowance",
        },
    },
    SUPPORT_PAGE: {
        title: "Support",
        techSupport: {
            linkLabel: "Riverbed Support",
            caseTitle: "Submit an issue to Riverbed Support",
            issueType: {
                label: "Issue type",
                options: {
                    problem: "Problem",
                    question: "Question",
                    accountAdministration: "Account administration",
                    featureRequest: "Feature request",
                    security: "Security",
                    other: "Other",
                },
            },
            contactPhoneNumber: {
                label: "Contact phone number (optional)",
                placeholder: "Enter a valid phone number",
                validation: "Not a valid phone number.",
            },
            email: {
                label: "Email",
            },
            caseDescription: {
                label: "Description",
                placeholder: "Limit 32000 characters",
            },
            priority: {
                label: "Priority",
                options: {
                    p1: "P1",
                    p1HelpText: "System Failure",
                    p2: "P2",
                    p2HelpText: "Serious",
                    p3: "P3",
                    p3HelpText: "Disruptive",
                    p4: "P4",
                    p4HelpText: "Support Request",
                },
            },
            subject: {
                label: "Subject",
                placeholder: "Limit 255 characters",
            },
            successMessage:
                "Your case #{0} has been created.<br />Email notification has been sent to {1}.<br />You can check the status of your case at the Riverbed Support Portal.",
        },
    },
    USER_PROFILE_PAGE: {
        manageAccount: "Manage Account",
        properties: {
            name: "Name",
            username: "Username",
        },
        title: "User Profile",
        clearPreferences: "Reset All User Preferences",
        clearPrefsSuccess: "User Preferences Cleared Successfully!",
        clearPrefsError: "Unable to Clear User Preferences!",
    },
    DEPLOYMENT_SUMMARY_PAGE: {
        title: "Map",
    },
    SITE_LIST_PAGE: {
        title: "Sites",
    },
    DEVICE_LIST_PAGE: {
        title: "Devices",
    },
    SEARCH_PAGE: {
        title: "Search",
    },
    UNDERLAY_COMPARISON_PAGE: {
        title: "Underlays",
    },
    SITE_SUMMARY_PAGE: {
        title: "Site Summary",
    },
    SITE_CONFIG_PAGE: {
        title: "Site Configuration",
        labels: {
            name: "Site Name",
            actionUndone: "This action cannot be undone.",
            showSitesWithUnknownLocation:
                "Show Sites with Unknown Locations Only",
            editSite: "Edit Site",
            resetSite: "Clear Customizations",
            siteImportance: "Site Importance",
            siteImportanceOptions: {
                STANDARD: "Standard",
                PRIORITY: "Priority",
            },
            siteId: "Site ID",
            siteName: "Site Name",
        },
        errorMessage: {
            siteSetBlankMessage:
                "Empty site name not allowed if site name already exists",
            locationBlankMessage: "Both latitude and longitude values required",
            latitudeSetBlankMessage:
                "Empty latitude value not allowed if site latitude already exists",
            longitudeSetBlankMessage:
                "Empty longitude value not allowed if site longitude already exists",
        },
        dalErrorCode: {
            InvalidLocation:
                "Error: Location should have either latitude or longitude",
            SiteNotFound: "Error: Only user modified site can be reset",
        },
    },
    warning: "Warning",
    welcome: "Welcome",
    welcomeMessage: "Welcome to Riverbed Cloud",
    TABLE: {
        noData: "No data to display",
    },
    traffic: "Traffic",
    TextEditor: {
        toolbar: {
            bold: "Bold",
            italic: "Italic",
            underline: "Underline",
            normal: "Normal",
            headingLarge: "Heading Large",
            headingMedium: "Heading Medium",
            headingSmall: "Heading Small",
            ul: "UL",
            ol: "OL",
            link: "Link!",
            removeLink: "Remove link",
        },
        insertVariable: "Insert Variable",
        variableTooltip: "Add Variable",
        notesPlaceholder: "Input text",
    },
    liquidTemplateEditor: {
        toolbar: {
            selectTrigger: "Select Trigger Property",
            selectTriggerTooltip:
                "Select A Trigger Property Or Metric To Add To Template",
            selectParent: "Select Input Property",
            selectparentTooltip:
                "Select Property Or Metric From Prent Node To Add To Template",
            selectVariable: "Select Variable",
            selectVariableTooltip: "Select A Variable To Add To Template",
            selectTypeTooltip: "Select The Type Of Entity To Add To Template",
            addTooltip: "Press To Add To Template",
        },
    },
    TIME_RANGE_SELECTOR: {
        view_last: "View last",
        time_range: "Time range",
        MIN_15: { displayText: "Last 15 minutes", shortText: "15m" },
        HOUR_1: { displayText: "Last 1 hour", shortText: "1h" },
        HOUR_2: { displayText: "Last 2 hours", shortText: "2h" },
        HOUR_6: { displayText: "Last 6 hours", shortText: "6h" },
        HOUR_8: { displayText: "Last 8 hours", shortText: "8h" },
        HOUR_12: { displayText: "Last 12 hours", shortText: "12h" },
        DAY_1: { displayText: "Last 1 day", shortText: "1d" },
        DAY_2: { displayText: "Last 2 days", shortText: "2d" },
        DAY_7: { displayText: "Last 7 days", shortText: "1w" },
        LAST_1_MONTH: { displayText: "Last month", shortText: "1M" },
        LAST_6_MONTH: { displayText: "Last 6 months", shortText: "6M" },
        LAST_1_YEAR: { displayText: "Last year", shortText: "1Y" },
        CURRENT_MONTH: { displayText: "Current month", shortText: "CM" },
        PREVIOUS_MONTH: { displayText: "Previous month", shortText: "PM" },
        BEFORE_PREVIOUS_MONTH: {
            displayText: "Berore previous month",
            shortText: "BPM",
        },
        hoursDisp: "{0}h",
        fullIncident: "Full Incident",
        custom: "Select a date range",
        apply: "Apply",
        cancel: "Cancel",
        dateRange: "Time Period",
    },
    site: "Site",
    site_plural: "Sites",
    dalErrorCode: {
        defaultMessage: "Error: Something Went Wrong, Please Try Again Later",
    },
    latitude: "Latitude",
    longitude: "Longitude",
    lastUpdatedOn: "Last Updated On",
    lastUpdatedBy: "Last Updated By",
    listOfDevices: "List Of Devices",
    submitButtonLabel: "Submit",
    resetButtonLabel: "Reset",
    unnamed_site_name: "-",
    unnamed_local_site_name: "Site 1",
    unnamed_remote_site_name: "Site 2",
    device: "Device",
    device_plural: "Devices",
    unnamed_device_name: "-",
    interface: "Interface",
    interface_plural: "Interfaces",
    interfaceUp: "UP",
    interfaceDown: "Down",
    noData: "-",
    description: "Description",
    issue: "Issue",
    issue_plural: "Issues",
    link: "Link",
    share: "Share",
    share_help_text: "Anyone with this link will be able to view this page",
    share_view: "Share this view",
    copy_view: "Copy Link",
    link_plural: "Links",
    overlay: "Overlay/Tunnel",
    overlay_plural: "Overlays/Tunnels",
    underlay: "Underlay/ Transport",
    underlay_plural: "Underlays/Transports",
    device_down: "Down",
    application: "Application",
    applications: "Applications",
    application_location: "Application Location",
    app: "App",
    apps: "Apps",
    host: "Host",
    host_plural: "Hosts",
    summary: "Summary",
    max: "Max",
    fetch_top_talkers: "Fetch Interface Top Talkers",
    interface_top_talkers: "Interface Top Talkers",
    smartText: {
        counts: {
            no: "No",
            yes: "Yes",
            this: "This",
            both: "Both",
            all: "All",
            most: "Most",
            several: "Several",
            many: "Many",
            some: "Some",
            few: "A few",
        },
        is: "is",
        are: "are",
        severeIssues: "experiencing severe issues",
        havingIssues: "experiencing issues",
        inProcessOf:
            "We're in the process of learning about the performance of",
        checkBackLater: "Check back later",
        unknownFor: "Status is unknown for",
        pleaseContact: "If this condition persists, please contact",
        techSupport: "technical support",
        performingAsExpected: "performing as expected",
        outOf: "of",
        problematic: "problematic",
        alwaysAboveThreshold: "Always Above Normal",
        exceedingMultipleThresholds: "Very High",
        criticalState: "Critical",
        exceedingThreshold: "Exceeding Threshold",
        backWithinThreshold: "Back To Normal",
        withinThreshold: "Within Normal Range",
        havingHigh: "experiencing high",
        normal: "is performing without any issues",
    },
    METRICS: {
        status: "Status",
        throughput: "Throughput",
        peakThroughput: "Peak Throughput",
        inboundThroughput: "Inbound Throughput",
        outboundThroughput: "Outbound Throughput",
        traffic: "Traffic",
        inboundTraffic: "Inbound Traffic",
        outboundTraffic: "Outbound Traffic",
        jitter: "Jitter",
        peakJitter: "Peak Jitter",
        latency: "Latency",
        peakLatency: "Peak Latency",
        packetLoss: "Packet Loss",
        peakPacketLoss: "Peak PacketLoss",
        availability: "Availability",
        cpu: "CPU",
        memory: "Memory",
        disk: "Disk",
        utilization: "Utilization",
        inboundUtilization: "Inbound Utilization",
        outboundUtilization: "Outbound Utilization",
        packets: "Packets",
        inboundPackets: "Inbound Packets",
        outboundPackets: "Outbound Packets",
        activeConnections: "Active Connections",
        connection: "Connection",
        connections: "Connections",
        roundTripTime: "RTT",
        connectedHost: "Connected Host",
        duration: "Duration",
        startTime: "Start Time",
        errorsPercent: "Packet Errors",
        dropsPercent: "Packet Discards",
        cpu_usedPercent: "CPU Used Percent",
        disk_usedPercent: "Disk Used Percent",
        memory_usedPercent: "Memory Used Percent",
        // These metrics are used by Desso in the runbooks.  Maybe we should reconsider
        // putting metrics in an enumerated type and should either get them from the
        // system or allow them to be defined on the fly.  For now I am adding all the
        // metrics Desso needs here.
        packet_loss: "Packet Loss",
        in_utilization: "Inbound Utilization",
        out_utilization: "Outbound Utilization",
        cpu_utilization: "CPU Utilization",
        memory_utilization: "Memory Utilization",
        disk_utilization: "Disk Utilization",
        bytes: "Traffic",
        in_bytes: "Inbound Traffic",
        out_bytes: "Outbound Traffic",
        in_throughput: "Inbound Throughput",
        out_throughput: "Outbound Throughput",
        in_packets: "Inbound Packets",
        out_packets: "Outbound Packets",
        active_connections: "Active Connections",
        sum_active_connections: "Active Connections",
        new_connections_ps: "New Connection Rate",
        sum_new_connections: "New Connections",
        retrans_bytes_percent: "% Retrans Bytes",
        retrans_packets_percent: "% Retrans Packets",
        response_time: "Response Time",
        request_network_time: "Request Data Transfer Time",
        response_network_time: "Response Data Transfer Time",
        network_rtt: "Network Round Trip Time",
        server_rtt: "Server Round Trip Time",
        server_delay: "Server Response Time",
        application_usage_time_total: "Usage Time",
        activity_response_time_avg: "Response Time",
        activity_network_time_avg: "Network Time",
        application_score_avg: "UXI Score",
        page_load_network_time_avg: "Page Load Network Time",
        application_wait_time_total: "Application Wait Time",
        sum_bytes: "Traffic",
        sum_in_bytes: "Inbound Traffic",
        sum_out_bytes: "Outbound Traffic",
        sum_in_bytes_too_high: "Inbound Traffic",
        sum_packets: "Packets",
        sum_in_packets: "Inbound Packets",
        sum_out_packets: "Outbound Packets",
        sum_application_usage_time: "Usage Time",
        activity_rtt: "Response Time",
        activity_network_time: "Network Time",
        application_uxi: "UXI Score",
        page_load_network_time: "Page Load Network Time",
        sum_application_wait_time: "Application Wait Time",
        sum_changes: "Configuration Changes",
        timestamp: "Timestamp",
        max_utilization: "Max Utilization",
        max_in_utilization: "Max Inbound Utilization",
        max_out_utilization: "Max Outbound Utilization",
        sum_in_ifc_errors: "Inbound Errors",
        sum_out_ifc_errors: "Outbound Errors",
        sum_in_packet_drops: "Inbound Packet Drops",
        sum_out_packet_drops: "Outbound Packet Drops",
        in_packet_error_rate: "Inbound Packet Error Rate",
        out_packet_error_rate: "Outbound Packet Error Rate",
        sum_usage_time: "Usage Time",
        sum_wait_time: "Wait Time",
        in_packet_drops_rate: "In Packet Drops Rate",
        device_status: "Device Status",
        iface_status: "Interface Status",
        out_packet_drops_rate: "Out Packet Drops Rate",
        admin_status: "Interface Administrative Status",
        op_status: "Interface Operational Status",
        device_up_time: "Up Time",
        mos: "MOS",
        activity_backend_time: "Activity Back End Time",
        activity_client_time: "Activity Client Time",
        activity_response_time: "Activity Response Time",
        sum_crashes: "Application Total Crashes",
        sum_hang_time: "Application Total Hang Time",
        page_load_client_time: "Page Load Client Time",
        page_load_backend_time: "Page Load Back End Time",
        page_load_time: "Page Load Time",
        dns_response_time: "DNS Response Time",
        connection_setup_time: "Connection Setup Time",
        request_payload_transfer_time: "Request Payload Transfer Time",
        response_payload_transfer_time: "Response Payload Transfer Time",
        retrans_delay: "Retrans Delay",
        count_unique_devices: "Count of Unique Devices",
        connection_requests: "Connection Requests",
        failed_connections: "Failed Connections",
        connections_failed_percent: "% Failed Connections",
        user_response_time: "User Response Time",
        latest_device_status: "Latest Device Status",
        sum_changes_24h: "Changes (24h)",
        latest_device_up_time: "Last Seen Up Time",
        request_payload_time: "Request Payload Transfer Time",
        response_payload_time: "Response Payload Transfer Time",
        sum_retrans_bytes: "Retrans Traffic",
        retrans_percent: "% Total Retrans",
        retrans_payload_percent: "% Payload Retrans",
        request_retrans_payload_percent: "% Request Payload Retrans",
        response_retrans_payload_percent: "% Response Payload Retrans",
        sum_out_of_order_segments: "OOO Segments",
        sum_client_out_of_order_segments: "OOO Client Segments",
        sum_server_out_of_order_segments: "OOO Server Segments",
        in_network_rtt: "In Round Trip Time",
        out_network_rtt: "Out Round Trip Time",
        sum_zero_window_segments: "Zero Window Segments",
        sum_client_zero_window_segments: "Zero Window Client Segments",
        sum_server_zero_window_segments: "Zero Window Server Segments",
        zero_window_percent: "% TCP Zero Window",
        request_retrans_delay: "Request Retrans Delay",
        response_retrans_delay: "Response Retrans Delay",
        // Metrics uses for NPM+
        cli2srv_ooo_bytes: "Client to Server Out of Order Traffic",
        cli2srv_ooo_packets: "Client to Server Out of Order Packets",
        cli2srv_payload_bytes: "Client to Server Payload Traffic",
        cli2srv_payload_packets: "Client to Server Payload Packets",
        cli2srv_rx_bytes: "Client to Server Received Traffic",
        cli2srv_rx_packets: "Client to Server Received Packets",
        cli2srv_traffic_bytes: "Client to Server Traffic",
        cli2srv_traffic_packets: "Client to Server Traffic Packets",
        cli2srv_zero_windows: "Client to Server Zero Windows",
        control_packets: "Control Packets",
        control_rx_packets: "Control Received Packets",
        srv2cli_ooo_bytes: "Server to Client Out of Order Traffic",
        srv2cli_ooo_packets: "Server to Client Out of Order Packets",
        srv2cli_payload_bytes: "Server to Client Payload Traffic",
        srv2cli_payload_packets: "Server to Client Payload Packets",
        srv2cli_rx_bytes: "Server to Client Received Traffic",
        srv2cli_rx_packets: "Server to Client Received Packets",
        srv2cli_traffic_bytes: "Server to Client Traffic",
        srv2cli_traffic_packets: "Server to Client Traffic Packets",
        srv2cli_zero_windows: "Server to Client Zero Windows",
        conn_setup_time_us: "Connection Setup Time",
        client_round_trip_time_us: "Client Round Trip Time",
        server_round_trip_time_us: "Server Round Trip Time",
        request_net_time_us: "Request Network Time",
        response_net_time_us: "Response Network Time",
        service_response_time_us: "Service Response Time",
    },
    no_data_to_display: "No data to display",
    select_metrics: "SELECT METRICS",
    UNITS: {
        pct: "%",
    },
    alert_events: "Alert Events",
    no_alert_events_found: "No Alert Events Found",
    ordered_by: "by",
    connected: "Connected",
    connectedTo: "Connected to",
    connectedHosts: "Connected Hosts",
    connections: "Connections",
    health: "Health",
    Normal: "Normal",
    Degraded: "Degraded",
    Critical: "Critical",
    Unknown: "Unknown",
    incidentPriorities: {
        label: "Priority",
        low: "Low",
        moderate: "Moderate",
        high: "High",
        critical: "Critical",
    },
    incidentStatus: {
        label: "Status",
        new: "New",
        investigating: "Investigating",
        dismissed: "Dismissed",
        onHold: "On Hold",
        closed: "Closed",
    },
    runbookStatus: {
        new: "New",
        inProgress: "In Progress",
        succeeded: "Completed",
        canceled: "Canceled",
        partiallySucceeded: "Completed With Errors",
        done: "Completed",
        failed: "Failed",
        unknown: "Unknown",
    },
    entityKinds: {
        label: "Type",
        networkInterface: "Interface",
        networkDevice: "Device",
        application: "Application",
        applicationServer: "Application Server",
        applicationLocation: "Application Location",
        networkServer: "Network Server",
        location: "Location",
        applicationActivity: "Application Activity",
    },
    triggerTypes: {
        label: "Trigger",
    },
    Initializing: "Initializing",
    health_label: "HEALTH:",
    viewDetails: "View Details",
    viewSummary: "View Summary",
    viewAll: "View All",
    overall_performace: "Overall Performance",
    view: "View",
    deviceDetails: {
        title: "Device details",
        labels: {
            name: "Name",
            model: "Model",
            location: "Location",
            version: "Software Version",
            accessAddress: "Access Address",
            reachability: "Reachability Status",
            serviceStatus: "Service Status",
        },
    },
    hostDetails: {
        title: "Host Details",
        labels: {
            operatingSystem: "OS",
            operatingSystemVersion: "OS Version",
            ipAddress: "IP Address",
        },
    },
    riverbedAdvisor: {
        title: "Runbook",
        trademark: "",
        details: "Details",
        editRunbook: "Edit Runbook",
        titles: {
            default: "Informational",
            degraded: "Minor Impact",
            critical: "Major Impact",
        },
    },
    runbooks: {
        title: "Runbooks",
        onDemandRunbooksTitle: "On-demand Runbooks",
        incidentRunbooksTitle: "Incident Runbooks",
        lifecycleRunbooksTitle: "Lifecycle Runbooks",
        onDemandRunbookBlade: {
            linkDescription: "Open analysis",
        },
        runbook: "Runbook",
        lifecycleRunbook: "Lifecycle Runbook",
        edit: "Edit",
        noRunbooks: "No Runbook Analysis Available",
        unknownRunbookName: "Unknown",
        viewTemplate: "View Runbook",
        rerun: "Re-run",
        offText: "off",
        onText: "on",
        systemText: "system",
        tabNames: {
            allIncidentRunbooks: "All Incident Runbooks",
            allLifecycleRunbooks: "All Lifecycle Runbooks",
            allOnDemandRunbooks: "All On-Demand Runbooks",
            allSubflowRunbooks: "All Subflows",
            mine: "Created By Me",
            automation: "Automation Allowed",
            inerror: "In Error",
            factory: "Built-In",
            scheduled: "Scheduled",
        },
        columns: {
            name: "Runbook",
            createdTime: "Created",
            createdBy: "Created By",
            description: "Description",
            trigger: "Trigger",
            factory: "Built-In",
            enabled: "Allow Automation",
            lastUpdateBy: "Last Updated By",
            lastUpdateTime: "Last Updated",
            entityType: "Entity Type",
            version: "Version",
        },
        more: {
            exportMenuItem: "Export",
            deleteMenuItem: "Delete",
            deleteVersionMenuItem: "Delete Older Versions",
            editMenuItem: "Edit",
            editVersionMenuItem: "Edit Older Versions",
            duplicateMenuItem: "Duplicate",
            testMenuItem: "Test Run",
            previewMenuItem: "Preview Output",
            runRunbook: "Run",
            manageSchedulesItem: "Manage Schedules",
        },
        newBtnText: "New",
        importBtnText: "Import",
        deleteBtnText: "Delete",
        importDialog: {
            title: "Import {variant}",
            text: "Click the button below to import the {variant} into the UI",
            errorText: "Error importing new {variant}!",
            wrongVariantErrorText:
                "Error importing new {variant}: the variant attribute does not match or is missing!",
            invalidTypeErrorText:
                "Error importing new {variant}: there are unsupported nodes ({types}) in the import file!",
            btnText: "Import",
        },
        exportDialog: {
            title: "Export {variant}",
            preText: "Querying for {variant} configuration",
            text: "Click the button below to copy the {variant} json to the clipboard",
            errorText: "Error exporting {variant}.",
            btnText: "Copy to Clipboard",
            downloadBtnText: "Save to File",
        },
        duplicateDialog: {
            title: "Duplicate {variant}",
            bodyText:
                "Creating a duplicate of the specified {variant}.",
            errorText: "Error duplicating {variant}!",
        },
        createDialog: {
            title: "Create New {variant}",
            bodyText:
                "Creating a new runbook and opening the {variant} editor.",
            errorText: "Error creating new {variant}!",
            newRunbookName: "New {variant}",
            newRunbookDesc: "This is a newly inserted {variant}.",
            newRunbookComment: "Enter nodes in the graph",
        },
        activateDialog: {
            title: "Allow/Disallow Automation",
            bodyText: "Allowing/Disallowing Automation",
            enabledBodyText:
                "Are you sure you want to allow automation for this {variant}?",
            disabledBodyText:
                "Are you sure you want to disallow automation for this {variant}?",
            mappedDisablingBodyText:
                "Disallowing automation for mapped {variant} is not permitted.",
            etagErrorText:
                "Another user has modified this {variant}, please refresh the {variant} list before activating/deactivating {variant}.",
            generalErrorText: "Error activating/deactivating {variant}.",
        },
        deleteDialog: {
            title: "Delete {variant}",
            bodyText: "Are you sure you want to delete the selected {variant}?",
            bodyTextWithRunbookName: "Are you sure you want to delete the {variant} {runbookName}?",
            bodyTextSubflowUsedInRunbooks1:
                "This subflow is currently used in the following runbooks. These will fail or will complete with errors if this subflow is deleted.",
            bodyTextSubflowUsedInRunbooks2:
                "Are you sure you want to delete this subflow?",
            bodyTextScheduledRunbook1:
                "This runbook must be unscheduled before being deleted. You can go to the",
            bodyTextScheduledRunbook2: "Runbook Scheduling",
            bodyTextScheduledRunbook3: "page to do this.",
            multiBodyText:
                "Are you sure you want to delete the selected {variant}(s)?",
            mappedBodyText: "Deleting mapped {variant} is not permitted.",
            enabledBodyText:
                "The current runbook is the only enabled {variant} for this trigger, deleting it will result in no active {variant}s for this trigger.  Are you sure you want to delete the selected {variant}?",
            generalDeleteError: "Error deleting {variant} from backend",
            btnText: "Delete",
            btnTextSubflowUsedInRunbooks: "Delete Anyway",
        },
        okBtnText: "OK",
        cancelBtnText: "Cancel",
        runbookTextForVariant: {
            incident: "runbook",
            lifecycle: "runbook",
            on_demand: "runbook",
            external: "runbook",
            subflow: "subflow",
            dashboard: "dashboard",
        },
        runbookTextForVariantUc: {
            incident: "Runbook",
            lifecycle: "Runbook",
            on_demand: "Runbook",
            external: "Runbook",
            subflow: "Subflow",
            dashboard: "Dashboard",
        },
        inputsPopup: {
            toggleButton: "Show inputs",
            title: "Inputs",
            noInputsMessage: "This runbooks has no associated inputs.",
        },
        subflowVersionWarning: "There are subflows in this runbook that do not use the latest version of the subflow."
    },
    apiAccess: {
        endpointScope: {
            partOne: `This page specifies client credential for third party applications to 
                      authenticate and access Riverbed IQ APIs. An OAauth client is used to 
                      identify a single application.`,
            partTwo: "Access tokens are obtained from URI: ",
            partThree: " (Scope: ",
        },
        title: "API Access",
        add: "Create OAuth Client",
        copyToClipboard: "Copy to Clipboard",
        columns: {
            name: "Name",
            expires: "Earliest Client Expiration",
            clientId: "Client Id",
            noOfSecrets: "Number of Client Secrets",
        },
        more: {
            add: "Add Client Secret",
            manage: "Manage Client Secrets",
            delete: "Delete OAuth Client",
        },
        expiredSecretTooltip: "One of the client secrets has expired.",
        expirationDateNA: "N/A",
        validity: "Secret Expiration",
        confirmClientDeleteMessage: `The credential will be permanently deleted and cannot be restored. 
                                     Once deleted, it can no longer be used to make API requests.\n 
                                     Are you sure you want to delete this credential?`,
        validityOptions: {
            threeMonths: "3 months",
            sixMonths: "6 months",
            twelveMonths: "12 months",
            eighteenMonths: "18 months",
            twentyfourMonths: "24 months",
            custom: "Custom",
        },
        validationMessages: {
            daysRequired: "Days required.",
            minDays: "The validity should be at least one day.",
            maxDays: "The validity should be maximum two years.",
        },
        unitToCountOn: "Days",
        modalTitles: {
            stepOne: "Create OAuth Client",
            stepTwo: "OAuth Client Created",
            manageSecrets: "Manage Client Secrets",
            addSecret: "Add Client Secret",
        },
        modalButtons: {
            addAnotherSecret: "Add another Client Secret",
            submitStepOne: "Create",
            submitStepTwo: "OK",
            submitManageSecrets: "OK",
            submitAddSecret: "Add",
        },
        labels: {
            clientName: "OAuth Client",
            clientId: "Client Id",
        },
        addClientSecretModal: {
            clientName: "OAuth Client",
            description: "Secret Description",
            descriptionPlaceholder: "Add name here...",
            columns: {
                description: "Description",
                secret: "Client Secret",
                expiration: "Expires on",
            },
        },
        generateClientSecretsStepOneModal: {
            name: "OAuth Client Name",
            namePlaceholder: "Add name here...",
            infoMessageTitle: "IMPORTANT:",
            infoMessage: `Copy the Client Secret above to a secure location immediately, 
                          it will never be shown ever again once the page reloads`,
            infoMessageForDeletion: `A client was marked for deletion. It will be permanently delete. 
                          Once deleted, it can no longer be used to obtain an access token.`,
        },
        manageClientSecretsModal: {
            infoMessageForNewSecretTitle: "IMPORTANT:",
            infoMessageForNewSecret: `Copy the Client Secret above to a secure location immediately, 
                                      it will never be shown ever again once the page reloads`,
            infoMessageForDeletion:
                "A client secret was marked for deletion. It will be permanently deleted. Once deleted, it can no longer be used to obtain an access token.",
        },
        toastMessages: {
            successCreateClient: "OAuth Client created successfully",
            errorCreateClient:
                "Error while creating an OAuth client. Please try again.",
            successDeleteClient: "OAuth Client deleted successfully",
            errorDeleteClient:
                "Error while deleted an OAuth client. Please try again.",
            successCreateSecret: "Client Secret created successfully",
            errorCreateSecret:
                "Error while creating a client secret. Please try again.",
            successDeleteSecret: "Client Secret deleted successfully",
            errorDeleteSecret:
                "Error while deleting a client secret. Please try again.",
        },
    },
    runbookEditor: {
        newRunbook: "New",
        propsTabText: "Properties",
        layoutTabText: "Layout",
        graphDataTabText: "Graph Data",
        helpTabText: "Help",
        noEditTitle: "Permissions Warning",
        noEditText:
            "You do not have write permissions.  You can continue to try out the runbook editor, but you will not be able to save your runbook.",
        noEditBtnText: "OK",
        interfaceInputType: "Interface",
        deviceInputType: "Device",
        applicationInputType: "Application",
        locationInputType: "Location",
        webhookInputType: "Webhook",
        impactAnalysisInputType: "Impact Analysis Ready",
        incidentStatusChangedInputType: "Status Changed",
        incidentNoteAddedInputType: "Note Added",
        incidentNoteUpdatedInputType: "Note Updated",
        incidentNoteDeletedInputTyp: "Note Deleted",
        incidentIndicatorsUpdatedInputType: "Indicators Updated",
        incidentOngoingChangedInputType: "Ongoing State Changed",
        unknownInputType: "Unknown",
        importDialog: {
            title: "Import {variant}",
            text: "Click the button below to import the {variant} into the UI",
            wrongVariantErrorText:
                "Error importing new {variant}: the variant attribute does not match or is missing!",
            btnText: "Load in editor",
        },
        exportDialog: {
            title: "Export {variant}",
            text: "Click the button below to copy the {variant} json to the clipboard",
            btnText: "Copy To Clipboard",
        },
        saveDialog: {
            syncTitle: "Save {variant}",
            syncText:
                "Click the button below to save the {variant} to the server:",
            syncShowErrorsBtnText: "Show Errors",
            syncNameLabel: "Name:",
            syncVersionLabel: "Version Update:",
            syncDescLabel: "Description:",
            syncInputTypeLabel: "Input Type:",
            syncErrorAndWarningText:
                "The {2} has {0} error(s) and {1} warning(s)",
            syncErrorText: "The {1} has {0} error(s)",
            syncWarningText: "The {1} has {0} warning(s)",
            syncHideErrors: "Hide {variant} errors",
            syncShowErrors: "Show {variant} errors",
            syncBtnText: "Save",
            syncWithEtagBtnText: "Save Anyway",
            settingsTitle: "{variant} Settings",
            settingsText:
                "Click the button below to update the {variant} settings:",
            settingsBtnText: "OK",
            confirmationModal: {
                title: "This subflow is currently used in the following runbooks. These might fail or complete with errors if this subflow is changed.",
                nameColumn: "Runbook Name",
                createdByColumn: "Created By",
            },
            savingScheduledRunbook: "This runbook is configured to run on a schedule. Any modifications will be applied to future runs scheduled for this runbook."
        },
        deleteDialog: {
            title: "Delete {variant}",
            text: "Click the button below to delete the {variant} from the server",
            btnText: "Delete from server",
        },
        testDialog: {
            title: "Execute A Test Run",
            text: "This runbook has modifications that have not been saved.  The runbook must be saved to run a test.",
            btnText: "OK",
        },
        unsavedChangesWarning:
            "You have unsaved changes in this {variant} which will be lost! Are you sure you want to continue?",
        unsavedNodeChangesTitle: "Unsaved Changes!",
        unsavedNodeChangesWarning:
            "The node editor has some Unsaved Changes! Would you like to save them?",
        unsavedVariablesChangesTitle: "Unsaved Changes!",
        unsavedVariablesChangesWarning:
            "The variables editor might have some Unsaved Changes! Would you like to keep editing?",
        keepEditing: "Keep editing",
        noLabelForErrorMessage: "Current node",
        noMatchingNodes: "No matching nodes",
        previewRunbookTitle: "Preview Output for {0}",
        testRunbookTitle: "Test Run for {0}",
        saveAndCloseBtn: "Save & Close",
        discardAndCloseBtn: "Discard & Close",
        runbookTextForVariant: {
            incident: "runbook",
            lifecycle: "runbook",
            on_demand: "runbook",
            external: "runbook",
            subflow: "subflow",
        },
        runbookTextForVariantUc: {
            incident: "Runbook",
            lifecycle: "Runbook",
            on_demand: "Runbook",
            external: "Runbook",
            subflow: "Subflow",
        },
        triggerNameForVariant: {
            incident: "trigger",
            lifecycle: "trigger",
            on_demand: "input",
            external: "trigger",
            subflow: "subflow input",
        },
        errors: {
            noCyclicGraphs: "{variant}s cannot include cyclic dependencies",
            generalRunbookServiceError:
                "Something went wrong when attempting this operation",
            generalSaveError: "Error saving {variant} to backend",
            etagSaveError:
                "Another user has modified this {variant}, please get the latest {variant} before saving the {variant}.",
            generalValidationError: "Error validating {variant}",
            generalDeleteError: "Error deleting {variant} from backend",
            newRunbookDeleteError:
                "Cannot delete a {variant} that is not yet saved on the server",
            nodeHasParent: "This node cannot have a parent",
            nodeDoesNotHaveParent: "This node must have a parent",
            nodeShouldHaveChildren: "This node must have a child",
            nodeShouldNotHaveChildren: "This node cannot have a child",
            nodeHasInvalidType:
                "This node is of an unknown type.  Please delete the node and re-add it using the latest set of nodes",
            noRunbookContent: "The {variant} has no content",
            runbookNodeCountExceeded: "The {1} cannot have more than {0} nodes",
            runbookDuplicateNameError:
                "A {variant} with this name exists already!",
            runbookTriggerTypeError:
                "Only one user-defined {variant} of each trigger type is allowed",
            runbooksValidationQueryError:
                "Error retrieving the {variant} list during validation",
            dataOceanInitFailed: "Data query initialization failed. {0}",
            connectError: "Cannot auto-connect: {0}",
            noMetricError: "Please select a metric.",
            noMetricsError: "Please select at least one metric.",
            pasteError:
                "Cannot paste nodes there are incompatible nodes in the content: {types}",
            uniqueVariableNameError:
                "The name of each variable must be unique.",
            uniquePropertyNameError: 
                "The name of each property must be unique.",
            uniqueMetricNameError: 
                "The name of each metric must be unique.",
            triggerNode: {
                noTriggerNodeError:
                    "No {triggerName} node found! The Runbook cannot be executed without a {triggerName}",
                tooManyTriggerNodesError:
                    "Too many {triggerName} Nodes! A Runbook should have only one {triggerName}",
                triggerNodeUnknownNode:
                    "Cannot connect this node to the trigger or subflow input node",
                timeReference:
                    "Please select one of the two options for referencing time.",
                timeOffset: "Please select an ooption for the time offset.",
                unexpectedError:
                    "An error was encountered when validating the trigger node",
                onlyTransformNodeWebhookTrigger:
                    "Only transform, http, set priority and set primitive variable nodes can be hooked up to the webhook trigger",
                triggerToSubflow:
                    "This subflow does not have the right input type to be connected to this trigger",
            },
            doNode: {
                dataOceanValueFromTriggerError:
                    "<b>{0}</b> filter value set to be retrieved from trigger node but the trigger node is of type <b>{1}</b>",
                dataOceanValueFromNodeError:
                    "<b>{0}</b> filter value set to be retrieved from connecting node but the connecting node does not return objects of type <b>{0}</b>.  Please delete the data query node and add it again.",
                dataOceanValueFromVariableError:
                    "<b>{0}</b> filter value set to be retrieved from a variable but the variable does not exist",
                dataOceanOnlyOneParentError:
                    "A data query node must have one and only one parent either of type Trigger, Data Query, Decision Branch, Aggregator, Subflow, or Transform",
                dataOceanInvalidObjType:
                    "This data query is obsolete please replace it with a new data query",
                requiredFilterError: "Value for <b>{0}</b> filter is required",
                networkInterfaceEmptyUserInput:
                    "Value for Specific Interfaces is required",
                networkDeviceEmptyUserInput:
                    "Value for Specific Device is required",
                applicationEmptyUserInput:
                    "Value for Specific Apps is required",
                metricsRequiredError: "At least one metric is required",
                timeSeriesMetricsError:
                    "There should be one and only one metric when Time Series option is selected and a Limit is specified",
                timeSeriesTopNError:
                    "The Time Series option cannot be selected when a Limit is specified",
                invalidMetricError:
                    "Metric <b>{0}</b> is not in the list of valid metrics",
                dataOceanLimitError:
                    "Limit field cannot be empty when apply limit is checked",
                dataOceanLimitValueError:
                    "Limit field must be an integer greater than 0",
                invalidDataOceanNode:
                    "Cannot configure this data node. Try deleting the node and then re-configure.",
                noDataOrTransformNode:
                    "The Runbook has no data query or transform nodes",
                dataOceanNodeCountExceeded:
                    "The Runbook cannot have more than 100 data query nodes",
                unexpectedError:
                    "An error was encountered when validating the data query node",
                noFilterForTrigger:
                    "There are no filters that correspond to this trigger",
                noFilterForConnectedNode:
                    "There are no filters that correspond to this connected node",
                dataOceanNodeUnknownNode:
                    "Cannot connect this node to the data query node",
                dataOceanDataSourceMissing:
                    "One or more vantage points that have been deleted are used in the filters",
            },
            priorityNode: {
                priorityNodeOneParent:
                    "Priority nodes can only have one parent node",
                priorityNodeNonDataOrTriggerParent:
                    "Priority nodes can only have query, logical, trigger, subflow, variable or http nodes as parents",
            },
            tagNode: {
                tagNodeOneParent:
                    "Impact assessment nodes can only have one parent node",
                tagNodeNonDataParent:
                    "Impact assessment nodes can only have query, logical or trigger nodes as parents",
                tagNodeInterfaceOrDeviceOrLocationTriggerWithWrongTag:
                    "An interface issue, device issue or location issue can only be tagged as an affected location",
                tagNodeApplicationTriggerWithWrongTag:
                    "An application issue can only be tagged as an affected application or location",
                tagNodeWebhookTrigger:
                    "Impact assessment nodes cannot be hooked up to the webhook trigger",
                tagNodeUnknownTrigger:
                    "This trigger cannot be tagged with this tag",
                tagNodeDoNodeWithWrongLocationTag:
                    "A location tag can only be connected to a data query that returns locations",
                tagNodeDoNodeWithWrongApplicationTag:
                    "An application tag can only be connected to a data query that returns applications",
                tagNodeDoNodeWithWrongUserTag:
                    "A user tag can only be connected to a data query that returns client IPs",
                tagNodeXformNodeWithWrongLocationTag:
                    "A location tag can only be connected to a transform that returns locations",
                tagNodeXformNodeWithWrongApplicationTag:
                    "An application tag can only be connected to a transform that returns applications",
                tagNodeXformNodeWithWrongUserTag:
                    "A user tag can only be connected to a transform that returns client IPs",
                tagNodeUnknownDataOcean:
                    "This data query cannot be tagged with this tag",
                tagNodeUnknownLogical:
                    "This decision branch (Version 1.0) cannot be tagged with this tag",
            },
            chartNode: {
                noChartNode:
                    "The Runbook has no visualization node.  At least one visualization is needed to display a runbook",
                chartNodeOneParent:
                    "This visualization node can only have one parent node",
                cardNodeOneOrTwoParents:
                    "Cards can only have one or two parent nodes",
                cardNodeQueryMismatch:
                    "When cards have two queries passing it data, they must be the same query type",
                cardNodeTimeSeriesMismatch:
                    "When cards have two queries passing it data, one must be a summary query and one must be a time series query",
                cardNodeMetricMissing:
                    "All queries connected to cards must have a metric list",
                cardNodeMetricMismatch:
                    "When cards have two queries passing it data, the metric list must be the same in each query",
                chartNodeNonDataOrHttpParent:
                    "Visualization nodes can only have query nodes, logical nodes, or transform nodes as parents",
                tableNodeNonDataOrHttpParent:
                    "Table nodes can only have query nodes, logical nodes, transform nodes, or trigger nodes as parents",
                chartNodeTimeSeriesDataParent:
                    "A time chart can only be connected to a query with time series data",
                chartNodeSummaryDataParent:
                    "This visualization can only be connected to a query with summarized data",
                connectionGraphTimeSeriesDataParent:
                    "A connection graph can only be connected to a summary query",
                connectionGraphClientServerDataParent:
                    "A connection graph can only be connected to a client/server query",
                noMetrics: "Please select at least one chart metric",
                noMetric: "Please select one chart metric",
                noSizeMetric: "Please select one chart size metric",
                noColorMetric: "Please select one chart color metric",
                noXAxisMetric: "Please select one chart x-axis metric",
                noYAxisMetric: "Please select one chart y-axis metric",
            },
            logicNode: {
                inputError:
                    "Invalid input type selected in a decision branch (Version 1.0) node.",
                editorInputError: "Please select an Input Type.",
                expError:
                    "Please provide at least one expression in a decision branch (Version 1.0) node.",
                editorExpError: "Please provide at least one expression.",
                editorInvalidExp:
                    "One or more of your expressions is invalid or incomplete. Please fix them.",
                invalidExp:
                    "One or more of your expressions is invalid in a decision branch (Version 1.0) node.",
                incompatibleDataType:
                    "Data query node and the decision branch (Version 1.0) node have incompatible metric type.",
                incompatibleInput:
                    "Data query node and the decision branch (Version 1.0) node have incompatible Input type.",
                incompatibleMetric:
                    "The decision branch (Version 1.0) node has a metric that is not in the data query node.",
                incompatibleKey:
                    "The decision branch (Version 1.0) node has a property that is not in the data query node.",
                noParentDONode:
                    "Decision branch (Version 1.0) node needs to be connected to a data query node.",
                unexpectedError:
                    "An error was encountered when validating the decision branch node",
                logicalNodeUnknownNode:
                    "Cannot connect this node to the decision branch (Version 1.0) node",
            },
            decisionNode: {
                expError:
                    "Please provide at least one expression in a decision branch node.",
                noParentDONode:
                    "Decision branch node needs to be connected to a data query node.",
                incompatibleParentNode:
                    "Decision branch node needs to be connected to a trigger, data query, HTTP, transform, variable, subflow or another decision branch node.",
                missingCategory:
                    "One of the expressions in the decision branch node does not have a metric or property selected.",
                missingKey:
                    "One of the expressions in the decision branch node does not have metric or property selected.",
                missingOperator:
                    "One of the expressions in the decision branch node does not have an operator selected.",
                missingValue:
                    "One of the expressions in the decision branch node does not have a value specified.",
                incompatibleMetric:
                    "The decision branch node has a metric that is not supported by it's parent node.",
                incompatibleRuntimeVariable:
                    "The decision branch node uses a runtime ariable that is not currently defined.",
                incompatibleIncidentVariable:
                    "The decision branch node uses an incident variable that is not currently defined.",
                incompatibleSubflowVariable:
                    "The decision branch node uses a subflow variable that is not currently defined.",
                incompatibleKey:
                    "The decision branch node has a property that is not supported by it's parent node.",
                incompatibleDoDataType:
                    "Data query node and the decision branch node have incompatible metric type.",
                incompatibleDoInput:
                    "Data query node and the decision branch node have incompatible Input type.",
                incompatibleDoMetric:
                    "The decision branch node has a metric that is not in the data query node.",
                incompatibleDoKey:
                    "The decision branch node has a property that is not in the data query node.",
                incompatibleTriggerMetric:
                    "The decision branch node has a metric that is not in the trigger node.",
                incompatibleTriggerKey:
                    "The decision branch node has a property that is not in the trigger node.",
                incompatibleHttpMetric:
                    "The decision branch node has a metric that is not in the HTTP node.",
                incompatibleHttpKey:
                    "The decision branch node has a property that is not in the HTTP node.",
                incompatibleTransformDataType:
                    "Transform node and the decision branch node have incompatible metric type.",
                incompatibleTransformMetric:
                    "The decision branch node has a metric that is not in the transform node.",
                incompatibleTransformKey:
                    "The decision branch node has a property that is not in the transform node.",
                incompatibleSubflowMetric:
                    "The decision branch node has a metric that is not in the subflow node.",
                incompatibleSubflowKey:
                    "The decision branch node has a property that is not in the subflow node.",
                unexpectedError:
                    "An error was encountered when validating the decision branch node",
                incompatibleDataSource:
                    "One or more vantage points that have been deleted are used in the decision branch node",
            },
            aggregateNode: {
                groupByKey: "Please select a group by key.",
                noMetric: "Please include at least one metric to group by.",
                noParentDONode:
                    "Aggregator node needs to be connected to a data query, transform, or subflow node.",
                timeseriesNotSupported:
                    "Aggregator node can only be connected to data queries that capture summarized data. Timeseries data is not supported.",
                logicNodeChildNotSupported:
                    "Decision branch (Version 1.0) cannot be connected to an aggregator node as of now. The feature will be added soon.",
                unexpectedError:
                    "An error was encountered when validating the aggregate node",
            },
            tableNode: {
                noColumn: "Please select at least one table column",
                noSortColumn: "Please select a sort column",
                noSortOrder: "Please select a sort order",
            },
            httpNode: {
                httpNodeOneParent: "Http nodes can only have one parent node",
                httpNodeNonTransformParent:
                    "Http nodes can only have query, logical, trigger, http, subflow or set primitive variable nodes as parents",
                urlEmptyUserInput: "Value for URL is required",
                contentTypeEmptyUserInput:
                    "Value for Content-Type Header is required",
                acceptEmptyUserInput: "Value for Accept Header is required",
                alluvioEdgeInput: "Value for Edge is required",
                unexpectedError:
                    "An error was encountered when validating the http query node",
                invalidUrl: "The URL you entered is not valid.  If you are using variables or script within the URL field you can ignore this warning.",
                invalidUrlVariable: "Variable {0} does not exist.",
                nodeNotConfigured: "The HTTP Request node was not configured",
                liquidTemplateEmptyUserInput:
                    "Value for liquid template is required",
                timeEmptyUserInput: "Value for time is required",
                timeValueLimits:
                    "Value for time should be between 1 minute and 7 days",
                additionalHeaderWithEmptyKeyOrValue: "An additional header can't have an empty key or value",
            },
            transformNode: {
                transformNodeOneParent:
                    "Transform nodes can only have one parent node",
                transformNodeNonHttpOrDecisionOrTriggerParent:
                    "Transform nodes can only have Http nodes, decision branch, variable, or trigger nodes as parents",
                transformTemplate: "Please define the transform template.",
                outputDataFormat: "Please select an output data format.",
                oneKeyOrMetric: "Please define at least one key or metric.",
                tooManyKeysOrMetrics:
                    "The maximum number of keys or metrics is exceeded",
                labelInDataOcean:
                    "The selected name should not match any existing data query name.",
                keysNotInSyncWithMetadata:
                    "Some keys might be missing from the properties.",
                unexpectedError:
                    "An error was encountered when validating the transform node",
                varToLoadFrom: "Please select a variable where to load from.",
                emptyVariable:
                    "The transform node uses an output data property that is not currently defined.",
                outputDataPropertyMissingName: "The property must have a name.",
                incompatibleRuntimeVariable:
                    "The transform node uses a runtime variable that is not currently defined.",
                incompatibleIncidentVariable:
                    "The transform node uses an incident variable that is not currently defined.",
                incompatibleSubflowVariable:
                    "The transform node uses a subflow variable that is not currently defined.",
                incompatibleKeysOrMetrics:
                    "The transform node uses properties or metrics that are not currently present in the variable definition.",
            },
            variablesNode: {
                variablesNodeOneParent:
                    "Variables nodes can only have one parent node",
                simpleVarsNodeSupportedParents:
                    "Set Primitive Variable nodes can only have decision, HTTP, transform, query, logical, trigger, aggregator, subflow or another variables nodes as parents",
                complexVarsNodeSupportedParents:
                    "Set Structured Variable nodes can only have decision, transform, query, logical, subflow or another variables nodes as parents",
                transformTemplate: "Please define the transform template.",
                variableNameMissing: "The variable name cannot be empty.",
                oneVariableDefined: "Please define at least one variable.",
                setSimpleVarsUnexpectedError:
                    "An error was encountered when validating the Set Primitive Variables node",
                setComplexVarsUnexpectedError:
                    "An error was encountered when validating the Set Structured Variables node",
                incompatibleRuntimeVariable:
                    "The variables node uses a runtime variable that is not currently defined.",
                incompatibleIncidentVariable:
                    "The variables node uses an incident variable that is not currently defined.",
                incompatibleSubflowVariable:
                    "The variables node uses a subflow variable that is not currently defined.",
            },
            subflowInputNode: {
                outputDataFormat: "Please select an output data format.",
                oneKeyOrMetric: "Please define at least one key or metric.",
                tooManyKeysOrMetrics:
                    "The maximum number of keys or metrics is exceeded",
                overlappingInputAndOutputVariables:
                    "The input and output variables cannot overlap.",
                missingInputVariable:
                    "An input variable refers to a variable definition that no longer exists.",
                missingOutputVariable:
                    "An output variable refers to a variable definition that no longer exists.",
                unexpectedError:
                    "An error was encountered when validating the subflow input node",
                labelInDataOcean:
                    "The selected name should not match any existing data query name.",
                keysNotInSyncWithMetadata:
                    "Some keys might be missing from the properties.",
            },
            subflowOutputNode: {
                subflowOutputNodeOneParent:
                    "The subflow output node should not have more than one parent.",
                subflowOutputNodeOneBranch:
                    "A subflow output node must not be connected to more than one branch except for the decision branch.",
                subflowOutputNodeTwoTypes:
                    "All subflow output nodes must have the same output data format, output properties and output metrics.",
            },
            subflowNode: {
                cannotConnectToSubflow:
                    "The subflow cannot be connected to this node.",
                configurationIdMissing:
                    "This subflow node is missing its reference to the subflow definition.",
                oldSubflowVersion:
                    "This subflow node is using version {currentVersion} of the subflow, the latest version is {latestVersion}.",
                unexpectedError:
                    "An error was encountered when validating the subflow node",
            },
            onDemandInputNode: {
                outputDataFormat: "Please select an output data format.",
                oneKeyOrMetric: "Please define at least one key or metric.",
                tooManyKeysOrMetrics:
                    "The maximum number of keys or metrics is exceeded",
                overlappingInputAndOutputVariables:
                    "The input and output variables cannot overlap.",
                missingInputVariable:
                    "An input variable refers to a variable definition that no longer exists.",
                missingOutputVariable:
                    "An output variable refers to a variable definition that no longer exists.",
                unexpectedError:
                    "An error was encountered when validating the on demand input node",
                labelInDataOcean:
                    "The selected name should not match any existing data query name.",
                keysNotInSyncWithMetadata:
                    "Some keys might be missing from the properties.",
            },
            codes: {
                ORCHESTRATOR_CONFLICT:
                    "A runbook with same name '{name}' already exists.",
                ORCHESTRATOR_DUPLICATE_NAME:
                    "A runbook with same name '{name}' already exists.",
                ORCHESTRATOR_VALIDATION_FAILED:
                    "The runbook validation failed.",
                ORCHESTRATOR_UNAUTHORIZED:
                    "You are not not authorized to access this runbook.",
                ORCHESTRATOR_UNPROCESSABLE_ENTITY:
                    "Error saving runbook to backend.",
                ORCHESTRATOR_BAD_REQUEST: "Error saving runbook to backend.",
                ORCHESTRATOR_NOT_FOUND: "The runbook could not be found.",
                ORCHESTRATOR_METHOD_NOT_ALLOWED:
                    "Error saving runbook to backend.",
                ORCHESTRATOR_PRECONDITION_FAILED:
                    "Error saving runbook to backend.",
                ORCHESTRATOR_SELECTOR_RESULT_COUNT_THRESHOLD_EXCEEDED:
                    "Failed to import runbook because the maximum number of keys and/or metrics is exceeded.",
                ORCHESTRATOR_MAPPING_VIOLATION:
                    "A runbook that is used in an Automation mapping cannot be deleted and its trigger cannot be changed.",
                ORCHESTRATOR_TOO_MANY_RUNBOOKS_FOR_THIS_TRIGGER:
                    "You have exceeed the limit of 200 runbooks for this trigger type.",
                ORCHESTRATOR_MAX_LENGTH_EXCEEDED:
                    "The max length was exceeded!",
                ORCHESTRATOR_INVALID_VERSION: "There is already a subflow with version={version} and seriesId={seriesId}."
            },
        },
        warnings: {
            noImpactAssessment:
                "No Impact tagging nodes found! You can save the runbook without it, but we recommend including at least one.",
        },
        operators: {
            oneOf: "is one of",
            nOneOf: "is not one of",
            cont: "contains",
            nCont: "doesn't contain",
            regex: "regex",
            min: "Min",
            max: "Max",
            avg: "Avg",
            equals: "equals",
            nequals: "not equals",
            equalsAny: "equals any",
            nequalsAny: "not equals any",
            exists: "exists",
            nexists: "does not exist",
            changegt: "Changed By",
            incgt: "Increased By",
            decgt: "Decreased By",
            normal: "is normal",
            nnormal: "is not normal",
            expectedhighgt: "is higher than normal",
            expectedhighlt: "is lower than the expected high",
            expectedlowgt: "is higher than the expected low",
            expectedlowlt: "is lower than normal",
            normalTerse: "is",
            nnormalTerse: "is not",
            expectedhighgtTerse: "is higher than",
            expectedhighltTerse: "is lower than the expected high",
            expectedlowgtTerse: "is higher than the expected low",
            expectedlowltTerse: "is lower than",
        },
        values: {
            normal: "Normal",
        },
        graph: {
            horizBtnText: "horizontal layout",
            vertBtnText: "vertical layout",
            refreshBtnText: "Refresh",
            unknownSubflow: "subflow",
        },
        nodeEditor: {
            title: "Edit Node",
            nodeType: "Type",
            subflowOriginalNameLabel: "Original Subflow Name",
            suggestText:
                "Please edit the node properties and click <strong>Done</strong>.",
            suggestTextNoContent: "Select a node in the graph to edit.",
            nameLabel: "Node Label",
            infoLabel: "Description",
            debugLabel: "Debug",
            doneBtnText: "Save & Close",
            cancelBtnText: "Close",
            defDropDownOption: "(Select an option)",
            durationUnderTopBy: "Over The Last",
            durationUnderMetrics: "Collect Data For Last",
            dataQueryReferenceTime: "Data Query Reference Time",
            dataFormat: "Output Data Format",
            outputDataDef: "Output Data Definition",
            outputDataDefMethod: "Output Data Definition Method",
            transformTemplate: "Transform Template",
            inputDataFormat: "Input Data Format",
            inputDataDef: "Input Data Definition",
            inputDataDefMethod: "Input Data Definition Method",
            subflowInputStartingEntity: "Starting Entity",
            subflowInputStartingEntityDesc:
                "This defines the entity passed from the calling runbook.  The entity is sent to the next node(s) in this subflow.",
            subflowInputFromCallerContext:
                "Context Values Received From Caller",
            subflowInputFromCallerContextPlaceHolder:
                "Type the name of the variables received from caller",
            subflowInputFromCallerContextDesc:
                "Subflow variables are used to store values passed from the runbook calling this subflow.",
            subflowInputToCallerContext: "Context Values Sent Back To Caller",
            subflowInputToCallerContextPlaceHolder:
                "Type the name of the variables sent back to caller",
            subflowInputToCallerContextDesc:
                "Subflow variables are used to return values to the runbook calling this subflow.",
            subflowInput: "Input",
            subflowInputType: "Input Type",
            subflowInputTypeDesc:
                "The subflow accepts a Device entity in a summarized data format from the previous node.",
            subflowInputTypeEmpty:
                "This subflow has no definition for the accepted entity passed from the previous node.",
            subflowInputContext: "Input Context",
            subflowInputContextDesc:
                "Context can be either set statically or at runtime via variables.",
            subflowNoInputNode: "This subflow does not have an input node.",
            subflowOutput: "Output",
            subflowOutputType: "Output Type",
            subflowOutputTypeLabel: "Output Type:",
            subflowOutputTypeDesc:
                "The subflow node returns a Custom entity in a time series data format to the next node.",
            subflowOutputUnknownEntity:
                "The subflow node returns an unknown entity type",
            subflowNoOutputNode: "This subflow does not have an output node.",
            subflowOutputContext: "Output Context",
            subflowOutputContextDesc:
                "The subflow node returns additional context that can be optionally stored in variables for later use.",
            subflowOutputAssignRuntimeVariablesTooltip: "Automatically create and assign runtime variables to unassigned outputs",
            subflowOutputAssignRuntimeVariablesButton: "Auto-assign",
            subflowUpdateVersionText: "The latest version is {newVersion}, do you want to update?",
            subflowUpdateVersionButton: "Update",
            subflowUpdateVersionTooltip: "The subflow version is not the latest available.  Click the update button to upgrade to the latest subflow version",
            subflowConnectorContext:
                "Connector",
            subflowConnectorContextDesc:
                "Subflows that are used with integrations can have a connector passed in that provides variables and authentication profiles for later use..",
            subflowVarMapping: {
                staticInputPlaceHolder: "Enter a value",
                selectRuntimeVariableText: "Select a variable",
                selectIncidentVariableText: "Select a variable",
                selectTriggerText: "Select a trigger",
                selectAuthenticationProfileText:
                    "Select an authentication profile",
                selectConnectorText:
                    "Select a connector",
                selectAlluvioEdgeText: "Select an Edge",
                inbound: {
                    name: "Context Parameter",
                    type: "Type",
                    unit: "Unit",
                    method: "Set Method",
                    set: "Set Value",
                },
                outbound: {
                    name: "Name",
                    type: "Value Type",
                    unit: "Unit",
                    method: "Variable Scope",
                    set: "Variable Name",
                },
                triggerKeys: {
                    network_interface: "Interface from Trigger",
                    network_device: "Device from Trigger",
                    application: "Application from Trigger",
                    location: "Location from Trigger",
                    client_location: "Client Location from Trigger",
                    json: "JSON from Trigger",
                    requestBody: "Request Payload from Trigger",
                    requestQueryParameters:
                        "Request Query Parameters from Trigger",
                    requestHeaders: "Request Header from Trigger",
                    requestPath: "Path",
                    createdAt: "Created At from Trigger",
                    runbookStatus: "Runbook Status from Trigger",
                    content: "Content from Trigger",
                    indicators: "Indicators from Trigger",
                    oldStatus: "Old Status from Trigger",
                    status: "New Status from Trigger",
                    endTime: "End Time from Trigger",
                    lastUpdatedAt: "Last Updated At from Trigger",
                },
            },
            onDemandInputStartingEntityDesc:
                "This defines the primary entity passed by the user. The primary entity is sent to the next node(s) in the runbook.",
            onDemandInputToCallerContextDesc:
                "Variables are used to store additional parameter values passed by the user calling this runbook.",
            onDemandInputDataPropertiesTitle: "PRIMARY ENTITY PROPERTIES",
            onDemandInputAdditionalParametersTitle:
                "Additional Parameter Values Received From Caller",
            onDemandInputCreateEditVariable: "Create or edit variable",
            onDemandInputTypeVariableName:
                "Type the name of the variable in the input below:",
            groupBy: "Group By",
            grouping: "Grouping",
            aggregate: "Aggregate",
            metrics: "Metrics",
            allRows: "All Records",
            by: "By",
            allTogether: "All together",
            builtInProperties: "Built-in properties",
            transformTemplatePlaceholder:
                "Enter the transform template to generate output",
            outputDataNameField: "Name",
            outputDataTypeField: "Type",
            outputDataUnitField: "Unit",
            outputDataSection: "OUTPUT DATA ",
            inputDataSection: "INPUT DATA ",
            addButton: "Add ",
            templateTabName: "TEMPLATE",
            exampleTemplateTabName: "OUTPUT DATA EXAMPLE",
            unconnectedNodeMessage1:
                "Connect node to a query node or a decision branch to edit this form.",
            unconnectedNodeMessage2:
                "Metrics will be displayed when node is connected.",
            connectInputMsg: "Connect an input to see all available options!",
            defaultDecisionOutputName: "Default",
            defaultDecisionOutputText:
                "The output to use when none of the other outputs had a match",
            advancedToggleName: "Advanced",
            addConditionLabel: "Add a Condition",
            addOutput: "Add An Output",
            addDefaultOutput: "Add A Default Output",
            multipleValuesHint: "(Comma Separated List)",
            conditionKey: {
                countAll: "Count",
                countMatch: "Count (Matching Only)",
            },
            addValue: "<Click to add value>",
            conditionCategory: {
                metric: "Metrics",
                metricDisp: "Metrics",
                key: "Properties",
                keyDisp: "Properties",
                rows: "Count of Rows",
                rowsDisp: "Input Rows",
                indRowsDisp: "Indicator Rows",
                trigger: "Properties",
                triggerDisp: "Properties",
                triggerMetric: "Metrics",
                triggerMetricDisp: "Metrics",
                triggerGenericMetric: "Trigger",
                triggerGenericMetricDisp: "Trigger",
                http: "Properties",
                httpDisp: "HTTP",
                variable: "Variables",
                variableDisp: "Variable",
                urlParam: "URL Parameters",
                urlParamDisp: "URL Parameters",
            },
            forwardAll: "Forward all input rows to this output",
            forwardMatches:
                "Forward only input rows that match above conditions",
            tooltips: {
                cloneOutput: "Clone This Output",
                removeOutput: "Remove Output",
                nestCondition: "Add a nested block",
                denestCondition: "De-nest this block",
                removeCondition: "Remove Condition",
                requiredForIntegration: "(Required for integration)",
                id: "Id: ",
                type: "Type: ",
                unit: "Unit: ",
                duplicateLabel: "Duplicate label",
            },
            editRawJson: "Edit Raw JSON",
            selectMetricOption: "Select a Metric",
            noMetricOption: "None",
            runbookContext: {
                contextText: "Context",
                propertiesText: "Properties",
                metricsText: "Metrics",
                possibleMetricsText: "Possible Metrics",
                outputSection: "Output",
                advancedSection: "Advanced",
                outputDataExampleHeader: "Output Data Example",
                inputDataExampleHeader: "Input Data Example",
            },
            variables: {
                primitiveVariable: {
                    variablesToSet: "Variables to Set",
                    scopeLabel: "Scope",
                    varNameLabel: "Variable Name",
                    varNamePlaceholder: "Enter variable name...",
                    templateVars: "Template to Assign Value to Variables",
                    templatePlaceholder:
                        "Enter transform template to generate expected output used to assign values to variables above ...",
                    addVariable: "Set Another Variable",
                    infoMessage:
                        "This node is pass-through: all data received is forwarded to the following node.",
                    selectVariable: "Select a variable...",
                },
                scopeOptions: {
                    runbookExecution: "Runbook execution",
                    subflowExecution: "Subflow execution",
                    incidentScope: "Incident scope",
                    globalScope: "Global scope",
                },
                structuredVariable: {
                    variableToSet: "Variable to Set",
                    infoMessage:
                        "This node is pass-through: all data received is forwarded to the following node.",
                },
            },
            subflow: {
                edit: "Edit subflow",
                editWarning:
                    "Any change to the subflow will require refreshing this page.",
            },
            http: {
                authentication: {
                    label: "Authentication",
                    useAuthMethod: "Use Authentication Profile",
                    defineNew: "Define new profile",
                    viewAll: "View all",
                    authMethod: "Authentication Profile",
                    noProfiles:
                        "No active profiles found. Enable or create a profile.",
                },
                endpoint: {
                    label: "Endpoint",
                    hint: "Enter URI with {{variables}}...",
                    sendRequestTo: "Send request to:",
                    publicEndpoint: "Public endpoint",
                    privateEndpoint:
                        "Private endpoint via an authorized Edge: ",
                    privateEndpointHelpText:
                        "The profiles displayed in this list come from the subflow variables",
                    selectAlluvioEdge: "Select Edge",
                },
                headers: {
                    label: "HTTP Headers",
                    contentHint: "Enter content-type header...",
                    contentHeader: "Content-Type Header",
                    acceptHint: "Enter accept header...",
                    acceptHeader: "Accept Header",
                    additionalHint: "Enter additional headers...",
                    additionalHeader: "Additional Headers",
                    headerKey: "Key",
                    headerKeyPlaceholder: "Enter key of additonal header",
                    headerValue: "Value",
                    headerValuePlaceholder: "Enter value",
                    addAdditionalHeader: "Add Additional Header",
                    includeAdditionalHeaders: "Include Additional Headers",
                },
                payload: {
                    label: "HTTP Payload",
                    includePayload: "Include payload",
                    payloadHint: "Enter Liquid template with {{variables}}...",
                },
                retry: {
                    label: "Retry until Ready",
                    retryText1: "Retries until the template below renders ",
                    retryText2: "ready. ",
                    retryText3: "Stops if unsuccessful after: ",
                    retryHint:
                        "Enter Liquid template with {{variables}} to evaluate as ready when final response is ready",
                },
            },
            offsetOptions: {
                noOffset: "no offset",
                fifteenMins: "15 minutes",
                thirtyMins: "30 minutes",
                fortyfiveMins: "45 minutes",
                sixtyMins: "60 minutes",
            },
            subflowInputNodeEditor: {
                staticValuesListFor: "Allowed values for",
                enterLabel: "Enter label",
                enterValue: "Enter value",
                changeValue: "Change",
                addValue: "Add",
                addedValues: "Added values...",
                enterDescriptions: "Enter descriptions",
                enterDescriptionsModal: {
                    title: "Enter {0} Descriptions",
                    enterForSelected: "Enter description for selected {0}:",
                    select: "Select {0}",
                    placeholder: "Enter the {0} description that will be visible to the user while configuring this subflow in a runbook",
                }
            }
        },
        graphData: {
            suggestText:
                "The tree below shows all the nodes and connections in your runbook.",
            nodesText: "Nodes",
            edgesText: "Edges",
            configNodesText: "Config Nodes",
            NoNameText: "No Name Defined",
            output: "Output",
        },
        graphHelp: {
            configurationLabel: "Configuration",
            inputsLabel: "Inputs",
            outputsLabel: "Outputs",
        },
        graphErrors: {
            suggestText:
                "All the errors or warnings in your {variant} are shown below.",
            hideNodeErrors: "Hide node errors",
            showNodeErrors: "Show node errors",
            generalText: "General Warnings/Errors",
            nodesText: "{variant} Node Warnings/Errors",
        },
        dnd: {
            title: "{variant} Nodes",
            suggestText:
                "Double-click or Drag these nodes to add to the work area.",
            hints: {
                triggers:
                    "Every runbook needs a <b>Trigger</b> to start its execution.<br/>Drag & drop one of these Trigger nodes to the work area.",
                subflowTriggers:
                    "Every subflow needs a <b>Subflow Input</b> to start its execution.<br/>Drag & drop the Input node to the work area and update the <b>Input Data Properties</b> to declare what type of data the subflow is expecting.",
                onDemandTriggers:
                    "Every on-demand runbook needs an <b>Input</b> to start its execution.<br/>Drag & drop the Input node to the work area and update the <b>Starting Entity</b> to declare what type of data the runbook is expecting.",
                dataOcean:
                    "<b>Data Query</b> nodes are for fetching data from other Riverbed products. After adding one of these nodes, connect the previous node's output to this node's input.",
                rvbdCharts:
                    "Add <b>Visualization</b> nodes to display Data Query node's output in the generated analysis report.",
                impactAssessment:
                    "Indicate the impacts of the incident which triggered this runbook with these nodes.",
                subflowOutput:
                    "<b>Subflow Output</b> nodes are used to declare which data will be passed out of the subflow (this is optional).  If you want to hook a decision branch to a subflow you will need this.",
            },
        },
        toolbar: {
            runbookLabelText: "Runbook:",
            resetTitle: "Reset",
            resetWarningText:
                "Clear all unsaved changes and reset to initial state?",
            cancelBtnText: "Cancel",
            yesBtnText: "Yes",
            unnamed: "Unnamed",
            autoConnect: "Auto-Connect",
            autoLayout: "Auto-Layout",
            tooltips: {
                new: "New Runbook",
                save: "Save",
                saveHint: "Ctrl/Cmd + S",
                reset: "Reset",
                cancel: "Cancel",
                clear: "Clear",
                delete: "Delete",
                zoomIn: "Zoom In",
                zoomOut: "Zoom Out",
                resetView: "Reset View",
                fitToScreen: "Fit To Screen",
                viewOutput: "Preview Output With Mock Data",
                viewVariables: "Edit the variables used in the runbook",
                testOutput: "Execute a Test Run",
                execute: "Execute",
                import: "Import",
                export: "Export",
                horizontalLayout: "Horizontal Layout",
                verticalLayout: "Vertical Layout",
                layout: "Beautify",
                edit: "Edit Runbook's Name & Description",
                undo: "Undo",
                undoHint: "Ctrl/Cmd + Z",
                redo: "Redo",
                redoHint: "Ctrl + Y or Cmd + Shift + Z",
                multiSelect:
                    "Select multiple nodes by drawing a box around them.",
                multiSelectHint: "Shortcut: Hold down Shift Key",
                autoConnect: "Toggle Auto-Connection of newly added nodes",
                videoDialogTitle: "Runbook Editor Video Tour",
            },
        },
        nodeLibrary: {
            categories: {
                triggers: "Triggering Entity",
                subflowTriggers: "Subflow Input",
                subflowOutputs: "Subflow Output",
                lifecycleTriggers: "Triggers",
                inputs: "Inputs",
                dataOcean: "Data Queries",
                common: "Miscellaneous",
                logic: "Logic",
                function: "Functions",
                variables: "Variables",
                network: "Network",
                integrations: "Integrations",
                sequence: "Sequence",
                parser: "Parser",
                storage: "Storage",
                profiler: "Profiler",
                rvbdDashboard: "RVBD Dashboard",
                rvbdCharts: "Visualizations",
                impactAssessment: "Impacts",
                test: "Test",
                subflows: "Subflows",
                unknown: "Unknown",
            },
            nodes: {
                trigger: {
                    name: "Trigger Node",
                    help: "Trigger nodes are the first node in all runbooks.",
                    configurationHelp:
                        "Trigger nodes have no configurable properties",
                    inputsHelp: "There are no inputs for trigger nodes",
                    outputsHelp:
                        "Trigger nodes pass along the trigger object, either an interface, device or application",
                    subTypes: {
                        NetworkInterface: {
                            name: "Interface",
                            help: "This node should be used to start all runbooks that have an interface trigger.",
                            outputsHelp:
                                "The interface trigger node outputs the interface that triggered the incident",
                            editorHint:
                                "This node starts running when anomalous behavior is detected on an Interface.",
                            docLink: "runbook_node_trigger_interface.htm",
                        },
                        NetworkDevice: {
                            name: "Device[s]",
                            help: "This node should be used to start all runbooks that have an device trigger.",
                            editorHint:
                                "This node starts running when a possible Device issue is detected.",
                            docLink: "runbook_node_trigger_interface.htm",
                        },
                        Application: {
                            name: "Application",
                            help: "This node should be used to start all runbooks that have an application trigger.",
                            editorHint:
                                "This node starts running when an App displays possible degradation in performance.",
                            docLink: "runbook_node_trigger_application.htm",
                        },
                        Location: {
                            name: "Location",
                            help: "This node should be used to start all runbooks that have an location trigger.",
                            editorHint:
                                "This node starts running when an Location displays possible degradation in performance.",
                        },
                        Webhook: {
                            name: "Webhook",
                            help: "This node should be used to start all runbooks that are going to be invoked by a webhook.",
                            editorHint:
                                "This node starts running when invoked by a webhook.",
                        },
                        RunbookCompleted: {
                            name: "Runbook Completed",
                            help: "",
                            outputsHelp: "",
                            editorHint: "",
                            docLink: "",
                        },
                        StatusChanged: {
                            name: "Status Changed",
                            help: "",
                            outputsHelp: "",
                            editorHint: "",
                            docLink: "",
                        },
                        NoteAdded: {
                            name: "Note Added",
                            help: "",
                            outputsHelp: "",
                            editorHint: "",
                            docLink: "",
                        },
                        NoteUpdated: {
                            name: "Note Updated",
                            help: "",
                            outputsHelp: "",
                            editorHint: "",
                            docLink: "",
                        },
                        NoteDeleted: {
                            name: "Note Deleted",
                            help: "",
                            outputsHelp: "",
                            editorHint: "",
                            docLink: "",
                        },
                        IndicatorsUpdated: {
                            name: "Indicators Updated",
                            help: "",
                            outputsHelp: "",
                            editorHint: "",
                            docLink: "",
                        },
                        OngoingStateChanged: {
                            name: "Ongoing State Changed",
                            help: "",
                            outputsHelp: "",
                            editorHint: "",
                            docLink: "",
                        },
                    },
                },
                lifecycleTrigger: {
                    name: "Trigger Node",
                    help: "Trigger nodes are the first node in all runbooks.",
                    configurationHelp:
                        "Trigger nodes have no configurable properties",
                    inputsHelp: "There are no inputs for trigger nodes",
                    outputsHelp:
                        "Trigger nodes pass along the trigger object, either an interface, device or application",
                    subTypes: {
                        RunbookCompleted: {
                            name: "Runbook Completed",
                            help: "",
                            outputsHelp: "",
                            editorHint: "",
                            docLink: "",
                        },
                        StatusChanged: {
                            name: "Status Changed",
                            help: "",
                            outputsHelp: "",
                            editorHint: "",
                            docLink: "",
                        },
                        NoteAdded: {
                            name: "Note Added",
                            help: "",
                            outputsHelp: "",
                            editorHint: "",
                            docLink: "",
                        },
                        NoteUpdated: {
                            name: "Note Updated",
                            help: "",
                            outputsHelp: "",
                            editorHint: "",
                            docLink: "",
                        },
                        IndicatorsUpdated: {
                            name: "Indicators Updated",
                            help: "",
                            outputsHelp: "",
                            editorHint: "",
                            docLink: "",
                        },
                        OngoingStateChanged: {
                            name: "Ongoing State Changed",
                            help: "",
                            outputsHelp: "",
                            editorHint: "",
                            docLink: "",
                        },
                    },
                },
                input: {
                    name: "Input Node",
                    help: "Input nodes are the first node in all runbooks.",
                    configurationHelp:
                        "Input nodes have no configurable properties",
                    inputsHelp: "There are no inputs for trigger nodes",
                    outputsHelp:
                        "Trigger nodes pass along the trigger object, either an interface, device or application",
                    subTypes: {
                        NetworkInterface: {
                            name: "Interface",
                            help: "This node should be used to start all runbooks that have an interface trigger.",
                            outputsHelp:
                                "The interface trigger node outputs the interface that triggered the incident",
                            editorHint:
                                "This node starts running when anomalous behavior is detected on an Interface.",
                            docLink: "runbook_node_trigger_interface.htm",
                        },
                        NetworkDevice: {
                            name: "Device",
                            help: "This node should be used to start all runbooks that have an device trigger.",
                            editorHint:
                                "This node starts running when a possible Device issue is detected.",
                            docLink: "runbook_node_trigger_interface.htm",
                        },
                        Application: {
                            name: "Application",
                            help: "This node should be used to start all runbooks that have an application trigger.",
                            editorHint:
                                "This node starts running when an App displays possible degradation in performance.",
                            docLink: "runbook_node_trigger_application.htm",
                        },
                        Location: {
                            name: "Location",
                            help: "This node should be used to start all runbooks that have an location trigger.",
                            editorHint:
                                "This node starts running when an Location displays possible degradation in performance.",
                        },
                    },
                },
                on_demand_input: {
                    name: "Input",
                    help: "This node is used to describe the input to an on-demand runbook.",
                    docLink: "runbook_node_function_aggregator.htm",
                },
                subflow_input: {
                    name: "Subflow Input",
                    help: "This node is used to describe the input to a subflow.",
                    docLink: "runbook_node_function_aggregator.htm",
                },
                subflow_output: {
                    name: "Subflow Output",
                    help: "This node allows you to define where the runbook output is generated.",
                    docLink: "runbook_node_function_aggregator.htm",
                },
                data_ocean: {
                    name: "Data Query",
                    filters: {
                        network_interface: {
                            fieldType: "select",
                            triggerOption: "Interface from trigger",
                            subflowInputOption: "Interface from subflow input",
                            onDemandInputOption:
                                "Interface from on-demand input",
                            connectedNode: "Interface from previous node",
                            connectedNodeClosestParent:
                                "Interface from closest parent",
                            connectedNodeAnyNode: 'Interface from "{0}" node',
                            userInput: "Specific interfaces",
                            userInputPlaceHolder:
                                "List of Interfaces (Comma Separated)",
                            variableOption: 'Interface from "{0}" variable',
                        },
                        connected_interface: {
                            fieldType: "select",
                            triggerOption: "Connected Interface from trigger",
                            subflowInputOption:
                                "Connected Interface from subflow input",
                            onDemandInputOption:
                                "Connected Interface from on-demand input",
                            connectedNode:
                                "Connected Interface from previous node",
                            connectedNodeClosestParent:
                                "Connected Interface from closest parent",
                            connectedNodeAnyNode:
                                'Connected Interface from "{0}" node',
                            userInput: "Specific Connected Interfaces",
                            userInputPlaceHolder:
                                "List of Connected Interfaces (Comma Separated)",
                            variableOption:
                                'Connected Interface from "{0}" variable',
                        },
                        application: {
                            fieldType: "select",
                            triggerOption: "Apps from trigger",
                            subflowInputOption: "Apps from subflow input",
                            onDemandInputOption: "Apps from on-demand input",
                            connectedNode: "Apps from previous node",
                            connectedNodeClosestParent:
                                "Apps from closest parent",
                            connectedNodeAnyNode: 'Apps from "{0}" node',
                            userInput: "Specific Apps",
                            userInputPlaceHolder:
                                "List of Apps (Comma Separated)",
                            variableOption: 'Apps from "{0}" variable',
                        },
                        network_device: {
                            fieldType: "select",
                            triggerOption: "Devices from trigger",
                            subflowInputOption: "Devices from subflow input",
                            onDemandInputOption: "Devices from on-demand input",
                            connectedNode: "Devices from previous node",
                            connectedNodeClosestParent:
                                "Devices from closest parent",
                            connectedNodeAnyNode: 'Devices from "{0}" node',
                            userInput: "Specific Devices",
                            userInputPlaceHolder:
                                "List of Devices (Comma Separated)",
                            variableOption: 'Devices from "{0}" variable',
                        },
                        connected_device: {
                            fieldType: "select",
                            triggerOption: "Connected Devices from trigger",
                            subflowInputOption:
                                "Connected Devices from subflow input",
                            onDemandInputOption:
                                "Connected Devices from on-demand input",
                            connectedNode:
                                "Connected Devices from previous node",
                            connectedNodeClosestParent:
                                "Connected Devices from closest parent",
                            connectedNodeAnyNode:
                                'Connected Devices from "{0}" node',
                            userInput: "Specific Connected Devices",
                            userInputPlaceHolder:
                                "List of Connected Devices (Comma Separated)",
                            variableOption:
                                'Connected Devices from "{0}" variable',
                        },
                        network_host: {
                            fieldType: "select",
                            triggerOption: "Hosts from trigger",
                            subflowInputOption: "Hosts from subflow input",
                            onDemandInputOption: "Hosts from on-demand input",
                            connectedNode: "Hosts from previous node",
                            connectedNodeClosestParent:
                                "Hosts from closest parent",
                            connectedNodeAnyNode: 'Hosts from "{0}" node',
                            userInput: "Specific Hosts",
                            userInputPlaceHolder:
                                "List of Hosts (Comma Separated)",
                            variableOption: 'Hosts from "{0}" variable',
                        },
                        network_client: {
                            fieldType: "select",
                            triggerOption: "Client Hosts from trigger",
                            subflowInputOption:
                                "Client Hosts from subflow input",
                            onDemandInputOption:
                                "Client Hosts from on-demand input",
                            connectedNode: "Client Hosts from previous node",
                            connectedNodeClosestParent:
                                "Client Hosts from closest parent",
                            connectedNodeAnyNode:
                                'Client Hosts from "{0}" node',
                            userInput: "Specific Client Hosts",
                            userInputPlaceHolder:
                                "List of Client Hosts (Comma Separated)",
                            variableOption: 'Client Hosts from "{0}" variable',
                        },
                        network_server: {
                            fieldType: "select",
                            triggerOption: "Server Hosts from trigger",
                            subflowInputOption:
                                "Server Hosts from subflow input",
                            onDemandInputOption:
                                "Server Hosts from on-demand input",
                            connectedNode: "Server Hosts from previous node",
                            connectedNodeClosestParent:
                                "Server Hosts from closest parent",
                            connectedNodeAnyNode:
                                'Server Hosts from "{0}" node',
                            userInput: "Specific Server Hosts",
                            userInputPlaceHolder:
                                "List of Server Hosts (Comma Separated)",
                            variableOption: 'Server Hosts from "{0}" variable',
                        },
                        user_application: {
                            fieldType: "select",
                            connectedNode:
                                "User Applications from previous node",
                            connectedNodeClosestParent:
                                "User Applications from closest parent",
                            connectedNodeAnyNode:
                                'User Applications from "{0}" node',
                            userInput: "Specific User Applications",
                            userInputPlaceHolder:
                                "List of User Apps (Comma Separated)",
                            variableOption:
                                'User Applications from "{0}" variable',
                        },
                        user_device: {
                            fieldType: "select",
                            connectedNode: "User Devices from previous node",
                            connectedNodeClosestParent:
                                "User Devices from closest parent",
                            connectedNodeAnyNode:
                                'User Devices from "{0}" node',
                            userInput: "Specific User Devices",
                            userInputPlaceHolder:
                                "List of User Devices (Comma Separated)",
                            variableOption: 'User Devices from "{0}" variable',
                        },
                        business_location: {
                            fieldType: "select",
                            connectedNode: "Locations from previous node",
                            connectedNodeClosestParent:
                                "Locations from closest parent",
                            connectedNodeAnyNode: 'Locations from "{0}" node',
                            userInput: "Specific Locations",
                            userInputPlaceHolder:
                                "List of Locations (Comma Separated)",
                            variableOption: 'Locations from "{0}" variable',
                        },
                        device_type: {
                            fieldType: "select",
                            connectedNode: "Device Types from previous node",
                            connectedNodeClosestParent:
                                "Device Types from closest parent",
                            connectedNodeAnyNode:
                                'Device Types from "{0}" node',
                            userInput: "Specific Device Types",
                            userInputPlaceHolder:
                                "List of Device Types (Comma Separated)",
                            variableOption: 'Device Types from "{0}" variable',
                        },
                        client: {
                            fieldType: "select",
                            connectedNode: "Clients from previous node",
                            connectedNodeClosestParent:
                                "Clients from closest parent",
                            connectedNodeAnyNode: 'Clients from "{0}" node',
                            userInput: "Specific clients",
                            userInputPlaceHolder:
                                "List of Clients (Comma Separated)",
                            variableOption: 'Clients from "{0}" variable',
                        },
                        location_country: {
                            fieldType: "select",
                            connectedNode: "Countries from previous node",
                            connectedNodeClosestParent:
                                "Countries from closest parent",
                            connectedNodeAnyNode: 'Countries from "{0}" node',
                            userInput: "Specific Countries",
                            userInputPlaceHolder:
                                "List of Countries (Comma Separated)",
                            variableOption: 'Countries from "{0}" variable',
                        },
                        location_city: {
                            fieldType: "select",
                            connectedNode: "Cities from previous node",
                            connectedNodeClosestParent:
                                "Cities from closest parent",
                            connectedNodeAnyNode: 'Cities from "{0}" node',
                            userInput: "Specific Cities",
                            userInputPlaceHolder:
                                "List of Cities (Comma Separated)",
                            variableOption: 'Cities from "{0}" variable',
                        },
                        user: {
                            fieldType: "select",
                            connectedNode: "Users from previous node",
                            connectedNodeClosestParent:
                                "Users from closest parent",
                            connectedNodeAnyNode: 'Users from "{0}" node',
                            userInput: "Specific Users",
                            userInputPlaceHolder:
                                "List of Users (Comma Separated)",
                            variableOption: 'Users from "{0}" variable',
                        },
                        physical_location: {
                            fieldType: "select",
                            triggerOption: "Physical Locations from trigger",
                            subflowInputOption:
                                "Physical Locations from subflow input",
                            onDemandInputOption:
                                "Physical Locations from on-demand input",
                            connectedNode:
                                "Physical Locations from previous node",
                            connectedNodeClosestParent:
                                "Physical Locations from closest parent",
                            connectedNodeAnyNode:
                                'Physical Locations from "{0}" node',
                            userInput: "Specific Physical Locations",
                            userInputPlaceHolder:
                                "List of Physical Locations (Comma Separated)",
                            variableOption:
                                'Physical Locations from "{0}" variable',
                        },
                        location: {
                            fieldType: "select",
                            triggerOption: "Locations from trigger",
                            subflowInputOption: "Locations from subflow input",
                            onDemandInputOption:
                                "Locations from on-demand input",
                            connectedNode: "Locations from previous node",
                            connectedNodeClosestParent:
                                "Locations from closest parent",
                            connectedNodeAnyNode: 'Locations from "{0}" node',
                            userInput: "Specific Locations",
                            userInputPlaceHolder:
                                "List of Locations (Comma Separated)",
                            variableOption: 'Locations from "{0}" variable',
                        },
                        client_location: {
                            fieldType: "select",
                            triggerOption: "Client Locations from trigger",
                            subflowInputOption:
                                "Client Locations from subflow input",
                            onDemandInputOption:
                                "Client Locations from on-demand input",
                            connectedNode:
                                "Client Locations from previous node",
                            connectedNodeClosestParent:
                                "Client Locations from closest parent",
                            connectedNodeAnyNode:
                                'Client Locations from "{0}" node',
                            userInput: "Specific Client Locations",
                            userInputPlaceHolder:
                                "List of Client Locations (Comma Separated)",
                            variableOption:
                                'Client Locations from "{0}" variable',
                        },
                        server_location: {
                            fieldType: "select",
                            triggerOption: "Server Locations from trigger",
                            subflowInputOption:
                                "Server Locations from subflow input",
                            onDemandInputOption:
                                "Server Locations from on-demand input",
                            connectedNode:
                                "Server Locations from previous node",
                            connectedNodeClosestParent:
                                "Server Locations from closest parent",
                            connectedNodeAnyNode:
                                'Server Locations from "{0}" node',
                            userInput: "Specific Server Locations",
                            userInputPlaceHolder:
                                "List of Server Locations (Comma Separated)",
                            variableOption:
                                'Server Locations from "{0}" variable',
                        },
                        protocol: {
                            fieldType: "select",
                            triggerOption: "Protocols from trigger",
                            subflowInputOption: "Protocols from subflow input",
                            onDemandInputOption:
                                "Protocols from on-demand input",
                            connectedNode: "Protocols from previous node",
                            connectedNodeClosestParent:
                                "Protocols from closest parent",
                            connectedNodeAnyNode: 'Protocols from "{0}" node',
                            userInput: "Specific Protocols",
                            userInputPlaceHolder:
                                "List of Protocols (Comma Separated)",
                            variableOption: 'Protocols from "{0}" variable',
                        },
                        protoport: {
                            fieldType: "select",
                            connectedNode: "Protoports from previous node",
                            connectedNodeClosestParent:
                                "Protoports from closest parent",
                            connectedNodeAnyNode: 'Protoports from "{0}" node',
                            userInput: "Specific Protoports",
                            userInputPlaceHolder:
                                "List of Protoports (Comma Separated)",
                            variableOption: 'Protoports from "{0}" variable',
                        },
                        dscp: {
                            fieldType: "select",
                            triggerOption: "DSCPs from trigger",
                            subflowInputOption: "DSCPs from subflow input",
                            onDemandInputOption: "DSCPs from on-demand input",
                            connectedNode: "DSCPs from previous node",
                            connectedNodeClosestParent:
                                "DSCPs from closest parent",
                            connectedNodeAnyNode: 'DSCPs from "{0}" node',
                            userInput: "Specific DSCPs",
                            userInputPlaceHolder:
                                "List of DSCPs (Comma Separated)",
                            variableOption: 'DSCPs from "{0}" variable',
                        },
                        expression: {
                            fieldType: "text",
                        },
                        network_client_server: {
                            fieldType: "select",
                            connectedNode:
                                "Client Server Host Pairs from previous node",
                            connectedNodeClosestParent:
                                "Client Server Host Pairs from closest parent",
                            connectedNodeAnyNode:
                                'Client Server Host Pairs from "{0}" node',
                            userInput: "Specific Client Server Host Pairs",
                            userInputPlaceHolder:
                                "List of Client Server Host Pairs (Comma Separated)",
                            variableOption:
                                'Client Server Host Pairs from "{0}" variable',
                        },
                        client_server_location: {
                            fieldType: "select",
                            connectedNode:
                                "Client Server Location Pairs from previous node",
                            connectedNodeClosestParent:
                                "Client Server Location Pairs from closest parent",
                            connectedNodeAnyNode:
                                'Client Server Location Pairs from "{0}" node',
                            userInput: "Specific Client Server Location Pairs",
                            userInputPlaceHolder:
                                "List of Client Server Location Pairs (Comma Separated)",
                            variableOption:
                                'Client Server Location Pairs from "{0}" variable',
                        },
                        network_client_server_protoport: {
                            fieldType: "select",
                            connectedNode:
                                "Client Server Host Pairs With Protoport from previous node",
                            connectedNodeClosestParent:
                                "Client Server Host Pairs With Protoport from closest parent",
                            connectedNodeAnyNode:
                                'Client Server Host Pairs With Protoport from "{0}" node',
                            userInput:
                                "Specific Client Server Host Pairs With Protoport",
                            userInputPlaceHolder:
                                "List of Client Server Host Pairs With Protoport (Comma Separated)",
                            variableOption:
                                'Client Server Host Pairs With Protoport from "{0}" variable',
                        },
                        data_source: {
                            fieldType: "select",
                            triggerOption: "Vantage Point from trigger",
                            subflowInputOption:
                                "Vantage Point from subflow input",
                            onDemandInputOption:
                                "Vantage Point from on-demand input",
                            connectedNode: "Vantage Point from previous node",
                            connectedNodeClosestParent:
                                "Vantage Point from closest parent",
                            connectedNodeAnyNode:
                                'Vantage Point from "{0}" node',
                            userInput: "Specific vantage point",
                            userInputPlaceHolder:
                                "List of Vantage Point (Comma Separated)",
                            variableOption: 'Vantage Point from "{0}" variable',
                            dataSourceSelect: "Specific Vantage Points",
                            dataSourceSelectPlaceHolder:
                                "List of Data Sources (Comma Separated)",
                            allDataSource: "All Vantage Points",
                        },
                    },
                    help: "This node queries the <b>Riverbed</b> point products for data and can then pass that data to charts or tables.",
                    labels: {
                        filtersSection: "Filters",
                        groupBySection: "Group By",
                        limitsSection: "Limit",
                        metricsSection: "Metrics",
                        metricsToggle: "Collect Metrics",
                        keysSection: "Properties",
                        dataFormatSection: "Output Data Example",
                    },
                    subTypes: {
                        IfcTrf: {
                            name: "Network Interfaces",
                            help: "This node queries interface data from the <b>Riverbed</b> point products for data and can then pass that data to charts or tables.",
                            output: "Interfaces",
                            editorHint:
                                "This node outputs a list of Interfaces (and related metrics if specified)",
                            docLink:
                                "runbook_node_data_query_network_interfaces.htm",
                        },
                        IfcErr: {
                            name: "Network Interfaces (Error Metrics)",
                            help: "This node queries interface data from the <b>Riverbed</b> point products for data and can then pass that data to charts or tables.",
                            output: "Interfaces",
                            editorHint:
                                "This node outputs a list of Interfaces and related interface error metrics",
                            docLink:
                                "runbook_node_data_query_network_interface_error_metrics.htm",
                        },
                        IfcPerf: {
                            name: "Interfaces (Performance)",
                            help: "This node queries interface data from the <b>Riverbed</b> point products for data and can then pass that data to charts or tables.",
                            output: "Interfaces",
                            editorHint:
                                "This node outputs a list of Interfaces and their Performance",
                            docLink:
                                "runbook_node_data_query_network_interface_performance.htm",
                        },
                        DevStatus: {
                            name: "Device Status",
                            help: "This node queries device status data from the <b>Riverbed</b> point products for data and can then pass that data to charts or tables.",
                            output: "Devices",
                            editorHint:
                                "This node outputs the time series of device status",
                            docLink: "runbook_node_data_query_Devices.htm",
                        },
                        IfcStatus: {
                            name: "Interface Status",
                            help: "This node queries interface status data from the <b>Riverbed</b> point products for data and can then pass that data to charts or tables.",
                            output: "Interfaces",
                            editorHint:
                                "This node outputs the time series of interface status",
                        },
                        App: {
                            name: "Applications",
                            help: "This node queries application data from the <b>Riverbed</b> point products for data and can then pass that data to charts or tables.",
                            output: "Applications",
                            editorHint:
                                "This node outputs a list of Apps (and related metrics if specified)",
                            docLink: "runbook_node_data_query_Applications.htm",
                        },
                        Dev: {
                            name: "Devices (Traffic)",
                            help: "This node queries devices data from the <b>Riverbed</b> point products for data and can then pass that data to charts or tables.",
                            output: "Devices",
                            editorHint:
                                "This node outputs a list of Devices (and related metrics if specified)",
                            docLink: "runbook_node_data_query_Devices.htm",
                        },
                        DevCpu: {
                            name: "Device (CPU Utilization)",
                            help: "This node queries devices data from the <b>Riverbed</b> point products for data and can then pass that data to charts or tables.",
                            output: "Devices",
                            editorHint:
                                "This node outputs a list of Devices and their CPU Utilization",
                            docLink:
                                "runbook_node_data_query_device_cpu_utilization.htm",
                        },
                        DevMem: {
                            name: "Device (Memory Utilization)",
                            help: "This node queries devices data from the <b>Riverbed</b> point products for data and can then pass that data to charts or tables.",
                            output: "Devices",
                            editorHint:
                                "This node outputs a list of Devices and their Memory Utilization",
                            docLink:
                                "runbook_node_data_query_device_memory_utilization.htm",
                        },
                        DevSdu: {
                            name: "Device (Disk Utilization)",
                            help: "This node queries devices data from the <b>Riverbed</b> point products for data and can then pass that data to charts or tables.",
                            output: "Devices",
                            editorHint:
                                "This node outputs a list of Devices and their Disk Utilization",
                            docLink:
                                "runbook_node_data_query_device_disk_utilization.htm",
                        },
                        DevUptime: {
                            name: "Device (Up Time)",
                            help: "This node queries devices data from the <b>Riverbed</b> point products for data and can then pass that data to charts or tables.",
                            output: "Devices",
                            editorHint:
                                "This node outputs a list of Devices and their up time",
                        },
                        DevAvl: {
                            name: "Device (Availability)",
                            help: "This node queries devices data from the <b>Riverbed</b> point products for data and can then pass that data to charts or tables.",
                            output: "Devices",
                            editorHint:
                                "This node outputs a list of Devices and their Availability",
                            docLink:
                                "runbook_node_data_query_devices_availability.htm",
                        },
                        DevChanges: {
                            name: "Device (Configuration Changes)",
                            help: "This node queries devices data from the <b>Riverbed</b> point products for data and can then pass that data to charts or tables.",
                            output: "Devices",
                            editorHint:
                                "This node outputs a list of Devices and their Config Changes",
                            docLink:
                                "runbook_node_data_query_network_device_config_change.htm",
                        },
                        DevPerf: {
                            name: "Devices (Performance)",
                            help: "This node queries devices data from the <b>Riverbed</b> point products for data and can then pass that data to charts or tables.",
                            output: "Devices",
                            editorHint:
                                "This node outputs a list of Devices and their Performance",
                            docLink:
                                "runbook_node_data_query_network_device_performance.htm",
                        },
                        Client_Hosts: {
                            name: "Client Hosts",
                            help: "This node queries client hosts data from the <b>Riverbed</b> point products for data and can then pass that data to charts or tables.",
                            output: "Hosts",
                            editorHint:
                                "This node outputs a list of Hosts acting as Clients (and related metrics if specified)",
                            docLink: "runbook_node_data_query_Client_Hosts.htm",
                        },
                        Server_Hosts: {
                            name: "Server Hosts",
                            help: "This node queries server hosts data from the <b>Riverbed</b> point products for data and can then pass that data to charts or tables.",
                            output: "Hosts",
                            editorHint:
                                "This node outputs a list of Hosts acting as Servers (and related metrics if specified)",
                            docLink:
                                "runbook_node_data_query_server_hosts_query.htm",
                        },
                        Hosts: {
                            name: "Hosts",
                            help: "This node queries hosts data from the <b>Riverbed</b> point products for data and can then pass that data to charts or tables.",
                            output: "Hosts",
                            editorHint:
                                "This node outputs a list of Hosts (and related metrics if specified)",
                            docLink: "runbook_node_data_query_Hosts.htm",
                        },
                        Host_Pairs: {
                            name: "Client-Server Pairs",
                            help: "This node queries client and servers data in pair from the <b>Riverbed</b> point products for data and can then pass that data to charts or tables.",
                            output: "Client-Server Pairs",
                            editorHint:
                                "This node outputs a list of Server Hosts (and related metrics if specified)",
                            docLink:
                                "runbook_node_data_query_Client_Server_Pairs.htm",
                        },
                        Locations: {
                            name: "Locations",
                            help: "This node queries location data from the <b>Riverbed</b> point products for data and can then pass that data to charts or tables.",
                            output: "Locations",
                            editorHint:
                                "This node outputs a list of Locations (and related metrics if specified)",
                            docLink: "runbook_node_data_query_locations.htm",
                        },
                        Client_Locations: {
                            name: "Client Locations",
                            help: "This node queries client location data from the <b>Riverbed</b> point products for data and can then pass that data to charts or tables.",
                            output: "Locations",
                            editorHint:
                                "This node outputs a list of Locations acting as Clients (and related metrics if specified)",
                            docLink: "runbook_node_client_locations.htm",
                        },
                        Server_Locations: {
                            name: "Server Locations",
                            help: "This node queries server location data from the <b>Riverbed</b> point products for data and can then pass that data to charts or tables.",
                            output: "Locations",
                            editorHint:
                                "This node outputs a list of Locations acting as Servers (and related metrics if specified)",
                            docLink: "runbook_node_server_locations.htm",
                        },
                        Protocols: {
                            name: "Protocols",
                            help: "This node queries protocol data from the <b>Riverbed</b> point products for data and can then pass that data to charts or tables.",
                            output: "Protocols",
                            editorHint:
                                "This node outputs a list of Protocols (and related metrics if specified)",
                            docLink: "runbook_node_data_query_protocols.htm",
                        },
                        Dscps: {
                            name: "DSCPs",
                            help: "This node queries DSCP data from the <b>Riverbed</b> point products for data and can then pass that data to charts or tables.",
                            output: "DSCPs",
                            editorHint:
                                "This node outputs a list of DSCPs (and related metrics if specified)",
                            docLink: "runbook_node_data_query_dscp.htm",
                        },
                        Location_Pairs: {
                            name: "Client-Server Location Pairs",
                            help: "This node queries client and server location data from the <b>Riverbed</b> point products for data and can then pass that data to charts or tables.",
                            output: "Location Pairs",
                            editorHint:
                                "This node outputs a list of Location Pairs (and related metrics if specified)",
                            docLink:
                                "runbook_node_data_query_client_server_location_pairs.htm",
                        },
                        Host_Pairs_Protoport: {
                            name: "Client-Server Pairs and Protoport",
                            help: "This node queries client and server data with protoports from the <b>Riverbed</b> point products for data and can then pass that data to charts or tables.",
                            output: "Client-Server Pairs And Protoports",
                            editorHint:
                                "This node outputs a list of Client and Servers Hosts with a Protoport (and related metrics if specified)",
                            docLink:
                                "runbook_node_data_query_client_server_pairs_and_protoport.htm",
                        },
                        TcpConnections: {
                            name: "TCP Connections",
                            help: "This node queries TCP connections from the <b>Riverbed</b> point products for data and can then pass that data to charts or tables.",
                            output: "TCP Connections",
                            editorHint:
                                "This node outputs a list of TCP Connections (and related metrics if specified)",
                            docLink:
                                "runbook_node_data_query_client_server_pairs_and_protoport.htm",
                        },
                        CloudRes: {
                            name: "Azure Monitor",
                            help: "This node queries <b>Azure Monitor</b> can then pass that data to charts or tables.",
                            output: "Resources",
                            editorHint:
                                "This node outputs a list of Resources (and related metrics if specified)",
                        },
                        User_Dev_Apps: {
                            name: "User Device Applications Query",
                            help: "This node queries user device applications data from the <b>Riverbed</b> point products for data and can then pass that data to charts or tables.",
                            output: "User Device Apps",
                            editorHint:
                                "This node outputs a list of User Device Apps (and related metrics if specified)",
                        },
                        User_Device: {
                            name: "User Device",
                            help: "This node queries user device data from the <b>Riverbed</b> Aternity product for data and can then pass that data to charts or tables.",
                            output: "User Devices",
                            editorHint:
                                "This node outputs a list of user devices (and related metrics if specified)",
                            docLink: "runbook_node_data_query_User_Device.htm",
                        },
                    },
                },
                set_priority: {
                    name: "Set Incident Priority",
                    help: "Use this node to set a priority for the Incident.",
                    editorHint:
                        "This node increases the priority of this Incident and the Trigger (Starts as Low)",
                    docLink: "runbook_node_function_Set_Incident_Priority.htm",
                },
                function: {
                    name: "Aggregator",
                    help: "This node enables you to group by applicable keys and then use an aggregate function over the metrics.",
                    docLink: "runbook_node_function_aggregator.htm",
                },
                logic: {
                    name: "Decision Branch (Version 1.0)",
                    help: "This node allows you to pass data in to the node and apply a condition to that data and then have a different child node execute depending on whether the condition evaluates to true or false.",
                },
                decision: {
                    name: "Decision Branch",
                    help: "This node lets you apply rules on incoming data and route flow to one of many matching outputs.",
                    docLink: "runbook_node_logic_decision_branch.htm",
                },
                tag: {
                    name: "Tag",
                    help: "This node allows you to tag the query that provides the impacted locations.",
                    subTypes: {
                        Location: {
                            name: "Tag As Affected Locations",
                            help: "This node allows you to tag the query that provides the impacted locations.",
                            docLink:
                                "runbook_node_tag_as_affected_locations.htm",
                        },
                        Application: {
                            name: "Tag As Affected Applications",
                            help: "This node allows you to tag the query that provides the impacted applications.",
                            docLink:
                                "runbook_node_tag_as_affected_applications.htm",
                        },
                        User: {
                            name: "Tag As Affected Users",
                            help: "This node allows you to tag the query that provides the impacted users.",
                            docLink: "runbook_node_tag_as_affected_users.htm",
                        },
                    },
                },
                table: {
                    title: "Title",
                    row: "Position in Runbook Output",
                    groupLabel: "Name & Details",
                    notes: "Notes",
                    notesPosition: "Notes Position",
                    includeAllColumns: "Include All",
                    sortColumn: "Sort Column",
                    noSortColumn: "Select Sort Column",
                    sortOrder: {
                        asc: "Ascending",
                        desc: "Descending",
                        none: "Select Sort Order",
                    },
                    tableColumns: "Table Columns",
                    tableColumnsPlaceholder: "Set default columns in the table",
                    docLink: "",
                },
                skeleton: {
                    label: "Click to add",
                },
                inject: {
                    name: "Inject",
                    help: "This is a test of the <b>tooltip</b> test.",
                },
                debug: {
                    name: "Debug",
                    help: "The <b>debug</b> node is used to collect debug information from the node it is attached to.  It has two configurable parameters: <ul><li>debug window</li><li>system console</li></ul>",
                },
                complete: {
                    name: "Complete",
                },
                catch: {
                    name: "Catch",
                },
                status: {
                    name: "Status",
                },
                "link in": {
                    name: "Link In",
                },
                "link out": {
                    name: "Link Out",
                },
                comment: {
                    name: "Comment",
                    docLink: "runbook_node_misc_Comment.htm",
                },
                notes: {
                    name: "Note",
                    docLink: "",
                },
                switch: {
                    name: "Switch",
                },
                change: {
                    name: "Change",
                },
                range: {
                    name: "Range",
                },
                template: {
                    name: "Template",
                },
                delay: {
                    name: "Delay",
                },
                exec: {
                    name: "Execute",
                },
                filter: {
                    name: "Filter",
                },
                "wait-paths": {
                    name: "Wait Paths",
                },
                "mqtt in": {
                    name: "MQTT In",
                },
                "mqtt out": {
                    name: "MQTT Out",
                },
                "http in": {
                    name: "HTTP In",
                },
                "http response": {
                    name: "HTTP Response",
                },
                "http request": {
                    name: "HTTP Request",
                },
                "websocket in": {
                    name: "WebSocket In",
                },
                "websocket out": {
                    name: "WebSocket Out",
                },
                "tcp in": {
                    name: "TCP In",
                },
                "tcp out": {
                    name: "TCP Out",
                },
                "tcp request": {
                    name: "TCP Request",
                },
                "udp in": {
                    name: "UDP In",
                },
                "udp out": {
                    name: "UDP Out",
                },
                split: {
                    name: "Split",
                },
                JOIN: {
                    name: "Join",
                },
                sort: {
                    name: "Sort",
                },
                batch: {
                    name: "Batch",
                },
                csv: {
                    name: "CSV",
                },
                html: {
                    name: "HTML",
                },
                json: {
                    name: "JSON",
                },
                xml: {
                    name: "XML",
                },
                yaml: {
                    name: "YAML",
                },
                file: {
                    name: "File",
                },
                "file in": {
                    name: "File in",
                },
                watch: {
                    name: "Watch",
                },
                profiler_apps: {
                    name: "Profiler Apps",
                },
                ui_chart: {
                    name: "Chart",
                },
                ui_pie_chart: {
                    name: "Pie Chart",
                },
                rvbd_ui_pie_chart: {
                    name: "Pie Chart",
                    docLink: "runbook_node_visualization_Pie_Chart.htm",
                },
                rvbd_ui_table: {
                    name: "Table",
                    docLink: "runbook_node_visualization_Table.htm",
                },
                rvbd_ui_bar_chart: {
                    name: "Bar Chart",
                    docLink: "runbook_node_visualization_Bar_Chart.htm",
                },
                rvbd_ui_time_chart: {
                    name: "Timeseries Chart",
                    docLink: "runbook_node_visualization_Time_Series_Chart.htm",
                },
                rvbd_ui_bubble_chart: {
                    name: "Bubble Chart",
                    docLink: "runbook_node_visualization_Bar_Chart.htm",
                },
                rvbd_ui_correlation_chart: {
                    name: "Correlation Chart",
                    docLink: "runbook_node_visualization_Bar_Chart.htm",
                },
                rvbd_ui_cards: {
                    name: "Cards",
                    docLink: "runbook_node_visualization_Cards.htm",
                },
                rvbd_ui_gauges: {
                    name: "Gauges",
                    docLink: "runbook_node_visualization_Gauges.htm",
                },
                rvbd_ui_connection_graph: {
                    name: "Connection Graph",
                    docLink: "runbook_node_visualization_connection_graph.htm",
                },
                rvbd_ui_debug: {
                    name: "Debug",
                },
                rvbd_ui_text: {
                    name: "Text",
                },
                ui_tab: {
                    name: "Tab",
                },
                ui_group: {
                    name: "Group",
                },
                test: {
                    name: "Test",
                    help: "This is the help for the <b>test</b> node.",
                    configurationHelp:
                        "This is just a test node so there are no <b>configuration</b>, but this would be the help for the configuration",
                    inputsHelp:
                        "This is just a test node so there are no <b>inputs</b>, but this would be the help for the inputs",
                    outputsHelp:
                        "This is just a test node so there are no <b>outputs</b>, but this would be the help for the outputs",
                    docLink: "runbook_node_Test.htm",
                },
                subflow: {
                    name: "Subflow",
                },
                aggregator: {
                    name: "Aggregator",
                },
                http: {
                    name: "HTTP Request",
                    help: "This node allows you to to communicate with another application through HTTP Requests.",
                    docLink: "runbook_node_http_request.htm",
                },
                transform: {
                    name: "Transform",
                    help: "This node takes in data from other nodes and transforms it into a standard schema.",
                },
                variables: {
                    name: "Variables",
                    help: "This node allows you to pass data in to the node and apply a condition to that data and then have a different child node execute depending on whether the condition evaluates to true or false.",
                    docLink: "runbook_node_misc_Variables.htm",
                },
                set_primitive_variables: {
                    name: "Set Primitive Variable",
                    help: "This node allows you to take data from another node and store it as a primitive variable.",
                },
                set_structured_variable: {
                    name: "Set Structured Variable",
                    help: "This node allows you to pass data into the node and save it as a structured variable.",
                },
            },
            noHelpAvailable: "No help available for this node.",
            propertyLabels: {
                query: "Query",
                summarized: "Summarized",
                timeSeries: "Time series",
                manualDefMethod: "Manual",
                loadFromDefMethod: "Use definition from variable",
                metricPlaceHolder:
                    "Type in the metrics to see the list and select",
                dataSourcePlaceHolder:
                    "Type in the vantage point to see the list and select",
                optionalLabel: "(No Filter)",
                duration15: "15 minutes",
                duration30: "30 minutes",
                duration45: "45 minutes",
                duration60: "1 hour",
                duration240: "4 hours",
                duration480: "8 hours",
                duration720: "12 hours",
                duration1440: "1 day",
                comparison: "Include Comparison Data From",
                comparisonOptionYesterday: "Previous Day",
                comparisonOptionLastWeek: "1 Week Prior",
                comparisonOptionLastMonth: "4 Weeks Prior",
                metrics: "Metrics",
                input: "Input",
                top: "Limit To",
                by: "Based On",
                applyLimit: "Apply Limit",
                priority: "Set Priority To",
                priorityOption1: "Critical",
                priorityOption2: "High",
                priorityOption3: "Moderate",
                priorityOption4: "Low",
                filter: "pass filtered results",
                passedData: "Passed Data",
                logicalOptionMatched: "Matched",
                logicalOptionAll: "All",
                expression: "Expression",
                description: "Description",
                dataOceanConfig: "Data Query Settings",
                debugWindow: "debug window",
                systemConsole: "system console",
                onMessage: "On Message",
                onStop: "On Stop",
                onStart: "On Start",
                switchRepair: "recreate message sequences",
                switchCheckAll: "Select",
                switchCheckOptionAll: "checking all rules",
                switchCheckOptionFirstMatch: "stopping after first match",
                switchProperty: "Property",
                switchPropertyOptionMsg: "msg.",
                switchPropertyOptionFlow: "flow.",
                switchPropertyOptionGlobal: "global.",
                switchPropertyOptionJson: "JSON",
                switchPropertyOptionDollar: "$",
                switchEnterText: "Enter text...",
                switchCases: "Cases",
                switchCaseOptionEq: "==",
                switchCaseOptionNeq: "!=",
                switchCaseOptionLt: "<",
                switchCaseOptionLte: "<=",
                switchCaseOptionGt: ">",
                switchCaseOptionGte: ">=",
                switchCaseOptionHasKey: "has key",
                switchCaseOptionIsBetween: "is between",
                switchCaseOptionContains: "contains",
                switchCaseOptionRegex: "matches regex",
                switchCaseOptionIsTrue: "is true",
                switchCaseOptionIsFalse: "is false",
                switchCaseOptionIsNull: "is null",
                switchCaseOptionIsNotNull: "is not null",
                switchCaseOptionIsType: "is of type",
                switchCaseOptionIsEmpty: "is empty",
                switchCaseOptionIsNotEmpty: "is not empty",
                switchCaseOptionHead: "head",
                switchCaseOptionIndex: "index between",
                switchCaseOptionTail: "tail",
                switchCaseOptionJson: "JSONata exp",
                switchCaseOptionOtherwise: "otherwise",
                switchAddBtnText: "add",
                paths: "PathsToWait",
                timeout: "Timeout",
                finalTimeout: "FinalTimeout",
                method: "Method",
                methodOptionGet: "GET",
                methodOptionPost: "POST",
                methodOptionPut: "PUT",
                methodOptionDelete: "DELETE",
                methodOptionPatch: "PATCH",
                statusCode: "Status Code",
                mode: "Mode",
                modeOptionAuto: "automatic",
                modeOptionManual: "manual",
                modeOptionReduce: "reduce sequence",
                filename: "Filename",
                action: "Action",
                actionOptionAppend: "append to file",
                actionOptionOverwrite: "overwrite file",
                actionOptionDelete: "delete file",
                addNewline: "Add newline (\n) to each payload?",
                createDirectory: "Create directory if it doesn't exist?",
                encoding: "Encoding",
                encodingOptionDefault: "default",
                encodingOptionUtf16: "utf-16le",
                output: "Output",
                outputOptionSingleUtf8: "a single utf8 string",
                outputOptionMsgPerLine: "a msg per line",
                outputOptionSingleBuffer: "a single Buffer object",
                outputOptionBufferStream: "a stream of Buffers",
                files: "File(s)",
                recursive: "Watch sub-directories recursively",
                url: "URL",
                password: "Password",
                phone: "Phone",
                email: "Email",
                text: "Text",
                select: "Select",
                order: "Order",
                tab: "Tab",
                group: "Name & Details",
                row: "Position in Runbook Output",
                rowTerse: "Position",
                outputIndex: "Output Index",
                comment: "Comment",
                title: "Title",
                notes: "Notes",
                notesPosition: "Notes Position",
                notesPositionOptionLeft: "Left",
                notesPositionOptionRight: "Right",
                notesPositionOptionAbove: "Top",
                notesPositionOptionBelow: "Bottom",
                reason: "Reason",
                showAllKeys: "show all keys in one table column",
                showPercentage: "Show Percentage",
                showValue: "Show Value",
                showBarLabels: "Show Bar Labels",
                stackBars: "Stack Bars",
                style: "Style",
                donutOption: "Donut",
                pieOption: "Pie",
                lineOption: "Line",
                areaOption: "Area",
                stackedAreaOption: "Stacked Area",
                stepLineOption: "Step",
                stepAreaOption: "Step (Area)",
                showLegend: "Show Legend",
                legendPosition: "Legend",
                topOption: "Top",
                bottomOption: "Bottom",
                leftOption: "Left",
                rightOption: "Right",
                metric: "Metric",
                sizeMetric: "Size Metric",
                colorMetric: "Color Metric",
                xMetric: "X-Axis Metric",
                yMetric: "Y-Axis Metric",
                orientation: "Bar Style",
                horizontalOption: "Horizontal",
                verticalOption: "Vertical",
                testSelectOption1: "Option 1",
                testSelectOption2: "Option 2",
                allRows: "all rows",
                matchingRows: "matching rows",
                pathText1: "Follow",
                pathText2:
                    " path if any row from the input matches the conditions below, and forward",
                pathText3: " otherwise follow ",
                pathText4: " path and forward all rows.",
                pathTextSuccess: "Success",
                pathTextFailure: "Failure",
                addCondition: "add condition",
                syncCall: "Sync Call",
                liquidTemplate: "Template",
                variables: "Variables",
                showInOutput: "show in runbook output",
                debug: "Debug",
                queryNodes: "Query Nodes",
                referenceQueryBasedOn: " will reference time based on:",
                primaryIndicatorStart: "primary indicator start",
                runbookExecution: "runbook execution",
            },
        },
        variableDefinitions: {
            label: "Variable Definitions",
            incidentVariablesDialog: {
                incidentVarsWarning1:
                    "Incident variables are available across multiple runbooks that share the same incident context.",
                incidentVarsWarning2:
                    " Changing an incident variable that is already used by one or more runbooks might cause runbook failures.",
                incidentVarsWarning3:
                    " Deleting an incident variable that is already used by one or more runbooks might cause runbook failures.",
                incidentVarsWarning4: " Would you like to save the changes?",
                incidentVariablesChangesTitle: "Incident variables changes!",
                discardAndCloseBtn: "Discard & Close",
                saveAndCloseBtn: "Save & Close",
            },
            variableTable: {
                name: "Name",
                type: "Type",
                unit: "Unit",
                initValue: "Default value",
            },
            save: "Save & Close",
            cancel: "Cancel",
            runtimeScope: {
                label: "Runtime Scope",
                userDefinedLabel: "User defined variables",
                noVariables: "No variables defined",
                builtin: {
                    label: "Built-in variables",
                    help: "Built-in variables can not be changed.",
                },
                addVariable: {
                    label: "Add Runtime Variable",
                    info: "Only persists over a single runbook execution",
                },
            },
            subflowScope: {
                label: "Subflow Scope",
                userDefinedLabel: "User defined variables",
                noVariables: "No variables defined",
                builtin: {
                    label: "Built-in variables",
                    help: "Built-in variables can not be changed.",
                },
                addVariable: {
                    label: "Add Subflow Variable",
                    info: "Only persists over a single subflow execution",
                },
            },
            onDemandScope: {
                label: "On-Demand Runbook Scope",
                userDefinedLabel: "User defined variables",
                noVariables: "No variables defined",
                builtin: {
                    label: "Built-in variables",
                    help: "Built-in variables can not be changed.",
                },
                addVariable: {
                    label: "Add On-Demand Runbook Variable",
                    info: "Only persists over a single on-demand runbook execution",
                },
            },
            incidentScope: {
                label: "Incident Scope",
                userDefinedLabel: "User defined variables",
                noVariables: "No variables defined",
                builtin: {
                    label: "Built-in variables",
                    help: "Built-in variables can not be changed.",
                },
                addVariable: {
                    label: "Add Incident Variable",
                    info: "Persists within the context of an incident",
                },
                successMsgUpdate: "Incident variables updated successfully.",
                errorMsgUpdate:
                    "Error while updating incident variables. Please try again later.",
            },
            globalScope: {
                label: "Global Scope",
            },
            primitiveVariable: {
                types: {
                    INTEGER: "Integer",
                    STRING: "String",
                    FLOAT: "Float",
                    IPADDR: "IP Address",
                    BOOLEAN: "Boolean",
                    TIMESTAMP: "Timestamp",
                    AUTH_PROFILE: "Authentication Profile",
                    ALLUVIO_EDGE: "Edge",
                    JSON: "JSON",
                    CONNECTOR: "Connector",
                },
                typeValidationError:
                    "The default value does not match the selected type.",
            },
            structuredVariable: {
                addProperty: "Add Property",
                addMetric: "Add Metric",
                metricsAndProperties: "Metrics & Properties",
                metrics: "Metrics",
                properties: "Properties",
                timeseriesType: "Time Series",
                variableType: {
                    summarized: "Summarized Data",
                    timeseries: "Timeseries Data",
                },
                types: {
                    INTERFACE: "Interface",
                    APPLICATION: "Application",
                    DEVICE: "Device",
                    PROTOCOL: "Protocol",
                    PROTOPORT: "Protoport",
                    DSCP: "DSCP",
                    LOCATION: "Locations",
                    CLIENT_LOCATION: "Client Locations",
                    SERVER_LOCATION: "Server Locations",
                    NETWORK_HOST: "Hosts",
                    NETWORK_CLIENT: "Client Hosts",
                    NETWORK_SERVER: "Server Hosts",
                    NETWORK_CLIENT_SERVER: "Client Server Host Pairs",
                    CLIENT_SERVER_LOCATION: "Client Server Location Pairs",
                    NETWORK_CLIENT_SERVER_PROTOPORT:
                        "Client Server Host Pairs With Protoport",
                    USER_DEVICE: "User Device",
                    DATA_SOURCE: "Vantage Point",
                    CUSTOM: "Custom",
                },
                summary: {
                    label: "Summarized Dataset",
                },
                timeseries: {
                    label: "Time Series Dataset",
                },
            },
        },
    },
    defaultRunbooks: {
        interface_congestion_name: "Interface Congestion",
        interface_congestion_desc:
            "This is the description of the Interface Congestion runbook",
        interface_congestion_inject_node_name: "inject",
        interface_congestion_inject_node_desc:
            "This is the description of the inject node",
        interface_congestion_debug_node_name: "debug",
        interface_congestion_debug_node_desc:
            "This is the description of the debug node",
    },
    incidents: {
        allIncidentsTab: "All Incidents",
        watchedIncidentsTab: "Watched",
        updateSuccess: "Incident updated successfully",
        defaultStatusUpdateNotesDesc: "Status updated to {0}",
        updateError: "There was an error updating the incident. Try again",
        secondaryFetchError: "Unable to fetch additional information",
        limitWarning:
            "The list has been truncated to the {0} most recent incidents.  Try adding a time filter to get below the {0} incident limit.",
        impactSummaryView: {
            title: "Impact Summary",
            showData: "Show Trending Data",
            reportedBy: "Reported by {0} objects",
            applications: "Applications",
            impactedApplications: "Impacted Applications",
            locations: "Locations",
            impactedLocations: "Impacted Locations",
            users: "Users",
            impactedUsers: "Impacted Users",
            search: "Search",
        },
        cards: {
            applications: {
                title: "Applications",
            },
            sites: {
                title: "Locations",
            },
            users: {
                title: "Users",
            },
        },
        incident: "Incident",
        incident_plural: "Incidents",
        trigger: "Trigger",
        trigger_plural: "Triggers",
        incident_sources: "Incident Sources",
        detectionsTitle: "Detections",
        runbookTitle: "Runbook Analysis",
        reasonsTitle: "Prioritization Factors",
        reasonPriorityUnknown: "Unknown",
        indicatorsTitle: "Primary Indicator",
        baselineIndicatorTabTitle: "Baseline Indicator",
        thresholdIndicatorTabTitle: "Threshold Indicator",
        primaryIndicatorTabTitle: "Primary Indicator",
        correlatedIndicatorTabTitle: "Correlated Indicators",
        listPageTitle: "Incidents",
        runbooksTabTitle: "Runbook Analysis",
        indicatorsTabTitle: "Indicators",
        allIndicatorsTabTitle: "All Indicators",
        incidentStartAnnotation: "Incident Start",
        incidentCreatedAnnotation: "Incident Created",
        incidentEndAnnotation: "Incident End",
        primaryIndicatorStartAnnotation: "Primary Indicator",
        correlatedIndicatorStartAnnotation: "Correlation starts",
        runbookExecAnnotation: "Runbook executes",
        timeFilterLabel: "Start Time",
        detailsPageTitle: "Incident Details",
        bladeTitle: "Incidents",
        viewAll: "View All Incidents",
        noIncidents: "No Incidents",
        clearNotifications: "Clear All",
        selectedLabel: "selected",
        applyChanges: "Apply Changes",
        runbookOutputs: {
            title: "Runbook Outputs",
            runbookName: "Runbook Name",
            runbookStart: "Run Date",
            runbookStatus: "Runbook Status",
            runbookLastRun: "Last Run",
            noRunbook: "Runbook Output Not Available!",
            runbookRunning: "Runbook is executing. Check back after sometime!",
            reRunRunbook: "Rerun Runbook",
            openRunbook: "Open Runbook",
            modifiedWarning: "Runbook has been modified on {0}",
            switchWarning: "Active runbook has changed since last run",
            noActiveError1:
                "There are no active runbooks for this type, go to ",
            noActiveError2: "Runbooks",
            noActiveError3: "to activate a runbook for this type.",
            inProgressMessage: "waiting for runbook to complete",
            reRunDialogTitle: "Re-Run Runbook",
            reRunDialogText: 'Scheduling another run of the "{0}" runbook',
            reRunErrorText: 'There was an error running the "{0}" runbook',
            reRun409ErrorText:
                'The "{0}" runbook could not be run because the incident is not ongoing. You can only re-run a runbook on an ongoing incident.',
            errorDialogTitle: "Runbook Errors, Warnings and Variables",
            currentValueLabel: "Current:",
            previousValueLabel: "Previous:",
            changeValueLabel: "Percent Change:",
            changeValueTooltipLabel: "Percent Change",
            enumCompareLabel: "compared to",
            incidentEntityNameText: "Analysis of {kind} {name}",
            incidentNoEntityText: "No entity used for analysis",
            debugDialogTitle: "Debug Information",
        },
        columns: {
            id: "Incident ID",
            priority: "Priority",
            status: "Status",
            description: "Description",
            detectedEntity: "Detected On",
            startedOn: "Started",
            updatedOn: "Updated",
            updateDetail: "Update Detail",
            indicators: "Indicators",
            impactedLocations: "Impacted Locations",
            impactedApplications: "Impacted Applications",
            impactedUsers: "Impacted Users",
            entityType: "Type",
            entity: "Name & Details",
            duration: "Duration",
            time: "Time",
            anomaly: "Indicator",
            indicatorId: "ID",
            metric: "Metric",
            indicatorType: "Indicator Type",
            expected: "Expected Value",
            expectedRange: "Expected Range",
            actual: "Observed Value",
            timeSeries: "Time Series",
            startTime: "Start Time",
            correlationStartTime: "Correlation Starts",
            uniqueMetrics: "Unique Metrics",
            uniqueEntityTypes: "Unique Entity Types",
            deviation: "Deviation",
            runbookStatus: "Runbook Status",
        },
        timeseriesChart: {
            actualValue: "Actual value",
            expectedRange: "Expected range",
            expectedValue: "Expected value",
            threshold: "Threshold",
            timestamp: "Timestamp",
        },
        moreMenu: {
            view: "Open Incident",
            viewNewTab: "Open Incident in New Tab",
            changePriority: "Change Priority",
            changeStatus: "Change State",
        },
        anomalyAt: "at",
        anomalyStrings: {
            sum_traffic_total_bytes_too_high: "Total bytes was too high",
            sum_in_bytes_too_high: "Incoming bytes was too high",
            sum_out_bytes_too_high: "Outgoing bytes was too high",
            sum_in_bytes_abnormally_low:
                "Incoming bytes was lower than expected",
            sum_out_bytes_abnormally_low:
                "Outgoing bytes was lower than expected",
            sum_in_bytes_abnormally_high:
                "Incoming bytes was higher than expected",
            sum_out_bytes_abnormally_high:
                "Outgoing bytes was higher than expected",
            status_fault_down: "Device went down",
            throughput_abnormally_low: "Lower than usual throughput",
            throughput_abnormally_high: "Higher than usual throughput",
            in_utilization_too_high: "Incoming Utilization was too high",
            in_utilization_too_low: "Incoming Utilization was too low",
            out_utilization_too_high: "Outgoing Utilization was too high",
            out_utilization_too_low: "Outgoing Utilization was too low",
            application_uxi_too_low: "App UXI was too low",
            packet_loss_too_high: "Packet Loss was too high",
            network_rtt_abnormally_low:
                "Network Round Trip Time was below baseline",
        },
        entityKinds: {
            application_server: "App Server",
            network_interface: "Network Interface",
            network_device: "Network Device",
            application_location: "App Location",
            location: "Location",
        },
        indicatorTypes: {
            abnormally_low: "Below Baseline",
            abnormally_high: "Over Baseline",
            too_low: "Below Threshold",
            too_high: "Over Threshold",
            fault_down: "Down",
            fault_degraded: "Degraded",
            fault_change: "Changed",
            dynamic_threshold_too_low: "Below Threshold",
            dynamic_threshold_too_high: "Over Threshold",
            not_increasing: "Dropped To Zero",
            abnormally_low_terse: "low",
            abnormally_high_terse: "high",
            too_low_terse: "low",
            too_high_terse: "high",
            fault_down_terse: "down",
            fault_degraded_terse: "degraded",
            fault_change_terse: "changed",
            dynamic_threshold_too_low_terse: "low",
            dynamic_threshold_too_high_terse: "high",
            not_increasing_terse: "a drop in",
        },
        indicators: {
            expectedAbove: "Above {0}",
            expectedBelow: "Below {0}",
            expectedAroundDownTo: "Around {value} down to {low}",
            expectedAroundUpTo: "Around {value} up to {high}",
            expectedRange: "{low} to {high}",
            timesHigher: "{0}x higher",
            timesLower: "{0}x lower",
            percentHigher: "{0} higher",
            percentLower: "{0} lower",
            outsideRange: "{0} % outside expected range",
            indicatorCountChartTitle: "Count of Indicators Over Time",
            indicatorChartSubtitle: "Measured from {0}",
        },
        elapsedPrefixStarted: "Started",
        elapsedSuffix: "ago",
        later: "later",
        lastedPrefix: "Lasted",
        ongoing: "Ongoing",
        ongoingFilterLabel: "Show Ongoing Only",
        headerStartedPrefix: "Started",
        headerUpdatedPrefix: "Last Update",
        headerEndedPrefix: "Ended",
        reload: "Reload",
        watched: {
            maxReached: "Cannot watch more than {0} incidents!",
            removedMsg: "Incident removed from Watchlist",
            addedMsg: "Incident added to Watchlist",
            addTitle: "Add to Watchlist",
            removeTitle: "Remove from Watchlist",
            missing: "One of the watched incidents is no longer in our system!",
            missing_plural:
                "{0} of the watched incidents are no longer in our system!",
        },
        misconfiguredMetric:
            "Interface speed is not properly configured in NetIM/NetProfiler causing interface utilization to be incorrectly calculated.",
        filterNames: {
            device: "Device",
            interface: "Interface",
            application: "Application",
            location: "Location",
            impactedApplication: "Impacted Application",
            impactedLocation: "Impacted Location",
            impactedUser: "Impacted User",
        },
        tooltips: {
            videoDialogTitle: "Incident Runbooks Video Tour",
        },
        debug: {
            debugText: "Debug Information",
            detailsLabel: "Indicator Details",
            samplesLabel: "Indicator Samples",
            tsLabel: "Indicator Timeseries Data",
        },
    },
    runbookOutput: {
        debugIconText: "Show Debug",
        debugDialogTitle: "Debug Information",
        infoIconText: "Show information",
        infoDialogTitle: "Information",
        timeLabel: "Time Interval:",
        comparisonTimeLabel: "Comparison Time Interval:",
        granularityLabel: "Granularity:",
        dsLabel: "Data Source:",
        filtersLabel: "Filters:",
        summaryQueryText: "Summary Query",
        summaryComparisonQueryText: "Summary Comparison Query",
        noCardSummaryData: "No summary data to display",
        tsQueryText: "Time Series Query",
        tsComparisonQueryText: "TimeSeries Comparison Query",
        errorLabel: "Error:",
        warningLabel: "Warning:",
        okBtnText: "OK",
        copyBtnText: "Copy To Clipboard",
        granularitySecond: "{0} second",
        granularitySeconds: "{0} seconds",
        granularityMinute: "{0} minute",
        granularityMinutes: "{0} minutes",
        granularityHour: "{0} hour",
        granularityHours: "{0} hours",
        granularityDay: "{0} day",
        granularityDays: "{0} days",
        // istanbul ignore next
        warnings: "Desso told me that DO errors can now be warnings so we are combining the error and warning lists.",
        errors: "Desso told me that DO errors can now be warnings so we are combining the error and warning lists.",
        errorsAndWarnings: {
            AternityNoData: "No recent data reported by Aternity.",
            InvalidFilter: "An invalid filter was supplied: {filterName}.",
            ClientConnectorError: "Could not connect to host.",
            DeviceNotConfiguredInNetProfiler:
                'The requested data is not available at this time.  Device {network_device__name} is not configured to send flow data to the connected {dataSourceType}: <a href="{dataSourceBaseUrl}" target="_blank" rel="noreferrer">{dataSourceBaseUrl}</a>. Check the NetProfiler settings if you believe this to be an error, configure the device to send the supported flow data, or adjust the active Runbook in order to remove this condition.',
            IfaceNotConfiguredInNetProfiler:
                'The requested data is not available at this time.  Device {network_device__name} is not configured to send flow data to the connected {dataSourceType}: <a href="{dataSourceBaseUrl}" target="_blank" rel="noreferrer">{dataSourceBaseUrl}</a>. Check the NetProfiler settings if you believe this to be an error, configure the device to send the supported flow data, or adjust the active Runbook in order to remove this condition.',
            DeviceNotConfiguredInNetIM:
                'The requested data is not available at this time.  Device {network_device__name} is not configured to send data to the connected {dataSourceType}: <a href="{dataSourceBaseUrl}" target="_blank" rel="noreferrer">{dataSourceBaseUrl}</a>. Check the NetIM settings if you believe this to be an error, configure NetIM to poll the device, or adjust the active Runbook in order to remove this condition.',
            IfaceNotConfiguredInNetIM:
                'The requested data is not available at this time.  Device {network_device__name} is not configured to send data to the connected {dataSourceType}: <a href="{dataSourceBaseUrl}" target="_blank" rel="noreferrer">{dataSourceBaseUrl}</a>. Check the NetIM settings if you believe this to be an error, configure NetIM to poll the device, or adjust the active Runbook in order to remove this condition.',
            NetProfilerNotPresent:
                'The data requested cannot be provided as this information is only available through <a href="https://www.riverbed.com/products/npm/netprofiler.html" target="_blank" rel="noreferrer">NetProfiler</a>. NetProfiler can provide unique insights leveraging hybrid flow monitoring, analysis, and reporting for all flow types.  Speak with your account team to find out how you can add NetProfiler to your environment and add this valuable perspective to the Platform.  For the time being, you may want to customize the active Runbook to remove this condition.',
            NetIMNotPresent:
                'The data requested cannot be provided as this information is only available through <a href="https://www.riverbed.com/products/npm/netim" target="_blank" rel="noreferrer">NetIM</a>. NetIM can proactively discover and monitor network infrastructure performance and configuration changes. Speak with your account team to find out how you can add NetIM to your environment and add this valuable perspective to the Platform. For the time being, you may want to customize the active Runbook to remove this condition.',
            ConnectionIssue:
                'The data requested cannot be provided as the system is unable to reach {dataSourceType}: <a href="{dataSourceBaseUrl}" target="_blank" rel="noreferrer">{dataSourceBaseUrl}</a> at this time. Please evaluate the network connection between the Gateway and {dataSourceType}.',
            AuthInvalidCredentials:
                'Authentication failure. Unable to query {dataSourceType}: <a href="{dataSourceBaseUrl}" target="_blank" rel="noreferrer">{dataSourceBaseUrl}</a>.  The configured username or password is incorrect or has changed. To address this issue, connect to {dataSourceType} and validate the existing credentials.  If you do not know the password, contact support for assistance.',
            AuthDisabledAccount:
                'Authentication failure. Unable to query {dataSourceType}: <a href="{dataSourceBaseUrl}" target="_blank" rel="noreferrer">{dataSourceBaseUrl}</a>.  The configured user account is disabled. To address this issue, connect to {dataSourceType}, access Administration, User Accounts, and enable the account from the Actions section.',
            AuthUnauthorizedAccount:
                'Authorization failure. Unable to query {dataSourceType}: <a href="{dataSourceBaseUrl}" target="_blank" rel="noreferrer">{dataSourceBaseUrl}</a>.  The configured user account does not have enough permissions.  To address this issue, connect to {dataSourceType}, access Administration, User Accounts, and change the role of account from the Actions section.',
            IotDeviceNotFound: "IoT device {deviceId} not found.",
            IotUnsafeUsername:
                "Using unsafe username 'admin'. Please configure a different user account for the integration.",
            CloudSyncVersionUnexpected:
                "The data source is not on the latest version. Current: {currentVersion}. Latest version: {latestVersion}.",
            NetProfilerQueueLimitExceeded:
                'The request to NetProfiler (<a href="{dataSourceBaseUrl}" target="_blank" rel="noreferrer">{dataSourceBaseUrl}</a>) cannot be performed at this time. The NetProfiler queue limit has been reached. Please try again later.',
            IoTC2DQueueLimitExceeded:
                "The request cannot be performed at this time. The Edge queue limit has been reached. Please try again later.",
            EdgeDeviceNotConfigured:
                'The Edge at "{gatewayHostName}" is not confugured for data source {dataSourceType}.',
            AppResponseProcessDown: 'AppResponse {appResponse} processes are down, please contact support.',
            NoDataSourceFound: "No data source found to match the request.",
            ApplicationNotConfigured:
                'The requested data is not available at this time. Application {application__name} is not configured on {dataSourceType}: <a href="{dataSourceBaseUrl}" target="_blank" rel="noreferrer">{dataSourceBaseUrl}</a>. Check the {dataSourceType} settings if you believe this to be an error, configure the application, or adjust the active Runbook in order to remove this condition.',
            LocationNotConfigured:
                'The requested data is not available at this time. Location {location__name} is not configured on {dataSourceType}: <a href="{dataSourceBaseUrl}" target="_blank" rel="noreferrer">{dataSourceBaseUrl}</a>. Check the {dataSourceType} settings if you believe this to be an error, configure the location, or adjust the active Runbook in order to remove this condition.',
            UnsupportedMetricsInNetIMQuery:
                "The requested metric(s): {metrics} are not available at this time.  Device {network_device__name} is not configured to send flow data to a NetProfiler. If you believe this to be an error, configure the device to send the supported flow data, or adjust the active Runbook in order to remove this condition.",
            UnsupportedMetricsInNetProfilerQuery:
                "The requested metric(s): {metrics} are not available for the NetProfiler query at this time. If you believe this to be an error, adjust the active Runbook in order to remove this condition.",
            UnsupportedMetricsInAternityQuery:
                "The requested metric(s): {metrics} are not available for the Aternity query at this time. If you believe this to be an error, adjust the active Runbook in order to remove this condition.",
            UnsupportedMetricsInAppResponseQuery:
                "The requested metric(s): {metrics} are not available for the AppResponse query at this time. If you believe this to be an error, adjust the active Runbook in order to remove this condition.",
            UnresolvedObjectIds:
                "There were properties whose data could not be retrieved.",
            MetriclessComparison:
                "When comparisons are enabled you need to select at least one metric.",
            GENERAL_ERROR: "Internal server error.",
            GENERAL_WARNING: "An unknown warning was received.",
            "Internal Error": "Internal server error.",
            ORCHESTRATOR_HTTP_NODE_FAILED_TO_FIND_AUTHORIZATION_PROFILE:
                "Could not find authentication profile.",
            ORCHESTRATOR_HTTP_NODE_AUTHORIZATION_PROFILE_NOT_FOUND_EXCEPTION:
                "Failed to find authentication profile with tenant ID: {tenantId} and profile ID: {profileId}. Server responded with the following status code: {httpResponseStatusCode} and reason: {httpResponseReasonPhrase}",
            ORCHESTRATOR_HTTP_NODE_AUTHORIZATION_PROFILE_DISABLED_EXCEPTION:
                "Unable to use authentication profile since it has been disabled.",
            ORCHESTRATOR_HTTP_NODE_ENRICH_REQUEST_WITH_AUTHORIZATION_DETAILS_EXCEPTION:
                "Failed to enrich HTTP request with authorization details.",
            ORCHESTRATOR_HTTP_NODE_GET_AUTHORIZATION_PROFILE_EXCEPTION:
                "Failed to get authentication profile,",
            ORCHESTRATOR_HTTP_NODE_INVALID_INPUT_PROPERTIES_EXCEPTION:
                "HTTP call properties are invalid.",
            ORCHESTRATOR_HTTP_NODE_RENDER_REQUEST_BODY_TEMPLATE_EXCEPTION:
                "Failed to render HTTP request body template due to: {templateRenderError}",
            ORCHESTRATOR_HTTP_NODE_RENDER_REQUEST_URI_TEMPLATE_EXCEPTION:
                "Failed to render HTTP request URI template due to: {templateRenderError}",
            ORCHESTRATOR_HTTP_NODE_RENDER_REQUEST_VERB_TEMPLATE_EXCEPTION:
                "Failed to render HTTP verb template due to: {templateRenderError}",
            ORCHESTRATOR_HTTP_NODE_INVALID_SERVER_CERTIFICATE_EXCEPTION:
                "Failed to receive HTTP response from third party due to an invalid server certificate.",
            ORCHESTRATOR_HTTP_NODE_REQUEST_CONNECTIVITY_EXCEPTION:
                "Failed to send HTTP request to third party due to a network or DNS related error.",
            ORCHESTRATOR_HTTP_NODE_REQUEST_TIMEOUT_EXCEPTION:
                "Failed to send HTTP request to third party due to a timeout.",
            ORCHESTRATOR_HTTP_NODE_RESPONSE_READ_CONTENTS_EXCEPTION:
                "Failed to read third party HTTP response contents due to an unexpected error.",
            ORCHESTRATOR_HTTP_NODE_THIRD_PARTY_CALL_EXCEPTION:
                "Call to third party failed due to an unexpected error.",
            ORCHESTRATOR_HTTP_NODE_EXCEPTION:
                "An unexpected error has occurred while executing HttpFlowLogicNode node.",
            ORCHESTRATOR_HTTP_NODE_EDGE_DEVICE_NOT_FOUND_EXCEPTION:
                "Could not find edge device.",
            ORCHESTRATOR_HTTP_NODE_EDGE_DEVICE_PUBLIC_KEY_NOT_FOUND_EXCEPTION:
                "Unable to contact edge device since its public key could not be found.",
            ORCHESTRATOR_HTTP_NODE_THIRD_PARTY_CALL_INVALID_INPUT_EXCEPTION:
                "Unable to contact edge device due to invalid input. Please contact support.",
            ORCHESTRATOR_HTTP_NODE_THIRD_PARTY_CALL_INVALID_DIGITAL_SIGNATURE_EXCEPTION:
                "The third-party response message does not have a valid digital signature. Please contact support.",
            ORCHESTRATOR_HTTP_NODE_THIRD_PARTY_RESPONSE_SIZE_EXCEEDS_THRESHOLD_EXCEPTION:
                "The size of the response message is larger than the maximum allowed value of 256 KB. Please ensure the third party response does not go beyond this threshold.",
            ORCHESTRATOR_HTTP_NODE_THIRD_PARTY_RESPONSE_TIMEOUT_EXCEPTION:
                "Failed to get the third party response due to a timeout.",
            ORCHESTRATOR_HTTP_NODE_IOT_MODULE_RESPONSE_TIMEOUT_EXCEPTION:
                "Failed to get the IoT Module response due to a timeout.",
            ORCHESTRATOR_HTTP_NODE_EVALUATE_THIRD_PARTY_RESPONSE_TIMEOUT_EXCEPTION:
                "Failed to evaluate the third party response due to a timeout.",
            ORCHESTRATOR_HTTP_NODE_INCOMPATIBLE_PROFILE_TYPE:
                "The selected profile type is not compatible with the service caller.",
            ORCHESTRATOR_DO_NODE_TIMEOUT_EXCEPTION:
                "The data query timed out after {timeout} seconds.",
            ORCHESTRATOR_DO_EXCEPTION: "There was an error in the data query.",
            ORCHESTRATOR_DO_NODE_NO_FILTER_DATA_FROM_PREVIOUS_NODE_EXCEPTION:
                "The {name} filter could not be populated using the output from its parent node.",
            ORCHESTRATOR_TRANSFORM_NODE_EXCEPTION:
                "An unexpected error has occurred while executing TransformFlowLogicNode node.",
            ORCHESTRATOR_TRANSFORM_NODE_INVALID_TEMPLATE_EXCEPTION:
                "Transformation template must not be empty or white-space only",
            ORCHESTRATOR_TRANSFORM_NODE_TRANSFORMATION_FAILED_EXCEPTION:
                "Transformation failed due to: {templateRenderError}",
            ORCHESTRATOR_TRANSFORM_NODE_TRANSFORMATION_RESULT_NOT_VALID_JSON_EXCEPTION:
                "Transformation result does not represent a valid JSON document",
            ORCHESTRATOR_VARIABLES_OVERRIDE_FAILED:
                "Incident variables cannot be initialized due to errors in the variables submitted with the test run",
            ORCHESTRATOR_INCONSISTENT_GRAPH_EXCEPTION:
                "An inconsistency was found in the runbook hierarchy",
            ORCHESTRATOR_SUBFLOW_EXCEPTION:
                "An error was encountered running a subflow within the runbook",
            ORCHESTRATOR_GENERIC_ERROR: "Error running runbook",
            CHECK_LONG_RUNNING_OPERATION_STATUS_FAILED:
                "The process of checking the status of the long-running operation has failed",
            StatusMissingPeriodicUpdate:
                "The Data Source has not provided any recent status update. The IoT edge module could be down or unreachable.",
            LastReceivedOutdated:
                "IQ has not received data during the expected time frame from this source. Try selecting " +
                "Reconfigure to correct any potential issue with the connection and check back in a few minutes. If the " +
                "problem persists, contact support.",
            ORCHESTRATOR_PROCESS_MODEL_EXCEEDINGLY_LONG_EXCEPTION:
                "Runbook processing is taking too long. Something is probably wrong with the runbook.",
            ORCHESTRATOR_SET_VARIABLES_FAILED:
                "Error setting variables in set primitive variables node (steps: {steps}, recursion: {recursion}).",
            ORCHESTRATOR_CUSTOM_PROPERTIES_LOADING_EXCEPTION:
                "Error while loading custom properties for {Kind} {Name} (ID={ObjectId}).",
            ORCHESTRATOR_CONNECTOR_SETUP_EXCEPTION: "The connector referred to by this subflow node cannot be found.",
            MultipleErrors: "Multiple errors encountered.",
            MultipleWarnings: "Multiple warnings encountered.",
            MinProdVersionNotMet: "Minimum product version requirement not met. Please update to version '{minVersion}' or above."
        },
        errorDialog: {
            unknownWidgetText:
                "Unknown (visualization does not have title set)",
            generalErrorsLabel: "General Errors",
            generalWarningsLabel: "General Warnings",
            ClientConnectorError: "Could not connect to host.",
            widgetErrorsAndWarningsLabel:
                "Errors and Warnings in Runbook Output",
            widgetErrorsLabel: "Errors",
            widgetWarningsLabel: "Warnings",
            widgetErrorAndWarningText:
                "The runbook has {0} widget error(s) and {1} widget warning(s).  Click on the show information buttons to see the errors in the runbook output.",
            widgetErrorText:
                "The runbook has {0} widget error(s).  Click on the show information buttons to see the errors in the runbook output.",
            widgetWarningText:
                "The runbook has {0} widget warnings.  Click on the show information buttons to see the errors in the runbook output.",
            variables: {
                scopes: {
                    runtime: {
                        name: "Runtime Variables",
                    },
                    incident: {
                        name: "Incident Variables",
                    },
                    global: {
                        name: "Global Variables",
                    },
                },
                enteringLabel: "Upon entering runbook",
                leavingLabel: "Upon leaving runbook",
                customLabel: "Custom",
                builtinLabel: "Built-in",
            },
            inputsLabel: "Inputs",
            indicatorsLabel: "Indicators",
            notesLabel: "Notes",
        },
    },
    viewRunbooks: {
        advancedSection: "Advanced",
        inputDialogTitle: "Inputs",
        inputLabel: "Input:",
        inputSearchLabel: "Search:",
        inputDeviceLabel: "Device:",
        inputDevicePlaceholder: "Select or enter a device...",
        inputInterfaceLabel: "Interface:",
        inputInterfacePlaceholder: "Select or enter an interface...",
        inputApplicationLabel: "Application:",
        inputApplicationPlaceholder: "Select or enter an application...",
        inputLocationLabel: "Client Location:",
        inputServerLocationLabel: "Server Location:",
        inputLocationPlaceholder: "Select or enter a client location...",
        inputServerLocationPlaceholder: "Select or enter a server location...",
        inputIfIndexLabel: "Ifindex:",
        inputAppNameLabel: "Application Name:",
        inputAppLocationLabel: "Location:",
        inputAppServerLabel: "Server:",
        inputAppTypeLabel: "Application Type:",
        inputAppTypeTextAll: "All",
        inputAppTypeTextApp: "App",
        inputAppTypeTextAppSrv: "App Server",
        inputAppTypeTextAppLoc: "App Location",
        inputTimeLabel: "Time:",
        inputEndTimeLabel: "Time:",
        inputEndTimeNowText: "Now",
        inputTimeoutLabel: "Timeout (min):",
        inputTriggerMetricLabel: "Trigger Metric:",
        inputTriggerMetricActualValueLabel: "Actual Value:",
        inputTriggerMetricAcceptableLowValueLabel: "Acceptable Low Value:",
        inputTriggerMetricAcceptableHighValueLabel: "Acceptable High Value:",
        inputRunbookLabel: "Runbook:",
        inputRunbookText:
            "Enter the runbook inputs for the {0} trigger type (use search to find a specific {0}):",
        inputActiveRunbookText:
            "Enter the runbook inputs for the {0} trigger type (use search to find a specific {0}):",
        inputInactiveRunbookText:
            "The runbook you have selected to test is inactive.  Right now only the active runbook for the {0} trigger type can be tested.  If you want to run a test of the active runbook, select the inputs and continue, otherwise go back to the Runbooks page and activate the {1} runbook:",
        inputVantagePointLabel: "Vantage Point:",
        inputErrorText: "Error parsing inputs",
        inputButtonTooltip:
            "Select the input from a list of {0}s seen by the indicators",
        inputs: {
            httpRequestBody: "HTTP Request Body",
            httpRequestHeaders: "HTTP Request Headers",
            httpRequestUrl: "HTTP Request URL",
            httpRequestQueryParams: "HTTP Request Query Parameters",
            incidentVariables: "Incident Variables",
            mimicRollup: "mimic the multi-device automation trigger",
        },
        unknownEntityName: "Unknown",
        previewDialogTitle: "Preview Options",
        previewOutputLabel: "Output Index:",
        previewLogicalIntroText:
            "Decision branch (Version 1.0) nodes can have a true or false output, which output do you want to display in the preview data?",
        previewDecisionIntroText:
            "Decision branch nodes have multiple outputs.  By default the preview will test the first output, if you want to test a different output change the index of the output below.",
        previewErrorText: "Error generating preview",
        okBtnText: "OK",
        runTestText: "Run another test",
        runPreviewText: "Run another preview",
        startingStatus: "Starting",
        retrievingStatus: "Retrieving Runbook",
        runningStatus: "Running Runbook",
        errorStatus: "Error Running Runbook",
        dataReceivedStatus: "Runbook Data Received",
        timeoutStatus: "Runbook Timeout",
        debugText: "Debug Information",
        runbookAnalysisAddedLabel: "Runbook Analysis Added At:",
        oldStatusLabel: "Old Status:",
        statusLabel: "Status:",
        incidentStatusChangedAtLabel: "Incident Status Changed At:",
        noteContentLabel: "Note Content:",
        noteAddedAtLabel: "Node Added At:",
        noteUpdatedAtLabel: "Node Updated At:",
        indicatorsCountLabel: "Indicators Count:",
        incidentEndTimeLabel: "Incident End Time:",
        queryTimeLabel: "Runbook Query Time:",
        inputsLabel: "Inputs:",
        orchestratorLabel: "Orchestrator Information:",
        orchestratorErrorLabel: "Orchestrator Errors:",
        dataLabel: "Data:",
        rerunRunbookNotAvailable:
            "Rerun runbook is not available when you preview or test a runbook",
        openRunbookNotAvailable:
            "Open runbook is not available when you preview or test a runbook",
        timeout: {
            msg: "The runbook timed out.",
            suggestionsText: "Suggestions:",
            sug1: "This could be a temporary issue so you can run another test and see what happens.",
            sug2: "The runbook might be fine and the queries might just take longer than the timeout, in the inputs dialog you can increase the timeout and see if the runbook returns data.",
            sug3: "If the runbook does not work when active contact support.",
        },
        error: {
            msg: "The runbook execution failed.",
            errorText: 'Error information: "{0}"',
            stackTraceText: "Stack trace:",
            suggestionsText: "Suggestions:",
            sug1: "This could be a temporary issue so you can run another test and see what happens.",
            sug2: "There could be an element in your runbook that is causing the issue, you can try simplifying the runbook and running the test again.",
            sug3: "If the issue persists you should contact support.",
        },
        inputErrors: {
            incidentVariablesParsing:
                "Could not parse JSON in Variable Overrides field!",
            requestBodyParsing:
                'Could not parse JSON in "HTTP Request Body" field!',
            requestBodySchema:
                'The JSON schema in the "HTTP Request Body" field is incorrect!',
            requestHeadersParsing:
                'Could not parse JSON in "HTTP Request Headers" field!',
            requestHeadersSchema:
                'The JSON schema in the "HTTP Request Headers" field is incorrect!',
            requestQueryParametersParsing:
                'Could not parse JSON in "HTTP Request Query Parameters" field!',
            requestQueryParametersSchema:
                'The JSON schema in the "HTTP Request Query Parameters" field is incorrect!',
            primaryEntityIpAddressParsing:
                "Could not parse IP address in Primary Entity field!",
        },
    },
    networkDashboard: {
        title: "Impact Dashboard",
        impactedLocations: "Most Impacted Locations",
        impactedServices: "Most Impacted Applications",
        userImpact: "Incidents By User Impact",
        incidentDistribution: "Active Incident Distribution",
        incidentPriority: "Incident Priority Over Time",
        statusText: "Status:",
        activeStatus: "Active",
        allStatus: "All",
        dismissStatusMenuText: "Done",
        twelveHrsTime: "12 hrs",
        twentyFourHrsTime: "24 hrs",
        twoDaysTime: "2 days",
        oneWeekTime: "1 week",
        lastText: "Last",
        usersHeader: "Users",
        incidentsLabel: "Incidents",
        unknownBarLabel: "Unknown",
        impactedUsers: "Impacted Users",
    },
    autoUpdate: {
        pausedPrefix: "Paused",
        lastUpdatedMinute: "Last updated {0} minute ago",
        lastUpdatedMinutes: "Last updated {0} minutes ago",
        lastUpdatedHour: "Last updated {0} hour ago",
        lastUpdatedHours: "Last updated {0} hours ago",
        lastUpdatedDay: "Last updated {0} day ago",
        lastUpdatedDays: "Last updated {0} days ago",
        lastUpdatedReallySmall: "Last updated within a minute",
        lastUpdatePrefix: "Last updated",
        autoUpdatePrefix: "Update every",
        autoUpdateSuffix: "minute(s)",
        okayBtnText: "OK",
        cancelBtnText: "Cancel",
        changeDetected: "Changes Detected!",
        updateBtnText: "Click to Update",
    },
    successfulSubmit: "Form submitted successfully",
    product: "Product",
    globalFilters: {
        filterLabels: {
            time: "Time",
            applicationId: "App",
            siteId: "Location",
            hostId: "Device",
            incidentId: "Incident ID",
            priority: "Priority",
            incidentStatus: "Status",
            userId: "User",
        },
        categoryLabels: {
            detectedApplication: "Detected On Applications",
            detectedLocation: "Detected On Locations",
            detectedDevice: "Detected On Devices",
            detectedInterface: "Detected On Interfaces",
            impactedApplication: "Impacted Applications",
            impactedLocation: "Impacted Locations",
            impactedUser: "Impacted Users",
        },
        placeholder: "Type name to filter",
        clearAll: "Clear All",
        recentResultsLabel: "Recent Filters...",
        loading: "Loading...",
        empty: "No Results!",
    },
    EDIT_DOMAINS: {
        title: "Manage Authorized Domains",
        buttonText: "Manage Authorized Domains",
        labelText: "Authorized domains",
        helpText:
            "As a security measure, user email addresses are checked against a list of authorized domains. Only authorized domains are trusted within your organization.",
        placeholderText: "Enter authorized domain name ...",
        errors: {
            updateFailure: "Error updating domains",
        },
        warningText:
            "Users within a domain must be removed before unauthorizing a domain",
        validation: {
            invalidDomain: "Invalid domain",
            valueRequired: "Please, provide at least one domain update",
        },
    },
    SIGNUP: {
        EMAIL: {
            label: "Email",
            placeholder: "Enter the username and select a domain",
            riverbedError:
                "Please type email in this format loginid@riverbed.com (jsmith@riverbed.com)",
            formatError: "The format of the email is not accepted",
            domainError: "Invalid Email",
        },
    },
    USER_ACCOUNT_MANAGEMENT: {
        title: "User Management",
        roles: "Roles",
        portfolioAdmin: "Portfolio Admin",
        isUserPortfolioAdmin: "Make this user a Portfolio Admin?",
        COLUMN_LABEL: {
            name: "Name",
            email: "Email",
            remove: "Remove user",
            role: "Role",
        },
        INVITE_USER: {
            title: "Invite User",
            info: "Send an email to a user to invite them to register their account in the application.",
            successMessage: "User invited successfully",
        },
        EDIT_ROLE: {
            title: "Edit Role",
            label: "Select Role:",
            buttonText: "Edit",
            successMessage: "User role updated successfully",
        },
        DELETE_USER: {
            title: "Remove User",
            buttonText: "Remove",
            confirmMsg: "Are you sure you want to remove user {0}?",
            successMessage: "User removed successfully",
        },
        VALIDATION: {
            selectOption: "You must select a role",
        },
    },
    DATA_SOURCES: {
        pageTitle: "Data Sources",
        menuTitle: "Edges & Data Sources",
        dataSourceStatus: {
            dialogTitle: "Details",
            buttons: {
                close: "Close",
            },
        },
        atternityConfig: {
            labels: {
                noConfig: "No Aternity SaaS data source configured",
                hostname: "Aternity Base URL: ",
                username: "Account Name & ID: ",
                enable: "Enable Data Source: ",
                status: "Integration Status: ",
                lastIngested: "Last Data Ingestion: ",
                lastReceived: "Last Data Received: ",
            },
            ctas: {
                noConfig: "Configure",
                hostname: "Edit",
                reset: "Reset Aternity Data Source",
            },
            queryResponse: {
                success: "Data source updated successfully",
                error: "There has been an error updating the Data Source",
            },
            status: {
                disabled: "Disabled",
                errors: "Error(s)",
            },
            dialog: {
                title: "Configure Aternity SaaS Data Source",
                labels: {
                    hostname: "Aternity base URL",
                    username: "Username",
                    password: "Password",
                },
                ctas: {
                    submit: "Save",
                    cancel: "Cancel",
                },
                placeholder: {
                    hostname: "Insert a valid URL...",
                    username: "Insert user name...",
                    password: "Insert password...",
                },
            },
            confirmModal: {
                label: "Are you sure you want to reset the Aternity Configuration?",
            },
        },
        edgeGateways: {
            columns: {
                gatewayName: "Edge",
                gatewayType: "Type",
                status: "Edge Status",
                dataSources: "Data Sources",
                performCertificateValidation: "Perform Certificate Validation",
            },
            menuItems: {
                editEdgeGateway: "Edit",
                createDataSource: "Connect a Data Source",
                downloadVmImage: "Obtain VM Image",
                downloadCloudInitScript: "Download cloud-init",
                downloadIso: "Download ISO",
                edit: "Edit",
                delete: "Delete",
            },
            messages: {
                deleteConfirmMsg: "Are you sure you want to delete {0}?",
                successfullyDeleted: "Edge successfully deleted",
            },
            status: {
                up: "Up",
                down: "Down",
                unknown: "Unknown",
                loading: "Loading",
            },
        },
        cloudimDataSources: {
            columns: {
                dataSource: "Data Source",
                authProfile: "Authentication Profile",
            },
            menuItems: {
                edit: "Edit Data Source",
                delete: "Delete Data Source",
            },
            dialog: {
                title: "Configure CloudIM Data Source",
                button: "Add a CloudIM Data Source",
                labels: {
                    dataSourceName: "Data Source Name",
                    authProfile: "Authentication Profile",
                },
                ctas: {
                    save: "Save",
                    cancel: "Cancel",
                },
                placeholder: {
                    dataSourceName: "Insert a Data Source name..."
                },
            },
            messages: {
                deleteConfirmMsg: "Are you sure you want to delete this data source?",
            },
        },
        addEdgeGatewayDialog: {
            title: {
                add: "Add an Edge",
                edit: "Edit an Edge",
            },
            editTitle: "Edit the Edge",
            panels: {
                basicDetails: {
                    title: "Basic Details",
                    fields: {
                        gatewayName: "Name",
                        gatewayType: "Edge Virtual Machine Type",
                        chooseType: "Choose type",
                    },
                },
                connectionConfig: {
                    title: "Connection Config",
                    fields: {
                        proxy: "Use A Proxy",
                        proxyAuth: "Use Basic Authentication",
                        proxyHostname: "Hostname or IP",
                        proxyProtocol: "Protocol",
                        proxyPort: "Port",
                        proxyUsername: "Username",
                        proxyPassword: "Password",
                        websockets: "Use Websockets",
                    },
                },
                ipConfig: {
                    title: "IP Config",
                    fields: {
                        useDhcp: "Use DHCP",
                        useStaticIp: "Use a static IP",
                        staticIpCidr: "CIDR IP Address",
                        staticIpGateway: "Gateway IP Address",
                        staticIpNameservers: "Nameserver IP Addresses",
                    },
                    fieldPlaceholders: {
                        staticIpCidr: "Input CIDR IP Address ...",
                        staticIpGateway: "Input Gateway IP Address ...",
                        staticIpNameservers:
                            "Input Nameserver IP Address and press Enter ...",
                    },
                    validationErrors: {
                        invalidStaticIpAddress: "Invalid CIDR IP Address",
                        invalidStaticIpGateway: "Invalid Gateway IP Address",
                        invalidIpNameservers: "Invalid Nameserver IP Addresses",
                    },
                },
                review: {
                    title: "Review",
                    fields: {
                        gatewayName: "Edge Name",
                        gatewayType: "Edge Type",
                        ipConfig: "IP Config",
                        staticIpCidr: "CIDR IP Address",
                        staticIpGateway: "Gateway IP Address",
                        staticIpNameservers: "Nameserver IP Addresses",
                        websocketsEnabled: "Use Websockets",
                        proxyEnabled: "Use a Proxy",
                        proxyHostname: "Proxy Hostname",
                        proxyProtoPort: "Proxy Protocol&Port",
                        proxyUsername: "Proxy Username",
                        proxyPassword: "Proxy Password",
                        dataSource: "Data Source",
                    },
                },
            },
            actions: {
                messages: {
                    successfullyCreated: "Edge successfully created",
                    successfullyUpdated: "Edge successfully updated",
                },
            },
            buttons: {
                submitBtnText: "Submit",
            },
        },
        addDataSourceDialog: {
            title: {
                add: "Connect a Data Source",
                edit: "Edit the Data Source",
            },
            fields: {
                gatewayIp: "Edge IP or Hostname",
                sourceDescription: "Name",
                sourceEnabled: "Enabled",
                sourceType: "Type",
                sourceHostname: "Data Source IP or Hostname",
                sourceProtocol: "Protocol",
                sourcePort: "Port",
                chooseType: "Choose type",
            },
            fieldsHelp: {
                gatewayIp:
                    "The IP address or FQDN of the Edge device this Data Source will use when connecting.",
            },
            messages: {
                addedSuccessfully:
                    "Data Source added successfully.",
                editedSuccessfully: "Data Source updated successfully",
            },
            validation: {
                invalid: "Invalid",
            },
            buttons: {
                submitBtnText: "Submit",
                cancelBtnText: "Close",
            },
        },
        setDataSourceSettingsDialog: {
            title: "Change Preferred Data Source",
            preferredLabel: "Preferred Data Source",
            actions: {
                change: "change",
            },
            messages: {
                topHelp:
                    "Riverbed IQ ingests metrics from multiple data sources, and the same metrics often can be ingested from multiple data source types. To reduce the amount of data ingest, a data source type must be configured as preferred.",
                settingsUpdated: "Data Source settings successfully updated.",
                bottomHelp:
                    "WARNING: Changing the preferred data source will reset baselines used for anomaly detection and it will require a few days for the system to train against the data ingested from the new preferred data source. Also, there could be performance implications with a large AppResponse deployment.",
                bottomHelp2:
                    "You may want to consult Riverbed IQ technical support before making any changes.",
            },
            sources: {
                appResponse: "Prefer AppResponse Data Sources",
                netProfiler: "Prefer NetProfiler Data Source",
            },
            buttons: {
                submitBtnText: "Apply",
                cancelBtnText: "Cancel",
            },
        },
        setDataSourceCredentialsDialog: {
            title: "Set Credentials",
            subTitle: "Enter data source credentials to allow Alluvio Edge to ingest data and make runbook queries.",
            fields: {
                dataSource: "Data Source",
                username: "Username",
                password: "Password"
            },
            messages: {
                credentialsUpdated: "Data Source credentials successfully set."
            },
            buttons: {
                submitBtnText: "Apply",
                cancelBtnText: "Cancel",
            },
        },
        dataSourceView: {
            stateLabel: "State",
            statusLabel: "Status",
            metricVersionLabel: "Metric Version",
            productVersionLabel: "Product Version",
            lastDataIngestion: "Last data ingestion",
            lastReceivedData: "Last data received",
            retreiveErrorMessage: "Could not be retrieved",
            elapsedSuffix: "ago",
            state: {
                enabled: "Enabled",
                disabled: "Disabled",
            },
            status: {
                ok: "Up",
                warning: "Warning",
                disabled: "Disabled",
                failed: "Failed",
                unknown: "Unknown",
                reconfiguring: "Reconfiguring",
            },
            columns: {
                sourceName: "Data Source",
                sourceHostname: "Hostname or IP",
                sourceType: "Type",
                sourceInfo: "Info",
                sourceIngestion: "Data Ingestion",
                sourceEnabled: "Enabled",
                sourceUrl: "URL",
            },
            actions: {
                edit: "Edit",
                reconfigure: "Reconfigure",
                changePreferredDataSource: "Change Preferred Data Source",
                setDataSourceCredentials: "Set Credentials",
                delete: "Delete",
            },
            messages: {
                deleteConfirmMsg: "Are you sure you want to delete {0}?",
                downloadVmImageWarning: "External link warning:",
                downloadVmImageMsg:
                    "Clicking this link will download the image from external site",
            },
        },
        finalDialog: {
            title: {
                add: "Add an Edge",
                edit: "Edit an Edge",
            },
            actions: {
                successfullyAdded: "Edge Added Successfully!",
                successfullyUpdated: "Edge Updated Successfully!",
            },
            nextSteps: "Next Steps:",
            steps: {
                step1: {
                    text1: "- Obtain the ",
                    vmImage: "VM Image",
                    text2: " & ",
                    cloudInitScript: "cloud-init ",
                    isoImage: "ISO Image ",
                    text3: "files",
                    hint: "(You can always come back here and download these files again if necessary)",
                },
                step2: "- Deploy the VM, including the cloud-init configuration",
                step3: "- Come back to this page and add data sources once the Edge is up and reachable",
            },
            buttons: {
                closeBtnText: "Close",
            },
            downloadStarted: "Download started",
        },
    },
    PAYWALL: {
        status: {
            active: "ACTIVE",
            inactive: "INACTIVE",
            cancelled: "CANCELLED",
        },
        inactiveText:
            "Your subscription has ended. Please contact your Riverbed account representative to renew it.",
        cancelledText:
            "Contact your Riverbed account representative to reach the full potential of your digital investment with Riverbed",
        cancelledHeader: "Ready to Get Going",
        contactUs: "CONTACT US",
    },
    access: {
        forbiddenTitle: "No Access",
        forbiddenMessage: "You are not authorized to view this page",
    },
    metadata: {
        title: "Entities",
        columns: {
            name: "Name",
            type: "Type",
            reportedBy: "Reported By",
            lastUpdateTime: "Last Updated",
        },
    },
    fakeData: {
        interfaceRunbookName: "Interface Congestion",
        deviceRunbookName: "Device Down",
        applicationRunbookName: "Application Performance",
        locationRunbookName: "Location Issue",
    },
    showingMockData: "*Showing Mock Data",
    showingPartiallyMockedData: "*Partially Mocked Data",
    tooltips: {
        ip: "IP Address:",
        cliIp: "Client IP Address:",
        srvIp: "Server IP Address:",
        ifIndex: "Ifindex:",
        ifAlias: "Ifalias:",
        ifDescr: "Ifdescr:",
        ifIps: "Interface IPs:",
        name: "Name:",
        cliName: "Client Name:",
        srvName: "Server Name:",
        location: "Location:",
        locationType: "Location Type:",
        country: "Country:",
        application: "Application:",
        cliLocation: "Client Location:",
        srvLocation: "Server Location:",
        vendor: "Vendor:",
        model: "Model:",
        osVersion: "OS Version:",
        serialNumber: "Serial Number:",
        type: "Type:",
        isGateway: "Is Gateway:",
        inboundSpeed: "Inbound Speed:",
        outboundSpeed: "Outbound Speed:",
        protocolName: "Protocol:",
        protocolNumber: "Protocol Number:",
        port: "Port:",
        dscpName: "DSCP:",
        dscpNumber: "DSCP Number:",
        deviceIp: "Device IP Address:",
        deviceName: "Device Name:",
        deviceLocation: "Device Location:",
        deviceVendor: "Device Vendor:",
        deviceModel: "Device Model:",
        deviceOsVersion: "Device OS Version:",
        deviceSerialNumber: "Device Serial Number:",
        deviceType: "Device Type:",
        deviceIsGateway: "Device Is Gateway:",
        appName: "Application:",
        appType: "Type:",
        appVersion: "Version:",
        ipDeviceName: "User Device Name:",
        ipUsername: "User Name:",
        ipUserDeviceIsp: "User Device ISP:",
        ipUserConnectionLocation: "User Connection Location:",
        ipWifiBssid: "Wifi BSSID:",
        ipWifiChannel: "Wifi Channel:",
        ipWifiSsid: "Wifi SSID:",
        ipPhysicalLocationName: "Physical Location:",
        ipPhysicalLocationGeoCity: "Physical Location City:",
        ipPhysicalLocationGeoState: "Physical Location State:",
        ipPhysicalLocationGeoCountry: "Physical Location Country:",
        tags: "Tags:",
        dataSource: "Vantage Point:",
    },
    entity: {
        webhookPreText: "webhook run",
    },
    formatter: {
        unknownInterface: "Unknown Interface",
        unknownDevice: "Unknown Device",
        unknownHost: "Unknown Host",
        unknownClient: "Unknown Client",
        unknownServer: "Unknown Server",
        unknownHostLocation: "Unknown Location",
        unknownClientLocation: "Unknown Client Location",
        unknownServerLocation: "Unknown Server Location",
        unknownProtocol: "Unknown Protocol",
        unknownPort: "Unknown Port",
        unknownDscp: "Unknown DSCP",
        unknownUserDevice: "Unknown User Device",
        unknownApplication: "Unknown Application",
        unknownKey: "Unknown",
    },
    conditionTreeBuilder: {
        conditionBlockType: {
            AND: "And",
            OR: "Or",
            NAND: "! And",
            NOR: "! Or",
            NOT: "Not",
        },
    },
    chartToolbar: {
        chartSettingsDialogTitle: "Chart Settings",
        fullscreenTooltip: "View full screen",
        settingsTooltip: "Change chart settings",
        moreTooltip: "More...",
        fullScreenMenuItem: "View Full Screen",
        settingsMenuItem: "Chart Settings",
    },
    runbookDetails: {
        title: "Runbook Details",
    },
    runbookInvocations: {
        title: "On-Demand Runbook Analyses",
        newButtonText: "Launch Runbook",
        runRunbook: "Run Runbook",
        runbookStatus: "Runbook Status",
        columns: {
            name: "Runbook",
            entity: "Launched On",
            description: "Description",
            trigger: "Trigger",
            timestamp: "Launch Time",
            startTime: "Start Time",
            endTime: "End Time",
            user: "Triggered By",
        },
        invokeRunbookDialogTitle: "Launch Runbook",
        invokeRunbookOnDemandDialogTitle: "Run Runbook",
        invokeRunbookDialogIntroText:
            "Select the runbook that you want to run and provide the inputs for the runbook:",
        invokeReRunRunbookDialogIntroText:
            "Provide the end time reference to re-run the runbook:",
        okBtnText: "OK",
        cancelBtnText: "Cancel",
        runBtnText: "Run",
        runbookSuccessfullyQueuedMsg:
            "Runbook successfully added to the queue.",
        runbookWillStartMomentarily: "Runbook will start in a moment.",
        runbookProgressCanBeAccessedFrom:
            "Runbook progress and result can be accessed from",
        runbookAnalyses: "Runbook Analyses",
        viewRunbookProgress: "View runbook progress",
        stayOnPage: "Stay on this page",
        refreshStatus: "Refresh Status",
        refreshingStatus: "Refreshing status",
        launchErrorText:
            "There was an error launching the runbook analysis, please try again later",
        filterNames: {
            runbook: "Runbook",
        },
        tooltips: {
            name: "Name:",
            email: "Email:",
        },
        unknownUser: "Unknown",
        entityTypeLabel: "Entity Type:",
        entityTypeInputPlaceholder: "Choose entity type",
        entityTypeAny: "Any",
        primaryEntityLabel: "Primary Entity",
        primaryEntityNotSet: "Not set",
        invalidIpAddress: "Invalid IP address",
        ipv4v6: "ipv4 or ipv6 address",
        additionalParametersLabel: "Additional Parameters",
        referenceTimeLabel: "Reference Time",
        onDemandVarMapping: {
            inbound: {
                name: "Name",
                type: "Type",
                unit: "Unit",
                set: "Value",
            },
            selectAuthenticationProfileText: "Select an authentication profile",
            selectAlluvioEdgeText: "Select an Edge",
            staticInputPlaceHolder: "Enter a value",
        },
        noOnDemandRunbooksDefined: "No on-demand runbooks have been defined."
    },
    primaryIndicatorView: {
        indicatorsTitle: "Incident Source",
        debugDialogTitle: "Debug Information",
        copyBtnText: "Copy To Clipboard",
        okBtnText: "Close",
    },
    indicatorDetailsView: {
        addTimeEarlierTooltip: "Move the start time {increment} hours earlier",
        addTimeLaterTooltip: "Move the start time {increment} hours later",
        shiftTimeEarlierText: "Shift Left {increment} Hours",
        shiftTimeEarlierTooltip: "Shift the start time {increment} hours earlier",
        shiftTimeLaterText: "Shift Right {increment} Hours",
        shiftTimeLaterTooltip: "Shift the start time {increment} hours later",
        restoreTooltip: "Restore the incident interval",
        referenceDropdownTooltip: "Shift the chart reference to either the start or the end of the incident",
        referenceStartOptionText: "Incident Start",
        referenceEndOptionText: "Incident End",
        referenceStartButtonTooltip: "Shift the chart reference to the start of the incident",
        referenceStartButtonText: "Start",
        referenceEndButtonTooltip: "Shift the chart reference to the end of the incident",
        referenceEndButtonText: "End"
    },
    timelineOutputsView: {
        title: "Incident Timeline",
        dialogTitle: "Timeline Event Detail",
        namedMap: {
            runbookExecuted: "Runbook Executed",
            runbookRerun: "Runbook Rerun",
            primaryIndicator: "Primary Indicator",
            correlatedIndicator: "Correlated Indicator",
            correlatedIndicators: "Correlated Indicators",
            incidentStart: "Incident Start/Created",
            incidentEnd: "Incident End",
        },
        series: {
            lifecycle: "Lifecycle",
            iqEvents: "IQ Events",
            indicators: "Indicators"
        },
        notes: "Open Notes"
    },
    priorityReasonsView: {
        noReasonSpecifiedText: "No reasons specified",
    },
    jsonViewer: {
        tabTreeLabel: "Tree",
        tabTextLabel: "Text",
        arrayText: "Array",
    },
    legalPage: {
        title: "Copyright and Legal",
    },
    thirdPartyIntegrations: {
        title: "Third Party Authentication",
        add: "Add an Authentication Profile",
        elapsedSuffix: "ago",
        columns: {
            name: "Profile Name",
            description: "Description",
            method: "Method",
            createdBy: "Created By",
            createdOn: "Created on",
            lastUpdateBy: "Last Updated By",
            lastUpdateTime: "Last Updated On",
            enabled: "Enabled",
            verified: "Verified",
            references: "References",
        },
        more: {
            edit: "Edit",
            referencingRunbooks: "View Referencing Runbooks",
            delete: "Delete",
        },
        addAuthProfile: {
            title: "Add an Authentication Profile",
            editTitle: "Edit the Authentication Profile",
            panels: {
                basicDetails: {
                    title: "Basic Details",
                    fields: {
                        profileName: {
                            label: "Profile Name",
                            placeholder:
                                "Enter name for the authentication profile",
                        },
                        profileDescription: {
                            label: "Description",
                            optional: "(optional)",
                            placeholder:
                                "Enter description about what the authentication is meant for",
                        },
                        profileAuthMethod: {
                            label: "Authentication Method",
                            selectMethod: "Select Method",
                            basicAuth: "Basic Authentication",
                            apiKey: "API Key Authentication",
                            openAuth: "OAuth 2.0",
                            clientCertificateAuth:
                                "Client Certificate (mTLS) Authentication",
                            awsSignatureAuth: "AWS Signature"
                        },
                    },
                },
                basicAuthParams: {
                    title: "Basic Authentication Parameters",
                    stepTitle: "Authentication Parameters",
                    fields: {
                        profileUsername: {
                            label: "Username",
                            placeholder: "Username for authentication",
                        },
                        profilePassword: {
                            label: "Password",
                            placeholder: "Password for authentication",
                        },
                    },
                },
                apiKeyDetails: {
                    title: "API Key Details",
                    fields: {
                        apiKey: {
                            label: "Key",
                            placeholder: "Enter key for API Key Authentication",
                            placeholderRequestHeaderKey: "Enter Key"
                        },
                        passingMethod: {
                            label: "Send via",
                            methods: {
                                selectMethod: "Select Method",
                                queryParameter: "Query Parameter",
                                authHeader: "Authorization Header",
                                requestHeader: "Request Header",
                                requestBodyField: "Request Body Field",
                            },
                        },
                        queryStringParamName: {
                            label: "Query Parameter Name",
                            placeholder: "Enter name of the Query parameter",
                            sectionName: "Query Parameters",
                            addQueryParameter: "Add Query Parameter",
                        },
                        authScheme: {
                            label: "HTTP Authentication Scheme",
                            placeholder: "Select or type scheme for HTTP authentication",
                            encoded: "Encode Key",
                        },
                        customHeaderName: {
                            label: "Request Header Name",
                            placeholder: "Enter name of the Request header",
                            sectionName: "Request Headers",
                            addRequestHeader: "Add Request Header"
                        },
                        tokenName: {
                            label: "Replace Text below with Key in Request Body",
                            placeholder: "Enter name of the Request body field",
                            sectionName: "Request Body Fields",
                            addQueryParameter: "Add Request Body Field",
                        },
                    },
                },
                oAuth2Details: {
                    title: "OAuth 2.0 Authentication Parameters",
                    fields: {
                        grantType: {
                            label: "Grant Type",
                            placeholder: "Select grant type",
                        },
                        clientId: {
                            label: "Client Id",
                            placeholder: "Enter id of the Client",
                        },
                        clientSecret: {
                            label: "Client Secret",
                            placeholder: "Enter secret of the Client",
                        },
                        authUri: {
                            label: "Authentication URI",
                            placeholder: "Enter URI for Authentication",
                        },
                        scope: {
                            label: "Scope",
                            placeholder: "Enter URL for Scope",
                        },
                        optParams: {
                            subtitle: "Custom Parameters in Token Request",
                            key: "Key",
                            value: "Value",
                            send: "Send Via",
                            addParam: "Add Custom Parameter",
                            switch: "Show Advanced Configuration",
                            tooltip: "Delete",
                            empty: "No custom parameter token defined",
                            error: "All fields are required",
                        },
                    },
                },
                clientCertificateAuthParams: {
                    title: "Client Certificate (mTLS) Authentication Parameters",
                    fields: {
                        clientCertificateSource: {
                            label: "mTLS Client Certificate Source",
                            placeholder: "Select",
                        },
                        clientCertificateUsingPemFormat: {
                            label: "Client Certificate (PEM format)",
                            placeholder:
                                "Paste from clipboard or upload client certificate in PEM format",
                        },
                        clientPrivateKey: {
                            label: "Client Private Key",
                            labelWhenClear: "Client Private Key *",
                            placeholder:
                                "Paste from clipboard or upload client private key",
                        },
                        passphrase: {
                            label: "Passphrase",
                            placeholder:
                                "Enter the passphrase used to encrypt the client private key",
                        },
                    },
                    upload: "Upload",
                    uploaded: "Uploaded",
                    clear: "Clear",
                    tooltip:
                        "Please make sure that the pasted or uploaded information contains the header and footer lines which determine the type of message encoded.",
                },
                awsSignatureAuthParams: {
                    title: "AWS Signature Authentication Parameters",
                    fields: {
                        version: {
                            label: "Version",
                            placeholder: "Select Version",
                        },
                        accessKeyID: {
                            label: "AWS Access Key ID",
                            placeholder: "Enter AWS access key ID",
                        },
                        secretAccessKey: {
                            label: "AWS Secret Access Key",
                            placeholder: "Enter AWS secret access key",
                        },
                        region: {
                            label: "AWS Region",
                            placeholder: "Select region in which the AWS server is hosted",
                        },
                        service: {
                            label: "AWS Service",
                            placeholder: "Enter AWS service to which to connect",
                        },
                    },
                },
                endpointType: {
                    title: "Specify whether endpoint is public or private",
                    stepTitle: "Public or Private Endpoint",
                    fields: {
                        sendQueriesTo: "Send queries to:",
                        publicEndpoint: "Public endpoint",
                        privateEndpoint:
                            "Private endpoint via an authorized Edge:",
                        alluvioEdge: "Edge",
                    },
                    note: "Note: Trusted certificates can be added on the ",
                    pageRefName: "Edge Configuration page",
                    addAlluvioEdge: "Add Authorized Edge",
                    noAlluvioEdge: "No Edge available",
                    deletedAlluvioEdgeName: "Deleted Edge",
                },
                testQuery: {
                    title: "Test Query (optional)",
                    stepTitle: "Test Query",
                    fields: {
                        requestUrl: {
                            requestVerb: {
                                get: "GET",
                                post: "POST",
                                put: "PUT",
                                patch: "PATCH",
                                delete: "DELETE",
                                copy: "COPY",
                                head: "HEAD",
                                options: "OPTIONS",
                                link: "LINK",
                                unlink: "UNLINK",
                                purge: "PURGE",
                                lock: "LOCK",
                                unlock: "UNLOCK",
                                propfind: "PROPFIND",
                                view: "VIEW",
                            },
                            urlPlaceholder: "https://www.example.com",
                        },
                        httpHeaders: {
                            label: "HTTP Headers",
                            defaultValue:
                                "Content-Type: application/json\nAccept: application/json",
                            placeholder: "Enter an HTTP Header...",
                        },
                        httpPayload: {
                            label: "HTTP Payload (optional)",
                            placeholder: "Enter payload...",
                        },
                    },
                },
                verifyResponse: {
                    title: "Verify Response",
                    runQuery: "Run Test Query",
                    sendRequestTo: "Send request to:",
                    selectAlluvioEdge: "Select Edge",
                },
                review: {
                    title: "Review",
                    fields: {
                        profileName: "Profile Name",
                        profileDescription: "Profile Description",
                        authMethod: "Authentication Method",
                        endpoint: "Endpoint",
                        verified: "Verified",
                    },
                },
            },
            actions: {
                messages: {
                    successfullyCreated:
                        "Authentication profile successfully created",
                    successfullyUpdated:
                        "Authentication profile successfully updated",
                },
            },
            buttons: {
                submit: {
                    connector: "Save",
                    profile: "Submit",
                }
            },
        },
        deleteAuthProfile: {
            delete: {
                title: "Delete Authentication Profile",
                dialog: "{0} runbooks are referencing this authentication profile. Are you sure you want to delete this authentication profile?",
                dialogNoRunbooks:
                    "Are you sure you want to delete this authentication profile?",
                action: "Delete Anyway",
            },
            disable: {
                title: "Disable Authentication Profile",
                dialog: "{0} runbooks are referencing this authentication profile. Are you sure you want to disable this authentication profile?",
                dialogNoRunbooks:
                    "Are you sure you want to disable this authentication profile?",
                action: "Disable Anyway",
            },
            buttons: {
                referencingRunbooks: "View Referencing Runbooks",
                cancel: "Cancel",
            },
        },
        referencingRunbooks: {
            title: "What references {0}?",
            runbookUpdate: "Last updated by {user} on {time}",
        },
        success: {
            AUTHENTICATION_PROFILES_VERIFY_PROFILE_THIRD_PARTY_RESPONSE_NO_CONTENT:
                "The server has successfully processed the request, but there's no content to be presented in the response body.",
        },
        errors: {
            title: "Error",
            AUTHENTICATION_PROFILES_DUPLICATE_PROFILE_NAME:
                "A profile name with the same name already exists.",
            AUTHENTICATION_PROFILES_INVALID_PROFILE: "Invalid profile?",
            AUTHENTICATION_PROFILES_INVALID_PROFILE_PROPERTY:
                "Invalid profile property",
            AUTHENTICATION_PROFILES_INVALID_IFMATCH_HEADER_VALUE:
                "Invalid ifmatch",
            AUTHENTICATION_PROFILES_PROFILE_NOT_FOUND: "Profile not found",
            AUTHENTICATION_PROFILES_PROFILE_ALREADY_MODIFIED:
                "Profile already modified",
            AUTHENTICATION_PROFILES_VERIFY_PROFILE_TIMEOUT_ERROR:
                "Failed to verify the details of an authentication profile associated with tenant with ID: {TenantId} due to operation taking too much time to respond",
            AUTHENTICATION_PROFILES_VERIFY_PROFILE_THIRD_PARTY_RESPONSE_TOO_BIG_ERROR:
                "Failed to verify the details of an authentication profile associated with tenant with ID: {TenantId} due to the incoming HTTP response being too large; for profile verification purposes, the third party should quickly respond with a very small amount of data",
            AUTHENTICATION_PROFILES_AUTHORIZATION_PROFILE_DISABLED:
                "Unable to use authentication profile since it has been disabled.",
            AUTHENTICATION_PROFILES_GET_AUTHORIZATION_PROFILE:
                "Failed to get authentication profile",
            AUTHENTICATION_PROFILES_EDGE_DEVICE_NOT_FOUND:
                "Could not find edge device.",
            AUTHENTICATION_PROFILES_EDGE_DEVICE_PUBLIC_KEY_NOT_FOUND:
                "Unable to contact edge device since its public key could not be found.",
            AUTHENTICATION_PROFILES_THIRD_PARTY_RESPONSE_SIZE_EXCEEDS_THRESHOLD:
                "The size of the response message is larger than the maximum allowed value of 256 KB. Please ensure the third party response does not go beyond this threshold.",
            AUTHENTICATION_PROFILES_THIRD_PARTY_CALL_FAILED:
                "Call to third party failed due to an unexpected error.",
            AUTHENTICATION_PROFILES_THIRD_PARTY_CONNECTION_FAILED:
                "Failed to send HTTP request to third party due to a network or DNS related error.",
            AUTHENTICATION_PROFILES_THIRD_PARTY_INVALID_CERTIFICATE:
                "Failed to receive HTTP response from third party due to an invalid server certificate.",
            AUTHENTICATION_PROFILES_THIRD_PARTY_ENRICH_REQUEST_FAILED:
                "Failed to enrich HTTP request.",
            AUTHENTICATION_PROFILES_THIRD_PARTY_REQUEST_TIMEOUT:
                "Failed to send HTTP request to third party due to a timeout.",
            AUTHENTICATION_PROFILES_THIRD_PARTY_RESPONSE_READ_FAILURE:
                "Failed to read third party HTTP response contents due to an unexpected error.",
            AUTHENTICATION_PROFILES_THIRD_PARTY_INVALID_INPUT:
                "Unable to contact edge device due to invalid input. Please contact support.",
            AUTHENTICATION_PROFILES_THIRD_PARTY_INVALID_DIGITAL_SIGNATURE:
                "The third-party response message does not have a valid digital signature. Please contact support.",
            AUTHENTICATION_PROFILES_THIRD_PARTY_INVALID_OAUTH2_PARAMETERS:
                "Unable to perform HTTP call against third-party endpoint due to invalid OAuth2 parameters",
        },
    },
    userPreferences: {
        title: "User Preferences",
        debugDialogTitle: "Debug Information",
        okBtnText: "OK",
        resetButtonText: "Reset All User Preferences",
        resetWhatsNewButtonText: "Reset What's New Preferences",
        applyButtonText: "Apply",
        themeSection: {
            title: "Theme Settings",
            clearSuccessText: "Theme User Preferences Cleared Successfully!",
            clearErrorText: "Unable to Clear Theme User Preferences!",
            themeControl: {
                label: "Theme:",
                darkOptionText: "",
                lightOptionText: "",
            },
        },
        searchSection: {
            title: "Search Settings",
            clearSuccessText: "Search User Preferences Cleared Successfully!",
            clearErrorText: "Unable to Clear Search User Preferences!",
            facetControl: {
                label: "Facet Mode:",
                initialOptionText: "Keep Initial",
                replaceOptionText: "Replace After Each Search *",
            },
            pageSizeControl: {
                label: "Search Page Size:",
            },
            searchLimitControl: {
                label: "Search Result Limit:",
            },
            allowBulkChangesControl: {
                label: "Allow Bulk Incident Status Changes:",
            },
            showSearchQueriesControl: {
                label: "Show Saved Search Queries:",
            },
            searchLoadPagesOnDemandControl: {
                label: "Server Side Sorting and Pagination:",
            },
            showSearchTypeControl: {
                label: "Allow Search Type Changes:",
            },
            sortFacetsControl: {
                label: "Sort Selected Facets First:",
            },
            saveLastSearchQueryControl: {
                label: "Save Last Search Query to Preferences:",
            },
            searchEngineControl: {
                label: "Search Engine:",
                cognitiveOptionText: "Cognitive Search",
                correlationDirectText: "Correlation Engine *",
                correlationDalText: "Correlation Engine via DAL",
            },
        },
        subflowsSection: {
            title: "Subflows Settings",
            clearSuccessText: "Subflows Preferences Cleared Successfully!",
            clearErrorText: "Unable to Clear Subflows User Preferences!",
            modeControl: {
                label: "Get Subflows From:",
                fakeDataOptionText: "Fake Data",
                incidentRunbookOptionText: "Incident Runbook List",
                subflowRunbookOptionText: "Subflow Runbook List",
            },
            multipleOutputs: {
                label: "Enable multiple outputs",
            },
            versionControl: {
                label: "Enable Versioning",
            },
            addMenuIntegrationsControl: {
                label: "Add menu: put integrations in folder",
            },
            addMenuVersionsControl: {
                label: "Add menu: show versions",
            },
        },
        menuSection: {
            title: "Menu Settings",
            clearSuccessText: "Menu User Preferences Cleared Successfully!",
            clearErrorText: "Unable to Clear Menu Search User Preferences!",
            menuUserPreferencesControl: {
                label: "Show User Preferences:",
            },
            menuDashboardsControl: {
                label: "Show Dashboards:",
            },
            menuExplorerControl: {
                label: "Show Explorer Menu:",
            },
            menuSubflowControl: {
                label: "Show Subflows:",
            },
            menuNpmPlusControl: {
                label: "Show NPM Plus:",
            },
            menuIntegrationLibraryControl: {
                label: "Show Integration Library:",
            },
        },
        runbookInputsSection: {
            title: "Runbook Inputs Settings",
            clearSuccessText:
                "Runbook Inputs User Preferences Cleared Successfully!",
            clearErrorText: "Unable Clear Runbook Inputs User Preferences!",
        },
        cloudIMSection: {
            title: "CloudIM Settings",
            clearSuccessText:
                "CloudIM user preferences cleared successfully.",
            clearErrorText: "Failed to clear CloudIM user preferences!",
            demoMode: "Theater View demo mode"
        },
    },
    scheduleRunbook: {
        navMenu: "Runbook Schedule",
        openWizardBtn: "Schedule Runbook",
        wizard: {
            title: "Schedule Runbook",
            panelSelect: {
                title: "Select runbook",
                panelTitle: "Select runbook",
                labelRunbook: "Select runbook to run on a schedule",
                note: "Note: only on-demand runbooks can run on a schedule.",
            },
            panelSpecify: {
                title: "Specify runbook inputs",
                panelTitle: "Specify runbooks",
                labelLocation: "Location",
                labelThreshold: "Threshold",
                labelVantage: "Vantage Point",
                labelSNow: "ServiceNow Instance",
                errorInvalidBooleans: "The additional parameters boolean values need to be true or false.",
                errorInvalidIpAddresses: "The additional parameters IP addresses need to be valid.",
            },
            panelSchedule: {
                title: "Select schedule for runbook",
                panelTitle: "Schedule",
                labelSaved: "Select saved schedule",
                labelNew: "Define new schedule",
                placeHolder: "Enter schedule name...",
                labelStart: "Start on",
                labelEST: "Time in EST timezone",
                labelRepeat: "Repeat",
                labelEvery: "Every",
                labelHrs: "hour(s)",
                labelDays: "day(s)",
                labelWeeks: "week(s)",
                labelOn: "on",
                summary: {
                    runs: "Runs every",
                    starting: "starting at"
                },
                ctas: {
                    revert: "Revert",
                    save: "Save",
                    edit: "Edit",
                    delete: "Delete"
                },
                warnings: {
                    delete: "You are about to delete a saved schedule. Are you sure you want to continue?",
                    noDups: "This name already exists.",
                    isEdit: "You have unsaved changes. Are you sure you want to continue?",
                    dayRequired: "Please select a day(s) of the week.",
                    required: "Name is required"
                },
                queryMessages: {
                    success: "Runbook Schedule deleted",
                    error: "Runbook Schedule couldn't be deleted",
                    editSuccess: "Runbook Schedule edited successfully",
                    editError: "Runbook Schedule couldn't be edited"
                }
            },
            panelReview: {
                title: "Review",
                panelTitle: "Review",
                labelDesc: "Schedule Description",
                listApp: "Application: ",
                listThreshold: "Threshold: ",
                listTO: "Timeout: ",
                callOutNext: "Next run is scheduled for ",
            },
            queryMessages: {
                successCreate: "Runbook Schedule created",
                successEdit: "Runbook Schedule edited",
                errorCreate: "Runbook Schedule couldn't be created",
                errorEdit: "Runbook Schedule couldn't be edited",
                successCreateJob: "Runbook Schedule job created",
                successEditJob: "Runbook Schedule job edited",
                errorCreateJob: "Runbook Schedule job couldn't be created",
                errorEditJob: "Runbook Schedule job couldn't be edited"
            }
        },
        scheduleJobDeleted: "The runbook schedule job was deleted successfully.",
        errorDeletingScheduleJob: "Error deleting the runbook schedule job.",
        scheduleJobStatusChanged: "The runbook schedule job status was changed successfully.",
        errorChangingScheduleJobStatus: "Error changing the runbook schedule job status.",
        changeRunbookScheduleJobStatusDialog: {
            title: "Change runbook schedule job status",
            confirmDisable: "Are you sure you want to disable this runbook schedule job?",
            confirmEnable: "Are you sure you want to enable this runbook schedule job?",
            couldNotEnableOrDisable: "Could not {0} the runbook schedule job.",
            limitErrorEnable: "The job can't be enabled because there are already {0} enabled jobs.",
            limitErrorCreate: "No new schedule jobs can be created because there are already {0} enabled jobs."
        }
    },
    incidentSearch: {
        types: {
            incident: {
                title: "Incidents",
                icon: "issue",
            },
            device: {
                title: "Devices",
                icon: "desktop",
            },
            interface: {
                title: "Interfaces",
                icon: "group-objects",
            },
            application: {
                title: "Applications",
                icon: "applications",
            },
            location: {
                title: "Locations",
                icon: "locate",
            },
            tcpconnection: {
                title: "TCP Connections",
                icon: "globe-network",
            },
            properties: {
                title: "Custom Properties",
                icon: "tag",
            },
            ondemandrunbooks: {
                title: "Runbook Analyses",
                icon: "selection",
            },
            runbookschedules: {
                title: "Runbook Scheduling",
                icon: "calendar",
            },
        },
        debugDialogTitle: "Debug Information",
        errorDialogTitle: "Error",
        errorDialogText:
            "There was an error running the search query!  Please clear the search string and clear all filters and try again.",
        columns: {
            name: "Name",
            description: "Description",
            tags: "Custom Properties",
            ipaddr: "IP Address",
            ifindex: "Ifindex",
            ifAlias: "IF Alias",
            ifDescription: "IF Description",
            inboundSpeed: "Inbound Speed",
            outboundSpeed: "Outbound Speed",
            location: "Location",
            locationType: "Type",
            deviceType: "Type",
            city: "City",
            state: "State",
            country: "Country",
            lastUpdatedAt: "Last Updated At",
            lastUpdatedBy: "Last Updated By",
            propertyTypes: "Types",
            propertyValues: "Values",
            model: "Model",
            serialNumber: "Serial Number",
            type: "Type",
            vendor: "Vendor",
            elementType: "Element Type",
            isGateway: "Gateway",
            taggedLocationCount: "Locations",
            taggedDeviceCount: "Devices",
            taggedApplicationCount: "Applications",
            taggedInterfaceCount: "Interfaces",
            time: "Time",
            client: "Client",
            server: "Server",
            serverPort: "Server Port",
            osVersion: "OS",
            bytes: "Bytes (S2C)",
            retrans: "% Retrans",
            rtt: "RTT",
            serverResponseTime: "Server Resp Time",
            actions: "Actions",
            runbook: "Runbook",
            runbookAnalysis: "Runbook Analysis",
            entityKind: "Entity Type",
            executionMethod: "Execution Method",
            executedBy: "Executed By",
            status: "Status",
            dataSourceType: "Data Source Type",
            dataSourceName: "Data Source Name",
        },
        incidentControl: {
            applyStatusToAllLabel: "all incidents",
            idDialog: {
                title: "Loading incidents",
                text: "Loading incident ids!",
            },
        },
        searchBar: {
            tooltip:
                'Enter the search text, denoting excluded tokens with a "-".  Use quotes to search for phrases',
            searchBtnText: "Search",
            placeholderText: {
                incident: "Search in incidents",
                device: "Search in devices",
                interface: "Search in interfaces",
                application: "Search in applications",
                location: "Search in locations",
            },
        },
        filterControl: {
            queryLabel: "Query:",
            noneSelectedOption: "Load saved query",
            querySelectTooltip: "Run the currently selected query",
            deleteTooltip: "Delete selected query",
            saveTooltip: "Save current applied query",
            moreTooltip: "Customize filters",
        },
        facetCTA: {
            clear: "Clear All",
            apply: "Apply Filters",
            saveQuery: "Save Query",
            cancelQuery: "Cancel",
        },
        facetView: {
            title: "Filters",
            menu: {
                expand: "Expand all",
                collapse: "Collapse all",
                customize: "Customize list",
            },
            facetSettingsDialog: {
                title: "Filter Section Settings",
                limitLabel: "Limit:",
                searchLabel: "Search:",
                columns: {
                    name: "Name",
                    count: "{type} Count",
                },
                typeNames: {
                    Incident: "Incident",
                    NetworkDevice: "Device",
                    NetworkInterface: "Interface",
                    Application: "Application",
                    Location: "Location",
                    CustomProperty: "Property",
                },
            },
            facets: {
                "incident/duration": {
                    name: "Ongoing State",
                    order: 0,
                    ongoingValue: "True",
                    endedValue: "False",
                },
                "incidents/isOngoing": {
                    name: "Ongoing State",
                    order: 0,
                    type: "incident",
                    searchSystem: "correlation_direct",
                    values: [true, false],
                    valueDisplayMap: {
                        true: "True",
                        false: "False",
                    },
                },
                COMPLETION_STATUS: {
                    name: "Ongoing State",
                    order: 0,
                    type: "incident",
                    searchSystem: "correlation_dal",
                    values: ["ONGOING", "COMPLETED"],
                    valueDisplayMap: {
                        ONGOING: "True",
                        COMPLETED: "False",
                    },
                },
                "incident/status": {
                    name: "Status",
                    order: 1.3,
                    type: "incident",
                    searchSystem: "cognitive",
                    values: ["New", "Investigating", "Closed"],
                },
                "incidents/status": {
                    name: "Status",
                    order: 1.3,
                    type: "incident",
                    searchSystem: "correlation_direct",
                    values: ["New", "Investigating", "Closed"],
                },
                STATUS: {
                    name: "Status",
                    order: 1.3,
                    type: "incident",
                    searchSystem: "correlation_dal",
                    values: ["NEW", "INVESTIGATING", "CLOSED"],
                    valueDisplayMap: {
                        NEW: "New",
                        INVESTIGATING: "Investigating",
                        CLOSED: "Closed",
                        SUCCEEDED: "Succeeded",
                        SUCCEEDED_WITH_ERRORS: "Succeeded With Errors",
                        FAILEd: "Failed",
                        IN_PROGRESS: "In Progress"
                    },
                },
                "incident/priority": {
                    name: "Priority",
                    order: 1.2,
                    type: "incident",
                    searchSystem: "cognitive",
                    values: ["Critical", "High", "Moderate", "Low"],
                },
                "incidents/priority": {
                    name: "Priority",
                    order: 1.2,
                    type: "incident",
                    searchSystem: "correlation_direct",
                    values: ["Critical", "High", "Moderate", "Low"],
                },
                PRIORITY: {
                    name: "Priority",
                    order: 1.2,
                    type: "incident",
                    searchSystem: "correlation_dal",
                    values: ["LOW", "MODERATE", "HIGH", "CRITICAL"],
                    valueDisplayMap: {
                        LOW: "Low",
                        MODERATE: "Moderate",
                        HIGH: "High",
                        CRITICAL: "Critical",
                    },
                },
                "incident/eventCategory": {
                    name: "Incident Type",
                    order: 1.1,
                    type: "incident",
                    searchSystem: "cognitive",
                    values: [
                        "ApplicationLocationPerformanceIssue",
                        "DeviceDownIssue",
                        "InterfacePerformanceIssue",
                        "MultiDeviceDownIssue",
                    ],
                    valuesFull: [
                        "ApplicationLocationPerformanceIssue",
                        "SingleLocMultiAppPerformanceIssue",
                        "DeviceDownIssue",
                        "InterfacePerformanceIssue",
                        "InterfaceDownIssue",
                        "SiteOutageIssue",
                        "MultiDeviceDownIssue",
                        "SingleAppMultiLocPerformanceIssue"
                    ],
                    valueDisplayMap: {
                        ApplicationLocationPerformanceIssue:
                            "Application Location Performance Issue",
                        SingleLocMultiAppPerformanceIssue:
                            "Multiple Applications at a Single Location Performance Issue",
                        DeviceDownIssue: "Device Down Issue",
                        InterfacePerformanceIssue:
                            "Interface Performance Issue",
                        InterfaceDownIssue: "Interface Down Issue",
                        SiteOutageIssue: "Site Outage Issue",
                        MultiDeviceDownIssue: "Multi-Device Down Issue",
                        SingleAppMultiLocPerformanceIssue: "Single Application at Multiple Locations Performance Issue"
                    },
                },
                "incidents/eventCategory": {
                    name: "Incident Type",
                    order: 1.1,
                    type: "incident",
                    searchSystem: "correlation_direct",
                    values: [
                        "ApplicationLocationPerformanceIssue",
                        "DeviceDownIssue",
                        "InterfacePerformanceIssue",
                        "MultiDeviceDownIssue",
                    ],
                    valuesFull: [
                        "ApplicationLocationPerformanceIssue",
                        "SingleLocMultiAppPerformanceIssue",
                        "DeviceDownIssue",
                        "InterfacePerformanceIssue",
                        "InterfaceDownIssue",
                        "SiteOutageIssue",
                        "MultiDeviceDownIssue",
                        "SingleAppMultiLocPerformanceIssue"
                    ],
                    valueDisplayMap: {
                        ApplicationLocationPerformanceIssue:
                            "Application Location Performance Issue",
                        SingleLocMultiAppPerformanceIssue:
                            "Multiple Applications at a Single Location Performance Issue",
                        DeviceDownIssue: "Device Down Issue",
                        InterfacePerformanceIssue:
                            "Interface Performance Issue",
                        InterfaceDownIssue: "Interface Down Issue",
                        SiteOutageIssue: "Site Outage Issue",
                        MultiDeviceDownIssue: "Multi-Device Down Issue",
                        SingleAppMultiLocPerformanceIssue: "Single Application at Multiple Locations Performance Issue"
                    },
                },
                EVENT_CATEGORY: {
                    name: "Incident Type",
                    order: 1.1,
                    type: "incident",
                    searchSystem: "correlation_dal",
                    values: [
                        "APPLICATION_LOCATION_PERFORMANCE_ISSUE",
                        "DEVICE_DOWN_ISSUE",
                        "INTERFACE_PERFORMANCE_ISSUE",
                        "MULTI_DEVICE_DOWN_ISSUE",
                        "INTERFACE_DOWN_ISSUE",
                    ],
                    valuesFull: [
                        "APPLICATION_LOCATION_PERFORMANCE_ISSUE",
                        "DEVICE_DOWN_ISSUE",
                        "INTERFACE_PERFORMANCE_ISSUE",
                        "MULTI_DEVICE_DOWN_ISSUE",
                        "INTERFACE_DOWN_ISSUE",
                        "SiteOutageIssue",
                        "SingleLocMultiAppPerformanceIssue",
                        "SingleAppMultiLocPerformanceIssue"
                    ],
                    valueDisplayMap: {
                        APPLICATION_LOCATION_PERFORMANCE_ISSUE:
                            "Application Location Performance Issue",
                        SingleLocMultiAppPerformanceIssue:
                            "Multiple Applications at a Single Location Performance Issue",
                        DEVICE_DOWN_ISSUE: "Device Down Issue",
                        INTERFACE_PERFORMANCE_ISSUE:
                            "Interface Performance Issue",
                        INTERFACE_DOWN_ISSUE: "Interface Down Issue",
                        SiteOutageIssue: "Site Outage Issue",
                        MULTI_DEVICE_DOWN_ISSUE: "Multi-Device Down Issue",
                        SingleAppMultiLocPerformanceIssue: "Single Application at Multiple Locations Performance Issue"
                    },
                },
                "incident/triggeredOn/kind": {
                    name: "Entity Type",
                    order: 1,
                },
                "incident/triggeredOn/network_device/name": {
                    name: "Device Name",
                    order: 2.1,
                },
                "incident/triggeredOn/network_device/ipaddr": {
                    name: "Device IP Address",
                    order: 2.2,
                },
                "incident/triggeredOn/network_device/location/name": {
                    name: "Device Location",
                    order: 2.3,
                },
                "incident/triggeredOn/network_interface/name": {
                    name: "Interface Name",
                    order: 3.1,
                },
                "incident/triggeredOn/network_interface/ipaddr": {
                    name: "Interface IP Address",
                    order: 3.2,
                },
                "incident/triggeredOn/network_interface/ifindex": {
                    name: "Interface Ifindex",
                    order: 3.3,
                },
                "incident/triggeredOn/network_interface/location/name": {
                    name: "Interface Location",
                    order: 3.4,
                },
                "incident/triggeredOn/application_location/application/name": {
                    name: "Application Name",
                    order: 4.1,
                },
                "incident/triggeredOn/application_location/location/name": {
                    name: "Application Location",
                    order: 4.2,
                },
                "incident/correlatedIndicators/kind": {
                    name: "Entity Type",
                    order: 1,
                },
                "incident/correlatedIndicators/network_device/name": {
                    name: "Device Name",
                    order: 2.1,
                },
                "incident/correlatedIndicators/network_device/ipaddr": {
                    name: "Device IP Address",
                    order: 2.2,
                },
                "incident/correlatedIndicators/network_device/location/name": {
                    name: "Device Location",
                    order: 2.3,
                },
                "incident/correlatedIndicators/network_interface/name": {
                    name: "Interface Name",
                    order: 3.1,
                },
                "incident/correlatedIndicators/network_interface/ipaddr": {
                    name: "Interface IP Address",
                    order: 3.2,
                },
                "incident/correlatedIndicators/network_interface/ifindex": {
                    name: "Interface Ifindex",
                    order: 3.3,
                },
                "incident/correlatedIndicators/network_interface/location/name":
                {
                    name: "Interface Location",
                    order: 3.4,
                },
                "incident/correlatedIndicators/application_location/application/name":
                {
                    name: "Application Name",
                    order: 4.1,
                },
                "incident/correlatedIndicators/application_location/location/name":
                {
                    name: "Application Location",
                    order: 4.2,
                },
                "incident/indicators/entity/kind": {
                    name: "Entity Type",
                    order: 1.4,
                    type: "incident",
                    searchSystem: "cognitive",
                    values: [
                        "network_device",
                        "network_interface",
                        "application_location",
                    ],
                    valueDisplayMap: {
                        application_location: "Application",
                        network_device: "Device",
                        network_interface: "Interface",
                        location: "Location",
                    },
                },
                "incident/indicators/entity/network_device/name": {
                    name: "Device Name",
                    order: 2.1,
                },
                "incidents/incidentIndicators/indicator/networkDevice/name": {
                    name: "Device Name",
                    order: 2.1,
                },
                DEVICE_NAME: {
                    name: "Device Name",
                    order: 2.1,
                },
                "incident/indicators/entity/network_device/ipaddr": {
                    name: "Device IP Address",
                    order: 2.2,
                },
                "incidents/incidentIndicators/indicator/networkDevice/ipaddr": {
                    name: "Device IP Address",
                    order: 2.2,
                },
                DEVICE_IP_ADDRESS: {
                    name: "Device IP Address",
                    order: 2.2,
                },
                "incident/indicators/entity/network_device/hostname": {
                    name: "Device Hostname",
                    order: 2.3,
                },
                "incident/indicators/entity/network_device/location/name": {
                    name: "Device Location",
                    order: 2.4,
                },
                "incidents/incidentIndicators/indicator/networkDevice/location/name":
                {
                    name: "Device Location",
                    order: 2.4,
                },
                DEVICE_LOCATION_NAME: {
                    name: "Device Location",
                    order: 2.4,
                },
                "incident/indicators/entity/network_interface/name": {
                    name: "Interface Name",
                    order: 3.1,
                },
                "incidents/incidentIndicators/indicator/networkInterface/name":
                {
                    name: "Interface Name",
                    order: 3.1,
                },
                INTERFACE_NAME: {
                    name: "Interface Name",
                    order: 3.1,
                },
                "incident/indicators/entity/network_interface/ipaddr": {
                    name: "Interface IP Address",
                    order: 3.2,
                },
                "incidents/incidentIndicators/indicator/networkInterface/ipaddr":
                {
                    name: "Interface IP Address",
                    order: 3.2,
                },
                INTERFACE_IP_ADDRESS: {
                    name: "Interface IP Address",
                    order: 3.2,
                },
                "incident/indicators/entity/network_interface/ifindex": {
                    name: "Interface Ifindex",
                    order: 3.3,
                },
                "incident/indicators/entity/network_interface/location/name": {
                    name: "Interface Location",
                    order: 3.4,
                },
                "incidents/incidentIndicators/indicator/networkInterface/location/name":
                {
                    name: "Interface Location",
                    order: 3.4,
                },
                INTERFACE_LOCATION_NAME: {
                    name: "Interface Location",
                    order: 3.4,
                },
                "incident/indicators/entity/application_location/application/name":
                {
                    name: "Application Name",
                    order: 4.1,
                },
                "incidents/incidentIndicators/indicator/applicationLocation/application/name":
                {
                    name: "Application Name",
                    order: 4.1,
                },
                APPLICATION_NAME: {
                    name: "Application Name",
                    order: 4.1,
                },
                "incident/indicators/entity/application_location/location/name":
                {
                    name: "Application Location",
                    order: 4.2,
                },
                "incidents/incidentIndicators/indicator/applicationLocation/location/name":
                {
                    name: "Application Location",
                    order: 4.2,
                },
                APPLICATION_LOCATION_NAME: {
                    name: "Application Location",
                    order: 4.2,
                },
                "incident/impactedLocations": {
                    name: "Impacted Location",
                    order: 5.1,
                },
                "incidents/impactedLocations/name": {
                    name: "Impacted Location",
                    order: 5.1,
                },
                IMPACTED_LOCATION: {
                    name: "Impacted Location",
                    order: 5.1,
                },
                "incident/impactedApplications": {
                    name: "Impacted Application",
                    order: 5.2,
                },
                "incidents/impactedApplications/name": {
                    name: "Impacted Application",
                    order: 5.2,
                },
                IMPACTED_APPLICATION: {
                    name: "Impacted Application",
                    order: 5.2,
                },
                "incident/impactedUsers": {
                    name: "Impacted User",
                    order: 5.3,
                },
                "incidents/impactedUsers/ipaddr": {
                    name: "Impacted User IP",
                    order: 5.3,
                },
                IMPACTED_USER_IP_ADDRESS: {
                    name: "Impacted User IP",
                    order: 5.3,
                },
                "incidents/impactedUsers/name": {
                    name: "Impacted User Name",
                    order: 5.3,
                },
                IMPACTED_USER_NAME: {
                    name: "Impacted User Name",
                    order: 5.3,
                },
                "network_device/name": {
                    name: "Name",
                    order: 0,
                },
                "network_device/ipaddr": {
                    name: "IP Address",
                    order: 1,
                },
                "network_device/location/name": {
                    name: "Location",
                    order: 2,
                },
                "networkDevices/name": {
                    name: "Name",
                    order: 0,
                },
                "networkDevices/ipaddr": {
                    name: "IP Address",
                    order: 1,
                },
                "networkDevices/location/name": {
                    name: "Location",
                    order: 2,
                },
                "networkDevices/hostname": {
                    name: "Hostname",
                    order: 4,
                },
                NAME: {
                    name: "Name",
                    order: 0,
                },
                IP_ADDRESS: {
                    name: "IP Address",
                    order: 1,
                },
                HOSTNAME: {
                    name: "Hostname",
                    order: 4,
                },
                VENDOR: {
                    name: "Vendor",
                    order: 5,
                },
                MODEL: {
                    name: "Model",
                    order: 6,
                },
                OS_VERSION: {
                    name: "OS Version",
                    order: 7,
                },
                IS_GATEWAY: {
                    name: "Gateway",
                    order: 8,
                    values: [true, false],
                    valueDisplayMap: {
                        true: "True",
                        false: "False",
                    },
                },
                SERIAL_NUMBER: {
                    name: "Serial Number",
                    order: 9,
                },
                ELEMENT_TYPE: {
                    name: "Element Type",
                    order: 10,
                },
                "networkDevices/type": {
                    name: "Type",
                    order: 3,
                },
                "network_interface/name": {
                    name: "Name",
                    order: 0,
                },
                "network_interface/ipaddr": {
                    name: "IP Address",
                    order: 1,
                },
                "network_interface/ifindex": {
                    name: "Ifindex",
                    order: 1,
                },
                IF_INDEX: {
                    name: "Ifindex",
                    order: 2,
                },
                IF_ALIAS: {
                    name: "IF Alias",
                    order: 2.01,
                },
                IF_DESCRIPTION: {
                    name: "IF Description",
                    order: 2.02,
                },
                INBOUND_SPEED: {
                    name: "Inbound Speed",
                    order: 2.03,
                },
                OUTBOUND_SPEED: {
                    name: "Outbound Speed",
                    order: 2.04,
                },
                DEVICE_HOSTNAME: {
                    name: "Device Hostname",
                    order: 20,
                },
                DEVICE_VENDOR: {
                    name: "Device Vendor",
                    order: 21,
                },
                DEVICE_MODEL: {
                    name: "Device Model",
                    order: 22,
                },
                DEVICE_OS_VERSION: {
                    name: "Device OS Version",
                    order: 23,
                },
                DEVICE_IS_GATEWAY: {
                    name: "Device Gateway",
                    order: 24,
                    values: [true, false],
                    valueDisplayMap: {
                        true: "True",
                        false: "False",
                    },
                },
                DEVICE_SERIAL_NUMBER: {
                    name: "Device Serial Number",
                    order: 25,
                },
                DEVICE_TYPE: {
                    name: "Device Type",
                    order: 26,
                },
                DEVICE_ELEMENT_TYPE: {
                    name: "Device Element Type",
                    order: 27,
                },
                "network_interface/location/name": {
                    name: "Location",
                    order: 2,
                },
                LOCATION_NAME: {
                    name: "Location",
                    order: 3,
                },
                "networkInterfaces/name": {
                    name: "Name",
                    order: 0,
                },
                "networkInterfaces/ipaddr": {
                    name: "IP Address",
                    order: 1,
                },
                "networkInterfaces/ifindex": {
                    name: "Ifindex",
                    order: 1,
                },
                "networkInterfaces/location/name": {
                    name: "Location",
                    order: 2,
                },
                "application/name": {
                    name: "Name",
                    order: 0,
                },
                "applications/name": {
                    name: "Name",
                    order: 0,
                },
                "location/name": {
                    name: "Name",
                    order: 0,
                },
                "locations/name": {
                    name: "Name",
                    order: 0,
                },
                "locations/type": {
                    name: "Type",
                    order: 1,
                },
                TYPE: {
                    name: "Type",
                    order: 1,
                },
                "locations/city": {
                    name: "City",
                    order: 2,
                },
                CITY: {
                    name: "City",
                    order: 2,
                },
                "locations/state": {
                    name: "State",
                    order: 3,
                },
                STATE: {
                    name: "State",
                    order: 3,
                },
                "locations/country": {
                    name: "Country",
                    order: 4,
                },
                COUNTRY: {
                    name: "Country",
                    order: 4,
                },
                "customProperties/name": {
                    name: "Name",
                    order: 0,
                },
                "customProperties/description": {
                    name: "Description",
                    order: 1,
                },
                DESCRIPTION: {
                    name: "Description",
                    order: 1,
                },
                "customProperties/customPropertyValues/name": {
                    name: "Values",
                    order: 2,
                },
                "customProperties/userId": {
                    name: "User",
                    order: 3,
                },
                "customProperties/customPropertyAssociations/type": {
                    name: "Type",
                    order: 4,
                },
                VALUE_NAME: {
                    name: "Value",
                    order: 5,
                },
                // Runbook Analyses facets
                RUNBOOK_NAME: {
                    name: "Runbook",
                    order: 1,
                },
                ENTITY_TYPE: {
                    name: "Entity Type",
                    order: 2,
                    valueDisplayMap: {
                        webhook: "N/A",
                    },
                },
                EXECUTION_METHOD: {
                    name: "Execution Method",
                    order: 3,
                    valueDisplayMap: {
                        CORRELATION: "Correlation",
                        MANUAL: "Manual",
                        WEBHOOK: "Webhook",
                        SCHEDULED: "Scheduled",
                    },
                },
                VARIANT: {
                    name: "Variant",
                    order: 4,
                    valueDisplayMap: {
                        INCIDENT: "Incident",
                        LIFECYCLE: "Lifecycle",
                        ON_DEMAND: "On Demand",
                        SUBFLOW: "Subflow",
                    },
                },
                TITLE: {
                    name: "Runbook Analysis",
                    order: 5,
                },
                CREATED_BY: {
                    name: "Executed By",
                    order: 4,
                },
                ENTITY_KIND: {
                    name: "Entity Type",
                    order: -1,
                    values: [
                        "NETWORK_DEVICE",
                        "NETWORK_INTERFACE",
                        "APPLICATION",
                        "LOCATION",
                    ],
                    valueDisplayMap: {
                        NETWORK_DEVICE: "Device",
                        NETWORK_INTERFACE: "Interface",
                        APPLICATION: "Application",
                        LOCATION: "Location",
                    },
                },
                SCHEDULE: {
                    name: "Schedule",
                    order: 2,
                },
                FREQUENCY: {
                    name: "Frequency",
                    order: 3.1,
                },
                ENABLED: {
                    name: "Enabled",
                    order: 3.2,
                },
                LAST_UPDATED_BY: {
                    name: "Last Updated by",
                    order: 3.4,
                },
            },
            facetXcases: {
                Unset: "<Unset value>",
            },
        },
        more: {
            findRelatedIndicators: "Find Related Incidents",
        },
        tableCells: {
            NONE: "None",
            NET_PROFILER: "NetProfiler",
            NET_IM: "NetIM",
            ATERNITY: "Aternity",
            APP_RESPONSE: "AppResponse",
            THIRD_PARTY_DATA: "Third Party Data",
        },
        facetsChooser: {
            title: "Facets Chooser",
            availableFacets: "Available Facets",
            selectedFacets: "Selected Facets",
        }
    },
    runbookSchedules: {
        columns: {
            runbook: "Runbook",
            scheduleDescription: "Schedule Description",
            schedule: "Schedule",
            inputs: "Inputs",
            frequency: "Frequency",
            createdBy: "Created By",
            createdOn: "Created On",
            lastUpdatedBy: "Last Updated By",
            lastUpdatedOn: "Last Updated",
            nextRun: "Next Run",
            enabled: "Enabled",
            enabledOnBtn: "on",
            enabledOffBtn: "off",
            more: {
                editMenuItem: "Edit",
                duplicateMenuItem: "Duplicate",
                deleteMenuItem: "Remove",
            },
        },
    },
    dashboards: {
        title: "Dashboards (Alpha)",
        newDashboardName: "New Dashboard",
        newDashboardDesc: "This is a newly inserted dashboard.",
        newDashboardComment: "Enter nodes in the graph",
        newTitle: "New",
        importTitle: "Import",
        systemText: "system",
        okBtnText: "OK",
        cancelBtnText: "Cancel",
        more: {
            exportMenuItem: "Export",
            deleteMenuItem: "Delete",
            editViewMenuItem: "View",
            duplicateMenuItem: "Duplicate",
            testMenuItem: "Test Run",
            previewMenuItem: "Preview Output",
        },
        columns: {
            name: "Dashboard",
            createdTime: "Created",
            createdBy: "Created By",
            description: "Description",
            trigger: "Trigger",
            factory: "Built-In",
            enabled: "Active",
            lastUpdateBy: "Last Updated By",
            lastUpdateTime: "Last Updated",
        },
        tabNames: {
            all: "All Dashboards",
            mine: "Created By Me",
            active: "Is Active",
            inerror: "In Error",
            factory: "Built-In",
        },
        activateDialog: {
            title: "Activate Dashboard",
            bodyText: "Activate the dashboard",
            enabledBodyText:
                "Are you sure you want to allow automation for this dashboard?",
            disabledBodyText:
                "Are you sure you want to disallow automation for this dashboard?",
            mappedDisablingBodyText:
                "Disallowing automation for mapped dashboard is not permitted.",
            etagErrorText:
                "Another user has modified this dashboard, please refresh the dashboard list before activating/deactivating dashboard.",
            generalErrorText: "Error activating/deactivating dashboard.",
        },
        createDialog: {
            title: "Create New Dashboard",
            bodyText:
                "Creating a new dashboard and opening the dashboard editor.",
            errorText: "Error creating new dashboard!",
        },
        duplicateDialog: {
            title: "Duplicate Dashboard",
            bodyText: "Creating a duplicate of the specified dashboard.",
            errorText: "Error duplicating dashboard!",
        },
        deleteDialog: {
            title: "Delete Dashboard",
            bodyText: "Are you sure you want to delete the selected dashboard?",
            generalDeleteError: "Error deleting dashboard from backend",
            btnText: "Delete",
        },
        importDialog: {
            title: "Import Dashboard",
            text: "Click the button below to import the dashboard into the UI",
            errorText: "Error importing new dashboards!",
            btnText: "Save",
        },
        exportDialog: {
            title: "Export Dashboard",
            preText: "Querying for dashboard configuration",
            exportText:
                "Click the button below to copy the dashboard json to the clipboard",
            errorText: "Error exporting runbook.",
        },
        settingsDialog: {
            title: "Dashboard Settings",
            settingsText:
                "Click the button below to update the dashboard settings:",
            saveBtnText: "OK",
        },
    },
    viewDashboard: {
        title: "View Dashboard",
        debugDialogTitle: "Configure Dashboard Widget",
        visualizationStepTitle: "Visualization",
        runbookStepTitle: "Runbook",
        interactionStepTitle: "Interactions",
        toolbar: {
            addWidgetButton: {
                text: "Add New Widget",
                tooltip: "Add new widget",
            },
            saveButton: {
                tooltip: "Save this dashboard",
            },
            importButton: {
                tooltip: "Import a dashboard",
            },
            exportButton: {
                tooltip: "Export a dashboard",
            },
            editButton: {
                tooltip: "Edit the dashboard",
                runTooltip: "Run the dashboard",
            },
            horizontalLayoutButton: {
                tooltip: "Arrange dashboard widgets horizontally",
            },
            verticalLayoutButton: {
                tooltip: "Arrange dashboard widgets vertically",
            },
            flexGridLayoutButton: {
                tooltip: "Arrange dashboard widgets in a grid",
            },
            customLayoutButton: {
                tooltip: "Customize your own layout",
            },
        },
        widget: {
            visStep: {
                nameLabel: "Name:",
                visLabel: "Visualization:",
            },
            interactionStep: {
                groupLabel: "Group:",
                groupPlaceholder: "Select charts you want to pass groups to",
                metricLabel: "Metric:",
                metricPlaceholder: "Select charts you want to pass metrics to",
            },
        },
        widgetTypes: {
            barChartLabel: "Bar Chart",
            pieChartLabel: "Pie Chart",
            timeChartLabel: "Time Chart",
            bubbleChartLabel: "Bubble Chart",
            correlationChartLabel: "Correlation Chart",
            tableLabel: "Table",
            connectionGraphLabel: "Connection Graph",
            cardsLabel: "Cards",
            gaugesLabel: "Gauges",
            debugLabel: "Debug",
            geoMapLabel: "Map",
        },
        saveDialog: {
            title: "Save Dashboard",
            text: "Click the save button below to save the dashboard",
            nameLabel: "Name:",
            descLabel: "Description:",
            saveBtnText: "Save",
        },
        importDialog: {
            title: "Import Dashboard",
            text: "Click the button below to import the runbook into the UI",
        },
        exportDialog: {
            title: "Export Dashboard",
            preText: "Export the current dashboard",
            errorText: "Error exporting dashboard.",
            btnText: "Copy To Clipboard",
        },
    },
    visFramework: {
        widgetTypes: {
            barChartLabel: "Bar Chart",
            pieChartLabel: "Pie Chart",
            timeChartLabel: "Time Chart",
            bubbleChartLabel: "Bubble Chart",
            correlationChartLabel: "Correlation Chart",
            tableLabel: "Table",
            connectionGraphLabel: "Connection Graph",
            cardsLabel: "Cards",
            gaugesLabel: "Gauges",
            debugLabel: "Debug",
            geoMapLabel: "Map",
        },
    },
    navigators: {
        title: "NPM Plus (Alpha)",
        settingsDialog: {
            title: "Dashboard Settings",
            settingsText:
                "Click the button below to update the dashboard settings:",
            saveBtnText: "OK",
        },
        widgetEditor: {
            title: "Edit Widget",
            columns: "Columns",
            noColumnsToEdit: "No columns to edit",
            buttons: {
                apply: "Apply",
                close: "Close",
            },
        },
    },
    navigator: {
        title: "view Navigator",
        debugDialogTitle: "Configure Navigator Widget",
        queryStepTitle: "Query",
        visualizationStepTitle: "Visualization",
        runbookStepTitle: "Runbook",
        interactionStepTitle: "Interactions",
        toolbar: {
            addWidgetButton: {
                text: "Add New Widget",
                tooltip: "Add new widget",
            },
            saveButton: {
                tooltip: "Save this template",
            },
            importButton: {
                tooltip: "Import a template",
            },
            exportButton: {
                tooltip: "Export a template",
            },
            editButton: {
                tooltip: "Edit the template",
                runTooltip: "Run the template",
            },
            horizontalLayoutButton: {
                tooltip: "Arrange template widgets horizontally",
            },
            verticalLayoutButton: {
                tooltip: "Arrange template widgets vertically",
            },
            flexGridLayoutButton: {
                tooltip: "Arrange template widgets in a grid",
            },
            customLayoutButton: {
                tooltip: "Customize your own layout",
            },
        },
        widget: {
            visStep: {
                nameLabel: "Name:",
                visLabel: "Visualization:",
            },
            interactionStep: {
                groupLabel: "Group:",
                groupPlaceholder: "Select charts you want to pass groups to",
                metricLabel: "Metric:",
                metricPlaceholder: "Select charts you want to pass metrics to",
            },
        },
        saveDialog: {
            title: "Save Template",
            text: "Click the save button below to save the template",
            nameLabel: "Name:",
            descLabel: "Description:",
            saveBtnText: "Save",
        },
        importDialog: {
            title: "Import Template",
            text: "Click the button below to import the runbook into the UI",
        },
        exportDialog: {
            title: "Export Template",
            preText: "Export the current template",
            errorText: "Error exporting template.",
            btnText: "Copy To Clipboard",
        },
        entityListWidget: {
            title: "Select",
            pinnedListTitle: "Pinned",
            noPinnedItemsFound: "No pinned items found.",
            listsListTitle: "Lists",
            noListsFound: "No lists found.",
        },
        addNewWidgetControl: {
            title: "Add New",
            subTitle: "from selected rows",
            tableText: "Table",
            timeText: "Chart",
            pieText: "Pie",
            scatterText: "Scatter",
            metricsSectionHeader: "Metrics & Properties",
            drilldownsSectionHeader: "Drill Down Into",
            drilldownsSectionSubHeader: "(optional)",
            noColumnsText: "No columns to edit.",
            noDrilldownsText: "No drilldowns to edit.",
            closeButtonText: "Close",
            applyButtonText: "Apply",
        },
    },
    subflows: {
        title: "Subflows",
        newSubflowName: "New Subflow",
        newSubflowDesc: "This is a newly inserted subflow.",
        newSubflowComment: "Enter nodes in the graph",
        newTitle: "New",
        importTitle: "Import",
        systemText: "system",
        okBtnText: "OK",
        cancelBtnText: "Cancel",
        more: {
            exportMenuItem: "Export",
            deleteMenuItem: "Delete",
            editViewMenuItem: "Edit",
            duplicateMenuItem: "Duplicate",
            testMenuItem: "Test Run",
            previewMenuItem: "Preview Output",
        },
        columns: {
            name: "Subflow",
            createdTime: "Created",
            createdBy: "Created By",
            description: "Description",
            trigger: "Trigger",
            factory: "Built-In",
            enabled: "Active",
            lastUpdateBy: "Last Updated By",
            lastUpdateTime: "Last Updated",
        },
        tabNames: {
            all: "All Subflows",
            mine: "Created By Me",
            active: "Is Active",
            inerror: "In Error",
            factory: "Built-In",
        },
        activateDialog: {
            title: "Activate Subflow",
            bodyText: "Activate the subflow",
            enabledBodyText:
                "Are you sure you want to allow automation for this subflow?",
            disabledBodyText:
                "Are you sure you want to disallow automation for this subflow?",
            mappedDisablingBodyText:
                "Disallowing automation for mapped subflow is not permitted.",
            etagErrorText:
                "Another user has modified this subflow, please refresh the runbook list before activating/deactivating subflow.",
            generalErrorText: "Error activating/deactivating subflow.",
        },
        createDialog: {
            title: "Create New Dashboard",
            bodyText:
                "Creating a new dashboard and opening the dashboard editor.",
            errorText: "Error creating new dashboard!",
        },
        duplicateDialog: {
            title: "Duplicate Dashboard",
            bodyText: "Creating a duplicate of the specified dashboard.",
            errorText: "Error duplicating dashboard!",
        },
        deleteDialog: {
            title: "Delete Dashboard",
            bodyText: "Are you sure you want to delete the selected dashboard?",
            generalDeleteError: "Error deleting dashboard from backend",
            btnText: "Delete",
        },
        importDialog: {
            title: "Import Dashboard",
            text: "Click the button below to import the dashboard into the UI",
            errorText: "Error importing new dashboards!",
            btnText: "Save",
        },
        exportDialog: {
            title: "Export Dashboard",
            preText: "Querying for dashboard configuration",
            exportText:
                "Click the button below to copy the dashboard json to the clipboard",
            errorText: "Error exporting runbook.",
        },
        settingsDialog: {
            title: "Dashboard Settings",
            settingsText:
                "Click the button below to update the dashboard settings:",
            saveBtnText: "OK",
        },
    },
    cloudim: {
        menuTitle: "Theater View",
        pageTitle: "Theater View",
        geoMap: {
            filter:{
                error: "An error occurred retrieving regions",
                results: "{0} filtered assets in {1} regions",
                placeholder: "Filter assets",
                tooltip: "Assets used to populate the map will be limited to those containing the filter terms.  Multiple terms can be entered separated by spaces."
            },
            provider: {
                aws: "AWS",
                azure: "Azure",
                onprem: "On Prem"
            },
            details: {
                provider: "Provider: ",
                code: "Code: ",
                location: "Location: ",
                assets: "Assets: "
            }
        },
        topology: {
            cardTitle: "Topology Map",
            aws: {
                account: "Account",
                owner: "Owner",
                vpc: "VPC",
                vpcPeeringConnection: "VPC Peering Connection",
                subnet: "Subnet",
                computeInstance: "Compute Instance",
                blobStorage: "Blob Storage",
                lambda: "Lambda",
            },
            netim: {
                switch: "Switch",
                router: "Router",
                firewall: "Firewall",
                loadBalancer: "Load Balancer",
                host: "Host",
                wanAccelerator: "WAN Accelerator",
                multilayeredSwitch: "Multi-Layer Switch",
                printer: "Printer",
                unifiedCommunication: "Unified Communication",
                wireless: "Wireless",
                sdwan: "SD-WAN",
                other: "Other",
            },
            layoutControl: {
                presetOptions: "Preset Layouts",
                applyOverlapRemoval: "Remove Overlap",
                algorithmOption: "Algorithm:",
                directionOption: "Direction:",
                nodeNodeSpacingOption: "Node Distance:",
                componentComponentSpacingOption: "Component Distance:",
                applyLayout: "Apply",
            }
        }
    },
    runbookAnalysesPage: {
        modalTitle: "Delete Runbook Outputs",
        modalSubmit: "Delete",
        modalClose: "Close",
        deleteConfirmationSingle:
            "Are you sure you want to delete the selected runbook?",
        deleteConfirmationMulti:
            "Are you sure you want to delete the selected runbooks?",
        toastMessages: {
            // for the Runbook Analyses page
            successDeleteRunbookOutputSingle:
                "Successfully deleted the Runbook.",
            successDeleteRunbookOutputMulti:
                "Successfully deleted the Runbooks.",
            errorDeleteRunbookOutputSingle:
                "Could not delete the selected Runbook. Please try again.",
            errorDeleteRunbookOutputMulti:
                "Could not delete the selected Runbooks. Please try again.",
        },
    },
    INTEGRATIONS_PAGE: {
        title: 'Integrations Library',
        searchBox:{
            placeholder: 'Search...',
            helpText: 'You can search through integrations, based on their title and description.'
        },
        relatedReferences: {
            title: "What references resources from {0}?",
        },
        affectedResources: {
            message: `Changing this connector might affect resources used in 
                        the runbooks created by this intregration.`,
            link: 'Check affected runbooks',
            checkInProgress: "Checking to see if there are any resources that use this connector...",
            dialogReferencingResource: "One runbook is referencing this connector.",
            dialogReferencingResources: "{0} runbooks are referencing this connector.",
            dialogNoReferencingResources: "There are no runbooks that reference this connector.",
            deleteConnectorConfirm: 'Are you sure you want to delete this connector?',
            deleteConnectorConfirmBtn: 'Delete',
            confirmBtn: 'OK'
        },
        noAvailableIntegrations: 'There are no available integrations.',
        noInstalledIntegrations: 'There are no installed integrations yet.',
        noIntegrationsFound: 'No integrations were found for this search.',
        unableToRetrieveIntegrations: 'The list of integrations could not be retrieved.',
        badges: {
            beta: "Beta",
            installed: "Installed",
            available: "Available",
            upgradeAvailable: "Upgrade Available",
            upgradeAvailableToVersion: "Upgrade available to version {0}"
        },
        card: {
            updatedInstalledIntegrationWithError: 'Updated with errors',
            installedWithError: 'Installed with errors',
            uninstalledWithError: 'Uninstalled with errors',
            seeDetails: "See details",
        },
        controls: {
            configure: 'Configure',
            install: 'Install',
            installInProgress: 'Installation in progress...',
            uninstallInProgress: 'Uninstall in progress...',
            updateInProgress: "Update in progress...",
        },
        tabs: {
            installed: 'Installed',
            available: 'Available',
        },
        errorModal: {
            title:'{0} Integration - Errors',
            calloutTitle: 'Error Details',
            errorCodeTitle: 'Error Code',
            errorDetailsTitle: 'Details',
            close: 'Close',
        },
        integrationDetailsModal: {
            title: 'Add connector',
            buttons: {
                install: 'Install',
                uninstall: 'Uninstall',
                upgrade: 'Upgrade',
                apply: 'Apply',
                cancel: 'Cancel',
                addConnector: "Add Connector",
            },
            errors: {
                seeDetails: 'See details',
                failedToDeleteConnector: 'The connector could not be removed.',
                installAlreadyInProgress: 'Another installation of this integration has already been started.',
                updateAlreadyInProgress: 'Someone is in the process of changing the details of this integration already.',
                upgradeAlreadyInProgress: 'Someone is in the process of upgrading this integration already.',
                uninstallAlreadyInProgress: 'Another uninstall of this integration has already been started.',
                update: 'The integration could not be updated.',
                connectorNoProfile: 'Something went wrong during installation, and the connector does not have an authentication profile associated with it.'
            },
            success: {
                update: 'The changes to the integration have been applied.'
            },
            fieldValidation: {
                string: 'The value should be a string',
                number: 'The value should be a number',
                float: 'The value should be a floating point number',
                ipAddress: 'The value should be an IP Address',
                boolean: 'The value should be true or false',
                json: 'The value should be a JSON',
                timestamp: 'The value should be a timestamp',
                required: 'This field is required',
            },
            connectorNameFieldLabel: 'Connector Name',
            noConnectorRequired: 'This integration does not need any connector to be installed.',
            connectorRequired: 'At least one connector must be defined before installing this integration.',
            minConnectorsRequired: 'This integration requires at least {0} connectors before being installed.',
            noConnectors: 'This integration has no connectors.',
            connectorIsDeprecated: "The recent upgrade requires updating this connector.",
            uninstallIntegration: {
                checkInProgress: "Checking to see if there are any referenced resources...",
                dialogReferencingResource: "One runbook is referencing resources created by this integration.",
                dialogReferencingResources: "{0} runbooks are referencing resources created by this integration.",
                dialogNoReferencingResources: "There are no runbooks that reference resources from this integration.",
                dialogConfirm:
                    "Are you sure you want to uninstall this integration?",
                dialogConfirmBtn: "Uninstall",
                uninstallConfirmMessage: "This integration might have runbooks or subflows that depend on the resources installed with it. \n \n Are you absolutely sure you want to uninstall this integration?",
            },
            upgradeIntegration: {
                confirmMessage: {
                    minor: 'Are you sure you want to upgrade the integration to a newer version?',
                    major:  `This is an upgrade to a major version and might require updating connectors or runbooks using the integration.\n
                            Are you sure you want to proceed with the upgrade?`
                },
                confirmButton: 'Upgrade'
            }
        },
        progressDialog: {
            title: {
                install: 'Install Integration',
                update: 'Update Integration Details',
                uninstall: 'Uninstall Integration',
                upgrade: 'Upgrade Integration',
            },
            confirmButton: "OK",
            inProgress: {
                install: "Installing {0} integration...",
                update: "Applying changes for {0} integration...",
                uninstall: "Uninstalling {0} integration...",
                upgrade: "Upgrading {0} integration...",
            },
            successMessage:  {
                install: "Integration {0} has been installed succesfully.",
                update: "Integration {0} has been updated succesfully.",
                uninstall: "Integration {0} has been uninstalled succesfully.",
                upgrade: "Integration {0} has been upgraded succesfully.",
            },
            errors: {
                INTEGRATION_UPDATE_WITH_ERRORS: "The integration details have been updated with errors.",
                INTEGRATION_UPGRADE_WITH_ERRORS: "The integration has been upgraded with errors.",
                INTEGRATION_INSTALL_WITH_ERRORS: "The integration has been installed with errors.",
                INTEGRATION_INSTALL_FAILURE: "The integration could not be installed. Please try again.",
                INTEGRATION_UNINSTALL_FAILURE: "The integration could not be uninstalled. Please try again."
            },
        }
    },
    ANALYTICS_CONFIGURATION_PAGE: {
        title: 'Analytics & Threshold Configuration',
        subTitle: 'This page provides additional controls for directly adjusting when the Analytics Engine generates indicators, and therefore automated incident creation. Changes on this page will impact all entities of the specified type. See the Help link for further detail.',
        disableMetricMessage: 'Disabling this metric will prevent all entities of this type from generating indicators on this metric. <br /><br /> <b>Are you sure you want to disable this metric?</b>',
        sections: {
            devices: {
                title: 'Devices',
                columns: {
                    analytics: 'Analytics'
                }, 
                thresholdText: '{0} in Drops {1} {2} {3} for {4} out of {5} measurements'
            },
            interfaces: {
                title: 'Interfaces',
                columns: {
                    analytics: 'Analytics',
                    thresholdConfiguration: 'Static Threshold Configuration'
                },
                thresholdText: '({0}) {1} {2} for {3} out of {4} measurements'
            },
            applications: {
                title: 'Applications',
                columns: {
                    analytics: 'Analytics',
                    thresholdConfiguration: 'Static Threshold Configuration'
                },
                thresholdText: '({0}) {1} {2} for {3} out of {4} measurements',
                voipLabel: '(VoIP)'
            },
        }, 
        detectorTypes: {
            threshold: 'Threshold',
	        alwaysIncreasing: 'Up Time Reset',
	        change: 'Status Change',
	        fault: 'Fault',
	        dynamicThreshold: 'Dynamic Threshold',
	        baseline: 'Baselining'
        },
        defaultConfig: 'Default',
        customConfig: 'Custom',
        updateSuccessMessage: 'Policy successfully updated.', 
        updateErrorMessage: 'Policy update failed.',
        isSpecializedMessage: 'Controls on this page are disabled due to advanced policies active in your environment that are not yet visible in the current UI. More sophisticated controls will be added soon. Please contact your Riverbed account team with any questions.',
        editStaticThreshold: 'Edit Static Threshold',
        modal: {
            texts: {
                topText: 'Generate indicator when {0} {1}',
                aboveText: 'above',
                belowText: 'below',
                for: 'for:',
                singleMeasurement: 'a single measurement',
                consecutiveMeasurement: 'consecutive measurements',
                outOf: 'out of',
                measurements: 'measurements',
                resetConfirmationText: 'Are you sure you want to reset to default settings?'

            },
            updateSuccessMessage: 'Static Threshold successfully updated.', 
            updateErrorMessage: 'Static Threshold update failed.',
        },
        buttons: {
            cancelBtnText: 'Cancel', 
            applyBtnText: 'Apply',
            resetBtnText: 'Reset To Default'
        }
    }
};

export { en };
