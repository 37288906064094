/** This file defines the GaugeChart React component.  The GaugeChart React component renders a
 *  a basic gauge chart with onge group and one metric.
 *  @module */
import React, { useCallback, useRef, useState } from 'react';
import { Icon, IconNames } from '@tir-ui/react-components';
import { Dialog } from '@blueprintjs/core';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import HighchartsReact from 'highcharts-react-official';
import { DEFAULT_GAUGE_CHART_OPTIONS } from 'components/reporting/charts/defaults/HighchartDefaults';
import { cloneDeep, merge } from 'lodash';
import { Unit } from 'reporting-infrastructure/types/Unit.class';
import { THEME, ThemeContext } from 'utils/themes';
import { CHART_COLORS } from 'components/enums';

// initialize highcharts more and highcharts solid gauge
highchartsMore(Highcharts);
solidGauge(Highcharts);

// These are the default options that all gauge charts should use as a starting point
const defaultOptions = DEFAULT_GAUGE_CHART_OPTIONS;

/** an interface that describes the properties that can be passed in to the gauge chart component.*/
export interface GaugeChartProps {
	/** a GaugeData object with the values, units, and labels that are necessary to display the gauge. */
	gaugeData?: GaugeData;
	/** the array of gauge thresholds.  If none are specified defaults are used from the high charts defaults.*/
	gaugeThresholds?: Array<GaugeThreshold>;
	/** CSS class to apply on the Gauge Chart holder element */
	className?: string;
	/** When passed as true, fullscreen control will appear on top right corner */
	enableFullScreen?: boolean;
}

/** an interface that describes the gauge data format. */
export interface GaugeData {
	/** the name for the metric.  This name will be displayed on top of the gauge. */
	metric: string;
	/** the values that the gauge will display. */
	value: number;
	/** the minimum value that should be displayed in the gauge. */
	minValue: number;
	/** the maximum value that should be displayed in the gauge. */
	maxValue: number;
	/** the unit that will be displayed in the gauge. */
	unit: Unit;
}

/** an interface that describes the format for gauge thresholds.  Gauge thresholds are used to color
 *  the gauge regions, usually in a good (green), bad (red), color scheme, but you can do what you want.*/
export interface GaugeThreshold {
	/** a value between 0 and 1 that specifies the perecentage value at which this region ends. */
	value: number;
	/** a string with the color for the current threshold region. */
	color: string;
}

/** Creates the the gauge chart view.
 *  @param props an object with the properties passed to the gauge chart view.
 *  @returns JSX with the gauge chart component.*/
export const GaugeChart = (props: GaugeChartProps): JSX.Element => {
	const chartRef = useRef<HighchartsReact.RefObject>(null);
	const [isOpen, setIsOpen] = useState(false);
	const handleOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);
	const handleClose = useCallback(() => setIsOpen(false), []);

	let seriesData: Array<any> = [];
	let title = 'Unknown',
		minValue = 0,
		maxValue = 200;
	if (props.gaugeData) {
		title = props.gaugeData.metric;
		minValue = props.gaugeData.minValue;
		maxValue = props.gaugeData.maxValue;
		let series = {
			name: props.gaugeData.metric,
			data: [props.gaugeData.value],
			dataLabels: {
				format:
					'<div style="text-align:center">' +
					'<span style="font-size:25px">{y}</span><br/>' +
					'<span style="font-size:12px;opacity:0.5">' +
					props.gaugeData.unit.getDisplayName() +
					'</span>' +
					'</div>',
			},
			tooltip: {
				valueSuffix: ' ' + props.gaugeData.unit.getDisplayName(),
			},
		};
		seriesData.push(series);
	}

	const getChart = (popup: boolean = false) => {
		return (
			<ThemeContext.Consumer>
				{(ctx) => (
					<div
						aria-label="gaugeChartMetrics card"
						className={
							'flex gaugeChart w-3 h-2' +
							(props.className ? ' ' + props.className : '')
						}
					>
						{props.enableFullScreen && !popup && (
							<div className="float-sm-right pr-2">
								<Icon
									icon={IconNames.FULLSCREEN}
									onClick={handleOpen}
								/>
							</div>
						)}
						<HighchartsReact
							highcharts={Highcharts}
							options={getChartOptions({
								title,
								minValue,
								maxValue,
								series: seriesData,
								thresholds: props.gaugeThresholds,
								darkMode: ctx.theme === THEME.dark,
							})}
							containerProps={{
								style: {
									width: '100%',
									height: '100%',
									padding: popup ? '10px' : '',
								},
							}}
							ref={chartRef}
						/>
					</div>
				)}
			</ThemeContext.Consumer>
		);
	};

	return (
		<>
			<Dialog
				title={''}
				isOpen={isOpen}
				autoFocus={true}
				canEscapeKeyClose={true}
				canOutsideClickClose={true}
				enforceFocus={true}
				usePortal={true}
				onClose={handleClose}
				style={{ width: 0.75 * window.innerWidth, height: '100%' }}
			>
				{getChart(true)}
			</Dialog>
			{getChart(false)}
		</>
	);
};

/** returns the chart options for the specified title and series.
 *  @param title a string with the title for the gauge chart.
 *  @param minValue a number with the minimum value that should be displayed on the gauge.
 *  @param maxValue a number with the maximum value that should be displayed on the gauge.
 *  @param series the data series to put in the bar chart.
 *  @param thresholds the array of gauge thresholds.  If none are specified defaults are used.
 *  @returns the chart options for the specified categories and series.*/
function getChartOptions({
	title,
	minValue,
	maxValue,
	series,
	thresholds,
	darkMode = false,
}: {
	title: string;
	minValue: number;
	maxValue: number;
	darkMode?: boolean;
	series: Array<any>;
	thresholds: Array<GaugeThreshold> | undefined;
}): Highcharts.Options {
	let optionsCopy: Highcharts.Options = cloneDeep(defaultOptions);
	merge(optionsCopy, {
		yAxis: {
			min: minValue,
			max: maxValue,
			title: {
				text: '<span class="display-8">' + title + '</span>',
				style: {
					color: darkMode
						? CHART_COLORS.TITLE_DARKMODE
						: CHART_COLORS.TITLE_DEFAULT,
				},
			},
			labels: {
				style: {
					color: darkMode
						? CHART_COLORS.LABEL_DARKMODE
						: CHART_COLORS.LABEL_DEFAULT,
				},
			},
		},
		plotOptions: {
			solidgauge: {
				dataLabels: {
					color: darkMode
						? CHART_COLORS.DATA_DARKMODE
						: CHART_COLORS.DATA_DEFAULT,
				},
			},
		},
		credits: {
			enabled: false,
		},
		series: series,
	});

	if (thresholds) {
		(optionsCopy.yAxis! as any).stops = [];
		for (const threshold of thresholds) {
			(optionsCopy.yAxis! as any).stops.push([
				threshold.value,
				threshold.color,
			]);
		}
	}

	return optionsCopy;
}
