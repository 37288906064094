/** This module contains the wrapper for the cloudim react-flow graph.
 *  @module
 */
import React, { useCallback, useEffect, useState } from "react";
import ReactFlow, { MiniMap, Controls, OnLoadParams, Elements, ConnectionLineType } from "react-flow-renderer";
import { CloudIMGraphDef, getElements } from "utils/cloudim/TopologyUtils";

import AWSNode from "./nodes/aws/AWSNode";
import NetIMNode from "./nodes/netim/NetIMNode";
import NetIMEdge from "./edges/netim/NetIMEdge";
import LayoutControl from "./components/layout/LayoutControl";

// The default edge type to use wherever an edge is created
const defaultEdgeType: ConnectionLineType = ConnectionLineType.Bezier;

/** This interface defines the properties passed into the cloudim react-flow graph React component.*/
export interface CloudIMReactFlowGraphProps {
    /** the GraphDef object with the graph nodes and edges in it.*/
    graphDef: CloudIMGraphDef;
    showLayoutOptions?: boolean;
    showMinimap?: boolean;
}

/** Renders the cloudim react-flow graph component.
 *  @param props the properties passed in. These properties contain some of the meta data necessary to 
 *      draw the graph, and the nodes and edges that should be drawn in the graph.
 *  @returns JSX with the cloudim react flow graph component.*/
const CloudIMReactFlowGraph = React.forwardRef(({ graphDef, ...props }: CloudIMReactFlowGraphProps, ref): JSX.Element => {
    const [reactFlowInstance, setReactFlowInstance] = useState<OnLoadParams>();
    const passedElements = getElements(graphDef);
    const [elements, setElements] = useState<Elements>(passedElements);

    const rerenderData = useCallback(async () => {
        setElements(getElements(graphDef));
    }, [graphDef])

    useEffect(() => {
        rerenderData().then(() => {
            if (reactFlowInstance) {
                reactFlowInstance.fitView();
            }
        });
    }, [rerenderData, reactFlowInstance]);

    const onLoad = (reactFlowInstance: OnLoadParams) => {
        setReactFlowInstance(reactFlowInstance);
        reactFlowInstance.fitView();
    };

    return (
        <ReactFlow
            elements={elements as Elements<any>}
            nodesConnectable={false}
            nodeTypes={{
                awsNode: AWSNode,
                netimNode: NetIMNode
            }}
            edgeTypes={{
                netimEdge: NetIMEdge
            }}
            connectionLineType={defaultEdgeType}
            onLoad={onLoad}
            minZoom={0}
            maxZoom={2.5}
        >
            <LayoutControl
                showLayoutOptions={props.showLayoutOptions}
                elements={elements as Elements<any>}
                updateElements={(elements) => setElements(elements)}
                reactFlowInstance={reactFlowInstance}
                debug={false}
                ref={ref}
            />
            {props.showMinimap && <MiniMap nodeBorderRadius={20} />}
            <Controls showZoom={true} showFitView={true} showInteractive={true} />
        </ReactFlow>
    );
});

export default CloudIMReactFlowGraph;