/** This module contains a react component that displays a JSON object in a tree or a text
 *  area.
 *  @module
 */
import React, { useCallback, useReducer, useRef } from "react";
import { TextArea, Tree, TreeNodeInfo } from "@blueprintjs/core";
import { clone } from "lodash";
import { STRINGS } from "app-strings";
import { Tab, TabbedSubPages } from "components/common/layout/tabbed-sub-pages/TabbedSubPages";
import './JsonViewer.css';

/** defines the NodePath type. */
type NodePath = number[];

/** defines the TreeAction type. */
type TreeAction = { type: "SET_IS_EXPANDED"; payload: { path: NodePath; isExpanded: boolean } }
    | { type: "DESELECT_ALL" } | { type: "RESET"; payload: TreeNodeInfo<{}>[] };

/** executes the specified visit function for the specified set of nodes and their
 *      children.
 *  @param nodes the nodes for which the visit function is to be run.
 *  @param path the path.
 *  @param visitFn the visit function.*/
function forEachNode(nodes: any[] | undefined, visitFn: (node: any) => void) {
    if (nodes === undefined) {
        return;
    }

    for (const node of nodes) {
        visitFn(node);
        forEachNode(node.childNodes, visitFn);
    }
}

/** executes the specified callback for the tree node specified by the path.
 *  @param nodes the nodes in the tree.
 *  @param path the path.
 *  @param callback the callback function.*/
function forNodeAtPath(nodes: any[], path: NodePath, callback: (node: any) => void) {
    callback(Tree.nodeFromPath(path, nodes));
}

/** the reducer function.
 *  @param state the current state of the tree.
 *  @param action the current action.
 *  @returns the new state of the tree after taking the specified action.*/
function treeReducer(state: any[], action: TreeAction) {
    switch (action.type) {
        case "DESELECT_ALL": {
            const newState = clone(state);
            forEachNode(newState, node => (node.isSelected = false));
            return newState;
        }
        case "SET_IS_EXPANDED": {
            const newState = clone(state);
            forNodeAtPath(newState, action.payload.path, node => (node.isExpanded = action.payload.isExpanded));
            return newState;
        }
        case "RESET": {
            return action.payload;
        }
    }
}

/** This interface defines the properties passed into the JSON viewer component.*/
export interface JsonViewerProps {
    /** the JSON object to output to the component. */
    json?: Record<string, any>;
    /** a string with the height of the widget in pixels if any. */
    height?: string;
}

/** Renders the JSON viewer react component.
 *  @param props the properties passed in.
 *  @returns JSX with the JSON viewer React component.*/
export const JsonViewer = (props: JsonViewerProps): JSX.Element => {
    // Setup the tree.
    const INITIAL_STATE: Array<any> = createTree(props.json);
    const [nodes, dispatch] = useReducer(treeReducer, INITIAL_STATE);

    const handleNodeCollapse = useCallback((_node: any, nodePath: NodePath) => {
        dispatch({
            payload: { path: nodePath, isExpanded: false },
            type: "SET_IS_EXPANDED",
        });
    }, []);

    const handleNodeExpand = useCallback((_node: any, nodePath: NodePath) => {
        dispatch({
            payload: { path: nodePath, isExpanded: true },
            type: "SET_IS_EXPANDED",
        });
    }, []);

    const lastJson = useRef<any>(undefined);
    if (lastJson.current !== undefined && lastJson.current !== props.json) {
        dispatch({
            payload: INITIAL_STATE,
            type: "RESET",
        });
    }
    lastJson.current = props.json;

    return <>
        <TabbedSubPages urlStateKey="debugTab">
            <Tab id="jsonTree" title={STRINGS.jsonViewer.tabTreeLabel}>
                <div style={{ minHeight: props.height ? props.height : "400px", maxHeight: props.height ? props.height : "400px", overflowY: "auto" }} className="json-viewer-content display-8" >
                    <Tree contents={nodes} onNodeCollapse={handleNodeCollapse} onNodeExpand={handleNodeExpand} />
                </div>
            </Tab>
            <Tab id="jsonText" title={STRINGS.jsonViewer.tabTextLabel}>
                <TextArea 
                    value={JSON.stringify(props.json || {}, null, 4)} 
                    className="json-viewer-debug-textarea display-8 w-100"
                    style={{ minHeight: props.height ? props.height : "400px", maxHeight: props.height ? props.height : "400px"}}
                    disabled={true}
                />
            </Tab>
        </TabbedSubPages>
    </>;
}

/** creates the debug tree from the json object.
 *  @param json the JSON to display in the tree.
 *  @returns an Array with the root tree nodes. */
function createTree(json: any): Array<any> {
    let rootNode = {id: "", depth: 0, path: [], childNodes: []};
    if (json && typeof json === "object") {
        addJsonObjectToTree(json, rootNode);
    }
    return rootNode.childNodes;
}

/** adds a JSON object to the tree.
 *  @param jsonObj the JSON object to add.
 *  @param parentNode the parent node that the JSON object will be attached to. */
export function addJsonObjectToTree(jsonObj: Record<string, string>, parentNode: any): void {
    const jsonChildren: Array<any> = [];
    for (const jsonKey in jsonObj) {
        const jsonValue = jsonObj[jsonKey];
        const node = {
            id: "fobj-" + parentNode.id + "-" + jsonKey, key: "fobj-" + parentNode.id + "-" + jsonKey,
            depth: parentNode.depth + 1, path: parentNode.path.concat(jsonChildren.length),
            label: <span>{jsonKey}</span>
        }
        if (typeof jsonValue === "string") {
            node.label = <div className="d-flex icon-and-label-div"><span>{jsonKey}: "{jsonValue}"</span></div>;
        } else if (typeof jsonValue === "number") {
                node.label = <div className="d-flex icon-and-label-div"><span>{jsonKey}: {jsonValue}</span></div>;
        } else if (typeof jsonValue === "boolean") {
            node.label = <div className="d-flex icon-and-label-div"><span>{jsonKey}: {Boolean(jsonValue).toString()}</span></div>;
        } else if (jsonValue === null) {
            node.label = <div className="d-flex icon-and-label-div"><span>{jsonKey}: null</span></div>;
        } else if (Array.isArray(jsonValue)) {
            if ((jsonValue as Array<any>).length === 0 /*1*/) {
                // If there is only one item in the array do not show the array in the tree
                addJsonObjectToTree(jsonValue[0], node);
            } else {
                node.label = <span>{jsonKey}: {STRINGS.jsonViewer.arrayText}({(jsonValue as Array<any>).length})</span>;
                addJsonArrayToTree(jsonValue, node);
            }
        } else if (typeof jsonValue === "object") {
            addJsonObjectToTree(jsonValue, node);
        }
        jsonChildren.push(node);
    }
    parentNode.childNodes = jsonChildren;
}

/** adds a JSON array to the tree.
 *  @param jsonArr the json array to add.
 *  @param parentNode the parent node that the json will be attached to. */
export function addJsonArrayToTree(jsonArr: Array<any>, parentNode: any): void {
    const jsonChildren: Array<any> = [];
    for (let arrayIndex = 0; arrayIndex < jsonArr.length; arrayIndex++) {
        const arrItem = jsonArr[arrayIndex];
        const node = {
            id: "jarr-" + parentNode.id + "-[" + arrayIndex + "]", key: "jarr-" + parentNode.id + "-[" + arrayIndex + "]",
            depth: parentNode.depth + 1, path: parentNode.path.concat(jsonChildren.length),
            label: <span>{arrayIndex + (typeof arrItem === "object" ? ": " + JSON.stringify(arrItem) : "")}</span>
        }
        if (typeof arrItem === "string") {
            node.label = <div className="d-flex icon-and-label-div"><span>[{arrayIndex}]: "{arrItem}"</span></div>;
        } else if (typeof arrItem === "number") {
            node.label = <div className="d-flex icon-and-label-div"><span>[{arrayIndex}]: {arrItem}</span></div>;
        } else if (typeof arrItem === "boolean") {
            node.label = <div className="d-flex icon-and-label-div"><span>[{arrayIndex}]: {Boolean(arrItem).toString()}</span></div>;
        } else if (arrItem === null) {
            node.label = <div className="d-flex icon-and-label-div"><span>[{arrayIndex}]: null</span></div>;
        } else if (Array.isArray(arrItem)) {
            if (arrItem.length === 0/*1*/) {
                // If there is only one item in the array do not show the array in the tree
                addJsonObjectToTree(arrItem[0], node);
            } else {
                addJsonArrayToTree(arrItem, node);
            }
        } else if (typeof arrItem === "object") {
            addJsonObjectToTree(arrItem, node);
        }
        jsonChildren.push(node);
    }
    parentNode.childNodes = jsonChildren;
}
