/** This module contains the IntegrationLibraryService that can be used to query the ILS (Integration Library Service)
 *      service.
 *  @module
 */
import { ApiService } from "utils/services/ApiService";
import {
    AvailableIntegration,
    AvailableIntegrationDetails,
    InstalledIntegration,
    InstalledIntegrationConnector,
    InstalledIntegrationConnectorDetails,
    InstalledIntegrationStatus,
    IntegrationErrorDetails,
    RunbookIntegrationDetails,
} from "pages/integrations/types/IntegrationTypes";

/** The URL for the API server. */
export const API_VERSION = "1.0";
export const INTEGRATIONS_URL = `/api/integrationLibrary/${API_VERSION}/`;

/** this class defines the Third Party Integration API Service. */
class IntegrationLibraryApiService extends ApiService {
    /** the constructor for the class. */
    constructor() {
        const baseUri = INTEGRATIONS_URL;
        super(baseUri);
    }

    /** returns the base uri, this can be overridden in subclasses to allow the uri to change
     *      after construction.
     *  @returns a String with the base uri. */
    protected getBaseUri(): string {
        if (ApiService.USE_REGION) {
            const region = ApiService.AUTH_SERVICE.getRegion();
            return `/api/iq/${region}/integrationLibrary/${API_VERSION}/`;
        } else {
            return this.baseApiUri;
        }
    }

    /**
     * Get the list of available integrations
     */
    async getAvailableIntegrations() {
        try {
            const result = await super.get(`availableIntegrations`);

            return result as Array<AvailableIntegration>;
        } catch (error) {
            console.error(error);
            throw new Error(`${error}`);
        }
    }

    /**
     * Get connectors/configuration details for a given available integrations
     */
    async getAvailableIntegration(
        id: string | undefined,
        version: string | undefined
    ) {
        try {
            if (!id || !version) {
                throw new Error(
                    "An integration id and version is needed for getting details about this integration."
                );
            }
            const result = await super.get(
                `availableIntegrations/${id}/${version}`
            );

            return result as AvailableIntegrationDetails;
        } catch (error) {
            console.error(error);
            throw new Error(`${error}`);
        }
    }

    /**
     * Get connectors/configuration details for a given available integrations
     */
    async getInstalledIntegration(id: string | undefined) {
        try {
            if (!id) {
                throw new Error(
                    "An integration id is needed for getting details about this integration."
                );
            }
            const tenantId = ApiService.AUTH_SERVICE.getTenantId();
            const result = await super.get(
                `tenants/${tenantId}/installedIntegrations/${id}`
            );

            return result as AvailableIntegration;
        } catch (error) {
            console.error(error);
            throw new Error(`${error}`);
        }
    }

    /**
     * Get the list of installed integrations
     */
    async getInstalledIntegrations() {
        const tenantId = ApiService.AUTH_SERVICE.getTenantId();

        try {
            const result = await super.get(
                `tenants/${tenantId}/installedIntegrations`
            );

            return result as Array<InstalledIntegration>;
        } catch (error) {
            console.error(error);
            throw new Error(`${error}`);
        }
    }

    /**
     * Get the status of the given installed integrations
     */
    async getIntegrationStatus(integrationId) {
        const tenantId = ApiService.AUTH_SERVICE.getTenantId();
        // When an integration is succesfully uninstalled, the response will come back with 404
        const response = await super.get(
            `tenants/${tenantId}/installedIntegrations/${integrationId}/status`
        );

        return response as {
            status: InstalledIntegrationStatus;
            error: IntegrationErrorDetails;
        };
    }

    /**
     * Install an integration
     */
    async installIntegration(integrationId, integrationVersion, payload) {
        const tenantId = ApiService.AUTH_SERVICE.getTenantId();

        try {
            const result = await super.post(
                `tenants/${tenantId}/installedIntegrations/${integrationId}/${integrationVersion}`,
                payload
            );

            return result as InstalledIntegration;
        } catch (error) {
            console.error(error);
            throw new Error(`${error}`);
        }
    }

    /**
     * Update Integration details
     * 
     * @param integrationId 
     * @param payload 
     * 
     * @returns {Promise}
     */
    async updateIntegration(integrationId, payload) {
        const tenantId = ApiService.AUTH_SERVICE.getTenantId();
        try {
            const result = await super.put(
                `tenants/${tenantId}/installedIntegrations/${integrationId}`,
                payload
            );

            return result as any;
        } catch (error) {
            console.error(error);
            throw new Error(`${error}`);
        }
    }

    /**
     * Upgrade an integration
     */
    async upgradeIntegration(integrationId, integrationVersion) {
        const tenantId = ApiService.AUTH_SERVICE.getTenantId();

        try {
            const result = await super.put(
                `tenants/${tenantId}/installedIntegrations/${integrationId}/${integrationVersion}`
            );

            return result as InstalledIntegration;
        } catch (error) {
            console.error(error);
            throw new Error(`${error}`);
        }
    }

    /**
     * Uninstall an integration
     */
    async uninstallIntegration(integrationId) {
        const tenantId = ApiService.AUTH_SERVICE.getTenantId();
        try {
            const result = await super.delete(
                `tenants/${tenantId}/installedIntegrations/${integrationId}`
            );

            return result;
        } catch (error) {
            console.error(error);
            throw new Error(`${error}`);
        }
    }

    /** returns all the connectors for the specified integration id.
     *  @param integrationId the id that defines the type of integration the connectors will come from.
     *
     *  @returns a Promise which resolves to the returned connectors.*/
    async getConnectors(integrationId: string) {
        const tenantId = ApiService.AUTH_SERVICE.getTenantId();

        try {
            const response = await super.get(
                `tenants/${tenantId}/installedIntegrations/${integrationId}/connectors`
            );

            const result = (
                response as Array<InstalledIntegrationConnector>
            ).filter((connector) => connector.tenantId === tenantId);

            return result;
        } catch (error) {
            throw new Error(`${error}`);
        }
    }

    /** returns the connector details for the specified integration id.
     *  @param integrationId - the id that defines the type of integration the connectors will come from.
     *  @param connectorId - the id that specifies which connector details we are going to receive
     *
     *  @returns a Promise which resolves to the returned connectors.*/
    async getConnectorDetails(integrationId: string, connectorId: string) {
        const tenantId = ApiService.AUTH_SERVICE.getTenantId();

        try {
            const response = await super.get(
                `tenants/${tenantId}/installedIntegrations/${integrationId}/connectors/${connectorId}`
            );

            return response as InstalledIntegrationConnectorDetails;
        } catch (error) {
            throw new Error(`${error}`);
        }
    }

    /**
     * returns a list with the installed integrations to be used in the runbooks
     *
     * Note: This is not an API Endpoint
     *
     *  @returns a Promise which resolves to the returned integrations.*/
    async getRunbookIntegrations(): Promise<RunbookIntegrationDetails[]> {
        try {
            const [installed, available] = await Promise.all([
                this.getInstalledIntegrations(),
                this.getAvailableIntegrations(),
            ]);

            // We need to get the name of the integration from the available integrations list
            return installed.map((installedIntegration) => {
                const matchingAvailableIntegration = available.find(
                    (el) => el.id === installedIntegration.integrationId
                );

                return {
                    name: matchingAvailableIntegration?.name,
                    id: matchingAvailableIntegration?.id,
                    branding: {
                        icons: matchingAvailableIntegration?.branding?.icons,
                        primaryColor:
                            matchingAvailableIntegration?.branding.primaryColor,
                        secondaryColor:
                            matchingAvailableIntegration?.branding
                                ?.secondaryColor,
                    },
                } as RunbookIntegrationDetails;
            });
        } catch (error) {
            throw new Error(`${error}`);
        }
    }

    /**
     * returns a list with the installed integrations to be used in the runbooks.  This 
     *  function will also get the connectors for the installed integrations.
     *
     * Note: This is not an API Endpoint
     *
     *  @returns a Promise which resolves to the returned integrations.*/
    async getRunbookIntegrationsAndConnectors(): Promise<RunbookIntegrationDetails[]> {
        try {
            const integrationDetails: RunbookIntegrationDetails[] = await this.getRunbookIntegrations();

            const integrationIds: string[] = integrationDetails.map(integration => integration.id);

            const connectors = await Promise.all(integrationIds.map(integrationId => this.getConnectors(integrationId)));
            integrationDetails.forEach((integration, index) => {
                integration.connectors = connectors[index];
            });

            return integrationDetails;
        } catch (error) {
            throw new Error(`${error}`);
        }
    }
}

const IntegrationLibraryService = new IntegrationLibraryApiService();
export { IntegrationLibraryService };
