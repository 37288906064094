/* eslint-disable no-template-curly-in-string */
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Classes, Divider, /*FormGroup, MenuItem*/ } from '@blueprintjs/core';
import { Form, InputField, SelectField } from 'components/common/form';
import { STRINGS } from 'app-strings';
import * as yup from 'yup';
import { FormikProps } from 'formik';
// import { Suggest } from '@blueprintjs/select';
// import { awsRegions, awsServices } from './awsRegionsAndServices';

export interface AWSsignatureAuthParamsPanelProps {
    isEdit: boolean;
    authMethod: string | number | undefined;
    awsSignatureVersion: string | undefined;
    onChangeAwsSignatureVersion: (event) => void;
    awsAccessKeyId: string | undefined;
    onChangeAwsAccessKeyId: (event) => void;
    awsSecretAccessKey: string | undefined;
    onChangeAwsSecretAccessKey: (event) => void;
    // awsRegion: string | undefined;
    // onChangeAwsRegion: (event) => void;
    // awsService: string | undefined;
    // onChangeAwsService: (event) => void;
}

const AWSsignatureAuthParamsPanel: React.FC<AWSsignatureAuthParamsPanelProps> = (props) => {

    const { 
        isEdit,
        authMethod,
        awsSignatureVersion,
        onChangeAwsSignatureVersion,
        awsAccessKeyId,
        onChangeAwsAccessKeyId,
        awsSecretAccessKey,
        onChangeAwsSecretAccessKey,
        // awsRegion,
        // onChangeAwsRegion,
        // awsService,
        // onChangeAwsService,
    } = props;

    useEffect(() => {
        if (isEdit) {
            if (authMethod === 4) {
                onChangeAwsSignatureVersion({
                    target: {
                        value: 'AwsSignatureV4'
                    }
                });
            } else if (authMethod === 5) {
                onChangeAwsSignatureVersion({
                    target: {
                        value: 'AwsSignatureV4A'
                    }
                });
            }
        } else {
            onChangeAwsSignatureVersion({
                target: {
                    value: awsSignatureVersion || 'AwsSignatureV4'
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const awsSignatureVersions: Array<{ label: string, value: string }> = [{
        label: 'AWS Signature Version 4',
        value: 'AwsSignatureV4'
    },{
        label: 'AWS Signature Version 4a (multi-region)',
        value: 'AwsSignatureV4A'
    }];

    const validationSchema = yup.object().shape({
        grant_type: yup.string(),
        aws_signature_version: yup
            .string()
            .required()
            .label(STRINGS.thirdPartyIntegrations.addAuthProfile.panels.awsSignatureAuthParams.fields.version.label),
        aws_access_key_id: yup
            .string()
            .required()
            .label("AWS Access Key ID"),
        aws_secret_access_key: yup
            .string()
            .required()
            .label("AWS Secret Access Key"),
        // aws_region: yup
        //     .string()
        //     .required()
        //     .label("AWS Region"),
        // aws_service: yup
        //     .string()
        //     .required()
        //     .label("AWS Service"),
    });

    // const AutocompleteSuggest = Suggest.ofType<string>();

    return (
        <div className={classNames(Classes.DIALOG_BODY)}>
            <p>
                <b>
                    {
                        STRINGS.thirdPartyIntegrations.addAuthProfile.panels.awsSignatureAuthParams.title
                    }
                </b>
            </p>
            <Divider />
            <Form
                className="mt-2"
                initialValues={{
                    aws_signature_version: awsSignatureVersion,
                    aws_access_key_id: awsAccessKeyId,
                    aws_secret_access_key: awsSecretAccessKey,
                    // aws_region: awsRegion,
                    // aws_service: awsService,
                }}
                validationSchema={validationSchema}
                loading={false}
            >
                {(formProps: FormikProps<object>) => <>
                <SelectField 
                    name="aws_signature_version"
                    label={
                        STRINGS.thirdPartyIntegrations.addAuthProfile.panels.awsSignatureAuthParams.fields.version.label
                    }
                    onChange={onChangeAwsSignatureVersion}
                    value={awsSignatureVersion}
                    required={true}
                >
                        <option value="">
                            {STRINGS.thirdPartyIntegrations.addAuthProfile.panels.awsSignatureAuthParams.fields.version.placeholder}
                        </option>
                        {awsSignatureVersions?.map((type, i) => <option key={i} value={type.value}>{type.label}</option>)}
                </SelectField>
                { !!awsSignatureVersion && (
                    <>
                        <InputField
                            name="aws_access_key_id"
                            type="text"
                            required={true}
                            label={STRINGS.thirdPartyIntegrations.addAuthProfile.panels.awsSignatureAuthParams.fields.accessKeyID.label}
                            placeholder={STRINGS.thirdPartyIntegrations.addAuthProfile.panels.awsSignatureAuthParams.fields.accessKeyID.placeholder}
                            onBlur={(event) => {
                                const value = event.target.value?.trim();
                                formProps.handleBlur(event);

                                props.onChangeAwsAccessKeyId && props.onChangeAwsAccessKeyId({target: {
                                    value: value
                                }});
                            }}
                            onChange={onChangeAwsAccessKeyId}
                            value={awsAccessKeyId}
                            disabled={false}
                        />
                        <InputField
                            name="aws_secret_access_key"
                            type="password"
                            required={true}
                            label={STRINGS.thirdPartyIntegrations.addAuthProfile.panels.awsSignatureAuthParams.fields.secretAccessKey.label}
                            placeholder={STRINGS.thirdPartyIntegrations.addAuthProfile.panels.awsSignatureAuthParams.fields.secretAccessKey.placeholder}
                            onBlur={(event) => {
                                const value = event.target.value?.trim();
                                formProps.handleBlur(event);

                                props.onChangeAwsSecretAccessKey && props.onChangeAwsSecretAccessKey({target: {
                                    value: value
                                }});
                            }}
                            onChange={onChangeAwsSecretAccessKey}
                            value={awsSecretAccessKey}
                            disabled={false}
                            showPasswordOption={true}
                        />
                        {/* <FormGroup className={awsSignatureVersion !== "AwsSignatureV4" ? "d-none" : ""} 
                            label={STRINGS.thirdPartyIntegrations.addAuthProfile.panels.awsSignatureAuthParams.fields.region.label} labelInfo={"* "}>
                            <AutocompleteSuggest
                                inputProps={{
                                    placeholder: STRINGS.thirdPartyIntegrations.addAuthProfile.panels.awsSignatureAuthParams.fields.region.placeholder,
                                    name: "aws_region",
                                }}
                                defaultSelectedItem={{ label: awsRegions.find(region => region.code === awsRegion)?.label, code: awsRegion } as any}
                                resetOnSelect={true}
                                closeOnSelect={true}
                                resetOnClose={true}
                                popoverProps={{ minimal: true, boundary: 'viewport' }}
                                items={awsRegions as any}
                                itemRenderer={(item, { handleClick, modifiers, query }): JSX.Element => {
                                    return (
                                        <MenuItem
                                            active={modifiers.active}
                                            key={"ac-" + (item  as any).code}
                                            onClick={handleClick}
                                            text={<span>{(item  as any).label}</span>}
                                        />
                                    );
                                }}
                                itemPredicate={(query, item) => {
                                    const queryLowerCase = query?.toLowerCase() || "";
                                    return (item  as any).label.toLowerCase().includes(queryLowerCase) ? true : false;
                                }}
                                fill={true}
                                inputValueRenderer={item => (item as any).label}
                                onItemSelect={(item) => {
                                    onChangeAwsRegion((item as any).code);
                                }}
                                noResults={<MenuItem disabled={true} text={STRINGS.globalFilters.empty} />}
                            />
                        </FormGroup> */}
                        {/* <FormGroup label={STRINGS.thirdPartyIntegrations.addAuthProfile.panels.awsSignatureAuthParams.fields.service.label} labelInfo={"* "}>
                            <AutocompleteSuggest
                                inputProps={{
                                    placeholder: STRINGS.thirdPartyIntegrations.addAuthProfile.panels.awsSignatureAuthParams.fields.service.placeholder,
                                    name: "aws_service",
                                }}
                                defaultSelectedItem={{ label: awsServices.find(service => service.code === awsService)?.label, code: awsService } as any}
                                resetOnSelect={true}
                                closeOnSelect={true}
                                resetOnClose={true}
                                popoverProps={{ minimal: true, boundary: 'viewport' }}
                                items={awsServices as any}
                                itemRenderer={(item, { handleClick, modifiers, query }): JSX.Element => {
                                    return (
                                        <MenuItem
                                            active={modifiers.active}
                                            key={"ac-" + (item  as any).code}
                                            onClick={handleClick}
                                            text={<span>{(item  as any).label}</span>}
                                        />
                                    );
                                }}
                                itemPredicate={(query, item) => {
                                    const queryLowerCase = query?.toLowerCase() || "";
                                    return (item  as any).label.toLowerCase().includes(queryLowerCase) ? true : false;
                                }}
                                fill={true}
                                inputValueRenderer={item => (item as any).label}
                                onItemSelect={(item) => {
                                    onChangeAwsService((item as any).code);
                                }}
                                noResults={<MenuItem disabled={true} text={STRINGS.globalFilters.empty} />}
                            />
                        </FormGroup> */}
                    </>
                )}
                </>
                }
            </Form>
        </div>
    );
};

export { AWSsignatureAuthParamsPanel };