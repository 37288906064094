/** This file defines the default options for most of the charts in the product.
 *  @module */
import Highcharts from 'highcharts';
import { STRINGS } from "app-strings";

/** these default options that should be applied to all high charts options objects. */
export const BASE_CHART_OPTIONS: Highcharts.Options = {
    credits: {
        enabled: false
    },
    navigation: {
        buttonOptions: {
            enabled: false
        }
    }
};

/** the default highcharts options for donut charts. */
export const DONUT_CHART_OPTIONS: Highcharts.Options = Highcharts.merge(BASE_CHART_OPTIONS, {
    chart: {
        height: null,
        width: null,
        type: 'pie',
        reflow: true,
        backgroundColor: 'transparent',
        spacingTop: 0,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        plotBorderWidth: 0,
        margin: [0, 0, 0, 0]
    },
    legend: {
        enabled: false
    },
    title: {
        text: ''
    },
    series: [{
        colorByPoint: true,
        innerSize: '85%',
        borderColor: 'transparent',
        data: [],
        animation: {
            defer: 200,
            duration: 500
        },
        dataLabels: {
            enabled: false,
        }
    }],
    tooltip: {
        enabled: true,
        backgroundColor: "white",
        shared: true,
        formatter: function(this: {y: number}) {
            return this.y;
        }
    }
});

/** these are the default highcharts options for time charts. */
export const DEFAULT_TIME_SERIES_OPTIONS: Highcharts.Options = Highcharts.merge(BASE_CHART_OPTIONS, {
    chart: {
        height: null,
        width: null,
        type: "area",
        reflow: true,
        backgroundColor: "transparent",
        events: {}
    },
    legend: {
        enabled: false,
    },
    xAxis: {
        visible: false,
        labels: {
            staggerLines: 2,
        }
    },
    yAxis: {
        visible: false,
    },
    title: {
        text: "",
    },
    plotOptions: {
        series: {
            color: "#88C",
            marker: {
                enabled: false,
                symbol: 'square'
                // This was used to get the time chart to show square markers in the legend, but it 
                // messed up the tooltips
                // enabled: undefined,
                // symbol: 'square',
                // radius: 12,
                // enabledThreshold: 1000
            }
        }
    },
    series: [{
        type: 'area',
        data: [],
        animation: {
            duration: 500,
        },
        borderColor: "transparent",
    }],
    tooltip: {
        enabled: true,
        backgroundColor: "white",
        shared: true
    },
    time:{
        useUTC: false
    },
    //Display when chartData:{data:[]}
    lang: {
        noData: STRINGS.no_data_to_display
    },
    noData: {
        style: {
            fontWeight: 'bold',
            fontSize: '13px'
        }
    }
});

/** these are the default Highcharts options for the bar chart. */
export const DEFAULT_BAR_CHART_OPTIONS: Highcharts.Options = Highcharts.merge(BASE_CHART_OPTIONS, {
    chart: {
        type: 'bar',
        backgroundColor: "transparent"
    },
    title: {
        text: ''
    },
    xAxis: {
        categories: [],
        title: {
            text: null
        },
        grid: {
            enabled: false,
        },
        lineColor: 'transparent',
        tickWidth: 0,
    },
    yAxis: {
        gridLineColor: 'transparent',
        min: 0,
        minorTicks: false,
        plotLines: [{
            color: "#AAA",
            width: 1,
            value: 0
        }],
        title: {
            text: '',
            align: 'high'
        },
        labels: {
            enabled: false,
            overflow: 'justify'
        }
    },
    series: [{
        type: 'bar',
        name: '',
        data: [],
    }],
    legend: {
        enabled: false
    },
    plotOptions: {
        bar: {
            dataLabels: {
                enabled: true
            },
            groupPadding: 0.005,
            animation: true,
            pointPadding: 0.005,
            maxPointWidth: 18
        }
    },
    noData: {
        style: {
            fontWeight: '200',
            fontSize: '1rem',
        }
    }
});

/** these are the default HighCharts options for the pie chart. */
export const DEFAULT_PIE_CHART_OPTIONS: Highcharts.Options = Highcharts.merge(BASE_CHART_OPTIONS, {
    chart: {
        type: 'pie',
        backgroundColor: "transparent"
    },
    title: {
        text: ''
    },
    xAxis: {
        categories: [],
        title: {
            text: null
        },
        grid: {
            enabled: false,
        },
        lineColor: 'transparent',
        tickWidth: 0,
    },
    yAxis: {
        gridLineColor: 'transparent',
        min: 0,
        title: {
            text: '',
            align: 'high'
        },
        labels: {
            enabled: false,
            overflow: 'justify'
        }
    },
    series: [{
        type: 'pie',
        name: '',
        data: [],
    }],
    legend: {
        enabled: true
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            animation: true,
            startAngle: 90,
            cursor: 'pointer',
            minSize: 100,
            borderWidth: 0,
            dataLabels: {
                enabled: true,
                padding: 0,
                distance: 35
            },
            depth: 20,
            states: {
                hover: {
                    enabled: true
                }
            },
            shadow: false,
            slicedOffset: 15,
            showInLegend: true
        },
    }
});

/** these are the default Highcharts options for the gauge chart. */
export const DEFAULT_GAUGE_CHART_OPTIONS: Highcharts.Options = Highcharts.merge(BASE_CHART_OPTIONS, {
    chart: {
        type: 'solidgauge',
        height: null,
        width: null,
        backgroundColor: "transparent"
    },
    title: undefined,
    pane: {
        center: ['50%', '85%'],
        size: '140%',
        startAngle: -90,
        endAngle: 90,
        background: {
            backgroundColor: Highcharts?.defaultOptions?.legend?.backgroundColor || '#EEE',
            innerRadius: '60%',
            outerRadius: '80%',
            shape: 'arc',
        }
    },
    series: [{
        type: 'solidgauge',
        innerRadius: '60%',
        radius: '80%',
    }],
    exporting: {
        enabled: false
    },
    tooltip: {
        enabled: false
    },
    // the value axis
    yAxis: {
        stops: [
            [0.1, '#55BF3B'], // green
            [0.5, '#DDDF0D'], // yellow
            [0.9, '#DF5353']  // red
        ],
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        title: {
            classNames: ["display-7"],
            y: -50
        },
        labels: {
            y: 16
        }
    },
    plotOptions: {
        solidgauge: {
            dataLabels: {
                y: 5,
                borderWidth: 0,
                useHTML: true
            }
        }
    }
});
