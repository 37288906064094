import React, { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import * as yup from 'yup';
import moment from 'moment';
import { Alignment, Button, Dialog, Intent, Switch } from '@blueprintjs/core';
import { LoadingOverlay, ErrorToaster } from '@tir-ui/react-components';
import { useStateSafePromise } from 'utils/hooks';
//import { validateIP } from 'utils/validators/Validators';
import {
	Form,
	InputField,
	SelectField,
	TextAreaField,
} from 'components/common/form';
import { DATASOURCE_STATE } from '../views/DataSourceStatus';
import { AuthServiceProvider } from 'utils/providers/AuthServiceProvider';
import {
	DataSourceService,
	dataSourceTypeOptions,
} from 'utils/services/DataSourceApiService';

import { STRINGS } from 'app-strings';

import './AddDataSourceModal.scss';

const AuthService = AuthServiceProvider.getService();

const HTTPS_PROTOCOL = 'https';

const AddDataSourceModal = React.forwardRef((props: any, ref) => {
	React.useImperativeHandle(ref, () => ({
		setGatewayId(id: string) {
			if (id) {
				setGatewayId(id);
			}
		},
		setDataSourceId(id: string) {
			if (id) {
				setDataSourceId(id);
			}
		},
		setEdit(edit: boolean) {
			setEditMode(edit);
		},
		handleOpen() {
			setIsOpen(!isOpen);
		},
	}));

	const { dataRefresh, setDelay } = props;
	const [editMode, setEditMode] = useState<boolean>(false);
	const [gatewayId, setGatewayId] = useState<string | undefined>(undefined);
	const [dataSourceId, setDataSourceId] = useState<string | undefined>(
		undefined
	);
	const [dataSourceState, setDataSourceState] = useState<boolean>(false);
	const [dataSource, setDataSource] = useState<any>({});
	const [dataSourceValues, setDataSourceValues] = useState<any>({
		gateway_ip: '',
		source_name: '',
		source_type: '',
		source_hostname: '',
		source_proto: '',
		source_port: '',
	});
	const [isOpen, setIsOpen] = useState(false);
	const handleClose = useCallback(() => {
		setIsOpen(false);
		setDataSourceValues({});
	}, []);

	const [executeSafely] = useStateSafePromise();
	const [loading, setLoading] = useState(false);
	const fetchDataSource = useCallback(
		(id: string | undefined) => {
			return executeSafely(DataSourceService.getDataSource(id)).then(
				(response: any) => {
					setDataSource(response);
					setValues(response);
					setLoading(false);
				},
				(error) => {
					console.error(error);
					setLoading(false);
				}
			);
		},
		[executeSafely]
	);

	const setValues = (item: any): void => {
		const dataSource = {
			gateway_ip: item.entity.attributes.iot.host,
			source_name: item.entity.attributes.name,
			source_type: item.entity.attributes.type,
			source_hostname: item.entity.attributes.host,
			source_proto: item.entity.attributes.proto,
			source_port: item.entity.attributes.port,
		};
		setDataSourceValues(dataSource);
		setDataSourceState(
			item.entity.attributes.state === DATASOURCE_STATE.ENABLED
				? true
				: false
		);
	};

	useEffect(() => {
		if (editMode && isOpen) {
			setLoading(true);
			fetchDataSource(dataSourceId);
		}
	}, [editMode, dataSourceId, fetchDataSource, isOpen]);

	const validationSchema = yup.object().shape({
		gateway_ip: yup.string().when([], {
			is: () => !editMode || !dataSourceState,
			then: yup.string().nullable(),
			otherwise: yup
				.string()
				.label(
					STRINGS.DATA_SOURCES.addDataSourceDialog.fields.gatewayIp
				)
				.required(),
		}),
		source_name: yup
			.string()
			.label(
				STRINGS.DATA_SOURCES.addDataSourceDialog.fields
					.sourceDescription
			)
			.required(),
		source_type: yup
			.string()
			.label(STRINGS.DATA_SOURCES.addDataSourceDialog.fields.sourceType)
			.required()
			.notOneOf([
				STRINGS.DATA_SOURCES.addDataSourceDialog.fields.chooseType,
			]),
		source_hostname: yup
			.string()
			.label(
				STRINGS.DATA_SOURCES.addDataSourceDialog.fields.sourceHostname
			)
			.required(),
		source_protocol: yup
			.string()
			.label(
				STRINGS.DATA_SOURCES.addDataSourceDialog.fields.sourceProtocol
			)
			.required(),
		source_port: yup
			.number()
			.label(STRINGS.DATA_SOURCES.addDataSourceDialog.fields.sourcePort)
			.required(),
	});

	/***
	 * onSubmit Handler function for the support formik form
	 * Resets the form after successful submission
	 * @param values
	 * @param resetForm
	 */
	const handleSubmit = (values, { setErrors, resetForm }) => {
		const payload = {
			version: '0.0.1',
			timestamp: moment().valueOf().toString(),
			doctype: 'metadata',
			utid: AuthService.getTenantId(),
			entity: {
				kind: 'data_source',
				attributes: {
					name: values.hasOwnProperty('source_name')
						? values['source_name'].toString().trim()
						: null,
					type: values.hasOwnProperty('source_type')
						? values['source_type']
						: null,
					host: values.hasOwnProperty('source_hostname')
						? values['source_hostname'].toString().trim()
						: null,
					proto: values.hasOwnProperty('source_protocol')
						? values['source_protocol'].toString().trim()
						: null,
					port: values.hasOwnProperty('source_port')
						? values['source_port'].toString().trim()
						: null,
					state: dataSourceState
						? DATASOURCE_STATE.ENABLED
						: DATASOURCE_STATE.DISABLED,
					iot: {
						device: gatewayId,
						host: values.hasOwnProperty('gateway_ip')
							? values['gateway_ip'].toString().trim()
							: null,
					},
				},
			},
		};

		return new Promise((resolve, reject) => {
			(editMode
				? DataSourceService.updateDataSource(
						dataSourceId,
						_.merge(dataSource, payload)
				  )
				: DataSourceService.createDataSource(payload)
			).then(
				(response) => {
					setDelay();
					resetForm();
					handleClose();
					setTimeout(() => {
						dataRefresh();
					}, 3000);
					resolve(response);
				},
				(error) => {
					ErrorToaster({
						message: error.message,
					});
					setErrors(error.message);
					reject(error);
				}
			);
		});
	};

	const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDataSourceState(event.target.checked);
	};

	if (loading) {
		return <LoadingOverlay visible={true} />;
	} else
		return (
			<React.Fragment>
				<Dialog
					title={
						editMode
							? STRINGS.DATA_SOURCES.addDataSourceDialog.title
									.edit
							: STRINGS.DATA_SOURCES.addDataSourceDialog.title.add
					}
					isOpen={isOpen}
					autoFocus={true}
					canEscapeKeyClose={true}
					canOutsideClickClose={true}
					enforceFocus={true}
					usePortal={true}
					onClose={handleClose}
					style={{ width: '360px' }}
				>
					<div className="tir-data-source">
						<Form
							initialValues={{
								source_enabled: dataSourceValues[
									'source_enabled'
								]
									? dataSourceValues['source_enabled']
									: '',
								gateway_ip: dataSourceValues['gateway_ip']
									? dataSourceValues['gateway_ip']
									: '',
								source_name:
									dataSourceValues['source_name'] &&
									dataSourceValues['source_name'],
								source_type:
									dataSourceValues['source_type'] &&
									dataSourceValues['source_type'],
								source_hostname:
									dataSourceValues['source_hostname'] &&
									dataSourceValues['source_hostname'],
								source_protocol: HTTPS_PROTOCOL,
								source_port:
									dataSourceValues['source_port'] &&
									dataSourceValues['source_port'],
							}}
							validationSchema={validationSchema}
							loading={false}
							onSubmit={handleSubmit}
							onSuccessMessage={
								editMode
									? STRINGS.DATA_SOURCES.addDataSourceDialog
											.messages.editedSuccessfully
									: STRINGS.DATA_SOURCES.addDataSourceDialog
											.messages.addedSuccessfully
							}
						>
							{editMode ? (
								<React.Fragment>
									<InputField
										id="gateway_ip"
										name="gateway_ip"
										type="text"
										className="gateway-ip-field"
										label={
											STRINGS.DATA_SOURCES
												.addDataSourceDialog.fields
												.gatewayIp
										}
										helperText={
											STRINGS.DATA_SOURCES
												.addDataSourceDialog.fieldsHelp
												.gatewayIp
										}
										disabled={false}
									/>
									<Switch
										id="source_enabled"
										name="source_enabled"
										checked={dataSourceState}
										label={
											STRINGS.DATA_SOURCES
												.addDataSourceDialog.fields
												.sourceEnabled
										}
										onChange={handleStateChange}
										alignIndicator={Alignment.RIGHT}
										inline={true}
										disabled={!editMode}
									/>{' '}
								</React.Fragment>
							) : null}
							<SelectField
								label={
									STRINGS.DATA_SOURCES.addDataSourceDialog
										.fields.sourceType
								}
								className="source-field"
								required={true}
								id="source_type"
								name="source_type"
								options={[
									STRINGS.DATA_SOURCES.addDataSourceDialog
										.fields.chooseType,
								].concat(
                                    [dataSourceTypeOptions.NetProfiler, dataSourceTypeOptions.NetIm, dataSourceTypeOptions.AppResponse]
								)}
								onChange={(event) => {
									setDataSourceValues({
										...dataSourceValues,
										source_type: event.currentTarget?.value,
										source_port:
											event.currentTarget?.value ===
											dataSourceTypeOptions.NetIm
												? '8543'
												: '443',
									});
								}}
								value={dataSourceValues['source_type']}
							/>
							<InputField
								id="source_hostname"
								name="source_hostname"
								type="text"
								required={true}
								className="hostname-field"
								label={
									STRINGS.DATA_SOURCES.addDataSourceDialog
										.fields.sourceHostname
								}
								disabled={false}
							/>
							<div
								style={{
									display: 'flex',
								}}
							>
								<div style={{ display: 'inline-block' }}>
									<InputField
										id="source_protocol"
										name="source_protocol"
										type="text"
										required={true}
										className="protocol-field"
										label={
											STRINGS.DATA_SOURCES
												.addDataSourceDialog.fields
												.sourceProtocol
										}
										disabled={true}
									/>
								</div>
								<div
									style={{
										display: 'inline-block',
										paddingLeft: '4%',
									}}
								>
									<InputField
										id="source_port"
										name="source_port"
										type="text"
										required={true}
										className="port-field"
										label={
											STRINGS.DATA_SOURCES
												.addDataSourceDialog.fields
												.sourcePort
										}
										disabled={false}
										value={dataSourceValues['source_port']}
									/>
								</div>
							</div>
							<TextAreaField
								data-testid="source-name"
								required={true}
								id="source_name"
								name="source_name"
								inline={false}
								className="name-field"
								label={
									STRINGS.DATA_SOURCES.addDataSourceDialog
										.fields.sourceDescription
								}
								disabled={false}
							/>
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
								}}
							>
								<div style={{ display: 'inline-block' }}>
									<Button
										className="user-btn"
										onClick={() => {
											handleClose();
										}}
									>
										{
											STRINGS.DATA_SOURCES
												.addDataSourceDialog.buttons
												.cancelBtnText
										}
									</Button>
								</div>
								<div
									style={{
										display: 'inline-block',
										paddingLeft: '5px',
									}}
								>
									<Button
										type="submit"
										className="tir-ui-btn-submit"
										intent={Intent.PRIMARY}
									>
										{
											STRINGS.DATA_SOURCES
												.addDataSourceDialog.buttons
												.submitBtnText
										}
									</Button>
								</div>
							</div>
						</Form>
					</div>
				</Dialog>
			</React.Fragment>
		);
});

export { AddDataSourceModal };
