/** This file defines the cloudim topology view react component. The 
 *  cloudim topology view react component displays a query made to a
 *  cloudim service.
 *  @module */
import React, { useContext, useState, useEffect, useRef } from "react";
import { loader } from "graphql.macro";
import { Query } from "reporting-infrastructure/types/Query";
import { useQuery, useQueryParams, FILTER_NAME } from "utils/hooks";
import { PARAM_NAME } from "components/enums/QueryParams";
import { STRINGS } from 'app-strings';
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { ReactFlowProvider } from "react-flow-renderer";
import { AutoUpdateContext } from "pages/cloudim/CloudIMPage";
import { Region } from "pages/cloudim/views/cloudim-geomap/CloudIMGeoMapView";
import { CloudIMGraphDef, AWSLayoutGraph, NetIMLayoutGraph } from "utils/cloudim/TopologyUtils";
import { LayoutControlHandle } from "components/common/graph/cloudim-react-flow/components/layout/LayoutControl";
import CloudIMReactFlowGraph from "components/common/graph/cloudim-react-flow/CloudIMReactFlowGraph";
import { defaultOptions, presetOptions } from "utils/cloudim/ELKLayout";

export interface CloudIMTopologyViewProps {
    regionDetail: Region;
    tokens?: Array<string>;
}

const CloudIMTopologyView = ({ regionDetail, tokens }: CloudIMTopologyViewProps): JSX.Element => {
    const { params } = useQueryParams({
        listenOnlyTo: [PARAM_NAME.layoutOption]
    })

    const autoUpdate = useContext(AutoUpdateContext);
    const [graphDef, setGraphDef] = useState<CloudIMGraphDef | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const layoutRef = useRef<LayoutControlHandle>();

    const awsQuery = useQuery({
        query: new Query(loader("./cloudim-aws-resources.graphql")),
        name: "CloudIM AWS Resources",
        requiredFilters: [FILTER_NAME.regions],
        consumedFilters: [FILTER_NAME.token],
        filters: {
            [FILTER_NAME.regions]: regionDetail.name,
            [FILTER_NAME.token]: tokens
        },
        queryVariables: {
            unique: true
        },
        timeNotRequired: true,
        skipGlobalFilters: true,
        lazy: true
    });

    const netImQuery = useQuery({
        query: new Query(loader("./cloudim-netim-resources.graphql")),
        name: "CloudIM OnPrem Resources",
        requiredFilters: [FILTER_NAME.latitude, FILTER_NAME.longitude],
        consumedFilters: [FILTER_NAME.token],
        filters: {
            [FILTER_NAME.latitude]: regionDetail.longitude,
            [FILTER_NAME.longitude]: regionDetail.longitude,
            [FILTER_NAME.token]: tokens
        },
        timeNotRequired: true,
        skipGlobalFilters: true,
        lazy: true
    });

    // Process data to build graph data inside a useEffect hook so that it only happens
    // on init and when 'data' changes
    const lastSequenceNumber = useRef(0);
    const lastRegionDetail = useRef({});
    useEffect(
        () => {
            if (lastSequenceNumber.current !== autoUpdate.sequenceNumber || lastRegionDetail.current !== regionDetail) {
                lastSequenceNumber.current = autoUpdate.sequenceNumber;
                lastRegionDetail.current = regionDetail;

                let graph: CloudIMGraphDef | null = null;
                setIsLoading(true);
                if (regionDetail.platform === "AWS") {
                    awsQuery.run({
                        fetchPolicy: "no-cache",
                        filters: { [FILTER_NAME.regions]: regionDetail.name, [FILTER_NAME.token]: tokens }
                    }).then((data) => {
                        return AWSLayoutGraph(data.resources, params[PARAM_NAME.layoutOption] ? layoutRef.current?.getOptions() : defaultOptions, layoutRef.current?.getOverlapRemoval());
                    }).then((graph) => {
                        setGraphDef(graph);
                    }).catch(error => console.error(error));
                } else if (regionDetail.platform === "Azure") {
                    // TODO: Add Run AzureQuery
                } else if (regionDetail.platform === "OnPrem") {
                    netImQuery.run({
                        fetchPolicy: "no-cache",
                        filters: { [FILTER_NAME.latitude]: regionDetail.latitude, [FILTER_NAME.longitude]: regionDetail.longitude, [FILTER_NAME.token]: tokens }
                    }).then((data) => {
                        return NetIMLayoutGraph(data.resources, presetOptions.Box, layoutRef.current?.getOverlapRemoval());
                    }).then((graph) => {
                        setGraphDef(graph);
                    }).catch(error => console.error(error));
                }
                setGraphDef(graph);
                setIsLoading(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [autoUpdate, regionDetail, tokens]
    );

    return <>
        <DataLoadFacade className="d-flex flex-wrap" loading={awsQuery.loading || netImQuery.loading || isLoading} loadingText={STRINGS.loading + STRINGS.loadingTextIndicator} error={awsQuery.error || netImQuery.error} data={graphDef && !isLoading}>
            {graphDef &&
                <ReactFlowProvider>
                    <CloudIMReactFlowGraph
                        graphDef={graphDef}
                        showLayoutOptions={regionDetail.platform === "AWS"}
                        ref={layoutRef}
                    />
                </ReactFlowProvider>
            }
        </DataLoadFacade>
    </>;
};

export { CloudIMTopologyView };
