/** This module contains the implementation for the priority reasons view.  The priority reasons
 *  view displays the list of reasons that caused the runbook priority to be set to the current
 *  value.
 *  @module
 */
import React, { useRef, useEffect, useState } from "react";
import { IconNames } from "@tir-ui/react-components";
import { IconTitle } from "components/common/icon-title/IconTitle";
import Markdown from "markdown-to-jsx";
import { PRIORITY_TO_LABEL_MAP, PRIORITY_INDEX, SIZE } from "components/enums";
import { FILTER_NAME, useGlobalFilters, useQuery } from "utils/hooks";
import { Query } from "reporting-infrastructure/data-hub";
import { loader } from "graphql.macro";
import { INCIDENT_DETAILS_STYLE } from "components/enums/QueryParams";
import { PriorityReason, RunbookOutput } from "pages/riverbed-advisor/views/runbook-view/Runbook.type";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { STRINGS } from "app-strings";
import { isEqual } from "lodash";
import { SummaryCard } from "components/common/layout/summary-card/SummaryCard";
import { formatVariables } from "utils/runbooks/RunbookUtils";
import { INCIDENT_SCOPE_BUILTIN_VARIABLES, RUNBOOK_SCOPE_BUILTIN_VARIABLES, removeTypename } from "utils/runbooks/VariablesUtils";

import "./PriorityReasonsView.css";
import { Variant } from "components/common/graph/types/GraphTypes";

const NUM_ROWS_TO_SHOW = 3;

/** an interface that describes the properties that can be passed in to the component.*/
export interface PriorityReasonsViewProps {
    /** a string with the incident uuid. */
    incidentId?: string;
    /** the runbook output to get the priority reasons from instead of querying it here. */
    runbookOutput?: RunbookOutput;
    /** a boolean value, if true the title icons should be displayed if false do not show the title icons. */
    showTitleIcons?: boolean;
    /** the handler to use for details requests. */
    onDetails?: (icon: string, title: string, data: PriorityReason[]) => void;
    /** a boolean value, if true show the footer, if false do not, the default is true. */
    showFooter?: boolean;
}

/** Creates the priority reasons view, which is a component that displays the list of 
 *  reasons why the runbook output was set to a certain priority.
 *  @param props an object with the properties passed to the priority reasons view.
 *  @returns JSX with the priority reasons view component.*/
export function PriorityReasonsView (props: PriorityReasonsViewProps): JSX.Element {
    const {showFooter = true} = props;
    const { filters } = useGlobalFilters({listenOnlyTo: [FILTER_NAME.incidentId]});

    const [incidentVariables, setIncidentVariables] = useState<any>();

    //const consumedFiltersList = [FILTER_NAME.incidentId];

    const allFilters = useRef<Record<string, string>>({
        [FILTER_NAME.incidentId]: (props.incidentId ?  props.incidentId : filters[FILTER_NAME.incidentId]) as string, 
        [FILTER_NAME.variant]: Variant.INCIDENT.toUpperCase()
    });
    const updatedFilters = {
        [FILTER_NAME.incidentId]: (props.incidentId ?  props.incidentId : filters[FILTER_NAME.incidentId]) as string,
        [FILTER_NAME.variant]: Variant.INCIDENT.toUpperCase()
    };
    if (!isEqual(allFilters.current, updatedFilters)) {
        allFilters.current = updatedFilters;
    }

    let { loading, data, error, run } = useQuery({
        name: "RunbooksForPriorityReason",
        query: new Query(loader("./priority-reason-query.graphql")),
        //consumedFilters: consumedFiltersList,
        //requiredFilters: [FILTER_NAME.incidentId, FILTER_NAME.detectionId],
        //supportedGlobalFilters: consumedFiltersList,
        //filters: {
        //    [FILTER_NAME.detectionId]: params[PARAM_NAME.detectionId] ? params[PARAM_NAME.detectionId] : undefined
        //},
        filters: allFilters.current,
        timeNotRequired: true,
        skipGlobalFilters: true,
        lazy: true
    });

    const incidentVariablesQuery = useQuery({
		name: 'IncidentVariables',
		query: new Query(loader('./../../../../utils/hooks/incident-variables-query.graphql')),
	});

    useEffect(() => {
        setIncidentVariables(removeTypename(incidentVariablesQuery.data));
        // API Fetch for incident and global variables
    }, [incidentVariablesQuery.data])

    useEffect(
        () => {
            if (!props.runbookOutput && allFilters.current[FILTER_NAME.incidentId]) {
                run({
                    filters: allFilters.current,
                    lazy: true
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [allFilters.current]
    )

    const reasonsForDetails: Array<PriorityReason> = [];
    let priority = STRINGS.incidents.reasonPriorityUnknown;
    let reasonsJsx: Array<JSX.Element> = [<li key="priority-reason-unknown"><span key="unknown-reason">{STRINGS.priorityReasonsView.noReasonSpecifiedText}</span></li>];
    let runbook: RunbookOutput | undefined = undefined;

    const isValidReasonsArray = (reasons) => {
        if (reasons.length === 0) {
            return false;
        }
    
        for (const reason of reasons) {
            if (reason.text && reason.text.replace(/<[^>]*>/g, "").trim() !== "") {
                return true;
            }
        }
    
        return false;
    };

    if (!loading || props.runbookOutput) {
        if (props.runbookOutput) {
            runbook = props.runbookOutput;
        } else if (data && data?.runbooks?.nodes?.length) {
            runbook = data.runbooks.nodes[data.runbooks.nodes.length - 1];
        }
        if (runbook) {
            loading = true;
            if (runbook.priority) {
                priority = PRIORITY_TO_LABEL_MAP[runbook.priority];
            }
            if (runbook.priorityReasons?.length) {
                const reasons: Array<PriorityReason> = [...runbook.priorityReasons];
                reasons.sort((a, b) => {
                    return PRIORITY_INDEX[a.priority] > PRIORITY_INDEX[b.priority] ? -1 : PRIORITY_INDEX[a.priority] < PRIORITY_INDEX[b.priority] ? 1 : 0;
                });
                reasonsJsx = [];
                //reasonsJsx.push(<Markdown key="priority-reason-0">{runbook.priorityReasons[0].text}</Markdown>);
                //reasonsForDetails.push(runbook.priorityReasons[0].text);
                let showedNoReasonsSpecified = false;
                for (let i = 0; i < Math.min(NUM_ROWS_TO_SHOW, reasons.length); i++) {
                    //reasonsJsx.push(<div key={"priority-reason-and-" + i} className="mb-2 pb-2"><b>and</b></div>);
                    const variables = (runbook.variableResults ?
                        (runbook.variableResults.hasOwnProperty('primitiveVariables') ?
                            runbook.variableResults.primitiveVariables.concat(RUNBOOK_SCOPE_BUILTIN_VARIABLES) :
                            RUNBOOK_SCOPE_BUILTIN_VARIABLES) : RUNBOOK_SCOPE_BUILTIN_VARIABLES)
                                .concat([...incidentVariables?.incidentVariables?.variables?.primitiveVariables || [],
                                ...INCIDENT_SCOPE_BUILTIN_VARIABLES]);
                    let divElement = document.createElement("div");
                    divElement.innerHTML = reasons[i].text;
                    const hasReason = reasons[i].text !== "" && divElement.innerText !== "";
                    if (!isValidReasonsArray(reasons) && !showedNoReasonsSpecified) { 
                        reasonsJsx.push(
                            <li key={"priority-reason-" + i} className={"priority-reason" + (reasons[i].priority ? " " + reasons[i].priority.toLocaleLowerCase() : "")} >
                                <div className={i < Math.min(NUM_ROWS_TO_SHOW, reasons.length) - 1 ? "mb-1" : ""}>
                                    <Markdown>{STRINGS.priorityReasonsView.noReasonSpecifiedText}</Markdown>
                                </div>
                            </li>
                        ); 
                    } else if (hasReason) { reasonsJsx.push(
                            <li key={"priority-reason-" + i} className={"priority-reason" + (reasons[i].priority ? " " + reasons[i].priority.toLocaleLowerCase() : "")} >
                                <div className={i < Math.min(NUM_ROWS_TO_SHOW, reasons.length) - 1 ? "mb-1" : ""}>
                                    <Markdown>{formatVariables(reasons[i].text, variables)}</Markdown>
                                </div>
                            </li>
                        );
                    }
                    if (!isValidReasonsArray(reasons)) {
                        showedNoReasonsSpecified = true;
                    }
                }
                for (let i = 0; i < reasons.length; i++) {
                    reasonsForDetails.push(reasons[i]);
                }
            }
            loading = false;
        }
    }

    return <DataLoadFacade loading={loading} data={data} error={error} showContentsWhenLoading={true} >
        <IconTitle icon={props.showTitleIcons ? IconNames.ISSUE : undefined} 
            title={STRINGS.formatString(STRINGS.incidents.reasonsTitle, {priority})} size={SIZE.m} 
            className="mb-2 font-weight-500"
        />
        <SummaryCard className="pt-1 pb-1 mb-4 w-min-3-force" size="flex" height={SIZE.m} padding="px-3 pb-3 pt-2" center={false}
            hideShadow={INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget"}
            hideBorder={INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget"}
			footerContent={
				(showFooter && !loading && runbook?.priorityReasons?.length) &&
                    <div className="display-9 float-right text-primary clickable pt-2" onClick={() => {
                        if (props.onDetails) {
                            props.onDetails(IconNames.ISSUE, STRINGS.incidents.reasonsTitle, reasonsForDetails)
                        }
                        }}>{ (reasonsForDetails.length - NUM_ROWS_TO_SHOW) > 0 ? (reasonsForDetails.length - NUM_ROWS_TO_SHOW) + ' ' + STRINGS.more : STRINGS.viewAll}
                    </div>
			}
        >
            {!loading &&
                <ul className={"priority-reasons-list" + (runbook?.priority ? " " + runbook.priority.toLocaleLowerCase() : "")}>
                    {reasonsJsx}
                </ul>
            }
        </SummaryCard>
    </DataLoadFacade>;
}
