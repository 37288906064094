/** This module contains the types for the user preferences
 *  @module
 */
import { THEME } from "utils/themes";
import { SEARCH_TYPE } from "pages/incident-search/IncidentSearchPage";

/** Filter-specific user preference keys. */
export enum FILTER_IN_USER_PREFS_KEY {
    global                      = "filters",
    globalRecents               = "filters_r",
    incidentsList               = "ilf",
    incidentListRecents         = "ilf_r",
    networkHealthOverview       = "nhof",
    entityKinds                 = "eks",
    runbookInvocations          = "runbookInvocations",
    runbookInvocationRecents    = "runbookInvocations_r",
};

/** Hint-specific user preference keys. */
export enum HINTS_HIDDEN_USER_PREFS_KEY {
    runbookEditor = "rbe_hints",
}

/** This is a type representing the user preference objects
 *  @TODO Making a top level key as required is not playing
 *  well with typescript. Need to figure out why. */
export type UserPreferences = {
    /** the UI theme that the user is currently using. */
    theme?: THEME | null,
    /** This preference is here for performing any tests during development */
    test?: string,
    /** This key is defined only so that we can use the useUserPreferences hook conditionally.
     * If you do not want to listen to user preference changes based on a condition, you can
     * pass listenTo param as void and user preferences changes won't affect your component  */
    void?: string,
    /** this key is defined to store the users time interval. */
    time?: string,
    /** Auto-connect runbook editor nodes */
    rbe_autoConnect?: string,
    /** Auto-update Incident list page */
    in_aup?: string,
    /** List of incidents watched in incident list page */
    in_watch?: string,
    /** Active tab in Runbook list page */
    rb_tab?: string,
    /** the user preferences for the what's new functionality. */
    whatsNew?: WhatsNewPreference,
    /** the optional search preferences. */
    search?: SearchPreference | null,
    /** the options subflow preference. */
    subflows?: SubflowsPreference | null,
    /** the optional explorer preference. */
    explorer?: ExplorerPreference | null,
    /** the optional explorer facets list preference. */
    explorer_facets?: ExplorerFacetsPreference | null,
    /** the optional sidebar menu preference. */
    menu?: MenuPreference | null,
    /** the optional runbook inputs preference with the inputs indexed by runbook type and runbook id. */
    runbookInputs?: RunbookInputsPreference | null
    /** the optional on demand runbook inputs preference. */
    onDemandRunbookInputs?: OnDemandRunbookInputsPreference | null,
    /** the optional scheduled on demand runbook inputs preference. */
    scheduledOnDemandRunbookInputs?: ScheduledOnDemandRunbookInputsPreference | null,
    /** preferences for CloudIM */
    cloudIM?: CloudIMPreference | null,
    /** Show/hide Timeline chart */
    incidentPage?: IncidentPagePreference | null
}
& { [x in FILTER_IN_USER_PREFS_KEY]?: string }
& { [x in HINTS_HIDDEN_USER_PREFS_KEY]?: string };

/** this is a type representing the stored runbook inputs preference. */
export type RunbookInputsPreference = {
    /** a dictionary with the inputs mapped by runbook type. */
    types: {[x: string]: any},
    /** a dictionary with the inputs mapped by runbook id. */
    runbooks: {[x: string]: any}
}

/** this is a type representing the stored on demand runbook inputs preference. */
export type OnDemandRunbookInputsPreference = {
    /** a dictionary with the inputs mapped by runbook id. */
    runbooks: {[x: string]: any}
}

/** this is a type representing the stored scheduled on demand runbook inputs preference. */
export type ScheduledOnDemandRunbookInputsPreference = {
    /** a dictionary with the inputs mapped by runbook id. */
    runbooks: {[x: string]: any}
}

/** type for cloudim preferences*/
export type CloudIMPreference = {

    /** whether demo mode features such as mock data should show up */
    demoMode?: boolean;
}

/** this is a type representing the whats new preference. */
export type WhatsNewPreference = {
    /** a string with the whats new document version in the format "x.y". */
    version: string,
    /** an optional boolean value, if true don't show the what's new text again. */
    dontShowAgain?: boolean,
}

/** this is the type that defines the search preferences. */
export type SearchPreference = {
    /** the facet mode which specifies how the facets should be maintained. */
    facetMode?: FACET_MODE;
    /** a boolean value, true if the search should pull the pages on demand and use server side sorting. */
    serverSideSortingAndPagination?: boolean,
    /** the top limit to use when doing a search. */
    pageSize?: number;
    /** the max number of search results to download. */
    maxResultLimit?: number;
    /** determines whether or not the saved queries should be displayed. */
    showQueries?: boolean;
    /** a boolean value, true if the type control should be displayed. */
    showTypeControl?: boolean;
    /** a boolean value, if true save the last search query to the preferences and load it when the page loads. */
    saveLastSearchToPreferences?: boolean;
    /** a boolean value, if true, allow the user to make bulk changes on all incidents in the search result. */
    allowBulkChanges?: boolean;
    /** a boolean value, if true sort the facets moving selected facets above and then sorting them alphabetically.  If 
     *  false or not present then there will be no sorting. */
    sortFacets?: boolean;
    /** the SEARCH_ENGINE to use when searching, if not specified use cognitive search. */
    srchEngine?: SEARCH_ENGINE;
    /** an object with the last search that was performed. */
    lastSearch?: SearchQueryPreference | null,
    /** an array of  */
    savedQueries?: SearchQueryPreference[] | null
}

/** this enum defines the values for the facet mode. */
export enum FACET_MODE {
    /** the enumerated value that specifies the use initial facets mode.  This means, store the initial facets and retain them
     *  until either the search string or time changes.  In this case when facet filters are added, the facet list will not change. */
    initial = "initial",
    /** the enumerated value that specifies the use replace facets mode.  This means, do not store the initial facets and replace them
     *  every time anything changes. */
    replace = "replace"
}

/** this enum defines the values for the search engine. */
export enum SEARCH_ENGINE {
    /** the enumerated value that specifies the correlation search engine accessed directly via the API. */
    correlation_direct  = "correlation_direct",
    /** the enumerated value that specifies the correlation search engine accessed via DAL. */
    correlation_dal     = "correlation_dal"
}

/** this constant has the default search parameters. */
export const DEFAULT_SEARCH_PREF: SearchPreference = {
    facetMode: FACET_MODE.replace,
    serverSideSortingAndPagination: true,
    pageSize: 100,
    maxResultLimit: 1000,
    showQueries: true,
    showTypeControl: false,
    allowBulkChanges: false,
    sortFacets: false,
    srchEngine: SEARCH_ENGINE.correlation_dal,
    saveLastSearchToPreferences: false
}

/** this is the type that defines the search query preferences.  These may either be saved queries, or
 *  the most recent search. */
export type SearchQueryPreference = {
    /** the optional name of the search query. */
    name?: string,
    /** the optional SEARCH_TYPE.  For backwards compatibility, if this is not provided it is assumed to be incident. */
    type?: SEARCH_TYPE,
    /** a string with the search text. */
    searchText?: string,
    /** the search time range or duration. */
    searchTime?: any,
    /** the selected facets for the search query. */
    facets?: Record<string, Array<string | number>>,
    /** the server side sorting that should be used where id is the id of the column in the cognitive search
     *  index and the order is either "desc" or "asc". */
    sortColumn?: string,
    /** the server side sorting that should be used where id is the id of the column in the cognitive search
     *  index and the order is either "desc" or "asc". */
    sortOrder?: "desc" | "asc",
    /** the page size to use in the query. */
    pageSize?: number,
    /** the version of the query. */
    version?: string
}

/** this is the type that defines the subflow preferences. */
export type SubflowsPreference = {
    /** this is going to enable multiple outputs for a subflow, showing the index value */
    isMultipleOutputsEnabled?: boolean,
    /** a boolean value, if true it shows the integration subflows in a separate folder.  If missing or false it shows 
     *  them at the same level as other subflows in the add menu. */
    showAddMenuIntegrationFolder?: boolean,
    /** a boolean value, if true it shows the subflow versions as menu items of the subflow.  If missing or false it shows 
     *  only the most recent in the add menu. */
    showAddMenuVersions?: boolean,
}

/** this is the type that defines the explorer preferences. */
export type ExplorerPreference = {
    /** explorer type and columns. */
    [x: string]: string[]
}

/** this is the type that defines the explorer preferences. */
export type ExplorerFacetsPreference = {
    /** explorer type and columns. */
    [x: string]: string
}

/** this constant has the default subflows parameters. */
export const DEFAULT_SUBFLOWS_PREF: SubflowsPreference = {
    /** this is going to enable multiple outputs for a subflow, showing the index value */
    isMultipleOutputsEnabled: false
}

/** this is the type that defines the menu preferences. */
export type MenuPreference = {
    /** a boolean value, true if the user preferences should be shown in the menus. */
    showUserPreferences?: boolean,
    /** a boolean value, true if the dashboards should be shown in the menus. */
    showDashboards?: boolean,
    /** a boolean value, if true show NPM plus, if false or undefined do not show NPM plus in the menus. */
    showNpmPlus?: boolean,
}

/** This is a type representing what is stored on the backend API. */
export type UserPreferencesFromAPI = {
    Preferences: UserPreferences,
    response?: {
        status?: number
    }
}

/** this is the type that defines the incident page preferences. */
export type IncidentPagePreference = {
    /** a boolean value, show/hide timeline chart . */
    timelineChart?: boolean,
}

/** Method to validate if a final user preference object is a valid one.
 *  This is to be used before creating or updating a user preferences entry
 *  from user preferences service so that we can make sure it is a valid one. */
export function isValidUserPreference (arg: any): arg is UserPreferences {
    return Boolean(arg?.theme);
}
