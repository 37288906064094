import { runbookService } from "utils/runbooks/RunbookUtils";
import { Variant } from "components/common/graph/types/GraphTypes";
import { SUBFLOW_NODE_EDIT_PROPS } from "components/common/graph/editors/subflow-input/SubflowInputNodeUtils";
import { AuthServiceProvider } from "utils/providers/AuthServiceProvider";
import { IntegrationBadgeVariant } from "./types/IntegrationTypes";
import { LangEN, STRINGS } from "app-strings";
import React from "react";

const AuthService = AuthServiceProvider.getService();

/**
 * @param {Object} object
 * @param {string} key
 *
 * @returns {Object}
 */
export function getParameterCaseInsensitive(object: Object, key: string) {
	if (!object || !key) {
		return {};
	}

	const asLowercase = key.toLowerCase();

	return object[
		Object.keys(object).filter(function (k) {
			return k.toLowerCase() === asLowercase;
		})[0]
	];
}


/**
 * Get the formatted request body for the test query
 * 
 * @param {string} value the request body string
 * 
 * @returns {string}
 */
export function getRequestBodyFromString(value: string) {
	function isJsonString(str: string) {
		try {
			const json = JSON.parse(str);

			return (typeof json === 'object');
		} catch (e) {
			return false;
		}
	}

	const valueAsJSON = JSON.stringify(value);

	return value
		? isJsonString(valueAsJSON)
			? valueAsJSON
			: value
		: "";
}

/**
 * Get the runbooks that are using resources from this integration
 * 
 * @param integrationId 
 * 
 * @returns Array of references
 */
export async function getIntegrationReferences(integrationId: string | undefined) {
	if (!integrationId) {
		return []
	}

	try {
		const [
			incidentRunbooks,
			lifecycleRunbooks,
			onDemandRunbooks,
			subflows,
		] = await Promise.all([
			runbookService.getRunbooks(Variant.INCIDENT),
			runbookService.getRunbooks(Variant.LIFECYCLE),
			runbookService.getRunbooks(Variant.ON_DEMAND),
			runbookService.getRunbooks(Variant.SUBFLOW),
		]);

		const runbooks = [
			...incidentRunbooks,
			...lifecycleRunbooks,
			...onDemandRunbooks,
		];

		let usageResources: Array<any> = [];
		// Get the runbooks
		// For each runbook node, get the related subflow node info
		// If the related subflow node has an integrationIds
		// Return the resource
		runbooks.forEach((runbook) => {
			runbook.nodes.map((runbookNode) => {
				if (runbookNode.properties?.configurationId) {
					const existingSubflow = subflows.find(
						(el) =>
							el.id ===
							runbookNode.properties?.configurationId
					);

					if (!existingSubflow) {
						return runbookNode;
					}

					existingSubflow?.nodes?.map((node) => {
						if (
							node.properties.hasOwnProperty(
								SUBFLOW_NODE_EDIT_PROPS.INTEGRATION_IDS
							)
						) {
							const list =
								node.properties[
								SUBFLOW_NODE_EDIT_PROPS
									.INTEGRATION_IDS
								];

							const isUsedByIntegration =
								Object.values(list).includes(
									integrationId
								);

							if (isUsedByIntegration) {
								usageResources.push({
									id: runbook.id,
									variant: runbook.variant,
									description:
										runbook.description,
									integrationId: integrationId,
									lastUpdatedUser:
										runbook.lastUpdatedUser,
									lastUpdatedTime:
										runbook.lastUpdatedTime,
									name: runbook.name,
								});
							}
						}

						return node;
					});
				}

				return runbookNode;
			});
		});

		return usageResources;
	} catch (error) {
		console.error(error);

		return [];
	}
};

/** returns whether or not the user has the privileges to create/edit integrations.
 * 
 *  @returns a boolean value, true if the user has privileges to edit integrations, false otherwise. */
export function canChangeIntegrations(): boolean {
	return AuthService.userHasWriteAccess("gelato");
}

/**
 * Get badge for the integration card
 * 
 * @param {IntegrationBadgeVariant} variant - the badge variant
 * @param {string} integrationVersion - the integration version
 * 
 * @returns {JSX.Element}
 */
export const getIntegrationBadge = (variant: IntegrationBadgeVariant, integrationVersion?: string) => {
	const translations: LangEN['INTEGRATIONS_PAGE'] = STRINGS.INTEGRATIONS_PAGE;

	switch (variant) {
		case 'installed':
			return (
				<span className="badge p-2 badge-success">
					{translations.badges.installed}
				</span>
			)
		case 'available':
			return (
				<span className="badge p-2 badge-primary">
					{translations.badges.available}
				</span>
			)
		case 'beta':
			return (
				<span className="badge p-2 badge-danger">
					{translations.badges.beta}
				</span>
			)
		case 'upgradeAvailable':
			if (integrationVersion) {
				return (
					<span className="badge p-2 badge-warning">
						{STRINGS.formatString(translations.badges.upgradeAvailableToVersion, integrationVersion)}
					</span>
				)
			}

			return (
				<span className="badge p-2 badge-warning">
					{translations.badges.upgradeAvailable}
				</span>
			)

		default:
			return <></>
	}
};

