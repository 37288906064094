
import React from 'react';
import { Icon } from '@blueprintjs/core';
import { BULLET_COLORS } from 'components/enums';
import { SDWAN_ICONS } from 'components/sdwan/enums';
import { TimeFormatter } from 'reporting-infrastructure/utils/formatters/time-formatter/TimeFormatter';
import { parseTimeFromDAL } from 'utils/hooks';
import { Variant } from '../graph/types/GraphTypes';
import './DataNode.scss'

export interface DataNodeProps {
    // style class to apply on the outermost element
    className?: string;
    // title string
    title: string;
    // timestamp in string format that is displayed as-is. Formatting has to be done by the consumer
    timestamp: string;
    // description that is diplayed as a paragraph. Formatting has to be done by the consumer
    description?: JSX.Element | string;
    // show/hide description. Defaults to true
    showDescription?: boolean;
    // A flag to indicate that this is the first node in the list of nodes
    firstNode?: boolean;
    //node type
    nodeType?: Variant | undefined;
};
/**
 * A data formatter component which displayed the provided data as a node with a title timestamp and descriptions
 * @param props Refer to DataNodePorps for details on member attributes.
 * @return  JSX component
 */
export function DataNode({
    className,
    title,
    timestamp,
    description,
    showDescription = true,
    firstNode = false,
    nodeType = Variant.INCIDENT
}: DataNodeProps) {
    const parsedTime = parseTimeFromDAL(timestamp);
    return <>
        <div className={"data-node pl-4 d-flex flex-column" + (firstNode ? "" : " show-border") + (className ? " " + className : "")}>
            <div className="d-flex align-items-center title">
                {nodeType === Variant.LIFECYCLE
                    ? <Icon icon={SDWAN_ICONS.RUNBOOK} className="" iconSize={14} color={BULLET_COLORS.DEFAULT} />
                    : <Icon icon="symbol-circle" className="" iconSize={14} color={BULLET_COLORS.DEFAULT} />}
                <div className="pl-2 font-weight-bold title-text text-truncate" title={title}>
                    {title}
                </div>
                <span className="pl-2 time-stamp" aria-label='timestamp'>
                    <TimeFormatter time={parsedTime} />
                </span>
            </div>
            {showDescription &&
                <div className="ml-2 pl-3 pb-4 desc flow-wrap" >
                    <div className="mt-1">
                        {description}
                    </div>
                </div>
            }
        </div>
    </>
}