import React from 'react';
import { getRoutes } from 'config';
import { PageWithHeader } from 'components/sdwan/layout/page-with-header/PageWithHeader';
import { MappingConfigurationView } from './views/MappingConfigurationView';
import { HELP, STRINGS } from 'app-strings';

const MappingConfigurationPage = (): JSX.Element => {
	return (
		<PageWithHeader
			name={MappingConfigurationPage.name}
			addPadding={true}
			showTimeBar={false}
			showGlobalFilters={false}
			{...getRoutes()['mapping-configuration']}
			title={STRINGS.MAPPING_CONFIGURATION_PAGE.pageTitle}
			helpInfo={HELP.automationMap}
		>
			<MappingConfigurationView />
		</PageWithHeader>
	);
};

export { MappingConfigurationPage };
