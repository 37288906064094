import React, { useState, useCallback } from 'react';
import { Button, Dialog, Icon, Intent, Label } from '@blueprintjs/core';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
	ErrorToaster,
	IconNames,
	SuccessToaster,
} from '@tir-ui/react-components';
import { Form } from 'components/common/form';
import {
	RadioButtonField,
	RadioButtonGroup,
} from 'components/common/form/RadioButton';
import {
	hasAppResponse,
	hasNetProfiler,
} from 'utils/stores/GlobalDataSourceTypeStore';
import { PreferredDataSource } from '../views/DataSourceView';

import { STRINGS } from 'app-strings';

import './SetDataSourceSettingsModal.scss';

interface PreferredDataSourceMutationInput {
	input: {
		applicationLocationPreferredDataSourceType: string;
	};
}

const SetDataSourceSettingsModal = React.forwardRef((props: any, ref) => {
	React.useImperativeHandle(ref, () => ({
		setPreferredDataSource(source: string) {
			setPreferredDataSource(source);
		},
		handleOpen() {
			setIsOpen(!isOpen);
		},
	}));

	const [preferredDataSource, setPreferredDataSource] = useState<string>();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);

	const handleClose = useCallback(() => {
		setIsOpen(false);
	}, []);

	const [updateDataSourceSettings] = useMutation<
		any,
		PreferredDataSourceMutationInput
	>(
		loader(
			'src/pages/edge-configuration/views/datasource-settings-mutation.graphql'
		),
		{
			onCompleted: (data) => {
				handleClose();
				SuccessToaster({
					message:
						STRINGS.DATA_SOURCES.setDataSourceSettingsDialog
							.messages.settingsUpdated,
				});
			},
			onError: (err) => {
				ErrorToaster({
					message: err.message,
				});
				console.error(err?.message);
				return Promise.reject(err.message);
			},
			refetchQueries: ['dataSourceSettings'],
		}
	);

	/***
	 * onSubmit Handler function for the support formik form
	 * Resets the form after successful submission
	 * @param values
	 */
	const handleSubmit = (values) => {
		setSubmitting(true);
		const payload = {
			variables: {
				input: {
					applicationLocationPreferredDataSourceType:
						values['preferred_data_source'],
				},
			},
		};

		try {
			// @ts-ignore
			updateDataSourceSettings(payload).finally(() => {
				setSubmitting(false);
			});
		} catch (error) {
			setSubmitting(false);
			return Promise.reject('Error updating Data Source settings.');
		}
	};

	return (
		<React.Fragment>
			<Dialog
				title={STRINGS.DATA_SOURCES.setDataSourceSettingsDialog.title}
				isOpen={isOpen}
				autoFocus={true}
				canEscapeKeyClose={true}
				canOutsideClickClose={true}
				enforceFocus={true}
				usePortal={true}
				onClose={handleClose}
				style={{ width: '550px' }}
			>
				<div className="tir-data-source-settings">
					<Form
						initialValues={{
							preferred_data_source: preferredDataSource || '',
						}}
						loading={false}
						onSubmit={handleSubmit}
						onSuccessMessage={
							STRINGS.DATA_SOURCES.setDataSourceSettingsDialog
								.messages.help
						}
					>
						<React.Fragment>
							<Label>
								{
									STRINGS.DATA_SOURCES
										.setDataSourceSettingsDialog.messages
										.topHelp
								}
							</Label>
							<RadioButtonGroup
								name={'preferred_data_source'}
								inline={false}
								selectedValue={preferredDataSource}
								onChange={(e) => {
									setPreferredDataSource(
										e.currentTarget.value
									);
								}}
							>
								<RadioButtonField
									label={
										STRINGS.DATA_SOURCES
											.setDataSourceSettingsDialog.sources
											.appResponse
									}
									value={PreferredDataSource.APP_RESPONSE}
									disabled={!hasAppResponse()}
								/>
								<RadioButtonField
									label={
										STRINGS.DATA_SOURCES
											.setDataSourceSettingsDialog.sources
											.netProfiler
									}
									value={PreferredDataSource.NET_PROFILER}
									disabled={!hasNetProfiler()}
								/>
							</RadioButtonGroup>
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
								}}
							>
								<div style={{ display: 'inline-block' }}>
									<Button
										className="user-btn"
										onClick={() => {
											handleClose();
										}}
									>
										{
											STRINGS.DATA_SOURCES
												.setDataSourceSettingsDialog
												.buttons.cancelBtnText
										}
									</Button>
								</div>
								<div
									style={{
										display: 'inline-block',
										paddingLeft: '5px',
									}}
								>
									<Button
										type="submit"
										className="tir-ui-btn-submit"
										intent={Intent.PRIMARY}
										disabled={submitting}
									>
										{
											STRINGS.DATA_SOURCES
												.setDataSourceSettingsDialog
												.buttons.submitBtnText
										}
									</Button>
								</div>
							</div>
							<div className="mt-4">
								<div className="d-flex icon-and-label-div">
									<Icon
										icon={IconNames.WARNING_SIGN}
										iconSize={14}
										className="mr-2"
										intent={Intent.WARNING}
									/>
									<Label>
										{
											STRINGS.DATA_SOURCES
												.setDataSourceSettingsDialog
												.messages.bottomHelp
										}
									</Label>
								</div>
								<br />
								<Label>
									{
										STRINGS.DATA_SOURCES
											.setDataSourceSettingsDialog
											.messages.bottomHelp2
									}
								</Label>
							</div>
						</React.Fragment>
					</Form>
				</div>
			</Dialog>
		</React.Fragment>
	);
});

export { SetDataSourceSettingsModal };
