import React, { useEffect, useCallback, useState } from 'react';
import * as yup from 'yup';
import classNames from 'classnames';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { IconNames } from '@blueprintjs/icons';
import { useStateSafePromise } from 'utils/hooks';
import { Button, Classes, Dialog, IconName, Intent } from '@blueprintjs/core';
import {
	Icon,
	LoadingOverlay,
	SuccessToaster,
	ErrorToaster,
} from '@tir-ui/react-components';
import { Form, SelectField, TextAreaField } from 'components/common/form';
import { RunbookNode, RunbookService } from 'utils/services/RunbookApiService';
import { MappingConfig } from 'utils/services/MappingConfigApiService';
import { TRIGGER_TYPE_MAP, TRIGGER_TYPE_TO_VARIANT_MAP } from 'pages/riverbed-advisor/views/runbook-view/Runbook.type';

import { BasicDialog, DialogState, updateDialogState } from "components/common/basic-dialog/BasicDialog";

import { STRINGS } from 'app-strings';
import { Variant } from 'components/common/graph/types/GraphTypes';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { AuthServiceProvider } from 'utils/providers/AuthServiceProvider';
import { EventNames, trackEvent } from 'utils/appinsights';

interface EditMappingMutationInput {
	id: string;
	runbookId: string;
	description: string;
	enabled: boolean;
}

const EditMappingModal = React.forwardRef((props: any, ref) => {
	React.useImperativeHandle(ref, () => ({
		setMapping(mapping: MappingConfig) {
			if (mapping) {
				setMapping(mapping);
                setVariant(TRIGGER_TYPE_TO_VARIANT_MAP[mapping.triggerType]);
			}
		},
		handleOpen() {
			handleEditMappingOpen();
		},
	}));

	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [mapping, setMapping] = useState<MappingConfig>();
    const [variant, setVariant] = useState<string>(Variant.INCIDENT);
	const [runbooks, setRunbooks] = useState<RunbookNode[] | undefined>(
		undefined
	);

	const [executeSafely] = useStateSafePromise();
	const [loading, setLoading] = useState(false);

	const fetchRunbooks = useCallback(() => {
		return executeSafely(RunbookService.getRunbooks(variant)).then(
			(response: any) => {
				setRunbooks(response);
				setLoading(false);
			},
			(error) => {
				console.error(error);
				setLoading(false);
			}
		);
	}, [executeSafely, variant]);

	useEffect(() => {
		if (isOpen) {
			setLoading(true);
			fetchRunbooks();
		}
	}, [fetchRunbooks, isOpen]);

	const handleEditMappingOpen = () => {
		setIsOpen(true);
	};

	const handleEditMappingClose = () => {
		setMapping(undefined);
		setIsOpen(false);
	};

	const [setAutomationMapping] = useMutation<any, EditMappingMutationInput>(
		loader('./../automation-mutation.graphql'),
		{
			onCompleted: (data) => {
				handleEditMappingClose();
				SuccessToaster({
					message:
						STRINGS.MAPPING_CONFIGURATION_PAGE.messages
							.mappingUpdated,
				});
				return Promise.resolve(undefined);
			},
			onError: (err) => {
				ErrorToaster({
					message: err.message,
				});
				console.error(err?.message);
				return Promise.resolve(err.message);
			},
		}
	);

    const appInsightsContext = useAppInsightsContext();
    const AuthService = AuthServiceProvider.getService();

	const handleSubmit = (values, { setErrors, setLoading }) => {
		const payload = {
			variables: { mapping: {
					id: mapping ? mapping.id : '',
					name: '',
					runbookId: values.mapping_runbook ? (runbooks || []).filter((runbook) => {
						return runbook.name === values.mapping_runbook;
					})[0]['id'] : '',
					description: values.mapping_description,
					enabled: mapping && values.mapping_runbook ? mapping.enabled : false,
				}
			},
		}
		try {
			if(!values.mapping_runbook && mapping?.enabled) {
				emptyRunbookWarning(payload);
			} else {
				// @ts-ignore
				const mutationResponse = setAutomationMapping(payload);
				setLoading(false);
				setErrors(mutationResponse);
			}
		} catch (error) {
			return Promise.reject('Error updating mapping.');
		}
        if (appInsightsContext) {
            const properties = {
                name: EventNames.AUTOMATION_MAPPING_ACTION,
                properties: {
                    topic: STRINGS.MAPPING_CONFIGURATION_PAGE.actions.edit
                }
            };
            trackEvent(appInsightsContext, AuthService, properties);
        }
	};

	const initDialogState = {showDialog: false, title: '', loading: false, dialogContent: null, dialogFooter: null};
    const [dialogState, setDialogState] = useState<any>(initDialogState);

	const emptyRunbookWarning = (payload) => {		
		setDialogState({
			showDialog: true,
			title: STRINGS.MAPPING_CONFIGURATION_PAGE.enableMappingModal.title,
			dialogContent: STRINGS.MAPPING_CONFIGURATION_PAGE.messages.mappingResetWarning,
			closeable: false,
			dialogFooter: <div className="d-flex justify-content-between flex-grow-1">
				<Button
					text={STRINGS.DATA_SOURCES
						.addDataSourceDialog.buttons
						.cancelBtnText}
					intent={Intent.DANGER}
					onClick={() => {
						setDialogState({ ...dialogState, showDialog: false, closeable: true });	
						handleEditMappingClose();
					}}
				/>
				<Button
					icon={IconNames.WARNING_SIGN as IconName}
					text={STRINGS.MAPPING_CONFIGURATION_PAGE.messages.mappingResetWarningOK}
					intent={Intent.WARNING}
					onClick={() => {
						setAutomationMapping(payload)
						setDialogState({ ...dialogState, showDialog: false, closeable: true });
					}}
				/>
			</div>,
		} as DialogState);
    }

	const validationSchema = yup.object().shape({
		mapping_runbook: yup
			.string()
			.label(
				STRINGS.MAPPING_CONFIGURATION_PAGE.editMappingModal.labels
					.associatedRunbook
			),
		mapping_description: yup
			.string()
			.label(
				STRINGS.MAPPING_CONFIGURATION_PAGE.editMappingModal.labels
					.description
			)
	});

	if (loading) {
		return <LoadingOverlay visible={true} />;
	} else
		return (
			<React.Fragment>
            	<BasicDialog dialogState={dialogState} onClose={() => setDialogState(updateDialogState(dialogState, false, false, []))} />
				<Dialog
					className={''}
					icon={<Icon icon={IconNames.LIGHTNING} />}
					onClose={handleEditMappingClose}
					title={
						STRINGS.MAPPING_CONFIGURATION_PAGE.editMappingModal
							.title
					}
					isOpen={isOpen}
					autoFocus={true}
					canEscapeKeyClose={false}
					canOutsideClickClose={false}
					enforceFocus={true}
					usePortal={true}
				>
					<div className="tir-edit-mapping">
						<Form
							initialValues={{
								mapping_runbook: mapping?.runbookName ? mapping?.runbookName : '',
								mapping_description: mapping?.description ? mapping?.description : '',
							}}
							validationSchema={validationSchema}
							loading={false}
							onSubmit={handleSubmit}
							onSuccessMessage={
								STRINGS.MAPPING_CONFIGURATION_PAGE.messages
									.mappingUpdated
							}
						>
							<div
								className={
									classNames(Classes.DIALOG_BODY) + ' m-3'
								}
							>
								<SelectField
									label={
										STRINGS.MAPPING_CONFIGURATION_PAGE
											.editMappingModal.labels
											.associatedRunbook
									}
									className="w-100"
									name="mapping_runbook"
									options={
										runbooks &&
										['', ...Object.values(runbooks)
											.filter((runbook) => {
												return (
													runbook.isReady &&
													TRIGGER_TYPE_MAP[
														mapping?.triggerType
													] === runbook.triggerType
												);
											})
											.map((runbook) => {
												return runbook.name;
											})]
									}
								/>
								<TextAreaField
									data-testid="mapping_description"
									name="mapping_description"
									rows={5}
									className="w-100"
									label={
										STRINGS.MAPPING_CONFIGURATION_PAGE
											.editMappingModal.labels.description
									}
									disabled={false}
								/>
								<div
									style={{
										display: 'flex',
										justifyContent: 'flex-end',
									}}
								>
									<div style={{ display: 'inline-block' }}>
										<Button
											onClick={() => {
												handleEditMappingClose();
											}}
										>
											{
												STRINGS.DATA_SOURCES
													.addDataSourceDialog.buttons
													.cancelBtnText
											}
										</Button>
									</div>
									<div
										style={{
											display: 'inline-block',
											paddingLeft: '5px',
										}}
									>
										<Button
											type="submit"
											intent={Intent.PRIMARY}
										>
											{
												STRINGS.DATA_SOURCES
													.addDataSourceDialog.buttons
													.submitBtnText
											}
										</Button>
									</div>
								</div>
							</div>
						</Form>
					</div>
				</Dialog>
			</React.Fragment>
		);
});

export { EditMappingModal };
