import { AWSsignatureInterface, ApiKeyInterface, AuthenticationMethodTypeEnum, BasicAuthInterface, ClientCertificateUsingPemFormatInterface, OAuth2Interface } from "utils/services/ThirdPartyIntegrationApiService";
import { AuthenticationMethods } from "../modals/BasicAuthDetailsPanel";

export enum InstalledIntegrationStatus {
    New = 0,
    InstallationInProgress = 1,
    UpdateInstallationInProgress = 2,
    UninstallInProgress = 3,
    InstallationCompletedWithSuccess = 4,
    InstallationCompletedWithErrors = 5,
    UninstallCompletedWithErrors = 6,
    UpdateInstallationCompletedWithSuccess = 7,
    UpdateInstallationCompletedWithErrors = 8,
    UpgradeInstallationInProgress = 9,
    UpgradeInstallationCompletedWithSuccess = 10,
    UpgradeInstallationCompletedWithErrors = 11
}

/*
- avatar (128×128): for runbook nodes
- small (120×60): for integration description (once user start integration configuration)
- large (200×128): for card in integration library
*/
export type IntegrationAvatarVariants = "small" | "large" | "avatar"

export enum INTEGRATION_PROCESS_VARIANT {
    install = "install",
    uninstall = "uninstall",
    update = "update",
    upgrade = "upgrade"
}

export type IntegrationProcess = "install" | "uninstall" | "update" | "upgrade";
export type IntegrationIconsList = Array<{
    type: IntegrationAvatarVariants;
    svg: string;
}>;

export type AvailableIntegration = {
    id: string;
    version: string;
    name: string;
    description: {
        short: string;
        long: string;
    };
    branding: {
        primaryColor: string;
        secondaryColor: string;
        icons: IntegrationIconsList;
    };
    isBeta: boolean;
};

export type InstalledIntegration = {
    integrationId: string,
    installedVersion: string,
    name: string,
    description: {
        short: string,
        long: string
    },
    branding: {
        primaryColor: string,
        secondaryColor: string,
        icons: IntegrationIconsList,
    },
    links: Array<{ name: string; value: string }>;
    isBeta: boolean,
    installedBy: string,
    installedOn: string,
    status: InstalledIntegrationStatus,
    lastModifiedBy: string,
    lastModifiedOn: string,
};

export type IntegrationConnectorPropertyPayload = {
    name: string;
    value: string | boolean;
};

export interface InstalledIntegrationConnector {
    tenantId: string;
    connectorId: string;
    integrationId: string;
    name: string;
    isDeprecated: boolean;
    isEnabled: boolean;
}

export interface InstalledIntegrationConnectorDetails extends InstalledIntegrationConnector {
    authenticationProfileId: null | string; // The installed connector does not return the full auth profile info
    properties: Array<IntegrationConnectorPropertyPayload>;
}

export interface CreateIntegrationPayload {
    connectors: Array<IntegrationConnectorPayload>;
}

export interface AvailableIntegrationDetails extends AvailableIntegration {
    links: Array<{ name: string; value: string }>;
    connector: IntegrationConnectorConfig;
}

export interface InstalledIntegrationDetails extends InstalledIntegration {
    links: Array<{ name: string; value: string }>;
}

export interface IntegrationConnectorPayload {
    name: string;
    isEnabled: boolean;
    connectorId?: string;
    authenticationProfile?: {
        id?: string;
        name: string;
        description: string;
        isEnabled: boolean;
        authenticationMethod: AuthenticationMethodTypeEnum;
        sensitiveData?:
            | BasicAuthInterface
            | ApiKeyInterface
            | OAuth2Interface
            | ClientCertificateUsingPemFormatInterface
            | AWSsignatureInterface
            | null
            | undefined;
        isVerified?: boolean;
        verifyUrl?: string;
        verifyHttpMethod?: string;
        verifyRequestHeaders?: any;
        verifyRequestBody?: string;
        lastVerifiedOn?: number;
    };
    properties: Array<IntegrationConnectorPropertyPayload>;
}

export type IntegrationConnectorPropertyFieldType = 'string'| 'int' | 'integer' | 'float' | 'ipaddress' | 'bool' | 'boolean' | 'json' | 'timestamp';
export type IntegrationBadgeVariant = 'installed' | 'available' | 'upgradeAvailable' | 'beta';

export type IntegrationConnectorPropertyField = {
    name: string;
    label: string;
    type: IntegrationConnectorPropertyFieldType;
    description: string;
    isRequired: boolean;
    defaultValue?: number | string | boolean;
    value?: any;
};


export type IntegrationConnectorConfig = {
    minimumAmount: number;
    maximumAmount: number;
    authenticationProfile: {
        verifyProfile: {
            relativeUrl: string;
            httpMethod: string;
            headers: Array<{
                name: string;
                value: string;
            }>;
            body: any;
        };
        supportedAuthenticationMethods: {
            [key in keyof typeof AuthenticationMethods]?: any;
        };
    };
    properties: Array<IntegrationConnectorPropertyField>;
};

export type RunbookIntegrationDetails = {
    name: string;
    id: string;
    branding: {
        primaryColor: string;
        secondaryColor: string;
        icons: IntegrationIconsList;
    };
    connectors?: InstalledIntegrationConnector[]
}

export interface IntegrationErrorDetails{
    code: string,
    message: string,
    details?: Array<IntegrationErrorDetails>,
    innererror: {
        ErrorId: string
    }
}
