import React, { useState, useCallback, useEffect } from 'react';
import { Button, Dialog, Intent, Label } from '@blueprintjs/core';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Query } from 'reporting-infrastructure/types/Query';
import { useQuery } from 'utils/hooks';
import { ErrorToaster, SuccessToaster } from '@tir-ui/react-components';
import { Form, InputField } from 'components/common/form';

import { STRINGS } from 'app-strings';

import './SetDataSourceCredentialsModal.scss';

interface DataSourceCredentialsMutationInput {
	input: {
		id: string;
		credentials: {
			userName: string;
			password: string;
		};
	};
}

const SetDataSourceCredentialsModal = React.forwardRef((props: any, ref) => {
	React.useImperativeHandle(ref, () => ({
		setDataSourceId(dataSourceId) {
			setDataSourceId(dataSourceId);
		},
		setDataSourceName(dataSourceName) {
			setDataSourceName(dataSourceName);
		},
		setDataSourceIp(dataSourceIp) {
			setDataSourceIp(dataSourceIp);
		},
		handleOpen() {
			setIsOpen(!isOpen);
		},
	}));

	const [dataSourceId, setDataSourceId] = useState<string | undefined>();
	const [dataSourceName, setDataSourceName] = useState<string>();
	const [dataSourceIp, setDataSourceIp] = useState<string>();
	const [username, setUsername] = useState<string>();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const handleClose = useCallback(() => {
		setIsOpen(false);
	}, []);

	const dataSourceCredentialsQuery = useQuery({
		query: new Query(loader('./../queries/datasource-credentials.graphql')),
		name: 'dataSourceCredentials',
		lazy: true,
	});

	useEffect(() => {
		if (isOpen && !dataSourceCredentialsQuery.loading) {
			dataSourceCredentialsQuery.run({
				queryVariables: {
					input: { id: dataSourceId },
				},
				noCache: true,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	useEffect(() => {
		if (dataSourceCredentialsQuery.data) {
			if (
				dataSourceCredentialsQuery.data.hasOwnProperty(
					'dataSourceCredentials'
				)
			) {
				if (
					dataSourceCredentialsQuery.data.dataSourceCredentials.hasOwnProperty(
						'userName'
					)
				) {
					setUsername(
						dataSourceCredentialsQuery.data.dataSourceCredentials
							.userName
					);
				}
			}
		}
	}, [dataSourceCredentialsQuery.data]);

	useEffect(() => {
		setLoading(dataSourceCredentialsQuery.loading);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataSourceCredentialsQuery.loading]);

	const [setDataSourceCredentials] = useMutation<
		any,
		DataSourceCredentialsMutationInput
	>(
		loader(
			'src/pages/edge-configuration/queries/datasource-credentials-mutation.graphql'
		),
		{
			onCompleted: (data) => {
				handleClose();
				SuccessToaster({
					message:
						STRINGS.DATA_SOURCES.setDataSourceCredentialsDialog
							.messages.credentialsUpdated,
				});
			},
			onError: (err) => {
				ErrorToaster({
					message: err.message,
				});
				console.error(err?.message);
				return Promise.reject(err.message);
			},
		}
	);

	/***
	 * onSubmit Handler function for the support formik form
	 * Resets the form after successful submission
	 * @param values
	 */
	const handleSubmit = (values) => {
		setSubmitting(true);
		const payload = {
			variables: {
				input: {
					id: dataSourceId,
					credentials: {
						userName: values.username,
						password: values.password,
					},
				},
			},
		};

		try {
			// @ts-ignore
			setDataSourceCredentials(payload).finally(() => {
				setSubmitting(false);
			});
		} catch (error) {
			setSubmitting(false);
			return Promise.reject('Error updating Data Source Credentials.');
		}
	};

	return (
		<React.Fragment>
			<Dialog
				title={
					STRINGS.DATA_SOURCES.setDataSourceCredentialsDialog.title
				}
				isOpen={isOpen}
				autoFocus={true}
				canEscapeKeyClose={true}
				canOutsideClickClose={true}
				enforceFocus={true}
				usePortal={true}
				onClose={handleClose}
				style={{ width: '650px' }}
			>
				<div className="tir-datasource-credentials">
					<Form
						initialValues={{
							username: username || '',
							password: '',
						}}
						loading={loading}
						onSubmit={handleSubmit}
						onSuccessMessage={
							STRINGS.DATA_SOURCES.setDataSourceCredentialsDialog
								.messages.credentialsUpdated
						}
					>
						<React.Fragment>
							<Label>
								{
									STRINGS.DATA_SOURCES
										.setDataSourceCredentialsDialog.subTitle
								}
							</Label>
							<section className="datasource-credentials">
								<div className="row">
									<div className="col-md-3">
										{
											STRINGS.DATA_SOURCES
												.setDataSourceCredentialsDialog
												.fields.dataSource
										}
									</div>
									<div className="col-md-9 font-weight-bold">
										{dataSourceName +
											' (' +
											dataSourceIp +
											')'}
									</div>
								</div>

								<div className="row mt-4">
									<Label className="col-md-3 mb-0">
										{
											STRINGS.DATA_SOURCES
												.setDataSourceCredentialsDialog
												.fields.username
										}
									</Label>
									<div className="col-md-9">
										<InputField
											name={'username'}
											required={true}
										/>
									</div>
								</div>
								<div className="row">
									<Label className="col-md-3 mb-0">
										{
											STRINGS.DATA_SOURCES
												.setDataSourceCredentialsDialog
												.fields.password
										}
									</Label>
									<div className="col-md-9">
										<InputField
											type="password"
											name={'password'}
											required={true}
										/>
									</div>
								</div>
							</section>
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
								}}
							>
								<div style={{ display: 'inline-block' }}>
									<Button
										className="user-btn"
										onClick={() => {
											handleClose();
										}}
									>
										{
											STRINGS.DATA_SOURCES
												.setDataSourceCredentialsDialog
												.buttons.cancelBtnText
										}
									</Button>
								</div>
								<div
									style={{
										display: 'inline-block',
										paddingLeft: '5px',
									}}
								>
									<Button
										type="submit"
										className="tir-ui-btn-submit"
										intent={Intent.PRIMARY}
										disabled={submitting}
									>
										{
											STRINGS.DATA_SOURCES
												.setDataSourceCredentialsDialog
												.buttons.submitBtnText
										}
									</Button>
								</div>
							</div>
						</React.Fragment>
					</Form>
				</div>
			</Dialog>
		</React.Fragment>
	);
});

export { SetDataSourceCredentialsModal };
