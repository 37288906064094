/** This module contains the component for the incident search page.  The incident search page displays
 *      the incident search results and a filter sidebar for filtering the search results.
 *  @module
 */

import React, { useRef, useCallback, useState, useEffect, useMemo } from "react";
import { Button, Icon as BpIcon, AnchorButton, IconName, Position, Menu, MenuItem, Intent, Switch, ButtonGroup } from "@blueprintjs/core";
import { IconNames as BpIconNames } from "@blueprintjs/icons";
import { useCustomProperties } from "utils/hooks/useCustomProperties";
import { CustomProperty } from "pages/create-runbook/views/create-runbook/CustomPropertyTypes";
import { TwoColumnContainer } from "components/common/layout/containers/two-column-container/TwoColumnContainer";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { PageWithHeader } from "components/sdwan/layout/page-with-header/PageWithHeader";
import { GENERAL_COLORS, INCIDENT_STATUS_TO_LABEL_MAP, PRIORITY, SIZE } from "components/enums";
import { JsonViewer } from "pages/incident-details/views/primary-indicator/JsonViewer";
import { 
    Facet, FACET_FIELDS, FacetSummary, FacetValue, FIELDS, ORDERS, SearchItem, SearchRequest, SearchResult 
} from "utils/services/SearchApiService";
import { SearchCorrelationService } from "utils/services/SearchCorrelationApiService";
import {
    // AutocompleteColumnFilterControl,
    DateRangeFilterControl, ErrorToaster, Icon, IconNames, LoadingOverlay, MultiSelectFilterControl, SuccessToaster, Table, TableColumnDef, useStateSafePromise
} from "@tir-ui/react-components";
import FacetView from "./views/facet/FacetView";
import { StatusLED } from "components/common/status-led/StatusLED";
import { PriorityLEDFormatter } from "reporting-infrastructure/utils/formatters/priority-led-formatter/PriorityLEDFormatter";
import { ElapsedTimeFormatter } from "reporting-infrastructure/utils/formatters/elapsed-time-formatter/elapsed-time-formatter";
import { parseTimeFromDAL, setUserPreferences, TIME_RANGE, useUserPreferences } from "utils/hooks";
import { ListWithOverflow } from "components/common/list-with-overflow/ListWithOverflow";
import { Popover2, Popover2InteractionKind } from "@blueprintjs/popover2";
import { Link, useHistory } from "react-router-dom";
import { ALLOW_MULTI_TYPE, PARAM_NAME } from "components/enums/QueryParams";
import { useQueryParams } from "utils/hooks";
import { clearQueryParam, getURL, setQueryParam } from "utils/hooks/useQueryParams";
import { getURLPath } from "config";
import { isEqual, cloneDeep } from 'lodash';
import { durationToRoundedTimeRange, TIME_DURATION, DURATION } from "utils/stores/GlobalTimeStore";
import { FacetSettings } from "./views/facet/FacetSectionSettings";
import FilterView from "./views/filter/FilterView";
import IncidentTableActions from "./views/control/IncidentTableActions";
import { IncidentBriefBlade } from "../incident-list/views/incident-brief-blade/IncidentBriefBlade";
import { BasicDialog, updateDialogState } from "components/common/basic-dialog/BasicDialog";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { sortColumnWithTimeData } from "reporting-infrastructure/utils/commonUtils";
import { sortBasedOnPriority } from "reporting-infrastructure/utils/commonUtils";
import { sortBasedOnIncidentStatus } from "reporting-infrastructure/utils/commonUtils";
import SearchControl from "./SearchControl";
import { DEFAULT_SEARCH_PREF, ExplorerPreference, FACET_MODE, SEARCH_ENGINE, SearchPreference, SearchQueryPreference, UserPreferences } from "utils/services/UserPrefsTypes";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { EventNames, trackEvent } from "utils/appinsights";
import { AuthServiceProvider } from "utils/providers/AuthServiceProvider";
import PaginationControl from "./PaginationControl";
import { WrapInTooltip } from "components/common/wrap-in-tooltip/WrapInTooltip";
import { RUNBOOK_STATUS_PROPS } from "pages/riverbed-advisor/views/runbook-view/Runbook.type";
import { useApolloClient, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { openConfirm, openModal } from "components/common/modal";
import { SearchGraphqlApiService } from "utils/services/search/SearchGraphqlApiService";
import CSVParserService from "utils/services/CSVParserService";
import { 
    getApplicationSearchNameIdForEngine, getCreatedAtIdForEngine, getCustomPropertySearchDescriptionIdForEngine, 
    getCustomPropertySearchLastUpdatedAtIdForEngine, getCustomPropertySearchNameIdForEngine, getDescriptionIdForEngine, 
    getDeviceSearchIpAddressIdForEngine, getDeviceSearchLocationIdForEngine, getDeviceSearchNameIdForEngine, 
    getInitialSearchParamsFacets, 
    getInterfaceSearchIfIndexIdForEngine, getInterfaceSearchIpAddressIdForEngine, getInterfaceSearchLocationIdForEngine, 
    getInterfaceSearchNameIdForEngine, getLocationSearchCityIdForEngine, getLocationSearchCountryIdForEngine, 
    getLocationSearchNameIdForEngine, getLocationSearchStateIdForEngine, getLocationSearchTypeIdForEngine, 
    getPriorityIdForEngine, getStatusIdForEngine 
} from "./IncidentSearchUtils";
import { runbookService } from "utils/runbooks/RunbookUtils";
import { Variant } from "components/common/graph/types/GraphTypes";
import { formatUnixTimestamp } from "components/common/daterange-tag/DateUtils";
import { Version } from "utils/Version.class";
import { HELP, STRINGS } from "app-strings";
import { Unit } from "reporting-infrastructure/types/Unit.class";
import { runRunbookOnDemand } from "pages/runbook-invocations/views/runbook-invocations-list/RunbookInvocationsUtils";
import { ScheduleRunbookModal } from "./modals/ScheduleRunbookModal/ScheduleRunbookModal";
import { useQuery, FILTER_NAME } from "utils/hooks";
import { Query } from "reporting-infrastructure/types/Query";
import { v4 as uuidv4 } from "uuid";
import { getDataSourceTypeSources } from "utils/stores/GlobalDataSourceTypeStore";
import { ProfileInterface, ThirdPartyIntegrationService } from "utils/services/ThirdPartyIntegrationApiService";
import { PrimitiveVariableType } from "utils/runbooks/VariablesUtils";
import { client } from "utils/services/GraphqlService";
import "./IncidentSearchPage.scss";

/** this constant refers to the auth service where you can get the tenant, user, etc. */
const AuthService = AuthServiceProvider.getService();

/** this enum defines the valid search types */
export enum SEARCH_TYPE {
    /** the enumerated value for the incident search type. */
    incident = "incident",
    /** the enumerated value for the device search type. */
    device = "device",
    /** the enumerated value for the interface search type. */
    interface = "interface",
    /** the enumerated value for the application search type. */
    application = "application",
    /** the enumerated value for the location search type. */
    location = "location",
    /** the enumerated value for the custom properties search type. */
    properties = "properties",
    /** the enumerated value for the tcp connections search type. */
    tcpconnection = "tcpconnection",
    /** the enumerated value for the on-demand runbooks search type */
    ondemandrunbooks = "ondemandrunbooks",
    /** the enumerated value for the runbooks schedules search type */
    runbookschedules = "runbookschedules"
}

const isLimitedSearchType = (value: string): value is SEARCH_TYPE => {
    const validTypes = [
        SEARCH_TYPE.application,
        SEARCH_TYPE.device,
        SEARCH_TYPE.interface,
        SEARCH_TYPE.location
    ];
    return validTypes.includes(value as SEARCH_TYPE);
}

type CustomPropertyGroupKey = "CUSTOM_PROPERTY_VALUE_NAME";

type GroupByTypes = "CUSTOM_PROPERTY_NAME";

type FacetVariables = any;

/** this interface defintes the parameters that are used to generate a search request. */
interface SearchParams {
    /** a boolean value, true if we are running an initial query to get the list of facets that will be maintained across sub-queries,
     *  false otherwise. */
    runInitialSearchRequest: boolean;
    /** the search type specified by one of the enumerated SEARCH_TYPEs. */
    searchType: SEARCH_TYPE;
    /** a String with the search text. */
    searchText: string;
    /** the specified time range or undefined if there is no time range. */
    timeRange: Partial<TIME_RANGE & TIME_DURATION> | undefined;
    /** the selected facets. */
    selectedFacets: Record<string, Array<FacetValue>>;
    /** the sort column. */
    sortColumn?: FIELDS;
    /** the sort order. */
    sortOrder?: ORDERS;
    /** the optional page size. */
    pageSize?: number;
    /** the information for a paginated search request if there are additional pages. */
    paginatedSearchRequest: SearchRequest | undefined;
}

/** Dictionary object keys to retrieve for help text. Refer to repo
 * `hyperion-user-assistance` to see objects in detail */
type EXPLORER_HELP_TYPE =
    | "incidentSearch"
    | "explorerPageApplication"
    | "explorerPageDevice"
    | "explorerPageInterface"
    | "explorerPageLocation"
    | "explorerPageCustom"
    | "runbookScheduling"
    | "runbookAnalyses";

/** Used to retrieve help text and links
 *  @param {SEARCH_TYPE} searchType - search type to map
 *  @returns {EXPLORER_HELP_TYPE} string - corresponding to `EXPLORER_HELP_TYPE` value. */
export function getHelpType(
    searchType: SEARCH_TYPE,
): EXPLORER_HELP_TYPE {
    switch (searchType) {
        case "application":
            return "explorerPageApplication";
        case "device":
            return "explorerPageDevice";
        case "interface":
            return "explorerPageInterface";
        case "location":
            return "explorerPageLocation";
        case "properties":
            return "explorerPageCustom";
        case "ondemandrunbooks":
            return "runbookAnalyses";
        case "runbookschedules":
            return "runbookScheduling";
        // NOTE: if undefined defaults to `incidentSearch`
        default:
            return "incidentSearch";
    }
}

/** this interface defines the search stats for a search request. */
interface SearchStats {
    /** a number with the value with the number of results at which the pagination stopped.  */
    truncatedAt: number;
    /** the total number of results for the current search. */
    totalCount: number;
    /** the sub count when not in the replace facet mode. */
    subCount: number;
}

interface DeleteCustomPropertyMutationInput {
    customProperty: {
        id: string
    }
}

/** this constant specifies the default facet limit. */
const DEFAULT_FACET_LIMIT: number = 10;
/** this constant specifies whether to show the table filters. */
const SHOW_TABLE_FILTERS: boolean = false;

/** a string with the empty display value. */
const EMPTY_DISPLAY_VALUE: string = "";

export type DataSourceType = {
    dataSourceId: string;
    dataSourceType: string;
    entityId: string | null;
};

/**
 * Combines data from DAL and the `GlobalDataSourceTypeStore.ts`
 * Used to display the data source type names in table cells.
 * @param {Object} entityMappings - datum taken from `GlobalDataSourceTypeStore.ts`
 * the comparison array
 * @param {Array} dataSources - str[]
 * @returns {Array} if there are no matched IDs will only return a `str[]` with
 * item `Deleted Data Source`
 */
export const dataSourceTypeNameZipper = (
    entityMappings: Record<string, string>,
    dataSources: DataSourceType[],
): { dataSourceTypes: string[], dataSourceNames: string[] } => {
    const dataSourceTypes = new Set<string>();
    const dataSourceNames = new Set<string>();

    dataSources.forEach(dataSource => {
        const name = entityMappings[dataSource.dataSourceId];
        if (name) {
            dataSourceTypes.add(STRINGS.incidentSearch.tableCells[dataSource.dataSourceType]);
            dataSourceNames.add(name);
        }
    });

    if (dataSourceNames.size === 0) {
        dataSourceNames.add("Deleted Data Source");
    }

    if (dataSourceTypes.size === 0) {
        dataSourceTypes.add("Deleted Data Source");
    }

    return {
        dataSourceTypes: Array.from(dataSourceTypes),
        dataSourceNames: Array.from(dataSourceNames)
    };
};

type RelativeTimeCellProps = {
    /** string literal representing unix epoch time */
    time: string;
};

/** Used in the table cells in the `ondemandRunbooks` column definitions
 * Properly formats time in `long` and `from` formats
 * @param {string} time - string literal representing unix time
 */
export const RelativeTimeCell = ({
    time,
}: RelativeTimeCellProps) => {
    /** intitial timestamp */
    const when = formatUnixTimestamp(time).from();
    /** elapsed time since intitial timestamp */
    const from = formatUnixTimestamp(time).long();
    return (
        <p className="RelativeTimeCell">
            {when ? (
                <span
                className="RelativeTimeCell-start"
                data-testid="RelativeTimeCell-start"
                >
                {when}
                </span>
            ) : null}
            {from ? (
                <span
                    className="RelativeTimeCell-end"
                    data-testid="RelativeTimeCell-end"
                >
                    {from}
                </span>
            ) : null}
        </p>
    );
};

const RunbookStatus = [
    "IN_PROGRESS",
    "SUCCEEDED",
    "SUCCEEDED_WITH_ERRORS",
    "FAILED",
    "CANCELED",
] as const;

type RunbookStatusEnums = (typeof RunbookStatus)[number];

type TableCellRunbookStatusIconProps = {
    /** enum expression on current state of runbook, maps to DAL schema
     * definition */
    runbookStatus: RunbookStatusEnums;
    /* an object containing the row runbook ouput properties and values */
    rowInfos: any
    /* a function used to refresh the search results list */
    refreshSearch: Function | undefined
};

/** Used in the `SEARCH_TYPE.incident` column defs to display appropriate icon
 * @param {string} runBookStatus - possible string values taken from
 * `IncidentRunbookStatusEnums`
 * @return {JSX.Element} icon indicators
 * */
export const TableCellRunbookStatusIcon = ({
    runbookStatus,
    rowInfos,
    refreshSearch
}: TableCellRunbookStatusIconProps) => {
    const [runbookUpdatedStatus, setRunbookUpdatedStatus] = useState(runbookStatus);

    let { run } = useQuery({
        query: new Query(loader("../../pages/riverbed-advisor/views/runbook-view/runbooks.graphql")),
        requiredFilters: [FILTER_NAME.runbookId],
        filters: {
            [FILTER_NAME.runbookId]: rowInfos.id
		},
        skipGlobalFilters: true,
        timeNotRequired: true,
        lazy: true,
    });

    const runBookStatus = () => {
        return RunbookStatus.indexOf(runbookUpdatedStatus as RunbookStatusEnums) !== -1;
    }

    useEffect(() => {
        setRunbookUpdatedStatus(runbookStatus);
    }, [runbookStatus]);

    return (
        <>
            {runBookStatus() ? (
                <WrapInTooltip
                    tooltip={RUNBOOK_STATUS_PROPS[runbookUpdatedStatus].label}
                >
                    <Icon
                        icon={RUNBOOK_STATUS_PROPS[runbookUpdatedStatus].icon}
                        className={
                            RUNBOOK_STATUS_PROPS[runbookUpdatedStatus].iconClass
                        }
                    />
                    {runbookUpdatedStatus === RunbookStatus[0] && <Button small={true} text={STRINGS.runbookInvocations.refreshStatus} intent="primary" className="on-demand-runbook-status-refresh ml-2" onClick={(e) => {
                        e.stopPropagation();
                        const button = e.target.closest("button");
                        button.disabled = true;
                        const buttonSpan = button.querySelector("span");
                        buttonSpan.textContent = STRINGS.runbookInvocations.refreshingStatus;

                        run({
                            filters: {
                                [FILTER_NAME.runbookId]: rowInfos.id
                            },
                            noCache: true,
                        }).then(data => {
                            if (data?.runbooks?.nodes?.[0]?.status) {
                                setRunbookUpdatedStatus(data.runbooks.nodes[0].status);
                                if (data.runbooks.nodes[0].status !== RunbookStatus[0] && refreshSearch) {
                                    refreshSearch();
                                }
                            }
                            button.disabled = false;
                            buttonSpan.textContent = STRINGS.runbookInvocations.refreshStatus;
                        }).catch(error => {
                            button.disabled = false;
                            buttonSpan.textContent = STRINGS.runbookInvocations.refreshStatus;
                            console.error("Error fetching the runbook status:", error);
                        });
                        
                    }} />}
                </WrapInTooltip>
            ) : null}
        </>
    );
};

type IncidentRunbookStatusEnums =
    | "IN_PROGRESS"
    | "SUCCEEDED"
    | "SUCCEEDED_WITH_ERRORS"
    | "FAILED"
    | "CANCELED"
    | undefined;

type IncidentRunbookStatusProps = {
    /** enum expression on current state of runbook, maps to DAL schema
     * definition */
    runbookStatus: IncidentRunbookStatusEnums;
};

/** Used in the `SEARCH_TYPE.incident` column defs to display appropriate icon
 * @param {string} runBookStatus - possible string values taken from
 * `IncidentRunbookStatusEnums`
 * @return {JSX.Element} icon indicators
 * */
export const IncidentRunbookStatusIcon = ({
    runbookStatus,
}: IncidentRunbookStatusProps) => {
    return (
        <>
            {runbookStatus ? (
                <WrapInTooltip
                    tooltip={RUNBOOK_STATUS_PROPS[runbookStatus].label}
                >
                    <Icon
                        icon={RUNBOOK_STATUS_PROPS[runbookStatus].icon}
                        className={
                            RUNBOOK_STATUS_PROPS[runbookStatus].iconClass
                        }
                    />
                </WrapInTooltip>
            ) : null}
        </>
    );
};

/** Renders the incident search page.
 *  @param props the properties passed in.
 *  @returns JSX with the incident search page component.*/
const IncidentSearchPage = (props): JSX.Element => {
    const userHasWritePermissions = AuthService.userHasWriteAccess('gelato');
    const history = useHistory();
    const { params, setQueryParams } = useQueryParams({ 
        listenOnlyTo: [
            PARAM_NAME.searchText, PARAM_NAME.facets, PARAM_NAME.groupedFacets, PARAM_NAME.searchType, PARAM_NAME.searchTime,
            PARAM_NAME.sortColumn, PARAM_NAME.sortOrder, PARAM_NAME.pageSize, PARAM_NAME.debug,
            PARAM_NAME.tableColumns, PARAM_NAME.incidentId, PARAM_NAME.createNewCP, PARAM_NAME.customPropertyId,
            PARAM_NAME.runbookId, PARAM_NAME.inspectorBladeOpen, PARAM_NAME.fromExplorerEntity
        ] 
    });
    const appInsightsContext = useAppInsightsContext();

    const userPreferences = useUserPreferences({listenOnlyTo: {search: {savedQueries: []}, explorer: {}}});
    const searchPreferences: SearchPreference = {...DEFAULT_SEARCH_PREF, ...userPreferences.search};
    const columnPreferences: ExplorerPreference = {...userPreferences.explorer};

    const showDebugInformation = params[PARAM_NAME.debug] === "true";

    /** this is the maximum number of search results to load through pagination. */
    const MAX_LOADED_SEARCH_RESULTS = searchPreferences.maxResultLimit || 1000;

    const initSearchParams: SearchParams = {
        runInitialSearchRequest: false, searchType: SEARCH_TYPE.incident, searchText: "", timeRange: undefined, selectedFacets: {}, 
        sortColumn: undefined, sortOrder: undefined, pageSize: undefined, paginatedSearchRequest: undefined
    };
    const [searchParams, setSearchParamsState] = useState<SearchParams>(initSearchParams);
    const searchParamsRef = useRef<SearchParams>(initSearchParams);
    function setSearchParams(sp: SearchParams) {
        searchParamsRef.current = sp;
        setSearchParamsState(searchParamsRef.current);
    }

    const searchText = params && params[PARAM_NAME.searchText] ? params[PARAM_NAME.searchText] : "";
    const [showSetCPButton, setShowSetCPButton] = useState(true);

    const timeRange: Partial<TIME_RANGE & TIME_DURATION> | undefined = params && params[PARAM_NAME.searchTime]
        ? JSON.parse(params[PARAM_NAME.searchTime]) as Partial<TIME_RANGE & TIME_DURATION>
        : undefined;

    // This state variable specifies which search type the page should display.
    const searchType: SEARCH_TYPE = params && params[PARAM_NAME.searchType]
        ? params[PARAM_NAME.searchType] as SEARCH_TYPE
        : SEARCH_TYPE.incident;

    // When the selected facets change, update the facet cache and run the cognitive search
    const selectedFacets = useRef<Record<string, Array<FacetValue>>>({});
    const newSelectedFacets = searchType === SEARCH_TYPE.incident
        ? getSelectedFacetsFromQueryParams(params[PARAM_NAME.facets])
        : getSelectedFacetsFromQueryParams(
              params[PARAM_NAME.facets],
              params[PARAM_NAME.groupedFacets],
          );
    if (!isEqual(selectedFacets.current, newSelectedFacets)) {
        selectedFacets.current = cloneDeep(newSelectedFacets);
    }

    const sortBy: {id: string, desc: boolean} = params && params[PARAM_NAME.sortColumn] && params[PARAM_NAME.sortOrder] 
        ? { id: params[PARAM_NAME.sortColumn], desc: params[PARAM_NAME.sortOrder] !== "asc" } 
        : getInitialSortBy(searchType, searchPreferences.srchEngine || SEARCH_ENGINE.correlation_direct);

    const pageSize: number | undefined = params && params[PARAM_NAME.pageSize] ? parseInt(params[PARAM_NAME.pageSize]) : undefined;

    const initDialogState = {showDialog: false, title: STRINGS.incidentSearch.debugDialogTitle, loading: false, dialogContent: <></>, dialogFooter: <></>};
    const [dialogState, setDialogState] = useState<any>(initDialogState);
    const [onDemandRunbookDialogState, setOnDemandRunbookDialogState] = useState<any>({ showDialog: false, title: "Run runbook on-demand", loading: false, dialogContent: null, dialogFooter: null });

    const [isRunningCustomPropUseCheck, setIsRunningCustomPropCheck] = useState(false);

    const pageIndex = useRef<number>(0);
    const searchResultsByPage = useRef<any[]>([]);
    const [searchResults, setSearchResultsState] = useState<any>({});
    const searchResultsCache = useRef<any>({});
    const facetCache = useRef<Record<string, Array<Facet>>>({});
    const [limitsByFacetCategory, setLimitsByFacetCategory] = useState<Record<string, number>>({});
    const [executeSafely] = useStateSafePromise();

    const [authProfiles, setAuthProfiles] = useState<ProfileInterface[]>();
    const fetchProfiles = useCallback(() => {
        return executeSafely(ThirdPartyIntegrationService.getAuthProfiles()).then(
            (response: ProfileInterface[]) => {
                const authProfiles = response.filter(profile => profile.isEnabled).sort((a, b) => a.name.localeCompare(b.name));
                setAuthProfiles(authProfiles);
            },
            _ => {
                setAuthProfiles([]);
            }
        );
    }, [executeSafely]);

    useEffect(() => {
        if (searchType === SEARCH_TYPE.runbookschedules) {
            // Fetch profiles on load.
            fetchProfiles();
        }
    }, [searchType, fetchProfiles]);

    const edgeConfigQuery = useQuery({
        name: 'EdgeConfig',
        query: new Query(loader('../../components/common/graph/editors/subflow/edge-config-list.graphql')),
        queryVariables: {
            ...(durationToRoundedTimeRange(DURATION.HOUR_1) as any),
        },
        lazy: true
    });

    useEffect(() => {
        if (searchType === SEARCH_TYPE.runbookschedules) {
            edgeConfigQuery.run();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [hasOnDemandRunbookConfigs, setHasOnDemandRunbookConfigs] = useState(true);

    useEffect(() => {
        async function checkIfDefinedOnDemandRunbookConfigs() {
            const items = await runbookService.getRunbooks(Variant.ON_DEMAND);
            if (!items?.length) {
                setHasOnDemandRunbookConfigs(false);
            }
        };
        checkIfDefinedOnDemandRunbookConfigs();
    }, []);

    const searchStats = useRef<SearchStats>({ truncatedAt: Number.MAX_SAFE_INTEGER, totalCount: 0, subCount: 0 });
    const loading = useRef<boolean>(true);

    // This key is used to get the values out of the search result
    const valueKey: string = "items";

    const tableData = useMemo(() => {
        if (!searchPreferences.serverSideSortingAndPagination) {
            return searchResults?.items || [];
        } else {
            return searchResultsByPage.current?.length > pageIndex.current ? searchResultsByPage.current[pageIndex.current].items : [];
        }
    }, [searchResults, searchPreferences.serverSideSortingAndPagination]);

    function setSearchResults(searchResults: any): void {
        setSearchResultsState(searchResults);
        searchResultsCache.current = searchResults;
    }

    const apolloClient = useApolloClient();
    const searchGraphqlService = useRef<SearchGraphqlApiService>(new SearchGraphqlApiService(apolloClient));

    // This is the last search request to be run
    const searchRequest = useRef<SearchRequest | undefined>();

    // This is the search request that the facets should use to run their search queries.
    // We remove the other facets from the filter of this search request.  The reason why
    // we do this is because we always show all the facets that existed after we run a
    // search request for the searchText and time range.  As more facets are selected the
    // other facets disappear, but we keep them and set their counts to 0 so the user
    // still sees all the facets that can bring back data.
    const facetSearchRequest = useRef<SearchRequest | undefined>();

    // This keeps track of the current search request, if you are processing a result and this number has changed
    // there is already a new search request that has been sent and we can disregard the results from the previous
    // search.  Right now we are only using this to determine whether to continue with pagination, but we can use
    // this for more in the future
    const searchSequence = useRef<number>(0);

    const bladeOpenedOnLoad = useRef<boolean>(false);

    const customPropertiesQuery = useCustomProperties({});
    const customProperties = useMemo(() => {
        return customPropertiesQuery.data || [];
    }, [customPropertiesQuery.data]);

    const runCognitiveSearch = useCallback(
        (searchParams: SearchParams) => {
            const searchStartTime = Date.now();
            const sequenceNumber = ++searchSequence.current;
            loading.current = true;
            searchRequest.current = searchParams.paginatedSearchRequest ? searchParams.paginatedSearchRequest : createSearchRequest(
                searchParams.searchText, limitsByFacetCategory, searchParams.timeRange, !searchParams.runInitialSearchRequest ? searchParams.selectedFacets: {}, searchParams.searchType, 
                searchParams.pageSize, userPreferences?.search || undefined, searchParams.sortColumn, searchParams.sortOrder
            );
            facetSearchRequest.current = createSearchRequest(
                searchParams.searchText, limitsByFacetCategory, searchParams.timeRange, {}, searchParams.searchType, 
                searchParams.pageSize, userPreferences?.search || undefined, searchParams.sortColumn, searchParams.sortOrder
            );

            let searchService: {search: (searchRequest: SearchRequest) => Promise<SearchResult<SearchItem>>} | null = null;
            switch (searchPreferences.srchEngine) {
                case SEARCH_ENGINE.correlation_direct:
                    searchService = SearchCorrelationService;
                    break;
                case SEARCH_ENGINE.correlation_dal:
                    searchService = searchGraphqlService.current;
                    break;
            }
            return executeSafely(searchService!.search(searchRequest.current)).then((searchResult: SearchResult<SearchItem>) => {
                loading.current = false;
                if (searchResult) {
                    if (sequenceNumber !== searchSequence.current) {
                        // We might have launched a request and the search request might have changed while this was running
                        return;
                    }

                    if (Object.keys(facetCache.current).length === 0 || searchPreferences.facetMode === FACET_MODE.replace) {
                        // We have no previous search results
                        searchStats.current.totalCount = (searchResult.count) || 0;
                        if (searchPreferences.serverSideSortingAndPagination && !searchParams.paginatedSearchRequest) {
                            searchResultsByPage.current = Array(Math.ceil(searchStats.current.totalCount / (searchParams.pageSize || 1000)));
                        }
                    }

                    // Update the facet cache with the current set of facets adding any new facets and updating existings counts.
                    const facetResults = searchResult.facets || {};
                    for (const category in facetResults) {
                        if (!facetCache.current[category]) {
                            facetCache.current[category] = [];
                        }
                        const facets: Facet[] = (facetResults[category] as FacetSummary).items || [];
                        for (const facet of facets) {
                            const cachedFacet = getFacet(category, facet.value, facetCache.current);
                            if (cachedFacet) {
                                cachedFacet.count = facet.count;
                            } else {
                                facetCache.current[category].push({ ...facet });
                            }
                        }
                    }

                        // ensure that 'isGroupedFacet' is appended to dynamic
                        // facets
                        mergeGroupedFacets(facetCache, facetResults);

                        // Set every count to zero in the cache if it is no longer returned by search
                        for (const category in facetCache.current) {
                        for (const facet of facetCache.current[category]) {
                            let checkFacet: Facet | undefined = getFacet(category, facet.value, facetResults, true);
                            if (!checkFacet) {
                                facet.count = 0;
                            }
                        }
                    }

                    // Calculate the subcount
                    if (Object.keys(facetCache.current).length !== 0) {
                        // We have no previous search results
                        searchStats.current.subCount = searchResult.count || 0;
                        if (searchPreferences.serverSideSortingAndPagination && !searchParams.paginatedSearchRequest) {
                            searchResultsByPage.current = Array(Math.ceil(searchStats.current.subCount / (searchParams.pageSize || 1000)));
                        }
                    }

                    // Update facet selections
                    for (const category in facetCache.current) {
                        for (const facet of facetCache.current[category]) {
                            const shouldSelect = Boolean(selectedFacets.current[category]?.includes(facet.value));
                            if (shouldSelect !== Boolean(facet.selected)) {
                                facet.selected = shouldSelect;
                            }
                        }
                    }

                    // Take any facets that are currently selected and make sure they are in the cache so they are 
                    // displayed
                    for (const facetCategory in selectedFacets.current) {
                        if (!facetCache.current[facetCategory]) {
                            facetCache.current[facetCategory] = [];
                        }
                        const selectedValues = selectedFacets.current[facetCategory];
                        for (const value of selectedValues) {
                            const cachedFacet = getFacet(facetCategory, value, facetCache.current);
                            if (!cachedFacet) {
                                facetCache.current[facetCategory].push({ value, selected: true, count: 0 });
                            }
                        }
                    }

                    // The PMs would like some of the categories with known values always to appear.  Use the 
                    // Strings file to figure this out
                    for (const facetCategory in STRINGS.incidentSearch.facetView.facets) {
                        const facetInfo = STRINGS.incidentSearch.facetView.facets[facetCategory];
                        if (
                            facetInfo.searchSystem &&
                            (facetInfo.searchSystem !== searchPreferences.srchEngine)
                        ) {
                            continue;
                        }
                        if (facetInfo.type === searchParams.searchType && facetInfo.values) {
                            if (!facetCache.current[facetCategory]) {
                                facetCache.current[facetCategory] = [];
                            }
                            const selectedValues = facetInfo.values;
                            for (const value of selectedValues) {
                                const cachedFacet = getFacet(facetCategory, value, facetCache.current);
                                if (!cachedFacet) {
                                    facetCache.current[facetCategory].push({ value, selected: false, count: 0 });
                                }
                            }
                        }
                    }

                    (searchResult as any).type = searchParams.searchType;

                    if (searchParams.paginatedSearchRequest) {
                        // We have paginated results
                        if (!searchPreferences.serverSideSortingAndPagination) {
                            searchResult = {...searchResult, [valueKey]: [...searchResultsCache.current[valueKey], ...(searchResult[valueKey] || [])]};
                        }
                        searchParams.paginatedSearchRequest = undefined;
                        setSearchParams(searchParams);
                    }

                    if (searchPreferences.serverSideSortingAndPagination) {
                        searchResultsByPage.current[pageIndex.current] = searchResult;
                    }

                    const resultCount: number = searchResult.count || 0;
                    searchStats.current.truncatedAt = resultCount > searchResult[valueKey]?.length ? searchResult[valueKey]?.length || 0 : Number.MAX_SAFE_INTEGER;

                    setSearchResults(searchResult);

                    if (searchParams.runInitialSearchRequest) {
                        // The selected facets indicate that this is a request from a URL change and we just did the 
                        // first query to get the search set and now we need to query the selected facets.  We need 
                        // to add any facets that might have been in the URL but were not found by the first query, so
                        // there is a URL with a list of selected facets, but those facets are not in the facet cache
                        // that was created after doing the first search query
                        loading.current = true;
                        setSearchParams({...searchParams, runInitialSearchRequest: false});
                    } else if (!searchPreferences.serverSideSortingAndPagination) {
                        const numResults = searchResult[valueKey]?.length || 0;
                        if (searchResult["@search.nextPageParameters"]) {
                            // We have more search results that have been paginated by the server
                            if (numResults < MAX_LOADED_SEARCH_RESULTS) {
                                setSearchParams({...searchParams, paginatedSearchRequest: searchResult["@search.nextPageParameters"]});
                            } else {
                                searchParams.paginatedSearchRequest = undefined;
                                setSearchParams(searchParams);
                            }
                        } else if (numResults < resultCount) {
                            if (numResults < MAX_LOADED_SEARCH_RESULTS) {
                                // Calculate the new top value, it should either be the page size or the remaining items count whichever is smaller
                                const topLimit: number = Math.min(MAX_LOADED_SEARCH_RESULTS - numResults, searchParams.pageSize || 1000);
                                const newPaginatedSearchRequest: SearchRequest = {...(searchRequest.current as SearchRequest), top: topLimit, skip: numResults};
                                setSearchParams({...searchParams, paginatedSearchRequest: newPaginatedSearchRequest});
                            } else {
                                searchParams.paginatedSearchRequest = undefined;
                                setSearchParams(searchParams);
                            }
                        }    
                    } 

                        if (appInsightsContext) {
                            const properties = {
                                name: EventNames.FACETED_SEARCH,
                                properties: {
                                searchType: searchParams.searchType,
                                searchText: searchParams.searchText,
                                searchTimeRange: searchParams.timeRange ? JSON.stringify(searchParams.timeRange) : "",
                                searchFacets: searchParams.selectedFacets ? JSON.stringify(searchParams.selectedFacets) : "",
                                numResults: searchStats.current.totalCount,
                                searchTime: Date.now() - searchStartTime,
                                pageSize: searchRequest.current?.top || 1000,
                                skip: searchRequest.current?.skip || 0
                            }
                        };
                        trackEvent(appInsightsContext, AuthService, properties);
                    }

                    if (!bladeOpenedOnLoad.current && params.customPropertyId) {
                        setActiveIncidentForBlade({
                            ...JSON.parse(`{"id":"${params.customPropertyId}"}`),
                            // Show a larger detailed view in blade when viewport is wide enough (Roughly Full HD or wider)
                            detailed: window.innerWidth >= (1920 - 150),
                        })
                        bladeOpenedOnLoad.current = true;
                    }

                    if (!bladeOpenedOnLoad.current && params.runbookId) {
                        setActiveIncidentForBlade({
                            ...JSON.parse(`{"id":"${params.runbookId}"}`),
                            // Show a larger detailed view in blade when viewport is wide enough (Roughly Full HD or wider)
                            detailed: window.innerWidth >= (1920 - 150),
                        })
                        bladeOpenedOnLoad.current = true;
                    }

                    if (!bladeOpenedOnLoad.current && params.inspectorBladeOpen && searchResult?.items?.[0]?.id) {
                        setActiveIncidentForBlade({
                            ...JSON.parse(`{"id":"${searchResult.items[0].id}"}`),
                            // Show a larger detailed view in blade when viewport is wide enough (Roughly Full HD or wider)
                            detailed: window.innerWidth >= (1920 - 150),
                        })
                        bladeOpenedOnLoad.current = true;
                    }
                }
            }, error => {
                // If there is an error, remove the loading animation and show the error dialog.
                loading.current = false;
                showErrorDialog(dialogState, setDialogState);
                if (error?.current) {
                    error.current = true;
                }
                console.error(error);
            });
        },
        [ 
            executeSafely, limitsByFacetCategory, MAX_LOADED_SEARCH_RESULTS, appInsightsContext, 
            searchPreferences.facetMode, searchPreferences.serverSideSortingAndPagination, userPreferences?.search,
            searchPreferences.srchEngine, dialogState, valueKey, params
        ]
    );

    const previousSearchText = useRef<string>("");
    const previousTimeRange = useRef<Partial<TIME_RANGE & TIME_DURATION> | undefined>(undefined);
    const previousSearchType = useRef<SEARCH_TYPE>(SEARCH_TYPE.incident);

    const [idState, setIdState] = useState<Array<string>>([]);
    const selectedItems: Array<string> = [];
    const [showFacetView, setShowFacetView] = useState<boolean>(true);

    const list = searchResults.items;
    if (list && searchResults.type === searchType) {
        for (let itemIndex = 0; itemIndex < list.length; itemIndex++) {
            if (idState.includes(getItemId(list[itemIndex], searchType, false))) {
                selectedItems.push(itemIndex.toString());
            }
            if (params.customPropertyId || params.runbookId || params.inspectorBladeOpen) {
                selectedItems.push("0");
            }
        }
    }

    const savedQueriesVersion: Version = searchPreferences.srchEngine === SEARCH_ENGINE.correlation_dal ? new Version(1, 0, 0) : new Version(0,0,0);
    const savedQueriesOfCorrectVersion: SearchQueryPreference[] = (userPreferences?.search?.savedQueries || []).filter(
        query => {
            const queryVersion = Version.parse(query.version || "");
            return queryVersion.isEqual(savedQueriesVersion) && (query.type === searchType || (!query.type && searchType === SEARCH_TYPE.incident));
        }
    );

    const [initialized, setInitialized] = useState<boolean>(false);
    useEffect(
        () => {
            if (!initialized) {
                if (
                    searchText === "" && timeRange === undefined && Object.keys(selectedFacets.current).length === 0
                ) {
                    let searchParams: any = undefined;
                    if (
                        searchPreferences.saveLastSearchToPreferences && userPreferences.search?.lastSearch &&
                        userPreferences.search?.lastSearch?.type === searchType && 
                        savedQueriesVersion.isEqual(Version.parse(userPreferences.search?.lastSearch?.version || ""))
                    ) {
                        searchParams = {};
                        if (userPreferences.search.lastSearch[PARAM_NAME.searchText]) {
                            searchParams[PARAM_NAME.searchText] = userPreferences.search.lastSearch[PARAM_NAME.searchText];
                        }
                        if (userPreferences.search.lastSearch[PARAM_NAME.searchTime]) {
                            searchParams[PARAM_NAME.searchTime] = userPreferences.search.lastSearch[PARAM_NAME.searchTime];
                        }
                        if (userPreferences.search.lastSearch[PARAM_NAME.facets]) {
                            searchParams[PARAM_NAME.facets] = userPreferences.search.lastSearch[PARAM_NAME.facets];
                        }
                        if (
                            userPreferences.search.lastSearch[
                                PARAM_NAME.groupedFacets
                            ]
                        ) {
                            searchParams[PARAM_NAME.groupedFacets] =
                                userPreferences.search.lastSearch[
                                    PARAM_NAME.groupedFacets
                                ];
                        }
                        if (userPreferences.search.lastSearch[PARAM_NAME.sortColumn]) {
                            searchParams[PARAM_NAME.sortColumn] = userPreferences.search.lastSearch[PARAM_NAME.sortColumn];
                        }
                        if (userPreferences.search.lastSearch[PARAM_NAME.sortOrder]) {
                            searchParams[PARAM_NAME.sortOrder] = userPreferences.search.lastSearch[PARAM_NAME.sortOrder];
                        }
                        if (userPreferences.search.lastSearch[PARAM_NAME.pageSize]) {
                            searchParams[PARAM_NAME.pageSize] = userPreferences.search.lastSearch[PARAM_NAME.pageSize];
                        }
                    } else if (searchType === 'incident') {
                        // These are the defaults for an incident search
                        searchParams = {
                            [PARAM_NAME.searchTime]: null, //{duration: DURATION.DAY_1},
                            [PARAM_NAME.facets]: getInitialSearchParamsFacets(searchPreferences.srchEngine),
                            [PARAM_NAME.groupedFacets]: getInitialSearchParamsFacets(searchPreferences.srchEngine),
                        };
                    }
                    // We are uninitialized and we have no timerange etc
                    if (searchParams) {
                        setQueryParams(searchParams, true);
                    }
                }
                setInitialized(true);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const prevSearchParams = useRef<SearchParams>(searchParams);
    useEffect(
        () => {
            if (searchParams !== prevSearchParams.current) {
                // Whenever search params changes run cognitive search
                prevSearchParams.current = searchParams;
                runCognitiveSearch(searchParams);
            }
        },
        [searchParams, runCognitiveSearch]
    )

    const hasInitialSearchRequest = useRef<boolean>(false);

    // When either the search text changes or the facets change re-run the search
    useEffect(
        () => {
            if (initialized) {
                if (previousSearchType.current !== searchType) {
                    // The search type has changed, clear out the facets and the pagination
                    hasInitialSearchRequest.current = false;
                    facetCache.current = {};
                }

                if (
                    previousSearchType.current !== searchType ||
                    previousSearchText.current !== searchText ||
                    previousTimeRange.current?.startTime !== timeRange?.startTime ||
                    previousTimeRange.current?.endTime !== timeRange?.endTime ||
                    previousTimeRange.current?.duration !== timeRange?.duration
                ) {
                    // The search text has changed so we are doing a new search from the 
                    // begining, update the facet cache
                    hasInitialSearchRequest.current = false;
                    facetCache.current = {};
                    searchStats.current.totalCount = 0;
                    searchStats.current.subCount = 0;

                    // Don't clear out the facets so that they are sticky
                    //clearQueryParam(PARAM_NAME.facets, true);

                    previousSearchText.current = searchText;
                    previousTimeRange.current = timeRange;
                    previousSearchType.current = searchType;
                    setIdState([]);
                    setSearchResults({});
                    setActiveIncidentForBlade(undefined);
                }

                // Set the loading flag
                loading.current = true;

                if (searchPreferences.facetMode === FACET_MODE.replace) {
                    // In replace mode we are always clearing the facets because we are replacing them
                    facetCache.current = {};
                }

                pageIndex.current = 0;
                searchResultsByPage.current = [];

                // Update the search params, if we are initialized and something has changed
                const runInitialSearchRequest = !hasInitialSearchRequest.current && searchPreferences.facetMode !== FACET_MODE.replace;
                hasInitialSearchRequest.current = true;
                setSearchParams(
                    {
                        runInitialSearchRequest: runInitialSearchRequest, searchType: searchType, searchText: searchText, timeRange: timeRange, 
                        selectedFacets: selectedFacets.current, sortColumn: sortBy?.id as FIELDS, 
                        sortOrder: sortBy?.desc === undefined || sortBy?.desc === true ? ORDERS.desc : ORDERS.asc, 
                        pageSize: pageSize || searchPreferences.pageSize || 1000,
                        paginatedSearchRequest: undefined
                    }
                );

                if (searchPreferences.saveLastSearchToPreferences) {
                    const prefs = {search: {...userPreferences.search}};
                    prefs.search.lastSearch = getSearchQueryPreferences(
                        searchType, searchText, timeRange, selectedFacets.current, savedQueriesVersion, sortBy, pageSize
                    );
                    setUserPreferences(prefs);
                }
                // To open custom property blade onLoad
                if(searchType === SEARCH_TYPE.properties && params.incidentId ) {
                    setActiveIncidentForBlade({
                        id: params.incidentId,
                        description: '',
                        detailed: window.innerWidth >= (1920 - 150),
                        priority: PRIORITY.UNKNOWN
                    })                    
                }
                // To open the 'Create New Property' dialog onLoad
                if(searchType === SEARCH_TYPE.properties && params.createNewCP) {
                    showCreatePropertyModal();
                    clearQueryParam('createNewCP', false);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            initialized, searchType, searchText, selectedFacets.current, timeRange?.startTime, timeRange?.endTime, 
            timeRange?.duration, limitsByFacetCategory, sortBy?.id, sortBy?.desc, pageSize
        ]
    );

    const [activeIncidentForBlade, setActiveIncidentForBlade] = useState<{
        id: string,
        description: string,
        priority: PRIORITY,
        detailed?: boolean,
        [x: string]: any
    } | undefined>();

    const onIncidentClicked = useCallback((props) => {
        const { incidentDetails } = props;

        if (!incidentDetails.loading) {
            setActiveIncidentForBlade({
                ...incidentDetails,
                // Show a larger detailed view in blade when viewport is wide enough (Roughly Full HD or wider)
                detailed: window.innerWidth >= (1920 - 150),
            })
        }
    }, [setActiveIncidentForBlade]);

    // Get the ids for the action control
    let idsForActions: string[] | undefined = ((searchResults.type === SEARCH_TYPE.incident && list ? list : [])).map(item => getItemId(item, searchType, false));
    if (idsForActions && idsForActions.length < (searchStats.current.subCount || searchStats.current.totalCount)) {
        idsForActions = undefined;
    }

    const hasItemsSelected = idState.length > 0;

    const [deleteCustomProperty] =
        useMutation<DeleteCustomPropertyMutationInput>(
            loader("./queries/delete-custom-property-mutation.graphql"),
            {
                onCompleted: () => {
                    SuccessToaster({
                        message:
                            STRINGS.CUSTOM_PROPERTIES_PAGE.toastMessages.successDeleteCustomProperty,
                    });
                    refreshSearch();
                },
                onError: (_err) => {
                    ErrorToaster({
                        message:
                            STRINGS.CUSTOM_PROPERTIES_PAGE.toastMessages.errorDeleteCustomProperty,
                    });
                },
            }
        );

    const onDeleteCustomProperty = useCallback(
        async (itemId: string) => {
            const isInUse = await checkIfCustomPropertyIsInUse();
            setIsRunningCustomPropCheck(false);
            openConfirm({
                message: isInUse ? 
                         STRINGS.CUSTOM_PROPERTIES_PAGE.confirmCustomPropertyInUseDeleteMessage : 
                         STRINGS.CUSTOM_PROPERTIES_PAGE.confirmCustomPropertyDeleteMessage,
                loading: loading.current,
                onConfirm: () => {
                    return deleteCustomProperty({
                        variables: {
                            id: itemId
                        }
                    }).catch((error) => {
                        ErrorToaster({
                            message: error.message,
                        });
                    });
                },
                icon: IconNames.TRASH,
                intent: Intent.PRIMARY,
            });

            async function checkIfCustomPropertyIsInUse() {
                const items = await runbookService.getRunbooks(Variant.INCIDENT);
                const customPropsInRunbooks = JSON.stringify(items.filter(el => {
                    return JSON.stringify(el.nodes).includes('.custom.');
                }));
                const isInUse = customPropsInRunbooks.includes(`.custom.${itemId}`);
                return isInUse;
            }
        }, 
        [deleteCustomProperty]
    );

    // Schedule Runbook Modal
    const scheduleRunbookModal = useRef();
    const openScheduleRunbookModal = (editInfos = null) => {
        if (scheduleRunbookModal.current) {
            // @ts-ignore
            scheduleRunbookModal.current.handleOpen(editInfos);
        }
    }

    // Callbacks for the more buttons
    const moreBtnActions = {
        edit: onIncidentClicked,
        delete: onDeleteCustomProperty

    }
    const [UnscheduleRunbook] = useMutation<any, any>(
        loader('./modals/ScheduleRunbookModal/unscheduleRunbook.graphql'),
        {
            onCompleted: (data) => {
                SuccessToaster({
                    message: STRINGS.scheduleRunbook.scheduleJobDeleted,
                });
            },
            onError: (err) => {
                ErrorToaster({
                    message: STRINGS.scheduleRunbook.errorDeletingScheduleJob,
                });
                console.error(err?.message);
            },
        }
    );

    const [SetRunbookScheduleJobStatus] = useMutation<any, any>(
        loader('./modals/ScheduleRunbookModal/set-runbook-schedule-job-status-mutation.graphql'),
        {
            onCompleted: (data) => {
                SuccessToaster({
                    message: STRINGS.scheduleRunbook.scheduleJobStatusChanged,
                });
            },
            onError: (err) => {
                ErrorToaster({
                    message: STRINGS.scheduleRunbook.errorChangingScheduleJobStatus,
                });
                console.error(err?.message);
            },
        }
    );

    const columns: Array<TableColumnDef> = getTableColumns(
        searchType,
        history,
        searchPreferences,
        setIsRunningCustomPropCheck,
        customProperties,
        moreBtnActions,
        refreshSearch,
        openScheduleRunbookModal,
        authProfiles,
        edgeConfigQuery?.data?.edges,
        UnscheduleRunbook,
        setRunbookScheduleJobEnabled,
        setDialogState,
        SetRunbookScheduleJobStatus
    );
    const handleColumnsChange = (selectedColumnIds: Array<string>) => {
        if (selectedColumnIds) {
            const prefs = { explorer: {
                [searchType]: selectedColumnIds
            }};
            setUserPreferences(prefs);
        }
    }
 
    // NOTE: can be used to fitler out certain columns
    // conditionals flows as follows: cols set by search params, cols set by
    // user preferences, if columns exist remove custom properties for explorer
    // type `location` also remove table accessor `dataSourceTypes` if valid explorer
    // type has it (`applications`, `device`, `interface`, or `location`)
    /** the initial columns choosen for a user */
    const getSelectedColumnIds = (searchType: string) => {
        let selectedColumnIds;
        if (params && params[PARAM_NAME.tableColumns]) {
            selectedColumnIds = params[PARAM_NAME.tableColumns].split(",");
        } else if (columnPreferences && Object.keys(columnPreferences).length > 0 && Object.entries(columnPreferences).find(entry => entry[0] === searchType)) {
            selectedColumnIds = columnPreferences[searchType];
        } else if (columns) {
            selectedColumnIds = (searchType === SEARCH_TYPE.location) ? 
                [getLocationSearchNameIdForEngine(searchPreferences.srchEngine), "tags", "more"] :
                columns.map(column => column.id).filter(columnId => {
                    return customProperties?.length === 0 || !customProperties.find((item) => (item.name === columnId));
                });
            const colPrefsExist = columnPreferences[searchType] ?? [];
            // NOTE: if columns aren't already defined from the users preference,
            // don't show `dataSourceType`
            if ((!colPrefsExist.includes("dataSourceType") && !colPrefsExist.includes("dataSourceType")) && isLimitedSearchType(searchType)) {
                selectedColumnIds = selectedColumnIds.filter((id) => id !== "dataSourceType" && id !== "dataSourceName");
            }
        } else {
            selectedColumnIds = [];
        }

        return selectedColumnIds;
    }

    const parseData = (tableData: any, searchType: string) => {
        searchType !== SEARCH_TYPE.incident &&
            searchType !== SEARCH_TYPE.tcpconnection &&
            searchType !== SEARCH_TYPE.ondemandrunbooks &&
            searchType !== SEARCH_TYPE.runbookschedules &&
            tableData &&
            tableData.forEach((element, index) => {
                element.hasOwnProperty("customProperties") &&
                    element.customProperties.forEach((prop) => {
                        if (prop.hasOwnProperty("name")) {
                            tableData[index][prop.name] =
                                prop.assignedValue.name;
                        }
                    });
            });
        return tableData;
    };
    const helpType = getHelpType(params.searchType as SEARCH_TYPE);
    /** Retrieves the appropriate object for comment, help summaries, and link.
     * NOTE: If there is no object, will not render the ICON*/
    const ExplorerHelpInfo = HELP[helpType];

    const onFacetSelectedHandler = (
        facetValue: FacetValue,
        facetCategory: string,
        selected: boolean,
    ) => {
        if (facetCache.current[facetCategory]) {
            const facets = facetCache.current[facetCategory];
            for (const facet of facets) {
                if (facet.value === facetValue) {
                    facet.selected = selected;
                    // Set the facets and leave the current search string, causing the component to re-render
                    setQueryParam(
                        facet.isGroupedFacet ? PARAM_NAME.groupedFacets : PARAM_NAME.facets,
                        getFacetQueryParam(facetCache.current, facet.isGroupedFacet || false),
                        true,
                    );
                    break;
                }
            }
        }
    };

    const onFilterSelectionMadeHandler = (selections) => {
        for (const facetCategory in facetCache.current) {
            for (const facet of facetCache.current[facetCategory]) {
                facet.selected =
                    selections &&
                    selections[facetCategory] &&
                    selections[facetCategory].includes(facet.value);
            }
        }
        setQueryParams(
            {
                [PARAM_NAME.facets]: getFacetQueryParam(facetCache.current, false),
                [PARAM_NAME.groupedFacets]: getFacetQueryParam(facetCache.current, true)
            },
            true,
        );
    };

    const onFacetCategorySelectedHandler = (
        facetCategory: string,
        selected: boolean,
    ) => {
        if (facetCache.current[facetCategory]) {
            const facets = facetCache.current[facetCategory];

            let isGrouped = false;
            for (const facet of facets) {
                facet.selected = selected;
                isGrouped = facet.isGroupedFacet || false;
            }
            // Set the facets and leave the current search string, causing the component to re-render
            setQueryParam(
                isGrouped ? PARAM_NAME.groupedFacets : PARAM_NAME.facets,
                getFacetQueryParam(facetCache.current, isGrouped),
                true,
            );
        }
    };

    const onFacetSettingsChangedHandler = (
        facetCategory: string,
        facetSettings: FacetSettings,
    ) => {
        const newLimitsByFacetCategory: Record<string, number> = {
            ...limitsByFacetCategory,
        };
        newLimitsByFacetCategory[facetCategory] =
            facetSettings.facetCategoryLimit;
        if (facetSettings.selectedFacets) {
            // If this is set then the selections are being passed
            if (facetCache.current[facetCategory]) {
                const facets: Facet[] = facetCache.current[facetCategory];
                let isGrouped = false;
                for (const facet of facets) {
                    facet.selected = false;
                    isGrouped = facet.isGroupedFacet || false;
                }
                for (const selectedFacet of facetSettings.selectedFacets) {
                    const cachedFacet = getFacet(
                        facetCategory,
                        selectedFacet.value,
                        facetCache.current,
                    );
                    if (cachedFacet) {
                        cachedFacet.selected = true;
                    } else {
                        facetCache.current[facetCategory].push({
                            ...selectedFacet,
                            selected: true,
                        });
                        isGrouped = selectedFacet.isGroupedFacet || false;
                    }
                }
                // Set the facets and leave the current search string, causing the component to re-render
                setQueryParam(
                    isGrouped ? PARAM_NAME.groupedFacets : PARAM_NAME.facets,
                    getFacetQueryParam(facetCache.current, isGrouped),
                    true,
                );
            }
        }
        setLimitsByFacetCategory(newLimitsByFacetCategory);
    };

    const clearAllFiltersHandler = () => {
        for (const facetCategory in facetCache.current) {
            const facets = facetCache.current[facetCategory];
            for (const facet of facets) {
                facet.selected = false;
            }
        }
        setQueryParams(
            {
                [PARAM_NAME.facets]: getFacetQueryParam(facetCache.current, false),
                [PARAM_NAME.groupedFacets]: getFacetQueryParam(facetCache.current, true),
                [PARAM_NAME.searchTime]: null,
            },
            true,
        );
    };

    const onCloseRunbookModalHandler = () => {
        setOnDemandRunbookDialogState(updateDialogState(onDemandRunbookDialogState, false, false, []))
    };

    const runRunbookHandler = () => {
        runRunbookOnDemand(
            setOnDemandRunbookDialogState,
            null,
            userPreferences?.onDemandRunbookInputs,
            history,
            refreshSearch,
            hasOnDemandRunbookConfigs
        );
    };

    return <PageWithHeader name="IncidentSearch" 
        title={STRINGS.incidentSearch.types[searchType]?.title || "Unknown"} icon={STRINGS.incidentSearch.types[searchType]?.icon} 
        showTimeBar={false} helpInfo={ExplorerHelpInfo}
    >
        <BasicDialog dialogState={dialogState} className={"incident-search-page-dialog" + (searchType === SEARCH_TYPE.runbookschedules ? " runbook-scheduling-page-dialog" : "")} onClose={() => setDialogState(updateDialogState(dialogState, false, false, []))} />
        <DataLoadFacade loading={loading.current} error={undefined/*data.error*/} data={undefined/*data.data*/} showContentsWhenLoading={true} className={"h-100 search-facade" + (searchType === SEARCH_TYPE.runbookschedules ? " search-facade-runbook-scheduling-page" : "")}>
            <TwoColumnContainer hideLeftColumn={!showFacetView} firstColumnSize={SIZE.s} noPaddingOnFirstColumn noPaddingOnSecondColumn doNotSetColumnWidth={false} className="facet-column-container">
                <FacetView
                    className={"facet-view p-2" + (searchType === SEARCH_TYPE.runbookschedules ? " runbook-scheduling-column" : "")}
                    facets={JSON.parse(JSON.stringify(facetCache.current))}
                    customProperties={customProperties}
                    searchType={searchType}
                    limitsByFacetCategory={limitsByFacetCategory}
                    defaultLimit={DEFAULT_FACET_LIMIT}
                    searchRequest={searchRequest.current} searchRequestWithNoFacets={facetSearchRequest.current}
                    searchText={searchText}
                    timeRange={timeRange}
                    showQueryControl={searchPreferences.showQueries === true}
                    sortSelectedFacetsFirst={searchPreferences.sortFacets}
                    onFacetSelected={(facetValue, facetCategory, selected) => {
                        onFacetSelectedHandler(
                            facetValue,
                            facetCategory,
                            selected,
                        )}
                    }
                    onFilterSelectionMade={(selections) => {
                        onFilterSelectionMadeHandler(selections);
                    }}
                    onFacetCategorySelected={(facetCategory, selected) => {
                        onFacetCategorySelectedHandler(facetCategory, selected)
                    }}
                    onFacetSettingsChanged={(facetCategory: string, facetSettings: FacetSettings) => {
                        onFacetSettingsChangedHandler(facetCategory, facetSettings)
                    }}
                    searchQueries={savedQueriesOfCorrectVersion}
                    querySaveRequested={(queryName: string) => {
                        // Add the version to any new saved queries, the DAL query version is 1.0.0, the correlation engine version is 0.0.0
                        const prefs = {search: {...userPreferences.search}};
                        const searchPrefs = getSearchQueryPreferences(
                            searchType, searchText, timeRange, selectedFacets.current, savedQueriesVersion, sortBy, pageSize
                        );
                        if (searchPrefs !== null) {
                            const searchQueryArray = prefs?.search?.savedQueries || [];
                            let found = false;
                            for (let queryIndex = 0; queryIndex < searchQueryArray.length; queryIndex++) {
                                if (searchQueryArray[queryIndex].name === queryName) {
                                    searchQueryArray[queryIndex] = { ...searchPrefs, name: queryName };
                                    found = true;
                                    break;
                                }
                            }
                            if (!found) {
                                searchQueryArray.push({ ...searchPrefs, name: queryName });
                            }
                            prefs.search.savedQueries = searchQueryArray;
                            setUserPreferences(prefs);
                        }
                    }}
                    queryDeleteRequested={(queryName: string) => {
                        // When deleting a query do not worry about the version
                        const prefs: Partial<UserPreferences> = { search: { ...userPreferences.search, savedQueries: [...(userPreferences?.search?.savedQueries || [])] } };
                        const searchQueryArray = prefs?.search?.savedQueries || [];
                        for (let queryIndex = 0; queryIndex < searchQueryArray.length; queryIndex++) {
                            if (searchQueryArray[queryIndex].name === queryName) {
                                searchQueryArray.splice(queryIndex, 1);
                                break;
                            }
                        }
                        prefs.search!.savedQueries = searchQueryArray?.length ? searchQueryArray : null;
                        setUserPreferences(prefs);
                    }}
                    loadQuery={(queryName: string) => {
                        // We don't need to check the version here because in order to see a query it needs
                        // to be the correct version
                        const searchQueryArray = userPreferences?.search?.savedQueries || [];
                        for (const searchQuery of searchQueryArray) {
                            if (searchQuery.name === queryName) {
                                let searchParams = {
                                    [PARAM_NAME.searchText]: null, [PARAM_NAME.searchTime]: null, 
                                    [PARAM_NAME.facets]: null, [PARAM_NAME.groupedFacets]: null,
                                    [PARAM_NAME.sortColumn]: null,
                                    [PARAM_NAME.sortOrder]: null, [PARAM_NAME.pageSize]: null
                                };
                                if (searchQuery[PARAM_NAME.searchText]) {
                                    searchParams[PARAM_NAME.searchText] = searchQuery[PARAM_NAME.searchText];
                                }
                                if (searchQuery[PARAM_NAME.searchTime]) {
                                    searchParams[PARAM_NAME.searchTime] = searchQuery[PARAM_NAME.searchTime];
                                }
                                if (searchQuery[PARAM_NAME.facets]) {
                                    searchParams[PARAM_NAME.facets] = searchQuery[PARAM_NAME.facets];
                                }
                                if (searchQuery[PARAM_NAME.groupedFacets]) {
                                    searchParams[PARAM_NAME.groupedFacets] = searchQuery[PARAM_NAME.groupedFacets];
                                }
                                if (searchQuery[PARAM_NAME.sortColumn]) {
                                    searchParams[PARAM_NAME.sortColumn] = searchQuery[PARAM_NAME.sortColumn];
                                }
                                if (searchQuery[PARAM_NAME.sortOrder]) {
                                    searchParams[PARAM_NAME.sortOrder] = searchQuery[PARAM_NAME.sortOrder];
                                }
                                if (searchQuery[PARAM_NAME.pageSize]) {
                                    searchParams[PARAM_NAME.pageSize] = searchQuery[PARAM_NAME.pageSize];
                                }
                                setIdState([]);
                                setQueryParams(searchParams, true);
                                break;
                            }
                        }
                    }}
                    clearAllFilters={() => clearAllFiltersHandler()}
                />
                <div>
                <div className={"search-header-wrapper pt-4 pb-2 pl-2 pr-3" + (searchType === SEARCH_TYPE.runbookschedules ? " runbook-scheduling-column" : "")}>
                    <div className="search-header-bar d-flex flex-wrap" style={{ width: "100%" }}>
                    <WrapInTooltip tooltip={"Show/hide filter sidebar"}>
                        <Button
                            icon={showFacetView ? IconNames.DOUBLE_CHEVRON_LEFT : IconNames.DOUBLE_CHEVRON_RIGHT}
                            className={"filter-toggle-button mr-3"}
                            intent={Intent.NONE}
                            onClick={() => {
                                setShowFacetView(!showFacetView);
                            }}
                        />
                    </WrapInTooltip>
                        <div className="d-flex flex-nowrap">
                            <SearchControl searchText={searchText} searchType={searchType} onChange={(newSearchText: string) => {
                                // Set the search text, causing the component to re-render
                                setQueryParams({ [PARAM_NAME.searchText]: newSearchText || "" }, true);
                            }} />
                            {showDebugInformation && <div>
                                <AnchorButton icon={IconNames.DIAGNOSIS} minimal={true} onClick={() => {
                                    showDebugDialog(searchResults, dialogState, setDialogState);
                                }} className="indicator-details-view-debug-button" />
                            </div>}
                        </div>
                                
                        {searchType === SEARCH_TYPE.properties && userHasWritePermissions && 
                            <div className="d-flex ml-auto" style={{gap: '8px'}}>
                                <ButtonGroup>
                                    <Button onClick={() => {showImportPropertiesModal(searchResults)}} text={STRINGS.CUSTOM_PROPERTIES_PAGE.bulkActionBtns.import} icon={IconNames.IMPORT} disabled={hasItemsSelected}/>
                                    <Button onClick={() => {handleExportToCSV(searchResults)}} text={STRINGS.CUSTOM_PROPERTIES_PAGE.bulkActionBtns.export} icon={IconNames.EXPORT} disabled={hasItemsSelected} />
                                    <Button onClick={() => showCreatePropertyModal()} text={STRINGS.CUSTOM_PROPERTIES_PAGE.bulkActionBtns.create} icon={IconNames.ADD} disabled={hasItemsSelected} />
                                    <Button onClick={() => showDeleteCustomPropertiesModal()} text={STRINGS.CUSTOM_PROPERTIES_PAGE.bulkActionBtns.delete} icon={IconNames.TRASH} disabled={!hasItemsSelected} />
                                </ButtonGroup>
                            </div>
                        }

                        {searchType === SEARCH_TYPE.runbookschedules && userHasWritePermissions && (
                            <div className="d-flex ml-auto" style={{gap: '8px'}}>
                                <Button
                                    text={STRINGS.scheduleRunbook.openWizardBtn}
                                    icon={IconNames.CALENDAR}
                                    onClick={() => { 
                                        const newDialogState: any = {
                                            showDialog: true,
                                            loading: true,
                                            title: STRINGS.scheduleRunbook.openWizardBtn,
                                        };
                                    
                                        setDialogState(newDialogState);
                                    
                                        client.query({
                                            query: new Query(loader("../../utils/services/search/search-runbooks-schedule-jobs.graphql")).getGqlQuery(),
                                            variables: {
                                                count: true,
                                                top: 10000,
                                                skip: 0
                                            },
                                            fetchPolicy: "no-cache"
                                        }).then(results => {
                                            const scheduleRunbookJobs = results?.data?.searchItems?.page;
                                            const jobsEnabledCount = scheduleRunbookJobs?.filter(job => job.enabled)?.length || 0;
                                            const allowedJobsLimit = 200;
                                            if (jobsEnabledCount >= allowedJobsLimit) {
                                                newDialogState.loading = false;
                                                newDialogState.dialogContent = (
                                                    <div className="mb-3">
                                                        <span>
                                                            {STRINGS.formatString(
                                                                STRINGS.scheduleRunbook.changeRunbookScheduleJobStatusDialog.limitErrorCreate, allowedJobsLimit
                                                            )}
                                                        </span>
                                                    </div>
                                                );
                                                newDialogState.dialogFooter = (
                                                    <>
                                                        <Button
                                                            active={true}
                                                            outlined={true}
                                                            onClick={() => {
                                                                setDialogState(updateDialogState(newDialogState, false, false, []));
                                                            }}
                                                            text={STRINGS.runbooks.okBtnText}
                                                        />
                                                    </>
                                                );
                                                setDialogState(updateDialogState(newDialogState, true, false, []));
                                            } else {
                                                setDialogState(updateDialogState(newDialogState, false, false, []));
                                                openScheduleRunbookModal(); 
                                            }
                                        }).catch(error => {
                                            newDialogState.dialogFooter = (
                                                <>
                                                    <Button
                                                        active={true}
                                                        outlined={true}
                                                        onClick={() => {
                                                            setDialogState(updateDialogState(newDialogState, false, false, []));
                                                        }}
                                                        text={STRINGS.runbooks.okBtnText}
                                                    />
                                                </>
                                            );
                                            setDialogState(updateDialogState(newDialogState, true, false, [error]));
                                        });
                                    }}
                                />
                            </div>
                        )}

                        {
                            searchType === SEARCH_TYPE.ondemandrunbooks && userHasWritePermissions ?
                                (
                                    <div
                                        data-testid="RunbookAnalyses-controls"
                                        className="d-flex ml-auto"
                                        style={{ gap: "8px" }}
                                    >
                                        <ButtonGroup>
                                            <Button
                                                aria-label="runbook-new-button"
                                                icon={IconNames.ADD}
                                                onClick={() => runRunbookHandler()}
                                                text={STRINGS.runbookInvocations.runRunbook}
                                            />
                                            <Button
                                                text={STRINGS.delete}
                                                icon={IconNames.DELETE}
                                                onClick={() =>
                                                    showDeleteRunbookOutputsModal()
                                                }
                                                disabled={!hasItemsSelected}
                                            />
                                        </ButtonGroup>
                                        <BasicDialog
                                            dialogState={onDemandRunbookDialogState}
                                            onClose={() => onCloseRunbookModalHandler()} 
                                            className="runbook-on-demand-inputs-dialog"
                                        />
                                    </div>
                                ) : null
                        }


                        {![SEARCH_TYPE.properties, SEARCH_TYPE.incident, SEARCH_TYPE.ondemandrunbooks, SEARCH_TYPE.runbookschedules].includes(searchType) && showSetCPButton && userHasWritePermissions && 
                        <div className="d-flex ml-auto" style={{gap: '8px'}}>
                            <ButtonGroup>
                                <Button text={'Set Custom Property'} icon={IconNames.TAG} onClick={() => setCustomPropertiesModal()} />
                            </ButtonGroup>
                        </div>}
                        {searchType === SEARCH_TYPE.incident && searchResults.type === searchType && 
                        <IncidentTableActions selectedIncidentIds={idState}
                            allIncidentIds={idsForActions}
                            searchRequest={searchRequest.current}
                            onUpdate={() => {
                                // Need to set loading here because there is a delay
                                loading.current = true;
                                setIdState([]);
                                const newSearchResults = { ...searchResults };
                                newSearchResults[valueKey] = [...searchResults[valueKey]];
                                setSearchResults(newSearchResults);

                                // Run the search, add a little delay because the search service needs some time to get
                                // the updates from the correlation engine.
                                setTimeout(() => {
                                    // Re-run the search by clearing the pagination and updating the reference to the search params
                                    pageIndex.current = 0;
                                    setSearchParams({ ...searchParams, paginatedSearchRequest: undefined });
                                }, 5 * 1000);
                            }}
                            className="ml-auto"
                        />}
                    </div>
                    <div className="display-9 search-result-count mt-1 ml-5">
                        {searchPreferences.facetMode !== FACET_MODE.replace && searchStats.current.subCount !== undefined ? <><span className="font-weight-normal">{searchStats.current.subCount}</span> out of </> : ""} 
                        <span className="font-weight-normal">{searchStats.current.totalCount}</span> {STRINGS.SEARCH.results}
                        {!searchPreferences.serverSideSortingAndPagination && searchStats.current.truncatedAt !== Number.MAX_SAFE_INTEGER ? <> (truncated at <span className="font-weight-normal">{searchStats.current.truncatedAt}</span>  results)</> : ""}
                    </div>
                    <FilterView timeRange={timeRange} selectedFacets={selectedFacets.current} type={searchType} className="my-3"
                        showTypeControl={searchPreferences.showTypeControl} showQueryControl={searchPreferences.showQueries === true}
                        onTimeChange={(timeRange: Partial<TIME_RANGE & TIME_DURATION> | undefined) => {
                            setIdState([]);
                            setQueryParam(PARAM_NAME.searchTime, timeRange, true);
                        }}
                        onTypeChange={(newSearchType: SEARCH_TYPE) => {
                            facetCache.current = {};
                            setIdState([]);
                            setSearchResults({});
                            setQueryParams(
                                {
                                    [PARAM_NAME.searchType]: newSearchType, [PARAM_NAME.searchText]: null, [PARAM_NAME.searchTime]: null, 
                                    [PARAM_NAME.facets]: null, [PARAM_NAME.groupedFacets]: null,
                                    [PARAM_NAME.sortColumn]: null, [PARAM_NAME.sortOrder]: null, 
                                    [PARAM_NAME.pageSize]: null
                                }, 
                                true
                            );
                            setActiveIncidentForBlade(undefined);
                        }}
                        onFacetSelected={(facetValue, facetCategory, selected) => {
                            onFacetSelectedHandler(facetValue, facetCategory, selected)
                        }}
                        clearAllFilters={() => clearAllFiltersHandler()}
                    />
                    </div>
                    {(searchType === SEARCH_TYPE.incident ||
                        searchType === SEARCH_TYPE.tcpconnection ||
                        searchType === SEARCH_TYPE.ondemandrunbooks ||
                        (searchType === SEARCH_TYPE.runbookschedules && !edgeConfigQuery?.loading && authProfiles) ||
                        (searchType !== SEARCH_TYPE.runbookschedules && customPropertiesQuery &&
                            customPropertiesQuery.hasOwnProperty(
                                "loading",
                            ) &&
                            !customPropertiesQuery.loading)) &&
                        <Table
                            id="incidentList"
                            removeOverflow={true}
                            key={"incidentList-" + searchType}
                            className={"incident-search-table display-9 pl-2 pr-3" + (searchType === SEARCH_TYPE.runbookschedules ? " pb-5" : "")}
                            columns={columns}
                            columnDefinitionDefaults={{
                                headerClassName: "text-nowrap w-min-1-5 display-9",
                                className: "display-9",
                            }}
                            data={parseData(tableData, searchType)}
                            sortExternally={searchPreferences.serverSideSortingAndPagination}
                            sortBy={[sortBy]}
                            onSortByChange={([newSortBy]) => {
                                if (searchPreferences.serverSideSortingAndPagination) {
                                    setQueryParams({[PARAM_NAME.sortColumn]: newSortBy.id, [PARAM_NAME.sortOrder]: (newSortBy.desc ? "desc" : "asc")}, true);
                                }
                            }}
                            // enableTopPagination={!searchPreferences.serverSideSortingAndPagination}
                            enablePagination={!searchPreferences.serverSideSortingAndPagination}
                            defaultPageSize={pageSize || 10}
                            enableSelection={searchType !== SEARCH_TYPE.runbookschedules}
                            selectOnRowClick={true}
                            selectedColumnIds={getSelectedColumnIds(searchType)}
                            showColumnChooser={searchType !== SEARCH_TYPE.incident}
                            onColumnsChange={handleColumnsChange}
                            onSelectionChange={(rows: Array<any>) => {
                                const newIdState: Array<string> = [];
                                for (const row of rows) {
                                    newIdState.push(getItemIdFromTable(row, searchType, false));
                                }
                                setIdState(newIdState);
                            }}
                            selectedRows={selectedItems}
                            onRowClick={(e) => {
                                // Runbook Schedules don't have a blade
                                if (searchType === SEARCH_TYPE.runbookschedules) {
                                    return;
                                }

                                onIncidentClicked({
                                    incidentId: e.record.id,
                                    incidentDetails: e.record,
                                });
                                setShowSetCPButton(false);
                            }}
                        />
                    }
                    {searchPreferences.serverSideSortingAndPagination &&
                    <div className="d-flex justify-content-end pagination-sticky pl-2 pr-3">
                        <PaginationControl pageIndex={pageIndex.current} 
                            numberOfPages={Math.ceil((searchStats.current.subCount > 0 ? searchStats.current.subCount : searchStats.current.totalCount) / (pageSize || searchPreferences.pageSize!))}
                            loading={loading.current}
                            onPageSelected={(newPageIndex: number) => {
                                pageIndex.current = newPageIndex;
                                const top = pageSize || searchPreferences.pageSize!;
                                if (!searchResultsByPage.current[pageIndex.current]) {
                                    loading.current = true;
                                    const skip: number = pageIndex.current * (top);
                                    const newPaginatedSearchRequest: SearchRequest = {...(searchRequest.current as SearchRequest), top: top, skip: skip};
                                    setSearchParams({...searchParams, paginatedSearchRequest: newPaginatedSearchRequest});
                                } else {
                                    setSearchResults({...searchResults});
                                }            
                            }}
                            defaultPageSize={pageSize || searchPreferences.pageSize!}
                            onPageSizeSelected={(pageSize: number) => {
                                setQueryParam(PARAM_NAME.pageSize, pageSize, true);
                            }}
                    /></div>}
                </div>
                <br />
            </TwoColumnContainer>
        </DataLoadFacade>
        <LoadingOverlay visible={isRunningCustomPropUseCheck} loadingText={STRINGS.CUSTOM_PROPERTIES_PAGE.checkInUseLoadingMessage}/>
        {activeIncidentForBlade && <IncidentBriefBlade
            key={"blade-" + activeIncidentForBlade.id}
            {...activeIncidentForBlade}
            priority={activeIncidentForBlade?.priority?.toUpperCase() as PRIORITY}
            onBladeClosed={() => { 
                setActiveIncidentForBlade(undefined); 
                refreshSearch();
                setShowSetCPButton(true);  
                clearQueryParam('runbookId',false);
                clearQueryParam('customPropertyId',false);
                clearQueryParam('inspectorBladeOpen',false);
                setIdState([]);
            }}
            onSetProperty={() => refreshSearch()}
            incidentEndTime={activeIncidentForBlade.endTime}
            incident={activeIncidentForBlade}
            engine={searchPreferences.srchEngine}
        />}
        <ScheduleRunbookModal ref={scheduleRunbookModal} title="Ke e esto!?" refreshSearch={refreshSearch} />
    </PageWithHeader>;

    /** Refresh the results list and put it back to page 0. */
    function refreshSearch(): void {
        pageIndex.current = 0;
        setSearchParams({ ...searchParamsRef.current, paginatedSearchRequest: undefined });
    }

    /** Show set custom properties dialog. */
    function setCustomPropertiesModal(): void {
        return openModal("setCustomPropertiesModal", {
            selectedIds: idState,
            totalCount: searchStats.current.totalCount,
            facets: selectedFacets?.current,
            searchType: searchType,
            searchRequest: searchRequest,
            onSuccess: () => { refreshSearch(); },
            onError: () => { loading.current = false; },
        });
    }

    function showDeleteRunbookOutputsModal() {
        return openModal("deleteRunbookOutput", {
            selectedIds: idState,
            onSuccess: () => {
                refreshSearch();
            },
            onError: () => {
                loading.current = false;
            },
            onClose: () => {},
        });
    }

    /** Show delete custom properties dialog. */
    function showDeleteCustomPropertiesModal(): void {       
        const facets = selectedFacets?.current;
        const namesFacet = (getCustomPropertySearchNameIdForEngine(searchPreferences.srchEngine));
        const descriptionFacet = (getCustomPropertySearchDescriptionIdForEngine(searchPreferences.srchEngine));
        const userIdsFacet = 'customProperties/userId';
        const valuesFacet = 'customProperties/customPropertyValues/name';

        return openModal("deleteCustomPropertiesModal", {
            selectedIds: idState,
            allIds: searchResults.items?.map(el => el.id),
            totalCount: searchStats.current.totalCount,
            currentSearchQuery: {
                search: searchText,
                searchFields: ["NAME", "DESCRIPTION"],
                names: facets?.[namesFacet] || [],
                descriptions: facets?.[descriptionFacet] || [],
                userIds: facets?.[userIdsFacet] || [],
                values: facets?.[valuesFacet] || [],
            },
            onSuccess: () => { refreshSearch(); },
            onError: () => { loading.current = false; },
            markItemsAsDeleted: (ids) => {
                searchResults.items = searchResults.items?.map((item) => {
                    if (ids.includes(item.id)) {
                        item.isDeleted = true;
                    }

                    return item;
                })
            }
        });
    }

    /** Creates a popup that imports a CSV file.

    /**
     * Show the Import Properties from CSV Modal
     * 
     * @param searchResults - a list of the names of existing custom properties
     * 
     * @returns {callback}
     */
    function showImportPropertiesModal(searchResults: any) {
        return openModal("importCustomPropertiesModal", {
            existingProperties: searchResults?.items.map(el => el.name),
            onSuccess: () => { refreshSearch(); },
            onError: () => { loading.current = false; }
        });
    }

    /**
     * @param searchResults - a list of all the Custom Properties
     */
    function handleExportToCSV(searchResults) {
        CSVParserService.exportToCSV(searchResults?.items, ALLOW_MULTI_TYPE);
    }

    /**
     * Show the Create Property Modal
     * 
     * @param searchResults - a list of the names of existing custom properties
     * 
     * @returns {callback}
     */
    function showCreatePropertyModal() {
        return openModal("addCustomPropertyModal", {
            onSuccess: () => {
                setSearchParams({...searchParams, searchType: searchType, pageSize: pageSize || 100})
            },
            onError: () => { loading.current = false; }
        });
    }
};

export default IncidentSearchPage;

/** get the search preferences object for the current filter and fuzzy search.
 *  @param searchType the SEARCH_TYPE which specifies whether this is an incident, interface, device search ...
 *  @param searchText the current fuzzy search text.
 *  @param timeRange the currently selected time range.
 *  @param selectedFacets the current list of facets.
 *  @param version the Version of the saved queries.
 *  @param sortBy the sort by specification.
 *  @param pageSize the page size value.
 *  @returns the SearchQueryPreference object with the current search query. */
/* istanbul ignore next */
function getSearchQueryPreferences(
    searchType: SEARCH_TYPE, searchText: string, timeRange: any, selectedFacets: Record<string, Array<FacetValue>>, 
    version: Version, sortBy?: {id: string, desc: boolean}, pageSize?: number
): SearchQueryPreference | null {
    let searchPrefs: SearchQueryPreference | null = null;
    if (searchText || timeRange || Object.keys(selectedFacets).length) {
        searchPrefs = {
            type: searchType,
            [PARAM_NAME.searchText]: null, [PARAM_NAME.searchTime]: null, [PARAM_NAME.facets]: null,
            [PARAM_NAME.groupedFacets]: null,
            version: version.toString(),
            [PARAM_NAME.sortColumn]: null, [PARAM_NAME.sortOrder]: null, [PARAM_NAME.pageSize]: null
        };
        if (searchText) {
            searchPrefs[PARAM_NAME.searchText] = searchText;
        }
        if (timeRange) {
            searchPrefs[PARAM_NAME.searchTime] = timeRange;
        }
        if (Object.keys(selectedFacets).length) {
            searchPrefs[PARAM_NAME.facets] = JSON.stringify(selectedFacets);
            searchPrefs[PARAM_NAME.groupedFacets] = JSON.stringify(selectedFacets);
        }
        if (sortBy) {
            searchPrefs[PARAM_NAME.sortColumn] = sortBy.id;
            searchPrefs[PARAM_NAME.sortOrder] = sortBy.desc ? "desc" : "asc";
        }
        if (pageSize && pageSize > 0) {
            searchPrefs[PARAM_NAME.pageSize] = pageSize;
        }
    }
    return searchPrefs;
}

/** creates the SearchRequest object from the specified input fields.
 *  @param searchText a String with the search text that the customer entered.
 *  @param limitsByFacetCategory the limit on the number of facet values, indexed by facet category.
 *  @param timeRange the time range the user has entered or undefined if no time range has been entered.
 *  @param selectedFacets the selected facets.
 *  @param type the type of search that the page is displaying.
 *  @param topLimit the top limit which is basically a number that specifies the page size.
 *  @param searchPreferences the search preferences.
 *  @param sortColumn a String with the id of the sort column.
 *  @param sortOrder an ORDERS value with the sort order.
 *  @returns the SearchRequest object that can be submitted to the search service. */
export function createSearchRequest(
    searchText: string, limitsByFacetCategory: Record<string, number>, timeRange: Partial<TIME_RANGE & TIME_DURATION> | undefined,
    selectedFacets: Record<string, Array<FacetValue>> | undefined, type: SEARCH_TYPE,
    topLimit: number = 10000, searchPreferences: SearchPreference | undefined, sortColumn?: FIELDS, sortOrder?: ORDERS, 
): SearchRequest {
    let searchRequest: SearchRequest | undefined;

    const searchPrefs: SearchPreference = {...DEFAULT_SEARCH_PREF, ...searchPreferences};
    const {serverSideSortingAndPagination} = searchPrefs;
 
    let filter: string = "";
    if (type === SEARCH_TYPE.incident) {
        switch (searchPrefs?.srchEngine) {
            case SEARCH_ENGINE.correlation_direct:
                searchRequest = {
                    type: "Incident",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    //highlightPreTag: "<b>", highlightPostTag: "</b>", 
                    //orderby: {field: FIELDS.devIpaddr, order: ORDERS.asc},
                    searchFields: [
                        FIELDS.incidentsDesc,
                        // Search incidents with device name, ip address and location
                        FIELDS.incidentsIndicatorsIfcName, FIELDS.incidentsIndicatorsIfcIp, FIELDS.incidentsIndicatorsIfcLocName,
                        // Search incidents with interface name, ip address, and location
                        FIELDS.incidentsIndicatorsDevName, FIELDS.incidentsIndicatorsDevIp, FIELDS.incidentsIndicatorsDevLocName,
                        // Search incidents with application name and location name
                        FIELDS.incidentsIndicatorsAppLocAppName, FIELDS.incidentsIndicatorsAppLocLocName,
                        FIELDS.incidentsImpactedAppName, FIELDS.incidentsImpactedLocName, FIELDS.incidentsImpactedUserName
                    ],
                    select: [FIELDS.type, FIELDS.incident],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.incidentsPriority, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsStatus, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsIsOngoing, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsEventCategory, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsIndicatorsDevName, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsIndicatorsDevIp, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsIndicatorsDevLocName, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsIndicatorsIfcName, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsIndicatorsIfcIp, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsIndicatorsIfcLocName, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsIndicatorsAppLocAppName, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsIndicatorsAppLocLocName, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsImpactedLocName, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsImpactedAppName, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsImpactedUserName, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsImpactedUserIp, limitsByFacetCategory)
                    ]
                    //filter: [FIELDS.incident, FIELDS.network_device, FIELDS.network_interface, FIELDS.application, FIELDS.impactedUser, FIELDS.impactedApplication, FIELDS.impactedLocation]
                };
                break;
            case SEARCH_ENGINE.correlation_dal:
                searchRequest = {
                    type: "Incident",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    //highlightPreTag: "<b>", highlightPostTag: "</b>", 
                    //orderby: {field: FIELDS.devIpaddr, order: ORDERS.asc},
                    searchFields: [
                        FIELDS.DESCRIPTION,
                        // Search incidents with device name, ip address and location
                        FIELDS.INTERFACE_NAME, FIELDS.INTERFACE_IP_ADDRESS, FIELDS.INTERFACE_LOCATION_NAME,
                        // Search incidents with interface name, ip address, and location
                        FIELDS.DEVICE_NAME, FIELDS.DEVICE_IP_ADDRESS, FIELDS.DEVICE_LOCATION_NAME,
                        // Search incidents with application name and location name
                        FIELDS.APPLICATION_NAME, FIELDS.APPLICATION_LOCATION_NAME,
                        FIELDS.IMPACTED_APPLICATION, FIELDS.IMPACTED_LOCATION, FIELDS.IMPACTED_USER_NAME
                    ],
                    select: [FIELDS.type, FIELDS.incident],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.PRIORITY, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.STATUS, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.COMPLETION_STATUS, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.EVENT_CATEGORY, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.DEVICE_NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.DEVICE_IP_ADDRESS, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.DEVICE_LOCATION_NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.INTERFACE_NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.INTERFACE_IP_ADDRESS, limitsByFacetCategory),
                        /*New*/
                        getFacetRequestObject(FACET_FIELDS.INTERFACE_LOCATION_NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.APPLICATION_NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.APPLICATION_LOCATION_NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.IMPACTED_LOCATION, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.IMPACTED_APPLICATION, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.IMPACTED_USER_NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.IMPACTED_USER_IP_ADDRESS, limitsByFacetCategory)
                    ]
                    //filter: [FIELDS.incident, FIELDS.network_device, FIELDS.network_interface, FIELDS.application, FIELDS.impactedUser, FIELDS.impactedApplication, FIELDS.impactedLocation]
                };
                break;
        }

        filter = "";

        if (selectedFacets && selectedFacets["incident/duration"]?.length) {
            // Handle the duration facet which is something that we created and is not returned with the facets
            let durationFilter = "";
            for (const value of selectedFacets["incident/duration"]) {
                if (value === STRINGS.incidentSearch.facetView.facets["incident/duration"].ongoingValue) {
                    // Add the ongoing filter
                    durationFilter += (durationFilter.length > 0 ? " or " : "") + "incident/endTime eq null";
                }
                if (value === STRINGS.incidentSearch.facetView.facets["incident/duration"].endedValue) {
                    // Add the ongoing filter
                    durationFilter += (durationFilter.length > 0 ? " or " : "") + "incident/endTime ne null";
                }
            }
            if (durationFilter.length) {
                filter += (filter.length ? " and " : "") + "(" + durationFilter + ")";
            }
        }
    } else if (type === SEARCH_TYPE.device) {
        switch (searchPrefs?.srchEngine) {
            case SEARCH_ENGINE.correlation_direct:
                searchRequest = {
                    type: "NetworkDevice",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    //highlightPreTag: "<b>", highlightPostTag: "</b>", 
                    //orderby: {field: FIELDS.devIpaddr, order: ORDERS.asc},
                    searchFields: [
                        FIELDS.devsName, FIELDS.devsIpaddr, FIELDS.devsHostname, FIELDS.devsType, FIELDS.devsLocName
                    ],
                    select: [FIELDS.type, FIELDS.network_device],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.devsIpaddr, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.devsHostname, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.devsName, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.devsType, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.devsLocName, limitsByFacetCategory),
                    ]
                };
                break;
            case SEARCH_ENGINE.correlation_dal:
                searchRequest = {
                    type: "NetworkDevice",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    //highlightPreTag: "<b>", highlightPostTag: "</b>", 
                    //orderby: {field: FIELDS.devIpaddr, order: ORDERS.asc},
                    searchFields: [
                        FIELDS.NAME, FIELDS.IP_ADDRESS, FIELDS.HOSTNAME, FIELDS.TYPE, FIELDS.LOCATION_NAME
                    ],
                    select: [FIELDS.type, FIELDS.network_device],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.IP_ADDRESS, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.HOSTNAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.TYPE, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.LOCATION_NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.SERIAL_NUMBER, limitsByFacetCategory),
                        //getFacetRequestObject(FACET_FIELDS.ELEMENT_TYPE, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.OS_VERSION, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.MODEL, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.VENDOR, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.IS_GATEWAY, limitsByFacetCategory),
                        getFacetRequestObject(
                            "CUSTOM_PROPERTY_VALUE_NAME",
                            limitsByFacetCategory,
                            "CUSTOM_PROPERTY_NAME",
                        ),
                    ]
                };
                break;
        }
        filter = "";
    } else if (type === SEARCH_TYPE.interface) {
        switch (searchPrefs?.srchEngine) {
            case SEARCH_ENGINE.correlation_direct:
                searchRequest = {
                    type: "NetworkInterface",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    //highlightPreTag: "<b>", highlightPostTag: "</b>", 
                    //orderby: {field: FIELDS.devIpaddr, order: ORDERS.asc},
                    searchFields: [
                        FIELDS.ifcsName, FIELDS.ifcsIpaddr, /*FIELDS.ifcIfindex,*/ FIELDS.ifcsLocName
                    ],
                    select: [FIELDS.type, FIELDS.network_interface],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.ifcsName, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.ifcsIpaddr, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.ifcsIfindex, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.ifcsLocName, limitsByFacetCategory)
                    ]
                };
                break;
            case SEARCH_ENGINE.correlation_dal:
                searchRequest = {
                    type: "NetworkInterface",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    //highlightPreTag: "<b>", highlightPostTag: "</b>", 
                    //orderby: {field: FIELDS.devIpaddr, order: ORDERS.asc},
                    searchFields: [
                        FIELDS.NAME, FIELDS.IP_ADDRESS, FIELDS.TYPE, FIELDS.LOCATION_NAME, FIELDS.IF_ALIAS, FIELDS.IF_DESCRIPTION,
                        FIELDS.DEVICE_NAME, FIELDS.DEVICE_IP_ADDRESS, FIELDS.DEVICE_HOSTNAME, FIELDS.DEVICE_TYPE,
                        FIELDS.DEVICE_SERIAL_NUMBER, FIELDS.DEVICE_OS_VERSION, FIELDS.DEVICE_MODEL, 
                        //FIELDS.DEVICE_ELEMENT_TYPE, 
                        FIELDS.DEVICE_VENDOR
                    ],
                    select: [FIELDS.type, FIELDS.network_interface],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.IP_ADDRESS, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.IF_INDEX, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.LOCATION_NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.TYPE, limitsByFacetCategory),
                        //getFacetRequestObject(FACET_FIELDS.ELEMENT_TYPE, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.IF_ALIAS, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.IF_DESCRIPTION, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.INBOUND_SPEED, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.OUTBOUND_SPEED, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.DEVICE_NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.DEVICE_IP_ADDRESS, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.DEVICE_HOSTNAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.DEVICE_TYPE, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.DEVICE_SERIAL_NUMBER, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.DEVICE_OS_VERSION, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.DEVICE_MODEL, limitsByFacetCategory),
                        //getFacetRequestObject(FACET_FIELDS.DEVICE_ELEMENT_TYPE, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.DEVICE_VENDOR, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.DEVICE_IS_GATEWAY, limitsByFacetCategory),
                        getFacetRequestObject(
                            "CUSTOM_PROPERTY_VALUE_NAME",
                            limitsByFacetCategory,
                            "CUSTOM_PROPERTY_NAME",
                        ),
                    ]
                };
                    break;
        }
        filter = "";
    } else if (type === SEARCH_TYPE.application) {
        switch (searchPrefs?.srchEngine) {
            case SEARCH_ENGINE.correlation_direct:
                searchRequest = {
                    type: "Application",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    //highlightPreTag: "<b>", highlightPostTag: "</b>", 
                    //orderby: {field: FIELDS.devIpaddr, order: ORDERS.asc},
                    searchFields: [
                        FIELDS.appsName
                    ],
                    select: [FIELDS.type, FIELDS.application],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.appsName, limitsByFacetCategory),
                    ]
                };
                break;
            case SEARCH_ENGINE.correlation_dal:
                searchRequest = {
                    type: "Application",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    //highlightPreTag: "<b>", highlightPostTag: "</b>", 
                    //orderby: {field: FIELDS.devIpaddr, order: ORDERS.asc},
                    searchFields: [
                        FIELDS.NAME
                    ],
                    select: [FIELDS.type, FIELDS.NAME],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.NAME, limitsByFacetCategory),
                        getFacetRequestObject(
                            "CUSTOM_PROPERTY_VALUE_NAME",
                            limitsByFacetCategory,
                            "CUSTOM_PROPERTY_NAME",
                        ),
                    ]
                };
                break;
        }
        filter = "";
    } else if (type === SEARCH_TYPE.location) {
        switch (searchPrefs?.srchEngine) {
            case SEARCH_ENGINE.correlation_direct:
                searchRequest = {
                    type: "Location",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    //highlightPreTag: "<b>", highlightPostTag: "</b>", 
                    //orderby: {field: FIELDS.devIpaddr, order: ORDERS.asc},
                    searchFields: [
                        FIELDS.locsName, FIELDS.locsType, FIELDS.locsCity, FIELDS.locsState, FIELDS.locsCountry
                    ],
                    select: [FIELDS.type, FIELDS.location],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.locsName, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.locsType, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.locsCity, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.locsState, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.locsCountry, limitsByFacetCategory),
                    ]
                };
                break;
            case SEARCH_ENGINE.correlation_dal:
                searchRequest = {
                    type: "Location",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    //highlightPreTag: "<b>", highlightPostTag: "</b>", 
                    //orderby: {field: FIELDS.devIpaddr, order: ORDERS.asc},
                    searchFields: [
                        FIELDS.NAME, FIELDS.TYPE, FIELDS.CITY, FIELDS.STATE, FIELDS.COUNTRY
                    ],
                    select: [FIELDS.type, FIELDS.location],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.TYPE, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.CITY, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.STATE, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.COUNTRY, limitsByFacetCategory),
                        getFacetRequestObject(
                            "CUSTOM_PROPERTY_VALUE_NAME",
                            limitsByFacetCategory,
                            "CUSTOM_PROPERTY_NAME",
                        ),
                    ]
                };
                break;
        }
        filter = "";
    } else if (type === SEARCH_TYPE.properties) {
        switch (searchPrefs?.srchEngine) {
            case SEARCH_ENGINE.correlation_direct:
                // Only supported by correlation engine
                searchRequest = {
                    type: "CustomProperty",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    //highlightPreTag: "<b>", highlightPostTag: "</b>", 
                    //orderby: {field: FIELDS.devIpaddr, order: ORDERS.asc},
                    searchFields: [
                        FIELDS.propName, FIELDS.propDesc
                    ],
                    select: [FIELDS.type, FIELDS.location],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.propName, limitsByFacetCategory),
                        // Damien and Jeff asked to remove this
                        //getFacetRequestObject(FACET_FIELDS.propDesc, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.propUser, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.propValue, limitsByFacetCategory),
                        //getFacetRequestObject(FACET_FIELDS.propType, limitsByFacetCategory),
                    ]
                };
                break;
            case SEARCH_ENGINE.correlation_dal:
                // Only supported by correlation engine
                searchRequest = {
                    type: "CustomProperty",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    //highlightPreTag: "<b>", highlightPostTag: "</b>", 
                    //orderby: {field: FIELDS.devIpaddr, order: ORDERS.asc},
                    searchFields: [
                        FIELDS.NAME, FIELDS.DESCRIPTION
                    ],
                    select: [FIELDS.type, FIELDS.location],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.ENTITY_KIND, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.NAME, limitsByFacetCategory),
                        // Damien and Jeff asked to remove this
                        //getFacetRequestObject(FACET_FIELDS.DESCRIPTION, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.VALUE_NAME, limitsByFacetCategory),
                    ]
                };
                break;
        }
        filter = "";
    } else if (type === SEARCH_TYPE.tcpconnection) {
        switch (searchPrefs?.srchEngine) {
            case SEARCH_ENGINE.correlation_dal:
                searchRequest = {
                    type: "TcpConnection",
                    search: getSearchString(searchText, searchPrefs),
                    top: topLimit,
                    // This shows the total count, not the search result count
                    count: true,
                    searchFields: [FIELDS.APPLICATION_NAME, FIELDS.OS],
                    select: [FIELDS.APPLICATION_NAME, FIELDS.OS],
                    facets: [
                        getFacetRequestObject(
                            FACET_FIELDS.OS,
                            limitsByFacetCategory,
                        ),
                        getFacetRequestObject(
                            FACET_FIELDS.APPLICATION_NAME,
                            limitsByFacetCategory,
                        ),
                    ],
                };
                break;
        }
    } else if (type === SEARCH_TYPE.ondemandrunbooks) {
        switch (searchPrefs?.srchEngine) {
            case SEARCH_ENGINE.correlation_direct:
                searchRequest = {
                    type: "OndemandRunbooks",
                    search: getSearchString(searchText, searchPrefs),
                    top: topLimit,
                    // This shows the total count, not the search result count
                    count: true,
                    searchFields: [
                        FIELDS.TITLE,
                    ],
                    select: [
                        FIELDS.TITLE,
                    ],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.RUNBOOK_NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.ENTITY_TYPE, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.STATUS, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.EXECUTION_METHOD, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.CREATED_BY, limitsByFacetCategory),
                    ],
                };
                break;
            case SEARCH_ENGINE.correlation_dal:
                searchRequest = {
                    type: "OndemandRunbooks",
                    search: getSearchString(searchText, searchPrefs),
                    top: topLimit,
                    // This shows the total count, not the search result count
                    count: true,
                    searchFields: [
                        FIELDS.TITLE,
                    ],
                    select: [
                        FIELDS.TITLE,
                    ],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.RUNBOOK_NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.ENTITY_TYPE, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.STATUS, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.EXECUTION_METHOD, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.CREATED_BY, limitsByFacetCategory),
                    ],
                };
                break;
        }
    } else if (type === SEARCH_TYPE.runbookschedules) {
        switch (searchPrefs?.srchEngine) {
            case SEARCH_ENGINE.correlation_direct:
                searchRequest = {
                    type: "RunbookSchedules",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    orderby: {field: FIELDS.schedulesNextRun, order: ORDERS.asc},
                    searchFields: [
                        FIELDS.schedulesRunbookName
                    ],
                    // TODO: will need to change the searchFields, select, and
                    // facet properties once DAL end point is live.
                    select: [
                        FIELDS.schedulesRunbookName
                    ],
                    facets: [],
                };
                break;
            case SEARCH_ENGINE.correlation_dal:
                searchRequest = {
                    type: "RunbookSchedules",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    orderby: {field: FIELDS.schedulesNextRun, order: ORDERS.asc},
                    searchFields: [
                        FIELDS.schedulesRunbookName
                    ],
                    // TODO: will need to change the searchFields, select, and
                    // facet properties once DAL end point is live.
                    select: [
                        FIELDS.schedulesRunbookName
                    ],
                    facets: [],
                };
                break;
        }
    }

    if (timeRange && [SEARCH_TYPE.incident, SEARCH_TYPE.tcpconnection, SEARCH_TYPE.ondemandrunbooks].includes(type)) {
        let actualTimeRange = timeRange.duration ? durationToRoundedTimeRange(timeRange.duration) : timeRange;
        filter += (filter.length ? " and " : "") + `(incidents/createdAt ge ${new Date(actualTimeRange.startTime as number).toISOString()} and incidents/createdAt le ${new Date(actualTimeRange.endTime as number).toISOString()})`;
        if (searchPrefs?.srchEngine === SEARCH_ENGINE.correlation_dal) {
            (searchRequest as any).dalOptions = {timeRange: actualTimeRange};
        }
    }

    if (selectedFacets) {
        for (const category in selectedFacets) {
            if (category === "incident/duration") {
                // This filter is hard coded.
                continue;
            }
            let facetFilter = "";
            for (const value of selectedFacets[category]) {
                if (category.includes("entity")) {
                    facetFilter += `${facetFilter.length > 0 ? " or " : ""}incident/indicators/any(indicator: (indicator/${category.substring(20, category.length)} eq ${getFacetValue(value)}))`;
                } else if (category.includes("incidentIndicators")) {
                    facetFilter += `${facetFilter.length > 0 ? " or " : ""}incidents/incidentIndicators/any(ii: (ii/${category.substring(29, category.length)} eq ${getFacetValue(value)}))`;
                } else if (category.includes("impacted")) {
                    const tokens = category.split("/");
                    facetFilter += `${facetFilter.length > 0 ? " or " : ""}${tokens[0]}/${tokens[1]}/any(ii: (ii/${tokens[2]} eq ${getFacetValue(value)}))`;
                } else if (category.includes("customPropertyValues")) {
                    facetFilter += `${facetFilter.length > 0 ? " or " : ""}customProperties/customPropertyValues/any(ii: (ii/${category.substring(38, category.length)} eq ${getFacetValue(value)}))`;
                } else {
                    facetFilter += `${facetFilter.length > 0 ? " or " : ""}${category} eq ${getFacetValue(value)}`;
                }
            }
            if (facetFilter !== "") {
                filter += `${filter.length > 0 ? " and " : ""}(${facetFilter})`;
            }
        }
        if (filter !== "") {
            searchRequest!.filter = filter;
        }
        if (searchPrefs.srchEngine === SEARCH_ENGINE.correlation_dal) {
            (searchRequest as any).dalOptions = {
                ...(searchRequest as any)?.dalOptions,
                facets: selectedFacets,
                hasParams: hasWhichParams(),
            };
        }
    }

    if (serverSideSortingAndPagination && searchPrefs?.srchEngine) {
        // If we are doing server side sorting, add the order by expression
        const initSortBy = getInitialSortBy(type, searchPrefs.srchEngine);
        searchRequest!.orderby = {
            field: (sortColumn || initSortBy.id) as FIELDS,
            order: sortOrder || (initSortBy.desc ? ORDERS.desc : ORDERS.asc)
        };
    }

    return searchRequest!;
}

/** returns a String with the initial sort column.
 *  @param searchType the SEARCH_TYPE which specifies whether this is an incident, interface, device search, etc
 *  @param engine the SEARCH_ENGINE that is in use.
 *  @returns a String with the initial sort column to use. */
export function getInitialSortBy(searchType: SEARCH_TYPE, engine: SEARCH_ENGINE): {id: string, desc: boolean} {
    switch (engine) {
        case SEARCH_ENGINE.correlation_direct:
            switch (searchType) {
                case SEARCH_TYPE.incident:
                    return {id: "incidents/createdAt", desc: true};
                case SEARCH_TYPE.device:
                    return {id: FIELDS.devsName, desc: false};
                case SEARCH_TYPE.interface:
                    return {id: FIELDS.ifcsName, desc: false};
                case SEARCH_TYPE.application:
                    return {id: FIELDS.appsName, desc: false};
                case SEARCH_TYPE.location:
                    return {id: FIELDS.locsName, desc: false};
                case SEARCH_TYPE.properties:
                    return {id: FIELDS.propName, desc: false};
                case SEARCH_TYPE.ondemandrunbooks:
                    return {id: FIELDS.RUNBOOK_NAME, desc: false};
                case SEARCH_TYPE.runbookschedules:
                    return {id: FIELDS.schedulesNextRun, desc: false};
            }    
            break;
        case SEARCH_ENGINE.correlation_dal:
            switch (searchType) {
                case SEARCH_TYPE.incident:
                    return {id: "createdAt", desc: true};
                case SEARCH_TYPE.device:
                    return {id: "name", desc: false};
                case SEARCH_TYPE.interface:
                    return {id: "name", desc: false};
                case SEARCH_TYPE.application:
                    return {id: "name", desc: false};
                case SEARCH_TYPE.location:
                    return {id: "name", desc: false};
                case SEARCH_TYPE.properties:
                    return {id: "name", desc: false};
                case SEARCH_TYPE.tcpconnection:
                    return {id: "time", desc: false};
                case SEARCH_TYPE.ondemandrunbooks:
                    return { id: "timestamp", desc: true };
                case SEARCH_TYPE.runbookschedules:
                    return {id: FIELDS.schedulesNextRun, desc: false};
            }
            break;
    }
    return {id: "", desc: true};
}

/** returns the search string that should be used based on whether wildcards are enabled or not.
 *  @param searchText a String with the search text.
 *  @param searchPreferences the SearchPreference object with the user and default search preferences.
 *  @returns a string with the new search text or the original string if no changes are necessary. */
function getSearchString(
    searchText: string,
    searchPreferences: SearchPreference,
): string {
    return searchText;
}

/** Used for the `dalOptions` object we send to our `SearchGraphqlApiService.ts` */
type HasParams = {
    hasGroupedFacetParam: boolean;
    hasFacetParam: boolean;
};

// TODO: may not be necessary
/**
 * Predicate function that uses current window's `location.search` to determine
 * if a `groupedFacet` has been selected. Inference done with the url search
 * params.
 * @returns {HasParams}
 */
export const hasWhichParams: () => HasParams = () => {
    const currentParams = new URLSearchParams(window.location.search);
    const hasGroupedFacetParam = currentParams.has("groupedFacets");
    const hasFacetParam = currentParams.has("facets");
    const hasParams = {
        hasGroupedFacetParam,
        hasFacetParam,
    };
    return hasParams;
};

export const mergeGroupedFacets = (
    facetCache: React.MutableRefObject<Record<string, Array<Facet>>>,
    comparisonObject: any,
) => {
    Object.entries(comparisonObject).forEach(([key, data]) => {
        if ((data as any).isGroupedFacet) {
            const existingFacets = facetCache.current[key] || [];

            const updatedFacets = (data as any).items.map((newFacet) => {
                /** if facet exists in cache, return its index */
                const existingFacetIndex = existingFacets.findIndex(
                    (f) => f.value === newFacet.value,
                );

                if (existingFacetIndex >= 0) {
                    // Update existing facet
                    return {
                        ...existingFacets[existingFacetIndex],
                        isGroupedFacet: true,
                    };
                }
                return undefined;
            });

            // Combine updated facets with the existing ones, excluding the old versions of updated facets
            facetCache.current[key] = [
                ...existingFacets.filter(
                    (f) => !updatedFacets.some((uf) => uf.value === f.value),
                ),
                ...updatedFacets,
            ];
        }
    });
};

/** returns a string with the facet value for the odata query.
 *  @param value the value for the facet as a string or number.  This might need to expand to other types.
 *  @returns a quoted string for a string value and a unquoted string for a number. */
function getFacetValue(value: FacetValue): string {
    return typeof value === "string" ? `'${value}'` : `${value}`;
}

/** This function returns the facet object for the specified facet category and facet value.
 *  @param facetCategory a String with the facet category.
 *  @param facetValue a String or number with the facet value.
 *  @param facetMap the facet map.
 *  @param isCorrelation this specifies whether or not the result is from the correlation engine using 
 *      the FacetSummary format.
 *  @returns a reference to the Facet that was found or undefined. */
function getFacet(
    facetCategory: string, facetValue: FacetValue, facetMap: Record<string, Facet[] | FacetSummary> | undefined, isCorrelation?: boolean
): Facet | undefined {
    if (facetCategory && facetMap && facetMap[facetCategory]) {
        const facetList: Facet[] = isCorrelation ? (facetMap[facetCategory] as FacetSummary).items : facetMap[facetCategory] as Facet[];
        for (const facet of facetList) {
            if (facetValue === facet.value) {
                return facet;
            }
        }
    }
    return undefined;
}

/** returns the string to be used in the facets parameter of the search request.
 *  @param facetCategory a String with the facet category.
 *  @param limitsByFacetCategory the facet value limits indexed by facet category. 
 *  @returns the string to be used in the facets parameter of the search request. */
export function getFacetRequestString(facetCategory: string, limitsByFacetCategory: Record<string, number>): string {
    return facetCategory + ",count:" + (limitsByFacetCategory[facetCategory] || DEFAULT_FACET_LIMIT);
}

/** returns the object to be used in the facets parameter of the search request.
 *  @param facetCategory a String with the facet category.
 *  @param limitsByFacetCategory the facet value limits indexed by facet category. 
 *  @returns the object to be used in the facets parameter of the search request. */
export function getFacetRequestObject(facetCategory: string | CustomPropertyGroupKey, limitsByFacetCategory: Record<string, number>, groupBy?: GroupByTypes): FacetVariables {
    return {name: facetCategory, skip: 0, top: (limitsByFacetCategory[facetCategory] || DEFAULT_FACET_LIMIT), count: true, groupBy,};
}

/** This function returns the list of table columns.
 *  @param type the type of search that the page is displaying.
 *  @param history the history object.
 *  @param searchPreferences the SearchPreference object with the user and default search preferences.
 *  @param setIsRunningCustomPropCheck set loading state for the table
 *  @param actions - callback for the more button.
 *  @param refreshSearch - a function used to refresh the search results list
 *  @param openScheduleRunbookModal - a function used to open the schedule runbook wizard
 *  @param authProfiles - an array containing the TPI auth profiles
 *  @param edges - an array containing the edges
 *  @param UnscheduleRunbook - a function used to remove a schedule runbook job
 *  @param SetRunbookScheduleJobStatus - a function used to change a schedule runbook job status
 *  @returns the Array of TableColumnDefs. */
export function getTableColumns(
    type: SEARCH_TYPE,
    history: any,
    searchPreferences: SearchPreference,
    setIsRunningCustomPropCheck: any,
    customProperties: CustomProperty[],
    actions?: any,
    refreshSearch?: Function,
    openScheduleRunbookModal?: Function,
    authProfiles?: ProfileInterface[],
    edges?: any,
    UnscheduleRunbook?: any,
    setRunbookScheduleJobEnabled?: any,
    setDialogState?: any,
    SetRunbookScheduleJobStatus?: any
  ): Array<TableColumnDef> {   
    let tableColumns: Array<TableColumnDef> = [];
    if (type === SEARCH_TYPE.incident) {
        tableColumns = [
            {
                Header: STRINGS.incidents.columns.priority,
                id: getPriorityIdForEngine(searchPreferences.srchEngine),
                accessor: (originalRow, rowIndex, row) => {
                    // There are some odd behaviors here, when the accessor is called to format the row it 
                    // gets originalRow, rowIndex, and row and originalRow has an attribute called priority.  When
                    // the accessor is called during filtering it only has originalRow and it does not have the 
                    // priority column, but rather has incident/priority
                    return originalRow.priority || originalRow[getPriorityIdForEngine(searchPreferences.srchEngine)];
                },
                headerClassName: "text-nowrap display-9",
                sortable: true,
                sortFunction: sortBasedOnPriority,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                filterControl: MultiSelectFilterControl,
                multiValueFilter: true,
                formatter: row => {
                    const runbookIsActive = row?.latestRunbookStatus === "IN_PROGRESS";
                    const ifActive = runbookIsActive ? "" : row?.priority.toUpperCase();
                    return row?.priority ? <PriorityLEDFormatter priority={ifActive} /> : ""
                },
            },
            {
                Header: STRINGS.incidents.columns.status,
                id: getStatusIdForEngine(searchPreferences.srchEngine),
                accessor: (originalRow, rowIndex, row) => {
                    // There are some odd behaviors here, when the accessor is called to format the row it 
                    // gets originalRow, rowIndex, and row and originalRow has an attribute called status.  When
                    // the accessor is called during filtering it only has originalRow and it does not have the 
                    // status column, but rather has incident/status
                    return originalRow.status || originalRow[getStatusIdForEngine(searchPreferences.srchEngine)];
                },
                className: "display-9",
                headerClassName: "text-nowrap display-9",
                sortable: true,
                sortFunction: sortBasedOnIncidentStatus,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                filterControl: MultiSelectFilterControl,
                multiValueFilter: true,
                formatter: row => row?.status ? INCIDENT_STATUS_TO_LABEL_MAP[row.status.toUpperCase()] || row.status : "",
            },
            {
                id: getDescriptionIdForEngine(searchPreferences.srchEngine),
                Header: STRINGS.incidents.columns.description,
                accessor: (originalRow, rowIndex, row) => {
                    // There are some odd behaviors here, when the accessor is called to format the row it 
                    // gets originalRow, rowIndex, and row and originalRow has an attribute called description.  When
                    // the accessor is called during filtering it only has originalRow and it does not have the 
                    // description column, but rather has incident/description
                    return originalRow.description || originalRow[getDescriptionIdForEngine(searchPreferences.srchEngine)];
                },
                headerClassName: "text-nowrap w-min-2-5 display-9",
                className: "display-9",
                sortable: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: row => row?.description ? <><Link
                        to={getURL(getURLPath("incident"), {[PARAM_NAME.incidentId]: row.id}, {replaceQueryParams: true})}
                        onClick={e => e.stopPropagation()}
                    >
                        {row.description}
                    </Link>
                    <WrapInTooltip tooltip={STRINGS.SEARCH.linkTooltip} >
                        <BpIcon icon={BpIconNames.SHARE as IconName} size={14} className="ml-2" style={{color: "#106ba3", cursor: "pointer", verticalAlign: "baseline"}} 
                            onClick={() => {
                                window.open(getURL(getURLPath("incident"), { [PARAM_NAME.incidentId]: row.id }, { replaceQueryParams: true }), "_blank");
                            }}
                        />
                    </WrapInTooltip>
                </>
                    : "",
            },
            {
                Header: STRINGS.incidents.columns.startedOn,
                id: getCreatedAtIdForEngine(searchPreferences.srchEngine),
                accessor: (originalRow, rowIndex, row) => {
                    // There are some odd behaviors here, when the accessor is called to format the row it 
                    // gets originalRow, rowIndex, and row and originalRow has an attribute called description.  When
                    // the accessor is called during filtering it only has originalRow and it does not have the 
                    // description column, but rather has incident/description
                    return originalRow.createdAt || originalRow[getCreatedAtIdForEngine(searchPreferences.srchEngine)];
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                className: "text-nowrap display-9",
                sortable: true,
                sortDescFirst: true,
                sortFunction: sortColumnWithTimeData,
                showFilter: SHOW_TABLE_FILTERS,
                filterControl: DateRangeFilterControl,
                filterFunction: (rows: any, [columnID]: [any], filterValue: any) => {
                    const [startDate, endDate] = Array.isArray(filterValue) ? filterValue : [filterValue];
                    if (startDate) {
                        let startDateToFilterWith, endDateToFilterWith;
                        // If only a single date is present, then filter to all rows in that date
                        if (!endDate) {
                            startDateToFilterWith = new Date(Number(startDate)).setHours(0, 0, 0, 0);
                            endDateToFilterWith = new Date(startDateToFilterWith).setHours(23, 59, 59, 999);
                        } else {
                            startDateToFilterWith = new Date(Number(startDate)).setHours(0, 0, 0, 0);
                            endDateToFilterWith = new Date(Number(endDate)).setHours(23, 59, 59, 999);
                        }
                        return rows.filter(row => {
                            const value = new Date(row.values[columnID]);
                            return Boolean(value >= startDateToFilterWith && value <= endDateToFilterWith);
                        });
                    } else {
                        return rows;
                    }
                },
                multiValueFilter: true,
                formatter: (row) => {
                    return row?.createdAt ? <ElapsedTimeFormatter
                        time={new Date(row.createdAt)}
                        showOriginal
                        showOriginalFirst
                        suffix={STRINGS.incidents.elapsedSuffix}
                        /> : "";
                },
            },
            {
                Header: STRINGS.incidents.columns.duration,
                id: "duration",
                headerClassName: "text-nowrap w-min-1 display-9",
                className: "display-9",
                accessor: "duration",
                sortable: !searchPreferences.serverSideSortingAndPagination,
                sortDescFirst: true,
                sortFunction: (a, b, columnId) => {
                    const durA = (a?.endTime === null || a?.endTime === undefined ? Date.now() / 1000 : (parseTimeFromDAL(a.endTime)?.getTime() || 0)) - (parseTimeFromDAL(a.generated)?.getTime() || 0);
                    const durB = (b?.endTime === null || b?.endTime === undefined ? Date.now() / 1000 : (parseTimeFromDAL(b.endTime)?.getTime() || 0)) - (parseTimeFromDAL(b.generated)?.getTime() || 0);
                    return durA > durB ? 1 : durA < durB ? -1 : 0;
                },
                formatter: (row) => {
                    return row?.isOngoing ?
                        <StatusLED size={SIZE.xs} label={STRINGS.incidents.ongoing} color={GENERAL_COLORS.success} iconClassName="throb" /> :
                        <ElapsedTimeFormatter
                            time={new Date(row?.endTime)}
                            compareToTime={new Date(row?.createdAt)}
                            showOriginal={false}
                            format="short"
                        />;
                },
            },
            {
                id: "impactedUsersCount",
                Header: STRINGS.incidents.columns.impactedUsers,
                headerClassName: "text-nowrap w-min-1 display-9",
                accessor: "impactedUsersCount",
                sortable: !searchPreferences.serverSideSortingAndPagination,
                sortDescFirst: true,
                sortFunction: (a, b, columnId) => {
                    const countA = a?.impactedUsers?.length || 0;
                    const countB = b?.impactedUsers?.length || 0;
                    return countA > countB ? 1 : countA < countB ? -1 : 0;
                },
                formatter: (row) => {
                    const impactedUsers = row?.impactedUsers;
                    const isRunbookNotInProgress = row?.latestRunbookStatus !== "IN_PROGRESS";
                    return impactedUsers && isRunbookNotInProgress
                        ? impactedUsers.length
                        : "";
                },
            },
            {
                Header: STRINGS.incidents.columns.impactedLocations,
                id: "impactedLocations",
                accessor: "impactedLocations",
                sortable: !searchPreferences.serverSideSortingAndPagination,
                sortDescFirst: true,
                sortFunction: (a, b, columnId) => {
                    const countA = a?.impactedLocations?.length || 0;
                    const countB = b?.impactedLocations?.length || 0;
                    return countA > countB ? 1 : countA < countB ? -1 : 0;
                },
                formatter: row => row?.impactedLocations ?
                    <ListWithOverflow overflowAfter={2} items={row.impactedLocations?.map(item => (item.name || "")) || []} /> :
                    ""
                ,
            },
            {
                Header: STRINGS.incidents.columns.impactedApplications,
                id: "impactedApplications",
                accessor: "impactedApplications",
                sortable: !searchPreferences.serverSideSortingAndPagination,
                sortDescFirst: true,
                sortFunction: (a, b, columnId) => {
                    const countA = a?.impactedApplications?.length || 0;
                    const countB = b?.impactedApplications?.length || 0;
                    return countA > countB ? 1 : countA < countB ? -1 : 0;
                },
                formatter: row => row?.impactedApplications ?
                    <ListWithOverflow overflowAfter={2} items={row.impactedApplications?.map(item => (item.name || "")) || []} /> :
                    ""
                ,
            },
            {
                id: "incidentRunbookStatus",
                Header: STRINGS.incidents.columns.runbookStatus,
                headerClassName: "text-nowrap w-min-1 display-9",
                accessor: "latestRunbookStatus",
                sortable: !searchPreferences.serverSideSortingAndPagination,
                sortDescFirst: true,
                formatter: (row) => {
                    const latestRunbookStatus = row?.latestRunbookStatus;
                    return <IncidentRunbookStatusIcon runbookStatus={latestRunbookStatus} />;
                },
            },
        ];
    } else if (type === SEARCH_TYPE.device) {
        tableColumns = [
            {
                Header: STRINGS.incidentSearch.columns.name,
                id: getDeviceSearchNameIdForEngine(searchPreferences.srchEngine),
                accessor: (originalRow, rowIndex, row) => {
                    return originalRow.name || originalRow[getDeviceSearchNameIdForEngine(searchPreferences.srchEngine)];
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: row => row?.name ? row.name : EMPTY_DISPLAY_VALUE,
            },
            {
                Header: STRINGS.incidentSearch.columns.ipaddr,
                id: getDeviceSearchIpAddressIdForEngine(searchPreferences.srchEngine),
                accessor: (originalRow, rowIndex, row) => {
                    return originalRow.ipaddr || originalRow[getDeviceSearchIpAddressIdForEngine(searchPreferences.srchEngine)];
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: row => row?.ipaddr ? row.ipaddr : EMPTY_DISPLAY_VALUE,
            },
            {
                Header: STRINGS.incidentSearch.columns.location,
                id: getDeviceSearchLocationIdForEngine(searchPreferences.srchEngine),
                accessor: (originalRow, rowIndex, row) => {
                    return originalRow.location?.name || originalRow[getDeviceSearchLocationIdForEngine(searchPreferences.srchEngine)];
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: false,
                formatter: row => row?.location?.name ? row.location.name : EMPTY_DISPLAY_VALUE,
            },
            {
                Header: STRINGS.incidentSearch.columns.model,
                id: "model",
                accessor: "model",
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: false,
                formatter: row => row?.model ? row.model : "",
            },
            {
                Header: STRINGS.incidentSearch.columns.osVersion,
                id: "osVersion",
                accessor: "osVersion",
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: false,
                formatter: row => row?.osVersion ? row.osVersion : "",
            },
            {
                Header: STRINGS.incidentSearch.columns.serialNumber,
                id: "serialNumber",
                accessor: "serialNumber",
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: false,
                formatter: row => row?.serialNumber ? row.serialNumber : "",
            },
            {
                Header: STRINGS.incidentSearch.columns.type,
                id: "type",
                accessor: "type",
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: false,
                formatter: row => row?.type ? row.type : "",
            },
            {
                Header: STRINGS.incidentSearch.columns.vendor,
                id: "vendor",
                accessor: "vendor",
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: false,
                formatter: row => row?.vendor ? row.vendor : "",
            },
            /* Bug 23605 
            {
                Header: STRINGS.incidentSearch.columns.elementType,
                id: "elementType",
                accessor: "elementType",
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: false,
                formatter: row => row?.elementType ? row.elementType : "",
            },
            */
            {
                Header: STRINGS.incidentSearch.columns.isGateway,
                id: "isGateway",
                accessor: "isGateway",
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: false,
                formatter: row => row?.isGateway === true ? "True" : "",
            },
            {
                Header: STRINGS.incidentSearch.columns.tags,
                id: "tags",
                accessor: "tags",
                headerClassName: "text-nowrap w-min-1 w-max-2 display-9",
                className: "w-min-1 w-max-2",
                sortable: false,
                sortDescFirst: true,
                showFilter: false,
                formatter: (row) => {
                    let values: {name: string, value: string}[] = searchPreferences.srchEngine === SEARCH_ENGINE.correlation_direct 
                        ? (row.customProperties || []).map(item => {return {name: item?.name, value: item?.valueName}})
                        : (row.customProperties || []).map(item => {return {name: item?.name, value: item?.assignedValue?.name}});
                    return <>{values.map((item, index) => <WrapInTooltip key={item.name + index} tooltip={item.name + ": " + item.value}><span key={"tag" + (index + 1)} className={"display-9 custom-property-bubble color" + ((index % 5) + 1)}>{item.value}</span></WrapInTooltip>)}</>;
                },
            },
            {
                Header: STRINGS.incidentSearch.columns.dataSourceName,
                id: "dataSourceName",
                accessor: (originalRow, rowIndex, row) => {
                    return row?.reportedBy;
                },
                headerClassName: "text-nowrap w-min-1 w-max-2 display-9",
                className: "w-min-1 w-max-2",
                sortable: false,
                sortDescFirst: true,
                showFilter: false,
                formatter: (row) => {
                    /** source data type */
                    const tds = getDataSourceTypeSources();
                    /** data source */
                    const dst = dataSourceTypeNameZipper(tds, row?.reportedBy);
                    return <ListWithOverflow overflowAfter={2} items={dst.dataSourceNames} />
                },
            },
            {
                Header: STRINGS.incidentSearch.columns.dataSourceType,
                id: "dataSourceType",
                accessor: (originalRow, rowIndex, row) => {
                    return row?.reportedBy?.dataSourceType;
                },
                headerClassName: "text-nowrap w-min-1 w-max-2 display-9",
                className: "w-min-1 w-max-2",
                sortable: false,
                sortDescFirst: true,
                showFilter: false,
                formatter: (row) => {
                    /** source data type */
                    const tds = getDataSourceTypeSources();
                    /** data source */
                    const dst = dataSourceTypeNameZipper(tds, row?.reportedBy);
                    return <ListWithOverflow overflowAfter={2} items={dst.dataSourceTypes} />
                },
            },
        ];
    } else if (type === SEARCH_TYPE.interface) {
        const ifIndexKey: string = "ifIndex";
        tableColumns = [
            {
                Header: STRINGS.incidentSearch.columns.name,
                id: getInterfaceSearchNameIdForEngine(searchPreferences.srchEngine),
                accessor: (originalRow, rowIndex, row) => {
                    return originalRow.name || originalRow[getInterfaceSearchNameIdForEngine(searchPreferences.srchEngine)];
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: row => row?.name ? row.name : EMPTY_DISPLAY_VALUE,
            },
            {
                Header: STRINGS.incidentSearch.columns.ipaddr,
                id: getInterfaceSearchIpAddressIdForEngine(searchPreferences.srchEngine),
                accessor: (originalRow, rowIndex, row) => {
                    return originalRow.ipaddr || originalRow[getInterfaceSearchIpAddressIdForEngine(searchPreferences.srchEngine)];
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: row => row?.ipaddr ? row.ipaddr : EMPTY_DISPLAY_VALUE,
            },
            {
                Header: STRINGS.incidentSearch.columns.ifindex,
                id: getInterfaceSearchIfIndexIdForEngine(searchPreferences.srchEngine),
                accessor: (originalRow, rowIndex, row) => {
                    return originalRow.ifindex || originalRow[getInterfaceSearchIfIndexIdForEngine(searchPreferences.srchEngine)];
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: false,
                formatter: row => row && row[ifIndexKey] ? row[ifIndexKey] : EMPTY_DISPLAY_VALUE,
            },
            {
                Header: STRINGS.incidentSearch.columns.ifAlias,
                id: "ifAlias",
                accessor: "ifAlias",
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: row => row?.ifAlias ? row.ifAlias : "",
            },
            {
                Header: STRINGS.incidentSearch.columns.ifDescription,
                id: "ifDescription",
                accessor: "ifDescription",
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: row => row?.ifDescription ? row.ifDescription : "",
            },
            {
                Header: STRINGS.incidentSearch.columns.type,
                id: "type",
                accessor: "type",
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: false,
                formatter: row => row?.type ? row.type : "",
            },
            /* Bug 23605
            {
                Header: STRINGS.incidentSearch.columns.elementType,
                id: "elementType",
                accessor: "elementType",
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: false,
                formatter: row => row?.elementType ? row.elementType : "",
            },
            */
            {
                Header: STRINGS.incidentSearch.columns.location,
                id: getInterfaceSearchLocationIdForEngine(searchPreferences.srchEngine),
                accessor: (originalRow, rowIndex, row) => {
                    return originalRow.location?.name || originalRow[getInterfaceSearchLocationIdForEngine(searchPreferences.srchEngine)];
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: false,
                formatter: row => row?.location?.name ? row.location.name : EMPTY_DISPLAY_VALUE,
            },
            {
                Header: STRINGS.incidentSearch.columns.inboundSpeed,
                id: "inboundSpeed",
                accessor: "inboundSpeed",
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: row => {
                    const unit = Unit.parseUnit("bps");
                    let value: any = row?.inboundSpeed !== undefined && row?.inboundSpeed !== null ? row.inboundSpeed : undefined;
                    if (value !== undefined) {
                        const result = unit.getScaledUnit(value);
                        value = value / result.scale + " " + result.unit.getDisplayName();        
                    }
                    return value === undefined ? "" : value;
                },
            },
            {
                Header: STRINGS.incidentSearch.columns.outboundSpeed,
                id: "outboundSpeed",
                accessor: "outboundSpeed",
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: row => {
                    const unit = Unit.parseUnit("bps");
                    let value: any = row?.outboundSpeed !== undefined && row?.outboundSpeed !== null ? row.outboundSpeed : undefined;
                    if (value !== undefined) {
                        const result = unit.getScaledUnit(value);
                        value = value / result.scale + " " + result.unit.getDisplayName();        
                    }
                    return value === undefined ? "" : value;
                },
            },
            {
                Header: STRINGS.incidentSearch.columns.tags,
                id: "tags",
                accessor: "tags",
                headerClassName: "text-nowrap w-min-1 w-max-2 display-9",
                className: "w-min-1 w-max-2",
                sortable: false,
                sortDescFirst: true,
                showFilter: false,
                formatter: (row) => {
                    let values: {name: string, value: string}[] = searchPreferences.srchEngine === SEARCH_ENGINE.correlation_direct 
                        ? (row.customProperties || []).map(item => {return {name: item?.name, value: item?.valueName}})
                        : (row.customProperties || []).map(item => {return {name: item?.name, value: item?.assignedValue?.name}});
                    return <>{values.map((item, index) => <WrapInTooltip key={item.name + index} tooltip={item.name + ": " + item.value}><span key={"tag" + (index + 1)} className={"display-9 custom-property-bubble color" + ((index % 5) + 1)}>{item.value}</span></WrapInTooltip>)}</>;
                },
            },
            {
                Header: STRINGS.incidentSearch.columns.dataSourceName,
                id: "dataSourceName",
                accessor: (originalRow, rowIndex, row) => {
                    return row?.reportedBy;
                },
                headerClassName: "text-nowrap w-min-1 w-max-2 display-9",
                className: "w-min-1 w-max-2",
                sortable: false,
                sortDescFirst: true,
                showFilter: false,
                formatter: (row) => {
                    /** source data type */
                    const tds = getDataSourceTypeSources();
                    /** data source */
                    const dst = dataSourceTypeNameZipper(tds, row?.reportedBy);
                    return <ListWithOverflow overflowAfter={2} items={dst.dataSourceNames} />
                },
            },
            {
                Header: STRINGS.incidentSearch.columns.dataSourceType,
                id: "dataSourceType",
                accessor: (originalRow, rowIndex, row) => {
                    return row?.reportedBy?.dataSourceType;
                },
                headerClassName: "text-nowrap w-min-1 w-max-2 display-9",
                className: "w-min-1 w-max-2",
                sortable: false,
                sortDescFirst: true,
                showFilter: false,
                formatter: (row) => {
                    /** source data type */
                    const tds = getDataSourceTypeSources();
                    /** data source */
                    const dst = dataSourceTypeNameZipper(tds, row?.reportedBy);
                    return <ListWithOverflow overflowAfter={2} items={dst.dataSourceTypes} />
                },
            },
        ];
    } else if (type === SEARCH_TYPE.application) {
        tableColumns = [
            {
                Header: STRINGS.incidentSearch.columns.name,
                id: getApplicationSearchNameIdForEngine(searchPreferences.srchEngine),
                accessor: (originalRow, rowIndex, row) => {
                    return originalRow.name || originalRow[getApplicationSearchNameIdForEngine(searchPreferences.srchEngine)];
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: row => row?.name ? row.name : EMPTY_DISPLAY_VALUE,
            },
            {
                Header: STRINGS.incidentSearch.columns.tags,
                id: "tags",
                accessor: "tags",
                headerClassName: "text-nowrap w-min-1 w-max-2 display-9",
                className: "w-min-1 w-max-2",
                sortable: false,
                sortDescFirst: true,
                showFilter: false,
                formatter: (row) => {
                    let values: {name: string, value: string}[] = searchPreferences.srchEngine === SEARCH_ENGINE.correlation_direct 
                        ? (row.customProperties || []).map(item => {return {name: item?.name, value: item?.valueName}})
                        : (row.customProperties || []).map(item => {return {name: item?.name, value: item?.assignedValue?.name}});
                    return <>{values.map((item, index) => <WrapInTooltip key={item.name + index} tooltip={item.name + ": " + item.value}><span key={"tag" + (index + 1)} className={"display-9 custom-property-bubble color" + ((index % 5) + 1)}>{item.value}</span></WrapInTooltip>)}</>;
                },
            },
            {
                Header: STRINGS.incidentSearch.columns.dataSourceName,
                id: "dataSourceName",
                accessor: (originalRow, rowIndex, row) => {
                    return row?.reportedBy;
                },
                headerClassName: "text-nowrap w-min-1 w-max-2 display-9",
                className: "w-min-1 w-max-2",
                sortable: false,
                sortDescFirst: true,
                showFilter: false,
                formatter: (row) => {
                    /** source data type */
                    const tds = getDataSourceTypeSources();
                    /** data source */
                    const dst = dataSourceTypeNameZipper(tds, row?.reportedBy);
                    return <ListWithOverflow overflowAfter={2} items={dst.dataSourceNames} />
                },
            },
            {
                Header: STRINGS.incidentSearch.columns.dataSourceType,
                id: "dataSourceType",
                accessor: (originalRow, rowIndex, row) => {
                    return row?.reportedBy?.dataSourceType;
                },
                headerClassName: "text-nowrap w-min-1 w-max-2 display-9",
                className: "w-min-1 w-max-2",
                sortable: false,
                sortDescFirst: true,
                showFilter: false,
                formatter: (row) => {
                    /** source data type */
                    const tds = getDataSourceTypeSources();
                    /** data source */
                    const dst = dataSourceTypeNameZipper(tds, row?.reportedBy);
                    return <ListWithOverflow overflowAfter={2} items={dst.dataSourceTypes} />
                },
            },
        ];
    } else if (type === SEARCH_TYPE.location) {
        tableColumns = [
            {
                Header: STRINGS.incidentSearch.columns.name,
                id: getLocationSearchNameIdForEngine(searchPreferences.srchEngine),
                accessor: (originalRow, rowIndex, row) => {
                    return originalRow.name || originalRow[getLocationSearchNameIdForEngine(searchPreferences.srchEngine)];
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: row => row?.name ? row.name : EMPTY_DISPLAY_VALUE,
            },
            {
                Header: STRINGS.incidentSearch.columns.locationType,
                id: getLocationSearchTypeIdForEngine(searchPreferences.srchEngine),
                accessor: (originalRow, rowIndex, row) => {
                    return originalRow.type || originalRow[getLocationSearchTypeIdForEngine(searchPreferences.srchEngine)];
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: row => row?.type ? row.type : EMPTY_DISPLAY_VALUE,
            },
            {
                Header: STRINGS.incidentSearch.columns.city,
                id: getLocationSearchCityIdForEngine(searchPreferences.srchEngine),
                accessor: (originalRow, rowIndex, row) => {
                    return originalRow.geo?.city || originalRow.city || originalRow[getLocationSearchCityIdForEngine(searchPreferences.srchEngine)];
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: false,
                formatter: row => row?.geo?.city ? row.geo.city : row.city ? row.city : EMPTY_DISPLAY_VALUE,
            },
            {
                Header: STRINGS.incidentSearch.columns.state,
                id: getLocationSearchStateIdForEngine(searchPreferences.srchEngine),
                accessor: (originalRow, rowIndex, row) => {
                    return originalRow.geo?.state || originalRow.state || originalRow[getLocationSearchStateIdForEngine(searchPreferences.srchEngine)];
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: false,
                formatter: row => row?.geo?.state ? row.geo.state : row.state ? row.state : EMPTY_DISPLAY_VALUE,
            },
            {
                Header: STRINGS.incidentSearch.columns.country,
                id: getLocationSearchCountryIdForEngine(searchPreferences.srchEngine),
                accessor: (originalRow, rowIndex, row) => {
                    return originalRow.geo?.country || originalRow.country || originalRow[getLocationSearchCountryIdForEngine(searchPreferences.srchEngine)];
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: false,
                formatter: row => row?.geo?.country ? row.geo.country : row.country ?  row.country : EMPTY_DISPLAY_VALUE,
            },
            {
                Header: STRINGS.incidentSearch.columns.tags,
                id: "tags",
                accessor: "tags",
                headerClassName: "text-nowrap w-min-1 w-max-2 display-9",
                className: "w-min-1 w-max-2",
                sortable: false,
                sortDescFirst: true,
                showFilter: false,
                formatter: (row) => {
                    let values: {name: string, value: string}[] = searchPreferences.srchEngine === SEARCH_ENGINE.correlation_direct 
                        ? (row.customProperties || []).map(item => {return {name: item?.name, value: item?.valueName}})
                        : (row.customProperties || []).map(item => {return {name: item?.name, value: item?.assignedValue?.name}});
                    return <>{values.map((item, index) => <WrapInTooltip key={item.name + index} tooltip={item.name + ": " + item.value}><span key={"tag" + (index + 1)} className={"display-9 custom-property-bubble color" + ((index % 5) + 1)}>{item.value}</span></WrapInTooltip>)}</>;
                },
            },
            {
                Header: STRINGS.incidentSearch.columns.dataSourceName,
                id: "dataSourceName",
                accessor: (originalRow, rowIndex, row) => {
                    return row?.reportedBy;
                },
                headerClassName: "text-nowrap w-min-1 w-max-2 display-9",
                className: "w-min-1 w-max-2",
                sortable: false,
                sortDescFirst: true,
                showFilter: false,
                formatter: (row) => {
                    /** source data type */
                    const tds = getDataSourceTypeSources();
                    /** data source */
                    const dst = dataSourceTypeNameZipper(tds, row?.reportedBy);
                    return <ListWithOverflow overflowAfter={2} items={dst.dataSourceNames} />
                },
            },
            {
                Header: STRINGS.incidentSearch.columns.dataSourceType,
                id: "dataSourceType",
                accessor: (originalRow, rowIndex, row) => {
                    return row?.reportedBy?.dataSourceType;
                },
                headerClassName: "text-nowrap w-min-1 w-max-2 display-9",
                className: "w-min-1 w-max-2",
                sortable: false,
                sortDescFirst: true,
                showFilter: false,
                formatter: (row) => {
                    /** source data type */
                    const tds = getDataSourceTypeSources();
                    /** data source */
                    const dst = dataSourceTypeNameZipper(tds, row?.reportedBy);
                    return <ListWithOverflow overflowAfter={2} items={dst.dataSourceTypes} />
                },
            },
        ];
    } else if (type === SEARCH_TYPE.tcpconnection) {
        tableColumns = [
            {
                Header: STRINGS.incidentSearch.columns.time,
                id: "time",
                accessor: (originalRow, rowIndex, row) => {
                    return originalRow.time || originalRow["time"];
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: (row) => {
                    return row?.time
                        ? "Unknown The Next Code Is Not A String" ///formatUnixTimestamp(row.time)
                        : EMPTY_DISPLAY_VALUE;
                },
            },
            {
                Header: STRINGS.incidentSearch.columns.client,
                id: "client",
                accessor: (originalRow, rowIndex, row) => {
                    return (
                        originalRow.client?.ipAddress || originalRow["client"]
                    );
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: false,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: (row) =>
                    row?.client?.ipAddress
                        ? row.client?.ipAddress
                        : EMPTY_DISPLAY_VALUE,
            },
            {
                Header: STRINGS.incidentSearch.columns.server,
                id: "server",
                accessor: (originalRow, rowIndex, row) => {
                    return (
                        originalRow.server?.ipAddress || originalRow["server"]
                    );
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: false,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: (row) =>
                    row?.server?.ipAddress
                        ? row.server?.ipAddress
                        : EMPTY_DISPLAY_VALUE,
            },
            {
                Header: STRINGS.incidentSearch.columns.serverPort,
                id: "port",
                accessor: (originalRow, rowIndex, row) => {
                    return originalRow.server?.port || originalRow["port"];
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: false,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: (row) =>
                    row?.server?.port ? row.server?.port : EMPTY_DISPLAY_VALUE,
            },
            {
                Header: STRINGS.incidentSearch.columns.osVersion,
                id: "operatingSystem",
                accessor: (originalRow, rowIndex, row) => {
                    return (
                        originalRow.operatingSystem?.type ||
                        originalRow["operatingSystem"]
                    );
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: false,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: (row) =>
                    row?.operatingSystem?.type
                        ? row.operatingSystem?.type
                        : EMPTY_DISPLAY_VALUE,
            },
            {
                Header: STRINGS.incidentSearch.columns.bytes,
                id: "server2Client",
                accessor: (originalRow, rowIndex, row) => {
                    return (
                        originalRow?.server2Client?.traffic?.bytes ||
                        originalRow["server2Client"]
                    );
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: false,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: (row) =>
                    row?.server2Client?.traffic?.bytes
                        ? row?.server2Client?.traffic?.bytes
                        : EMPTY_DISPLAY_VALUE,
            },
            {
                Header: STRINGS.incidentSearch.columns.retrans,
                id: "retransmittedPercent",
                accessor: (originalRow, rowIndex, row) => {
                    return (
                        originalRow?.retransmittedPercent ||
                        originalRow["retransmittedPercent"]
                    );
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: false,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: (row) =>
                    !Number.isNaN(row?.retransmittedPercent)
                        ? row?.retransmittedPercent
                        : EMPTY_DISPLAY_VALUE,
            },
            {
                Header: STRINGS.incidentSearch.columns.rtt,
                id: "roundTripTime",
                accessor: (originalRow, rowIndex, row) => {
                    return (
                        originalRow?.roundTripTime ||
                        originalRow["roundTripTime"]
                    );
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: false,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: (row) =>
                    !Number.isNaN(row?.roundTripTime)
                        ? row?.roundTripTime
                        : EMPTY_DISPLAY_VALUE,
            },
            {
                Header: STRINGS.incidentSearch.columns.serverResponseTime,
                id: "responseTime",
                accessor: (originalRow, rowIndex, row) => {
                    return (
                        originalRow?.server?.responseTime ||
                        originalRow["responseTime"]
                    );
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: false,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: (row) =>
                    !Number.isNaN(row?.server?.responseTime)
                        ? row?.server?.responseTime
                        : EMPTY_DISPLAY_VALUE,
            },
        ];
    } else if (type === SEARCH_TYPE.properties) {
            tableColumns = [
            {
                Header: STRINGS.incidentSearch.columns.name,
                id: getCustomPropertySearchNameIdForEngine(searchPreferences.srchEngine),
                accessor: (originalRow, rowIndex, row) => {
                    return originalRow.name || originalRow[getCustomPropertySearchNameIdForEngine(searchPreferences.srchEngine)];
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: row => {
                    const propName = row?.name ? row.name : EMPTY_DISPLAY_VALUE;
                    return row?.isDeleted ? <span className="deleted-item">{propName}</span> : propName
                },
            },
            {
                Header: STRINGS.incidentSearch.columns.description,
                id: getCustomPropertySearchDescriptionIdForEngine(searchPreferences.srchEngine),
                accessor: (originalRow, rowIndex, row) => {
                    return originalRow.description || originalRow[getCustomPropertySearchDescriptionIdForEngine(searchPreferences.srchEngine)];
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: row => row?.description ? row.description : EMPTY_DISPLAY_VALUE,
            },
            {
                Header: STRINGS.incidentSearch.columns.taggedLocationCount,
                id: "taggedLocationCount",
                accessor: "taggedLocationCount",
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: false,
                sortDescFirst: true,
                showFilter: false,
                formatter: row => row?.taggedLocationCount ? row.taggedLocationCount : EMPTY_DISPLAY_VALUE,
            },
            {
                Header: STRINGS.incidentSearch.columns.taggedDeviceCount,
                id: "taggedDeviceCount",
                accessor: "taggedDeviceCount",
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: false,
                sortDescFirst: true,
                showFilter: false,
                formatter: row => row?.taggedNetworkDeviceCount ? row.taggedNetworkDeviceCount : EMPTY_DISPLAY_VALUE,
            },
            {
                Header: STRINGS.incidentSearch.columns.taggedInterfaceCount,
                id: "taggedInterfaceCount",
                accessor: "taggedInterfaceCount",
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: false,
                sortDescFirst: true,
                showFilter: false,
                formatter: row => row?.taggedNetworkInterfaceCount ? row.taggedNetworkInterfaceCount : EMPTY_DISPLAY_VALUE,
            },
            {
                Header: STRINGS.incidentSearch.columns.taggedApplicationCount,
                id: "taggedApplicationCount",
                accessor: "taggedApplicationCount",
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: false,
                sortDescFirst: true,
                showFilter: false,
                formatter: row => row?.taggedApplicationCount ? row.taggedApplicationCount : EMPTY_DISPLAY_VALUE,
            },
            {
                Header: STRINGS.incidentSearch.columns.lastUpdatedAt,
                id: getCustomPropertySearchLastUpdatedAtIdForEngine(searchPreferences.srchEngine),
                accessor: (originalRow, rowIndex, row) => {
                    return originalRow.lastUpdatedAt || originalRow[getCustomPropertySearchLastUpdatedAtIdForEngine(searchPreferences.srchEngine)];
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                sortFunction: sortColumnWithTimeData,
                showFilter: SHOW_TABLE_FILTERS,
                filterControl: DateRangeFilterControl,
                filterFunction: (rows: any, [columnID]: [any], filterValue: any) => {
                    const [startDate, endDate] = Array.isArray(filterValue) ? filterValue : [filterValue];
                    if (startDate) {
                        let startDateToFilterWith, endDateToFilterWith;
                        // If only a single date is present, then filter to all rows in that date
                        if (!endDate) {
                            startDateToFilterWith = new Date(Number(startDate)).setHours(0, 0, 0, 0);
                            endDateToFilterWith = new Date(startDateToFilterWith).setHours(23, 59, 59, 999);
                        } else {
                            startDateToFilterWith = new Date(Number(startDate)).setHours(0, 0, 0, 0);
                            endDateToFilterWith = new Date(Number(endDate)).setHours(23, 59, 59, 999);
                        }
                        return rows.filter(row => {
                            const value = new Date(row.values[columnID]);
                            return Boolean(value >= startDateToFilterWith && value <= endDateToFilterWith);
                        });
                    } else {
                        return rows;
                    }
                },
                multiValueFilter: true,
                formatter: (row) => {
                    if (searchPreferences.srchEngine === SEARCH_ENGINE.correlation_dal) {
                        return row?.lastUpdatedAt ? <ElapsedTimeFormatter
                            time={parseTimeFromDAL(row.lastUpdatedAt)}
                            showOriginal
                            showOriginalFirst
                            suffix={STRINGS.incidents.elapsedSuffix}
                            /> : "";
                    } else {
                        return row?.lastUpdatedAt ? <ElapsedTimeFormatter
                            time={new Date(row.lastUpdatedAt)}
                            showOriginal
                            showOriginalFirst
                            suffix={STRINGS.incidents.elapsedSuffix}
                            /> : "";
                    }
                }
            },
            {
                Header: STRINGS.incidentSearch.columns.lastUpdatedBy,
                id: "lastUpdatedBy",
                accessor: "lastUpdatedBy",
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: false,
                sortDescFirst: true,
                showFilter: false,
                formatter: row => row?.user?.name ? row.user.name : EMPTY_DISPLAY_VALUE,
/*            
            },
            {
                Header: STRINGS.incidentSearch.columns.propertyTypes,
                id: `${propertyKey}/validTypes`,
                accessor: (originalRow, rowIndex, row) => {
                    return originalRow.validTypes || originalRow[`${propertyKey}/validTypes`];
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: false,
                formatter: row => {
                    const types: string[] = [];
                    if (row?.validTypes?.length) {
                        for (const type of row.validTypes) {
                            types.push(type.type);
                        }
                    }
                    return types.join(", ");
                },
            },
            {
                Header: STRINGS.incidentSearch.columns.propertyValues,
                id: `${propertyKey}/values`,
                accessor: (originalRow, rowIndex, row) => {
                    return originalRow.values || originalRow[`${propertyKey}/values`];
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: false,
                sortDescFirst: true,
                showFilter: false,
                formatter: (row) => {
                    let values: string[] = (row.values || []).map(item => item?.name);
                    return <>{values.map((item, index) => <span key={"tag" + (index + 1)} className={"display-9 custom-property-bubble color" + ((index % 5) + 1)}>{item}</span>)}</>;
                }
*/
            }
        ];
    } else if (type === SEARCH_TYPE.ondemandrunbooks) {
        tableColumns = [
            {
                Header: STRINGS.incidentSearch.columns.runbookAnalysis,
                id: "title",
                accessor: (originalRow, rowIndex, row) => {
                    return originalRow?.title || originalRow["title"];
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: (row) => 
                    row?.title ? formatOnDemandRunbooksNameCell(row) : EMPTY_DISPLAY_VALUE
            },
            {
                Header: STRINGS.incidentSearch.columns.runbook,
                id: "name",
                accessor: (originalRow) => {
                    return originalRow?.name || originalRow["name"];
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: (row) =>
                    row?.name ? row?.name : EMPTY_DISPLAY_VALUE,
            },
            {
                Header: STRINGS.incidentSearch.columns.entityKind,
                id: "entityType",
                accessor: (originalRow) => {
                    return (
                        originalRow?.entityType || originalRow["entityType"]
                    );
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: (row) =>
                    row?.entityType ? row.entityType === "webhook" ? "N/A" : row.entityType : EMPTY_DISPLAY_VALUE,
            },
            {
                Header: STRINGS.incidentSearch.columns.executionMethod,
                id: "executionMethod",
                accessor: (originalRow) => {
                    return (
                        originalRow?.executionMethod || originalRow["executionMethod"]
                    );
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: (row) =>
                    row?.executionMethod ? row?.executionMethod : EMPTY_DISPLAY_VALUE,
            },
            {
                Header: STRINGS.incidentSearch.columns.time,
                id: "timestamp",
                accessor: (originalRow) => {
                    return originalRow?.timestamp || originalRow["timestamp"];
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                sortFunction: sortColumnWithTimeData,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: (row) => {
                    return (
                        <RelativeTimeCell time={row?.timestamp} />
                    );
                },
            },
            {
                Header: STRINGS.incidentSearch.columns.executedBy,
                id: "executedBy",
                accessor: (originalRow, rowIndex, row) => {
                    return originalRow?.createdBy || originalRow["name"];
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: false,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: (row) => (row?.createdBy ? row?.createdBy : EMPTY_DISPLAY_VALUE),
            },
            {
                Header: STRINGS.incidentSearch.columns.status,
                id: "status",
                accessor: (originalRow) => {
                    return originalRow?.status || originalRow["status"];
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: (row) => {
                    const latestRunbookStatus = row?.status;
                    return <TableCellRunbookStatusIcon
                                key={uuidv4()}
                                runbookStatus={latestRunbookStatus}
                                rowInfos={row}
                                refreshSearch={refreshSearch}
                            />;
                },
            },
        ];
    } else if (type === SEARCH_TYPE.runbookschedules) {
        tableColumns = [
            {
                Header: STRINGS.runbookSchedules.columns.runbook,
                id: "runbookName",
                accessor: (originalRow) => {
                    return originalRow?.runbook.name;
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: row => {
                    return row?.runbook.name ? row?.runbook.name: EMPTY_DISPLAY_VALUE
                },
            },
            {
                Header: STRINGS.runbookSchedules.columns.scheduleDescription,
                id: "scheduleDescription",
                accessor: (originalRow) => {
                    return (
                        originalRow?.schedule?.description
                    );
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                sortDescFirst: true,
                showFilter: SHOW_TABLE_FILTERS,
                formatter: (row) => {
                    return row?.schedule?.description ? row?.schedule?.description : EMPTY_DISPLAY_VALUE
                },
            },
            {
                Header: STRINGS.runbookSchedules.columns.schedule,
                id: "scheduleName",
                accessor: (originalRow) => {
                    return (
                        originalRow?.schedule?.name
                    );
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                showFilter: false,
                formatter: (row) => {
                    return row?.schedule?.name ? row?.schedule?.name : EMPTY_DISPLAY_VALUE
                },
            },
            // {
            //     Header: STRINGS.runbookSchedules.columns.frequency,
            //     id: "frequency",
            //     accessor: "frequency",
            //     headerClassName: "text-nowrap w-min-1 display-9",
            //     sortable: false,
            //     showFilter: false,
            // },
            // {
            //     id: "createdBy",
            //     Header: STRINGS.runbookSchedules.columns.createdBy,
            //     accessor: "createdByUser",
            //     headerClassName: "text-nowrap w-min-1 display-9",
            //     showFilter: true,
            //     filterControl: AutocompleteColumnFilterControl,
            // },
            // {
            //     id: "createdOn",
            //     Header: STRINGS.runbookSchedules.columns.createdOn,
            //     accessor: "createdOn",
            //     headerClassName: "text-nowrap w-min-1 display-9",
            //     formatter: row => (row.createdOn ? <ElapsedTimeFormatter time={row.createdOn} showOriginal suffix={STRINGS.thirdPartyIntegrations.elapsedSuffix} /> : ""),
            // },
            // {
            //     id: "lastUpdatedBy",
            //     Header: STRINGS.runbookSchedules.columns.lastUpdatedBy,
            //     accessor: "lastUpdatedBy",
            //     headerClassName: "text-nowrap w-min-1 display-9",
            // },
            // {
            //     id: "lastUpdatedOn",
            //     Header: STRINGS.runbookSchedules.columns.lastUpdatedOn,
            //     accessor: "lastUpdatedOn",
            //     headerClassName: "text-nowrap w-min-1 display-9",
            //     formatter: row => (row.lastUpdatedOn ? <ElapsedTimeFormatter time={row.lastUpdatedOn} showOriginal suffix={STRINGS.thirdPartyIntegrations.elapsedSuffix} /> : ""),
            // },
            {
                Header: STRINGS.runbookSchedules.columns.inputs,
                id: "inputs",
                accessor: (originalRow) => {
                    return (
                        originalRow?.details?.runbook?.inputs
                    );
                },
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: false,
                showFilter: false,
                formatter: getInputColumn(authProfiles, edges)
            },
            {
                Header: STRINGS.runbookSchedules.columns.nextRun,
                id: "nextRunUtc",
                accessor: "nextRunUtc",
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                showFilter: false,
                formatter: row => 
                    <ElapsedTimeFormatter
                        time={parseTimeFromDAL(row.nextRun)}
                        compareToTime={new Date()}
                        showOriginal
                        showRange={false}
                        prefix={"in"}
                    />,
            },
            {
                Header: STRINGS.runbookSchedules.columns.enabled,
                id: "enabled",
                accessor: "enabled",
                headerClassName: "text-nowrap w-min-1 display-9",
                sortable: true,
                showFilter: false,
                alwaysShowFilter: true,
                formatter: row => {
                    return  (
                        <Switch
                            checked={row.enabled}
                            className="mb-0"
                            innerLabel={
                                row.enabled
                                    ? STRINGS.runbookSchedules.columns.enabledOnBtn
                                    : STRINGS.runbookSchedules.columns.enabledOffBtn
                            }
                            onChange={
                                () => setRunbookScheduleJobEnabled(
                                    row.runbook.id,
                                    row.schedule.name,
                                    row.enabled,
                                    setDialogState,
                                    SetRunbookScheduleJobStatus,
                                    refreshSearch
                                )
                            }
                        />
                    )
                }
            },
        ];
    }

    // NOTE: only retrieve custom properties for certain routes/exporer types
    type !== SEARCH_TYPE.incident &&
        type !== SEARCH_TYPE.tcpconnection &&
        type !== SEARCH_TYPE.ondemandrunbooks &&
        customProperties &&
        customProperties.length > 0 &&
        customProperties.forEach((item) => {
            if (item.types.find((item) => item.indexOf(type) > -1)) {
                tableColumns.push({
                    Header: item.name,
                    id: item.name,
                    accessor: item.name,
                    headerClassName: "text-nowrap w-min-1 w-max-2 display-9",
                    className: "w-min-1 w-max-2",
                    style: {wordBreak: 'break-word'},
                    sortable: false,
                    sortDescFirst: true,
                    showFilter: false,
                    formatter: (row) => {
                        return row[item.name] ? <span key={row.name} className={"display-9 custom-property-bubble color1"}>{row[item.name]}</span> : '';
                    },
                });
            }
        });

    if (![SEARCH_TYPE.incident, SEARCH_TYPE.tcpconnection, SEARCH_TYPE.ondemandrunbooks].includes(type)) {
        // Add the more column so we can search
        let facetForNameField: FACET_FIELDS | undefined = undefined;
        switch (searchPreferences.srchEngine) {
            case SEARCH_ENGINE.correlation_direct:
                switch (type) {
                    case SEARCH_TYPE.device:
                        facetForNameField = FACET_FIELDS.incidentsIndicatorsDevName;
                        break;
                    case SEARCH_TYPE.interface:
                        facetForNameField = FACET_FIELDS.incidentsIndicatorsIfcName;
                        break;
                    case SEARCH_TYPE.application:
                        facetForNameField = FACET_FIELDS.incidentsIndicatorsAppLocAppName;
                        break;
                    case SEARCH_TYPE.location:
                        facetForNameField = FACET_FIELDS.incidentsIndicatorsAppLocLocName;
                        break;
                }
                break;
            case SEARCH_ENGINE.correlation_dal:
                switch (type) {
                    case SEARCH_TYPE.device:
                        facetForNameField = FACET_FIELDS.DEVICE_NAME;
                        break;
                    case SEARCH_TYPE.interface:
                        facetForNameField = FACET_FIELDS.INTERFACE_NAME;
                        break;
                    case SEARCH_TYPE.application:
                        facetForNameField = FACET_FIELDS.APPLICATION_NAME;
                        break;
                    case SEARCH_TYPE.location:
                        facetForNameField = FACET_FIELDS.APPLICATION_LOCATION_NAME;
                        break;
                    case SEARCH_TYPE.tcpconnection:
                        facetForNameField = FACET_FIELDS.NAME;
                        break;
                    case SEARCH_TYPE.ondemandrunbooks:
                        facetForNameField = FACET_FIELDS.NAME;
                        break;
                }
                break;
        }

        tableColumns.push({
            id: "more",
            Header: STRINGS.incidentSearch.columns.actions,
            accessor: "more",
            style: { minWidth: "24px" },
            formatter: row => {
                const ipaddr = row?.name ? row.name : EMPTY_DISPLAY_VALUE;
                const moreMenuItems: Array<JSX.Element> = [];
                if (type !== SEARCH_TYPE.properties && type !== SEARCH_TYPE.runbookschedules) {
                    moreMenuItems.push(
                        <MenuItem disabled={false} text={STRINGS.incidentSearch.more.findRelatedIndicators} active={false} key={"edit"}
                            onClick={() => {
                                const facets = { [facetForNameField || ""]: [ipaddr] };
                                history.push(getURL(getURLPath("incident-search"), { facets, searchType: SEARCH_TYPE.incident }, { replaceQueryParams: true }));
                                //window.open(getURLPath("incident-search") + '?facets=' + JSON.stringify(facets), '_blank');            
                            }}
                        />
                    );
                }

                if (type === SEARCH_TYPE.properties) {
                    moreMenuItems.push(
                        <MenuItem disabled={row.isDisabled} text={STRINGS.CUSTOM_PROPERTIES_PAGE.moreButton.edit} active={false} key={"editCustomProperty"}
                            onClick={() => {
                                actions.edit({
                                    incidentId: row.id,
                                    incidentDetails: {...row, loading: false},
                                })
                            }}
                        />
                    );

                    moreMenuItems.push(
                        <MenuItem disabled={false} text={STRINGS.CUSTOM_PROPERTIES_PAGE.moreButton.delete} active={false} key={"deleteCustomProperty"}
                            onClick={() => {
                                setIsRunningCustomPropCheck(true);
                                actions.delete(row.id)
                            }}
                        />
                    );
                }

                if (type === SEARCH_TYPE.runbookschedules) {
                    moreMenuItems.push(
                        <MenuItem disabled={row.isDisabled} text={STRINGS.runbookSchedules.columns.more.editMenuItem} active={false} key={"editSchedule"}
                            onClick={() => {
                                if (openScheduleRunbookModal) {
                                    openScheduleRunbookModal(row);
                                }
                            }}
                        />
                    )

                    // moreMenuItems.push(
                    //     <MenuItem disabled={row.isDisabled} text={STRINGS.runbookSchedules.columns.more.duplicateMenuItem} active={false} key={"duplicateSchedule"}
                    //         onClick={() => {
                    //            console.log('Duplicate Schedule')
                    //         }}
                    //     />
                    // )

                    moreMenuItems.push(
                        <MenuItem disabled={row.isDisabled} text={STRINGS.runbookSchedules.columns.more.deleteMenuItem} active={false} key={"deleteSchedule"}
                            onClick={async () => {
                                try {
                                    const payload = {
                                        variables: {
                                            input: {
                                                runbookId: row.runbook.id,
                                                name: row.schedule.name
                                            }
                                        }
                                    }
                                    await UnscheduleRunbook(payload);
                                    if (refreshSearch) {
                                        refreshSearch();
                                    }
                                } catch (error) {
                                    console.log(error)
                                }
                            }}
                        />
                    )
                }

                return <div onClick={(e) => { e.stopPropagation(); }}>
                    <Popover2 position={Position.BOTTOM_RIGHT}
                        interactionKind={Popover2InteractionKind.CLICK}
                        content={
                            <Menu>{moreMenuItems}</Menu>
                        } >
                        <Button aria-label="entity-more-button" icon={IconNames.MORE} minimal className="entity-action-icon"
                            disabled={false} onClick={(e) => { }}
                        />
                    </Popover2>
                </div>;
            },
        });
    }

    return tableColumns;
}

/**
 * Format the ondemand runbooks name cell
 * 
 * @param row A table row
 * 
 * @returns 
 */
function formatOnDemandRunbooksNameCell(row: any): string | JSX.Element {
    return <>
        <Link
            to={getRunbookViewPage(row.id)}
            onClick={e => e.stopPropagation()}
        >
            {row?.title}
        </Link>
        <WrapInTooltip tooltip={STRINGS.SEARCH.linkTooltip}>
            <BpIcon icon={BpIconNames.SHARE as IconName} size={14} className="ml-2" style={{ color: "#106ba3", cursor: "pointer", verticalAlign: "baseline" }}
                onClick={() => {
                    window.open(getRunbookViewPage(row.id), "_blank");
                } } />
        </WrapInTooltip>
    </>;
}


/**
 * Get the formatted input column for the schedules list
 * 
 * @returns JSX.Element
 */
function getInputColumn(authProfiles: ProfileInterface[] | undefined, edges): ((record: any) => string | JSX.Element) | undefined {
    return (row) => {
        if (!row?.details?.runbook?.inputs) {
            return 'No inputs';
        }
        const inputs = {};
        row.details.runbook.inputs.forEach((item) => {
            let friendlyAuthOrEdgeValue: string | undefined = "";
            if (item.type === PrimitiveVariableType.AUTH_PROFILE && authProfiles) {
                friendlyAuthOrEdgeValue = (authProfiles as ProfileInterface[]).find(profile => profile.id === item.value)?.name;
            }
            if (item.type === PrimitiveVariableType.ALLUVIO_EDGE && edges) {
                friendlyAuthOrEdgeValue = (edges as any).find(edge => edge.id === item.value)?.name;
            }
            inputs[item.name] = friendlyAuthOrEdgeValue || item.value;
        });
        const values = Object.values(inputs);
        const keys = Object.keys(inputs);

        return (
            <Popover2
                usePortal
                lazy
                content={
                    <div className='p-4' style={{ minWidth: "300px", maxWidth: "600px" }}>
                        <h2 className="m-0 font-size-md-large font-weight-bold ">{STRINGS.runbookSchedules.columns.inputs}</h2>
                        <div className="mt-2 py-2 container px-0" style={{ maxHeight: "350px", overflowY: "auto", overflowX: "hidden" }}>
                            {keys.map((key: string | number, i: React.Key | undefined) => {
                                const inputName = key;
                                const inputValue = inputs[key];

                                return (<div key={i} className="row my-2">
                                    <span className="col mr-2">{inputName}</span>
                                    <span className="col mr-2 font-weight-bold"> {inputValue}</span>
                                </div>);
                            })}
                        </div>
                </div>
                }
                interactionKind="click"
                className="w-300"
                placement="bottom-start"
                hoverOpenDelay={500}
                transitionDuration={150}
            >
                {/* eslint-disable-next-line */}
                <a href="#" onClick={(e) => {e.preventDefault()}} className="text-truncate d-inline-block" style={{ maxWidth: "150px" }}>{values.join(", ")}</a>
            </Popover2>
        );
    };
}

/** returns the id of the search item (the items in the array of values). 
 *  @param value the item in the search results value array.
 *  @param type the type of search that the page is displaying.
 *  @param useCognitiveSearch a boolean value, if true we are using cognitive search, if false we are 
 *      using the correlation engine.
 *  @returns a String with the id of the item. */
export function getItemId(value: any, type: SEARCH_TYPE, useCognitiveSearch: boolean): string {
    if (useCognitiveSearch) {
        switch (type) {
            case SEARCH_TYPE.incident:
                return value.incident.id;
            case SEARCH_TYPE.device:
                return value.network_device.uuid;
            case SEARCH_TYPE.interface:
                return value.network_interface.uuid;
            case SEARCH_TYPE.application:
                return value.application.uuid;
            case SEARCH_TYPE.location:
                return value.location.uuid;
            case SEARCH_TYPE.properties:
                return value.customProperties.id;
        }
    } else {
        switch (type) {
            case SEARCH_TYPE.incident:
                return value.id;
            case SEARCH_TYPE.device:
                return value.id;
            case SEARCH_TYPE.interface:
                return value.id;
            case SEARCH_TYPE.application:
                return value.id;
            case SEARCH_TYPE.location:
                return value.id;
            case SEARCH_TYPE.properties:
                return value.id;
            case SEARCH_TYPE.tcpconnection:
                return value.id;
            case SEARCH_TYPE.ondemandrunbooks:
                return value.id;
            case SEARCH_TYPE.runbookschedules:
                return value.id;
        }
    }
    return "";
}

/** returns the id of the search item (the items in the array of values).
 *  @param value the item in the search results value array.
 *  @param type the type of search that the page is displaying.
 *  @param useCognitiveSearch a boolean value, if true we are using cognitive search, if false we are
 *      using the correlation engine.
 *  @returns a String with the id of the item. */
export function getItemIdFromTable(value: any, type: SEARCH_TYPE, useCognitiveSearch: boolean): string {
    if (useCognitiveSearch) {
        switch (type) {
            case SEARCH_TYPE.incident:
                return value.id;
            case SEARCH_TYPE.device:
                return value.uuid;
            case SEARCH_TYPE.interface:
                return value.uuid;
            case SEARCH_TYPE.application:
                return value.uuid;
            case SEARCH_TYPE.location:
                return value.uuid;
            case SEARCH_TYPE.properties:
                return value.id;
        }
    } else {
        switch (type) {
            case SEARCH_TYPE.incident:
                return value.id;
            case SEARCH_TYPE.device:
                return value.id;
            case SEARCH_TYPE.interface:
                return value.id;
            case SEARCH_TYPE.application:
                return value.id;
            case SEARCH_TYPE.location:
                return value.id;
            case SEARCH_TYPE.properties:
                return value.id;
            case SEARCH_TYPE.tcpconnection:
                return value.tcpConnection.id;
            case SEARCH_TYPE.ondemandrunbooks:
                return value.id;
        }
    }
    return "";
}

/** returns the string with the query parameter that contains the selected facets.
 *  @param facets the current facet cache.
 *  @param isGrouped a boolean value, if true we want the selected grouped facets and if false we want the selected
 *      facets that are not grouped.
 *  @returns a String with the query parameter. */
export function getFacetQueryParam(facets: Record<string, Array<Facet>>, isGrouped: boolean): string {
    const selectedFacets: Record<string, Array<FacetValue>> = {};
    for (const category in facets) {
        for (const facetValue of facets[category]) {
            if (facetValue.selected && (isGrouped === (facetValue.isGroupedFacet || false))) {
                if (!selectedFacets[category]) {
                    selectedFacets[category] = [];
                }
                selectedFacets[category].push(facetValue.value);
            }
        }
    }
    return Object.keys(selectedFacets).length ? JSON.stringify(selectedFacets) : "";
}

/** returns the dictionary with the query facets as parsed from the facets query parameter.
 *  @param {string} facetQueryParam the string with the facets from the query parameter.
 *  @param {string} groupedFacetQueryParam
 *  @returns the dictionary with the selected query facets indexed by facet category. */
export function getSelectedFacetsFromQueryParams(facetQueryParam: string, groupedFacetQueryParam?: string): Record<string, Array<string>> {
    let selectedFacets: Record<string, Array<string>> = {};
    if (facetQueryParam?.length > 0) {
        try {
            selectedFacets = JSON.parse(facetQueryParam);
        } catch (error) {
            console.error(error);
        }
    }
    if (groupedFacetQueryParam && groupedFacetQueryParam?.length > 0) {
        try {
            const groupedFacets = JSON.parse(groupedFacetQueryParam);
            selectedFacets = {...selectedFacets, ...groupedFacets};
        } catch (error) {
            console.error(error);
        }
    }
    return selectedFacets;
}

/** A simple wrapper that takes in priority and returns a Priority LED */
/* istanbul ignore next */
export function EntityFormatter({
    triggeredOn = {}
}: any) {
    let name = "Unknown";
    let icon = "";
    let tooltipContent: Array<JSX.Element> = [];
    if (triggeredOn && triggeredOn.kind) {
        switch (triggeredOn.kind) {
            case "application_location":
                name = `${triggeredOn?.application_location?.application?.name || "Unknown"} (${triggeredOn?.application_location?.location?.name || "Unknown"})`;
                icon = IconNames.APPLICATIONS;
                if (triggeredOn?.application_location?.application?.name) {
                    tooltipContent.push(<tr key="application-name"><td className="text-right pr-2">{STRINGS.tooltips.name}</td><td>{triggeredOn.application_location.application.name}</td></tr>);
                }
                if (triggeredOn?.application_location?.location?.name) {
                    tooltipContent.push(<tr key="application-location"><td className="text-right pr-2">{STRINGS.tooltips.location}</td><td>{triggeredOn.application_location.location.name}</td></tr>);
                }
                break;
            case "network_interface":
                name = `${triggeredOn?.network_interface?.name || (triggeredOn?.network_interface?.ipaddr + ":" + triggeredOn?.network_interface?.ifindex)}`;
                icon = IconNames.MERGE_LINKS;
                if (triggeredOn?.network_interface?.ipaddr) {
                    tooltipContent.push(<tr key="interface-ip"><td className="text-right pr-2">{STRINGS.tooltips.ip}</td><td>{triggeredOn.network_interface.ipaddr}</td></tr>);
                }
                if (triggeredOn?.network_interface?.ifindex) {
                    tooltipContent.push(<tr key="interface-ifindex"><td className="text-right pr-2">{STRINGS.tooltips.ifIndex}</td><td>{triggeredOn.network_interface.ifindex}</td></tr>);
                }
                if (triggeredOn?.network_interface?.name) {
                    tooltipContent.push(<tr key="interface-name"><td className="text-right pr-2">{STRINGS.tooltips.name}</td><td>{triggeredOn.network_interface.name}</td></tr>);
                }
                if (triggeredOn?.network_interface?.location?.name) {
                    tooltipContent.push(<tr key="interface-location"><td className="text-right pr-2">{STRINGS.tooltips.location}</td><td>{triggeredOn.network_interface.location.name}</td></tr>);
                }
                break;
            case "network_device":
                name = `${triggeredOn?.network_device?.name || triggeredOn?.network_device?.ipaddr}`;
                icon = IconNames.DEVICES;
                if (triggeredOn?.network_device?.ipaddr) {
                    tooltipContent.push(<tr key="device-ip"><td className="text-right pr-2">{STRINGS.tooltips.ip}</td><td>{triggeredOn.network_device.ipaddr}</td></tr>);
                }
                if (triggeredOn?.network_device?.name) {
                    tooltipContent.push(<tr key="device-name"><td className="text-right pr-2">{STRINGS.tooltips.name}</td><td>{triggeredOn.network_device.name}</td></tr>);
                }
                if (triggeredOn?.network_device?.location?.name) {
                    tooltipContent.push(<tr key="device-location"><td className="text-right pr-2">{STRINGS.tooltips.location}</td><td>{triggeredOn.network_device.location.name}</td></tr>);
                }
                break;
            case "location":
                name = `${triggeredOn?.location?.name || "Unknown"}`;
                icon = IconNames.GLOBE;
                break;
        }
    }
    return <span className="incident-entity">
        {icon && <Icon icon={icon} className="mr-1"/>}
        {tooltipContent.length > 0 ? 
            <WrapInTooltip tooltip={<div className="px-2 py-2"><table><tbody>{tooltipContent}</tbody></table></div>} >
                {name}
            </WrapInTooltip> :
            name
        }
    </span>;
}

/** Creates a popup that displays the debug information.
 *  @param json the JSON object with the debug information.
 *  @param dialogState the copied state object with the state setup to open the dialog.  The content
 *      needs to be appended and the title needs to be set in this function.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function. */
/* istanbul ignore next */
function showDebugDialog(
    json: Record<string, any>, dialogState: any, setDialogState: (dialogState: any) => void
): void {
    const newDialogState = Object.assign({}, dialogState);
    newDialogState.title = STRINGS.incidentSearch.debugDialogTitle;
    newDialogState.showDialog = true;
    newDialogState.dialogContent = <JsonViewer json={json} />;
    newDialogState.dialogFooter = <>
        <CopyToClipboard text={JSON.stringify(json || {}, null, 4)}>
            <Button active={true} outlined={true}
                text={STRINGS.primaryIndicatorView.copyBtnText} onClick={() => {
                    setDialogState(updateDialogState(newDialogState, false, false, []));
                }}
            />
        </CopyToClipboard>
        <Button active={true} outlined={true}
            text={STRINGS.primaryIndicatorView.okBtnText}
            onClick={async (evt) => {
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }}
        />
    </>;
    setDialogState(newDialogState);
}

/** Creates a popup that displays the error information.
 *  @param dialogState the copied state object with the state setup to open the dialog.  The content
 *      needs to be appended and the title needs to be set in this function.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function. */
/* istanbul ignore next */
function showErrorDialog(
    dialogState: any, setDialogState: (dialogState: any) => void
): void {
    const newDialogState = Object.assign({}, dialogState);
    newDialogState.title = STRINGS.incidentSearch.errorDialogTitle;
    newDialogState.showDialog = true;
    newDialogState.dialogContent = <span>{STRINGS.incidentSearch.errorDialogText}</span>;
    newDialogState.dialogFooter = <>
        <Button active={true} outlined={true}
            text={STRINGS.primaryIndicatorView.okBtnText}
            onClick={async (evt) => {
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }}
        />
    </>;
    setDialogState(newDialogState);
}

/**
 * Get the Runbook Analysis View Page
 * 
 * @param runbookOutputId {string} 
 * 
 * @returns The absolute URL for the runbook details page
 */
export function getRunbookViewPage(runbookOutputId): any {
    return getURL(getURLPath("runbook-details"), {
        runbookId: runbookOutputId,
        ondemand: "true"
    }, { replaceQueryParams: true });
}

/** enables or disables a schedule runbook job.
 *  @param runbookId a String with the id of the runbook.
 *  @param scheduleName a String with the name of the schedule.
 *  @param enabled the current value of the enabled flag.
 *  @param setDialogState the function from useState that is used to set the dialog state.
 *  @param SetRunbookScheduleJobStatus a promise used to mutate the schedule runbook job status.
 *  @param refreshSearch a function used to refresh the search results list. */
async function setRunbookScheduleJobEnabled(
    runbookId: string,
    scheduleName: string,
    enabled: boolean,
    setDialogState: Function,
    SetRunbookScheduleJobStatus: any,
    refreshSearch: Function,
): Promise<void> {
    const newDialogState: any = {
        showDialog: true,
        loading: true,
        title: STRINGS.scheduleRunbook.changeRunbookScheduleJobStatusDialog.title,
    };

    setDialogState(newDialogState);

    client.query({
        query: new Query(loader("../../utils/services/search/search-runbooks-schedule-jobs.graphql")).getGqlQuery(),
        variables: {
            count: true,
            top: 10000,
            skip: 0
        },
        fetchPolicy: "no-cache"
    }).then(results => {
        const scheduleRunbookJobs = results?.data?.searchItems?.page;
        const jobsEnabledCount = scheduleRunbookJobs?.filter(job => job.enabled)?.length || 0;
        const allowedJobsLimit = 200;
        newDialogState.loading = false;
        newDialogState.dialogContent = (
            <div className="mb-3">
                <span>
                    {(jobsEnabledCount >= allowedJobsLimit && !enabled) 
                        ? STRINGS.formatString(
                            STRINGS.scheduleRunbook.changeRunbookScheduleJobStatusDialog.limitErrorEnable, allowedJobsLimit
                        ) 
                        : enabled ? STRINGS.scheduleRunbook.changeRunbookScheduleJobStatusDialog.confirmDisable
                        : STRINGS.scheduleRunbook.changeRunbookScheduleJobStatusDialog.confirmEnable}
                </span>
            </div>
        );
        newDialogState.dialogFooter = (
            <>
              <Button
                    active={true}
                    outlined={true}
                    onClick={async () => {
                        if (jobsEnabledCount >= allowedJobsLimit && !enabled) {
                            setDialogState(updateDialogState(newDialogState, false, false, []));
                        } else {
                            try {
                                setDialogState(updateDialogState(newDialogState, true, true, []));
                                const payload = {
                                    variables: {
                                        input: {
                                            runbookId: runbookId,
                                            name: scheduleName,
                                            enabled: !enabled,
                                        },
                                    },
                                };
                                await SetRunbookScheduleJobStatus(payload);
                                refreshSearch();
                                setDialogState(updateDialogState(newDialogState, false, false, []));
                            } catch (error) {
                                const errorMsg = STRINGS.formatString(
                                    STRINGS.scheduleRunbook.changeRunbookScheduleJobStatusDialog.couldNotEnableOrDisable, enabled 
                                    ? "disable" : "enable"
                                );
                                setDialogState(
                                    updateDialogState(newDialogState, true, false, [errorMsg]),
                                );
                            }
                        }
                    }}
                    text={STRINGS.runbooks.okBtnText}
                />
                {((!enabled && jobsEnabledCount < allowedJobsLimit) || enabled) && <Button
                    active={true}
                    outlined={true}
                    onClick={async () => {
                        setDialogState(updateDialogState(newDialogState, false, false, []));
                    }}
                    text={STRINGS.runbooks.cancelBtnText}
                />}
            </>
        );
        setDialogState(updateDialogState(newDialogState, true, false, []));
    }).catch(error => {
        newDialogState.dialogFooter = (
            <>
                <Button
                    active={true}
                    outlined={true}
                    onClick={() => {
                        setDialogState(updateDialogState(newDialogState, false, false, []));
                    }}
                    text={STRINGS.runbooks.okBtnText}
                />
            </>
        );
        setDialogState(updateDialogState(newDialogState, true, false, [error]));
    });
}
