/** This file defines the global data source type store.  This store keeps track of which types
 *      of data sources are currently connected to this user's tenant.
 *  @module 
 */

import { loader } from "graphql.macro";
import { Query } from "reporting-infrastructure/data-hub";
import { DataSourceConfig, DataSourceService, dataSourceTypeOptions } from "utils/services/DataSourceApiService";
import { client } from "utils/services/GraphqlService";

/** the array of types that is part of this tenant. */
let types: Array<dataSourceTypeOptions> = [];
let ar11s: {id: string, name: string, hostname: string}[];
/** dict for data source type retrieval */
let dst: Record<string, string> = {};

/** initializes the data source type store. */
export async function init() {
    try {
        ar11s = [];
        types = [];
        dst = {};
        const dataSources: {items: Array<DataSourceConfig>} = await DataSourceService.getDataSources() as any;
        if (dataSources?.items?.length) {
            for (const config of dataSources.items) {
                const sourceType: dataSourceTypeOptions = config.entity.attributes.type as dataSourceTypeOptions;
                if (Object.values(dataSourceTypeOptions).includes(sourceType) && !types.includes(sourceType)) {
                    types.push(sourceType);
                }
                if (sourceType === dataSourceTypeOptions.AppResponse) {
                    ar11s.push({id: config.entity.attributes.id || "", name: config.entity.attributes.name, hostname: config.entity.attributes.host});
                }
                if (config.entity.kind === "data_source") {
                    dst[config.entity.attributes.id || "noId"] = config.entity.attributes.name;
                }
            }
        }

        // Graphql query to get the aternity configuration
        const results = await client.query({
            query: new Query(loader("../../pages/edge-configuration/views/datasources.graphql")).getGqlQuery(),
            variables: {},
            fetchPolicy: "no-cache"
        });
        if (results?.data?.dataSources?.length === 1 && results.data.dataSources[0].enabled) {
            types.push(dataSourceTypeOptions.Aternity);
            dst[results?.data?.dataSources[0].id || "noId"] = results?.data?.dataSources[0].hostname;
        }
    } catch (error) {
        console.error("Could not get list of data sources");
    }
}

/** returns whether or not the specified type of data source is in this tenant.
 *  @param type the type of data source to check for which is one of the dataSourceTypeOptions.
 *  @returns a boolean value which is true if the specified data source type is part of this tenant. */
export function hasDataSource(type: dataSourceTypeOptions): boolean {
    return types.includes(type);
}

/** returns whether or not the Aternity data source is in this tenant.
 *  @returns a boolean value which is true if the Aternity data source is part of this tenant. */
export function hasAternity(): boolean {
    return hasDataSource(dataSourceTypeOptions.Aternity);
}

/** returns whether or not the NetIm data source is in this tenant.
 *  @returns a boolean value which is true if the NetIm data source is part of this tenant. */
export function hasNetIm(): boolean {
    return hasDataSource(dataSourceTypeOptions.NetIm);
}

/** returns whether or not the NetProfiler data source is in this tenant.
 *  @returns a boolean value which is true if the NetProfiler data source is part of this tenant. */
export function hasNetProfiler(): boolean {
    return hasDataSource(dataSourceTypeOptions.NetProfiler);
}

/** returns whether or not the AppResponse data source is in this tenant.
 *  @returns a boolean value which is true if the AppResponse data source is part of this tenant. */
export function hasAppResponse(): boolean {
    return hasDataSource(dataSourceTypeOptions.AppResponse);
}

/** returns the list of data source types.
 *  @returns an Array with the dataSourceTypeOptions that are in this tenant. */
export function getTypes(): dataSourceTypeOptions[] {
    return types;
}

/** returns the list of data source types.
 *  @returns an Array with the dataSourceTypeOptions that are in this tenant. */
export function getArDataSources(): {id: string, name: string}[] {
    return ar11s;
}
/** returns records to compare with explorer `datasourceType: id`
 * used in the `<IncidentSearchPage />` */
export function getDataSourceTypeSources(): Record<string, string> {
    return dst;
}

