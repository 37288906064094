import React from 'react';
import { Intent, Label } from '@blueprintjs/core';
import { Icon, IconNames } from '@tir-ui/react-components';
import { WrapInTooltip } from 'components/common/wrap-in-tooltip/WrapInTooltip';
import { STRINGS } from 'app-strings';

/** defines the datasource status enum.*/
export enum DATASOURCE_STATUS {
	OK = 'OK',
	WARNING = 'WARNING',
	DISABLED = 'DISABLED',
	FAILED = 'FAILED',
	UNKNOWN = 'UNKNOWN',
	RECONFIGURING = 'RECONFIGURING',
}

export enum DATASOURCE_STATE {
	ENABLED = 'Enabled',
	DISABLED = 'Disabled',
}

export const DATASOURCE_STATUS_PROPS: Record<
	string,
	{
		label: string;
		icon: string;
		intent: Intent;
		className?: string;
	}
> = {
	[DATASOURCE_STATUS.OK]: {
		label: STRINGS.DATA_SOURCES.dataSourceView.status.ok,
		icon: IconNames.TICK_CIRCLE,
		intent: Intent.SUCCESS,
	},
	[DATASOURCE_STATUS.WARNING]: {
		label: STRINGS.DATA_SOURCES.dataSourceView.status.warning,
		icon: IconNames.ISSUE,
		intent: Intent.WARNING,
	},
	[DATASOURCE_STATUS.DISABLED]: {
		label: STRINGS.DATA_SOURCES.dataSourceView.status.disabled,
		icon: IconNames.DISABLE,
		intent: Intent.DANGER,
	},
	[DATASOURCE_STATUS.FAILED]: {
		label: STRINGS.DATA_SOURCES.dataSourceView.status.failed,
		icon: IconNames.ERROR,
		intent: Intent.DANGER,
	},
	[DATASOURCE_STATUS.UNKNOWN]: {
		label: STRINGS.DATA_SOURCES.dataSourceView.status.unknown,
		icon: IconNames.ISSUE,
		intent: Intent.WARNING,
	},
	[DATASOURCE_STATUS.RECONFIGURING]: {
		label: STRINGS.DATA_SOURCES.dataSourceView.status.reconfiguring,
		icon: IconNames.REFRESH,
		intent: Intent.NONE,
		className: 'rotate',
	},
};

const DataSourceStatus = (props: any): JSX.Element => {
	const { dataSource } = props;

	const status = dataSource.status
		? dataSource.status
		: DATASOURCE_STATUS.UNKNOWN;
	const metricVersion = dataSource.metricVersion
		? dataSource.metricVersion
		: '';
	const productVersion = dataSource.productVersion
		? dataSource.productVersion
		: '';

	return (
		<React.Fragment>
			{status ? (
				<Label>
					{STRINGS.DATA_SOURCES.dataSourceView.statusLabel}: &nbsp;
					{
						<WrapInTooltip
							tooltip={DATASOURCE_STATUS_PROPS[status].label}
						>
							<Icon
								icon={DATASOURCE_STATUS_PROPS[status].icon}
								intent={DATASOURCE_STATUS_PROPS[status].intent}
								className={
									DATASOURCE_STATUS_PROPS[status].className
								}
							/>
						</WrapInTooltip>
					}
				</Label>
			) : null}
			{metricVersion && status !== DATASOURCE_STATUS.DISABLED ? (
				<Label>
					{STRINGS.DATA_SOURCES.dataSourceView.metricVersionLabel}:
					&nbsp;
					{metricVersion}
				</Label>
			) : null}
			{productVersion && status !== DATASOURCE_STATUS.DISABLED ? (
				<Label>
					{STRINGS.DATA_SOURCES.dataSourceView.productVersionLabel}:
					&nbsp;
					{productVersion}
				</Label>
			) : null}
		</React.Fragment>
	);
};

export { DataSourceStatus };
