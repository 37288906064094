export enum METRIC_NAME {
    // Metrics that match DAL
    throughput                      = "throughput",
    inboundThroughput               = "inboundThroughput",
    outboundThroughput              = "outboundThroughput",
    peakThroughput                  = "peakThroughput",
    jitter                          = "jitter",
    peakJitter                      = "peakJitter",
    latency                         = "latency",
    peakLatency                     = "peakLatency",
    packetLoss                      = "packetLoss",
    peakPacketLoss                  = "peakPacketLoss",
    packets                         = "packets",
    inboundPackets                  = "inboundPackets",
    outboundPackets                 = "outboundPackets",
    peakPackets                     = "peakPackets",
    cpu                             = "cpu",
    memory                          = "memory",
    disk                            = "disk",
    availability                    = "availability",
    errorsPercent                   = "errorsPercent",
    dropsPercent                    = "dropsPercent",
    usedPercent                     = "usedPercent",
    
    // UI-only metrics. The DAL has a hierarchy like cpu.usedPercent which doesn't work here so we have flattened the hierarchy
    cpu_usedPercent                 = "cpu_usedPercent",
    disk_usedPercent                = "disk_usedPercent",
    memory_usedPercent              = "memory_usedPercent",

    // These metrics are used by Desso in the runbooks.  Maybe we should reconsider 
    // putting metrics in an enumerated type and should either get them from the 
    // system or allow them to be defined on the fly.  For now I am adding all the 
    // metrics Desso needs here.
    packet_loss                     = "packet_loss",

    utilization                     = "utilization",
    in_utilization                  = "in_utilization",
    out_utilization                 = "out_utilization",
    bytes                           = "bytes",
    in_bytes                        = "in_bytes",
    out_bytes                       = "out_bytes",
    in_throughput                   = "in_throughput",
    out_throughput                  = "out_throughput",
    in_packets                      = "in_packets",
    out_packets                     = "out_packets",
    active_connections              = "active_connections",
    sum_active_connections          = "sum_active_connections",
    new_connections_ps              = "new_connections_ps",
    sum_new_connections             = "sum_new_connections",
    retrans_bytes_percent           = "retrans_bytes_percent",
    retrans_packets_percent         = "retrans_packets_percent",
    response_time                   = "response_time",
    request_network_time            = "request_network_time",
    response_network_time           = "response_network_time",
    network_rtt                     = "network_rtt",
    server_rtt                      = "server_rtt",
    server_delay                    = "server_delay",
    application_usage_time_total    = "application_usage_time_total",
    activity_response_time_avg      = "activity_response_time_avg",
    activity_network_time_avg       = "activity_network_time_avg",
    application_score_avg           = "application_score_avg",
    page_load_network_time_avg      = "page_load_network_time_avg",
    application_wait_time_total     = "application_wait_time_total",

    sum_bytes                       = "sum_bytes",
    sum_in_bytes                    = "sum_in_bytes",
    sum_out_bytes                   = "sum_out_bytes",
    sum_packets                     = "sum_packets",
    sum_in_packets                  = "sum_in_packets",
    sum_out_packets                 = "sum_out_packets",
    sum_application_usage_time      = "sum_application_usage_time",
    activity_rtt                    = "activity_rtt",
    activity_network_time           = "activity_network_time",
    application_uxi                 = "application_uxi",
    page_load_network_time          = "page_load_network_time_avg",
    sum_application_wait_time       = "sum_application_wait_time",
    sum_changes                     = "sum_changes",

    cpu_utilization                 = "cpu_utilization",
    memory_utilization              = "memory_utilization",
    disk_utilization                = "disk_utilization",

    timestamp                       = "timestamp",
    max_utilization                 = "max_utilization",
    max_in_utilization              = "max_in_utilization",
    max_out_utilization             = "max_out_utilization",
    sum_in_ifc_errors               = "sum_in_ifc_errors",
    sum_out_ifc_errors              = "sum_out_ifc_errors",
    sum_in_packet_drops             = "sum_in_packet_drops",
    sum_out_packet_drops            = "sum_out_packet_drops",
    in_packet_error_rate            = "in_packet_error_rate",
    out_packet_error_rate           = "out_packet_error_rate",
    sum_usage_time                  = "sum_usage_time",
    sum_wait_time                   = "sum_wait_time",

    in_packet_drops_rate            = "in_packet_drops_rate",
    out_packet_drops_rate           = "out_packet_drops_rate",

    device_status                   = "device_status",
    iface_status                    = "iface_status",
    admin_status                    = "admin_status",
    op_status                       = "op_status",

    device_up_time                  = "device_up_time",
    mos                             = "mos",
    activity_backend_time           = "activity_backend_time",
    activity_client_time            = "activity_client_time",
    activity_response_time          = "activity_response_time",
    sum_crashes                     = "sum_crashes",
    sum_hang_time                   = "sum_hang_time",
    page_load_client_time           = "page_load_client_time",
    page_load_backend_time          = "page_load_backend_time",
    page_load_time                  = "page_load_time",
    dns_response_time               = "dns_response_time",
    connection_setup_time           = "connection_setup_time",
    request_payload_transfer_time   = "request_payload_transfer_time",
    response_payload_transfer_time  = "response_payload_transfer_time",
    retrans_delay                   = "retrans_delay",
    count_unique_devices            = "count_unique_devices",
    connection_requests             = "connection_requests",
    failed_connections              = "failed_connections",
    connections_failed_percent      = "connections_failed_percent",
    user_response_time              = "user_response_time",
    latest_device_status            = "Latest Device Status",
    sum_changes_24h                 = "Changes (24h)",
    latest_device_up_time           = "Last Seen Up Time",
    request_payload_time            = "request_payload_time",
    response_payload_time           = "response_payload_time",
    sum_retrans_bytes               = "sum_retrans_bytes",
    retrans_percent                 = "retrans_percent",
    in_network_rtt                  = "in_network_rtt",
    out_network_rtt                 = "out_network_rtt",
    sum_zero_window_segments        = "sum_zero_window_segments",
    sum_client_zero_window_segments = "sum_client_zero_window_segments",
    sum_server_zero_window_segments = "sum_server_zero_window_segments",
    zero_window_percent             = "zero_window_percent",

    retrans_payload_percent         = "retrans_payload_percent",
    request_retrans_payload_percent = "request_retrans_payload_percent",
    response_retrans_payload_percent= "response_retrans_payload_percent",
    sum_out_of_order_segments       = "sum_out_of_order_segments",
    sum_client_out_of_order_segments= "sum_client_out_of_order_segments",
    sum_server_out_of_order_segments= "sum_server_out_of_order_segments",
    cli2srv_ooo_bytes               = "cli2srv_ooo_bytes",
    cli2srv_ooo_packets             = "cli2srv_ooo_packets",
    cli2srv_payload_bytes           = "cli2srv_payload_bytes",
    cli2srv_payload_packets         = "cli2srv_payload_packets",
    cli2srv_rx_bytes                = "cli2srv_rx_bytes",
    cli2srv_rx_packets              = "cli2srv_rx_packets",
    cli2srv_traffic_bytes           = "cli2srv_traffic_bytes",
    cli2srv_traffic_packets         = "cli2srv_traffic_packets",
    cli2srv_zero_windows            = "cli2srv_zero_windows",
    control_packets                 = "control_packets",
    control_rx_packets              = "control_rx_packets",
    srv2cli_ooo_bytes               = "srv2cli_ooo_bytes",
    srv2cli_ooo_packets             = "srv2cli_ooo_packets",
    srv2cli_payload_bytes           = "srv2cli_payload_bytes",
    srv2cli_payload_packets         = "srv2cli_payload_packets",
    srv2cli_rx_bytes                = "srv2cli_rx_bytes",
    srv2cli_rx_packets              = "srv2cli_rx_packets",
    srv2cli_traffic_bytes           = "srv2cli_traffic_bytes",
    srv2cli_traffic_packets         = "srv2cli_traffic_packets",
    srv2cli_zero_windows            = "srv2cli_zero_windows",
    conn_setup_time_us              = "conn_setup_time_us",
    client_round_trip_time_us       = "client_round_trip_time_us",
    server_round_trip_time_us       = "server_round_trip_time_us",
    request_net_time_us             = "request_net_time_us",
    response_net_time_us            = "response_net_time_us",
    service_response_time_us        = "service_response_time_us",
    request_retrans_delay           = "request_retrans_delay",
    response_retrans_delay          = "response_retrans_delay"
}
