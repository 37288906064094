/** This module defines the What's New React component.  The What's New component is displayed below
 *      the help icon in the top navigation bar.
 *  @module */
import React, { ReactNode, useEffect, useState } from 'react';
import { AnchorButton, Checkbox, IconName, Position } from '@blueprintjs/core';
import { STRINGS } from 'app-strings';
import { IconNames } from '@blueprintjs/icons';
import { UserPreferences } from 'utils/services/UserPrefsTypes';
import { getURLPath } from 'config';
import { Icon } from '@tir-ui/react-components';
import { Popover2 } from '@blueprintjs/popover2';
import { TryItActionType, UserAssistanceImgType, WhatsNewUserAssistanceValue } from 'types/UserAssistance';
import { setUserPreferences, useUserPreferences } from 'utils/hooks';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { EventNames, trackEvent } from 'utils/appinsights';
import { AuthServiceProvider } from 'utils/providers/AuthServiceProvider';
import { HelpSources } from 'utils/appinsights/AppInsights';
import { getURL, setQueryParams } from 'utils/hooks/useQueryParams';
import { useHistory } from 'react-router';

/** this constant refers to the auth service where you can get the tenant, user, etc. */
const AuthService = AuthServiceProvider.getService();

/** this constant has the base URI for the help. */
const { HELP_URI } = window['runConfig'] ? window['runConfig'] : {HELP_URI :''};

/** this interface defines the properties passed into the WhatsNew React component. */
export interface WhatsNewProps {
    /** a boolean value, which if true specifies that we should not check the whats new preference and show the whats new content. */
    dontCheckWhatsNew?: boolean;
    /** the child nodes that should be displayed in the Popover2 component. */
    children?: ReactNode;
}

/** Renders the Whats New React component.
 *  @param props the properties passed in.
 *  @returns JSX with the Whats New React component.*/
export function WhatsNew(props: WhatsNewProps): JSX.Element {
    const appInsightsContext = useAppInsightsContext();
    const history = useHistory();

/*
    //const noop = "#";
    const whatsNewMapping = useMemo<WhatsNewUserAssistanceValue>(() => {
        try {
            if (!props.dontCheckWhatsNew) {
                var httpRequest = new XMLHttpRequest();
                httpRequest.open("GET", "/whatsnew/WhatsNew/WhatsNew.json", false);
                httpRequest.send(null);
                let jsonDoc: any = httpRequest.responseText;
                jsonDoc = JSON.parse(jsonDoc) || {};
                return jsonDoc.whatsNew;
                //return HELP.whatsNew || {};    
            } else {
                return {};
            }
        } catch (error) {
            return {};
        }
    }, [props.dontCheckWhatsNew]);
*/

    const [whatsNewMapping, setWhatsNewMapping] = useState<WhatsNewUserAssistanceValue>({} as WhatsNewUserAssistanceValue);
    useEffect(
        () => {
            try {
                if (!props.dontCheckWhatsNew) {
                    var httpRequest = new XMLHttpRequest();
                    httpRequest.onload = (e) => {
                        try {
                            let jsonDoc: any = httpRequest.responseText;
                            jsonDoc = JSON.parse(jsonDoc) || {};
                            setWhatsNewMapping(jsonDoc.whatsNew);    
                        } catch (error) {
                            console.log("Error parsing whats new");
                        }
                    };
                    httpRequest.open("GET", "/whatsnew/WhatsNew/WhatsNew.json", false);
                    httpRequest.send(null);
                }
            } catch (error) {
                console.log("Error fetching whats new");
            }    
        },
        [props.dontCheckWhatsNew]
    );

    const userPreferences = useUserPreferences({listenOnlyTo: { whatsNew: {version: "0.0"} }});
    const [showWhatsNew, setShowWhatsNew] = useState<boolean>(false)
    const [dontShowEver, setDontShowEver] = useState(false);

    const whatsNewMappingVersion = whatsNewMapping?.version || "0.0";
    const whatsNewVersion = userPreferences?.whatsNew?.version || "0.0";
    const whatsNewDontShow = userPreferences?.whatsNew?.dontShowAgain;
    useEffect(() => {
        try {
            const newVersion = parseFloat(whatsNewMappingVersion);
            const oldVersion = parseFloat(whatsNewVersion);
            if (newVersion > oldVersion && !whatsNewDontShow) {
                setShowWhatsNew(true);
            }
        } catch (error) {
            console.log("Error parsing whats new versions!");
        }
    }, [whatsNewMappingVersion, whatsNewVersion, whatsNewDontShow]);

    return <Popover2 position={Position.BOTTOM} 
        //interactionKind={Popover2InteractionKind.} 
        isOpen={showWhatsNew}
        enforceFocus={false}
        content={
            <div className="p-4" style={{maxWidth: "800px", maxHeight: "600px", overflow: "auto"}}>
                <div className="d-flex justify-content-between align-items-end mb-4 w-100">
                    <span className="display-7 w-100 mr-4">{STRINGS.topNavigation.whatsNew.title}</span>
                    <Checkbox
                        className="mr-1 display-10 text-nowrap"
                        label={STRINGS.topNavigation.whatsNew.dontShowAgainText}
                        checked={dontShowEver}
                        onChange={e => setDontShowEver(e.currentTarget.checked)}
                    />                                
                    <AnchorButton icon={IconNames.SMALL_CROSS as IconName} minimal={true} data-testid="whats-new-close-icon" onClick={() => {
                        setShowWhatsNew(false);
                        const userPrefsUpdate: UserPreferences = {
                            whatsNew: {
                                version: (whatsNewMapping.version || "2.0")
                            }
                        };
                        if (dontShowEver) {
                            userPrefsUpdate.whatsNew!.dontShowAgain = true;
                        }
                        setUserPreferences(userPrefsUpdate);
                    }}/>
                </div>
                <div className="mb-4">
                    <dl>
                        {(whatsNewMapping?.features || []).filter((item) => !item.hidden).map((item) => {
                            const hostName = window.location.origin;
                            return <React.Fragment key={"feature-" + item.name}>
                                {item.name && <dt key={"dt-name-" + item.name}>
                                    {item.name}{item.icon ? <Icon className="ml-2 align-text-top" icon={item.icon} data-testid="whats-new-feature-icon" /> : ""}
                                </dt>}
                                {item.description && <dl key={"dt-description-" + item.name}>
                                    {item.description}
                                    {item.tryIt?.length && <>
                                        {item.tryIt.map((tryIt, index) => {
                                            const pathString = tryIt?.type === TryItActionType.HelpPage 
                                                ? `${HELP_URI}${tryIt!.route || ""}`
                                                : tryIt?.route ? getURLPath(tryIt!.route) + (tryIt!.params ? "?" + tryIt!.params : "") : "";
                                            return <span key={"try-it-" + (index + 1)}>  <a href={
                                                    tryIt?.type === TryItActionType.HelpPage 
                                                        ? `${HELP_URI}${tryIt!.route || ""}` 
                                                        : `${hostName}${pathString}`
                                                } 
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    event.preventDefault();
                                                    if (tryIt?.type === TryItActionType.UrlUpdate) {
                                                        const params = {};
                                                        if (tryIt?.params) {
                                                            const paramsSplit = tryIt.params.split("=");
                                                            if (paramsSplit.length % 2 === 0) {
                                                                let index = 0;
                                                                while (index < paramsSplit.length) {
                                                                    params[paramsSplit[index++]] = paramsSplit[index++];
                                                                }
                                                            }
                                                        }
                                                        if (tryIt?.route) {
                                                            history.push(getURL(getURLPath(tryIt.route), params, {replaceQueryParams: true}));                                        
                                                        } else {
                                                            setQueryParams(params , true);    
                                                        }
                                                    } else if (tryIt?.type === TryItActionType.HelpPage) {
                                                        window.open(`${HELP_URI}${tryIt!.route || ""}`);
                                                    } else {
                                                        window.open(pathString, '_blank');
                                                    }
                                                    if (appInsightsContext) {
                                                        const properties = {
                                                            name: EventNames.TRY_IT_INVOKED, 
                                                            properties: {
                                                                topic: item.name || "Unknown",
                                                            }
                                                        };
                                                        trackEvent(appInsightsContext, AuthService, properties);
                                                    }                    
                                                }}
                                            >{tryIt.text}
                                            </a></span>;
                                        })}
                                    </>}
                                    {item.img && <><br /><br /><img 
                                        alt={item.img.url} 
                                        src={item.img.type === UserAssistanceImgType.internet ? item.img.url : `${HELP_URI}${item.img.url}` }
                                    /></>}
                                </dl>}
                            </React.Fragment>;
                        })}
                    </dl>
                </div>
                <div className="d-flex justify-content-end w-100">
                    <AnchorButton intent="primary" text={STRINGS.topNavigation.whatsNew.viewHelpBtn}
                        onClick={() => {
                            setShowWhatsNew(false);
                            setUserPreferences({whatsNew: {version: (whatsNewMapping.version || "2.0")}});
                            if (whatsNewMapping.showMore) {
                                window.open(`${HELP_URI}${whatsNewMapping.url}`);
                            }
                            if (appInsightsContext) {
                                const properties = {
                                    name: EventNames.HELP_INVOKED, 
                                    properties: {
                                        topic: whatsNewMapping.url || "Unknown",
                                        type: HelpSources.PAGE
                                    }
                                };
                                trackEvent(appInsightsContext, AuthService, properties);
                            }    
                        }}
                    />
                </div>
            </div>
        } 
    >
        {props.children}
    </Popover2>;
}
