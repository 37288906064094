import React, { useEffect } from "react";
import classNames from "classnames";
import { Classes, Divider } from "@blueprintjs/core";
import { FormikProps } from "formik";
import moment from "moment";
import { LangEN, STRINGS } from "app-strings";
import { Form, CheckboxField, InputField, SelectField } from "components/common/form";
import { AuthenticationMethodTypeEnum } from "utils/services/ThirdPartyIntegrationApiService";
import { validIP, validateJSON } from "utils/validators/Validators";
import { IntegrationConnectorPropertyField, IntegrationConnectorPropertyFieldType } from "../types/IntegrationTypes";
import * as yup from "yup";
import {
    AuthenticationMethods,
    getProfileAuthOptions,
} from "./BasicAuthDetailsPanel";

type ConnectorPropertyValidation = yup.StringSchema | yup.NumberSchema | yup.BooleanSchema | yup.DateSchema;

export interface ConnectorDetailsPanelProps {
    connectorName?: string;
    connectorAuthMethod?: AuthenticationMethodTypeEnum;
    supportedAuthenticationMethods: any;
    fields: Array<IntegrationConnectorPropertyField>;
    customProperties: Array<{ name: string; value: string | boolean }>;
    onChangeName: (event) => void;
    onChangeAuthMethod: (event) => void;
    onChangeCustomProperties: (
        _propertyName: string,
        _propertyValue: string
    ) => void;
    profileNameList?: string[];
}

export function getAuthMethod(authMethod) {
    if (authMethod && typeof authMethod === "string") {
        return authMethod;
    }
    
    if (typeof authMethod === "number") {
        return AuthenticationMethodTypeEnum[authMethod];
    }
    
    return 0;
}

const ConnectorDetailsPanel = (props: ConnectorDetailsPanelProps) => {
    const { ENV } = window["runConfig"] ? window["runConfig"] : { ENV: "" };
    const authMethods = getProfileAuthOptions(ENV);    
    const availableAuthMethods = authMethods.filter((method) => {
        const isAvailable = Object.keys(props.supportedAuthenticationMethods)
            .map((item) => {
                return item ? item?.toLowerCase() : "";
            })
            .includes(AuthenticationMethods[method]?.toLowerCase());

        return isAvailable;
    });
    const validationsText: LangEN["INTEGRATIONS_PAGE"]["integrationDetailsModal"]["fieldValidation"] = STRINGS["INTEGRATIONS_PAGE"]["integrationDetailsModal"]["fieldValidation"];

    const customPropsFields = props.fields || [];
    const customFieldsValidation = getValidationSchemaForCustomFields(customPropsFields);
    const validationSchema = yup.object({
        profile_name: yup
            .string()
            .required()
            .max(128)
            .label(
                STRINGS.INTEGRATIONS_PAGE.integrationDetailsModal.connectorNameFieldLabel
            )
            .notOneOf(props.connectorName ? props.profileNameList || [] : []),
        profile_auth_method: yup
            .string()
            .notOneOf([
                STRINGS.thirdPartyIntegrations.addAuthProfile.panels.basicDetails.fields.profileAuthMethod.selectMethod,
            ])
            .label(
                STRINGS.thirdPartyIntegrations.addAuthProfile.panels.basicDetails.fields.profileAuthMethod.label
            )
            .when([], {
                is: () => availableAuthMethods.length > 0,
                then: yup.string().required()
            }),
        ...customFieldsValidation
    });
    const initialValues = getInitialValuesWithCustomFields();

    useEffect(() => {
        if (availableAuthMethods?.length === 1 && !props.connectorAuthMethod) {
            authMethodChangeHandler(availableAuthMethods[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableAuthMethods?.length])

    /**
     * Get Validation Schema for Custom Fields
     * 
     * @param {Array} fields - the list of custom properties
     * 
     * @returns {object}
     */
    function getValidationSchemaForCustomFields(fields: Array<IntegrationConnectorPropertyField>) {
        const schema = {};

        if (!fields) {
            return {}
        }

        function getValidationFromType(type: IntegrationConnectorPropertyFieldType, fieldLabel: string, isRequired: boolean) {
            const floatRegex = /^[+-]?\d+(\.\d+)?$/;
            const lowerCaseFieldType = `${type}`.toLowerCase() as IntegrationConnectorPropertyFieldType;
            let validation: ConnectorPropertyValidation = yup.string().typeError(validationsText.string).label(fieldLabel);

            switch(lowerCaseFieldType) {
                case 'string':
                    validation = yup.string().typeError(validationsText.string).label(fieldLabel);
                    break;               
                case 'int':
                case 'integer':
                    validation = yup.number().integer(validationsText.number).label(fieldLabel);;
                    break;
                case 'float':
                    validation = yup.string().matches(floatRegex, validationsText.float).label(fieldLabel);
                    break;
                case 'ipaddress':
                    validation = yup.string().test(`ip-address`, validationsText.ipAddress, (value) => validIP(value)).label(fieldLabel);
                    break;
                case 'bool':
                case 'boolean':
                    return yup.bool().typeError(validationsText.boolean).label(fieldLabel);
                case 'json':
                    validation = yup.string().test(`json`, validationsText.json, (value) => validateJSON(value)).label(fieldLabel);
                    break;
                case 'timestamp':
                    validation = yup.string().test(`timestamp`, validationsText.timestamp, (value) => {
                        // multiplied by 1000 so that the argument is in milliseconds, not seconds
                        const isValidTimestamp = moment(new Date(value  * 1000)).isValid();

                        return isValidTimestamp;
                    }).typeError(validationsText.timestamp);
                    break; 
            }

            return isRequired ? validation.required() : validation;
        }

        fields.forEach(customField => {
            const yupValidationType = getValidationFromType(customField.type, customField.label, customField.isRequired);

            schema[customField.name] = yupValidationType;
        });

        return schema;
    }

    /**
     * Get initial values for Formik Form
     * 
     * @returns Object with the initial values config
     */
    function getInitialValuesWithCustomFields() {
        const initialValues = {
            profile_name: props.connectorName,
            profile_auth_method: props.connectorAuthMethod,
        };

        customPropsFields.forEach(field => {
            const currentValue = props.customProperties.find(el => el.name === field.name);
            initialValues[field.name] = currentValue?.value || field.defaultValue || "";
        });

        return initialValues;
    }

    /**
     *  Update the auth method associated with this connector
     *
     * @param newValue
     */
    function authMethodChangeHandler(newValue) {
        if (props.onChangeAuthMethod) {
            props.onChangeAuthMethod(AuthenticationMethods[newValue]);
        }
    }

    /**
     * Render the connector custom properties fields set in the config file
     *
     * @param properties
     */
    function renderCustomPropertiesFields(
        fields: Array<IntegrationConnectorPropertyField>,
        formProps: FormikProps<any>
    ) {
        if (!props?.customProperties || !fields || fields?.length === 0) {
            return <></>
        }

        return (
            <>
                {fields.map((field) => {
                    const property = props.customProperties.find(
                        (customProp) => customProp.name === field.name
                    );

                    if (!property) {
                        return null;
                    }

                    let inputType: 'string' | 'checkbox' | 'number' = 'string';

                    switch (field.type) {
                        case 'string':
                        case 'ipaddress':
                        case 'json':
                        case 'timestamp':
                            inputType = 'string';
                            break;
                        case 'bool':
                        case 'boolean':
                            inputType = 'checkbox';
                            break;
                        case 'int':
                        case 'integer':
                        case 'float':
                            inputType = 'number';
                            break;
                    }

                    if (inputType === 'checkbox') {
                        return <CheckboxField
                            key={field.name}
                            name={field.name}
                            label={field.label}
                            type={inputType} // The values for the properties are going to be sent as string
                            checked={property.value}
                            onChange={
                                (e) => {
                                    formProps.handleChange(e);
                                    props.onChangeCustomProperties( property.name, e.target.checked)
                                }
                            }
                            helperText={field.description}
                        />
                    }

                    return (
                        <InputField
                            key={field.name}
                            name={field.name}
                            label={field.label}
                            type={inputType} // The values for the properties are going to be sent as string
                            required={field.isRequired}
                            onChange={(e) => {
                                formProps.handleChange(e);
                                props.onChangeCustomProperties(property.name, e.target.value)}
                            }
                            helperText={field.description}
                            value={`${property.value}`}
                        />
                    );
                })}
            </>
        );
    }

    return (
        <div className={classNames(Classes.DIALOG_BODY)}>
            <p>
                <b>
                    {
                        STRINGS.thirdPartyIntegrations.addAuthProfile.panels
                            .basicDetails.title
                    }
                </b>
            </p>
            <Divider />
            <br />
            <Form
                initialValues={initialValues}
                validationSchema={validationSchema}
                loading={false}
            >
                {(formProps: FormikProps<object>) => <>
                <InputField
                    name="profile_name"
                    type="text"
                    required={true}
                    label={
                        STRINGS.INTEGRATIONS_PAGE.integrationDetailsModal
                            .connectorNameFieldLabel
                    }
                    placeholder={
                        STRINGS.thirdPartyIntegrations.addAuthProfile.panels
                            .basicDetails.fields.profileName.placeholder
                    }
                    onBlur={(event) => {
                        const value = event.target.value?.trim();

                        formProps.handleBlur(event);
                        props.onChangeName({target: {
                            value: value
                        }});
                    }}
                    onChange={props.onChangeName}
                    value={props.connectorName}
                    disabled={false}
                />
                {renderCustomPropertiesFields(customPropsFields, formProps)}
                {availableAuthMethods?.length > 0 &&
                    <SelectField
                        label={
                            STRINGS.thirdPartyIntegrations.addAuthProfile.panels
                                .basicDetails.fields.profileAuthMethod.label
                        }
                        name="profile_auth_method"
                        required={true}
                        disabled={availableAuthMethods?.length === 1} // If it is only one it will be preselected
                        options={[
                            STRINGS.thirdPartyIntegrations.addAuthProfile.panels
                                .basicDetails.fields.profileAuthMethod.selectMethod,
                        ].concat(availableAuthMethods)}
                        onChange={(event) => {
                            authMethodChangeHandler(event.target.value);
                        }}
                        value={
                            AuthenticationMethods[
                            getAuthMethod(props.connectorAuthMethod)
                            ]
                        }
                    />
                }
            </>}
            </Form>
        </div>
    );
};

export { ConnectorDetailsPanel };
