/** This file defines the cloudim summary view react component.
 *  @module */
import React from "react";
import { STRINGS } from 'app-strings';
import { Region } from "pages/cloudim/views/cloudim-geomap/CloudIMGeoMapView";

import "./CloudIMSummaryView.scss";

export interface CloudIMSummaryViewProps {
    regionDetail: Region;
    /** Style class to be applied to the outer container element */
    className?: string;
}

const CloudIMSummaryView = ({ regionDetail, className }: CloudIMSummaryViewProps): JSX.Element => {
    return <div className={"detailsFields" + (className ? " " + className : "")}>
        <div>
            <div className="drawerField"><span className="geoLabel">{STRINGS.cloudim.geoMap.details.provider}</span>{regionDetail.platform === "OnPrem" ? STRINGS.cloudim.geoMap.provider.onprem : regionDetail.platform}</div>
            <div className="drawerField"><span className="geoLabel">{STRINGS.cloudim.geoMap.details.code}</span>{regionDetail.name}</div>
            <div className="drawerField"><span className="geoLabel">{STRINGS.cloudim.geoMap.details.location}</span>{regionDetail.location}</div>
        </div>
        <div>
            <div className="drawerField"><span className="geoLabel">{STRINGS.cloudim.geoMap.details.assets}</span>{regionDetail.assets}</div>
            {Object.keys(regionDetail.resources).map(resourceTypeKey => (
                <div className="drawerField subMetric" key={resourceTypeKey}><span className="geoLabel">{resourceTypeKey + ": "}</span>{regionDetail.resources[resourceTypeKey]}</div>
            ))}
        </div>
    </div>
};

export { CloudIMSummaryView };