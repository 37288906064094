/** This file defines the cloudim detail view react component.
 *  @module */
import React from "react";
import { STRINGS } from 'app-strings';
import { SIZE } from 'components/enums';
import { Icon, IconNames } from "@tir-ui/react-components";
import { DetailsPanel } from "components/reporting";
import { Region } from "pages/cloudim/views/cloudim-geomap/CloudIMGeoMapView";
import { SummaryCard } from 'components/common/layout/summary-card/SummaryCard';
import { BladeContainer } from "components/common/layout/containers/blade-container/BladeContainer";

import { CloudIMSummaryView } from "./CloudIMSummaryView";
import { CloudIMTopologyView } from "./CloudIMTopologyView";
import "./CloudIMDetailsView.scss";

export interface CloudIMDetailsViewProps {
    regionDetail: Region;
    tokens?: Array<string>;
    onBladeClosed?: () => void;
}

const CloudIMDetailsView = ({ regionDetail, tokens, onBladeClosed }: CloudIMDetailsViewProps): JSX.Element => {
    return <DetailsPanel size={SIZE.l}>
        <BladeContainer
            title={<span>
                <Icon className="align-middle mx-1" icon={IconNames.MAP_MARKER} />
                <div className="d-inline-block align-middle font-size-large">{regionDetail.displayName}</div>
            </span>}
            className="cloudim-detail-blade"
            onCloseClicked={() => onBladeClosed && onBladeClosed()}
            noContentPadding
        >
            <CloudIMSummaryView className="cloudim-summary" regionDetail={regionDetail} />
            <SummaryCard className="w-100 cloudim-topology" title={STRINGS.cloudim.topology.cardTitle} alignTitle="left" titleFontWeight="font-weight-bold" titlePadding={false}>
                <CloudIMTopologyView regionDetail={regionDetail} tokens={tokens} />
            </SummaryCard>
        </BladeContainer>
    </DetailsPanel>
};

export { CloudIMDetailsView };