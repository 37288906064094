/** This file defines the ChartToolbar React component.  The ChartToolbar React component renders a
 *  a toolbar that allows the user to change some basic chart settings.
 *  @module */

import React, { useState } from 'react';
import { Icon, IconNames } from "@tir-ui/react-components";
import { BasicDialog, updateDialogState } from '../basic-dialog/BasicDialog';
import { STRINGS } from 'app-strings';
import { AnchorButton, Button, Checkbox, ControlGroup, HTMLSelect, Menu, MenuItem, PopoverPosition, Position } from '@blueprintjs/core';
import { Classes, Popover2, Popover2InteractionKind, Tooltip2 } from '@blueprintjs/popover2';
import "./ChartToolbar.css";

/** an interface that describes the properties that can be passed in to the chart toolbar component.*/
export interface ChartToolbarProps {
    /** a string with the type of chart.  These is needed to tell the settings dialog what type of settings to display.  */
    chartType: ChartType;
    /** the toolbar controls that should be displayed.  If empty all controls are shown.  If undefined no controls are shown. */
    controls?: Array<ChartToolbarControls>;
    /** the current set of chart settings. */
    settings: ChartSettings;
    /** notifies any listeners about a toolbar action. */
    notifyToolbarAction?: (type: ToolbarAction, value: any) => void;
}

/** this enum declares all the chart toolbar controls. */
export enum ChartToolbarControls {
    /** the key for the full screen button. */
    fullScreen  = "fullScreen",
    /** the key for the settings button. */
    settings    = "settings"
}

/** this enum declares all the chart types. */
export enum ChartType {
    /** the type for the bar chart. */
    bar         = "bar",
    /** the type for the pie chart. */
    pie         = "pie",
    /** the type for the bubble chart. */
    bubble      = "bubble",
    /** the type for the correlation chart. */
    correlation = "correlation",
    /** the type for the timeseries chart. */
    timeseries  = "timeseries",
    /** the type for the sankey chart. */
    sankey      = "sankey",
}

/** this interface defines the base chart settings. */
export interface ChartSettings {
    /** a boolean value, if true show the legend, if false hide the legend.  If not provided the legend will be shown. */
    showLegend?: boolean;
    /** the legend position. */
    legendPosition?: LegendPosition;
    /** a boolean value, if true show the more button, if false do not. */
    showMore?: boolean;
    /** a boolean value true if the icons should only be shown on hover and false if the icons should always be shown. */
    showIconsOnHover?: boolean;
}

/** this enum declares all the legend positions. */
export enum LegendPosition {
    /** specifies showing the legend at the top of the chart. */
    top     = "top",
    /** specifies showing the legend at the bottom of the chart. */
    bottom  = "bottom",
    /** specifies showing the legend to the left of the chart. */
    left    = "left",
    /** specifies showing the legend to the right of the chart. */
    right   = "right",
}

/** this interface defines the bar chart settings. */
export interface BarChartSettings extends ChartSettings {
	/** if true show the bars as stacked if false or undefined show them as grouped. */
	stacked?: boolean;
    /** a string with the orientation of the bars, horizontal or vertical. */
    orientation?: BarOrientation;
    /** a boolean value, true if the bar labels should be shown in the bar, false otherwise.  If
     *      it is not present it is assumed to be true. */
	showBarLabels?: boolean;
}

/** this enum declares all the bar orientations. */
export enum BarOrientation {
    /** the horizontal bar orientation. */
    horizontal  = "horizontal",
    /** the vertical bar orientation. */
    vertical    = "vertical"
}

/** this interface defines the sankey chart settings. */
export interface SankeyChartSettings extends ChartSettings {
    /** a string with the label for the from column. */
    fromLabel?: string;
    /** a string with the label for the to column. */
    toLabel?: string;
}

/** this interface defines the pie chart settings. */
export interface PieChartSettings extends ChartSettings {
	/** a String with the chart style where the valid options are pie or donut. */
	style?: PieStyle;
	/** a boolean value, true if the percentage should be shown in the pie slice label, false otherwise.  If
     *      it is not present it is assumed to be false. */
	showPercentage?: boolean;
    /** a boolean value, if true, show the value in the slice label, false otherwise.  If it is not 
     *      present it is assumed to be false. */
    showValue?: boolean;
}

/** this enum declares all the pie chart styles. */
export enum PieStyle {
    /** the style which displays the series in a pie. */
    pie     = "pie",
    /** the style which displays the series in a donut. */
    donut   = "donut",
}

/** this interface defines the bubble chart settings. */
export interface BubbleChartSettings extends ChartSettings {
    /** a boolean value, if true, show the value in the slice label, false otherwise.  If it is not 
     *      present it is assumed to be false. */
    showValue?: boolean;
}

/** this interface defines the time chart settings. */
export interface TimeChartSettings extends ChartSettings {
	/** a String with the chart style where the valid options are line or area. */
	style?: LineStyle;
}

/** this interface defines the time chart settings. */
export interface CorrelationChartSettings extends ChartSettings {
	/** a String with the chart style where the valid options are line or area. */
	style?: LineStyle;
}

/** this enum declares all the line chart styles. */
export enum LineStyle {
    /** the style which displays a line for each series. */
    line        = "line",
    /** the style which displays a area for each series. */
    area        = "area",
    /** the style which displays a stacked area for each series. */
    stackedArea = "stackedArea",
    /** the style which displays a line with steps for each series. */
    stepLine    = "stepLine",
    /** the style which displays an area with steps for each series. */
    stepArea    = "stepArea",
    /** the style which displays a bar for each series. */
    bar         = "bar"
}

/** this enum specifies all the supported toolbar action types.  The toolbar action type is passed
 *  to the handler for toolbar actions.*/
 export enum ToolbarAction {
    /** this enumerated type specifies that the user clicked on the show full screen button. */
    SHOW_FULL_SCREEN    = "show_full_screen",
    /** this enumerated type specifies that the user has changed the chart settings. */
    SETTINGS_CHANGED    = "settings_changed",
 }

/** Creates the the chart toolbar view.  This view contains a common toolbar for all the charts.
 *  @param props an object with the properties passed to the chart toolbar view.
 *  @returns JSX with the chart toolbar component.*/
export const ChartToolbar = (props: ChartToolbarProps): JSX.Element => {
    const [dialogState, setDialogState] = useState<any>({ showDialog: false, title: "My Dialog", loading: false, dialogContent: null, dialogFooter: null });

    // We need to use useCallback here
    function sendNotification (action: ToolbarAction, value: any = null): void {
        if (props.notifyToolbarAction) {
            props.notifyToolbarAction(action, value);
        }
    }

    const moreMenuItems: Array<JSX.Element> = [];
    if (props.controls) {
        if (props.controls.length === 0 || props.controls.includes(ChartToolbarControls.fullScreen)) {
            moreMenuItems.push(
                <MenuItem disabled={false} text={STRINGS.chartToolbar.fullScreenMenuItem} active={false} key={"full-screen"}
                    onClick={() => sendNotification(ToolbarAction.SHOW_FULL_SCREEN)} 
                />
            );
        }
        if (props.controls.length === 0 || props.controls.includes(ChartToolbarControls.settings)) {
            moreMenuItems.push(
                <MenuItem disabled={false} text={STRINGS.chartToolbar.settingsMenuItem} active={false} key={"settings"}
                    onClick={() => showSettingsDialog(props.settings, props.chartType, setDialogState, sendNotification)} 
                />
            );
        }
    }

    return <>
        <BasicDialog dialogState={dialogState} onClose={() => setDialogState(updateDialogState(dialogState, false, false, []))} />
        {props.controls && <div className={"chart-toolbar pr-2" + (props.settings.showIconsOnHover ? " hover-only" : "")}>
            {!props.settings.showMore && <>
                {(props.controls.length === 0 || props.controls.includes(ChartToolbarControls.fullScreen)) && 
                    <Tooltip2 className={Classes.TOOLTIP2_INDICATOR + " border-0"} content={STRINGS.chartToolbar.fullscreenTooltip} position={PopoverPosition.RIGHT} transitionDuration={50}>
                        <Icon icon={IconNames.FULLSCREEN} onClick={() => sendNotification(ToolbarAction.SHOW_FULL_SCREEN)} className="chart-toolbar-button" />
                    </Tooltip2>
                }
                {(props.controls.length === 0 || props.controls.includes(ChartToolbarControls.settings)) && 
                    <Tooltip2 className={Classes.TOOLTIP2_INDICATOR + " border-0"} content={STRINGS.chartToolbar.settingsTooltip} position={PopoverPosition.RIGHT} transitionDuration={50}>
                        <Icon icon={IconNames.COG} onClick={() => showSettingsDialog(props.settings, props.chartType, setDialogState, sendNotification)} className="chart-toolbar-button ml-2" />
                    </Tooltip2>
                }
            </>}
            {props.settings.showMore && 
                <Popover2 position={Position.BOTTOM_RIGHT} 
                    interactionKind={Popover2InteractionKind.CLICK} 
                    content={
                        <Menu>{moreMenuItems}</Menu>
                } >
                    <Tooltip2 className={Classes.TOOLTIP2_INDICATOR + " border-0"} content={STRINGS.chartToolbar.moreTooltip} position={PopoverPosition.RIGHT} transitionDuration={50}>
                        <AnchorButton aria-label="chart-toolbar-more-button" icon={IconNames.MORE} minimal className="chart-toolbar-action-icon" 
                            disabled={false} onClick={(e) => {}} 
                        />
                    </Tooltip2>
                </Popover2>
            }
        </div>}
    </>;
}

/** Creates a popup that allows the user to specify the chart settings.
 *  @param settings the object with the settings to display.
 *  @param chartType a String with the chart type.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function.
 *  @param sendNotification this function must be provided and is used to send out change notifications. */
function showSettingsDialog(
    settings: ChartSettings, chartType: ChartType, 
    setDialogState: (state: any) => void, sendNotification: (action: ToolbarAction, value: any) => void
): void {
    settings = JSON.parse(JSON.stringify(settings));

    const styleOptions: Array<{ label: string, value: string }> = [];
    if (chartType === ChartType.pie) {
        styleOptions.push({ label: STRINGS.runbookEditor.nodeLibrary.propertyLabels.pieOption, value: PieStyle.pie });
        styleOptions.push({ label: STRINGS.runbookEditor.nodeLibrary.propertyLabels.donutOption, value: PieStyle.donut });    
    } else if (chartType === ChartType.timeseries) {
        styleOptions.push({ label: STRINGS.runbookEditor.nodeLibrary.propertyLabels.areaOption, value: LineStyle.area });
        styleOptions.push({ label: STRINGS.runbookEditor.nodeLibrary.propertyLabels.lineOption, value: LineStyle.line });    
        styleOptions.push({ label: STRINGS.runbookEditor.nodeLibrary.propertyLabels.stackedAreaOption, value: LineStyle.stackedArea });
        styleOptions.push({ label: STRINGS.runbookEditor.nodeLibrary.propertyLabels.stepAreaOption, value: LineStyle.stepArea });
        styleOptions.push({ label: STRINGS.runbookEditor.nodeLibrary.propertyLabels.stepLineOption, value: LineStyle.stepLine });    
    }

    const orientationOptions: Array<{ label: string, value: string }> = [];
    orientationOptions.push({ label: STRINGS.runbookEditor.nodeLibrary.propertyLabels.horizontalOption, value: BarOrientation.horizontal });
    orientationOptions.push({ label: STRINGS.runbookEditor.nodeLibrary.propertyLabels.verticalOption, value: BarOrientation.vertical });

    const legendOptions: Array<{ label: string, value: string }> = [];
    legendOptions.push({ label: STRINGS.runbookEditor.nodeLibrary.propertyLabels.topOption, value: LegendPosition.top });
    legendOptions.push({ label: STRINGS.runbookEditor.nodeLibrary.propertyLabels.bottomOption, value: LegendPosition.bottom });
    legendOptions.push({ label: STRINGS.runbookEditor.nodeLibrary.propertyLabels.leftOption, value: LegendPosition.left });
    legendOptions.push({ label: STRINGS.runbookEditor.nodeLibrary.propertyLabels.rightOption, value: LegendPosition.right });

    const newDialogState: any = { showDialog: true, loading: false, title: STRINGS.chartToolbar.chartSettingsDialogTitle };
    newDialogState.dialogContent = <table><tbody>
        {chartType === ChartType.bar && <>
            <tr key="style">
                <td className="p-1"><label>{STRINGS.runbookEditor.nodeLibrary.propertyLabels.style}:</label></td>
                <td className="p-1"><HTMLSelect id="style" name="style"
                    defaultValue={(settings as BarChartSettings).orientation}
                    onChange={(event) => (settings as BarChartSettings).orientation = (event.target.value === "vertical" ? BarOrientation.vertical : BarOrientation.horizontal)}
                    options={orientationOptions} className="editor-input-standard"
                /></td>
            </tr>
            <tr key="show-labels">
                <td className="p-1" colSpan={2}><Checkbox type="checkbox" id="showLabels" name="showLabels"
                    label={STRINGS.runbookEditor.nodeLibrary.propertyLabels.showBarLabels}
                    defaultChecked={(settings as BarChartSettings).showBarLabels}
                    onChange={(event: any) => (settings as BarChartSettings).showBarLabels = event.target.checked}
                /></td>
            </tr>
            <tr key="stack-bars">
                <td className="p-1" colSpan={2}><Checkbox type="checkbox" id="stackBars" name="stackBars"
                    label={STRINGS.runbookEditor.nodeLibrary.propertyLabels.stackBars}
                    defaultChecked={(settings as BarChartSettings).stacked}
                    onChange={(event: any) => (settings as BarChartSettings).stacked = event.target.checked}
                /></td>
            </tr>
        </>}
        {(chartType === ChartType.timeseries)  && 
            <tr key="style">
                <td className="p-1"><label>{STRINGS.runbookEditor.nodeLibrary.propertyLabels.style}:</label></td>
                <td className="p-1"><HTMLSelect id="style" name="style"
                    defaultValue={(settings as TimeChartSettings).style}
                    onChange={(event) => (settings as TimeChartSettings).style = event.target.value as LineStyle}
                    options={styleOptions} className="editor-input-standard"
                /></td>
            </tr>
        }
        {(chartType === ChartType.pie)  && 
            <tr key="style">
                <td className="p-1"><label>{STRINGS.runbookEditor.nodeLibrary.propertyLabels.style}:</label></td>
                <td className="p-1"><HTMLSelect id="style" name="style"
                    defaultValue={(settings as PieChartSettings).style}
                    onChange={(event) => (settings as PieChartSettings).style = event.target.value as PieStyle}
                    options={styleOptions} className="editor-input-standard"
                /></td>
            </tr>
        }
        {chartType === ChartType.pie && <>
            <tr key="show-percentage">
                <td className="p-1" colSpan={2}><Checkbox type="checkbox" id="showPercentage" name="showPercentage"
                    label={STRINGS.runbookEditor.nodeLibrary.propertyLabels.showPercentage}
                    defaultChecked={(settings as PieChartSettings).showPercentage}
                    onChange={(event: any) => (settings as PieChartSettings).showPercentage = event.target.checked}
                /></td>
            </tr>
            <tr key="show-value">
                <td className="p-1" colSpan={2}><Checkbox type="checkbox" id="showValue" name="showValue"
                    label={STRINGS.runbookEditor.nodeLibrary.propertyLabels.showValue}
                    defaultChecked={(settings as PieChartSettings).showValue}
                    onChange={(event: any) => (settings as PieChartSettings).showValue = event.target.checked}
                /></td>
            </tr>
        </>}
        {chartType === ChartType.bubble && <>
            <tr key="show-value">
                <td className="p-1" colSpan={2}><Checkbox type="checkbox" id="showValue" name="showValue"
                    label={STRINGS.runbookEditor.nodeLibrary.propertyLabels.showValue}
                    defaultChecked={(settings as BubbleChartSettings).showValue}
                    onChange={(event: any) => (settings as BubbleChartSettings).showValue = event.target.checked}
                /></td>
            </tr>
        </>}
        {chartType !== ChartType.bubble && <>
        <tr key="show-legend">
            <td className="p-1" colSpan={2}>
                <ControlGroup fill={true} vertical={false} >
                    <Checkbox type="checkbox" id="showLegend" name="showLegend" className="pt-1"
                        label={STRINGS.runbookEditor.nodeLibrary.propertyLabels.showLegend}
                        defaultChecked={settings.showLegend}
                        onChange={(event: any) => {
                            settings.showLegend = event.target.checked;
                            (document.getElementById("legend-position") as HTMLInputElement).disabled = !settings.showLegend;
                        }}
                    />
                    <HTMLSelect id="legend-position" name="legend-position"
                        disabled={!settings.showLegend}
                        defaultValue={settings.legendPosition}
                        onChange={(event) => settings.legendPosition = event.target.value as LegendPosition}
                        options={legendOptions} className="editor-input-standard ml-3"
                    />
                </ControlGroup>
            </td>
        </tr>
        </>}
    </tbody></table>;
    newDialogState.dialogFooter = <Button active={true} outlined={true}
        text={"SAVE"}
        onClick={(evt) => {
            sendNotification(ToolbarAction.SETTINGS_CHANGED, settings);
            setDialogState(updateDialogState(newDialogState, false, false, []));
        }}
    />;
    setDialogState(newDialogState);
}
