/** This module contains the component for displaying the facet settings for the facet category.
 *  @module
 */
import React, { useState, useRef, useCallback, useEffect } from "react";
import { Classes, InputGroup, Label, NumericInput, Tag } from "@blueprintjs/core";
import { Facet, FacetSummary, FacetValue, SearchItem, SearchRequest, SearchResult } from "utils/services/SearchApiService";
import { STRINGS } from "app-strings";
import { Table, TableColumnDef, useStateSafePromise } from "@tir-ui/react-components";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { cloneDeep } from 'lodash';
import { FACET_FILTER_COLORS } from "components/enums";
import { SearchCorrelationService } from "utils/services/SearchCorrelationApiService";
import { useUserPreferences } from "utils/hooks";
import { SearchPreference, DEFAULT_SEARCH_PREF, SEARCH_ENGINE } from "utils/services/UserPrefsTypes";
import { useApolloClient } from "@apollo/client";
import { SearchGraphqlApiService, arrayFacetsNotInSearchFields } from "utils/services/search/SearchGraphqlApiService";

/** a bolean value, if true show the limit control and use that limit for the search query. */
const SHOW_LIMIT_CONTROL: boolean = false;
/** if the limit control is not used, then this is the limit that should be used in the search query. */
const FACET_COUNT: number = 50000;

/** this interface defines the facet settings. */
export interface FacetSettings {
     /** a number with the limit for the facet category. */
    facetCategoryLimit: number;
    /** the search string to use for the facets. */
    facetSearchString: string;
    /** an optional array with the selected facets. */
    selectedFacets?: Array<Facet>;
}

/** this interface defines the properties passed into the facet section React component. */
export interface FacetSectionSettingsProps {
    /** a string with the facet category. */
    facetCategory: string;
    /** the current set of facet settings. */
    facetSettings: FacetSettings;
    /** the list of facets. */
    facets?: Array<Facet>;
    /** the current search request with all the filters including the facets. */
    searchRequest: SearchRequest | undefined;
    /** the search request object to use to do a search with the current time range and search string but no facets. */
    searchRequestWithNoFacets: SearchRequest | undefined;
    /** the handler for facet settings changes. */
    onFacetSettingsChanged: (facetSettings: FacetSettings) => void;
    /** the SEARCH_TYPE with the current displayed type of search results. */
    searchType?: string;
}

/** Renders the facet section view.
 *  @param props the properties passed in.
 *  @returns JSX with the facet section view component.*/
export const FacetSectionSettings = (props: FacetSectionSettingsProps, ref: any): JSX.Element => {
    const fullFacetList = useRef<Array<Facet>>((props.facets || []).map(facet => {return {...facet, count: 0}}));
    const [displayedFacets, setDisplayedFacets] = useState<Array<Facet>>(props.facets || []);

    const currentLimit = useRef<number>(props.facetSettings.facetCategoryLimit);
    const currentSearch = useRef<string>(props.facetSettings.facetSearchString);

    const [searchRequest] = useState<SearchRequest | undefined>(props.searchRequest);
    const [searchRequestWithNoFacets, setSearchRequestWithNoFacets] = useState<SearchRequest | undefined>(props.searchRequestWithNoFacets);

    const columns: Array<TableColumnDef> = getTableColumns(props.facetCategory, props.searchType || "");

    const loading = useRef<number>(0);

    const userPreferences = useUserPreferences({listenOnlyTo: {search: {savedQueries: []}}});
    const searchPreferences: SearchPreference = {...DEFAULT_SEARCH_PREF, ...userPreferences.search};

    const apolloClient = useApolloClient();
    const searchGraphqlService = useRef<SearchGraphqlApiService>(new SearchGraphqlApiService(apolloClient));

    const [executeSafely] = useStateSafePromise();
    const runCognitiveSearch = useCallback(
        (request: SearchRequest, full: boolean) => {
            const facetLimit: number = SHOW_LIMIT_CONTROL ? currentLimit.current : FACET_COUNT;
            if (request && request.facets) {
                // TODO: need to correct `SearchRequest` type for parameter
                // `request` as it can't infer if `searchFields` exists
                const facetCategoryName =
                    (request as any)?.searchFields?.includes(
                        props.facetCategory,
                    ) ||
                    arrayFacetsNotInSearchFields.includes(props.facetCategory) ||
                    request.type === "Incident" || 
                    request.type === "CustomProperty" ||
                    request.type === "TcpConnection" ||
                    request.type === "OndemandRunbooks"
                        ? props?.facetCategory
                        : "CUSTOM_PROPERTY_VALUE_NAME";
                const facetCategoryObject = {
                    name: facetCategoryName,
                    skip: 0,
                    top: facetLimit,
                    count: true,
                    // dynamically create the property if necessary
                    ...(facetCategoryName === "CUSTOM_PROPERTY_VALUE_NAME" && {
                        groupBy: "CUSTOM_PROPERTY_NAME",
                    }),
                };
                // We only want to return the facets for the current face category
                request.facets = [facetCategoryObject];
            }
            let searchService: {search: (searchRequest: SearchRequest) => Promise<SearchResult<SearchItem>>} | null = null;
            switch (searchPreferences.srchEngine) {
                case SEARCH_ENGINE.correlation_direct:
                    searchService = SearchCorrelationService;
                    break;
                case SEARCH_ENGINE.correlation_dal:
                    searchService = searchGraphqlService.current;
                    break;
            }
            return executeSafely(searchService!.search(request!)).then((searchResult: SearchResult<SearchItem>) => {
                loading.current--;
                if (searchResult) {
                    const facetResults: Record<string, Facet[]> | Record<string, FacetSummary> | undefined = searchResult.facets;
                    if (facetResults) {
                        const newFullFacetList: Array<Facet> = [...fullFacetList.current];
                        const facetsFromQuery: Facet[] = ((facetResults[props.facetCategory] as FacetSummary)?.items) || [];
                        for (const facet of facetsFromQuery) {
                            let foundFacet: Facet | null = null;
                            for (const existingFacet of newFullFacetList) {
                                if (facet.value === existingFacet.value) {
                                    foundFacet = existingFacet;
                                    break;
                                }
                            }
                            if (foundFacet) {
                                foundFacet.count = full ? facet.count : foundFacet.count;
                            } else {
                                newFullFacetList.push({...facet, count: full ? facet.count : 0, isGroupedFacet: (facetResults[props.facetCategory] as FacetSummary).isGroupedFacet === true })
                            }
                        }
                        const facets = (newFullFacetList.filter((facet) => {
                            const displayName = STRINGS.incidentSearch?.facetView?.facets[props.facetCategory] && STRINGS.incidentSearch?.facetView?.facets[props.facetCategory].valueDisplayMap &&
                                facet.value !== null && STRINGS.incidentSearch?.facetView?.facets[props.facetCategory].valueDisplayMap[facet.value.toString()]
                                ? STRINGS.incidentSearch?.facetView?.facets[props.facetCategory].valueDisplayMap[facet.value.toString()] : String(facet.value);
                            return displayName.toString().match(new RegExp(".*" + currentSearch.current + ".*", "i")) !== null;
                        }) || []).sort((a: Facet, b: Facet) => {
                            const aCount = a.count || 0;
                            const bCount = b.count || 0;
                            return aCount < bCount ? -1 : bCount < aCount ? 1 : 0;
                        }).reverse();
                        fullFacetList.current = newFullFacetList;
                        setDisplayedFacets(facets);
                    }
                }
            }, error => {
                error.current = true;
                console.error(error);
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ executeSafely, searchRequestWithNoFacets ]
    );

    const cachedSearchRequest = useRef<SearchRequest | undefined>(SHOW_LIMIT_CONTROL ? props.searchRequest : undefined);
    const cachedSearchRequestWithNoFacets = useRef<SearchRequest | undefined>(SHOW_LIMIT_CONTROL ? props.searchRequestWithNoFacets : undefined);
    
    useEffect(() => {
        if (searchRequest !== cachedSearchRequest.current) {
            loading.current++;
            cachedSearchRequest.current = searchRequest;
            runCognitiveSearch(searchRequest!, true);
        }
        if (searchRequestWithNoFacets !== cachedSearchRequestWithNoFacets.current) {
            loading.current++;
            cachedSearchRequestWithNoFacets.current = searchRequestWithNoFacets;
            runCognitiveSearch(searchRequestWithNoFacets!, false);
        }
    }, [searchRequest, searchRequestWithNoFacets, runCognitiveSearch]);

    const initSelection: Array<FacetValue> = [];
    for (const facet of (props.facets || [])) {
        if (facet.selected) {
            initSelection.push(facet.value);
        }
    }

    const selectedFacetValues = useRef<Array<FacetValue>>(initSelection);
    const selectedFacets: Array<string> = [];
    if (displayedFacets) {
        for (let facetIndex = 0; facetIndex < displayedFacets.length; facetIndex++) {
            if (selectedFacetValues.current.includes(displayedFacets[facetIndex].value)) {
                selectedFacets.push(facetIndex.toString());
            }
        }
    }

    const [tempFacets, setTempFacets] = useState<Array<Facet>>([]);

    const onValueChangeHandler = (valueAsNumber: number) => {
        const newLimit: number = valueAsNumber;
        currentLimit.current = newLimit;
        props.onFacetSettingsChanged({
            facetCategoryLimit: currentLimit.current,
            facetSearchString: currentSearch.current,
        });
        if (props.searchRequestWithNoFacets) {
            const newSearchRequest: SearchRequest = cloneDeep(
                searchRequestWithNoFacets,
            );
            setSearchRequestWithNoFacets(newSearchRequest);
        }
    };

    const onChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement>,
    ): void => {
        const newSearchString: string = event.target.value;
        currentSearch.current = newSearchString;
        props.onFacetSettingsChanged({
            facetCategoryLimit: currentLimit.current,
            facetSearchString: currentSearch.current,
        });
        const facets =
            fullFacetList.current.filter((facet) => {
                const displayName =
                    STRINGS.incidentSearch?.facetView?.facets[
                        props.facetCategory
                    ] &&
                    STRINGS.incidentSearch?.facetView?.facets[
                        props.facetCategory
                    ].valueDisplayMap &&
                    STRINGS.incidentSearch?.facetView?.facets[
                        props.facetCategory
                    ].valueDisplayMap[facet.value.toString()]
                        ? STRINGS.incidentSearch?.facetView?.facets[
                              props.facetCategory
                          ].valueDisplayMap[facet.value.toString()]
                        : String(facet.value);
                return (
                    displayName
                        .toString()
                        .match(
                            new RegExp(".*" + newSearchString + ".*", "i"),
                        ) !== null
                );
            }) || [];
        setDisplayedFacets(facets);
    };

    return <DataLoadFacade loading={loading.current > 0} error={undefined} data={undefined} showContentsWhenLoading={true}>
        {SHOW_LIMIT_CONTROL && <Label className={Classes.INLINE}>{STRINGS.incidentSearch.facetView.facetSettingsDialog.limitLabel} 
            <NumericInput min={0} defaultValue={props.facetSettings.facetCategoryLimit} onValueChange={(valueAsNumber: number) => onValueChangeHandler(valueAsNumber)}/>
        </Label>}
        <Label className={Classes.INLINE}>{STRINGS.incidentSearch.facetView.facetSettingsDialog.searchLabel} 
            <InputGroup min={0} 
                defaultValue={props.facetSettings.facetSearchString}
                className="w-min-4" 
                onChange={(event) => onChangeHandler(event)}
            />
        </Label>
        <div className="h-max-1 overflow-auto">
            Selected items:
            {tempFacets.map((tempFacet, i) => {
                const label = tempFacet.value;
                const value = label + "";
                const displayName = STRINGS.incidentSearch?.facetView?.facets[props.facetCategory] && STRINGS.incidentSearch?.facetView?.facets[props.facetCategory].valueDisplayMap &&
                STRINGS.incidentSearch?.facetView?.facets[props.facetCategory].valueDisplayMap[value]
                ? STRINGS.incidentSearch?.facetView?.facets[props.facetCategory].valueDisplayMap[value] : label;
                return <Tag key={i} title={(tempFacet.value || "").toString()} 
                        style={{ backgroundColor: FACET_FILTER_COLORS[props.facetCategory] }} 
                        className={"filter-tag ml-1 my-1 py-1 px-2 align-middle" + (true ? "" : " opacity-non-essential")}
                        >{displayName}</Tag>
        })}
        </div>
        <Table
            id="facet-settings-facet-search-table"
            key="facet-settings-facet-search-table"
            className="facet-settings-facet-search-table display-9 overflow-y-auto h-max-4-5 h-min-4"
            columns={columns}
            columnDefinitionDefaults={{
                headerClassName: "text-nowrap w-min-1-5 display-9",
                className: "display-9",
            }}
            data={displayedFacets}
            defaultPageSize={10}
            enableSelection={true}
            resetSelectionsOnPaginate={false}
            selectOnRowClick={false}
            onSelectionChange={(rows: Array<any>) => {
                const modSelectedFacets: Array<Facet> = [];
                for (const row of rows) {
                    modSelectedFacets.push({value: row.value, count: row.count, selected: true, isGroupedFacet: row.isGroupedFacet === true});
                    selectedFacetValues.current.push(row.value);
                }
                setTempFacets(modSelectedFacets);
                props.onFacetSettingsChanged(
                    {facetCategoryLimit: currentLimit.current, facetSearchString: currentSearch.current, selectedFacets: modSelectedFacets}
                );
            }}
            selectedRows={selectedFacets}
        />
    </DataLoadFacade>;
};

/** This function returns the list of table columns.
 *  @param facetCategory a string with the facet category
 *  @param searchType a String with the search type.
 *  @returns the Array of TableColumnDefs. */
function getTableColumns(facetCategory: string, searchType: string): Array<TableColumnDef> {
    return [
        {
            Header: STRINGS.incidentSearch.facetView.facetSettingsDialog.columns.name,
            id: "name",
            accessor: "name",
            headerClassName: "text-nowrap w-min-0-5 display-9",
            sortable: true,
            //sortFunction: sortBasedOnPriority,
            sortDescFirst: true,
            formatter: row => {
                const displayName = STRINGS.incidentSearch?.facetView?.facets[facetCategory] && STRINGS.incidentSearch?.facetView?.facets[facetCategory].valueDisplayMap &&
                    STRINGS.incidentSearch?.facetView?.facets[facetCategory].valueDisplayMap[row?.value]
                    ? STRINGS.incidentSearch?.facetView?.facets[facetCategory].valueDisplayMap[row?.value] : row?.value || STRINGS.incidentSearch.facetView.facetXcases.Unset;
                return displayName;
            }
        },
        {
            Header: STRINGS.formatString(
                STRINGS.incidentSearch.facetView.facetSettingsDialog.columns.count, 
                {type: STRINGS.incidentSearch.facetView.facetSettingsDialog.typeNames[searchType || "Incident"] || ""}
            ),
            id: "count",
            accessor: "count",
            className: " ",
            headerClassName: "text-nowrap w-max-0-5 display-9",
            style: { minWidth: "100px"},
            sortable: true,
            //sortFunction: sortBasedOnIncidentStatus,
            sortDescFirst: true,
            formatter: row => row?.count > 0  ? row.count : "",
        }
    ];
}
