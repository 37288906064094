/** This module contains the component for displaying the list of indicators in a table.
 *  @module
 */
import React, { useMemo, useState, useRef, useEffect } from "react";
import { IconNames, Table, TableColumnDef } from "@tir-ui/react-components";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { 
    FILTERS_OBJECT, FILTER_NAME, parseTimeFromDAL, parseTimeFromDALToUnix, TIME_RANGE, useQuery, 
    useQueryParams, useStateSafePromise 
} from "utils/hooks";
import { Query } from "reporting-infrastructure/data-hub";
import { loader } from "graphql.macro";
import { formatAndScaleMetricValue, formatToLocalTimestamp } from "reporting-infrastructure/utils/formatters";
import { STRINGS } from "app-strings";
import { Tooltip2 } from "@blueprintjs/popover2";
import { ElapsedTimeFormatter } from "reporting-infrastructure/utils/formatters/elapsed-time-formatter/elapsed-time-formatter";
import { Unit } from "reporting-infrastructure/types/Unit.class";
import { TimeseriesTile } from "components/reporting";
import { ListWithOverflow } from "components/common/list-with-overflow/ListWithOverflow";
import { 
    INDICATOR_TYPE, isBaselineIndicatorType, isHighIndicatorType, isLowIndicatorType, isLowOrHighIndicatorType, 
    TIME_FORMAT 
} from "components/enums";
import { getLabelForMetric, getUnitForMetric } from "utils/stores/GlobalUnitsStore";
import { DURATION, durationToRoundedTimeRange } from "utils/stores/GlobalTimeStore";
import { getEntityName } from "utils/runbooks/EntityUtils";
import { IndicatorDetailedView } from "../indicator-detailed-view/IndicatorDetailedView";
import { IndicatorKind } from "components/hyperion/controls/indicator-kind/IndicatorKind";
import { formatTimeToUserFriendlyString } from "reporting-infrastructure/utils/formatters/date-formatter/DateFormatter";
import { MultiSelectFilterControl } from "./MultiSelectFilterControl";
import { BasicDialog, updateDialogState } from "components/common/basic-dialog/BasicDialog";
import { AnchorButton, Button } from "@blueprintjs/core";
import { PARAM_NAME } from "components/enums/QueryParams";
import { JsonViewer } from "pages/incident-details/views/primary-indicator/JsonViewer";
import { CopyToClipboard } from "react-copy-to-clipboard";
import cloneDeep from "lodash/cloneDeep";
import { annotateTimestamp } from "pages/incident-details/views/primary-indicator/PrimaryIndicatorView";
import { Incident, Indicator, IndicatorMapping } from "pages/incident-list/Incident.type";
import { RunbookOutput } from "pages/riverbed-advisor/views/runbook-view/Runbook.type";
import { DataOceanMetadata } from "components/common/graph/editors/data-ocean/DataOceanMetadata.type";
import { DataOceanUtils } from "components/common/graph/editors/data-ocean/DataOceanUtils";
import './IndicatorsListView.css';

/** an array with the all the status metrics since they are handled as a special case. */
const STATUS_METRICS = ["device_status", "iface_status", "admin_status", "op_status"];

/** this interface defines the properties passed into the React component. */
export interface indicatorListViewProps {
    /** the filters to use to filter the list. */
    filters?: FILTERS_OBJECT;
    /** the timerange between which you expect to find all the indicators. */
    indicatorsTimeRange?: TIME_RANGE;
    /** the timerange of the incident. */
    incidentTimeRange?: TIME_RANGE;
    /** a boolean value, if true show the summary information, if false do not.  The summary includes the indicator 
     *  count.*/
    showSummary?: boolean;
    /** the list of indicator mappings to use to filter the indicator list. */
    indicatorMappings: IndicatorMapping | IndicatorMapping[];
    /** a string with the class name to use on the root div for the component. */
    className?: string;
    /** a string with the primary entity uuid. */
    primaryEntityId?: string;
    /** the primary indicator.  This should be used to exclude the primary indicator from the list in some views. */
    primaryIndicator?: Indicator;
    /** the incident whose indicator list is being displayed. */
    incident?: Incident;
    /** the list of runbook outputs to use for the annotations on the chart. */
    runbooks?: Array<RunbookOutput>;
}

/** Renders the indicator list view component.
 *  @param props the properties passed into the component.
 *  @returns JSX with the indicator list view component.*/
export function IndicatorsListView({
    indicatorMappings,
    indicatorsTimeRange = durationToRoundedTimeRange(DURATION.HOUR_6),
    showSummary = true,
    filters = {},
    className,
    primaryEntityId,
    incident,
    runbooks,
    primaryIndicator,
    ...props
}: indicatorListViewProps): JSX.Element {
    // TBD: Apollo Client merges all records with same ID value even when I tried to set the fetch-type as "no-cache".
    // To circumvent this problem, I am running 2 queries; one to fetch the data without ID property and another to
    // fetch just the IDs. I combine the results of the 2 queries in the UI after it's received. We need a proper solution
    // for this behavior. (Possibly change the ID key in DAL from the standard "id" to "indicatorId" or something non-standard)
    const { loading, data, error } = useQuery({
        name: "IndicatorList",
        query: new Query(loader("./indicators-list-query.graphql")),
        filters: {
            [FILTER_NAME.indicatorMapping]: indicatorMappings,
            ...filters
        },
        consumedFilters: [FILTER_NAME.indicatorMapping],
        requiredFilters: [FILTER_NAME.indicatorMapping],
        time: indicatorsTimeRange as TIME_RANGE,
    });
    
    const [executeSafely] = useStateSafePromise();
    const objMetricMetaData = useRef<DataOceanMetadata>();
    useEffect(() => {
        executeSafely(DataOceanUtils.init()).then(
            (response: DataOceanMetadata) => {
                objMetricMetaData.current = response;
            },
            (error) => {
                console.error(error);
            }
        );
    }, [executeSafely]);

    const columns: TableColumnDef[] = [
        {
            id:  "entityName",
            Header: STRINGS.incidents.columns.entity,
            sortable: true,
            showFilter: true,
            // The subrow does not show the name and is empty
            //formatter: row => row.entitySubRow ? "" : row.sourceName ? <div className="d-flex">{row.entityName}<div className="ml-2">seen on {row.sourceName}</div></div> : row.entityName,
            // The subrow does show the name
            formatter: row => row.sourceName ? <div className="d-flex">{row.entityName}<div className="ml-2">seen on {row.sourceName}</div></div> : row.entityName,
        },
        {
            id: "entityKind",
            Header: STRINGS.incidents.columns.entityType,
            sortable: true,
            showFilter: true,
            multiValueFilter: true,
            filterControl: MultiSelectFilterControl,
            // The subrow does not show the kind and is empty
            //formatter: row => row.entitySubRow ? "" : (row.entityKind ? (STRINGS.incidents.entityKinds[row.entityKind] || row.entityKind) : STRINGS.noData),
            // The subrow does show the kind
            formatter: row => (row.entityKind ? (STRINGS.incidents.entityKinds[row.entityKind] || row.entityKind) : STRINGS.noData),
        },
        {
            id: "metric",
            Header: STRINGS.incidents.columns.metric,
            showFilter: true,
            multiValueFilter: true,
            filterControl: MultiSelectFilterControl,
            formatter: row => {
                if (Array.isArray(row.metric)) {
                    const localizedMetricNames = row.metric.map(metric => (getLabelForMetric(metric) || metric));
                    return <ListWithOverflow items={localizedMetricNames} overflowAfter={2}/>
                } else {
                    return getLabelForMetric(row.metric) || row.metric || <span>&#8212;</span>;
                }
            },
        },
        {
            id: "kind",
            Header: STRINGS.incidents.columns.indicatorType,
            showFilter: true,
            multiValueFilter: true,
            filterControl: MultiSelectFilterControl,
            formatter: row => {
                if (row.kind) {
                    return <IndicatorKind kind={row.kind || ""}/>;
                } else {
                    return <span>&#8212;</span>;
                }
            },
        },
        {
            id: "expected",
            Header: STRINGS.incidents.columns.expectedRange,
            formatter: row => {
                let output = "";
                if (STATUS_METRICS.includes(row.metric)) {
                    // We always expect a 1 according to Damien
                    if (objMetricMetaData.current) {
                        output = "1 (" + (objMetricMetaData.current as any).metrics[row.metric].enum["1"] + ")";
                    }
                } else if ((row.acceptableLow !== null && row.acceptableHigh !== null) && isLowOrHighIndicatorType(row.kind) &&
                 (row.expected !== undefined && row.expected !== null)) {
                    output = STRINGS.formatString(
                        STRINGS.incidents.indicators.expectedRange, {
                            low: formatMetricValue(row.acceptableLow, row.metric),
                            high: formatMetricValue(row.acceptableHigh, row.metric)
                        }
                    );
                } else if (row.acceptableLow !== null && isLowIndicatorType(row.kind)) {
                    if (row.expected === undefined || row.expected === null) {
                        output = STRINGS.formatString(STRINGS.incidents.indicators.expectedAbove, formatMetricValue(row.acceptableLow, row.metric));
                    } else {
                        output = STRINGS.formatString(
                            STRINGS.incidents.indicators.expectedAroundDownTo, {
                                value: formatMetricValue(row.expected, row.metric),
                                low: formatMetricValue(row.acceptableLow, row.metric)
                            }
                        )
                    }
                } else if (row.acceptableHigh !== null && isHighIndicatorType(row.kind)) {
                    if (row.expected === undefined || row.expected === null) {
                        output = STRINGS.formatString(STRINGS.incidents.indicators.expectedBelow, formatMetricValue(row.acceptableHigh, row.metric));
                    } else {
                        output = STRINGS.formatString(
                            STRINGS.incidents.indicators.expectedAroundUpTo, {
                                value: formatMetricValue(row.expected, row.metric),
                                high: formatMetricValue(row.acceptableHigh, row.metric)
                            }
                        )
                    }
                }
                if (output) {
                    return output;
                } else {
                    return <span>&#8212;</span>;
                }
            }
        },
        {
            id: "actual",
            Header: STRINGS.incidents.columns.actual,
            formatter: row => {
                const value = row.actual !== undefined ? formatMetricValue(row.actual, row.metric) : "";
                if (STATUS_METRICS.includes(row.metric) && value !== undefined && value !== null && (objMetricMetaData.current as any)?.metrics[row.metric].enum[String(value)]) {
                    return value + " (" + (objMetricMetaData.current as any)?.metrics[row.metric].enum[String(value)] + ")";
                }
                if (value) {
                    return value;
                } else {
                    return <span>&#8212;</span>;
                }
            },
        },
        {
            id: "deviation",
            Header: STRINGS.incidents.columns.deviation,
            sortable: true,
            sortDescFirst: true,
            formatter: row => {
                let details = "";
                if (row.deviation) {
                    const deviation = Number(row.useTimes ? row.deviation / 100 : row.deviation);
                    const roundedDeviation = deviation < 2 ?
                        Math.round(deviation * 100) / 100 :
                        (deviation > 1000 ? "> 1000" : Math.round(deviation));
                    details = STRINGS.formatString(row.deviationInfo, roundedDeviation);
                }
                if (details) {
                    return <span className="ml-1">{details}</span>;
                } else {
                    return <span>&#8212;</span>;
                }
            },
        },
        {
            id: "startTime",
            Header: STRINGS.incidents.columns.correlationStartTime,
            sortable: true,
            sortFunction: (a, b) => {
                const timeA = Number(a.startTime);
                const timeB = Number(b.startTime);
                return (timeA === timeB) ? 0 : (timeA > timeB ? 1 : -1);
            },
            formatter: row => {
                return <ElapsedTimeFormatter time={parseTimeFromDAL(row.startTime)} showOriginal showOriginalFirst suffix={STRINGS.incidents.elapsedSuffix}/>
            },
        },
    ];

    // Wrapping in useMemo to make it more performant
    const { timingInformation, indicatorData, indicatorsCount, entityCountPerEntityType, uniqueMetrics, expandedRows } = useMemo(() => {
        const indicatorsFromQuery: Array<Indicator> = data?.indicators?.nodes || [];
        const indicatorIDsFromQuery: Array<{id: string}> = data?.idsOnly?.nodes || [];

        // As mentioned above Apollo client was merging all records with the same id.  These ids are not
        // unique so we are now taking the indicators and ids and merging them together, so indicatorsFromQuery
        // has the indicators without the ids and now indicatorsFromQueryWithIDs will have the indicators with the ids
        const indicatorsFromQueryWithIDs: Array<Indicator> = indicatorsFromQuery.map((indicator, index) => {
            return {
                ...indicator,
                id: indicatorIDsFromQuery[index].id,
            };
        });

        const uniqueSources: Array<string> = [];
        const uniqueEntityMetricCombo = {};
        const uniqueEntities = {};
        const uniqueMetrics = {};
        const indicatorCountPerTimestamp = {};
        const entityCountPerEntityType = {};
        let earliestStartTime = 0;
        let latestEndTime = 0;
        indicatorsFromQueryWithIDs.forEach(indicator => {
            // indicator id is sourceId::entityId::metric::anomalyKind
            let indicatorID: string = indicator.id || "";
            const entityType: string = indicator.entity?.kind || "";
            const metric: string = indicator.metric;
            const entityID: string = indicator.entity?.id || "";
            const anomalyKind: INDICATOR_TYPE = indicator.kind;
            const sourceId: string = indicator.entity?.source?.id || "";
            const sourceName: string = indicator.entity?.source?.name || indicator.entity?.source?.host || "";

            // exclude the primary indicator
            if (
                primaryIndicator && primaryIndicator?.entity?.id === indicator?.entity?.id &&
                primaryIndicator?.metric === indicator?.metric && primaryIndicator?.kind === indicator?.kind &&
                primaryIndicator?.entity?.source?.id === indicator?.entity?.source?.id
            ) {
                return;
            }

            // The entity may come from two different data sources and each needs to have its own plot
            // right now, so create a unique id from the source id and entity id.
            const uniqueEntityId: string = sourceId + "::" + entityID;

            const indicatorStartTime = parseFloat(indicator.startTime || "0");
            const indicatorEndTime = parseFloat(indicator.endTime || "0");

            if (isBaselineIndicatorType(anomalyKind)){
                indicatorID += "_baseline";
            } else {
                indicatorID += "_threshold";
            }

            if (!uniqueSources.includes(sourceId)) {
                uniqueSources.push(sourceId);
            }

            if (uniqueEntityMetricCombo[indicatorID] === undefined) {
                // This is the first time we are seeing this entity/source/metric/anomalyKind
                uniqueEntityMetricCombo[indicatorID] = true;

                entityCountPerEntityType[entityType] = (entityCountPerEntityType[entityType] || 0) + 1;
        
                uniqueMetrics[metric] = (uniqueMetrics[metric] || 0) + 1;
        
                if (uniqueEntities[uniqueEntityId] === undefined) {
                    uniqueEntities[uniqueEntityId] = {
                        id: entityID,
                        sourceId: sourceId,
                        sourceName: sourceName,
                        entity: indicator.entity,
                        metrics: {}
                    };
                }
                if (uniqueEntities[uniqueEntityId].metrics[metric] === undefined) {
                    uniqueEntities[uniqueEntityId].metrics[metric] = {
                        metric: metric,
                        anomalies: {}
                    };
                }
                if (uniqueEntities[uniqueEntityId].metrics[metric].anomalies[anomalyKind] === undefined) {
                    uniqueEntities[uniqueEntityId].metrics[metric].anomalies[anomalyKind] = {
                        kind: anomalyKind,
                        data: []
                    };
                }
                if (earliestStartTime === 0 || indicatorStartTime < earliestStartTime) {
                    earliestStartTime = indicatorStartTime;
                }
                if (latestEndTime === 0 || indicatorEndTime > latestEndTime) {
                    latestEndTime = indicatorEndTime;
                }
                if (!indicatorCountPerTimestamp[indicatorStartTime]) {
                    indicatorCountPerTimestamp[indicatorStartTime] = { x: indicator.startTime, y: 1 };
                } else {
                    indicatorCountPerTimestamp[indicatorStartTime].y++;
                }

                const expectedValue = indicator.details?.expectedValue;
                const expectedValueForCalculation = expectedValue === null || expectedValue === undefined ? 0 : expectedValue;
                const actualValue = indicator.details?.actualValue;
                const acceptableHigh = indicator.details?.acceptableHighValue;
                const acceptableLow = indicator.details?.acceptableLowValue;
                let percentExceeded:number = 0;
                let useTimes = false;
                let deviationInfo = "";
                if (actualValue !== undefined && actualValue !== null &&
                    (
                        (actualValue <= expectedValueForCalculation && acceptableLow !== null && acceptableLow !== undefined) ||
                        (actualValue > expectedValueForCalculation && acceptableHigh !== null && acceptableHigh !== undefined)
                    )
                ) {
                    if (actualValue > expectedValueForCalculation) {
                        if (expectedValue === null || expectedValue === undefined) {
                            useTimes = true;
                            deviationInfo = STRINGS.incidents.indicators.timesHigher;
                        } else {
                            deviationInfo = STRINGS.incidents.indicators.outsideRange;
                        }
                        percentExceeded = ((actualValue - acceptableHigh!) / (acceptableHigh! - expectedValueForCalculation)) * 100;
                    } else {
                        if (expectedValue === null || expectedValue === undefined) {
                            useTimes = true;
                            deviationInfo = STRINGS.incidents.indicators.timesLower;
                        } else {
                            deviationInfo = STRINGS.incidents.indicators.outsideRange;
                        }
                        percentExceeded = Math.abs((actualValue - acceptableLow!) / (acceptableLow! - expectedValueForCalculation)) * 100;
                    }
                }

                if (STATUS_METRICS.includes(metric)) {
                    // There is no deviation information for a status metric
                    deviationInfo = "";
                }

                uniqueEntities[uniqueEntityId].metrics[metric].anomalies[anomalyKind].data.push({
                    startTime: indicator.startTime,
                    endTime: indicator.endTime,
                    expected: expectedValue,
                    actual: actualValue,
                    acceptableHigh: acceptableHigh,
                    acceptableLow: acceptableLow,
                    deviation: percentExceeded,
                    useTimes: useTimes,
                    deviationInfo: deviationInfo,
                    indicator: indicator
                });
            } else {
                // Verify the start/end time for existing metrics.
                // The data array will always have only 1 entry since the
                // processing is done for unique entity/metric combos
                if (indicatorStartTime < earliestStartTime) {
                    earliestStartTime = indicatorStartTime;
                }
                if (indicatorStartTime < uniqueEntities[uniqueEntityId].metrics[metric].anomalies[anomalyKind].data[0].startTime) {
                    uniqueEntities[uniqueEntityId].metrics[metric].anomalies[anomalyKind].data[0].startTime = indicatorStartTime;
                }
                if (indicatorEndTime > latestEndTime) {
                    latestEndTime = indicatorEndTime;
                }
                if (indicatorEndTime > uniqueEntities[uniqueEntityId].metrics[metric].anomalies[anomalyKind].data[0].endTime) {
                    uniqueEntities[uniqueEntityId].metrics[metric].anomalies[anomalyKind].data[0].endTime = latestEndTime;
                }
            }
        });

        // Create one chart for each entity/source/metric/kind
        const indicatorData:any[] = [];
        let indicatorsCount = 0;
        const expandedRows:string[] = ["0"];
        for (const uniqueEntityId in uniqueEntities) {
            const entityDataObj = uniqueEntities[uniqueEntityId];
            const metrics = Object.values(entityDataObj.metrics);
            const moreThanOneMetric = metrics.length > 1;
            let minStartTimeForEntity = 0;
            let maxEndTimeForEntity = 0;

            // Create one row for this entity/source for each metric and anomaly type
            const rowsForEntity:any[] = [];
            for (const metricObj of metrics) {
                const metric = (metricObj as any).metric;
                const anomaliesForThisMetric = Object.values((metricObj as any).anomalies);
                for (const anomaly of anomaliesForThisMetric) {
                    const anomalyKind = (anomaly as any).kind;
                    for (const data of (anomaly as any).data) {
                        rowsForEntity.push({
                            uniqueID: uniqueEntityId + "::" + metric + "::" + anomalyKind,
                            entityID: entityDataObj.id,
                            sourceID: entityDataObj.sourceId,
                            sourceName: uniqueSources.length > 1 ? entityDataObj.sourceName : null,
                            entityKind: entityDataObj.entity.kind,
                            entity: entityDataObj.entity,
                            entityName: getEntityName(entityDataObj.entity),
                            metric: metric,
                            kind: anomalyKind,
                            ...data,
                            subComponent: <IndicatorDetailedView
                                className="mt-3"
                                indicatorMapping={{
                                    entityId: entityDataObj.id,
                                    metric: metric,
                                    kind: anomalyKind,
                                    sourceId: entityDataObj.sourceId
                                }}
                                indicatorTimeRange={{
                                    endTime: indicatorsTimeRange.endTime + (1 * 60 * 1000),
                                    startTime: indicatorsTimeRange.startTime - (15 * 60 * 1000),
                                }}
                                incidentTimeRange={props.incidentTimeRange}
                                isBaselineType={isBaselineIndicatorType(anomalyKind)}
                                annotationIncident={incident}
                                annotationIndicator={data.indicator}
                                runbooks={runbooks}
                            />
                        });
                        if (minStartTimeForEntity === 0 || minStartTimeForEntity > data.startTime) {
                            minStartTimeForEntity = data.startTime;
                        }
                        if (maxEndTimeForEntity === 0 || maxEndTimeForEntity < data.endTime) {
                            maxEndTimeForEntity = data.endTime;
                        }
                        indicatorsCount++;
                    }
                }
            }
            
            if (moreThanOneMetric) {
                expandedRows.push(String(indicatorData.length));
                indicatorData.push({
                    uniqueID: uniqueEntityId,
                    entityID: entityDataObj.id,
                    sourceID: entityDataObj.sourceId,
                    sourceName: uniqueSources.length > 1 ? entityDataObj.sourceName : null,
                    entityKind: entityDataObj.entity.kind,
                    entityName: getEntityName(entityDataObj.entity),
                    metric: metrics.map((metricSummary) => (metricSummary as any)?.metric),
                    entity: entityDataObj.entity,
                    subRows: rowsForEntity.map(row => ({ ...row, entitySubRow: true, className: "sub-row" })),
                    startTime: minStartTimeForEntity,
                    endTime: maxEndTimeForEntity,
                    entityHeader: true,
                });
            } else {
                indicatorData.push(...rowsForEntity);
            }
        }
    
        // Sort data to show primary entity on top of the list
        indicatorData.sort((a, b) => {
            if (primaryEntityId && a.entityID === primaryEntityId && b.entityID !== primaryEntityId) {
                return -1;
            } else if (primaryEntityId && b.entityID === primaryEntityId && a.entityID !== primaryEntityId) {
                return 1;
            } else {
                return 0;
            }
        });

        let timingInformation = (earliestStartTime && latestEndTime) ? STRINGS.formatString(
                STRINGS.incidents.indicators.indicatorChartSubtitle,
                formatTimeToUserFriendlyString({
                    startTime: parseTimeFromDALToUnix(earliestStartTime),
                    endTime: parseTimeFromDALToUnix(latestEndTime),
                })
            ): "";
        
        if (Object.keys(indicatorCountPerTimestamp).length > 1) {
            const timeChartData:{ x: number, y: number, increase: number }[] = [];
            let total = 0;
            const indicatorCountDataPoints = Object.values(indicatorCountPerTimestamp)
                .sort((a:any, b:any) => a.x - b.x)
                .map((data:any) => ({
                    ...data,
                    x: parseTimeFromDALToUnix(data.x)
                }));
            for (const dataPoint of indicatorCountDataPoints) {
                dataPoint.increase = dataPoint.y;
                dataPoint.y += total;
                total = dataPoint.y;
                timeChartData.push(dataPoint);
            }
            const parsedLatestEndTime = parseTimeFromDALToUnix(latestEndTime);
            if (parsedLatestEndTime && timeChartData[timeChartData.length - 1].x < parsedLatestEndTime) {
                const lastDataPoint = timeChartData[timeChartData.length - 1];
                timeChartData.push({
                    ...lastDataPoint,
                    x: parsedLatestEndTime,
                    increase: lastDataPoint.y,
                });
            }
            const firstDataPoint = timeChartData[0];
            timeChartData.splice(0, 0, {
                ...firstDataPoint,
                y: 0,
                increase: 0,
                x: firstDataPoint.x - (60 * 1000),
            })
    
            timingInformation = <div className="w-100">
                <div className="text-center">{STRINGS.incidents.indicators.indicatorCountChartTitle} <div className="display-10">({timingInformation})</div></div>
                <TimeseriesTile
                    showAsSteps
                    className="w-100 h-1-5"
                    chartData={timeChartData}
                    config={{
                        chart: {
                            height: 140
                        },
                        xAxis: {
                            visible: true,
                            type: "datetime",
                            labels: {
                                formatter: function (this:any) {
                                    return formatToLocalTimestamp(this.value, TIME_FORMAT.DISPLAY_TIME_ONLY_FORMAT);
                                }
                            }
                        },
                        tooltip: {
                            useHTML: true,
                            formatter: function (this:any) {
                                const [{point}] = this.points;
                                return "<div class='text-center'><span class='font-weight-bold'>" + this.y + "</span>" +
                                    (point.increase === this.y ? "" : " (<span class='display-10 font-weight-bold'>↑" + point.increase + "</span>)") +
                                    "<div class='display-10'>At " + formatToLocalTimestamp(parseTimeFromDAL(this.x)) + "</div></div>";
                            } 
                        }
                    }}
                />
            </div>;
        }
        return { timingInformation, indicatorData, indicatorsCount, entityCountPerEntityType, uniqueMetrics, expandedRows };
    }, [data, primaryEntityId, indicatorsTimeRange, props.incidentTimeRange, runbooks, primaryIndicator, incident]);

    let { params } = useQueryParams({ listenOnlyTo: [ PARAM_NAME.debug ] });
    const showDebugInformation = params[PARAM_NAME.debug] === "true";
    const initDialogState = {showDialog: false, title: STRINGS.primaryIndicatorView.debugDialogTitle, loading: false, dialogContent: <></>, dialogFooter: <></>};
    const [dialogState, setDialogState] = useState<any>(initDialogState);

    const currentExpandedRows = useRef<Array<string> | undefined>(undefined);
    if (currentExpandedRows.current === undefined && expandedRows.length > 0) {
        // The reference has not been initialized
        currentExpandedRows.current = expandedRows;
    }

    return (<>
        <BasicDialog dialogState={dialogState} className="indicators-list-view-dialog" onClose={() => setDialogState(updateDialogState(dialogState, false, false, []))} />
        {showDebugInformation && <AnchorButton icon={IconNames.DIAGNOSIS} minimal={true} onClick={() => {
            showDebugDialog(
                getDebugInformation(data?.indicators?.nodes, indicatorsTimeRange, indicatorMappings, filters), 
                dialogState, setDialogState
            );
        }} className="indicators-list-view-debug-button" />}
        <DataLoadFacade loading={loading} data={data} error={error} showContentsWhenLoading={true}>
            <div className={"indicator-list-view" + (className ? " " + className : "")}>
                {
                    showSummary &&
                    <div className="d-flex mb-4 align-items-center">
                        <div className="d-inline-block mx-5">
                            <div className="display-6 font-weight-bold text-black">{indicatorsCount}</div>
                            <div className="display-8 text-secondary">{STRINGS.incidents.columns.indicators}</div>
                        </div>
                        {
                            Object.keys(entityCountPerEntityType).length > 1 &&
                            <div className="d-inline-block mx-5">
                                <Tooltip2
                                    content={<div className="h-max-2">{Object.keys(entityCountPerEntityType).map(entityType => {
                                        return <div className="m-1" key={"metric-" + entityType}>{STRINGS.incidents.entityKinds[entityType] || entityType}</div>;
                                    })}</div>}
                                    position="bottom"
                                    interactionKind="hover"
                                >
                                    <div className="display-6 font-weight-bold text-black clickable">
                                        {Object.keys(entityCountPerEntityType).length}
                                    </div>
                                </Tooltip2>
                                <div className="display-8 text-secondary">{STRINGS.incidents.columns.uniqueEntityTypes}</div>
                            </div>
                        }
                        {
                            Object.keys(uniqueMetrics).length > 1 &&
                            <div className="d-inline-block mx-5">
                                <Tooltip2
                                    content={<div className="h-max-2">{Object.keys(uniqueMetrics).map(metric => {
                                        return <div className="m-1" key={"metric-" + metric}>{getLabelForMetric(metric) || metric}</div>;
                                    })}</div>}
                                    position="bottom"
                                    interactionKind="hover"
                                >
                                    <div className="display-6 font-weight-bold text-black clickable">
                                        {Object.keys(uniqueMetrics).length}
                                    </div>
                                </Tooltip2>
                                <div className="display-8 text-secondary">{STRINGS.incidents.columns.uniqueMetrics}</div>
                            </div>
                        }
                        <div className="d-inline-flex mx-5 align-items-center flex-grow-1">
                            {timingInformation}
                        </div>
                    </div>
                }
                <Table
                    id="indicatorList"
                    columns={columns}
                    data={indicatorData}
                    transparent
                    getHeaderProps={column => ({ className: "text-uppercase border-bottom" })}
                    enablePagination={true}
                    expandedRows={currentExpandedRows.current}
                    onExpansionChange={(expandedRows: any) => {
                        if (indicatorData && indicatorData.length > 0) {
                            currentExpandedRows.current = expandedRows.map(row => row.id);
                        }
                    }}
                />
            </div>
        </DataLoadFacade>
    </>);
}

/** format the metric value.
 *  @param value the value to format.
 *  @param metric the metric id.
 *  @returns a String with the formatted value. */
function formatMetricValue (value: number, metric: string = ""): string {
    if (metric === "status") {
        return "";
    } else {
        const unit = getUnitForMetric(metric);
        return formatAndScaleMetricValue(value, Unit.parseUnit(unit)).formatted;
    }
}

/** Creates a popup that displays the debug information.
 *  @param json the JSON object with the debug information.
 *  @param dialogState the copied state object with the state setup to open the dialog.  The content
 *      needs to be appended and the title needs to be set in this function.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function. */
function showDebugDialog(
    json: Record<string, any>, dialogState: any, setDialogState: (dialogState: any) => void
): void {
    const newDialogState = Object.assign({}, dialogState);
    newDialogState.showDialog = true;
    newDialogState.dialogContent = <JsonViewer json={json} />;
    newDialogState.dialogFooter = <>
        <CopyToClipboard text={JSON.stringify(json || {}, null, 4)}>
            <Button active={true} outlined={true} 
                text={STRINGS.primaryIndicatorView.copyBtnText} onClick={() => {
                    setDialogState(updateDialogState(newDialogState, false, false, []));
                }}
            />
        </CopyToClipboard>
        <Button active={true} outlined={true}
            text={STRINGS.primaryIndicatorView.okBtnText}
            onClick={async (evt) => {
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }}
        />
    </>;
    setDialogState(newDialogState);
}

/** This function outputs the debug information for the debug dialog.
 *  @param indicators the Array of indicators that were returned by DAL.
 *  @param timeRange the time range that is used to query the indicators.
 *  @param indicatorMappings the incidator mappings, either one or the array of mappings.
 *  @param filters the filters used in the query.
 *  @returns an object with the debug information. */
function getDebugInformation(
    indicators: Array<Indicator>, timeRange: any,
    indicatorMappings: IndicatorMapping | IndicatorMapping[], filters: FILTERS_OBJECT | undefined
): Record<string, any> {
    if (indicators?.length) {
        indicators = cloneDeep(indicators);
        for (const indicator of indicators) {
            annotateTimestamp(indicator, "startTime");
            annotateTimestamp(indicator, "endTime");
         }
    }

    if (timeRange?.startTime) {
        annotateTimestamp(timeRange, "startTime");
    }
    if (timeRange?.endTime) {
        annotateTimestamp(timeRange, "endTime");
    }

    return {indicators, timeRange, indicatorMappings, filters}
}
