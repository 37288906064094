/** This module contains utilites that are used for the decision branch.
 *  @module
 */
import { ConditionTree } from "components/common/condition-tree-builder/ConditionTreeBuilder";
import { ConditionBlockType, ConditionBlockValue, ConditionRowOrBlockValue, ConditionRowValue } from "components/common/condition-tree-builder/condition-block/ConditionBlock";
import { generateRandomID, isConditionBlock, operationItem } from "components/common/condition-tree-builder/condition/ConditionUtils";
import { CustomProperty } from "pages/create-runbook/views/create-runbook/CustomPropertyTypes";

// API specified expression format
/** the prefix for key ids. */
export const KEY_PREFIX = "$DATA.KEYS.";
/** the prefix for metric ids. */
export const METRIC_PREFIX = "$DATA.DATA.";
/** the prefix for trigger key ids. */
export const TRIGGER_PREFIX = "$ENTITY.KEY.";
/** the prefix for trigger metric ids. */
export const TRIGGER_METRIC_PREFIX = "$PRIMARY_INDICATOR.DATA.";
/** the prefix for trigger metric ids.  The items in this category all have a different prefix so 
 *  set the prefix to the empty string and put the prefix directly in the ID.  The two variations that
 *  are used are shown below in the two constants below this constant. */
export const TRIGGER_GENERIC_METRIC_PREFIX = "";
/** this is the first prefix that is used for the trigger generic metric. */
export const TRIGGER_GENERIC_METRIC_PREFIX_1 = "$PRIMARY_INDICATOR.KEY.";
/** this is the second prefix that is used for the trigger generic metric. */
export const TRIGGER_GENERIC_METRIC_PREFIX_2 = "$ENTITY.KEY.";
/** the prefix for https key ids.  For now don't show a prefix. */
export const HTTP_PREFIX = "";
/** the prefix for variable ids.  For now don't show a prefix. */
export const VARIABLE_PREFIX = "$VARIABLE.";
/** a String that has the id that is always used for the default output. */
export const DEFAULT_OUTPUT_ID = "default";

/** this interface defines an options set. */
interface OptionSet {
    /** an array with the left hand side options, the list of keys, metrics, etc. */
    lhsOptions: Array<any>;
    /** the list of operators to display. */
    operators: Array<any>;
}

/** the list of options by type. */
export interface OptionSetByType {
    /** the metrics options. */
    inputMetrics?: OptionSet & {metrics: Array<{value: string, label: string}>};
    /** the key options. */
    inputKeys?: OptionSet & {customProperties: CustomProperty[] |  undefined, dataSources?: any[]};
    /** the input row count options. */
    inputRowCount?: OptionSet;
    /** the indicator row count options. */
    indicatorRowCount?: OptionSet;
    /** the trigger keys (properties) options. */
    inputTriggerKeys: OptionSet & {
        triggerMetrics: Array<{value: string, label: string}>, 
        excludedTriggerMetrics: Array<{value: string, label: string}>, 
        triggerEntityTypes: Array<{value: string, label: string}>, 
        analysisTypes: Array<{value: string, label: string}>,
        userProvidesKey?: boolean
    };
    /** the trigger metrics options. */
    inputTriggerMetrics: OptionSet & {
        triggerMetrics: Array<{value: string, label: string}>,
        excludedTriggerMetrics: Array<{value: string, label: string}> 
    };
    /** the trigger generic metrics options.  This includes items such as the trigger metric, entity and
     *  anomaly type. */
    inputTriggerGenericMetrics: OptionSet & {
        triggerMetrics: Array<{value: string, label: string}>, 
        excludedTriggerMetrics: Array<{value: string, label: string}>, 
        triggerEntityTypes: Array<{value: string, label: string}>, 
        analysisTypes: Array<{value: string, label: string}>
    };
    /** the https options. */
    inputHttpKeys: OptionSet;
    /** the current set of variables that are avaible to this decision node. */
    inputVariables: OptionSet;
}

/** the string that is used to separate the aggregation operation from the rest of the metric id. */
export const AGGREAGATION_SEPARATOR = "-";

export enum ConditionBlockOrchestratorOperationType {
    AND     = "AND",
    OR      = "OR",
    NOT_AND = "NAND",
    NOT_OR  = "NOR",
}

export interface RunbookOrchestratorConditionRow {
    type?: string;
    id?: string;
    category?: string;
    key?: string;
    op?: operationItem;
    value?: any;
    ts_op?: string;
}

/** this enumeration defines the different types of conditions. */
export enum ConditionType {
    /** this is the enumerated value for a condition on the count of rows. */
    COUNTS                  = "input.rows",
    /** this is the enumerated value for a condition on the count of indicator rows. */
    INDICATOR_COUNTS        = "indicator_rows",
    /** this is the enumerated value for a condition on a metric. */
    METRIC                  = "input.metric",
    /** this is the enumerated value for a condition on a key. */
    KEY                     = "input.key",
    /** this is the enumerated value for a condition on a trigger key. */
    TRIGGER_KEY             = "trigger-key",
    /** this is the enumerated value for a condition on a trigger metric. */
    TRIGGER_METRIC          = "trigger-metric",
    /** this is the enumerated value for a condition on a trigger generic metric/key. */
    TRIGGER_GENERIC_METRIC  = "trigger-generic-metric",
    /** this is the enumerated value for a condition on an HTTP key. */
    HTTP                    = "http",
    /** this is the enumerated value for a condition on a variable. */
    VARIABLE                = "variable",
}

/** Converts the whole tree of conditions to JSON that the runbook orchestrator understands.
 *  @param expression the expression to convert to the runbook orchestrator format.
 *  @returns the runbook orchestrator JSON for the specified expression. */
export function convertTreeBuilderFormatToOrchestratorFormat(expression: ConditionTree) {
    return convertConditionOrBlockToOrchestratorFormat(expression);
}

/** Converts the specified condition block to JSON that the runbook orchestrator understands.
 *  @param conditionOrBlock the condition block to convert to runbook orchestrator format.
 *  @returns the runbook orchestrator JSON for the specified condition block. */
export function convertConditionOrBlockToOrchestratorFormat(conditionOrBlock) {
    if (isConditionBlock(conditionOrBlock)) {
        return convertConditionBlockToOrchestratorFormat(conditionOrBlock as ConditionBlockValue);
    } else {
        return convertConditionRowToOrchestratorFormat(conditionOrBlock as ConditionRowValue);
    }
}

/** converts the condition block into the JSON format expected by the runbook orchestrator.
 *  @param conditionBlock the ConditionBlockValue that is to be formatted.
 *  @returns the JSON with the condition block output. */
export function convertConditionBlockToOrchestratorFormat(conditionBlock: ConditionBlockValue) {
    const BLOCK_TYPE_TO_OPERATION_MAP = {
        [ConditionBlockType.AND]: ConditionBlockOrchestratorOperationType.AND,
        [ConditionBlockType.OR]: ConditionBlockOrchestratorOperationType.OR,
        [ConditionBlockType.NOT_AND]: ConditionBlockOrchestratorOperationType.NOT_AND,
        [ConditionBlockType.NOT_OR]: ConditionBlockOrchestratorOperationType.NOT_OR,
    };
    const conditionBlockOutput = {
        id: "o" + generateRandomID(),
        type: "block",
        operation: BLOCK_TYPE_TO_OPERATION_MAP[conditionBlock.blockType || ""] || BLOCK_TYPE_TO_OPERATION_MAP[ConditionBlockType.AND],
        conditions: conditionBlock.conditions.map(convertConditionOrBlockToOrchestratorFormat),
    };
    return conditionBlockOutput;
}

/** converts a condition row to the runbook orchestrator format.
 *  @param conditionRow the condition row to output the runbook orchestrator format.
 *  @returns outputs the JSON that is in runbook orchestrator format. */
export function convertConditionRowToOrchestratorFormat(conditionRow: ConditionRowValue): RunbookOrchestratorConditionRow {
    // There are three categories that should map to trigger, since the code requires the category 
    // to be unique we have given them names like trigger-key, trigger-metric, so check to see if 
    // the categories tarts with trigger and then, if so, just output trigger. 
    let category = conditionRow.category;
    const catSupportsAggregator = category === ConditionType.METRIC || category === ConditionType.TRIGGER_METRIC;
    if (category?.startsWith("trigger")) {
        category = "trigger";
    }

    // key and ts_op are two separate properties in runbook orchestrator. We are splitting the combined value
    // used by UI control to 2 individual ones here
    let key: string = (conditionRow.conditionKey || "").toString();
    let aggregator: string | undefined = undefined;
    if (catSupportsAggregator && key.indexOf(AGGREAGATION_SEPARATOR) === key.lastIndexOf(AGGREAGATION_SEPARATOR)) {
        [key, aggregator] = (conditionRow.conditionKey || "").split(AGGREAGATION_SEPARATOR);
    }
    return {
        type: "condition",
        id: conditionRow.id,
        category,
        key: key,
        op: conditionRow.operation,
        value: conditionRow.value,
        ...(aggregator ? { ts_op: aggregator } : {})
    };
}

/** Converts an expression in Runbook Orchestrator format to the format that the UI tree control understands.
 *  @param expression the expression to format in the UI tree control format.
 *  @returns a ConditionTree that can be understood by the condition tree control. */
export function convertExpressionToTreeBuilderFormat(expression: { type?: string, [x: string]: any }): ConditionTree {
    if (expression.type === "condition") {
        return convertConditionBlockToTreeFormat({
            id: (expression.id || "c") + "-b1",
            type: "block",
            conditions: [expression],
        });
    } else {
        return convertConditionBlockToTreeFormat(expression);
    }
}

/** Converts a condition in Runbook Orchestrator format to the format that the UI tree control understands.
 *  @param condition the condition to format in the UI tree control format.
 *  @returns a ConditionRowValue that can be understood by the condition tree control. */
export function convertConditionToTreeFormat (condition: RunbookOrchestratorConditionRow = {}): ConditionRowValue {
    // There are three categories that should map to trigger, since the code requires the category 
    // to be unique we have given them names like trigger-key, trigger-metric, so check the key to 
    // figure out the category.
    let category = condition.category;
    if (category === "trigger") {
        if (condition.key === "$ENTITY.KEY.kind") {
            category = ConditionType.TRIGGER_GENERIC_METRIC;
        } else if (condition.key === "$PRIMARY_INDICATOR.KEY.kind") {
            category = ConditionType.TRIGGER_GENERIC_METRIC;
        } else if (condition.key === "$PRIMARY_INDICATOR.KEY.metric") {
            category = ConditionType.TRIGGER_GENERIC_METRIC;
        } else if (condition.key?.startsWith("$ENTITY.KEY")) {
            category = ConditionType.TRIGGER_KEY;
        } else if (condition.key?.startsWith("$PRIMARY_INDICATOR")) {
            category = ConditionType.TRIGGER_METRIC;
        } else {
            // This happens in the webhook case
            category = ConditionType.TRIGGER_KEY;
        }
    }

    return {
        id: condition.id,
        category,
        // In Runbook orchestrator, key and ts_op are 2 separate operations. But in a condition they both together
        // indicate the condition key. Hence we are combining their values using a separator and using it within
        // the UI controls. They will be split back to individual fields when coverting to RO's format
        conditionKey: condition.key + (condition.ts_op ? (AGGREAGATION_SEPARATOR + condition.ts_op) : ""),
        operation: condition.op,
        value: condition.value,
    }
}

/** Converts a condition block in Runbook Orchestrator format to the format that the UI tree control understands.
 *  @param conditionBlock the condition block to format in the UI tree control format.
 *  @returns a ConditionBlockValue that can be understood by the condition tree control. */
export function convertConditionBlockToTreeFormat (conditionBlock:any = {}): ConditionBlockValue {
    const BLOCK_OPERATION_TO_TYPE_MAP = {
        [ConditionBlockOrchestratorOperationType.AND]: ConditionBlockType.AND,
        [ConditionBlockOrchestratorOperationType.OR]: ConditionBlockType.OR,
        [ConditionBlockOrchestratorOperationType.NOT_AND]: ConditionBlockType.NOT_AND,
        [ConditionBlockOrchestratorOperationType.NOT_OR]: ConditionBlockType.NOT_OR,
    };
    return {
        id: conditionBlock.id,
        conditions: conditionBlock.conditions?.map(convertConditionOrBlockToTreeFormat) || [],
        blockType: BLOCK_OPERATION_TO_TYPE_MAP[conditionBlock.operation] || BLOCK_OPERATION_TO_TYPE_MAP["AND"],
    }
}

/** Converts a condition or condition block in Runbook Orchestrator format to the format that the UI tree control understands.
 *  @param conditionOrBlock the condition or condition block to format in the UI tree control format.
 *  @returns a ConditionRowOrBlockValue that can be understood by the condition tree control. */
export function convertConditionOrBlockToTreeFormat (conditionOrBlock): ConditionRowOrBlockValue {
    if (conditionOrBlock.type === "block") {
        return convertConditionBlockToTreeFormat(conditionOrBlock);
    } else {
        return convertConditionToTreeFormat(conditionOrBlock);
    }
}
