import React from 'react';
import classNames from 'classnames';
import { Classes, Divider } from '@blueprintjs/core';
import { Form, SelectField } from 'components/common/form';
import { TriggerTypes } from '../views/MappingConfigurationView';

import { STRINGS } from 'app-strings';

export enum IncidentTriggerOptions {
	deviceDownIssue = 'DEVICE_DOWN_ISSUE',
	interfacePerformanceIssue = 'INTERFACE_PERFORMANCE_ISSUE',
	multiDeviceDownIssue = 'MULTI_DEVICE_DOWN_ISSUE',
	applicationLocationPerformanceIssue = 'APPLICATION_LOCATION_PERFORMANCE_ISSUE',
}

export const INCIDENT_TRIGGER_TO_LABEL_MAP: Record<string, string> = {
	[IncidentTriggerOptions.deviceDownIssue]:
		STRINGS.MAPPING_CONFIGURATION_PAGE.addMappingModal.panels.selectTrigger
			.triggers.deviceDownIssue.label,
	[IncidentTriggerOptions.interfacePerformanceIssue]:
		STRINGS.MAPPING_CONFIGURATION_PAGE.addMappingModal.panels.selectTrigger
			.triggers.interfacePerformanceIssue.label,
	[IncidentTriggerOptions.multiDeviceDownIssue]:
		STRINGS.MAPPING_CONFIGURATION_PAGE.addMappingModal.panels.selectTrigger
			.triggers.multiDeviceDownIssue.label,
	[IncidentTriggerOptions.applicationLocationPerformanceIssue]:
		STRINGS.MAPPING_CONFIGURATION_PAGE.addMappingModal.panels.selectTrigger
			.triggers.applicationLocationPerformanceIssue.label,
};

export enum LifecycleTriggerOptions {
	impactAnalysisReady = 'IMPACT_ANALYSIS_READY',
	incidentIndicatorsUpdated = 'INCIDENT_INDICATORS_UPDATED',
	incidentNoteAdded = 'INCIDENT_NOTE_ADDED',
	incidentNoteUpdated = 'INCIDENT_NOTE_UPDATED',
	incidentOngoingChanged = 'INCIDENT_ONGOING_CHANGED',
	incidentStatusChanged = 'INCIDENT_STATUS_CHANGED',
}

export const LIFECYCLE_TRIGGER_TO_LABEL_MAP: Record<string, string> = {
	[LifecycleTriggerOptions.impactAnalysisReady]:
		STRINGS.MAPPING_CONFIGURATION_PAGE.addMappingModal.panels.selectTrigger
			.triggers.impactAnalysisReady.label,
	[LifecycleTriggerOptions.incidentIndicatorsUpdated]:
		STRINGS.MAPPING_CONFIGURATION_PAGE.addMappingModal.panels.selectTrigger
			.triggers.incidentIndicatorsUpdated.label,
	[LifecycleTriggerOptions.incidentNoteAdded]:
		STRINGS.MAPPING_CONFIGURATION_PAGE.addMappingModal.panels.selectTrigger
			.triggers.incidentNoteAdded.label,
	[LifecycleTriggerOptions.incidentNoteUpdated]:
		STRINGS.MAPPING_CONFIGURATION_PAGE.addMappingModal.panels.selectTrigger
			.triggers.incidentNoteUpdated.label,
	[LifecycleTriggerOptions.incidentOngoingChanged]:
		STRINGS.MAPPING_CONFIGURATION_PAGE.addMappingModal.panels.selectTrigger
			.triggers.incidentOngoingChanged.label,
	[LifecycleTriggerOptions.incidentStatusChanged]:
		STRINGS.MAPPING_CONFIGURATION_PAGE.addMappingModal.panels.selectTrigger
			.triggers.incidentStatusChanged.label,
};

export enum ExternalTriggerOptions {
	webhook = 'WEBHOOK',
}

export const EXTERNAL_TRIGGER_TO_LABEL_MAP: Record<string, string> = {
	[ExternalTriggerOptions.webhook]:
		STRINGS.MAPPING_CONFIGURATION_PAGE.addMappingModal.panels.selectTrigger
			.triggers.webhook.label,
};

export interface ITriggerPanelProps {
	editMode: boolean;
	triggerTypeValue: string;
	triggerValue: string;
	onTriggerValueChange: (triggerValue: string) => void;
}

const TriggerPanel: React.FC<ITriggerPanelProps> = (props: any) => {
	const triggerToLabelMap =
		props.triggerTypeValue === TriggerTypes.INCIDENT
			? INCIDENT_TRIGGER_TO_LABEL_MAP
			: props.triggerTypeValue === TriggerTypes.LIFECYCLE
			? LIFECYCLE_TRIGGER_TO_LABEL_MAP
			: EXTERNAL_TRIGGER_TO_LABEL_MAP;

	const triggerOptions =
		props.triggerTypeValue === TriggerTypes.INCIDENT
			? IncidentTriggerOptions
			: props.triggerTypeValue === TriggerTypes.LIFECYCLE
			? LifecycleTriggerOptions
			: ExternalTriggerOptions;

	const handleTriggerValueChange = (event) => {
		const trigger = Object.keys(triggerToLabelMap).find(
			(key) => triggerToLabelMap[key] === event.currentTarget.value
		);
		return trigger && props.onTriggerValueChange(trigger);
	};

	return (
		<div className={classNames(Classes.DIALOG_BODY)}>
			<p>
				<b>
					{
						STRINGS.MAPPING_CONFIGURATION_PAGE.addMappingModal
							.panels.selectTrigger.subTitle
					}
				</b>
			</p>
			<Divider />
			<br />
			<Form
				initialValues={{
					triggerValue: triggerToLabelMap[props.triggerValue],
				}}
				loading={false}
			>
				<SelectField
					label={
						STRINGS.MAPPING_CONFIGURATION_PAGE.addMappingModal
							.panels.selectTrigger.fields.trigger
					}
					name="trigger_type"
					required={true}
					options={[
						STRINGS.MAPPING_CONFIGURATION_PAGE.addMappingModal
							.panels.selectTrigger.fields.selectTrigger,
					].concat(Object.values(triggerToLabelMap))}
					onChange={handleTriggerValueChange}
					value={
						props.triggerValue &&
						triggerToLabelMap[props.triggerValue]
					}
					disabled={props.editMode}
				/>
				{props.triggerValue && (
					<div className="pt-3">
						<div className="mt-1">
							{
								STRINGS.MAPPING_CONFIGURATION_PAGE
									.addMappingModal.panels.selectTrigger
									.triggers[
									Object.keys(triggerOptions)[
										Object.values(triggerOptions).indexOf(
											props.triggerValue
										)
									]
								]?.description
							}
						</div>
						<div className="d-flex mt-4">
							<div className="d-inline font-weight-bold">
								{props.triggerValue !==
									STRINGS.MAPPING_CONFIGURATION_PAGE
										.addMappingModal.panels.selectTrigger
										.fields.selectTrigger &&
									STRINGS.MAPPING_CONFIGURATION_PAGE
										.addMappingModal.panels.selectTrigger
										.triggers.triggeringEntity}
							</div>
							<div className="d-inline ml-2">
								{
									STRINGS.MAPPING_CONFIGURATION_PAGE
										.addMappingModal.panels.selectTrigger
										.triggers[
										Object.keys(triggerOptions)[
											Object.values(
												triggerOptions
											).indexOf(props.triggerValue)
										]
									]?.entity
								}
							</div>
						</div>
					</div>
				)}
			</Form>
		</div>
	);
};

export { TriggerPanel };
