/** This module contains the component for displaying the global filters.  The global filters
 *  control provides the user with autocompletes for the filters and displays a set of buttons
 *  with the current filters that have close icons that allow you to remove the filters.
 *  @module
 */
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Button, MenuItem, Spinner, Tag, Intent } from '@blueprintjs/core';
import { IRef } from '@blueprintjs/core/dist/core.bundle';
import { FILTER_NAME, SDWAN_ICONS } from 'components/sdwan/enums';
import { setUserPreferences, useGlobalFilters, useGlobalTime, useQuery, useUserPreferences } from 'utils/hooks';
import { Query } from 'reporting-infrastructure/data-hub';
import { loader } from 'graphql.macro';
import { STRINGS } from 'app-strings';
import { Suggest } from "@blueprintjs/select";
import { Icon, IconNames } from '@tir-ui/react-components';
import { useSupportedFilters } from 'utils/hooks';
import { CHART_SERIES_COLORS, INCIDENT_STATUS, INCIDENT_STATUS_TO_LABEL_MAP, PRIORITY, PRIORITY_TO_LABEL_MAP } from 'components/enums';
import { isEqual, isEmpty } from 'lodash';
import { getSupportedFilters } from 'utils/stores/GlobalSupportedFiltersStore';
import { DURATION, durationToRoundedTimeRange, isCustomTimeSet } from 'utils/stores/GlobalTimeStore';
import { TimePeriodMoreView } from 'components/common/time-period-more-view/TimePeriodMoreView';
import { TIME_OPTION_VALUES } from 'components/common/time-range-selector/TimeRangeSelector';
import { formatTimeToUserFriendlyString } from 'reporting-infrastructure/utils/formatters/date-formatter/DateFormatter';
import { clearFiltersFromUserPreference, getFiltersFromUserPreference, saveFiltersToUserPreference } from 'utils/stores/UserPreferencesUtils';
import { FILTER_IN_USER_PREFS_KEY } from 'utils/services/UserPrefsTypes';
import { runbookService } from 'utils/runbooks/RunbookUtils';
import { DalEnumByTriggerType, InputType, NamesByTriggerType, Variant } from 'components/common/graph/types/GraphTypes';
import { FIELDS, SearchService } from 'utils/services/SearchApiService';
import { getUserPreferences } from 'utils/stores/UserPreferencesStore';
import "./GlobalFilters.scss";

/** this interface defines a RecentSearch to be persisted to and from user preferences. */
interface RecentSearch {
    /** the autocomplete type. */
    type: autocompleteTypes;
    /** the filter name. */
    filter: FILTER_NAME;
    /** the label that displays the search result. */
    label: string;
    /** the id of the result. */
    id: string;
    /** the UUID of the result. */
    uuid?: string;
}

/** Supported filter types */
export enum autocompleteTypes {
    site                = "site",
    controlType         = "controlType",
    incident            = "incident",
    priority            = "priority",
    incidentStatus      = "incidentStatus",
    runbook             = "runbook",
    runbookType         = "runbookType",
    device              = "device",
    interface           = "interface",
    application         = "application",
    location            = "location",
    impactedLocation    = "impactedLocation",
    impactedApplication = "impactedApplication",
    impactedUser        = "impactedUser",
};

/** Map that ties global filters used in queries to the known filter types */
export const FILTER_TO_AUTOCOMPLETE_TYPE_MAP = {
    [FILTER_NAME.siteId]: autocompleteTypes.site,
    [FILTER_NAME.incidentId]: autocompleteTypes.incident,
    [FILTER_NAME.priority]: autocompleteTypes.priority,
    [FILTER_NAME.incidentStatus]: autocompleteTypes.incidentStatus,
    [FILTER_NAME.configurationIds]: autocompleteTypes.runbook,
    [FILTER_NAME.triggerTypes]: autocompleteTypes.runbookType,
    [FILTER_NAME.deviceName]: autocompleteTypes.device,
    [FILTER_NAME.interfaceName]: autocompleteTypes.interface,
    [FILTER_NAME.applicationName]: autocompleteTypes.application,
    [FILTER_NAME.locationName]: autocompleteTypes.location,
    [FILTER_NAME.impactedLocationId]: autocompleteTypes.impactedLocation,
    [FILTER_NAME.impactedApplicationId]: autocompleteTypes.impactedApplication,
    [FILTER_NAME.impactedUserId]: autocompleteTypes.impactedUser,
};

/** Icon to show in filter tags for filter types (optional) */
const ICON_MAP = {
    [autocompleteTypes.site]:                   IconNames.SITE,
    [autocompleteTypes.incident]:               SDWAN_ICONS.INCIDENT,
    [autocompleteTypes.runbook]:                SDWAN_ICONS.RUNBOOK,
    [autocompleteTypes.runbookType]:            SDWAN_ICONS.APP_SINGLE,
    [autocompleteTypes.device]:                 IconNames.DEVICES,
    [autocompleteTypes.interface]:              IconNames.MERGE_LINKS,
    [autocompleteTypes.application]:            IconNames.APPLICATIONS,
    [autocompleteTypes.location]:               IconNames.GLOBE,
    [autocompleteTypes.impactedLocation]:       IconNames.GLOBE,
    [autocompleteTypes.impactedApplication]:    IconNames.APPLICATIONS,
    [autocompleteTypes.impactedUser]:           IconNames.PEOPLE,
};

/** Interface to be used by result sets that powered autocomplete */
interface baseAutocompleteItem {
    type:           autocompleteTypes;
    filter:         FILTER_NAME;
    label:          string;
    info?:          string;
    disabled?:      boolean;
    showAlways?:    boolean;
    className?:     string;
    uuid?: string;
}

/** Autocomplete item type to cover entries which will have a single value that will be under 'id' parameter */
interface genericAutocompleteItemType extends baseAutocompleteItem {
    id: string;
}

/** Site-specific autocomplete type (has id and name) */
interface siteAutocompleteItemType extends baseAutocompleteItem {
    type: autocompleteTypes.site;
    id: string;
    name: null | string;
}
/** Device-specific autocomplete type (has id and cityName) */
interface deviceAutocompleteItemType extends baseAutocompleteItem {
    type: autocompleteTypes.device;
    id: string;
    cityName: string;
}

interface controlItemType {
    id: string,
    type: autocompleteTypes.controlType,
    label: string | ReactNode,
    uuid?: string,
}

type autocompleteItemType = siteAutocompleteItemType | deviceAutocompleteItemType | genericAutocompleteItemType;

let DATA_FOR_AUTOCOMPLETE: null | Array<autocompleteItemType | controlItemType> = null;

/** This interface defines the properties passed into the global filters React component.*/
export interface globalFiltersProps {
    additionalSupportedFilters?: FILTER_NAME[];
    disabledFilters?: FILTER_NAME[];
    hiddenFilters?: FILTER_NAME[];
    className?: string;
    /** Callback that will be executed when a filter gets added or removed with current count of active filters. This will also be called when the first render happens */
    onFilterCountChange?: (count: number) => void;
    onUsableFiltersChange?: (filtersAvailable: boolean) => void;
    /** A flag that can be used to hide the input control while rendering the component and
     * doing other necessary tasks such as fetching autocomplete data, executing the callbacks
     * to inform of applied filter counts, presence of usable filters, etc */
    hidden?: boolean;
    /** a flag that determines whether or not the time control should be displayed. */
    showTimeControl?: boolean;
    /** This map can be used to override any of the filter name labels if needed */
    customFilterNames?: { [x in FILTER_NAME | "time"]?: string };
    /** A flag to auto-open the results dropdown on render  */
    autoOpenDropDownOnRender?: boolean;
    /** A key that can be used to decide which set of user preferences keys this needs to be stored under.
     * Filters will not be saved to user preferences if this is not provided. */
    userPrefsKey?: FILTER_IN_USER_PREFS_KEY;
    /** A key that can be used to store the recently added filter list in user preferences. If not provided,
     * it will be stored in common global recent filters list **/
    recentFiltersUserPrefsKey?: FILTER_IN_USER_PREFS_KEY;
}

const STATIC_PRIORITIES = [
    { type: autocompleteTypes.priority, filter: FILTER_NAME.priority, label: STRINGS.incidentPriorities.critical, id: PRIORITY.CRITICAL },
    { type: autocompleteTypes.priority, filter: FILTER_NAME.priority, label: STRINGS.incidentPriorities.high, id: PRIORITY.HIGH },
    { type: autocompleteTypes.priority, filter: FILTER_NAME.priority, label: STRINGS.incidentPriorities.moderate, id: PRIORITY.MODERATE },
    { type: autocompleteTypes.priority, filter: FILTER_NAME.priority, label: STRINGS.incidentPriorities.low, id: PRIORITY.LOW },
];
const STATIC_STATUSES = [
    { type: autocompleteTypes.incidentStatus, info: "Status", filter: FILTER_NAME.incidentStatus, label: STRINGS.incidentStatus.new, id: INCIDENT_STATUS.NEW },
    { type: autocompleteTypes.incidentStatus, info: "Status", filter: FILTER_NAME.incidentStatus, label: STRINGS.incidentStatus.investigating, id: INCIDENT_STATUS.INVESTIGATING },
    { type: autocompleteTypes.incidentStatus, info: "Status", filter: FILTER_NAME.incidentStatus, label: STRINGS.incidentStatus.onHold, id: INCIDENT_STATUS.ON_HOLD },
    { type: autocompleteTypes.incidentStatus, info: "Status", filter: FILTER_NAME.incidentStatus, label: STRINGS.incidentStatus.closed, id: INCIDENT_STATUS.CLOSED },
    { type: autocompleteTypes.incidentStatus, info: "Status", filter: FILTER_NAME.incidentStatus, label: STRINGS.incidentStatus.dismissed, id: INCIDENT_STATUS.DISMISSED },
];
const STATIC_RUNBOOK_TYPES = [
    { type: autocompleteTypes.runbookType, filter: FILTER_NAME.triggerTypes, label: NamesByTriggerType[InputType.APPLICATION], id: DalEnumByTriggerType[InputType.APPLICATION] },
    { type: autocompleteTypes.runbookType, filter: FILTER_NAME.triggerTypes, label: NamesByTriggerType[InputType.DEVICE], id: DalEnumByTriggerType[InputType.DEVICE] },
    { type: autocompleteTypes.runbookType, filter: FILTER_NAME.triggerTypes, label: NamesByTriggerType[InputType.INTERFACE], id: DalEnumByTriggerType[InputType.INTERFACE] },
    { type: autocompleteTypes.runbookType, filter: FILTER_NAME.triggerTypes, label: NamesByTriggerType[InputType.LOCATION], id: DalEnumByTriggerType[InputType.LOCATION] },
];

/** Renders the global filters control.
 *  @param props the properties passed into the component.
 *  @returns JSX with the global filters control component.*/
export function GlobalFilters({
    hidden = false, showTimeControl = false, disabledFilters = [], hiddenFilters = [], additionalSupportedFilters = [],
    onFilterCountChange, onUsableFiltersChange, className, customFilterNames = {}, autoOpenDropDownOnRender,
    userPrefsKey = FILTER_IN_USER_PREFS_KEY.global, recentFiltersUserPrefsKey = FILTER_IN_USER_PREFS_KEY.globalRecents,
}: globalFiltersProps): JSX.Element {
    const { filters, addFilterValue, removeFilterValue, setAllFilters, setMultipleFilters } = useGlobalFilters();
    const { time, setTimeRange, setDuration, resetTime } = useGlobalTime();
    let { supportedFilters } = useSupportedFilters();
    const supportedFiltersFromHook = supportedFilters;
    supportedFilters = getSupportedFilters();
    const USE_AUTOCOMPLETE_PRELOADER_QUERY = false;

    // Query to get all the autocompletes that come from GraphQL
    const autocompleteDataQuery = useQuery({
        name: "autocompleteData",
        query: new Query(loader("./autocomplete-data.graphql")),
        timeNotRequired: true,
        lazy: true
    });

    const [runbookAutoCompletes, setRunbookAutoCompletes] = useState<Array<autocompleteItemType>>([]);
    useEffect(
        () => {
            async function retrieveRunbooks() {
                try {
                    const runbooks = await runbookService.getRunbooks(Variant.INCIDENT);
                    if (runbooks && runbooks.length > 0) {
                        runbooks.sort((a, b) => {
                            if (a?.name && b?.name) {
                                return a.name.localeCompare(b.name);
                            } else if (a?.name) {
                                return -1;
                            } else if (b?.name) {
                                return +1;
                            }
                            return 0;
                        });
                        const acRunbooks: Array<autocompleteItemType> = [];
                        for (const rc of runbooks) {
                            acRunbooks.push({
                                type: autocompleteTypes.runbook, filter: FILTER_NAME.configurationIds, label: rc.name || "Unknown", id: rc.id
                            });
                        }
                        if (DATA_FOR_AUTOCOMPLETE) {
                            DATA_FOR_AUTOCOMPLETE = DATA_FOR_AUTOCOMPLETE.concat(acRunbooks);
                        } else {
                            DATA_FOR_AUTOCOMPLETE = [...acRunbooks, ...STATIC_RUNBOOK_TYPES];
                        }
                        setRunbookAutoCompletes(acRunbooks);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
            retrieveRunbooks();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const [suggestQueryInProgress, setSuggestQueryInProgress] = useState(false);
    const [suggestAPIAutoCompletes, setSuggestAPIAutoCompletes] = useState<Array<autocompleteItemType | controlItemType>>([]);
    const lastQueryString = useRef("");
    async function searchSuggestApiOnDemand(query: string = "") {
        const RESULT_LIMIT = 10;
        if (query.length > 0 && lastQueryString.current !== query) {
            const searchFields: FIELDS[] = [];
            if (supportedFiltersMap[FILTER_NAME.deviceName]) {
                //searchFields.push(FIELDS.devName);
                //searchFields.push(FIELDS.triggeredOnDevName);
                searchFields.push(FIELDS.indicatorEntityDevName);
            }
            if (supportedFiltersMap[FILTER_NAME.interfaceName]) {
                //searchFields.push(FIELDS.ifcName);
                //searchFields.push(FIELDS.triggeredOnIfcName);
                searchFields.push(FIELDS.indicatorEntityIfcName);
            }
            if (supportedFiltersMap[FILTER_NAME.applicationName]) {
                //searchFields.push(FIELDS.appName);
                //searchFields.push(FIELDS.triggeredOnAppLocAppName);
                searchFields.push(FIELDS.indicatorEntityAppLocAppName);
            }
            if (supportedFiltersMap[FILTER_NAME.locationName]) {
                //searchFields.push(FIELDS.triggeredOnAppLocLocName);
                searchFields.push(FIELDS.indicatorEntityAppLocLocName);
            }
            if (supportedFiltersMap[FILTER_NAME.impactedLocationId]) {
                searchFields.push(FIELDS.impactedLocationName);
            }
            if (supportedFiltersMap[FILTER_NAME.impactedApplicationId]) {
                searchFields.push(FIELDS.impactedApplicationName);
            }
            if (supportedFiltersMap[FILTER_NAME.impactedUserId]) {
                searchFields.push(FIELDS.impactedUserName);
                searchFields.push(FIELDS.impactedUserIpaddr);
            }
            if (searchFields.length > 0) {
                lastQueryString.current = query;
                setSuggestQueryInProgress(true);
                const results = await SearchService.suggest({
                    searchFields,
                    fuzzy: false,
                    search: query,
                    top: 100,
                });
                let processedResults: Array<autocompleteItemType | controlItemType> = [];
                const resultsPerCategory: {
                    [key in FIELDS]?: autocompleteItemType[]
                } = {};
                const dedupAppNames: Array<string> = [];
                //const dedupLocNames: Array<string> = [];
                for (const item of results.value) {
                    let skipApp = false; 
                    //let skipLoc = false;
                    if (item.type === FIELDS.triggeredOn) {
                        let type: string = "";
                        let autocompleteType = "";
                        let filter = FILTER_NAME.deviceName;
                        if (item[FIELDS.triggeredOn].network_device) {
                            type = FIELDS.network_device;
                            autocompleteType = autocompleteTypes.device;
                            filter = FILTER_NAME.deviceName;
                        } else if (item[FIELDS.triggeredOn].network_interface) {
                            type = FIELDS.network_interface;
                            autocompleteType = autocompleteTypes.interface;
                            filter = FILTER_NAME.interfaceName;
                        } else if (item[FIELDS.triggeredOn].application_location) {
                            type = FIELDS.application;
                            autocompleteType = autocompleteTypes.application;
                            filter = FILTER_NAME.applicationName;
                            // we are searching on application location but we are only interested in the app right now.
                            item[FIELDS.triggeredOn][type] = item[FIELDS.triggeredOn].application_location.application;
                            item[FIELDS.triggeredOn][type].uuid = item[FIELDS.triggeredOn].application_location.application.uuid + 
                                ":" + item[FIELDS.triggeredOn].application_location.location.uuid;
                            if (!dedupAppNames.includes(item[FIELDS.triggeredOn].application_location.application.name as string)) {
                                dedupAppNames.push(item[FIELDS.triggeredOn].application_location.application.name as string);
                            } else {
                                skipApp = true;
                            }
                            /*
                            if (!dedupLocNames.includes(item[FIELDS.triggeredOn].application_location.location.name as string)) {
                                dedupLocNames.push(item[FIELDS.triggeredOn].application_location.location.name as string);
                            } else {
                                skipLoc = true;
                            }
                            */
                        }
                        const items = resultsPerCategory[type] || [];
                        if (items.length < RESULT_LIMIT * 2) {
                            if (!skipApp) {
                                items.push({
                                    type: autocompleteType,
                                    filter: filter,
                                    label: item[FIELDS.triggeredOn][type].name,
                                    id: item[FIELDS.triggeredOn][type].name, // + ";" + item[FIELDS.network_device].uuid,
                                    uuid: item[FIELDS.triggeredOn][type].uuid
                                });    
                            }
                            resultsPerCategory[type] = items;
                            /*
                            if (item[FIELDS.triggeredOn].application_location) {
                                // Add the location for now
                                if (!skipLoc) {
                                    const locations = resultsPerCategory[FIELDS.location] || [];
                                    locations.push({
                                        type: autocompleteTypes.location,
                                        filter: FILTER_NAME.locationName,
                                        label: item[FIELDS.triggeredOn].application_location.location.name,
                                        id: item[FIELDS.triggeredOn].application_location.location.name,
                                        uuid: item[FIELDS.triggeredOn].application_location.application.uuid + 
                                        ":" + item[FIELDS.triggeredOn].application_location.location.uuid
                                    });
                                    resultsPerCategory[FIELDS.location] = locations;    
                                }
                            }
                            */
                        }
                    } else if (item.type === FIELDS.indicator) {
                        let type: string = "";
                        let autocompleteType = "";
                        let filter = FILTER_NAME.deviceName;
                        if (item[FIELDS.indicator].entity.network_device) {
                            type = FIELDS.network_device;
                            autocompleteType = autocompleteTypes.device;
                            filter = FILTER_NAME.deviceName;
                        } else if (item[FIELDS.indicator].entity.network_interface) {
                            type = FIELDS.network_interface;
                            autocompleteType = autocompleteTypes.interface;
                            filter = FILTER_NAME.interfaceName;
                        } else if (item[FIELDS.indicator].entity.application_location) {
                            type = FIELDS.application;
                            autocompleteType = autocompleteTypes.application;
                            filter = FILTER_NAME.applicationName;
                            // we are searching on application location but we are only interested in the app right now.
                            item[FIELDS.indicator].entity[type] = item[FIELDS.indicator].entity.application_location.application;
                            item[FIELDS.indicator].entity[type].uuid = item[FIELDS.indicator].entity.application_location.application.uuid + 
                                ":" + item[FIELDS.indicator].entity.application_location.location.uuid;
                            if (!dedupAppNames.includes(item[FIELDS.indicator].entity.application_location.application.name as string)) {
                                dedupAppNames.push(item[FIELDS.indicator].entity.application_location.application.name as string);
                            } else {
                                skipApp = true;
                            }
                        }
                        const items = resultsPerCategory[type] || [];
                        if (items.length < RESULT_LIMIT * 2) {
                            if (!skipApp) {
                                items.push({
                                    type: autocompleteType,
                                    filter: filter,
                                    label: item[FIELDS.indicator].entity[type].name,
                                    id: item[FIELDS.indicator].entity[type].name, // + ";" + item[FIELDS.network_device].uuid,
                                    uuid: item[FIELDS.indicator].entity[type].uuid
                                });    
                            }
                            resultsPerCategory[type] = items;
                        }
                    } else if (item.type === FIELDS.network_device) {
                        const items = resultsPerCategory[item.type] || [];
                        if (items.length < RESULT_LIMIT * 2) {
                            items.push({
                                type: autocompleteTypes.device,
                                filter: FILTER_NAME.deviceName,
                                label: item[FIELDS.network_device].name,
                                id: item[FIELDS.network_device].name // + ";" + item[FIELDS.network_device].uuid,
                            });
                            resultsPerCategory[item.type] = items;
                        }
                    } else if (item.type === FIELDS.network_interface) {
                        const items = resultsPerCategory[item.type] || [];
                        if (items.length < RESULT_LIMIT * 2) {
                            items.push({
                                type: autocompleteTypes.interface,
                                filter: FILTER_NAME.interfaceName,
                                label: item[FIELDS.network_interface].name,
                                id: item[FIELDS.network_interface].name // + ";" + item[FIELDS.network_interface].uuid,
                            });
                            resultsPerCategory[item.type] = items;
                        }
                    } else if (item.type === FIELDS.application) {
                        const items = resultsPerCategory[item.type] || [];
                        if (items.length < RESULT_LIMIT * 2) {
                            items.push({
                                type: autocompleteTypes.application,
                                filter: FILTER_NAME.applicationName,
                                label: item[FIELDS.application].name,
                                id: item[FIELDS.application].name // + ";" + item[FIELDS.application].uuid,
                            });
                            resultsPerCategory[item.type] = items;
                        }
                    } else if (item.type === FIELDS.location) {
                        const items = resultsPerCategory[item.type] || [];
                        if (items.length < RESULT_LIMIT * 2) {
                            items.push({
                                type: autocompleteTypes.location,
                                filter: FILTER_NAME.locationName,
                                label: item[FIELDS.location].name,
                                id: item[FIELDS.location].name // + ";" + item[FIELDS.location].uuid,
                            });
                            resultsPerCategory[item.type] = items;
                        }
                    } else if (item.type === FIELDS.impactedLocation) {
                        const items = resultsPerCategory[item.type] || [];
                        if (items.length < RESULT_LIMIT * 2) {
                            items.push({
                                type: autocompleteTypes.impactedLocation,
                                filter: FILTER_NAME.impactedLocationId,
                                label: getImpactText(item[FIELDS.impactedLocation]),
                                id: getImpactText(item[FIELDS.impactedLocation]),
                            });
                            resultsPerCategory[item.type] = items;
                        }
                    } else if (item.type === FIELDS.impactedApplication) {
                        const items = resultsPerCategory[item.type] || [];
                        if (items.length < RESULT_LIMIT * 2) {
                            items.push({
                                type: autocompleteTypes.impactedApplication,
                                filter: FILTER_NAME.impactedApplicationId,
                                label: getImpactText(item[FIELDS.impactedApplication]),
                                id: getImpactText(item[FIELDS.impactedApplication]),
                            });
                            resultsPerCategory[item.type] = items;
                        }
                    } else if (item.type === FIELDS.impactedUser) {
                        const items = resultsPerCategory[item.type] || [];
                        if (items.length < RESULT_LIMIT * 2) {
                            const userImpact = getUserImpactText(item[FIELDS.impactedUser]);
                            items.push({
                                type: autocompleteTypes.impactedUser,
                                filter: FILTER_NAME.impactedUserId,
                                label: userImpact.name || userImpact.ipaddr || "",
                                info: userImpact.name ? userImpact.ipaddr : "",
                                id: (userImpact.ipaddr || "") + ";" + (userImpact.name || ""),
                            });
                            resultsPerCategory[item.type] = items;
                        }
                    }
                }
                for (const type of Object.keys(resultsPerCategory).sort()) {
                    if (type === FIELDS.network_device) {
                        processedResults.push({
                            id: "detected-device-header",
                            type: autocompleteTypes.controlType,
                            label: STRINGS.globalFilters.categoryLabels.detectedDevice,
                        });
                    } else if (type === FIELDS.network_interface) {
                        processedResults.push({
                            id: "detected-interface-header",
                            type: autocompleteTypes.controlType,
                            label: STRINGS.globalFilters.categoryLabels.detectedInterface,
                        });
                    } else if (type === FIELDS.application) {
                        processedResults.push({
                            id: "detected-app-header",
                            type: autocompleteTypes.controlType,
                            label: STRINGS.globalFilters.categoryLabels.detectedApplication,
                        });
                    } else if (type === FIELDS.location) {
                        processedResults.push({
                            id: "detected-location-header",
                            type: autocompleteTypes.controlType,
                            label: STRINGS.globalFilters.categoryLabels.detectedLocation,
                        });
                    } else if (type === FIELDS.impactedApplication) {
                        processedResults.push({
                            id: "app-header",
                            type: autocompleteTypes.controlType,
                            label: STRINGS.globalFilters.categoryLabels.impactedApplication,
                        });
                    } else if (type === FIELDS.impactedLocation) {
                        processedResults.push({
                            id: "loc-header",
                            type: autocompleteTypes.controlType,
                            label: STRINGS.globalFilters.categoryLabels.impactedLocation,
                        });
                    } else if (type === FIELDS.impactedUser) {
                        processedResults.push({
                            id: "user-header",
                            type: autocompleteTypes.controlType,
                            label: STRINGS.globalFilters.categoryLabels.impactedUser,
                        });
                    }
                    const items = resultsPerCategory[type] || [];
                    for (const item of items) {
                        processedResults.push({
                            ...item,
                            className: "pl-4",
                            showAlways: true,
                        });
                    }
                }
                setSuggestAPIAutoCompletes(processedResults);
                setSuggestQueryInProgress(false);
                lastQueryString.current = "";
            }
        }
    }

    function loadAutocompleteData(delay = 1000) {
        // Load autocomplete data by running the query. Delay the request by one second to let any other query requests in the page go through first.
        setTimeout(() => {
            autocompleteDataQuery.run({
                ...(isCustomTimeSet() ? {
                    queryVariables: {
                        ...durationToRoundedTimeRange(DURATION.HOUR_1) as any
                    }
                } : {})
            });
        }, delay);
    }

    const filtersCount = useRef(0);
    // Load autocomplete data on demand when it's not available in cache and there are
    // already some filters for which we need to do name resolution
    if (!DATA_FOR_AUTOCOMPLETE && Object.keys(filters).length > 0) {
        if (autocompleteDataQuery.loading === false && !autocompleteDataQuery.error) {
            if (autocompleteDataQuery.data || USE_AUTOCOMPLETE_PRELOADER_QUERY === false) {
                DATA_FOR_AUTOCOMPLETE = [
                    ...STATIC_PRIORITIES,
                    ...STATIC_STATUSES,
                    ...STATIC_RUNBOOK_TYPES,
                    ...runbookAutoCompletes,
                    // The below ones were used when we pre-fetched all sites, devices, apps and incidents and used them to populate the list. We have switched to using the suggest API.
                    // If in the future we have some other list of items that should be pre-fetched using the autocompleteDataQuery, follow below pattern and enable USE_AUTOCOMPLETE_PRELOADER_QUERY flag
                    // ...(autocompleteDataQuery.data?.sites?.nodes ? autocompleteDataQuery.data.sites?.nodes.map(site => ({ type: autocompleteTypes.site, filter: FILTER_NAME.siteId, label: site.name || STRINGS.site + " " + site.id, id: site.id, name: site.name })) : []),
                    // ...(autocompleteDataQuery.data?.devices?.nodes ? autocompleteDataQuery.data.devices?.nodes.map(device => ({ type: autocompleteTypes.device, filter: FILTER_NAME.hostId, label: device.id, info: device.cityName, id: device.id, cityName: device.cityName })) : []),
                    // ...(autocompleteDataQuery.data?.apps?.nodes ? autocompleteDataQuery.data.apps?.nodes.map(app => ({ type: autocompleteTypes.app, filter: FILTER_NAME.applicationId, label: app.id, id: app.id })) : []),
                    // ...(autocompleteDataQuery.data?.incidents?.nodes ? autocompleteDataQuery.data.incidents?.nodes.map(incident => ({ type: autocompleteTypes.incident, filter: FILTER_NAME.incidentId, label: incident.id, id: incident.id })) : []),
                ];
            } else {
                loadAutocompleteData();
            }
        }
    }

    useEffect(() => {
        if (DATA_FOR_AUTOCOMPLETE && USE_AUTOCOMPLETE_PRELOADER_QUERY) {
            loadAutocompleteData(10);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    const disabledFiltersMap = disabledFilters.reduce((o, i) => { o[i] = true; return o; }, {});
    const hiddenFiltersMap = hiddenFilters.reduce((o, i) => { o[i] = true; return o; }, {});
    const supportedFiltersMap = [...supportedFilters, ...additionalSupportedFilters].reduce((o, i) => { o[i] = true; return o; }, {});
    let hasUsableFilters = false;
    const hasUsableFiltersRef = useRef<boolean>();
    for (const filterID in supportedFiltersMap) {
        if (!disabledFiltersMap[filterID] && !hiddenFiltersMap[filterID] && FILTER_TO_AUTOCOMPLETE_TYPE_MAP[filterID] !== undefined) {
            hasUsableFilters = true;
            break;
        }
    }
    // Wrapping callback calling logic within useEffect so that it happens after render completes
    useEffect(() => {
        if (hasUsableFiltersRef.current !== hasUsableFilters) {
            hasUsableFiltersRef.current = hasUsableFilters;
            if (onUsableFiltersChange) {
                onUsableFiltersChange(hasUsableFiltersRef.current);
            }
        }
    });
    const [filterJSX, setFilterJSX] = useState<ReactNode[]>([]);
    useEffect(() => {
        generateFiltersBarJSX().then(asyncFiltersJSX => setFilterJSX(asyncFiltersJSX));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, autocompleteDataQuery.data, runbookAutoCompletes, supportedFiltersFromHook, time]);

    // Even though the value of supportedFilters stays the same, the reference of
    // the array gets updated and that triggers the logic within useEffect to run.
    // So I'm storing the value of supportedFiltersMap in a ref and comparing
    // against it to identify an actual change.
    const lastSupportedFiltersMap = useRef(supportedFiltersMap);
    // When mounting the global filters control
    useEffect(() => {
        if (!isEqual(lastSupportedFiltersMap.current, supportedFiltersMap)) {
            lastSupportedFiltersMap.current = supportedFiltersMap;
            // If there are no filters currently that were applied due to
            // filter values already in the UI (from filters passed in the URL)
            // This first statement was the check that was there originally, it uses the user preferences if 
            // there are no non-hidden filters in the filter list.  We are changing this to say only use the 
            // user preferences if there is no filter in the url
            //if (!hasFilters() && userPrefsKey) {
            if (Object.keys(filters).length === 0 && userPrefsKey) {
                // Get filters from user preferences
                getFiltersFromUserPreference(userPrefsKey).then(filtersObj => {
                    // If preferences had filters saved
                    // And if filters in user preferences had values
                    // that apply for currently supported filters
                    if (hasFilters(filtersObj)) {
                        // Then set those filter values (use setMultipleFilters instead of
                        // setAllFilters so that we don't lose any other unsupported filters
                        // that other controls are using)
                        const supportedFiltersObj = {};
                        for (const filterId in filtersObj) {
                            // If this filter is a key that is both supported and isn't suppressed using hidden filters map
                            if (supportedFiltersMap[filterId] && !hiddenFiltersMap[filterId]) {
                                supportedFiltersObj[filterId] = filtersObj[filterId];
                            }
                        }
                        if (!isEmpty(supportedFiltersObj)) {
                            setMultipleFilters(supportedFiltersObj);
                        }
                    }
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supportedFilters]);

    function hasFilters(filtersObj = filters) {
        let hasFilters = false;
        for (const filter in filtersObj) {
            if (!hiddenFiltersMap[filter]) {
                hasFilters = true;
                break;
            }
        }
        return hasFilters;
    }

    async function generateFiltersBarJSX() {
        const tags: ReactNode[] = [];
        const filtersListInOrder = Object.keys(filters).sort((a, b) => {
            // Bring disabled filters to the top as these are the ones that are restricted from being edited
            if (disabledFiltersMap[a] && !disabledFiltersMap[b]) {
                return -1;
            } else if (disabledFiltersMap[b] && !disabledFiltersMap[a]) {
                return 1;
                // Move filters that aren't supported to the end of the list.
            } else if (supportedFiltersMap[a] && !supportedFiltersMap[b]) {
                return -1;
            } else if (supportedFiltersMap[b] && !supportedFiltersMap[a]) {
                return 1;
            } else {
                return 0;
            }
        });
        let groupIndex = 0;
        let activeFiltersCount = 0;
        if (showTimeControl && isCustomTimeSet()) {
            let timeValue = formatTimeToUserFriendlyString(time);
            tags.push(<span className="spacer ml-2" key="tags-time">
                <span className="text-secondary">{customFilterNames["time"] || STRINGS.globalFilters.filterLabels.time} =</span>
                <Tag key="time-value"
                    // intent={supportedFiltersMap[filterID] ? Intent.PRIMARY : Intent.NONE}
                    style={{ backgroundColor: CHART_SERIES_COLORS[groupIndex] }}
                    className="ml-1 my-1 py-1 px-2 align-middle"
                    title={timeValue}
                    data-type="time"
                    icon={<Icon icon={IconNames.TIME} />}
                    onRemove={resetTime}
                >{timeValue}</Tag>
            </span>);
        }
        let uniqueTagsCount = 0;
        for (const filterID of filtersListInOrder) {
            const filterList = Array.isArray(filters[filterID]) ? filters[filterID] : [filters[filterID]];
            let tagGroup: ReactNode[] = [];
            const iconForFilterType = ICON_MAP[FILTER_TO_AUTOCOMPLETE_TYPE_MAP[filterID]];
            if (!hiddenFiltersMap[filterID]) {
                if (supportedFiltersMap[filterID]) {
                    activeFiltersCount++;
                }
                for (const value of filterList) {
                    const label = String(await getFilterBarLabelForItem(filterID as FILTER_NAME, value));
                    tagGroup.push(<Tag key={filterID + "_" + value}
                        // intent={supportedFiltersMap[filterID] ? Intent.PRIMARY : Intent.NONE}
                        style={{ backgroundColor: CHART_SERIES_COLORS[groupIndex] }}
                        className={"filter-tag ml-1 my-1 py-1 px-2 align-middle" + (supportedFiltersMap[filterID] ? "" : " opacity-non-essential")}
                        title={value} icon={iconForFilterType && <Icon icon={iconForFilterType} />}
                        data-type={filterID}
                        onRemove={disabledFiltersMap[filterID] || disabledFiltersMap[filterID] ? undefined : () => {
                            handleItemRemoved(FILTER_NAME[filterID], value);
                        }}
                    >{label}</Tag>);
                    uniqueTagsCount++;
                }
                if (tagGroup.length > 0) {
                    tags.push(<span className="spacer ml-2" key={"tags-" + filterID}>
                        <span className="text-secondary">{customFilterNames[filterID] || STRINGS.globalFilters.filterLabels[filterID] || filterID} =</span>
                        {tagGroup}
                    </span>);
                }
                groupIndex = (groupIndex + 1) >= CHART_SERIES_COLORS.length ? 0 : (groupIndex + 1);
            }
        }
        if (uniqueTagsCount > 1) {
            tags.push(<Button key="clear-filter-btn" onClick={clearAllVisibleFilters} minimal small intent={Intent.DANGER} className="ml-2 btn-link" text={STRINGS.globalFilters.clearAll} />)
        }
        if (filtersCount.current !== activeFiltersCount) {
            filtersCount.current = activeFiltersCount;
            if (onFilterCountChange) {
                onFilterCountChange(filtersCount.current);
            }
        }

        return tags;
    }

    const handleItemSelection = (item: autocompleteItemType | controlItemType) => {
        if (item.type !== autocompleteTypes.controlType) {
            let filterType: FILTER_NAME | undefined = item.filter;
            let value: string | undefined = item.id;
            if (filterType && value) {
                const updatedFilters = addFilterValue(filterType, value);
                // Since user has manually added a filter, save the updated filters object to user preferences
                saveFiltersToUserPreference(updatedFilters, undefined, userPrefsKey).then(() => {
                    addFilterToRecentSearches({
                        item,
                        userPrefsKey: recentFiltersUserPrefsKey,
                    });
                });
            }
        }
    }

    const handleItemRemoved = (filter: FILTER_NAME, value) => {
        const updatedFilters = removeFilterValue(filter, value);
        if (!updatedFilters || updatedFilters[filter] === undefined) {
            // Clear the filter from user preferences if the last item got removed
            clearFiltersFromUserPreference([filter], userPrefsKey);
        } else {
            // Just update user preferences if there are still values for the removed filter
            saveFiltersToUserPreference(updatedFilters, undefined, userPrefsKey);
        }
    }

    const clearAllVisibleFilters = () => {
        const currentFiltersCopy = Object.assign({}, filters);
        const removedFilters: Array<FILTER_NAME> = [];
        for (const filterID in filters) {
            if (!disabledFiltersMap[filterID] && !hiddenFiltersMap[filterID]) {
                delete currentFiltersCopy[filterID];
                removedFilters.push(FILTER_NAME[filterID]);
            }
        }
        setAllFilters(currentFiltersCopy);
        if (showTimeControl && isCustomTimeSet()) {
            resetTime();
        }

        clearFiltersFromUserPreference(removedFilters, userPrefsKey);
    }

    const showInputTextField = hasUsableFilters && !hidden;
    const showLoadingIndicator = suggestQueryInProgress || (hasUsableFilters && !hidden && autocompleteDataQuery.loading);

    const suggestInput = useRef<IRef<HTMLInputElement> | undefined>();
    const autoOpenedAlready = useRef(!hidden);
    useEffect(() => {
        let autoOpen = false;
        if (autoOpenDropDownOnRender === undefined) {
            autoOpen = !hasFilters();
        } else {
            autoOpen = autoOpenDropDownOnRender;
        }
        if (autoOpen && showInputTextField && suggestInput.current && !autoOpenedAlready.current) {
            autoOpenedAlready.current = true;
            suggestInput.current.focus();
        }
    });

    const preferences = useUserPreferences({
        listenOnlyTo: {
            [recentFiltersUserPrefsKey]: ""
        }
    });
    const recentResults = parseRecentSearchesFromUserPrefs(preferences[recentFiltersUserPrefsKey] as string)
        .filter(item => item.filter && supportedFiltersMap[item.filter] && !hiddenFiltersMap[item.filter] && !disabledFiltersMap[item.filter]);

    // When clicking on "Cancel" in the content that's displayed under the suggest control's
    // autocomplete dropdown, we want to close the dropdown. To do that, we are using an index
    // that will be updated which will cause the suggest control to be re-rendered by changing
    // it's key property
    const [renderCount, setRenderCount] = useState(0);
    function closeSuggestDropdown() {
        setRenderCount(renderCount + 1);
    }

    const AutocompleteSuggest = Suggest.ofType<autocompleteItemType | controlItemType>();

    return <div className={"global-filters-holder d-flex align-items-center" + (className ? " " + className : "") + (hidden ? " d-none" : "")}>
        {
            showInputTextField && <AutocompleteSuggest
                key={"global-filters-field-" + renderCount}
                className="filter-autocomplete-input"
                inputProps={{
                    placeholder: STRINGS.globalFilters.placeholder,
                    className: "w-min-3",
                    inputRef: suggestInput,
                }}
                resetOnSelect={false}
                closeOnSelect={true}
                resetOnClose={true}
                popoverProps={{ minimal: true, popoverClassName: "h-max-6 overflow-auto global-filter-popover" }}
                onItemSelect={handleItemSelection}
                items={DATA_FOR_AUTOCOMPLETE ? [...DATA_FOR_AUTOCOMPLETE, ...suggestAPIAutoCompletes].filter((item: any) => item.filter === undefined || (supportedFiltersMap[item.filter] && !hiddenFiltersMap[item.filter] && !disabledFiltersMap[item.filter])) : []}
                itemRenderer={renderAutocompleteResultForItem}
                onQueryChange={searchSuggestApiOnDemand}
                inputValueRenderer={item => ""}
                itemPredicate={(query, item, _index) => {
                    const queryLowerCase = query?.toLowerCase() || "";
                    let show = false;
                    if (item.type === autocompleteTypes.controlType || item.showAlways || (queryLowerCase.length > 0 && (
                        item.label.toLowerCase().includes(queryLowerCase) || item.info?.toLowerCase().includes(queryLowerCase)
                    ))) {
                        show = true;
                    }
                    return show;
                }}
                initialContent={<>
                    {
                        showTimeControl &&
                        <TimePeriodMoreView
                            showInline
                            sideBySideLayout
                            showTimePeriodOptions={[
                                DURATION.HOUR_1,
                                DURATION.HOUR_6,
                                DURATION.DAY_1,
                                DURATION.DAY_2,
                                DURATION.DAY_7,
                                DURATION.LAST_1_MONTH,
                            ]}
                            durationSelectionHandler={(option, timeRange) => {
                                if (option === TIME_OPTION_VALUES.CUSTOM) {
                                    if (timeRange) {
                                        setTimeRange(timeRange);
                                    }
                                } else {
                                    setDuration(option);
                                }
                                suggestInput.current?.click();
                            }}
                            timeOption={isCustomTimeSet() ? time : undefined}
                            onCancelClicked={closeSuggestDropdown}
                        />
                    }
                    {
                        recentResults.length > 0 && <>
                            <MenuItem icon={IconNames.HISTORY} disabled={true} text={STRINGS.globalFilters.recentResultsLabel} />
                            {
                                recentResults.map(item => {
                                    return renderAutocompleteResultForItem(item, {
                                        handleClick: () => {
                                            handleItemSelection(item);
                                        },
                                        modifiers: {},
                                        query: ""
                                    })
                                })
                            }
                        </>
                    }
                </>}
                noResults={<MenuItem disabled={true} text={suggestQueryInProgress ? STRINGS.globalFilters.loading : STRINGS.globalFilters.empty} />}
            />
        }
        {
            // Show a loading spinner when autocomplete data that powers the suggest field is being loaded
            showLoadingIndicator && <Spinner size={Spinner.SIZE_SMALL} className="ml-2 d-inline-block" />
        }
        <span className="filters-holder">
            {/* Show a loading spinner if there are global filters but filterJSX is still under construction because of some label lookup calls */}
            {hasFilters() && filterJSX.length === 0 ? <Spinner size={Spinner.SIZE_SMALL} className="ml-2" /> : filterJSX}
        </span>
    </div>;
}

/** renders the autocomplete result in the list of autocomplete results and also the list of recent filters.
 *  @param item the autocompleteItemType with the result of the autocomplete.
 *  @param param1 
 *  @returns the JSX with the MenuItem that displays the autocomplete result in the list. */
const renderAutocompleteResultForItem = (item: autocompleteItemType | controlItemType, { handleClick, modifiers, query }): JSX.Element => {
    if (item.type === autocompleteTypes.controlType) {
        return <MenuItem
            key={"ac-" + item.type + "-" + item.id + (item.uuid ? "-" + item.uuid : "")}
            disabled
            text={item.label}
        />;
    } else {
        const iconForFilterType = ICON_MAP[item.type];
        return (
            <MenuItem
                icon={
                    <Icon
                        icon={iconForFilterType}
                    />
                }
                className={item.className}
                active={modifiers.active}
                disabled={item.disabled || modifiers.disabled}
                key={"ac-" + item.type + "-" + item.id + (item.uuid ? "-" + item.uuid : "")}
                onClick={handleClick}
                text={<div className="d-flex align-items-center justify-content-between">
                    <span>{item.label}</span>
                    {item.info && <span className="display-9 font-weight-500 ml-2 text-secondary">({item.info})</span>}
                </div>}
            />
        );
    }
}

/** returns the label that should be displayed in the filter bar for the item.
 *  @param type the FILTER_NAME for the item.
 *  @param value the value returned by the item.
 *  @returns a Promise with the label as a String.*/
async function getFilterBarLabelForItem(type: FILTER_NAME, value: string): Promise<string> {
    let label = value;
    if (type === FILTER_NAME.siteId) {
        label = isNaN(Number(value)) ? value : STRINGS.site + " " + value;
        if (DATA_FOR_AUTOCOMPLETE) {
            for (const item of DATA_FOR_AUTOCOMPLETE) {
                if (item.type === autocompleteTypes.site && item.id === value) {
                    label = item.label;
                    break;
                }
            }
        }
    } else if (type === FILTER_NAME.configurationIds) {
        if (DATA_FOR_AUTOCOMPLETE) {
            for (const item of DATA_FOR_AUTOCOMPLETE) {
                if (item.type === autocompleteTypes.runbook && item.id === value) {
                    label = item.label;
                    break;
                }
            }
        }
    } else if (type === FILTER_NAME.priority) {
        label = PRIORITY_TO_LABEL_MAP[value] || value;
    } else if (type === FILTER_NAME.incidentStatus) {
        label = INCIDENT_STATUS_TO_LABEL_MAP[value] || value;
    } else if (type === FILTER_NAME.impactedUserId) {
        const [ip, userName] = value.split(";");
        label = userName ? userName + (ip ? " (" + ip + ")" : "") : ip;
    } else if (
        type === FILTER_NAME.deviceName || type === FILTER_NAME.interfaceName || 
        type === FILTER_NAME.applicationName || type === FILTER_NAME.locationName
    ) {
        // Use this if we embed the uuid in the name.
        //label = value.includes(";") ? value.substring(0, value.lastIndexOf(";")) : value;
        label = value;
    }
    return label;
}

/** adds a new result to the list of recent searches.
 *  @param item the autocomplete result item to be added to the recent search.
 *  @param userPrefsKey the user prefs key to use to save the list. */
async function addFilterToRecentSearches(
    {item, userPrefsKey}: {item: autocompleteItemType, userPrefsKey: string}
) {
    let recordedRecentSearches = await getRecentSearches(userPrefsKey);
    // These are the list of props that we don't need for displaying the item in recent searches.
    const blockListProps = ["className", "disabled", "showAlways"];
    let sanitizedItem: autocompleteItemType = { ...item };
    for (const key in sanitizedItem) {
        if (sanitizedItem[key] === undefined) {
            delete sanitizedItem[key];
        }
    }
    for (const propName of blockListProps) {
        delete sanitizedItem[propName];
    }
    for (let i = 0; i < recordedRecentSearches.length; i++) {
        const searchItem = recordedRecentSearches[i];
        if (isEqual(searchItem, sanitizedItem)) {
            recordedRecentSearches.splice(i--, 1);
        }
    }
    if (recordedRecentSearches.length === 5) {
        recordedRecentSearches.pop();
    }
    recordedRecentSearches.splice(0, 0, sanitizedItem);
    setUserPreferences({
        [userPrefsKey]: JSON.stringify(recordedRecentSearches),
    });
}

/** returns the list of recent searches from the user preferences.
 *  @param userPrefsKey a String with the key used to store the recent searches in the preferences.
 *  @returns the list of recent searches stored in user preferences. */
async function getRecentSearches(userPrefsKey: string): Promise<Array<RecentSearch>> {
    const preferences = await getUserPreferences();
    return parseRecentSearchesFromUserPrefs(preferences[userPrefsKey]);
}

/** parses the recent search list that was returned by the user preferences.
 *  @param recentSearchesFromAPI a String with the recent search list that user preferences returned.  This
 *      is stringified JSON.
 *  @returns an array with the recent searches. */
function parseRecentSearchesFromUserPrefs(recentSearchesFromAPI: string): Array<RecentSearch> {
    return recentSearchesFromAPI ? JSON.parse(recentSearchesFromAPI) : [];
}

/** returns the impact text for impacted applications and locations.
 *  @param impact the impact information returned by search.  This maybe either a string or an object
 *      depending on which version of search w are connected to.
 *  @returns the text that should be displayed for the impact. */
function getImpactText(impact: {name: string, ipaddr: string} | string): string {
    if (typeof impact === "string") {
        // This will occur for the old search.  Remove this in a later sprint when the new 
        // version of search is deployed everywhere.
        return impact;
    } else {
        // This will occur for the new search.
        return impact.name || impact.ipaddr;
    }
}

/** returns the impact text for impacted user.
 *  @param impact the impact information returned by search.  This maybe either a string or an object
 *      depending on which version of search w are connected to.
 *  @returns the text that should be displayed for the impact. */
function getUserImpactText(impact: {name: string, ipaddr: string} | string): {name?: string, ipaddr?: string} {
    if (typeof impact === "string") {
        // This will occur for the old search.  Remove this in a later sprint when the new 
        // version of search is deployed everywhere.
        const [ip, userName] = impact.split(";");
        return {name: userName, ipaddr: ip};
    } else {
        // This will occur for the new search.
        return impact;
    }
}
