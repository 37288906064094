import React, { useCallback, useEffect, useState } from 'react';
import {
	ButtonProps,
	DialogStep,
	MultistepDialog,
	Intent,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import {
	ErrorToaster,
	SuccessToaster,
	LoadingOverlay,
	useStateSafePromise,
} from '@tir-ui/react-components';
import { MappingConfig } from 'utils/services/MappingConfigApiService';
import {
	IncidentTriggerOptions,
	LifecycleTriggerOptions,
	TriggerPanel,
} from './TriggerPanel';
import { ConditionPanel } from './ConditionPanel';
import { RunbookPanel } from './RunbookPanel';
import { FinalPanel } from './FinalPanel';
import { TriggerTypes } from '../views/MappingConfigurationView';
import { Variant } from 'components/common/graph/types/GraphTypes';
import { RunbookNode, RunbookService } from 'utils/services/RunbookApiService';
import { ConditionTree } from 'components/common/condition-tree-builder/ConditionTreeBuilder';
import {
	convertConditionTreeToDALFormat,
	convertExpressionToTreeFormat,
	validateConditionTree,
} from '../views/decision-branch/MappingDecisionBranchView';
import { getPropFreeConditionTree } from 'components/common/condition-tree-builder/condition/ConditionUtils';

import { STRINGS } from 'app-strings';

import './AddMappingModal.scss';

type MappingCondition = {
	condition: {
		id: string;
		category: string;
		key: string;
		operation: string;
		value: string;
	};
};

interface AddMappingMutationInput {
	mapping: {
		name: string;
		runbookId: string;
		triggerType: string;
		description: string;
		enabled: boolean;
		order: number;
	};
	expression: {
		block: {
			operator: string;
			expressions: Array<MappingCondition>;
		};
	};
}

interface EditMappingMutationInput {
	mapping: {
		id: string;
		name: string;
		runbookId: string;
		description: string;
		enabled: boolean;
	};
	expression: {
		block: {
			operator: string;
			expressions: Array<MappingCondition>;
		};
	};
}

const AddMappingModal = React.forwardRef((props: any, ref) => {
	React.useImperativeHandle(ref, () => ({
		setEdit(edit: boolean) {
			setEditMode(edit);
		},
		handleOpen() {
			setIsOpen(!isOpen);
		},
		setTrigger(trigger: string) {
			setTriggerValue(trigger);
		},
		setTriggerType(triggerType: string) {
			setTriggerTypeValue(triggerType.toLocaleLowerCase());
		},
		setMapping(mapping: MappingConfig) {
			setMapping(mapping);
		},
		setMappingData(data: MappingConfig[]) {
			setMappingData(data);
		},
	}));

	const [mapping, setMapping] = useState<MappingConfig>();
	const [mappingData, setMappingData] = useState<MappingConfig[]>();
	const [triggerTypeValue, setTriggerTypeValue] = useState<string>(
		TriggerTypes.INCIDENT
	);
	const [triggerValue, setTriggerValue] = useState<string>(
		STRINGS.MAPPING_CONFIGURATION_PAGE.addMappingModal.panels.selectTrigger
			.fields.selectTrigger
	);
	const [conditionTreeValue, setConditionTreeValue] = useState<any>({});
	const [validConditionTree, setValidConditionTree] =
		useState<boolean>(false);
	const [executeRunbookValue, setExecuteRunbookValue] =
		useState<boolean>(false);
	const [runbookValue, setRunbookValue] = useState<string>('');
	const [automationNameValue, setAutomationNameValue] = useState<string>('');
	const [automationDescriptionValue, setAutomationDescriptionValue] =
		useState<string>('');
	const [automationOrderValue, setAutomationOrderValue] = useState<number>(1);

	const [editMode, setEditMode] = useState<boolean>(false);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);

	const [runbooks, setRunbooks] = useState<RunbookNode[] | undefined>(
		undefined
	);

	const [executeSafely] = useStateSafePromise();
	const [loading, setLoading] = useState(false);

	const fetchRunbooks = useCallback(
		(variant: Variant) => {
			return executeSafely(RunbookService.getRunbooks(variant)).then(
				(response: any) => {
					setRunbooks(response);
					setLoading(false);
				},
				(error) => {
					console.error(error);
					setLoading(false);
				}
			);
		},
		[executeSafely]
	);

	useEffect(() => {
		let variant;
		if (editMode) {
			if (
				Object.values(IncidentTriggerOptions).includes(
					mapping?.triggerType
				)
			) {
				variant = Variant.INCIDENT;
				setTriggerTypeValue(TriggerTypes.INCIDENT);
			} else if (
				Object.values(LifecycleTriggerOptions).includes(
					mapping?.triggerType
				)
			) {
				variant = Variant.LIFECYCLE;
				setTriggerTypeValue(TriggerTypes.LIFECYCLE);
			} else {
				variant = Variant.INCIDENT;
				setTriggerTypeValue(TriggerTypes.EXTERNAL);
			}
			setTriggerValue(mapping?.triggerType);
			if (mapping?.expression) {
				const conditionTreeValue = convertExpressionToTreeFormat(JSON.parse(mapping?.expression));
				setConditionTreeValue(conditionTreeValue)
				validateConditions(conditionTreeValue);
			} else {
				setConditionTreeValue({})
			}
			setExecuteRunbookValue(Boolean(mapping?.enabled));
			setRunbookValue(String(mapping?.runbookName));
			setAutomationNameValue(String(mapping?.name));
			setAutomationDescriptionValue(String(mapping?.description));
			setAutomationOrderValue(Number(mapping?.order));
		}
		if (!variant) {
			triggerTypeValue === TriggerTypes.LIFECYCLE
				? (variant = Variant.LIFECYCLE)
				: (variant = Variant.INCIDENT);
		}
		if (isOpen) {
			setLoading(true);
			fetchRunbooks(variant);
		}
	}, [fetchRunbooks, isOpen, mapping, editMode, triggerTypeValue]);

	const handleClose = useCallback(() => {
		setIsOpen(false);
		setMapping(undefined);
		setTriggerTypeValue(TriggerTypes.INCIDENT);
		setTriggerValue('');
		setConditionTreeValue({});
		setExecuteRunbookValue(false);
		setRunbookValue('');
		setAutomationNameValue('');
		setAutomationDescriptionValue('');
		setAutomationOrderValue(1);
		setEditMode(false);
		setSubmitting(false);
		setRunbooks(undefined);
	}, []);

	const [createAutomationMapping] = useMutation<any, AddMappingMutationInput>(
		loader('./../automation-create-mutation.graphql'),
		{
			onCompleted: (data) => {
				handleClose();
                setTimeout(() => {
                    SuccessToaster({
                        message:
                            STRINGS.MAPPING_CONFIGURATION_PAGE.messages
                                .mappingCreated,
                    })
                }, 2000);
				return Promise.resolve(undefined);
			},
			onError: (err) => {
				setSubmitting(false);
				ErrorToaster({
					message: err.message === STRINGS.MAPPING_CONFIGURATION_PAGE.messages.mappingCreatedFailedDupMessage ? STRINGS.MAPPING_CONFIGURATION_PAGE.messages.mappingCreatedFailedDup : STRINGS.MAPPING_CONFIGURATION_PAGE.messages.mappingCreatedFailed,
				});
				return Promise.resolve(err.message);
			},
			refetchQueries: ['automation'],
		}
	);

	const [setAutomationMapping] = useMutation<any, EditMappingMutationInput>(
		loader('./../automation-update-mutation.graphql'),
		{
			onCompleted: (data) => {
				handleClose();
                setTimeout(() => {
                    SuccessToaster({
                        message:
                            STRINGS.MAPPING_CONFIGURATION_PAGE.messages
                                .mappingUpdated,
                    })
                }, 2000);
				return Promise.resolve(undefined);
			},
			onError: (err) => {
				setSubmitting(false);
				ErrorToaster({
					message: err.message,
				});
				console.error(err?.message);
				return Promise.resolve(err.message);
			},
			refetchQueries: ['automation'],
		}
	);

	const handleSubmit = useCallback(() => {
		setSubmitting(true);
		const isEmpty =
			Object.keys(conditionTreeValue).length === 0 &&
			conditionTreeValue.constructor === Object;
		const payload = editMode
			? {
					variables: {
						mapping: {
							id: mapping?.hasOwnProperty('id') ? mapping.id : '',
							name: automationNameValue
								? automationNameValue
								: '',
							runbookId:
								runbookValue && executeRunbookValue
									? (runbooks || []).filter((runbook) => {
											return (
												runbook.name === runbookValue
											);
									  })[0]['id']
									: '',
							description: automationDescriptionValue
								? automationDescriptionValue
								: '',
							enabled: executeRunbookValue,
						},
						...(!isEmpty && {
							expression: conditionTreeValue.conditions.length
								? convertConditionTreeToDALFormat(
										getPropFreeConditionTree(
											conditionTreeValue
										)
								  )
								: null,
						}),
					},
					notifyOnNetworkStatusChange: true,
			  }
			: {
					variables: {
						mapping: {
							name: automationNameValue
								? automationNameValue
								: '',
							runbookId:
								runbookValue && executeRunbookValue
									? (runbooks || []).filter((runbook) => {
											return (
												runbook.name === runbookValue
											);
									  })[0]['id']
									: '',
							triggerType: triggerValue,
							description: automationDescriptionValue
								? automationDescriptionValue
								: '',
							enabled: executeRunbookValue,
							order: automationOrderValue
								? automationOrderValue
								: 1,
						},
						...(!isEmpty && {
							expression: convertConditionTreeToDALFormat(
								getPropFreeConditionTree(conditionTreeValue)
							),
						}),
					},
					notifyOnNetworkStatusChange: true,
			  };
		try {
			// @ts-ignore
			editMode ? setAutomationMapping(payload) : createAutomationMapping(payload);
		} catch (error) {
			return Promise.reject('Error creating/updating mapping.');
		}
	}, [
		mapping,
		editMode,
		triggerValue,
		automationNameValue,
		automationDescriptionValue,
		automationOrderValue,
		runbookValue,
		runbooks,
		executeRunbookValue,
		conditionTreeValue,
		createAutomationMapping,
		setAutomationMapping,
	]);

	const maxOrderValue = mappingData
		? mappingData?.filter((item) => {
				return item.triggerType === triggerValue;
		  }).length + 1
		: 1;

	const finalButtonProps: Partial<ButtonProps> = {
		intent: Intent.PRIMARY,
		onClick: handleSubmit,
		text: STRINGS.MAPPING_CONFIGURATION_PAGE.addMappingModal.buttons
			.submitBtnText,
		loading: submitting,
		disabled:
			submitting ||
			!automationNameValue ||
			!automationDescriptionValue ||
			!automationOrderValue ||
			automationOrderValue < 1 ||
			automationOrderValue > maxOrderValue,
	};

	const validateConditions = (conditionTree: any) => {
		if (validateConditionTree(conditionTree)) {
			setValidConditionTree(true);
		} else {
			setValidConditionTree(false);
		}
	};

	const handleRunbooksRefresh = (variant: Variant) => {
		fetchRunbooks(variant);
	}

	const handleTriggerValueChange = (trigger: string): void => {
		setTriggerValue(trigger);
	};

	const handleConditionTreeValueChange = (
		conditionTreeValue: ConditionTree
	): void => {
		validateConditions(conditionTreeValue);
		setConditionTreeValue(conditionTreeValue);
	};

	const handleRunbookValueChange = (
		event: React.FormEvent<HTMLInputElement>
	): void => {
		setRunbookValue(event.currentTarget.value);
	};

	const handleExecuteRunbookValueChange = (
		event: React.FormEvent<HTMLInputElement>
	): void => {
		setExecuteRunbookValue(event.currentTarget.checked);
	};

	const handleAutomationNameValueChange = (
		event: React.FormEvent<HTMLInputElement>
	): void => {
		setAutomationNameValue(event.currentTarget.value);
	};

	const handleAutomationDescriptionValueChange = (
		event: React.FormEvent<HTMLTextAreaElement>
	): void => {
		setAutomationDescriptionValue(event.currentTarget.value);
	};

	const handleAutomationOrderValueChange = (
		event: React.FormEvent<HTMLInputElement>
	): void => {
		setAutomationOrderValue(Number(event.currentTarget.value));
	};

	if (loading) {
		return <LoadingOverlay visible={true} />;
	} else
		return (
			<React.Fragment>
				<div id="mapping_multistep_dialog">
					<MultistepDialog
						className="tir-add-automation-mapping main-content"
						onClose={handleClose}
						// @ts-ignore
						icon={IconNames.LIGHTNING}
						finalButtonProps={finalButtonProps}
						hasTitle={true}
						title={
							editMode
								? STRINGS.MAPPING_CONFIGURATION_PAGE
										.addMappingModal.title.edit
								: STRINGS.MAPPING_CONFIGURATION_PAGE
										.addMappingModal.title.add
						}
						autoFocus={true}
						navPosition={'left'}
						initialStepIndex={editMode ? 1 : 0}
						canEscapeKeyClose={true}
						canOutsideClickClose={false}
						enforceFocus={true}
						isCloseButtonShown={true}
						isOpen={isOpen}
						resetOnClose={true}
						usePortal={true}
					>
						<DialogStep
							id="select_trigger"
							className="tir-add-automation-mapping panel-content"
							panel={
								<TriggerPanel
									editMode={editMode}
									triggerTypeValue={triggerTypeValue}
									triggerValue={triggerValue}
									onTriggerValueChange={
										handleTriggerValueChange
									}
								/>
							}
							title={
								STRINGS.MAPPING_CONFIGURATION_PAGE
									.addMappingModal.panels.selectTrigger.title
							}
							nextButtonProps={{
								disabled:
									!mapping &&
									(triggerValue === '' ||
										triggerValue ===
											STRINGS.MAPPING_CONFIGURATION_PAGE
												.addMappingModal.panels
												.selectTrigger.fields
												.selectTrigger),
							}}
						/>
						<DialogStep
							id="set_conditions"
							className="tir-add-automation-mapping panel-content"
							panel={
								<ConditionPanel
									triggerValue={triggerValue}
									conditionTree={conditionTreeValue}
									onConditionTreeChange={
										handleConditionTreeValueChange
									}
								/>
							}
							title={
								STRINGS.MAPPING_CONFIGURATION_PAGE
									.addMappingModal.panels.setConditions.title
							}
							nextButtonProps={{
								disabled:
									Object.keys(conditionTreeValue).length !==
										0 &&
									conditionTreeValue.hasOwnProperty(
										'conditions'
									) &&
									conditionTreeValue.conditions.length > 0 &&
									!validConditionTree,
							}}
						/>
						<DialogStep
							id="runbook"
							className="tir-add-automation-mapping panel-content"
							panel={
								<RunbookPanel
									runbooks={runbooks}
									triggerValue={triggerValue}
									executeRunbookValue={executeRunbookValue}
									runbookValue={runbookValue}
									onRunbooksRefresh={handleRunbooksRefresh}
									onRunbookValueChange={
										handleRunbookValueChange
									}
									onExecuteRunbookValueChange={
										handleExecuteRunbookValueChange
									}
								/>
							}
							title={
								STRINGS.MAPPING_CONFIGURATION_PAGE
									.addMappingModal.panels.runbook.title
							}
							nextButtonProps={{
								disabled:
									executeRunbookValue &&
									(!runbookValue || runbookValue === 'null' ||
										runbookValue ===
											STRINGS.MAPPING_CONFIGURATION_PAGE
												.addMappingModal.panels.runbook
												.selectRunbook),
							}}
						/>
						<DialogStep
							id="final"
							className="tir-add-automation-mapping panel-content"
							panel={
								<FinalPanel
									editMode={editMode}
									triggerType={triggerTypeValue}
									conditionTree={conditionTreeValue}
									automationNameValue={automationNameValue}
									automationDescriptionValue={
										automationDescriptionValue
									}
									automationOrderValue={automationOrderValue}
									maxOrderValue={maxOrderValue}
									onAutomationNameValueChange={
										handleAutomationNameValueChange
									}
									onAutomationDescriptionValueChange={
										handleAutomationDescriptionValueChange
									}
									onAutomationOrderValueChange={
										handleAutomationOrderValueChange
									}
								/>
							}
							title={
								STRINGS.MAPPING_CONFIGURATION_PAGE
									.addMappingModal.panels.final.title
							}
						/>
					</MultistepDialog>
				</div>
			</React.Fragment>
		);
});

export { AddMappingModal };
