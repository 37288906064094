import React from 'react';
import classNames from 'classnames';
import { Classes, Divider } from '@blueprintjs/core';
import { Form } from 'components/common/form';
import { TRIGGER_TYPE_MAP } from 'pages/riverbed-advisor/views/runbook-view/Runbook.type';
import { MappingDecisionBranchView } from '../views/decision-branch/MappingDecisionBranchView';

import { STRINGS } from 'app-strings';

export interface IConditionPanelProps {
	triggerValue: string;
	conditionTree: any;
	onConditionTreeChange: (conditionTree: any) => void;
}

const ConditionPanel: React.FC<IConditionPanelProps> = (props) => {
	return (
		<div className={classNames(Classes.DIALOG_BODY)}>
			<p>
				<b>
					{STRINGS.MAPPING_CONFIGURATION_PAGE.addMappingModal.panels
						.setConditions.subTitle + ':'}
				</b>
			</p>
			<Divider />
			<br />
            <div className="condition-wrapper">
                <Form initialValues={{}} loading={false}>
                    <MappingDecisionBranchView
                        triggerType={TRIGGER_TYPE_MAP[props.triggerValue]}
                        expression={props.conditionTree}
                        onExpressionChanged={(expression) => {
                            props.onConditionTreeChange(expression);
                        }}
                    />
                </Form>
            </div>
		</div>
	);
};

export { ConditionPanel };
