/** This module creates a new node for the react-flow graph that represents a NetIM node.
 *  @module
 */
import React, { CSSProperties } from "react";
import { Handle, NodeProps, Position } from "react-flow-renderer";
import { Icon, IconNames } from "@tir-ui/react-components";
import { NetIMSupportedTypes, NETIM_TYPES_TO_LABEL_MAP } from "utils/cloudim/TopologyUtils";

import './NetIMNode.scss';

export default function NetIMNode({ data }: NodeProps) {
    const handleStyle: CSSProperties = { visibility: "hidden" };
    const icons = {
        [NetIMSupportedTypes.SWITCH]: IconNames.NETIM_SWITCH,
        [NetIMSupportedTypes.ROUTER]: IconNames.NETIM_ROUTER,
        [NetIMSupportedTypes.FIREWALL]: IconNames.NETIM_FIREWALL,
        [NetIMSupportedTypes.LOADBALANCER]: IconNames.NETIM_LOAD_BALANCER,
        [NetIMSupportedTypes.HOST]: IconNames.NETIM_HOST,
        [NetIMSupportedTypes.WANACCELERATOR]: IconNames.NETIM_WAN_ACCELERATOR,
        [NetIMSupportedTypes.MULTILAYERSWITCH]: IconNames.NETIM_SWITCH,
        [NetIMSupportedTypes.PRINTER]: IconNames.NETIM_PRINTER,
        [NetIMSupportedTypes.UNIFIEDCOMMUNICATION]: IconNames.NETIM_UNIFIED_COMMUNICATION,
        [NetIMSupportedTypes.WIRELESS]: IconNames.NETIM_WIRELESS,
        [NetIMSupportedTypes.SDWAN]: IconNames.NETIM_SDWAN,
        [NetIMSupportedTypes.OTHER]: IconNames.DEVICES
    }

    let iconToUse: any = icons[data.type] ?? IconNames.BLANK;

    return (
        <div className="netimNode">
            <div className="icon" title={NETIM_TYPES_TO_LABEL_MAP[data.type]} onContextMenu={(e) => onNodeRightClick(e)}>
                <Icon icon={iconToUse} />
            </div>
            <Handle
                type="target"
                position={Position.Left}
                style={handleStyle}
            />
            <Handle
                type="source"
                position={Position.Right}
                style={handleStyle}
            />
            <div className="labelContainer">
                <div className="details">
                    <span>
                        {data.label}
                    </span>
                </div>
            </div>
        </div>
    );

    function onNodeRightClick(e): any {
        e.preventDefault();
        e.stopPropagation();
        // TODO: Add some context menu when node is right clicked.
    }
}
