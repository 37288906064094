/** This module contains the editor for the new decision branch.
 *  @module
 */
import React, { useCallback, useEffect,useRef, useState, useContext } from 'react';
import { NodeWiresSetting, Variant } from '../../types/GraphTypes';
import { UniversalNode } from '../../UniversalNode';
import { SimpleNodeEditorProps } from '../simple/SimpleNodeEditor';
import { DecisionNodeUtil, DECISION_NODE_EDIT_PROPS, MetricDataType } from './DecisionNodeUtil';
import { getNodeFromGraphDef, dataOceanNodes, aggregatorNodes, transformNodes } from 'utils/runbooks/RunbookUtils';
import { useStateSafePromise } from 'utils/hooks';
import { DataLoadFacade } from 'components/reporting/data-load-facade/DataLoadFacade';
import { Button, IconName } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { generateRandomID, operationItem } from 'components/common/condition-tree-builder/condition/ConditionUtils';
import { STRINGS } from 'app-strings';
import { 
    DecisionOutputBlock, DefaultOutputDefinition, OutputBlockDefinition, OutputPassedDataOptions 
} from './DecisionOutputBlock';
import { Icon } from '@tir-ui/react-components';
import { Sortable } from 'components/common/sortable/Sortable';
import { setNativeValue } from 'reporting-infrastructure/utils/commonUtils';
import { Context, RunbookContext, VariableContextByScope } from 'utils/runbooks/RunbookContext.class';
import { 
    FunctionOperators, KeyOperators, MetricBaselineOperators, MetricComparisonOperators, MetricOperators, TriggerMetricOperators
} from '../LogicOperators';
import { COMPARISON_LABEL_MAP } from '../data-ocean/DataOceanComparison';
import { RunbookContextSummary } from '../RunbookContextSummary';
import { DataOceanMetadata } from '../data-ocean/DataOceanMetadata.type';
import { SHOW_CONTEXT } from 'components/enums/QueryParams';
import { DataOceanUtils } from '../data-ocean/DataOceanUtils';
import { VariableContext } from 'utils/runbooks/VariableContext';
import { GLOBAL_SCOPE, INCIDENT_SCOPE, RUNTIME_SCOPE } from 'utils/runbooks/VariablesUtils';
import { NodeLibraryNode } from 'pages/create-runbook/views/create-runbook/NodeLibrary';
import { 
    ConditionBlockOrchestratorOperationType, DEFAULT_OUTPUT_ID, HTTP_PREFIX, KEY_PREFIX, METRIC_PREFIX, OptionSetByType, 
    TRIGGER_GENERIC_METRIC_PREFIX, TRIGGER_METRIC_PREFIX, TRIGGER_PREFIX, VARIABLE_PREFIX 
} from 'components/hyperion/views/decision-branch/DecisionBranchUtils';
import { CustomPropertyContext } from 'pages/create-runbook/views/create-runbook/CustomPropertyTypes';
import "./DecisionNodeEditor.scss";
import { getArDataSources } from 'utils/stores/GlobalDataSourceTypeStore';

/** the properties that used in the decision branch editor. */
interface DecisionNodeProperties {
    /** a String with the input type. */
    inputType?: string;
    /** the array of outputs */
    outputs?: Array<any>;
    /** the data type. */
    dataType?: MetricDataType;
    /** the options for the node. */
    optionsConfig?: OptionSetByType;
    /** specifies whether or not to show the advanced section.  set to true and it is visible, in all other cases it is not visible. */
    showAdvanced?: boolean;
}

/** Renders the Decision Branch Editor UI.
 *  @param props - SimpleNodeEditor
 *  @param ref - React Forward Ref object of the current Component to be used in the parent component
 *  @returns the JSX with the decision branch React component. */
export const DecisionNodeEditor = React.forwardRef(({ selectedNode, libraryNode, graphDef, ...props }: SimpleNodeEditorProps, ref: any): JSX.Element => {
    const outputsExpressionElement = useRef(null);
    const decisionNodeEditorContainer = useRef<HTMLDivElement>(null);
    const [executeSafely] = useStateSafePromise();
    // Meta data about the object types, list of metrics and keys.
    const [objMetricMetaData, setObjMetricMetaData] = useState<DataOceanMetadata>();
    const [editMode, setEditMode] = useState(false);
    const [duplicateOutputLabels, setDuplicateOutputLabels] = useState<Array<string>>([]);
    let selNodeId = selectedNode?.getId();
    selNodeId = selNodeId? selNodeId: "";
    // context = new RunbookContext((selNode?.node , graphDef, DataOceanUtils.dataOceanMetaData);

    // Dataocean, aggregator or transform node properties
    const parentDataNode = DecisionNodeUtil.getParentNode(selNodeId, graphDef, [...dataOceanNodes, ...aggregatorNodes, ...transformNodes]);
    const parentDataNodeInputType = parentDataNode ? DecisionNodeUtil.getPropertyFromNode("objType", parentDataNode)?.value : undefined;

    const parentDataType = useRef<MetricDataType | undefined>(undefined);

    const {getVariables} = useContext(VariableContext);

    const customProperties = useContext(CustomPropertyContext);

    const fetchData = useCallback(
        () => {
            return executeSafely(DataOceanUtils.init()).then((response: any) => {
                setObjMetricMetaData(response);
            }, error => {
                console.error(error);
            });
        },
        [executeSafely]
    );

    useEffect(() => {
        // Fetch Meta data on load.
        fetchData();
    }, [fetchData]);

    // Component states
    const [curProperties, setCurProperties] = useState<DecisionNodeProperties>({
        inputType: selectedNode?.getProperty(DECISION_NODE_EDIT_PROPS.INPUT_TYPE),
        // passedData: selectedNode?.getProperty(LOGIC_NODE_EDIT_PROPS.PASSED_DATA),
        outputs: convertOutputCasesToControlFormat(selectedNode?.getProperty(DECISION_NODE_EDIT_PROPS.OUTPUT_CASES)),
        // summarized/timeseries
        dataType: selectedNode?.getProperty(DECISION_NODE_EDIT_PROPS.DATA_TYPE)
    });

    const [outputsExpressionList, setOutputsExpressionList] = useState<Array<OutputBlockDefinition | DefaultOutputDefinition>>(curProperties.outputs as Array<OutputBlockDefinition | DefaultOutputDefinition>);

    useEffect(() => {
        const outputCases = convertOutputCasesToControlFormat(selectedNode?.getProperty(DECISION_NODE_EDIT_PROPS.OUTPUT_CASES));
        if (selectedNode?.node) {
            let isTimeseries = Boolean(DecisionNodeUtil.getPropertyFromNode("timeSeries", parentDataNode)?.value) || Boolean(DecisionNodeUtil.getPropertyFromNode("outputDataFormat", parentDataNode)?.value === "timeseries");
            // Infer the input type and the dataType(summary/timeseries) from the parent DO node if possible
            parentDataType.current = parentDataNode ? (isTimeseries ? MetricDataType.TIMESERIES : MetricDataType.SUMMARY) : undefined;

            let selNodeId = selectedNode?.getId();
            selNodeId = selNodeId ? selNodeId : "";
            let nodeDefObj = getNodeFromGraphDef(selNodeId, graphDef);
            if (nodeDefObj && objMetricMetaData) {
                let runbookContext = new RunbookContext(nodeDefObj, graphDef, objMetricMetaData, customProperties);
                const nodeContexts: Context[] = runbookContext.getNodeContexts();
                let nodeContext: Context | undefined =  nodeContexts?.length ? nodeContexts[nodeContexts.length - 1] : undefined;
                if (!nodeContext) {
                    nodeContext = runbookContext.getTriggerContext();
                }
                if (nodeContext) {
                    isTimeseries = nodeContext.isTimeseries === true;
                    parentDataType.current = isTimeseries ? MetricDataType.TIMESERIES : MetricDataType.SUMMARY;
                }
            
                let inputKeyOperators: Array<any> = [];
                let inputKeyOptions: Array<any> = [];
                let inputTriggerKeyOperators: Array<any> = [];
                let inputTriggerKeyOptions: Array<any> = [];
                let triggerEntityTypes: Array<{value: string, label: string}> = [];
                let analysisTypes: Array<{value: string, label: string}> = [];
                let inputTriggerMetricOperators: Array<any> = [];
                let inputTriggerMetricOptions: Array<any> = [];
                let triggerMetrics: Array<{value: string, label: string}> = [];
                let excludedTriggerMetrics: Array<{value: string, label: string}> = [];
                let inputTriggerGenericMetricOperators: Array<any> = [];
                let inputTriggerGenericMetricOptions: Array<any> = [];
                let inputMetricOperators: Array<operationItem> = [];
                let inputMetricOptions: Array<any> = [];
                let inputVariableOperators: Array<any> = [];
                let inputVariableOptions: Array<any> = [];
                let inputRowCountOptions: Array<any> = [];
                let inputRowCountOperators: Array<any> = [];
                let showAdvanced = true;

                const variables: VariableContextByScope = {
                    runtime: getVariables(RUNTIME_SCOPE, true),
                    incident: props.variant === Variant.SUBFLOW || props.variant === Variant.ON_DEMAND
                        ? {primitiveVariables: [], structuredVariables: []} 
                        : getVariables(INCIDENT_SCOPE, true),
                    global: getVariables(GLOBAL_SCOPE, true)
                };
                let dataCols = runbookContext.getApplicableKeysMetrics(variables, customProperties);

                // Keys lhs and operator options
                inputKeyOptions = dataCols?.keys?.map((key: any) => {
                    return {
                        display: key.label,
                        value: ( dataCols?.properties?.isHttp ? HTTP_PREFIX : KEY_PREFIX ) + key.id,
                        raw: key,
                    }
                }) || [];
                inputKeyOperators = getKeyOperators();
                if (dataCols?.properties?.isHttp) {
                    showAdvanced = false;
                }

                // Trigger keys lhs and operator options
                inputTriggerKeyOptions = dataCols?.triggerKeys?.map((key: any) => {
                    return {
                        display: key.label,
                        value: TRIGGER_PREFIX + key.id,
                        raw: key,
                    }
                }) || [];
                inputTriggerKeyOperators = getKeyOperators();
                //inputTriggerKeyOperators = inputTriggerKeyOperators.filter(key => {
                //    return dataCols?.properties?.hasTriggerMetric ? key?.raw?.triggerMetricReqd : !key?.raw?.triggerMetricReqd ;
                //});
                triggerEntityTypes = dataCols?.properties?.triggerEntityTypes || [];
                analysisTypes = dataCols?.properties?.analysisTypes || [];

                // Trigger Metric keys lhs and operator options
                inputTriggerMetricOptions = dataCols?.triggerMetrics?.map((metric: any) => {
                    return {
                        display: metric.label,
                        value: TRIGGER_METRIC_PREFIX + metric.id,
                        raw: metric,
                    }
                }) || [];
                inputTriggerMetricOperators = getMetricOperations({ hasComparisonData: false });
                //inputTriggerMetricOperators = inputTriggerMetricOperators.filter(key => {
                //    return dataCols?.properties?.hasTriggerMetric ? key?.raw?.triggerMetricReqd : !key?.raw?.triggerMetricReqd ;
                //});
                triggerMetrics = dataCols?.properties?.triggerMetrics || [];
                excludedTriggerMetrics = dataCols?.properties?.excludedTriggerMetrics || [];

                inputTriggerGenericMetricOptions = dataCols?.triggerGenericMetrics?.map((metric: any) => {
                    return {
                        display: metric.label,
                        value: TRIGGER_GENERIC_METRIC_PREFIX + metric.id,
                        raw: metric,
                    }
                }) || [];
                inputTriggerGenericMetricOperators = getTriggerMetricOperators();
                //inputTriggerGenericMetricOperators = inputTriggerGenericMetricOperators.filter(key => {
                //    return dataCols?.properties?.hasTriggerMetric ? key?.raw?.triggerMetricReqd : !key?.raw?.triggerMetricReqd ;
                //});

                // Metric lhs and operator options
                const isTimeSeriesData = parentDataType.current === MetricDataType.TIMESERIES;
                const metricAggregationOperations = isTimeSeriesData ? getMetricAggregationOperationList() : [];
                inputMetricOptions = dataCols?.metrics?.map((metric: any) => {
                    return {
                        display: metric.label,
                        value: METRIC_PREFIX + metric.id,
                        raw: metric,
                        aggregators: metricAggregationOperations,
                    }
                }) || [];
                const hasComparisonData = dataCols?.properties?.comparedTo ? true : false;
                inputMetricOperators = getMetricOperations({ hasComparisonData: hasComparisonData });
                if (hasComparisonData && dataCols?.properties?.comparedTo) {
                    const comparedToTimeFrame = dataCols?.properties?.comparedTo;
                    const comparisonLabel = COMPARISON_LABEL_MAP[comparedToTimeFrame] ? " (vs " + COMPARISON_LABEL_MAP[comparedToTimeFrame] + ")" : "";
                    inputMetricOperators = inputMetricOperators.map(metric => {
                        if (typeof metric !== "string" && metric.raw?.comparisonReqd) {
                            return {
                                ...metric,
                                display: metric.display,
                                hint: comparisonLabel,
                            };
                        } else {
                            return metric;
                        }
                    })
                }

                // Variables lhs and operator options
                inputVariableOptions = dataCols?.variables?.map((key: any) => {
                    return {
                        display: key.label,
                        value: VARIABLE_PREFIX + key.id,
                        raw: key,
                    }
                }) || [];
                // ************************************************************* Need to handle this better
                inputVariableOperators = getKeyOperators();
                
                // RowCount lhs and operator options
                inputRowCountOptions = dataCols?.properties?.isHttp /*|| dataCols?.properties?.isTrigger*/ ? [] : [
                    { display: STRINGS.runbookEditor.nodeEditor.conditionKey.countAll, value: "count" },
                    { display: STRINGS.runbookEditor.nodeEditor.conditionKey.countMatch, value: "count_matching" }
                ];

                inputRowCountOperators = getMetricOperations();
                let optionsConfig: OptionSetByType = {
                    inputKeys: {
                        lhsOptions: (!dataCols?.properties?.isHttp ? inputKeyOptions : []),
                        operators: (!dataCols?.properties?.isHttp ? inputKeyOperators : []),
                        customProperties,
                        dataSources: getArDataSources()
                    },
                    inputVariables: {
                        lhsOptions: inputVariableOptions,
                        operators: inputVariableOperators
                    },
                    inputMetrics: {
                        lhsOptions: inputMetricOptions,
                        operators: inputMetricOperators,
                        metrics: triggerMetrics
                    },
                    [dataCols?.properties?.isTrigger ? "indicatorRowCount" : "inputRowCount"] : {
                        lhsOptions: inputRowCountOptions,
                        operators: inputRowCountOperators
                    },
                    inputTriggerKeys: {
                        lhsOptions: inputTriggerKeyOptions,
                        operators: inputTriggerKeyOperators,
                        triggerMetrics: triggerMetrics,
                        excludedTriggerMetrics: excludedTriggerMetrics,
                        triggerEntityTypes: triggerEntityTypes,
                        analysisTypes: analysisTypes,
                        userProvidesKey: dataCols?.properties?.userProvidesKey === true
                    },
                    inputTriggerMetrics: {
                        lhsOptions: inputTriggerMetricOptions,
                        operators: inputTriggerMetricOperators,
                        triggerMetrics: triggerMetrics,
                        excludedTriggerMetrics: excludedTriggerMetrics,
                    },
                    inputTriggerGenericMetrics: {
                        lhsOptions: inputTriggerGenericMetricOptions,
                        operators: inputTriggerGenericMetricOperators,
                        triggerMetrics: triggerMetrics,
                        excludedTriggerMetrics: excludedTriggerMetrics,
                        triggerEntityTypes: triggerEntityTypes,
                        analysisTypes: analysisTypes
                    },
                    inputHttpKeys: {
                        lhsOptions: (dataCols?.properties?.isHttp ? inputKeyOptions : []),
                        operators: (dataCols?.properties?.isHttp ? inputKeyOperators : [])
                    }
                };

                setCurProperties({
                    outputs: outputCases,
                    inputType: parentDataNodeInputType,
                    dataType: parentDataType.current,
                    optionsConfig: optionsConfig,
                    showAdvanced: showAdvanced
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode, objMetricMetaData]);

    /** Finds the duplicate values of an array with string values and returns them in another array
     * @param arr - an array with string values
     * @returns an array with the values that are duplicate. */
    function findDuplicateValues(arr) {
        const duplicates: string[] = [];
        const seen = {};
        
        arr.forEach((value) => {
            if (seen[value] === 1) {
                duplicates.push(value);
            }
            seen[value] = (seen[value] || 0) + 1;
        });
        
        return duplicates;
    }

    useEffect(() => {   
        if (outputsExpressionList?.length) {
            const labels: string[] = [];
            for (const output of outputsExpressionList) {
                if (output?.label) {
                    labels.push(output.label);
                }
            }
            setDuplicateOutputLabels(findDuplicateValues(labels));
        }
    }, [outputsExpressionList]);

    /** Tanslates Compare Control express to the API expression format
     *  @param outputs - list of Controls(expression object)
     *  @returns  RunBookAPIExpressions. */
    function convertOutputCasesToAPIFormat(outputs: Array<any> | undefined) {
        return outputs;
    }

    /** Tanslates API expression format to Compare Control expression format
     *  @param outputs - Runbook api expression object
     *  @returns  Array of Control object(expression object). */
    function convertOutputCasesToControlFormat(outputs){
        return outputs || [];
    }

    /** Update the selected node porperties. This is invoked when done button is clicked
     *  on the node editor dialog.
     *  @param properties -  Properties in selected node that need to updated
     *  @param selectedNode - node being editied
     *  @param libraryNode - object that specifies all the editable properties for a given node. */
    function updateNode(properties: DecisionNodeProperties, selectedNode: UniversalNode | undefined, libraryNode: NodeLibraryNode | undefined) {
        properties.outputs = outputsExpressionList || [];

        if (parentDataType.current) {
            properties.dataType = parentDataType.current;
        } else {
            delete properties.dataType;
        }

        if (!selectedNode || !libraryNode || !properties) {
            console.warn("updateNode has invalid inputs. Node update failed");
            return;
        }
        libraryNode.properties?.forEach((prop) => {
            if (properties.hasOwnProperty(prop.name)) {
                if (prop.name === DECISION_NODE_EDIT_PROPS.OUTPUT_CASES) {
                    const exp= convertOutputCasesToAPIFormat(properties?.outputs);
                    selectedNode.setProperty(prop.name, exp);
                }
                else if (prop.name === DECISION_NODE_EDIT_PROPS.INPUT_TYPE) {
                    selectedNode.setProperty(prop.name, properties.inputType);
                }
                else if (prop.name === DECISION_NODE_EDIT_PROPS.DATA_TYPE) {
                    selectedNode.setProperty(prop.name, properties.dataType)
                }
            } else {
                selectedNode.removeProperty(prop.name);
            }

        });

        // We need to update the outputs count
        const wires: NodeWiresSetting | null | undefined = selectedNode.getWires();
        if (wires) {
            wires.outputsCount = properties.outputs.length || 0;
        }

        let nodeId = selectedNode?.getId();
        nodeId = nodeId ? nodeId : "";
        const node = getNodeFromGraphDef(nodeId, graphDef);
        if (node) {
            node.editedByUser = true;
        }
    }
    ref.current = {
        updateNode: () => {
            updateNode(curProperties, selectedNode, libraryNode);
        },
        validate: () => {
            const errorMessages = new Array<string>();
            //if (!curProperties.inputType || curProperties.inputType === BLANK_ID ) {
            //    errorMessages.push(STRINGS.runbookEditor.errors.logicNode.editorInputError);
            //}
            const expressions = outputsExpressionList;
            if (!expressions?.length) {
                // There are no expressions flag it as an error
                errorMessages.push(DecisionNodeUtil.errMsgs.expError);
            } else {
                // There are expressions, check the expression to make sure all required fields are set.
                DecisionNodeUtil.checkExpressionsForErrors(expressions, errorMessages);
            }
            return errorMessages;
        }
    };
    function handleOutputsListChange(event){
        try {
            const outputs = JSON.parse(event.currentTarget.value);
            setOutputsExpressionList(outputs);
        } catch (ex) {
            console.error(ex);
        }
    }

    function handleOutputDefinitionChanged (index: number, definition: OutputBlockDefinition) {
        if (outputsExpressionList) {
            if (outputsExpressionList[index] === undefined) {
                setOutputsExpressionList([
                    ...outputsExpressionList,
                    definition,
                ])
            } else {
                // BEFORE BUG 12602
                // Updates to the definition of this one expression need not change the
                // state and cause a re-render because it was initiated from within the
                // output block. Hence directly setting the value. This is not standard
                // process and so keep that in mind. Uncomment the below section instead
                // if you want the state update to trigger a re-render.
                // outputsExpressionList[index] = definition;

                // AFTER BUG 12602
                // A state update to trigger a re-render is needed in order to have the updated
                // list for the sortable component. Otherwise, the content of the latest added
                // block will be lost if sorting is used
                const updatedOutputsExpressionList:any[] = [];
                for (let i = 0; i < outputsExpressionList.length; i++) {
                    if (i === index) {
                        updatedOutputsExpressionList.push(definition);
                    } else {
                        updatedOutputsExpressionList.push(outputsExpressionList[i]);
                    }
                }
                setOutputsExpressionList(updatedOutputsExpressionList);
            }
        }
        triggerNativeOnChange();
    }

    const onRemoveOutputClicked = useCallback((outputId: string) => {
        const updatedOutputsList: any[] = [];
        if (outputsExpressionList) {
            for (const output of outputsExpressionList) {
                if (output.id !== outputId) {
                    updatedOutputsList.push(output);
                }
            }
        }
        setOutputsExpressionList(updatedOutputsList);
        triggerNativeOnChange();
    }, [outputsExpressionList]);

    const generateNewOutputId = useCallback(function () {
        // TBD: Add logic to make sure new ID is not a duplicate
        return "o" + (outputsExpressionList || []).length + generateRandomID();
    }, [outputsExpressionList]);

    const onDuplicateOutputClicked = useCallback((outputId: string) => {
        const updatedOutputsList: any[] = [];
        if (outputsExpressionList) {
            for (const output of outputsExpressionList) {
                updatedOutputsList.push(output);
                if (output.id === outputId) {
                    const outputClone = {
                        ...output,
                        id: generateNewOutputId(),
                    }
                    updatedOutputsList.push(outputClone);
                }
            }
        }
        setOutputsExpressionList(updatedOutputsList);
        triggerNativeOnChange();
    }, [outputsExpressionList, generateNewOutputId]);

    const onAddNewOutputClicked = useCallback(() => {
        const updatedOutputsList: any[] = [];
        const existingExpressionList = outputsExpressionList || [];
        const newOutputDefinition = {
            id: generateNewOutputId(),
            passedData: OutputPassedDataOptions.MATCHED,
            expression: {
                id: "b" + generateRandomID(),
                operation: ConditionBlockOrchestratorOperationType.AND,
                conditions: [],
            },
        }
        if (existingExpressionList.length === 0) {
            updatedOutputsList.push(newOutputDefinition);
        } else {
            for (let i = 0; i < existingExpressionList.length; i++) {
                const output = existingExpressionList[i];
                if (output.id === DEFAULT_OUTPUT_ID) {
                    updatedOutputsList.push(newOutputDefinition);
                    updatedOutputsList.push(output);
                } else if (i === existingExpressionList.length - 1) {
                    updatedOutputsList.push(output);
                    updatedOutputsList.push(newOutputDefinition);
                } else {
                    updatedOutputsList.push(output);
                }
            }
        }
        setOutputsExpressionList(updatedOutputsList);
        triggerNativeOnChange();
    }, [outputsExpressionList, generateNewOutputId]);

    const onAddDefaultOutputClicked = useCallback(() => {
        const defaultExists = outputsExpressionList?.find(output => output.id === DEFAULT_OUTPUT_ID);
        if (!defaultExists) {
            setOutputsExpressionList([
                ...(outputsExpressionList || []),
                { id: DEFAULT_OUTPUT_ID }
            ]);
            triggerNativeOnChange();
        }
    }, [outputsExpressionList]);
        
    const hiddenValueInput = useRef<HTMLInputElement>(null);
    function triggerNativeOnChange () {
        if (hiddenValueInput.current) {
            setNativeValue(hiddenValueInput.current, new Date().getTime());
        }
    }

    // Wait for api call to complete before rendering the editor
    if (!objMetricMetaData) {
        return <tr><td colSpan={2}><DataLoadFacade loading/></td></tr>;
    }

    let contentsJsx;
    if (editMode) {
        contentsJsx = <textarea
            autoFocus
            ref={outputsExpressionElement}
            defaultValue={JSON.stringify(outputsExpressionList, undefined, 4)}
            style={{width: "100%", height: "600px", fontFamily: "monospace", fontSize: "small", borderColor: "#999"}}
            className="bg-white text-black"
            // onChange={handleOutputsListChange}
            onBlur={(e) => {
                handleOutputsListChange(e);
                setEditMode(false);
            }}
        />;
    } else {
        const defaultBlockExists = Boolean(outputsExpressionList?.find(output => output.id === DEFAULT_OUTPUT_ID));
        const blockClassList = "decision-node-output card h-min-1 p-2 mb-4";
        const blockHeadingClassList = "display-8 font-weight-600 text-uppercase border-bottom mb-2 pb-2 d-flex justify-content-between align-items-center";
        contentsJsx = <div className="decision-node-editor" ref={decisionNodeEditorContainer}>
            <div className="top-toolbar text-right mb-2">
                <Button text="Edit Raw Outputs JSON" className="edit-raw-json-btn" icon={IconNames.EDIT as IconName} onClick={() => setEditMode(true)} minimal/>
            </div>
            <input type="text" className="d-none" ref={hiddenValueInput} defaultValue={new Date().getTime()}/>
            <Sortable
                dragOnlyUsingHandles
                items={outputsExpressionList?.filter(output => output.id !== DEFAULT_OUTPUT_ID).map((output, index) => {
                    return {
                        record: output,
                        contents: <DecisionOutputBlock
                            titleIcon={<Icon className="drag-handle" icon={IconNames.DRAG_HANDLE_VERTICAL}/>}
                            outputBlockDefinition={output as OutputBlockDefinition}
                            index={index}
                            className={blockClassList}
                            headingClassName={blockHeadingClassList}
                            key={"out-" + output.id}
                            handleOutputDefinitionChanged={newDef => handleOutputDefinitionChanged(index, newDef)}
                            onRemoveOutputClicked={onRemoveOutputClicked}
                            onDuplicateOutputClicked={onDuplicateOutputClicked}
                            handleOutputLabelChange={(label) => { 
                                const updatedOutputsExpressionList = [...outputsExpressionList];
                                updatedOutputsExpressionList[index].label = label.trim();
                                setOutputsExpressionList(updatedOutputsExpressionList);
                                triggerNativeOnChange();
                            }}
                            metadata={{
                                metricsAndKeys: objMetricMetaData,
                                optionsConfig: curProperties.optionsConfig
                            }}
                            showAdvanced={curProperties.showAdvanced}
                            duplicateOutputLabels={duplicateOutputLabels}
                            saveAndCloseBtnDisable={props.saveAndCloseBtnDisable}
                        />,
                    };
                })}
                onChange={updatedExpressionList => {
                    setOutputsExpressionList([
                        ...updatedExpressionList.map(item => item.record),
                        ...outputsExpressionList?.filter(output => output.id === DEFAULT_OUTPUT_ID),
                    ]);
                    triggerNativeOnChange();
                }}
                onDragStart={() => decisionNodeEditorContainer.current?.classList.add("reorder-in-progress")}
                onDragEnd={() => decisionNodeEditorContainer.current?.classList.remove("reorder-in-progress")}
            />
            {
                <div key={"out-add-control"} className={blockClassList + " add-output-control"}>
                    <div className="d-flex p-4 justify-content-around">
                        <Button
                            minimal
                            id="add-output"
                            icon={IconNames.ADD as IconName}
                            text={STRINGS.runbookEditor.nodeEditor.addOutput}
                            disabled={outputsExpressionList.length - (defaultBlockExists ? 1 : 0) >= 10}
                            onClick={() => onAddNewOutputClicked()}
                        />
                        <Button
                            minimal
                            id="add-default-output"
                            icon={IconNames.ADD as IconName}
                            text={STRINGS.runbookEditor.nodeEditor.addDefaultOutput}
                            disabled={defaultBlockExists}
                            onClick={() => onAddDefaultOutputClicked()}
                        />
                    </div>
                </div>
            }
            {
                defaultBlockExists &&
                <div key={"out-" + DEFAULT_OUTPUT_ID} className={blockClassList + " default-output"}>
                    <div className={blockHeadingClassList}>
                        {STRINGS.runbookEditor.nodeEditor.defaultDecisionOutputName}
                        <Button
                            minimal
                            icon={IconNames.CROSS as IconName}
                            onClick={() => onRemoveOutputClicked(DEFAULT_OUTPUT_ID)}
                        />
                    </div>
                    <div className="text-center py-4">{STRINGS.runbookEditor.nodeEditor.defaultDecisionOutputText}</div>
                </div>
            }
        </div>
    }

    return (
        <>
            <tr>
                <td className="" colSpan={2}>
                    {contentsJsx}
                </td>
            </tr>
            {SHOW_CONTEXT && <RunbookContextSummary 
                currentProperties={JSON.parse(JSON.stringify({}))} 
                node={graphDef.nodes.find(node => node.id === selectedNode?.getId())!} graphDef={graphDef} 
                showOutputExample={true} showInputExample={true}
            />}
        </>
    )
});

/** this variable caches the list of key operators. */
let keyOperatorListCache:operationItem[];

/** returns the list of key operators.
 *  @returns an Array with the list of key operators. */
function getKeyOperators (): Array<operationItem> {
    if (!keyOperatorListCache) {
        const operations: operationItem[] = [];
        for (const opkey in KeyOperators) {
            const operation = KeyOperators[opkey];
            if (operation && operation.alt !== "EQ-ANY" && operation.alt !== "NOT-EQ-ANY") {
                operations.push({
                    value: operation.alt || operation.id,
                    display: operation.label,
                    valueNotReqd: operation.valueNotReqd,
                    raw: operation,
                });
            }
        }
        keyOperatorListCache = operations;
    }
    return keyOperatorListCache;
}

/** this variable caches the list of trigger metric operators. */
let triggerMetricOperatorListCache: operationItem[];

/** returns the list of trigger metric operators.
 *  @returns an Array with the list of trigger metric operators. */
function getTriggerMetricOperators(): Array<operationItem> {
    if (!triggerMetricOperatorListCache) {
        let operations: operationItem[] = [];
        for (const opkey in TriggerMetricOperators) {
            const operation = TriggerMetricOperators[opkey];
            if (operation) {
                operations.push({
                    value: operation.alt || operation.id,
                    display: operation.label,
                    valueNotReqd: operation.valueNotReqd,
                    raw: operation,
                });
            }
        }
        triggerMetricOperatorListCache = operations;
    }
    return triggerMetricOperatorListCache;
}

/** this variable caches the list of metric operators. */
let metricOperatorListCache:operationItem[];

/** returns the list of metric operators.
 *  @param hasComparisonData a boolean which is true if the previous node has comparison data.
 *  @returns an Array with the metric of key operators. */
function getMetricOperations ({ hasComparisonData } = { hasComparisonData: false }): Array<operationItem> {
    if (!metricOperatorListCache) {
        const operations: operationItem[] = [];
        const combinedOperatorsMap = {
            ...MetricOperators,
            ...MetricComparisonOperators,
            ...MetricBaselineOperators
        }
        for (const opkey in combinedOperatorsMap) {
            const operation = combinedOperatorsMap[opkey];
            if (operation) {
                operations.push({
                    value: operation.alt || operation.id,
                    display: operation.label,
                    valueNotReqd: operation.valueNotReqd,
                    raw: operation,
                });
            }
        }
        metricOperatorListCache = operations;
    }
    return hasComparisonData ? metricOperatorListCache : metricOperatorListCache.filter(operation => !(operation as any).raw.comparisonReqd);
}

/** this variable caches the list of metric aggregation operators. */
let metricAggregationOperationListCache:{
    [metric: string]: operationItem[]
} = {};

/** returns the list of metric aggregator operators.
 *  @param metric a string with the metric.
 *  @returns an Array with the metric of metric aggregation operators. */
function getMetricAggregationOperationList ({ metric } = { metric: "" }): Array<operationItem> {
    if (!metricAggregationOperationListCache[metric]) {
        const operations: operationItem[] = [];
        for (const opkey in FunctionOperators) {
            const operation = FunctionOperators[opkey];
            if (operation) {
                operations.push({
                    value: operation.alt || operation.id,
                    display: operation.label,
                    raw: operation,
                });
            }
        }
        metricAggregationOperationListCache[metric] = operations;
    }
    return metricAggregationOperationListCache[metric];
}
