import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { IconNames, LoadingOverlay } from '@tir-ui/react-components';
import { Button, Classes, Divider, Label, Switch } from '@blueprintjs/core';
import { Form, SelectField } from 'components/common/form';
import { RunbookNode } from 'utils/services/RunbookApiService';
import {
	IncidentTriggerOptions,
	LifecycleTriggerOptions,
} from './TriggerPanel';
import { TriggerTypes } from '../views/MappingConfigurationView';
import { TRIGGER_TYPE_MAP } from 'pages/riverbed-advisor/views/runbook-view/Runbook.type';
import { STRINGS } from 'app-strings';
import { PARAM_NAME } from 'components/enums/QueryParams';

export interface IRunbookPanelProps {
	triggerValue: IncidentTriggerOptions | string;
	runbooks: RunbookNode[] | undefined;
	executeRunbookValue: boolean;
	runbookValue: string;
	onRunbooksRefresh: (variant: string) => void;
	onRunbookValueChange: (event: React.FormEvent<HTMLInputElement>) => void;
	onExecuteRunbookValueChange: (
		event: React.FormEvent<HTMLInputElement>
	) => void;
}

const RunbookPanel: React.FC<IRunbookPanelProps> = (props) => {
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		setLoading(false);
	}, [props.runbooks]);

	const runbook =
		props.runbookValue &&
		props.runbooks?.filter((item) => {
			return item.name === props.runbookValue;
		});

	const description =
		runbook && runbook?.hasOwnProperty(0)
			? runbook['0']['description']
			: undefined;

	const variant = Object.values(LifecycleTriggerOptions).includes(
		// @ts-ignore
		props.triggerValue
	)
		? TriggerTypes.LIFECYCLE
		: TriggerTypes.INCIDENT;

	return (
		<div className={classNames(Classes.DIALOG_BODY)}>
			<p>
				<b>
					{STRINGS.MAPPING_CONFIGURATION_PAGE.addMappingModal.panels
						.runbook.subTitle + ':'}
				</b>
			</p>
			<Divider />
			<br />
			<Form
				initialValues={{
					execute_runbook: props.executeRunbookValue,
					runbook: props.runbookValue,
				}}
				loading={false}
			>
				<div className="mt-1 d-flex">
					<div className="w-50">
						<Switch
							name="execute_runbook"
							className="mt-1 mb-1"
							label={
								STRINGS.MAPPING_CONFIGURATION_PAGE
									.addMappingModal.panels.runbook.fields
									.executeRunbook.label
							}
							checked={props.executeRunbookValue}
							onChange={props.onExecuteRunbookValueChange}
						/>
					</div>
					<div>
						<SelectField
							className="w-100"
							name="runbook"
							required={true}
							options={
								props.runbooks && [
									STRINGS.MAPPING_CONFIGURATION_PAGE
										.addMappingModal.panels.runbook
										.selectRunbook,
									...Object.values(props.runbooks)
										.filter((runbook) => {
											return (
												runbook.isReady &&
												TRIGGER_TYPE_MAP[
													props.triggerValue
												] === runbook.triggerType
											);
										})
										.map((runbook) => {
											return runbook.name;
										}),
								]
							}
							disabled={!props.executeRunbookValue}
							onChange={props.onRunbookValueChange}
							value={props.runbookValue}
						/>
					</div>
					<div className="ml-2">
						<Button
							icon={IconNames.REFRESH}
							minimal={true}
							onClick={() => {
								setLoading(true);
								props.onRunbooksRefresh(variant);
							}}
						/>
					</div>
				</div>
				{loading && <LoadingOverlay visible={true} />}
				<div className="mt-2 mr-4 text-right">
					{' '}
					<p className="font-weight-bold">
						<a
							href={`create-runbook?${PARAM_NAME.variant}=${variant}&${PARAM_NAME.triggerType}=${TRIGGER_TYPE_MAP[props.triggerValue]}&fromWizard=true`}
							rel="noreferrer"
							target="_blank"
						>
							{
								STRINGS.MAPPING_CONFIGURATION_PAGE
									.addMappingModal.panels.runbook.fields
									.createRunbook.label
							}
						</a>
					</p>
				</div>
				{description && (
					<div>
						<div className="mt-2 font-weight-bold">
							<Label>
								{
									STRINGS.MAPPING_CONFIGURATION_PAGE
										.addMappingModal.panels.runbook.fields
										.runbookDescription.label
								}
							</Label>
						</div>
						<div className="mt-3">{description}</div>
					</div>
				)}
			</Form>
		</div>
	);
};

export { RunbookPanel };
