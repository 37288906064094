import React, { useRef } from 'react';
import { Icon, IconNames, IconName } from "@tir-ui/react-components";
import { Button } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { SIZE, PANEL_SIZE } from 'components/enums';
import './DetailsPanel.scss';

export function DetailsPanel (props: {
    children: any,
    floating?: boolean,
    className?: string,
    title?: string,
    icon?: IconName,
    animate?: boolean,
    size?: SIZE.s | SIZE.m | SIZE.l | SIZE.xl,
    anchorElement?: string,
    resizableElement?: string,
    onCloseClicked?: () => void,
    notResizable?: boolean,
    visible?: boolean,
    persistSize?: boolean
}) {
    const el = useRef<Element>();
    if (!el.current) {
        const elem = document.createElement("div");
        elem.className = "w-4 d-flex flex-column" + (props.className? (" " + props.className) : "");
        el.current = elem;
    }

    useEffect(
        () => {
            if (el.current) {
                if (props.visible !== undefined) {
                    const targetElement = props.anchorElement || "right-details-pane";
                    let mount = document.getElementById(targetElement);
                    let resizable = document.getElementsByClassName(props?.resizableElement || 'resizable-details-panel')[0] as HTMLElement;
                    if (props.visible) {
                        mount?.classList.add("show");
                        resizable?.classList.remove("d-none");
                    } else {
                        mount?.classList.remove("show");
                        resizable?.classList.add("d-none");
                    }
                }
            }
        }, 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.visible]
    );

    useEffect(() => {
        const targetElement = props.anchorElement || "right-details-pane";
        let mount = document.getElementById(targetElement);
        let resizable = document.getElementsByClassName(props?.resizableElement || 'resizable-details-panel')[0] as HTMLElement;
        if (!mount) {
            mount = document.createElement("div");
            mount.id = props.anchorElement || "right-details-pane";
            document.body.appendChild(mount);
        }
        if (mount) {
            mount.textContent = "";
            if (el.current) {
                mount.appendChild(el.current);
            }
        }
        if (props.floating) {
            mount?.classList.add("floating");
            mount?.classList.add("show");
            resizable?.classList.remove("d-none");
            mount?.classList.remove("d-none");
        } else {
            if (props.animate) {
                mount?.classList.remove("d-none");
                setTimeout(() => mount?.classList.add("show"), 5);
                setTimeout(() => resizable?.classList.remove("d-none"), 5);
                setTimeout(() => window.dispatchEvent(new Event("resize")), 350);
            } else {
                mount?.classList.add("show");
                resizable?.classList.remove("d-none");
                mount?.classList.remove("d-none");
                window.dispatchEvent(new Event("resize"));
            }
        }
        if (props.size === SIZE.xl) {
            mount?.classList.add("x-large");
            mount?.classList.remove("large", "medium", "small");
            if (resizable && !props.persistSize) {
                resizable.style.width = PANEL_SIZE.xLarge;
            }
        } else if (props.size === SIZE.l) {
            mount?.classList.add("large");
            mount?.classList.remove("x-large", "medium", "small");
            if (resizable && !props.persistSize) {
                resizable.style.width = PANEL_SIZE.large;
            }
        } else if (props.size === SIZE.s) {
            mount?.classList.add("small");
            mount?.classList.remove("x-large", "large", "medium");
            if (resizable && !props.persistSize) {
                resizable.style.width = PANEL_SIZE.small;
            }
        } else {
            mount?.classList.add("medium");
            mount?.classList.remove("large", "x-large", "small");
            if (resizable && !props.persistSize) {
                resizable.style.width = PANEL_SIZE.medium;
            }
        }
        if (props.notResizable) {
            resizable?.classList.add("d-none");
        }
        return () => {
            if (props.animate) {
                mount?.classList.remove("show");
                resizable?.classList.add("d-none");
            } else {
                if (mount) {
                    mount.classList.add("d-none");
                    mount.classList.remove("show");
                    mount.textContent = "";
                    if (resizable) {
                        resizable.classList.add("d-none");
                    }
                }
                window.dispatchEvent(new Event("resize"));
            }
            if (props.floating) {
                mount?.classList.remove("floating");
                resizable?.classList.add("d-none");
            } else if (props.animate) {
                setTimeout(() => {
                    window.dispatchEvent(new Event("resize"));
                    if (mount && mount.childElementCount === 0) {
                        mount.classList.add("d-none");
                    }
                }, 350);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [el.current]);

    const [panelVisible, setPanelVisible] = useState(true);

    return createPortal((props.title || props.icon? 
        <>{
            panelVisible && 
            <><div className="border-bottom p-2 shadow-sm">
                <Icon icon={props.icon || IconNames.INFO_SIGN} iconSize={18} className="mr-1 text-success"/>
                <span className="font-size-large">{props.title || "Details"}</span>
                <Button icon={IconNames.CROSS} className="float-right pt-0" onClick={()=> {
                    setPanelVisible(false);
                    if (props.onCloseClicked) {
                        props.onCloseClicked();
                    }
                }} minimal/>
            </div>
            <div className="p-1 overflow-auto">
                {props.children}
            </div></>
        }
        </> : props.children), el.current);
};
