import React, { useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighChartsTimeline from 'highcharts/modules/timeline';
import { STRINGS } from 'app-strings';
import { scaleMetric } from 'reporting-infrastructure/utils/formatters';
import { Unit } from 'reporting-infrastructure/types/Unit.class';
import { getUnitForMetric } from 'utils/stores/GlobalUnitsStore';
import { isBaselineIndicatorType } from 'components/enums';

NoDataToDisplay(Highcharts);
HighChartsTimeline(Highcharts);

function TimelineChart(props) {
    const chartRef = useRef<HighchartsReact.RefObject>(null);

    const { pick, defined, fireEvent } = Highcharts;
    /** Extends legend.getAllItems method to show legend items per series */
    Highcharts.wrap(Highcharts.Legend.prototype, 'getAllItems', function (this: any) {
        let allItems = [];
        this.chart.series.forEach(series => {
            const seriesOptions = series?.options;
            // Handle showInLegend. If the series is linked to another series, defaults to false.
            if (series && pick(seriesOptions.showInLegend, !defined(seriesOptions.linkedTo))) {
                // Use points or series for the legend item depending on legendType
                allItems = allItems.concat(series.legendItem?.labels || series);
            }
        });
        fireEvent(this, 'afterGetAllItems', { allItems });
        return allItems;
    });

    /** Custom svg marker (cluster of blue dots) */
    Highcharts.SVGRenderer.prototype.symbols.cluster = function (x, y) {
        return [
            'M', x + 13.43, y + 15.85, 'c', -1.37, 0.91, -3.02, 1.45, -4.79, 1.45,
            'C', x + 3.88, y + 17.3, x, y + 13.42, x, y + 8.65, 'C', x, y + 3.88, x + 3.88, y, x + 8.65, y,
            'c', 1.77, 0, 3.41, 0.54, 4.79, 1.45, 'c', -1.64, 1.94, -2.64, 4.45, -2.64, 7.2,
            'S', x + 11.79, y + 13.9, x + 13.43, y + 15.85, 'z',

            'M', x + 34.44, y, 'c', -4.77, 0, -8.65, 3.88, -8.65, 8.65,
            's', 3.88, 8.65, 8.65, 8.65, 's', 8.65, -3.88, 8.65, -8.65,
            'S', x + 39.21, y, x + 34.44, y, 'z',

            'M', x + 23.29, y + 8.65, 'c', 0, -2.9, 1.1, -5.53, 2.91, -7.51,
            'C', x + 24.95, y + 0.42, x + 23.49, y, x + 21.94, y,
            'c', -4.77, 0, -8.65, 3.88, -8.65, 8.65, 'c', 0, 4.77, 3.88, 8.65, 8.65, 8.65,
            'c', 1.55, 0, 3, -0.42, 4.26, -1.13, 'C', x + 24.4, y + 14.18, x + 23.29, y + 11.54, x + 23.29, y + 8.65, 'z'
        ];
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [options, setOptions] = useState({
        chart: {
            height: 300,
            zooming: {
                type: 'x'
            },
            panning: {
                enabled: true
            },
            panKey: 'shift',
            type: 'timeline',
            events: {
                load: function (this: any) {
                    const chart = this;
                    chart.series.forEach(series => {
                        series.points.forEach((point) => {
                            const div = point.dataLabel.div;
                            const element = point.dataLabel.element;
                            point.graphic.element.addEventListener('click', () => {
                                const isEnabled = point.dataLabel && point.dataLabel.element.style.display !== 'none';

                                if (isEnabled) {
                                    element.style.display = 'none';
                                    div.style.display = 'none';
                                } else {
                                    div.style.display = 'block';
                                    element.style.display = 'block'
                                }
                            });

                            if (point.dataLabel && div) {
                                div.addEventListener('click', () => {
                                    props.dialog(point)
                                });
                            }
                        });
                    });
                }
            },
        },
        exporting: {
            enabled: false
        },
        time: {
            useUTC: false
        },
        xAxis: {
            type: 'datetime',
            visible: false,
            gridLineWidth: 0,
        },
        yAxis: {
            gridLineWidth: 5,
            gridLineColor: 'lightgrey',
            title: null,
            labels: {
                enabled: false
            }
        },
        title: {
            text: undefined
        },
        tooltip: {
            enabled: false,
            headerFormat: '',
            formatter: function (this: any) {
                if (!this.point.description) return false;
                return this.point.description + ' correlated indicators'
            }
        },
        credits: {
            enabled: false
        },
        // caption: {
        //     text: '<strong>Hint:</strong> Select a range of points to zoom <span>&#x1F50D;</span>. Then press "shift" and drag mouse to pan <span>&#10021;</span>'
        // },
        plotOptions: {
            timeline: {
                showInLegend: true,
                symbolRadius: 0,
                legendSymbol: 'lineMarker',
            },
            series: {
                states: {
                    inactive: {
                        opacity: 1
                    }
                },
                lineWidth: 0,
                dataLabels: {
                    enabled: true,
                    useHTML: true,
                    allowOverlap: false,
                    connectorWidth: 2,
                    connectorColor: 'silver',
                    zIndex: 2,
                    formatter: function (this: any) {
                        const item = this.point.custom;
                        const unit = getUnitForMetric(item.metric) || "";
                        const threshold = item.details?.acceptableLowValue || item.details?.acceptableHighValue || 0;
                        const isBaseline = isBaselineIndicatorType(item.kind);

                        return `<span style="font-weight: bold">${this.point.name}</span></br><span style="color: grey">${this.point.label}</span>

                            ${(item.metric && (!item.cluster || item.cluster === 1))
                                ? `</br><span class="metric-timeline" style="font-weight: bold">${STRINGS.METRICS[item.metric]}</span>`
                                : ''}

                            ${['primaryIndicator', 'correlatedIndicator'].includes(item?.eventType) && (!item.cluster || item.cluster === 1)
                                ? `<span>${STRINGS.incidents.columns.actual}: 
                                    ${scaleMetric(item.details.actualValue, Unit.parseUnit(unit || "")).formatted}</span>
                                ${!isBaseline
                                    ? `</br><span>${STRINGS.incidents.timeseriesChart.threshold}: 
                                        ${scaleMetric(threshold, Unit.parseUnit(unit || "")).formatted}</span>`
                                    : `</br><span>${STRINGS.incidents.timeseriesChart.expectedRange}: 
                                        ${scaleMetric((item.details?.acceptableLowValue || 0), Unit.parseUnit(unit || "")).formatted} - ${scaleMetric(item.details?.acceptableHighValue, Unit.parseUnit(unit || "")).formatted}</span>`}`
                                : ''}

                            ${item?.eventType === 'correlatedIndicator' && item.cluster > 1
                                ? '</br><span class="cta-timeline" style="color: #007BFF; text-decoration: underline">View List</span>'
                                : (item.eventType
                                    ? '</br><span class="cta-timeline" style="color: #007BFF; text-decoration: underline">View Automation</span>'
                                    : '')
                            }`;

                    }
                },
            }
        },
        series: props.dataSeries
    })

    return (
        <div className='timeline-chart'>
            <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
        </div>

    )
}

export default TimelineChart;

