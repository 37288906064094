/** This module creates a new edge for the react-flow graph that represents a NetIM edge.
 *  @module
 */
import React from "react";
import { EdgeProps, EdgeText, getBezierPath, getEdgeCenter } from "react-flow-renderer";

export default function NetIMEdge({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style, data }: EdgeProps) {
    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition
    });

    const [centerX, centerY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY
    })

    return (
        <>
            <path
                id={id}
                className="react-flow__edge-path"
                d={edgePath}
                style={style}
            />
            {/* TODO: Work on adding more edge functions */}
            <EdgeText
                x={centerX}
                y={centerY}
                label={data.label}
                labelBgPadding={[2, 4]}
                labelBgBorderRadius={2}
            />
        </>
    );
}
