import React from 'react';
import classNames from 'classnames';
import { Classes, Divider } from '@blueprintjs/core';
import { Form, InputField, SelectField, TextAreaField } from 'components/common/form';
import { STRINGS } from 'app-strings';
import { FormikProps } from 'formik';

export interface ITestQueryPanelProps {
	testQuery?: any;
    onChangeUrl: (event) => void;
    onChangeVerb: (event) => void;
    onChangeHeaders: (event) => void;
    onChangePayload: (event) => void;
}

const TestQueryPanel: React.FC<ITestQueryPanelProps> = (props) => {
    return (
		<div className={classNames(Classes.DIALOG_BODY)}>
			<p>
				<b>
					{
						STRINGS.thirdPartyIntegrations.addAuthProfile.panels
                            .testQuery.title
					}
				</b>
			</p>
			<Divider />
			<br />
			<Form
				initialValues={
                    {
                        request_url: props.testQuery.verifyUrl,
                        request_verb: props.testQuery.verifyHttpMethod,
                        http_headers: props.testQuery.verifyRequestHeaders,
                        http_payload: props.testQuery.verifyRequestBody
                    }
                }
				loading={false}
			>
                    {(formProps: FormikProps<object>) => <>
                    <InputField
                        name="request_url"
                        type="text"
                        placeholder={
                            STRINGS.thirdPartyIntegrations.addAuthProfile.panels
                                .testQuery.fields.requestUrl.urlPlaceholder
                        }
                        fill={true}
                        value={props.testQuery.verifyUrl}
                        leftElement={
                            <SelectField
                                name="request_verb"
                                className="mr-2"
                                options={Object.values(STRINGS.thirdPartyIntegrations.addAuthProfile.panels
                                    .testQuery.fields.requestUrl.requestVerb)}
                                onChange={props.onChangeVerb}
                                value={props.testQuery.verifyHttpMethod}
                            />
                        }

                        onBlur={(event) => {
                            const value = event.target.value.trimEnd();
                            
                            props?.onChangeUrl && props.onChangeUrl({target: {
                                value: value
                            }});
                        }}
                        onChange={props.onChangeUrl}
                    />
                <TextAreaField
					name="http_headers"
                    rows={3}
					label={
						STRINGS.thirdPartyIntegrations.addAuthProfile.panels
							.testQuery.fields.httpHeaders.label
					}
                    placeholder={
                        STRINGS.thirdPartyIntegrations.addAuthProfile.panels
                            .testQuery.fields.httpHeaders.placeholder
                    }
                    onBlur={(event) => {
                        const value = event.target.value?.trim();

                        formProps.handleBlur(event);
                        props?.onChangeHeaders && props.onChangeHeaders({target: {
                            value: value
                        }});
                    }}
                    onChange={props.onChangeHeaders}
                    value={
                        props.testQuery.verifyRequestHeaders
                    }
				/>
                <TextAreaField
					name="http_payload"
                    disabled={props.testQuery?.verifyHttpMethod === 'GET'}
                    rows={8}
					label={
						STRINGS.thirdPartyIntegrations.addAuthProfile.panels
							.testQuery.fields.httpPayload.label
					}
                    placeholder={
                        STRINGS.thirdPartyIntegrations.addAuthProfile.panels
                            .testQuery.fields.httpPayload.placeholder
                    }
                    onBlur={(event) => {
                        const value = event.target.value?.trim();

                        formProps.handleBlur(event);
                        props?.onChangePayload && props.onChangePayload({target: {
                            value: value
                        }});
                    }}
                    onChange={props.onChangePayload}
                    value={props.testQuery.verifyRequestBody}
				/>
                </>}
			</Form>
		</div>
	);
};

export { TestQueryPanel };
