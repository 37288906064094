/** This module contains a view for displaying the decision branch for a mapping configuration.
 *  @module
 */
import React, { useCallback, useState, useEffect, useRef } from "react";
import { InputType, Variant } from "components/common/graph/types/GraphTypes";
import { ConditionTree } from "components/common/condition-tree-builder/ConditionTreeBuilder";
import { operationItem } from "components/common/condition-tree-builder/condition/ConditionUtils";
import { STRINGS } from "app-strings";
import { 
    FunctionOperators, KeyOperators, MetricBaselineOperators, TriggerMetricOperators, MetricComparisonOperators, MetricOperators 
} from "components/common/graph/editors/LogicOperators";
import { DataOceanMetadata } from "components/common/graph/editors/data-ocean/DataOceanMetadata.type";
import { DataOceanUtils } from "components/common/graph/editors/data-ocean/DataOceanUtils";
import { useStateSafePromise } from "utils/hooks";
import { DataColumns, DecisionBranchColumn, RunbookContext, VariableContextByScope } from "utils/runbooks/RunbookContext.class";
import { GenericKey } from "utils/runbooks/NodeUtil";
import { INDICATOR_TO_LABEL_MAP, INDICATOR_TYPE } from "components/enums";
import { getTriggerMetricIds } from "utils/runbooks/RunbookUtils";
import { getArDataSources, getTypes } from "utils/stores/GlobalDataSourceTypeStore";
import { COMPARISON_LABEL_MAP } from "components/common/graph/editors/data-ocean/DataOceanComparison";
import { 
    HTTP_PREFIX, KEY_PREFIX, METRIC_PREFIX, OptionSetByType, 
    TRIGGER_GENERIC_METRIC_PREFIX, TRIGGER_METRIC_PREFIX, TRIGGER_PREFIX, VARIABLE_PREFIX 
} from "components/hyperion/views/decision-branch/DecisionBranchUtils";
import { DecisionBranchView } from "components/hyperion/views/decision-branch/DecisionBranchView";
import { Operations, Ops } from "components/common/graph/editors/decision/DecisionNodeUtil";
import { ConditionBlockType } from "components/common/condition-tree-builder/condition-block/ConditionBlock";
import { useVariables } from 'utils/hooks/useVariables';
import { GLOBAL_SCOPE, INCIDENT_SCOPE, RUNTIME_SCOPE } from "utils/runbooks/VariablesUtils";
import { CustomProperty } from "pages/create-runbook/views/create-runbook/CustomPropertyTypes";
import { useCustomProperties } from "utils/hooks/useCustomProperties";

/** this interface defines the properties passed into the mapping configuration decision branch React component. */
export interface MappingDecisionBranchProps {
    triggerType: InputType;
    expression: any;
    onExpressionChanged?: (expression: any) => void;
    readonly?: boolean;
    className?: string;
}

/** Renders the mapping configuration decision branch view.
 *  @param props the properties passed in.
 *  @returns JSX with the mapping configuration decision branch view component.*/
export const MappingDecisionBranchView = (props: MappingDecisionBranchProps): JSX.Element =>{
    // Meta data about the object types, list of metrics and keys.
    const [executeSafely] = useStateSafePromise();
    const [metadata, setMetadata] = useState<{
        metricsAndKeys?: any,
        optionsConfig?: OptionSetByType
    }>({});

    const useVariablesReturn = useVariables({
        runbookInfo: {
            id: "",
            label: "",
            triggerType: props.triggerType
        },
        variant: Variant.INCIDENT
    });
    const getVariables = useVariablesReturn[0];
    const incidentVariables = useVariablesReturn[3];

    const initialized = useRef<boolean>(false);

    const [customProperties, setCustomProperties] = useState<CustomProperty[] | undefined>(undefined);
    const customPropertiesQuery = useCustomProperties({});
    useEffect(
        () => {
            setCustomProperties(customPropertiesQuery.data || []);
        },
        [customPropertiesQuery.data]
    );

    const fetchData = useCallback(
        () => {
            return executeSafely(DataOceanUtils.init()).then((response: any) => {
                //setObjMetricMetaData(response);
                initialized.current = customProperties !== undefined;
                if (initialized.current) {
                    const variables: VariableContextByScope = {
                        runtime: getVariables(RUNTIME_SCOPE, false),
                        incident: getVariables(INCIDENT_SCOPE, true),
                        global: getVariables(GLOBAL_SCOPE, true)
                    };
                    setMetadata(getMetadata(props.triggerType, response, customProperties || [], variables));            
                }
            }, error => {
                console.error(error);
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [executeSafely, props.triggerType, incidentVariables, customProperties]
    );

    useEffect(() => {
        // Fetch Meta data on load.
        if (customProperties) {
            fetchData();
        }
    }, [fetchData, customProperties]);

    function onExpressionChanged (newExpression: ConditionTree) {
        props.onExpressionChanged && props.onExpressionChanged(newExpression);

        /*
        expressionTreeRef.current = expression;
        if (handleOutputDefinitionChanged) {
            handleOutputDefinitionChanged({
                ...outputBlockDefinition,
                expression: convertTreeBuilderFormatToOrchestratorFormat(expression),
            });
        }
        */
    }

    return <>
        {initialized.current && <DecisionBranchView
            metadata={metadata}
            expression={props.expression}
            onExpressionChanged={onExpressionChanged}
            readonly={props.readonly}
            className={props.className}
        />}
    </>;
};

/** returns the metadata that is used to configure the decision branch.
 *  @param triggerType the InputType
 *  @param doMetadata the data ocean metadata.
 *  @param variables the VariableContextByScope with all the variables.
 *  @param customProperties the array of CustomProperty objects with the list of custom properties for all entities.
 *  @returns the metadata. */
function getMetadata(triggerType: InputType, doMetadata: DataOceanMetadata, customProperties: CustomProperty[], variables: VariableContextByScope): any {
    const columns: DataColumns = {};

    let triggerMetricIds: Array<string> = getTriggerMetricIds(triggerType, [], doMetadata, getTypes());
    const triggerMetricDefs = triggerMetricIds.map(metricId => {
        const metricDef = doMetadata.metrics[metricId];
        return {value: metricDef.id, label: metricDef.label};
    });

    let excludedTriggerMetricIds: Array<string> = getTriggerMetricIds(triggerType, [], doMetadata, getTypes(), false);
    const excludedTriggerMetricDefs = excludedTriggerMetricIds.map(metricId => {
        const metricDef = doMetadata.metrics[metricId];
        return {value: metricDef.id, label: metricDef.label};
    });

    let triggerKeyColumns: Array<DecisionBranchColumn | GenericKey> = RunbookContext.getExpandedKeysForTrigger(triggerType, customProperties, "", true);
    triggerKeyColumns = triggerKeyColumns.map((item: GenericKey) => {
        return {
            id: item.id,
            label: (item.id === "data_source.id" && item.label.includes(" (ID)")
                ? item.label.substring(0, item.label.indexOf(" (ID)")) 
                : item.label
            ),
            type: item.type,
            unit: item.unit,
            raw: item
        }
    }).filter((key) => {
        // Don't show the data_source name and hostname fields
        return key.id !== "data_source.name" && key.id !== "data_source.hostname"
    });
    columns.triggerKeys = triggerKeyColumns;
    let triggerEntityTypes: Array<{value: string, label: string}> = [];
    switch (triggerType) {
        case InputType.DEVICE:
            triggerEntityTypes = [{value: "network_device", label: STRINGS.incidents.entityKinds.network_device}];
            break;
        case InputType.INTERFACE:
            triggerEntityTypes = [{value: "network_interface", label: STRINGS.incidents.entityKinds.network_interface}];
            break;
        case InputType.APPLICATION:
            triggerEntityTypes = [
                {value: "application_location", label: STRINGS.incidents.entityKinds.application_location}, 
                //{value: "application_server", label: STRINGS.incidents.entityKinds.application_server}
            ];
            break;
        case InputType.LOCATION:
            triggerEntityTypes = [
                {value: "location", label: STRINGS.incidents.entityKinds.location}
            ];
            break;
        case InputType.WEBHOOK:
            triggerEntityTypes = [];
            break;
    }
    let analysisTypes: Array<{value: string, label: string}> = [];
    for (const type of Object.values(INDICATOR_TYPE)) {
        analysisTypes.push({value: type.toLocaleLowerCase(), label: INDICATOR_TO_LABEL_MAP[type]})
    }
    columns.triggerMetrics = triggerMetricIds.map((metricId): DecisionBranchColumn => {
        const metric = {...doMetadata.metrics[metricId]};
        metric.label += " *";
        return {
            id: metric.id,
            label: metric.label,
            type: metric.type,
            unit: metric.unit,
            raw: {...metric},
        }
    });

    columns.triggerGenericMetrics = [
            InputType.WEBHOOK, InputType.IMPACT_ANALYSIS_READY,
            InputType.INCIDENT_INDICATORS_UPDATED, InputType.INCIDENT_NOTE_ADDED,
            InputType.INCIDENT_NOTE_UPDATED, InputType.INCIDENT_NOTE_DELETED,
            InputType.INCIDENT_ONGOING_CHANGED, InputType.INCIDENT_STATUS_CHANGED
    ].includes(triggerType) ? [] : [{
        id: "$PRIMARY_INDICATOR.KEY.metric",
        label: "Triggering Metric",
        type: "string",
        unit: "none",
        raw: {
            id: "$PRIMARY_INDICATOR.KEY.metric",
            label: "Triggering Metric",
            type: "string",
            unit: "none"   
        },
    }, {
        id: "$ENTITY.KEY.kind",
        label: "Entity Type",
        type: "string",
        unit: "none",
        raw: {
            id: "$ENTITY.KEY.kind",
            label: "Entity Type",
            type: "string",
            unit: "none"   
        },
    /* Let's wait on exposing this    
    }, {
        id: "$PRIMARY_INDICATOR.KEY.kind",
        label: "Anomaly Type",
        type: "string",
        unit: "none",
        raw: {
            id: "PRIMARY_INDICATOR.KEY.kind",
            label: "Anomaly Type",
            type: "string",
            unit: "none"   
        },
    */
    }];
    columns.properties = { 
        comparedTo: undefined, 
        isTrigger: true,
// This needs to be re-enabled but the filter operation on the editor needs to be fixed                
        //hasTriggerMetric: true,
        triggerMetrics: triggerMetricDefs,
        excludedTriggerMetrics: excludedTriggerMetricDefs,
        triggerEntityTypes: triggerEntityTypes,
        analysisTypes: analysisTypes,
    };

    let inputKeyOperators: Array<any> = [];
    let inputKeyOptions: Array<any> = [];
    let inputTriggerKeyOperators: Array<any> = [];
    let inputTriggerKeyOptions: Array<any> = [];
    // This is defined in two places
    //let triggerEntityTypes: Array<{value: string, label: string}> = [];
    //let analysisTypes: Array<{value: string, label: string}> = [];
    let inputTriggerMetricOperators: Array<any> = [];
    let inputTriggerMetricOptions: Array<any> = [];
    let triggerMetrics: Array<{value: string, label: string}> = [];
    let excludedTriggerMetrics: Array<{value: string, label: string}> = [];
    let inputTriggerGenericMetricOperators: Array<any> = [];
    let inputTriggerGenericMetricOptions: Array<any> = [];
    let inputMetricOperators: Array<operationItem> = [];
    let inputMetricOptions: Array<any> = [];
    let inputVariableOperators: Array<any> = [];
    let inputVariableOptions: Array<any> = [];
    let inputRowCountOptions: Array<any> = [];
    let inputRowCountOperators: Array<any> = [];
    //let showAdvanced = true;

    /*
    let dataCols = context.getApplicableKeysMetrics(variables);
    */

    if (
        [
            InputType.IMPACT_ANALYSIS_READY, InputType.INCIDENT_STATUS_CHANGED, InputType.INCIDENT_INDICATORS_UPDATED, 
            InputType.INCIDENT_ONGOING_CHANGED, InputType.INCIDENT_NOTE_ADDED, InputType.INCIDENT_NOTE_UPDATED,
            InputType.INCIDENT_NOTE_DELETED
        ].includes(triggerType)
    ) {
        columns.variables = [];
        for (const key in variables) {
            const varCollection = variables[key];
            if (varCollection.primitiveVariables) {
                for (const variable of varCollection.primitiveVariables) {
                    columns.variables.push(
                        {
                            id: variable.name, label: variable.name, type: variable.type, 
                            unit: variable.unit ? variable.unit.toString() : "", 
                            raw: {...variable, unit: variable.unit ? variable.unit.toString() : ""}
                        }
                    );
                }
            }
        }    
    }

    // Keys lhs and operator options
    inputKeyOptions = columns?.keys?.map((key: any) => {
        return {
            display: key.label,
            value: ( columns?.properties?.isHttp ? HTTP_PREFIX : KEY_PREFIX ) + key.id,
            raw: key,
        }
    }) || [];
    inputKeyOperators = getKeyOperators();
    //if (columns?.properties?.isHttp) {
    //    showAdvanced = false;
    //}

    // Trigger keys lhs and operator options
    inputTriggerKeyOptions = columns?.triggerKeys?.map((key: any) => {
        return {
            display: key.label,
            value: TRIGGER_PREFIX + key.id,
            raw: key,
        }
    }) || [];
    inputTriggerKeyOperators = getKeyOperators();
    //inputTriggerKeyOperators = inputTriggerKeyOperators.filter(key => {
    //    return dataCols?.properties?.hasTriggerMetric ? key?.raw?.triggerMetricReqd : !key?.raw?.triggerMetricReqd ;
    //});
    triggerEntityTypes = columns?.properties?.triggerEntityTypes || [];
    analysisTypes = columns?.properties?.analysisTypes || [];

    // Trigger Metric keys lhs and operator options
    inputTriggerMetricOptions = columns?.triggerMetrics?.map((metric: any) => {
        return {
            display: metric.label,
            value: TRIGGER_METRIC_PREFIX + metric.id,
            raw: metric,
        }
    }) || [];
    inputTriggerMetricOperators = getMetricOperations({ hasComparisonData: false });
    //inputTriggerMetricOperators = inputTriggerMetricOperators.filter(key => {
    //    return dataCols?.properties?.hasTriggerMetric ? key?.raw?.triggerMetricReqd : !key?.raw?.triggerMetricReqd ;
    //});
    triggerMetrics = columns?.properties?.triggerMetrics || [];
    excludedTriggerMetrics = columns?.properties?.excludedTriggerMetrics || [];

    inputTriggerGenericMetricOptions = columns?.triggerGenericMetrics?.map((metric: any) => {
        return {
            display: metric.label,
            value: TRIGGER_GENERIC_METRIC_PREFIX + metric.id,
            raw: metric,
        }
    }) || [];
    inputTriggerGenericMetricOperators = getTriggerMetricOperators();
    //inputTriggerGenericMetricOperators = inputTriggerGenericMetricOperators.filter(key => {
    //    return dataCols?.properties?.hasTriggerMetric ? key?.raw?.triggerMetricReqd : !key?.raw?.triggerMetricReqd ;
    //});

    // Metric lhs and operator options
    //const isTimeSeriesData = parentDataType === MetricDataType.TIMESERIES;
    const isTimeSeriesData = false;
    const metricAggregationOperations = isTimeSeriesData ? getMetricAggregationOperationList() : [];
    inputMetricOptions = columns?.metrics?.map((metric: any) => {
        return {
            display: metric.label,
            value: METRIC_PREFIX + metric.id,
            raw: metric,
            aggregators: metricAggregationOperations,
        }
    }) || [];
    const hasComparisonData = columns?.properties?.comparedTo ? true : false;
    inputMetricOperators = getMetricOperations({ hasComparisonData: hasComparisonData });
    if (hasComparisonData && columns?.properties?.comparedTo) {
        const comparedToTimeFrame = columns?.properties?.comparedTo;
        const comparisonLabel = COMPARISON_LABEL_MAP[comparedToTimeFrame] ? " (vs " + COMPARISON_LABEL_MAP[comparedToTimeFrame] + ")" : "";
        inputMetricOperators = inputMetricOperators.map(metric => {
            if (typeof metric !== "string" && metric.raw?.comparisonReqd) {
                return {
                    ...metric,
                    display: metric.display,
                    hint: comparisonLabel,
                };
            } else {
                return metric;
            }
        })
    }

    // Variables lhs and operator options
    inputVariableOptions = columns?.variables?.map((key: any) => {
        return {
            display: key.label,
            value: VARIABLE_PREFIX + key.id,
            raw: key,
        }
    }) || [];
    // ************************************************************* Need to handle this better
    inputVariableOperators = getKeyOperators();
    
    // RowCount lhs and operator options
    inputRowCountOptions = columns?.properties?.isHttp || triggerType === InputType.WEBHOOK /*|| dataCols?.properties?.isTrigger*/ ? [] : [
        { display: STRINGS.runbookEditor.nodeEditor.conditionKey.countAll, value: "count" },
        { display: STRINGS.runbookEditor.nodeEditor.conditionKey.countMatch, value: "count_matching" }
    ];

    inputRowCountOperators = getMetricOperations();
    let optionsConfig: OptionSetByType = {
        inputKeys: {
            lhsOptions: (!columns?.properties?.isHttp ? inputKeyOptions : []),
            operators: (!columns?.properties?.isHttp ? inputKeyOperators : []),
            customProperties,
            dataSources: getArDataSources()
        },
        inputVariables: {
            lhsOptions: inputVariableOptions,
            operators: inputVariableOperators
        },
        inputMetrics: {
            lhsOptions: inputMetricOptions,
            operators: inputMetricOperators,
            metrics: triggerMetrics
        },
        [columns?.properties?.isTrigger ? "indicatorRowCount" : "inputRowCount"] : {
            lhsOptions: inputRowCountOptions,
            operators: inputRowCountOperators
        },
        inputTriggerKeys: {
            lhsOptions: inputTriggerKeyOptions,
            operators: inputTriggerKeyOperators,
            triggerMetrics: triggerMetrics,
            excludedTriggerMetrics: excludedTriggerMetrics,
            triggerEntityTypes: triggerEntityTypes,
            analysisTypes: analysisTypes,
            userProvidesKey: triggerType === InputType.WEBHOOK
        },
        inputTriggerMetrics: {
            lhsOptions: inputTriggerMetricOptions,
            operators: inputTriggerMetricOperators,
            triggerMetrics: triggerMetrics,
            excludedTriggerMetrics: excludedTriggerMetrics,
        },
        inputTriggerGenericMetrics: {
            lhsOptions: inputTriggerGenericMetricOptions,
            operators: inputTriggerGenericMetricOperators,
            triggerMetrics: triggerMetrics,
            excludedTriggerMetrics: excludedTriggerMetrics,
            triggerEntityTypes: triggerEntityTypes,
            analysisTypes: analysisTypes
        },
        inputHttpKeys: {
            lhsOptions: (columns?.properties?.isHttp ? inputKeyOptions : []),
            operators: (columns?.properties?.isHttp ? inputKeyOperators : [])
        }
    };

    const metadata = {
        metricsAndKeys: doMetadata,
        optionsConfig: optionsConfig
    };
    return metadata;
}

// Everything down here is copied and pasted and should be centralized

/** this variable caches the list of key operators. */
let keyOperatorListCache:operationItem[];

/** returns the list of key operators.
 *  @returns an Array with the list of key operators. */
function getKeyOperators (): Array<operationItem> {
    if (!keyOperatorListCache) {
        const operations: operationItem[] = [];
        for (const opkey in KeyOperators) {
            const operation = KeyOperators[opkey];
            if (operation) {
                operations.push({
                    value: operation.alt || operation.id,
                    display: operation.label,
                    valueNotReqd: operation.valueNotReqd,
                    raw: operation,
                });
            }
        }
        keyOperatorListCache = operations;
    }
    return keyOperatorListCache;
}

/** this variable caches the list of trigger metric operators. */
let triggerMetricOperatorListCache: operationItem[];

/** returns the list of trigger metric operators.
 *  @returns an Array with the list of trigger metric operators. */
function getTriggerMetricOperators(): Array<operationItem> {
    if (!triggerMetricOperatorListCache) {
        let operations: operationItem[] = [];
        for (const opkey in TriggerMetricOperators) {
            const operation = TriggerMetricOperators[opkey];
            if (operation) {
                operations.push({
                    value: operation.alt || operation.id,
                    display: operation.label,
                    valueNotReqd: operation.valueNotReqd,
                    raw: operation,
                });
            }
        }
        triggerMetricOperatorListCache = operations;
    }
    return triggerMetricOperatorListCache;
}

/** this variable caches the list of metric operators. */
let metricOperatorListCache:operationItem[];

/** returns the list of metric operators.
 *  @param hasComparisonData a boolean which is true if the previous node has comparison data.
 *  @returns an Array with the metric of key operators. */
function getMetricOperations ({ hasComparisonData } = { hasComparisonData: false }): Array<operationItem> {
    if (!metricOperatorListCache) {
        const operations: operationItem[] = [];
        const combinedOperatorsMap = {
            ...MetricOperators,
            ...MetricComparisonOperators,
            ...MetricBaselineOperators
        }
        for (const opkey in combinedOperatorsMap) {
            const operation = combinedOperatorsMap[opkey];
            if (operation) {
                operations.push({
                    value: operation.alt || operation.id,
                    display: operation.label,
                    valueNotReqd: operation.valueNotReqd,
                    raw: operation,
                });
            }
        }
        metricOperatorListCache = operations;
    }
    return hasComparisonData ? metricOperatorListCache : metricOperatorListCache.filter(operation => !(operation as any).raw.comparisonReqd);
}

/** this variable caches the list of metric aggregation operators. */
let metricAggregationOperationListCache:{
    [metric: string]: operationItem[]
} = {};

/** returns the list of metric aggregator operators.
 *  @param metric a string with the metric.
 *  @returns an Array with the metric of metric aggregation operators. */
function getMetricAggregationOperationList ({ metric } = { metric: "" }): Array<operationItem> {
    if (!metricAggregationOperationListCache[metric]) {
        const operations: operationItem[] = [];
        for (const opkey in FunctionOperators) {
            const operation = FunctionOperators[opkey];
            if (operation) {
                operations.push({
                    value: operation.alt || operation.id,
                    display: operation.label,
                    raw: operation,
                });
            }
        }
        metricAggregationOperationListCache[metric] = operations;
    }
    return metricAggregationOperationListCache[metric];
}

/**
 * Creates an object to be rendered in UI
 * @param item is an object with either a 'BLOCK' or 'CONDITION' key 
 * @returns Formatted object to be used in convertExpressionToTreeFormat function
 */
const conditionTreeItem = item => (
    {
        id: item.id,
        type: item.type,
        category: item.category,
        key: item.key,
        op: item.op,
        value: item.value
    }
)

/**
 * Converts from DAL to the proper format to rendered by UI
 * @param conditionTree 
 * @returns Object with the proper formatting to be rendered in UI 
 */
export function convertExpressionToTreeFormat(conditionTree: { type?: string;[x: string]: any }): any {
    const isBlock = conditionTree.type === 'block';
    return isBlock ? {
        id: !!conditionTree['id'] ? conditionTree.id : null,
        type: conditionTree.type || 'block',
        operation: conditionTree.operation || ConditionBlockType.AND,
        conditions: conditionTree.conditions?.length ? conditionTree.conditions.map(condition => convertExpressionToTreeFormat(condition)) : null
    } : conditionTreeItem(conditionTree);
}

/**
 * Creates an object to be used in DAL formatting.
 * @param item is a Condition object
 * @returns Formatted DAL Condition object with/without properties to be used in convertConditionTreeToDALFormat
 */
const conditionDAL = (item) => {
    return {
        condition: {
            id: item.id,
            category: item.category,
            key: item.key,
            operation: item.op && Operations[Ops[item.op]],
            value: item.value && String(item.value)
        }
    }
};

/**
 * Converts to proper DAL format (to be sent in the create mutation), 
 * when 'conditionTree' has either 'BLOCK' or 'CONDITION' 
 * or when is a NEW creation.
 * @param conditionTree 
 * @returns Object with the proper DAL format
 */
export function convertConditionTreeToDALFormat(conditionTree: { type?: string;[x: string]: any }): any {
    const isEmpty = Object.keys(conditionTree).length === 0 && conditionTree.constructor === Object;
    const isBlock = conditionTree.type === 'block';
    return !isEmpty && isBlock ?
        {
            block: {
                id: conditionTree.id ? conditionTree.id : null,
                operator: conditionTree.operation,
                expressions: conditionTree.conditions.map(condition => convertConditionTreeToDALFormat(condition))
            }
        } : conditionDAL(conditionTree);
}

/**
 * Validates the conditions
 * return true if key, op and value are not empty
 * otherwise return false
 * @param conditionTree
 * @returns boolean
 */
export function validateConditionTree(conditionTree: { type?: string;[x: string]: any }): any {
    let validConditions = false;
    const validateConditions = (conditionTree) => {
        const isEmpty = Object.keys(conditionTree).length === 0 && conditionTree.constructor === Object;
        if (!isEmpty) {
            if (conditionTree.hasOwnProperty("conditions")) {
                for (let i = 0; i < conditionTree.conditions.length; i++) {
                    const condition = conditionTree.conditions[i];
                    if (condition.hasOwnProperty("conditions")) {
                        validateConditions(condition);
                    } else {
                        validConditions = Boolean(condition.key && condition.key !== '' &&
                            condition.op && condition.op !== '' &&
                            condition.value && condition.value !== '');
                        if (!validConditions) {
                            break;
                        }
                    }
                }
            }
        }
    }
    validateConditions(conditionTree);
    return validConditions;
}
