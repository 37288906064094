/** This module contains the class that should be the base class for all Hyperion services.
 *  @module
 */
import axios from 'axios';
import { saveAs } from 'file-saver';
import { AuthServiceProvider } from 'utils/providers/AuthServiceProvider';

/** the wrapper for a get call, that returns both an etag and the data. */
export interface EtagResult<T> {
    /** a string with the etag pulled out of the header. */
    etag: string | undefined;
    /** the data returned by the call. */
    data: T;
}

/** this class is the base class for all Hyperion API services. */
class ApiService {
    /** a reference to the auth service that is used to get the tenant and the region. */
    protected static AUTH_SERVICE = AuthServiceProvider.getService();
    /** a boolean value true if the region should be included in the API calls. */
    public static USE_REGION = true;

    /** the base URI for the API. */
	protected baseApiUri: string;
    /** the string with the etag, this should not be used, since there can be multiple gets. */
	protected ETAG: string | undefined;

    /** The constructor for the class.
     *  @param baseApiUri the base URI for the API. */
	constructor(baseApiUri: string = '') {
		this.baseApiUri = baseApiUri;
		this.ETAG = undefined;
	}

    /** returns the base uri, this can be overridden in subclasses to allow the uri to change
     *      after construction.
     *  @returns a String with the base uri. */
    protected getBaseUri(): string {
        return this.baseApiUri;
    }

	/** manages the errors regardless of source and turns them into something the client code can handle consistently
     *  @param error a string with the error object.
     *  @returns a string with the error information. */
	protected parseError(error) {
		if (error.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx

			//this is normal error handling case (validation/checks/etc)
			//the backend returns a body with an error message
			return error.response.data;
		} else {
			// Some real error happened, might need debugging, so lets add some extra log info
			console.error(error.toJSON()); //log some more info
			//it will have 'message' like the backend errors, so frontend code will be ok
			return error;
		}
	}

	/** extracts the actual backend response from the axios response.
     *  @param response the http response object.
     *  @returns the data in the response object. */
	protected parseResponse(response) {
		return response.data;
	}

    /** handles the Axios promise and pulls any etags out of the header.
     *  @param promise the Promise that is being handled.
     *  @returns the data in the response. */
	protected handlePromise<T>(promise: Promise<T>): Promise<T> {
		const self = this;
		return promise.then((response) => {
			if (response['headers']['etag']) {
				self.ETAG = response['headers']['etag'];
			}
			return self.parseResponse(response)
		}).catch(error => {
			return Promise.reject(error);
		})
	}

    /** handles the Axios promise when the etag needs to be wrapped in the output.
     *  @param promise the Promise that is being handled.
     *  @returns the data and etag in the response wrapped in an EtagResult. */
    protected handleEtagPromise<T>(promise: Promise<T>): Promise<EtagResult<T>> {
		const self = this;
		return promise.then((response) => {
            let etag: string | undefined = undefined;
			if (response['headers']['etag']) {
				etag = response['headers']['etag'];
			}
			return {data: self.parseResponse(response), etag};
		});
	}

    /** adds the auth information and etag to the header.
     *  @param isPublic .
     *  @param etag a String with the etag.
     *  @returns an object with the headers that are to be set. */
    protected async headers(isPublic = false, etag): Promise<object> {
		if (isPublic) {
			return {};
		}
		const accessToken = await ApiService.AUTH_SERVICE.getToken();
		if (etag) {
			return {
				'Authorization': `Bearer ${accessToken}`,
				'If-Match': etag
			};
		} else {
			return { Authorization: `Bearer ${accessToken}` };
		}
	}

    /** does the http get and automatically pulls out the etag and places it in a member variable
     *      in the class.
     *  @param endpoint the API endpoint
     *  @param params the query parameters.
     *  @param isPublic a boolean which specifies if it is public.
     *  @param config items to be added to the AxiosConfig object.
     *  @returns A Promise that resolves to the data. */
	public async get<T>(endpoint: string = '', params?: object, isPublic?: boolean, config?): Promise<T> {
		const h = await this.headers(isPublic, undefined);
        return this.handlePromise<T>(
			axios.get(this.getBaseUri() + endpoint, {
				headers: h,
				params: params,
				...config,
			})
		);
    }

    /** does the http get and wraps the result in an EtagResult.
     *  @param endpoint the API endpoint
     *  @param params the query parameters.
     *  @param isPublic a boolean which specifies if it is public.
     *  @param config items to be added to the AxiosConfig object.
     *  @returns A Promise that resolves to the EtagResult that wraps the data and the etag. */
    public async getWithEtag<T>(endpoint: string = '', params?: object, isPublic?: boolean, config?): Promise<EtagResult<T>> {
		const h = await this.headers(isPublic, undefined);
        return this.handleEtagPromise<T>(
			axios.get(this.getBaseUri() + endpoint, {
				headers: h,
				params: params,
				...config,
			})
		);
	}

    /** does the http put.
     *  @param endpoint the API endpoint
     *  @param payload the payload for the put.
     *  @param isPublic a boolean which specifies if it is public.
     *  @param config items to be added to the AxiosConfig object.
     *  @param etag a string with the etag that should be passed in the header or undefined if no etag.
     *  @returns A Promise that resolves to the returned data. */
    public async put<T>(endpoint: string = '', payload?: unknown, isPublic?: boolean, config?, etag?: string): Promise<T> {
		const h = await this.headers(isPublic, etag || this.ETAG);
		return this.handlePromise<T>(
			axios.put(this.getBaseUri() + endpoint, payload, {
				headers: h,
				...config,
			})
		);
	}

    /** does the http post.
     *  @param endpoint the API endpoint
     *  @param payload the payload for the post.
     *  @param isPublic a boolean which specifies if it is public.
     *  @param config items to be added to the AxiosConfig object.
     *  @returns A Promise that resolves to the returned data. */
    public async post<T>(endpoint: string = '', payload?: unknown, isPublic?: boolean, config?): Promise<T> {
		const h = await this.headers(isPublic, undefined);
		return this.handlePromise<T>(
			axios.post(this.getBaseUri() + endpoint, payload, {
				headers: h,
				...config,
			})
		);
	}

    /** does the http patch.
     *  @param endpoint the API endpoint
     *  @param payload the payload for the patch.
     *  @param isPublic a boolean which specifies if it is public.
     *  @param config items to be added to the AxiosConfig object.
     *  @returns A Promise that resolves to the returned data. */
    public async patch<T>(endpoint: string = '', payload?: object, isPublic?: boolean, config?): Promise<T> {
		const h = await this.headers(isPublic, undefined);
		return this.handlePromise<T>(
			axios.put(this.getBaseUri() + endpoint, payload, {
				headers: h,
				...config,
			})
		);
	}

    /** does the http delete.
     *  @param endpoint the API endpoint
     *  @param isPublic a boolean which specifies if it is public.
     *  @param config items to be added to the AxiosConfig object.
     *  @returns A Promise that resolves to the returned data. */
    public async delete<T>(endpoint: string = '', isPublic?: boolean, config?): Promise<T> {
		const h = await this.headers(isPublic, undefined);
		return this.handlePromise<T>(
			axios.delete(this.getBaseUri() + endpoint, {
				headers: h,
				...config,
			})
		);
	}

	/** turns out downloading files with an auth header is not very straightforward, so we need this extra functionality
	 *  a promise is not strictly needed for this case, because the data will be passed to the browser directly,
	 *  it might be useful to show the user while the download is in progress, since it will go to ram and not show a normal
	 *  download indicator in the browser until the file finished downloading
	 *
	 *  USE ONLY FOR SMALL FILES! (since it will not show normal download progress)
     *  @param endpoint the API endpoint
     *  @param filename a String with the filename.
     *  @param isPublic a boolean which specifies if it is public.
     *  @param config items to be added to the AxiosConfig object.
     *  @returns A Promise that resolves to the returned data. */
	public async download(endpoint: string, filename: string, isPublic?: boolean, config?): Promise<void> {
		const h = await this.headers(isPublic, undefined);
		return axios
			.get(this.getBaseUri() + endpoint, {
				headers: h,
				responseType: 'blob',
				...config,
			})
			.then((response) => {
				saveAs(new Blob([response.data]), filename);
			});
	}
}

export { ApiService };
