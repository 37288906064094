import React, { useState } from 'react'
import { loader } from "graphql.macro";
import { Query } from "reporting-infrastructure/types/Query";
import { FILTER_NAME, parseTimeFromDALToUnix, useQuery } from 'utils/hooks';
import { STRINGS } from 'app-strings';
import { DataLoadFacade } from 'components/reporting/data-load-facade/DataLoadFacade';
import { BasicDialog, updateDialogState } from 'components/common/basic-dialog/BasicDialog';
import TimelineChart from 'components/common/timeline-chart/TimelineChart';
import moment from 'moment';
import { Button, Intent } from '@blueprintjs/core';
import { IndicatorsListView } from 'components/hyperion/views/indicators-list-view/IndicatorsListView';
import { IndicatorDetailedView } from 'components/hyperion/views/indicator-detailed-view/IndicatorDetailedView';
import { RunbookTemplateView } from 'components/hyperion/views/runbooks/runbook-template-view/RunbookTemplateView';
import { Variant } from 'components/common/graph/types/GraphTypes';
import { Card } from 'components/reporting';
import { IconTitle } from 'components/common/icon-title/IconTitle';
import { SIZE } from 'components/enums';
import './TimelineOutputView.scss';

export default function TimelineOutputsView({ incidentHeaderQuery, incident, setShowBlade }) {

    const initDialogState = { showDialog: false, title: STRINGS.timelineOutputsView.dialogTitle, loading: false, dialogContent: <></>, dialogFooter: <></> };
    const [dialogState, setDialogState] = useState<any>(initDialogState);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isRealTime, setIsRealTime] = useState<boolean>(true);
    const incidentStrings = STRINGS.MAPPING_CONFIGURATION_PAGE.addMappingModal.panels.selectTrigger.triggers;

    const primaryIndicatorQuery = useQuery({
        name: "PrimaryIndicatorForPrimaryIndicatorView",
        query: new Query(loader("../primary-indicator/primary-indicator-query.graphql")),
        // filters: {
        //     [FILTER_NAME.incidentId]: incident?.id,
        //     [FILTER_NAME.variant]: Variant.INCIDENT.toUpperCase()
        // },
        requiredFilters: [FILTER_NAME.incidentId],
        // skipGlobalFilters: true,
        timeNotRequired: true,
    });

    // uncapFirstLetter (Should be in utils)
    const uncapFirstLetter = (string) => {
        return (string?.charAt(0)?.toLowerCase() + string?.slice(1)) || '';
    }

    const runbookQuery = useQuery({
        name: "RunbookList",
        query: new Query(loader("../runbook-outputs-tab/runbook-list.graphql")),
        requiredFilters: [FILTER_NAME.incidentId],
    });

    // Yellow
    // console.log('runbookQuery', runbookQuery?.data)

    // Blue
    // const correlatedInds = primaryIndicatorQuery?.data?.incidents?.nodes?.[0];
    // console.log('correlatedInds', correlatedInds)

    //Green
    //console.log('incidentHeaderQuery', incidentHeaderQuery?.data)

    // All types of events raw data
    const lifecycleEvents = runbookQuery?.data?.runbooks?.nodes?.filter(x => !x.entity?.kind?.includes("_")) || [];
    const runbookEvents = runbookQuery?.data?.runbooks?.nodes?.filter(x => x.entity?.kind?.includes("_")) || [];
    const primaryIndicator = primaryIndicatorQuery?.data?.incidents?.nodes?.[0]?.indicators?.filter(x => x.isPrimary) || [];
    const correlatedIndicators = primaryIndicatorQuery?.data?.incidents?.nodes?.[0]?.indicators?.filter(x => !x.isPrimary) || [];
    const incidentEvents = incidentHeaderQuery?.data?.incidents?.nodes || [];

    // Merging indicators with the same startTime a.k.a. Correlated Indicators
    function mergeIndicatorsByStartTime(indicators) {
        // Grouping objects by startTime
        const groupedByStartTime = indicators.reduce((acc, indicator) => {
            const key = indicator.startTime;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(indicator);
            return acc;
        }, {});

        // Merging the objects and adding the cluster property
        const mergedArray = Object.keys(groupedByStartTime).map(startTime => {
            const group = groupedByStartTime[startTime];
            // Merging objects and adding cluster count
            const mergedObject = { ...group[0], cluster: group.length };
            return mergedObject;
        });
        return mergedArray;
    }

    // Sorting & Mapping Series
    const allEventsSorted = [
        ...lifecycleEvents?.map(lfcEvent => ({
            seriesType: 'lifecycle',
            eventType: 'lifecycleEvent',
            timestamp: parseTimeFromDALToUnix(lfcEvent.timestamp),
            nameMap: lfcEvent.entity?.kind,
            value: lfcEvent
        })) || [],
        ...runbookEvents?.map((iqEvent, i) => ({
            seriesType: 'iqEvents',
            eventType: 'runbookIncident',
            timestamp: parseTimeFromDALToUnix(iqEvent.timestamp),
            nameMap: i === 0 ? STRINGS.timelineOutputsView.namedMap.runbookExecuted : STRINGS.timelineOutputsView.namedMap.runbookRerun,
            value: iqEvent
        })) || [],
        ...primaryIndicator?.map(indicator => ({
            seriesType: 'indicators',
            eventType: 'primaryIndicator',
            timestamp: parseTimeFromDALToUnix(indicator.startTime),
            nameMap: STRINGS.timelineOutputsView.namedMap.primaryIndicator,
            value: indicator
        })) || [],
        ...mergeIndicatorsByStartTime(correlatedIndicators)?.map(corrIndicator => ({
            seriesType: 'indicators',
            eventType: 'correlatedIndicator',
            timestamp: parseTimeFromDALToUnix(corrIndicator.startTime),
            nameMap: STRINGS.timelineOutputsView.namedMap.correlatedIndicator,
            value: corrIndicator
        })),
        ...incidentEvents?.flatMap(iqEvent => [
            { seriesType: 'iqEvents', timestamp: parseTimeFromDALToUnix(iqEvent.createdAt), value: iqEvent, nameMap: STRINGS.timelineOutputsView.namedMap.incidentStart },
            { seriesType: 'iqEvents', timestamp: parseTimeFromDALToUnix(iqEvent.endTime), value: iqEvent, nameMap: STRINGS.timelineOutputsView.namedMap.incidentEnd }
        ])
    ]
        .sort((a, b) => a.timestamp - b.timestamp)
        .map((event, i) => ({ ...event, sortIndex: i }));

    // Remapping Series to match highchart format and config
    const mappedSeries = allEventsSorted.reduce((acc, curr) => {
        if (!acc[curr.seriesType]) {
            acc[curr.seriesType] = {
                name:
                    curr.seriesType === 'lifecycle' ? STRINGS.timelineOutputsView.series.lifecycle :
                        curr.seriesType === 'iqEvents' ? STRINGS.timelineOutputsView.series.iqEvents :
                            curr.seriesType === 'indicators' ? STRINGS.timelineOutputsView.series.indicators : '',
                marker: {
                    symbol:
                        curr.seriesType === 'lifecycle' ? 'triangle' :
                            curr.seriesType === 'iqEvents' ? 'diamond' :
                                curr.seriesType === 'indicators' ? 'circle' : '',
                    radius: curr.seriesType === 'indicators' ? 9 : 10,
                    fillColor:
                        curr.seriesType === 'lifecycle' ? 'orange' :
                            curr.seriesType === 'iqEvents' ? 'green' :
                                curr.seriesType === 'indicators' ? '#0080FE' : ''
                },
                data: []
            };
        }
        // Extract the properties of curr.value and create the new structure
        const mappedEventPoint = {
            x: curr.sortIndex,
            ...((curr.value.cluster && curr.value.cluster > 1) && {
                marker: {
                    symbol: 'cluster',
                }
            }),
            name: curr.seriesType === 'lifecycle' ? (incidentStrings[uncapFirstLetter(curr?.nameMap)]?.label || '') :
                (curr.eventType === 'correlatedIndicator' && curr.value?.cluster > 1) ?
                    `${curr.value.cluster} ${STRINGS.timelineOutputsView.namedMap.correlatedIndicators}` : curr.nameMap,
            label: moment(parseTimeFromDALToUnix(curr.timestamp)).format('MMM Do, h:mm:ss A'),
            description: curr.value.cluster && curr.value.cluster > 1 ? curr.value.cluster : undefined,
            custom: { ...curr.value, eventType: curr.eventType || undefined }
        };

        acc[curr.seriesType].data.push(mappedEventPoint);
        return acc;
    }, {});

    // Sequenced array with all Series in order
    const sequencedSeries = Object.values(mappedSeries);

    // Yellow Triangle (Lifecycle)
    const getLifecycleData = () => {
        const lfcIncs = runbookQuery?.data?.runbooks?.nodes || [];
        const asd = lfcIncs.filter(lfc => !lfc.entity?.kind?.includes("_")).map(lfc => ({
            x: parseTimeFromDALToUnix(lfc.timestamp),
            name: incidentStrings[uncapFirstLetter(lfc.entity?.kind)]?.label || lfc.entity.kind,
            label: moment(parseTimeFromDALToUnix(lfc.timestamp)).format('MMM Do, h:mm:ss A')
        }));
        return asd
    }
    // Blue circle (Indicators)
    const getIndicators = () => {
        const onlyCorrelated = primaryIndicatorQuery?.data?.incidents?.nodes[0]?.indicators?.filter(x => !x.isPrimary) || [];
        // Group objects by startTime
        const startTimeGroups = onlyCorrelated.reduce((acc, obj) => {
            acc[obj.startTime] = acc[obj.startTime] || [];
            acc[obj.startTime].push(obj);
            return acc;
        }, {});
        // Map the array and add the 'cluster' property with the list of duplicated items
        const multiIncidents = onlyCorrelated.map(obj => ({
            ...obj,
            cluster: startTimeGroups[obj.startTime]
        }));

        const correlatedIndicators = onlyCorrelated.map((cInd, i) => (
            {
                x: parseTimeFromDALToUnix(cInd.startTime),
                name: STRINGS.timelineOutputsView.namedMap.correlatedIndicator,
                label: moment(parseTimeFromDALToUnix(cInd.startTime)).format('MMM Do, h:mm:ss A'),
                description: multiIncidents[i]?.cluster?.length || '' // All dup incidents :)
            }
        )) || [];

        return [
            {
                x: parseTimeFromDALToUnix(primaryIndicatorQuery?.data?.incidents?.nodes[0].earliestIndicator),
                name: STRINGS.timelineOutputsView.namedMap.primaryIndicator,
                label: moment(parseTimeFromDALToUnix(primaryIndicatorQuery?.data?.incidents?.nodes[0].earliestIndicator)).format('MMM Do, h:mm:ss A'),
            },
            ...correlatedIndicators
        ]
    }
    // Green diamond (Start/End)
    const getIncidentStartEndData = () => {
        const lfcIncs = runbookQuery?.data?.runbooks?.nodes || [];
        const incs = incidentHeaderQuery?.data?.incidents?.nodes || [];

        // We could sort runbooks to make index 0 always be the earliest date (*ToDo)
        const runbookEvents = lfcIncs.filter(lfc => lfc.entity?.kind?.includes("_")).map((inc, index) => ({
            x: parseTimeFromDALToUnix(inc.timestamp),
            name: STRINGS.timelineOutputsView.series.iqEvents,
            label: moment(parseTimeFromDALToUnix(inc.timestamp)).format('MMM Do, h:mm:ss A'),
        }));

        const IQevents = incs.flatMap(inc => [
            {
                x: parseTimeFromDALToUnix(inc.createdAt),
                name: STRINGS.timelineOutputsView.namedMap.incidentStart,
                label: moment(parseTimeFromDALToUnix(inc.createdAt)).format('MMM Do, h:mm:ss A')
            },
            {
                x: parseTimeFromDALToUnix(inc.endTime),
                name: STRINGS.timelineOutputsView.namedMap.incidentEnd,
                label: moment(parseTimeFromDALToUnix(inc.endTime)).format('MMM Do, h:mm:ss A')
            },
        ]
        )
        return [...runbookEvents, ...IQevents]
    }

    return (
        <>
            <BasicDialog dialogState={dialogState} className="timeline-dialog" onClose={() => setDialogState(updateDialogState(dialogState, false, false, []))} />
            <DataLoadFacade data={runbookQuery.data && primaryIndicatorQuery.data}>
                <IconTitle title={STRINGS.timelineOutputsView.title} size={SIZE.m} className="mb-2 font-weight-500" />
                <Card hideBorder hideShadow className="mb-4">
                    <TimelineChart
                        dialog={pointData => {
                            if (!pointData?.custom?.eventType) {
                                return false
                            } else {
                                showTimelineDialog(pointData, primaryIndicatorQuery?.data?.incidents?.nodes[0], dialogState, setDialogState, incident, primaryIndicatorQuery?.data?.runbooks?.nodes, setShowBlade);
                            }
                        }}
                        dataSeries={isRealTime ? sequencedSeries :
                            [
                                {
                                    name: "IQ Events",
                                    marker: {
                                        symbol: 'diamond',
                                        radius: 10,
                                        fillColor: 'green',
                                    },
                                    showInLegend: true,
                                    data: getIncidentStartEndData()
                                },
                                {
                                    name: "Lifecycle",
                                    marker: {
                                        symbol: 'triangle',
                                        radius: 10,
                                        fillColor: 'orange',
                                    },
                                    showInLegend: true,
                                    data: getLifecycleData()
                                },
                                {
                                    name: "Indicators",
                                    marker: {
                                        symbol: 'circle',
                                        radius: 9,
                                        fillColor: '#0080FE',
                                    },
                                    showInLegend: true,
                                    data: getIndicators()
                                }
                            ]}
                    />
                    {/* <Switch inline label={!isRealTime ? "Real time snapshots" : "Progressive sequence"} onChange={() => setIsRealTime(!isRealTime)} /> */}
                </Card>
            </DataLoadFacade>
        </>
    )
}

/** Dialog contents for timeline dialog */
const timelineDialogContent = (point, indicators, incident, runbook) => {
    const { eventType, template, variant, cluster, startTime, endTime } = point;
    const timeRange = {
        startTime: parseTimeFromDALToUnix(startTime) || 0,
        endTime: parseTimeFromDALToUnix(endTime) || 0,
    };
    const indicatorMappings = indicators.indicatorMappings.map(({ __typename, isPrimary, ...rest }) => rest);

    return eventType === 'lifecycleEvent' || eventType === 'runbookIncident' ? (
        <RunbookTemplateView template={JSON.parse(template)} variant={Variant[variant]} />
    ) : eventType === 'primaryIndicator' || (eventType === 'correlatedIndicator' && cluster === 1) ? (
        <IndicatorDetailedView
            className="flex-grow-1"
            indicatorMapping={indicatorMappings[0]}
            indicatorTimeRange={timeRange}
            isBaselineType={false}
            annotationIndicator={point}
            annotationIncident={incident}
            incidentTimeRange={{
                startTime: parseTimeFromDALToUnix(startTime) || 0, // Not sure this is the right value
                endTime: parseTimeFromDALToUnix(incident.endTime) || 0,
            }}
            isPrimaryIndicator={eventType === 'primaryIndicator'}
            runbooks={runbook}
        />
    ) : eventType === 'correlatedIndicator' && cluster > 1 ? (
        <>
            <IndicatorsListView
                indicatorMappings={indicators.indicatorMappings.filter(x => !x.isPrimary).map(({ __typename, isPrimary, ...rest }) => rest)}
                indicatorsTimeRange={timeRange}
            />
        </>
    ) : null;
};


/**
 * Dialog to dynamically open timeline labels actions
 * @param pointData All point content from highchart data series
 * @param datas Indicators (needed to retrieve indicatorPappings)
 * @param dialogState Body of dialog
 * @param setDialogState Set new dialog values
 */
function showTimelineDialog(
    pointData, datas, dialogState: any, setDialogState: (dialogState: any) => void, incident, runbook, setShowBlade
): void {
    const newDialogState = Object.assign({}, dialogState);
    newDialogState.showDialog = true;
    newDialogState.dialogContent = timelineDialogContent(pointData.custom, datas, incident, runbook)
    newDialogState.dialogFooter = <>
        <Button text={STRINGS.timelineOutputsView.notes} onClick={() => {
            setShowBlade(true);
            setDialogState(updateDialogState(newDialogState, false, false, []));
        }}/>
        <Button intent={Intent.PRIMARY} text={STRINGS.primaryIndicatorView.okBtnText}
            onClick={async () => {
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }}
        />
    </>;
    setDialogState(newDialogState);
}
