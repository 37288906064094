import { STRINGS } from "app-strings";

export enum ApiCallStatus {
    IDLE = 'IDLE',
    LOADING = 'LOADING',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
};

export enum Outline {
    SHOW = "SHOW",
    HIDE = "HIDE",
    SHOW_ON_HOVER = "SHOW_ON_HOVER"
}

/** this enum has all the rules that are displayed in the monitoring health page Incident Generation chart's legend.  */
export enum Rules {
    'rule.iface-hardware-problem' = STRINGS.rules.ifaceHardwareProblem,
    'rule.interface-down'         = STRINGS.rules.interfaceDown,
    'rule.iface-utilization'      = STRINGS.rules.ifaceUtilization,
    'rule.device-down'            = STRINGS.rules.deviceDown,
    'rule.application-problem'    = STRINGS.rules.applicationProblem,
    'rule.site-health-problem'    = STRINGS.rules.siteHealthProblem,
    'rule.site-outage'            = STRINGS.rules.multiDeviceDown,
    'rule.multi-device-down'      = STRINGS.rules.multiDeviceDown
}
