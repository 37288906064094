/** This file defines the TableView React component.  The TableView React component wraps a 
 *  Table component and includes the query to get the data.  The Table React component renders a 
 *  a basic table.
 *  @module */
import React, { useState, useEffect } from "react";
import { Table, TableColumnDef } from "@tir-ui/react-components";
import { loader } from "graphql.macro";
import { Query } from "reporting-infrastructure/types/Query";
import { useQuery, FILTER_NAME } from "utils/hooks";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { formatAndScaleMetricValue, precise } from "reporting-infrastructure/utils/formatters";
import { Column, SeverityScore, BaseRunbookViewProps, COLUMN_TYPE, DataSet } from "pages/riverbed-advisor/views/runbook-view/Runbook.type";
import { SEVERITY_CLASS } from "components/enums";
import { Unit } from "reporting-infrastructure/types/Unit.class";
import { formatBooleanValue, formatKeys, markUrlsFromText } from "utils/runbooks/RunbookFormatter";
import { Icon, IconNames } from "@tir-ui/react-components";
import { Classes, Tooltip2, Popover2 } from "@blueprintjs/popover2";
import { Button, PopoverPosition, Card, Menu, MenuItem } from "@blueprintjs/core";
import { STRINGS } from "app-strings";
import { calculatePercentChange, getComparisonParameters, keysMatch } from "utils/runbooks/RunbookOutputUtils";
import { INCIDENT_DETAILS_STYLE } from "components/enums/QueryParams";
import { ListWithOverflow } from "components/common/list-with-overflow/ListWithOverflow";
import "./TableView.scss";

/** an interface that describes the properties that can be passed in to the component.*/
export interface TableViewProps extends BaseRunbookViewProps {
    /** a String with the id of the column that is the trigger.*/
    triggerColumnId?: string;
    /** one of the enumerated severities with the severity of the trigger. */
    triggerSeverity?: SeverityScore;
    /** array of available columns. */
    columns?: any;
    /** sort column id and order. */
    sortBy?: Array<{ id: string; desc?: boolean;}>;
    /** a boolean value which, if true, specifies that selections should be be displayed. */
    showSelections?: boolean;
    /** toggles the `<ShowMore />` control for the `<NavigatorWidget />` */
    showControls?: boolean;
    /** the limit for the number of rows in the table. */
    tableLimit?: number;
    /** the handler for table limit changes. */
    setTableLimitHandler?: any;
    /** In npm+ we need to recreate the table on each render because the columns could change.  If this is true
     *  each table gets a new table. */
    recreateTableOnEachRender?: boolean;
}

/** this type defines the show more options. */
type ShowMoreProps = {
    /** which `<MenuItem />` is active */
    tableLimit: number;
    /** toggle "show more" controls */
    setTableLimitHandler?: any;
};

/** the key seed is used to completely redraw the table in the NPM+ UI because when the columns change after
 *  the table is created, the table did not update. */
let KEY_SEED: number = 1;

export const ShowMore = ({ tableLimit, setTableLimitHandler }: ShowMoreProps) => {
    const [selectedItem, setSelectedItem] = useState(tableLimit);

    // Need to lift `tableLimit` back up the grandparent `<NavigatorWidget />`
    const handleMenuItemClick = (item: number) => {
        setSelectedItem(item);
        setTableLimitHandler(item);
    };

    const showItems = (
        <Menu>
            {[5, 10, 25, 50, 100].map((item) => (
                <MenuItem
                    key={item}
                    text={item}
                    active={selectedItem === item}
                    onClick={() => handleMenuItemClick(item)}
                />
            ))}
        </Menu>
    );

    return (
        <Card className="WidgetTable-showmore">
            <Popover2 content={showItems} fill={true} placement="bottom">
                <Button
                    alignText="center"
                    fill={true}
                    rightIcon="caret-down"
                    text={STRINGS.showMore}
                />
            </Popover2>
        </Card>
    );
};

/** Creates the table view, which is a component that displays one table with analytics data.
 *  @param props an object with the properties passed to the table view.
 *  @returns JSX with the table component.*/
export const TableView = (props: TableViewProps): JSX.Element => {
    let {comparisonSuffix = ""} = getComparisonParameters(props.widget?.options?.comparedTo as string);
    let primaryDataset: DataSet | undefined = undefined;
    let comparisonDataset: DataSet | undefined = undefined;
    if (props.datasets) {
        for (const dataset of props.datasets) {
            if (!dataset.isComparison) {
                primaryDataset =  dataset;
            } else {
                comparisonDataset = dataset;
            }
        }
    }

    let {loading, data, error} = useQuery({
        query: new Query(loader("./summary-data-query.graphql")),
        requiredFilters: [FILTER_NAME.incidentId, FILTER_NAME.datasetId],
        filters: {
            [FILTER_NAME.incidentId]: props.incidentId,
            [FILTER_NAME.datasetId]: primaryDataset?.datapoints ? undefined : primaryDataset?.id
        },
        skipGlobalFilters: true
    });

    const keyColumns: Array<string> = [];
    let columnDefs: Array<Column> = [];
    let columnDefsById: Record<string, Column> = {};

    if (props.hasOwnProperty('columns') && props.columns.includes('group_column')) {
        const groupColumn: Column = {
            id: 'group_column',
            label: STRINGS.runbookEditor.nodeLibrary.nodes.table.groupLabel,
            type: COLUMN_TYPE.STRING,
            unit: ''
        };
        columnDefs = columnDefs.concat([groupColumn]);
        columnDefsById.group_column = groupColumn;
        keyColumns.push("group_column");
    }

    if (primaryDataset?.keys) {
        columnDefs = columnDefs.concat(primaryDataset.keys);
        for (const key in primaryDataset.keys) {
            columnDefsById[primaryDataset.keys[key].id] = primaryDataset.keys[key];
            keyColumns.push(primaryDataset.keys[key].id);
        }
    }

    if (primaryDataset?.metrics) {
        columnDefs = columnDefs.concat(primaryDataset.metrics);
        for (const key in primaryDataset.metrics) {
            columnDefsById[primaryDataset.metrics[key].id] = primaryDataset.metrics[key];
        }
    }

    let tableData: Array<any> = [];
    if (primaryDataset?.datapoints) {
        data = {};
        data.datapoints = primaryDataset.datapoints;
    }
    if (!loading) {
        if (data && data.datapoints && primaryDataset?.keys && primaryDataset?.metrics) {
            let rowIndex = 1;
            for (const datapoint of data.datapoints) {
                const tableRow: any = {id: rowIndex++};
                if (props.hasOwnProperty('columns') && props.columns.includes('group_column')) {
                    tableRow.group_column = {current: formatKeys(primaryDataset.keys, datapoint.keys, true)};
                }

                tableRow.group = datapoint.keys?.group;

                if (primaryDataset.keys) {
                    // We need to make sure every key has a value so loop through the key definitions and get their values
                    for (const keyDef of primaryDataset.keys) {
                        const key = keyDef.id;
                        tableRow[key] = {current: datapoint.keys && datapoint.keys[key] !== undefined ? datapoint.keys[key] : null};
                    }
                }

                if (primaryDataset.metrics) {
                    for (const metricDef of primaryDataset.metrics) {
                        const key = metricDef.id;
                        let tv = datapoint.data && datapoint.data[key] !== undefined ? datapoint.data[key] : null;
                        tableRow[key] = {current: tv};
                        if (datapoint.keys && comparisonDataset?.datapoints) {
                            for (const checkDatapoint of comparisonDataset.datapoints) {
                                if (keysMatch(datapoint, checkDatapoint)) {
                                    tableRow[key].previous = checkDatapoint.data[key];
                                    break;
                                }
                            }
                        }
                    }
                }
                tableData.push(tableRow);
            }
        }
    }

    const triggerId = props.triggerColumnId;
    const triggerSeverity = (props?.triggerSeverity?.value ? SEVERITY_CLASS[props.triggerSeverity.value] : {});
    const columns: Array<any> = [];

    for (const columnDef of columnDefs) {
        const id = columnDef.id.replace(/\./g, "__");
        if (columnDef.hidden) {
            continue;
        }
        const column: TableColumnDef = {
            id: id,
            Header: columnDef.label,
            accessor: id,
            sortable: true,
            formatter: (record: any) => {
                let rawValue = record[id].current;
                let value = formatValue(rawValue, columnDef, keyColumns);
                let rawCompValue = record[id].previous;
                let compValue = formatValue(rawCompValue, columnDef, keyColumns);
                let pctChange: number | undefined = undefined;
                if (columnDef.enum) {
                    return <div className="metric-value">
                        <div className="value-container">
                            <div className="value">{value !== null && value !== undefined ? columnDef.enum[value.toString()] : ""}</div>
                        </div>
                        {compValue !== null && compValue !== undefined && <Tooltip2 className={Classes.TOOLTIP2_INDICATOR + " border-0"} content={
                            <div className="px-2 py-2">
                                <table><tbody>
                                    <tr key="current"><td className="text-right pr-2">{STRINGS.incidents.runbookOutputs.currentValueLabel}</td><td>{value !== null && value !== undefined ? columnDef.enum[value.toString()] : ""}</td></tr>
                                    <tr key="previous"><td className="text-right pr-2">{comparisonSuffix}</td><td>{columnDef.enum[compValue.toString()]}</td></tr>
                                </tbody></table>
                            </div>
                        }
                            position={PopoverPosition.RIGHT} transitionDuration={50}
                        >
                            <div className="comparison-value-container ml-1">
                                <div className="value">{"(" + STRINGS.incidents.runbookOutputs.enumCompareLabel + " " + columnDef.enum[compValue.toString()] + ")"}</div>
                            </div>
                        </Tooltip2>}
                    </div>;
                }
                if (
                    rawValue !== undefined && rawValue !== null && rawCompValue !== undefined && rawCompValue !== null &&
                    !Number.isNaN(rawValue) && !Number.isNaN(rawCompValue)
                ) {
                    pctChange = calculatePercentChange(rawValue, rawCompValue);
                }
                const nonMetric = typeof rawValue === "object" || (typeof rawValue === "string" && isNaN(Number(rawValue)));
                if (nonMetric) {
                    if (
                        (columnDef.type === "array" || columnDef.type === "ipaddrList") && 
                        value !== null && value !== undefined && typeof value === "string" && value.trim().length > 0
                    ) {
                        // Need to handle an array of items
                        let items = [];
                        try {
                            items = JSON.parse(value);
                        } catch (error) {}
                        return <ListWithOverflow overflowAfter={2} items={items}/>;
                    }

                    return <>{value !== null && value !== undefined ? markUrlsFromText(value) : <span className="table-cell-dash">&#8212;</span>}</>;
                }
                const [val, unit] = String(value !== undefined && value !== null ? value : "").split(" ");
                const valueDisplay = <div className="value-container">
                    <div className="value">{val}</div>
                    {unit && <div className="unit">{unit}</div>}
                </div>;
                return <div className="metric-value">
                    {valueDisplay}
                    {pctChange !== undefined && <>
                        <Tooltip2 className={Classes.TOOLTIP2_INDICATOR + " border-0"} content={
                            <div className="px-2 py-2">
                                <table><tbody>
                                    <tr key="current"><td className="text-right pr-2">{STRINGS.incidents.runbookOutputs.currentValueLabel}</td><td>{value}</td></tr>
                                    <tr key="previous"><td className="text-right pr-2">{comparisonSuffix}</td><td>{compValue}</td></tr>
                                    <tr key="change"><td className="text-right pr-2">{STRINGS.incidents.runbookOutputs.changeValueLabel}</td><td>
                                        {pctChange > 1000 && <span>&gt; </span>}{pctChange < -1000 && <span>&lt; </span>}
                                        {precise(pctChange > 0 ? Math.min(pctChange, 1000) : Math.max(pctChange, -1000)) + " %"}
                                    </td></tr>
                                </tbody></table>
                            </div>
                        }
                            position={PopoverPosition.RIGHT} transitionDuration={50}
                        >
                            <div className="comparison-value-container">
                                <Icon
                                    icon={pctChange > 0 ? IconNames.ARROW_UP : pctChange < 0 ? IconNames.ARROW_DOWN : IconNames.MINUS}
                                    iconSize={16}
                                    className="mx-1"
                                />
                                <div className="value">{(pctChange > 1000 || pctChange < -1000) && "> "}{precise(Math.min(Math.abs(pctChange), 1000))}</div>
                                <div className="unit">%</div>
                            </div>
                        </Tooltip2>
                    </>}
                </div>;
            }
        };
        if (columnDef.order_by_weight) {
            column.sortFunction = (a, b, columnId) => {
                const valA = columnDef.order_by_weight![a[columnId].current] || 0;
                const valB = columnDef.order_by_weight![b[columnId].current] || 0;
                return valA > valB ? 1 : valA < valB ? -1 : 0;
            };
        } else if (columnDef.type === COLUMN_TYPE.INTEGER || columnDef.type === COLUMN_TYPE.FLOAT) {
            column.sortFunction = (a, b) => {
                const valA = getRawValue(a[id].current, columnDef);
                const valB = getRawValue(b[id].current, columnDef);
                if (valA !== undefined && valA !== null && valB !== undefined && valB !== null) {
                    return valA > valB ? 1 : valA < valB ? -1 : 0;
                } else if ((valA === undefined || valA === null) && valB !== undefined && valB !== null) {
                    return -1;
                } else if ((valB === undefined || valB === null) && valA !== undefined && valA !== null) {
                    return 1;
                } else {
                    return 0;
                }
            };
        } else  {
            column.sortFunction = (a, b) => {
                const valA = a[id].current;
                const valB = b[id].current;
                if (valA !== undefined && valA !== null && valB !== undefined && valB !== null) {
                    const compValue = String(valA).localeCompare(String(valB));
                    return compValue > 0 ? 1 : compValue < 0 ? -1 : 0;
                } else {
                    return 0;
                }
            };
        }
        /* The ipaddress sorter did not work, we need to find another way to do this
        if (columnDef.type === COLUMN_TYPE.IPADDR) {
            column.sortFunction = (a, b) => {
                const value = ip6addr.compare(a, b);
                return value > 0 ? 1 : value < 0 ? -1 : 0;
            };
        }
        */
        if (false && triggerId && triggerSeverity && columnDef.id === triggerId) {
            column.className = triggerSeverity.bg;
            column.style = {background: "red"}
            column.Cell = (cell) => {
                return <span className={triggerSeverity.bg}>{cell.value}</span>;
            };
        }
        columns.push(column);
    }

    for (const tableRow of tableData) {
        for (const colKey in tableRow) {
            if (colKey.includes(".")) {
                tableRow[colKey.replace(/\./g, "__")] = tableRow[colKey];
                delete tableRow[colKey];
            }
        }
    }

    let getHeaderProps, getRowProps, getColumnProps, getCellProps;
    getCellProps = (cell) => {
        if (cell.column.id === triggerId) {
            return {className: triggerSeverity.bg};
        }
        return {};
    };

    const selectedColumnsIds = props.hasOwnProperty('columns') ? props.columns.map(column => column.replace(/\./g, "__")) : [];
    const initialSortBy = props.sortBy?.length ? props.sortBy.map((spec) => {return {id: spec.id.replace(/\./g, "__"), desc: Boolean(spec.desc)}}) : undefined;
    const [sortBy, setSortBy] = useState<Array<{id: string, desc: boolean}> | undefined>(initialSortBy);

    // series of variables which are used to determine, in conjunction with the
    // `props.showControls`, if the `<ShowMore />` renders or not. This is done
    // because once the datapointsLength reaches a size of 25, the pagination
    // controls from tiramisu will render.
    const datapoints = props?.datasets[0]?.datapoints;
    const datapointsLength = datapoints?.length ?? 0;
    /** used to determine if `<ShowMore />` renders */
    const isShowMoreLimitReached = datapointsLength < 25;

    useEffect(() => {
        const flippedTableEqualizeHeights = () => {
            const ths: NodeListOf<HTMLTableElement> | null = document.querySelectorAll('.flipped-table thead th');
            const tds: NodeListOf<HTMLTableElement> | null = document.querySelectorAll('.flipped-table tbody td');
    
            if (ths && tds && ths.length === tds.length) {
                for (let i = 0; i < ths.length; i++) {
                    ths[i].style.height = 'auto';
                    tds[i].style.height = 'auto';
    
                    const thHeight = ths[i].offsetHeight;
                    const tdHeight = tds[i].offsetHeight;
    
                    const maxHeight = Math.max(thHeight, tdHeight);
                    ths[i].style.height = `${maxHeight}px`;
                    tds[i].style.height = `${maxHeight}px`;
                }
            }
        }

        flippedTableEqualizeHeights();

        window.addEventListener('resize', flippedTableEqualizeHeights);

        return () => {
            document.removeEventListener('resize', flippedTableEqualizeHeights);
        };
    });

    return (
        <>
            <DataLoadFacade
                loading={loading || props.loading}
                error={error}
                data={data}
            >
                <Table
                    id={primaryDataset?.id || "id"}
                    key={props.recreateTableOnEachRender ? KEY_SEED++ : undefined}
                    columns={columns}
                    data={tableData}
                    interactive={true}
                    defaultPageSize={10}
                    getCellProps={getCellProps}
                    getColumnProps={getColumnProps}
                    getRowProps={getRowProps}
                    getHeaderProps={getHeaderProps}
                    enablePagination={true}
                    selectedColumnIds={selectedColumnsIds}
                    showColumnChooser={props.showControls ? false : true}
                    sortBy={sortBy}
                    onSortByChange={(newSortBy) => {
                        if (newSortBy) {
                            setSortBy(newSortBy);
                        }
                    }}
                    className={
                        "table-view" +
                        (INCIDENT_DETAILS_STYLE ===
                        "noTableOneCardForEachWidget"
                            ? " individual-cards"
                            : "") + (tableData?.length === 1 && !!props.widget?.options?.flipTable ? " flipped-table" : "")
                    }
                    enableSelection={props.showSelections === true}
                    onSelectionChange={(rows: Array<any>) => {
                        if (props.onGroupsAndMetricsSelected) {
                            const groups: any[] = [];
                            for (const row of rows) {
                                if (row.group) {
                                    groups.push(row.group);
                                }
                            }
                            if (groups.length) {
                                props.onGroupsAndMetricsSelected(
                                    [...groups],
                                    [],
                                );
                            }
                        }
                    }}
                />
            </DataLoadFacade>
            {/* verifying if controls are enabled AND datasets are less than 25
                due to the baked in pagination controls appearing
             */}
            {props.showControls && isShowMoreLimitReached ? (
                <div className="ShowMore-container">
                    <ShowMore
                        tableLimit={props.tableLimit ?? 10}
                        setTableLimitHandler={props.setTableLimitHandler}
                    />
                </div>
            ) : null}
        </>
    );
};

/** formats the value based on its type.
 *  @param value the value returned by the back-end.  When coming from DAL this will be a string,
 *      but when we do testing with node red this can be a number.
 *  @param columnDef the definition of the column.
 *  @param keyColumns the list of ids with the key columns.  This is used to know whether the column
 *      needs to be scaled.  Key columns will not be scaled.
 *  @returns the formated value for the column. */
function formatValue(value: string | number | null, columnDef: Column, keyColumns: Array<string>): string | number | null {
    if (value !== null && value !== undefined) {
        try {
            if (columnDef.type === "integer" || columnDef.type === "float") {
                if (typeof value === "string") {
                    // We have a string
                    if (value === "") {
                        // Empty string will return 0
                        return null;
                    }
                    value = Number(value);
                }
                if (Number.isNaN(value)) {
                    return null;
                }
                if (columnDef.unit && columnDef.unit !== "" && columnDef.unit !== "none") {
                    value = formatAndScaleMetricValue(value, Unit.parseUnit(columnDef.unit || "")).formatted;
                } else if (columnDef.type === "float") {
                    value = precise(value);
                }
            } else if (columnDef.type === "boolean") {
                value = formatBooleanValue(value as string | boolean);
            } else if (columnDef.type === "json" && value && typeof value === "object") {
                value = JSON.stringify(value);
            }
        } catch (error) {
            console.log("table data was not a number");
        }
    }
    return value;
}

/** get the raw value without formatting it for the unit, just convert it to the correct type.
 *  @param value the value returned by the back-end.  When coming from DAL this will be a string, 
 *      but when we do testing with node red this can be a number.
 *  @param columnDef the definition of the column.
 *  @returns the unformatted value with the correct type and no unit. */
function getRawValue(value: string | number | null, columnDef: Column): string | number | null {
    if (value !== null && value !== undefined && (columnDef.type === "integer" || columnDef.type === "float")) {
        try {
            if (typeof value === "string") {
                // We have a string
                if (value === "") {
                    // Empty string will return 0
                    return null;
                }
                value = Number(value);
            }
            if (Number.isNaN(value)) {
                return null;
            }
        } catch (error) {
            console.log("table data was not a number");
        }
    }
    return value;
}
