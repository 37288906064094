/** This module contains the component for creating a graph drag and drop source.
 *  @module
 */
import React, { DragEvent, useState, useEffect, useRef, useMemo } from "react";
import { Classes, Popover2 } from "@blueprintjs/popover2";
import { Button, PopoverPosition, InputGroup, Intent } from "@blueprintjs/core";
import { RunbookNode } from "utils/services/RunbookApiService";
import { DIRECTION, GraphDef, NodeWiresSetting, Variant } from "./types/GraphTypes";
import { STRINGS } from "app-strings";
import { Icon, IconNames } from "@tir-ui/react-components";
import { APP_ICONS, SDWAN_ICONS } from "components/sdwan/enums";
import { IconTitle } from "../icon-title/IconTitle";
import { SIZE } from "components/enums";
import { NodeLibrary, NodeLibraryCategory, NodeLibraryNode, NodeLibrarySpec, NodeLibrarySubType, SubTypeDefault } from "pages/create-runbook/views/create-runbook/NodeLibrary";
import { triggerNodes, dataOceanNodes, chartNodes, tagNodes, deduplicateSubflows, subflowOutputNodes } from "utils/runbooks/RunbookUtils";
import { HintInfo, useGuidedHintsStore, WrapInGuidedHint } from "../guided-hints";
import { getReactFlowNodeType, splitName } from "./react-flow/ReactFlowGraph";
import { HINTS_HIDDEN_USER_PREFS_KEY } from "utils/services/UserPrefsTypes";
import { useUserPreferences } from "utils/hooks";
import VariableDefinitionView from "./variables/VariableDefinitionView";
import { RunbookIntegrationDetails } from "pages/integrations/types/IntegrationTypes";
import { getTriggerTypes } from "utils/stores/GlobalEventMappingsStore";
import { BasicDialog, DialogState, updateDialogState } from "../basic-dialog/BasicDialog";
import { AuthServiceProvider } from "utils/providers/AuthServiceProvider";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { EventNames, trackEvent } from "utils/appinsights";
import { HelpSources } from "utils/appinsights/AppInsights";
import { InlineHelp } from "../layout/inline-help/InlineHelp";
import DOMPurify from 'dompurify';
import { DataOceanUtils } from 'components/common/graph/editors/data-ocean/DataOceanUtils';
import { getTypes } from "utils/stores/GlobalDataSourceTypeStore";
import { IS_EMBEDDED } from "components/enums/QueryParams";
import { DEFAULT_SUBFLOW_COLOR } from "./react-flow/nodes/subflow/SubflowNode";
import { subflowOrderingInNodePalette } from "utils/runbooks/RunbookUtils";

import './DragAndDropPanel.scss';
import './variables/VariableDefinitionView.scss';

/** these variables get the environment and help URI from the runtime config. */
let { ENV, HELP_URI } = window["runConfig"] || {};

const DOC_ROOT: string = IS_EMBEDDED ? "EMBED" : "IQ";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DOC_SUFFIX: string | undefined = IS_EMBEDDED ? ".embedded" : undefined;

/** this constant refers to the auth service where you can get the tenant, user, etc. */
const AuthService = AuthServiceProvider.getService();

/** this function is used for testing purposes only to set the environment value.
 *  @param env the value for the ENV variable. */
export function setDragAndDropEnv(env: string): void {
    ENV = env;
}

/** this interface defines the properties that are passed into this function component. */
export interface DragAndDropPanelProps {
    /** the NodeLibrary which has the nodes to be displayed in this panel. */
    nodeLibrary: NodeLibrary;
    /** the subflows that can be used to build a new flow. */
    subflows: Array<RunbookNode>;
    /** CSS class to be applied on the outer container of DragAndDrop panel. */
    className?: string;
    /** the GraphDef object with the current graph nodes and edges. */
    graphDef?: GraphDef;
    /** a string array with the categories that need to be expanded initially. */
    defaultExpandedCategories?: string[];
    /** Callback for when user double-clicks a node. */
    onNodeDoubleClicked?: (node: NodeLibraryNode) => void;
    /** if true hide the editor, if false, display it.   When hidden all subcomponents that utilize a popover need 
     *  to have the popover hidden as well. */
    editorHidden?: boolean;
    /** current runbook trigger type used for variables */
    runbookTriggerType?: string;
    /** runtime variables changes handler */
    onRuntimeOrSubflowVariableEdited?: (updatedVariablesList) => void;
    /** incident variables changes handler */
    onIncidentVariableEdited?: (updatedVariablesList) => void;
    /** a boolean value, if true show the variable popover. */
    isVariablePopoverOpen?: boolean;
    /** the handler for variable popover closed events. */
    onVariablePopoverClosed?: (open: boolean) => void;
    /** the runbook variant, incident or lifecycle. */
    variant?: Variant;
    /** an optional set of integrations that is used to categorize the integration subflows. */
    integrations?: RunbookIntegrationDetails[];
}

/** specifies whether to show the control to launch the variable editor or if false it is controlled elsewhere. */
export const SHOW_VARIABLE_HEADER: boolean = false;

/** Renders the graph drag and drop panel.
 *  @param props the properties passed in.
 *  @returns JSX with the graph drag and drop panel component.*/
export default function DragAndDropPanel(props: DragAndDropPanelProps): JSX.Element {
    const [openedCategories, setOpenedCategoriesState] = useState<Array<string>>(props.defaultExpandedCategories || []);
    const openedCategoriesRef = useRef<string[]> ([]);
    function setOpenedCategories(categories: string[]): void {
        openedCategoriesRef.current = categories;
        setOpenedCategoriesState(categories);
    }

    //const [openedIntegrations, setOpenedIntegrations] = useState<Array<string>>([]);
    const [filterValue, setFilterValue] = useState<string>('');
    const [variablesChanges, setVariablesChanges] = useState(false);

    const appInsightsContext = useAppInsightsContext();

    let initDialogState: DialogState = { showDialog: false, title: "My Dialog", loading: false, dialogContent: null, dialogFooter: null };
    // A reference to the dialog state so we can check it during async dialog operations
    const dialogStateRef = useRef<DialogState>(initDialogState);
    // The current dialog state
    const [dialogState, setDialogStateUseState] = useState<DialogState>(initDialogState);
    // A wrapper around the useState set state function so we can record the dialogStateRef with the latest state.
    function setDialogState(dState: DialogState): void {
        dialogStateRef.current = dState;
        setDialogStateUseState(dState);
    };

    let triggerKey: string = "triggers";
    switch (props.variant) {
        case Variant.INCIDENT:
            triggerKey = "triggers";
            break;
        case Variant.LIFECYCLE:
            triggerKey = "lifecycleTriggers";
            break;
        case Variant.ON_DEMAND:
            triggerKey = "triggers";
            break;
        case Variant.SUBFLOW:
            triggerKey = "subflowTriggers";
            break;
    };

    const guidedHintsStoryboard: HintInfo[] = useMemo(() => {
        if (props.variant) {
            let hintsStoryboard: HintInfo[] = [
                {
                    hintKey: triggerKey, 
                    hint: STRINGS.runbookEditor.dnd.hints[
                        props.variant === Variant.SUBFLOW ? "subflowTriggers" : 
                        props.variant === Variant.ON_DEMAND ? "onDemandTriggers" : "triggers"
                    ], 
                    setStringAsMarkup: true,
                    beforeShow: () => setOpenedCategories([triggerKey]),
                }
            ];
            if (props.variant !== Variant.LIFECYCLE) {
                hintsStoryboard = hintsStoryboard.concat([
                    {
                        hintKey: "dataOcean", hint: STRINGS.runbookEditor.dnd.hints.dataOcean, setStringAsMarkup: true,
                        beforeShow: () => setOpenedCategories(["dataOcean"])
                    }    
                ]);
            }
            if (props.variant !== Variant.LIFECYCLE && props.variant !== Variant.SUBFLOW) {
                hintsStoryboard = hintsStoryboard.concat([
                    {
                        hintKey: "rvbdCharts", hint: STRINGS.runbookEditor.dnd.hints.rvbdCharts, setStringAsMarkup: true,
                        beforeShow: () => {
                            setOpenedCategories(["rvbdCharts"]);
                        },
                        onHide: () => {
                            if (props.variant !== Variant.INCIDENT && openedCategoriesRef.current.includes("rvbdCharts")) {
                                setOpenedCategories([]);
                            }
                        }
                    }
                ]);
            }
            if (props.variant === Variant.INCIDENT) {
                hintsStoryboard = hintsStoryboard.concat([
                    {
                        hintKey: "impactAssessment", hint: STRINGS.runbookEditor.dnd.hints.impactAssessment, setStringAsMarkup: true,
                        beforeShow: () => setOpenedCategories(["impactAssessment"]), 
                        onHide: () => {
                            if (openedCategoriesRef.current.includes("impactAssessment")) {
                                setOpenedCategories([]);
                            }
                        }
                    }
                ]);
            }
            if (props.variant === Variant.SUBFLOW) {
                hintsStoryboard = hintsStoryboard.concat([
                    {
                        hintKey: "subflowOutputs", hint: STRINGS.runbookEditor.dnd.hints.subflowOutput, setStringAsMarkup: true,
                        beforeShow: () => {
                            setOpenedCategories(["subflowOutputs"]);
                        }, 
                        onHide: () => {
                            if (openedCategoriesRef.current.includes("subflowOutputs")) {
                                setOpenedCategories([]);
                            }
                        }
                    }
                ]);
            }
            return hintsStoryboard;
        }
        return [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.variant, openedCategoriesRef.current]);

    const hintStore = useGuidedHintsStore({
        hints: guidedHintsStoryboard,
        showHintStoryUserPreferenceKey: HINTS_HIDDEN_USER_PREFS_KEY.runbookEditor,
    });
    const userPreferences = useUserPreferences({
        listenOnlyTo: {
            [HINTS_HIDDEN_USER_PREFS_KEY.runbookEditor]: ""
        }
    });
    const hideHints = userPreferences ? userPreferences[HINTS_HIDDEN_USER_PREFS_KEY.runbookEditor] === "false" : true;
    const nodesWithHintsMap = guidedHintsStoryboard.reduce((output, hint) => {
        output[hint.hintKey] = true;
        return output;
    }, {});

    useEffect(() => {
        if (!hideHints) {
            const showTriggerWarning = !hasTriggerNode;
            const showDoWarning = !showTriggerWarning && !hasDoNode && props.variant !== Variant.LIFECYCLE;
            const showChartWarning = !showTriggerWarning && !showDoWarning && !hasChartNode && ![Variant.SUBFLOW, Variant.LIFECYCLE].includes(props.variant || Variant.INCIDENT);
            const showImpactWarning = !showTriggerWarning && !showDoWarning && !showChartWarning && !hasImpactNode && props.variant === Variant.INCIDENT;
            const showSubflowOutputWarning = !showTriggerWarning && !showDoWarning && !hasSubflowOutputNode && props.variant === Variant.SUBFLOW;

            let warningCategory: string | undefined = undefined;
            if (showTriggerWarning || showDoWarning || showChartWarning || showImpactWarning || showSubflowOutputWarning) {
                warningCategory = (showTriggerWarning ? triggerKey : (showDoWarning ? "dataOcean" : 
                    (showChartWarning ? "rvbdCharts" : showImpactWarning ? "impactAssessment" : "subflowOutputs")));
                if (hintStore.activeHint?.hintKey !== warningCategory) {
                    // Manually choose the hint to display
                    hintStore.showHint(warningCategory);
                }
            } else {
                hintStore.resetHints();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.graphDef]);

    // Custom user guiding logic
    let hasTriggerNode = false, hasDoNode = false, hasChartNode = false, hasImpactNode = false, hasSubflowOutputNode = false;
    if (props.graphDef && props.graphDef.nodes) {
        for (const node of props.graphDef.nodes) {
            if (triggerNodes.includes(node.type)) {
                hasTriggerNode = true;
            } else if (dataOceanNodes.includes(node.type)) {
                hasDoNode = true;
            } else if (chartNodes.includes(node.type)) {
                hasChartNode = true;
            } else if (tagNodes.includes(node.type)) {
                hasImpactNode = true;
            } else if (subflowOutputNodes.includes(node.type)) {
                hasSubflowOutputNode = true;
            }
        }
    }

    const nodes: Array<JSX.Element> = [];
    let dividerIndex = 0;

    if (props.nodeLibrary && props.nodeLibrary.getNodeSpecification().categories) {
        // Add the subflows to the library
        const fullLibrary: NodeLibrarySpec = JSON.parse(JSON.stringify(props.nodeLibrary.getNodeSpecification()));
        const subflows: RunbookNode[] = deduplicateSubflows(props.subflows);
        if (subflows) {
            const { builtInSubflows, nonBuiltInSubflows } = subflowOrderingInNodePalette(subflows);
            const sortedSubflows = [...builtInSubflows, ...nonBuiltInSubflows];
            const subflowCategories: Record<string, NodeLibraryCategory> = {};
            for (const subflow of sortedSubflows) {
                const integrationId: string = subflow.integrationId || "";
                const connectorIntegrationDetails: RunbookIntegrationDetails | undefined = (props.integrations || []).find(integration => integration.id === integrationId);
                const connectorName: string = connectorIntegrationDetails?.name || integrationId;
                const subflowCategoryName: string = connectorName || STRINGS.runbookEditor.nodeLibrary.categories.subflows!;

                if (!subflowCategories[subflowCategoryName]) {
                    subflowCategories[subflowCategoryName] = { name: subflowCategoryName, color: "#ffffff", nodes: [] };
                }
                let wires: NodeWiresSetting = {
                    direction: DIRECTION.NONE, in: subflow.in, inputLabels: subflow.inputLabels,
                    out: subflow.out, outputLabels: subflow.outputLabels
                };
                if ((subflow?.in?.length || 0) > 0 && (subflow?.out?.length || 0) > 0) {
                    wires.direction = DIRECTION.BOTH;
                } else if ((subflow?.in?.length || 0) > 0) {
                    wires.direction = DIRECTION.IN;
                } else if ((subflow?.out?.length || 0) > 0) {
                    wires.direction = DIRECTION.OUT;
                }
                const subflowNode: NodeLibraryNode = {
                    //type: "subflow:" + subflow.id, 
                    type: "subflow", 
                    icon: subflow.icon,
                    subflowName: subflow.name || "unknown", 
                    subflowId: subflow.id, 
                    subflowBuiltIn: subflow.builtIn, 
                    subflowDescription: subflow.info,
                    wires, 
                    color: subflow.color || DEFAULT_SUBFLOW_COLOR, 
                    uiAttrs: {showDebug: true},
                    integrationId: integrationId,
                    integrationInfo: {
                        id: integrationId, 
                        icon: connectorIntegrationDetails?.branding?.icons?.find(icon => icon.type === "avatar")?.svg,
                        name: connectorIntegrationDetails?.name || "",
                        primaryColor: connectorIntegrationDetails?.branding?.primaryColor || DEFAULT_SUBFLOW_COLOR,
                        secondaryColor: connectorIntegrationDetails?.branding?.secondaryColor
                    },
                    properties: [
                        // we moved this up to the parent
                        //{"name": "info", "label": "Description", "type": "textarea", "default": ""}
                        // This was not there
                        {name: "debug", type: "boolean", label: "debug", default: false},
                        {name: "configurationId", label: "configurationId", type: "hidden", default: subflow.id},
                        {name: "in", label: "in", type: "hidden", default: (subflow.inputVariables || []).map(variable => {return {inner: variable.name, outer: ""}})},
                        {name: "out", label: "out", type: "hidden", default: (subflow.outputVariables || []).map(variable => {return {inner: variable.name, outer: ""}})}
                    ]
                };
                // This was there
                //if (subflow.properties) {
                //    for (const property in subflow.properties) {
                //        subflowNode.properties!.push({name: property, label: "label", type: "custom", default: subflow.properties[property]});
                //    }
                //}
                subflowCategories[subflowCategoryName].nodes!.push(subflowNode);
            }
            if (STRINGS.runbookEditor.nodeLibrary.categories.subflows in subflowCategories) {
                // We want subflows to appear ahead of integrations
                fullLibrary.categories!.push(subflowCategories[STRINGS.runbookEditor.nodeLibrary.categories.subflows]);
            }
            for (const catName in subflowCategories) {
                if (catName === STRINGS.runbookEditor.nodeLibrary.categories.subflows) {
                    // We already handled the subflow category
                    continue;
                }
                fullLibrary.categories!.push(subflowCategories[catName]);
            }
        }

        const reorderCategoriesAfterSubflows = (categories: NodeLibraryCategory[]) => {
            const subflowsIndex = categories.findIndex(item => item.name === "Subflows");
          
            if (subflowsIndex === -1 || subflowsIndex === categories.length - 1) {
              return categories;
            }
          
            const beforeSubflows = categories.slice(0, subflowsIndex + 1);
            const afterSubflows = categories.slice(subflowsIndex + 1);
          
            afterSubflows.sort((a, b) => a.name.localeCompare(b.name));
          
            return beforeSubflows.concat(afterSubflows);
        };

        /* Order alphabetically all categories displayed after the Subflows category */
        if (fullLibrary?.categories?.length) {
            fullLibrary.categories = reorderCategoriesAfterSubflows(fullLibrary.categories);
        }

        for (const libraryCategory of fullLibrary.categories!) {
            if (!isCategoryAllowed(libraryCategory, IS_EMBEDDED)) {
                continue;
            }

            const categoryDisplayName = STRINGS.runbookEditor.nodeLibrary.categories[libraryCategory.name] ?
                STRINGS.runbookEditor.nodeLibrary.categories[libraryCategory.name] : libraryCategory.name;

            // Show the category as open if user is typing a filter or if it is included in the 'openedCategories' list
            const categoryOpen = openedCategories.includes(libraryCategory.name) || filterValue !== '';
            const libraryCategoryHeader = <InlineHelp
                className={"categories"}
                key={libraryCategory.name}
                helpInfo={STRINGS.runbookEditor.nodeLibrary.nodes[libraryCategory.name.replace('.', '_')]?.help}>
                <div
                    className={"d-inline-block clickable my-2 category-wrapper category-" + (categoryOpen ? "open" : "closed")}
                    key={libraryCategory.name + "-" + (categoryOpen ? "open" : "closed")}
                    onClick={() => {
                        if (categoryOpen) {
                            openedCategories.splice(openedCategories.indexOf(libraryCategory.name), 1);
                            const newAr = [].concat(openedCategories as any);
                            setOpenedCategories(newAr);
                        } else {
                            setOpenedCategories([libraryCategory.name].concat(openedCategories))
                        }
                    }}
                >
                    <Icon icon={categoryOpen ? APP_ICONS.SECTION_OPEN : APP_ICONS.SECTION_CLOSED} />
                    <span className="display-8 text-capitalize">{categoryDisplayName}</span>
                </div>
            </InlineHelp>;

            if (libraryCategory.nodes && categoryOpen) {
                const nodesSection: Array<JSX.Element> = [];
                for (const rawLibraryNode of libraryCategory.nodes) {
                    // Get the library node from the library node cache.  Note that the subflow nodes
                    // are not cached so we continue if there is no cached node otherwise the subflows are 
                    // not displayed and cannot be added to the graph
                    let libraryNode = props.nodeLibrary.getNode(rawLibraryNode.type, undefined, libraryCategory.name);
                    libraryNode = libraryNode ? libraryNode : rawLibraryNode;

                    const subTypes: Array<NodeLibrarySubType> = libraryNode.subTypes ? libraryNode.subTypes : [{ subType: "", defaults: [] as Array<SubTypeDefault> }];
                    for (const subTypeConfig of subTypes) {
                        const { subType } = subTypeConfig;
                        const finalNodeConfig: NodeLibraryNode = props.nodeLibrary.getNode(rawLibraryNode.type, (subType === "" ? undefined : subType), libraryCategory.name) || rawLibraryNode;
                        if (finalNodeConfig && isNodeAllowed(finalNodeConfig, IS_EMBEDDED)) {
                            const resources = NodeLibrary.getNodeResourceStrings(libraryNode.type, subType);

                            // Logic to render the node's name
                            let name = libraryNode.subflowName || resources.name || libraryNode.type;
                            const [nodeName, subText] = splitName(name);
                            // Add the nodes if either the user is not filtering the nodes or if the node name matches user's filter excluding dividers
                            if (filterValue === "" || (String(nodeName).toLowerCase().includes(filterValue.toLowerCase()) &&
                                subType !== "divider" && rawLibraryNode.type !== "divider")) {
                                const nameElement = subText ? <div>{nodeName}<div className="display-10">{subText}</div></div> : name;

                                // Icon for the node if it exists
                                const icon = finalNodeConfig.icon || "";

                                if (subType === "divider" || rawLibraryNode.type === "divider") {
                                    nodesSection.push(<hr className="my-2 ml-3 invisible" key={"divider-" + (dividerIndex++)} />);
                                } else {
                                    const type = getReactFlowNodeType(finalNodeConfig);
                                    const node = {
                                        type: type,
                                        data: {
                                            type: finalNodeConfig.type,
                                            subType: subType,
                                            label: name,
                                            info: type === "subflow" ? finalNodeConfig.subflowDescription || "" : "",
                                            color: finalNodeConfig.color,
                                            wires: finalNodeConfig.wires,
                                            icon: finalNodeConfig?.icon || "",
                                            integrationInfo: finalNodeConfig?.integrationInfo,
                                            properties: finalNodeConfig.properties?.map(prop => ({
                                                key: prop.name,
                                                value: prop.default,
                                            })),
                                        }
                                    };

                                    let nodeHelpText = resources.help || finalNodeConfig.subflowDescription || "";
                                    let nodeHelpLink = updateEmbedHelpLink(resources.docLink || "", IS_EMBEDDED);
                                    const nodeHelp = (nodeHelpText !== "" ?
                                        <div className="d-flex flex-column p-2 w-max-3">
                                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(nodeHelpText) }} />
                                            {nodeHelpLink ?
                                                <Button
                                                    className="p-1 ml-auto"
                                                    icon="more"
                                                    minimal
                                                    onClick={
                                                        () => {
                                                            window.open(`${HELP_URI}/${DOC_ROOT}/Content/${nodeHelpLink}`);
                                                            if (appInsightsContext) {
                                                                const appInsightsProps = {
                                                                    name: EventNames.HELP_INVOKED,
                                                                    properties: {
                                                                        topic: `/${DOC_ROOT}/Content/${nodeHelpLink}`,
                                                                        type: HelpSources.NODE
                                                                    }
                                                                };
                                                                trackEvent(appInsightsContext, AuthService, appInsightsProps);
                                                            }
                                                        }
                                                    } />
                                                : null
                                            }
                                        </div> : null
                                    );
                                    nodesSection.push(
                                        <div className={"draggable-node react-flow__node-default " + type + " node-wrapper ml-4 my-1"}
                                            style={{ backgroundColor: finalNodeConfig.color || libraryCategory.color }}
                                            key={finalNodeConfig.type + subType + (finalNodeConfig.subflowId ? finalNodeConfig.subflowId : "")}
                                            draggable
                                            onDragStart={(event: DragEvent) => {
                                                const isIntegrationNode = (node as any)?.data?.hasOwnProperty('icon') && typeof (node as any).data.icon !== "string";
                                                const parsedNode =  isIntegrationNode ? 
                                                                        {...node, data: {...node.data, icon: 'SUBFLOW', integrationId: finalNodeConfig.integrationId}} : 
                                                                        node;

                                                onDragStart(event, parsedNode);
                                                // Adding this class to the body because the displayed hint will be using a portal
                                                // and so will not be under the current element's DOM tree
                                                document.body.classList.add("drag-in-progress");
                                            }}
                                            onDragEnd={(event: DragEvent) => onDragEnd(event, node)}
                                            onDoubleClick={event => {
                                                if (props.onNodeDoubleClicked) {
                                                    props.onNodeDoubleClicked(node);
                                                }
                                            }}
                                        >
                                            <div className="contents">
                                                <div className="icon-and-label">
                                                    {/* Subflow with Integration have custom svg as icon */}
                                                    {libraryNode?.integrationId && <>{libraryNode?.icon}</>}
                                                    
                                                    {/* Other Subflows use Standard Icons */}
                                                    {!libraryNode?.integrationId && libraryNode?.icon && <Icon className="icon flex-grow-0" icon={SDWAN_ICONS[icon as string] || APP_ICONS[icon as string] || icon as string} />}
                                                    <span className="name flex-grow-1 text-center">{nameElement}</span>
                                                    {finalNodeConfig.subflowBuiltIn && <Icon className="icon flex-grow-0 builtin-subflow-icon" icon={APP_ICONS["LOCK"]} />}
                                                    {
                                                        nodeHelp &&
                                                        <div
                                                            className="tooltip-holder position-relative d-flex align-items-center"
                                                            style={{ left: "5px", width: "0px" }}
                                                        >
                                                            <Popover2
                                                                className={Classes.TOOLTIP2_INDICATOR + " border-0"}
                                                                usePortal={true}
                                                                content={nodeHelp}
                                                                position={PopoverPosition.RIGHT}
                                                                transitionDuration={50}
                                                                onOpened={() => {
                                                                    if (appInsightsContext) {
                                                                        const appInsightsProps = {
                                                                            name: EventNames.HELP_POPUP_INVOKED,
                                                                            properties: {
                                                                                topic: `/${DOC_ROOT}/Content/${nodeHelpLink}`,
                                                                                type: HelpSources.NODE
                                                                            }
                                                                        };
                                                                        trackEvent(appInsightsContext, AuthService, appInsightsProps);
                                                                    }
                                                                }}
                                                            >
                                                                <Icon icon={IconNames.HELP} className="text-secondary" />
                                                            </Popover2>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            }
                        }
                    }
                }
                if (nodesSection && nodesSection.length > 0) {
                    nodes.push(libraryCategoryHeader);
                    const nodesSectionContainer = <div key={libraryCategory.name + "-nodes"}>{nodesSection}</div>;
                    // If there is a guided story available for this specific node, then wrap it
                    if (nodesWithHintsMap[libraryCategory.name]) {
                        nodes.push(<WrapInGuidedHint
                            key={libraryCategory.name + "-hint"}
                            hintStore={hintStore}
                            hintKey={libraryCategory.name}
                            className={"drag-and-drop-hint" + (props.editorHidden ? " d-none" : "")}
                        >{nodesSectionContainer}</WrapInGuidedHint>);
                    } else {
                        nodes.push(nodesSectionContainer);
                    }
                }
            } else if (filterValue === '') {
                nodes.push(libraryCategoryHeader);
            }
        }
    }

    const handleFilterChange = (event) => {
        setFilterValue(event.target.value);
    };

    const handleFilterClear = () => {
        const inputField = document.getElementById('nodesFilterInput') as HTMLInputElement;
        if (inputField) {
            inputField.value = '';
            setFilterValue('');
        }
    }

    const clearIcon = (
        <Button
            icon={IconNames.SMALL_CROSS}
            minimal={true}
            onClick={handleFilterClear}
        />
    );


    function closeVariablesEditor() {
        setDialogState({
            showDialog: true,
            title: STRINGS.runbookEditor.unsavedVariablesChangesTitle,
            dialogContent: STRINGS.runbookEditor.unsavedVariablesChangesWarning,
            closeable: false,
            dialogFooter: <div className="d-flex justify-content-between flex-grow-1">
                <Button
                    className="ml-0"
                    text={STRINGS.runbookEditor.discardAndCloseBtn}
                    intent={Intent.DANGER}
                    onClick={() => {
                        setDialogState({ ...dialogState, showDialog: false, closeable: true });
                        if (props.onVariablePopoverClosed) {
                            props.onVariablePopoverClosed(!props.isVariablePopoverOpen);
                        }
                        setVariablesChanges(false);
                    }}
                />
                <Button
                    icon={IconNames.SAVED}
                    text={STRINGS.runbookEditor.keepEditing}
                    intent={Intent.SUCCESS}
                    onClick={() => {
                        setDialogState({ ...dialogState, showDialog: false, closeable: true });
                        setVariablesChanges(false);
                    }}
                />
            </div>,
        } as DialogState);
    }

    return (
        <>
            <BasicDialog dialogState={dialogState} onClose={() => setDialogState(updateDialogState(dialogState, false, false, []))} />
            <aside id="drag-and-drop-panel" className={"d-flex flex-column" + (props.className ? " " + props.className : "")}>
                <Popover2
                    portalClassName="variables-container-portal"
                    minimal={true}
                    isOpen={props.isVariablePopoverOpen}
                    onInteraction={(_nextOpenState, e) => {
                        if (e && ((e.type === 'click' && e?.currentTarget?.closest(".variables-container-portal")) ||
                            (e.type === 'mousedown' && (e?.target as Element).classList.contains('bp3-button-text')))) {
                            // Clicked inside variables container or click on save button from incident dialog
                        } else if (e && (e.type === 'mousedown' && (e?.target as Element)?.closest('#variables-icon'))) {
                            // Clicked on the variables icon
                            if (variablesChanges) {
                                closeVariablesEditor();
                            }
                        } else if (e) {
                            /* istanbul ignore next */
                            // Clicked outside variables container
                            const isLeftClick = e.type === 'mousedown' && (e as React.MouseEvent<HTMLButtonElement>).button === 0 

                            if (!isLeftClick) {
                                return
                            }
                            
                            if (variablesChanges) {
                                closeVariablesEditor();
                            } else {
                                if (props.onVariablePopoverClosed) {
                                    props.onVariablePopoverClosed(!props.isVariablePopoverOpen);
                                }
                                setVariablesChanges(false);
                            }
                        }
                    }}
                    content={<VariableDefinitionView
                        onRuntimeOrSubflowVariableEdited={(event) => {
                            if (props.onRuntimeOrSubflowVariableEdited) {
                                props.onRuntimeOrSubflowVariableEdited(event);
                            }
                        }}
                        onIncidentVariableEdited={(event) => {
                            if (props.onIncidentVariableEdited) {
                                props.onIncidentVariableEdited(event);
                            }
                        }}
                        onVariableChanges={(event) => {
                            setVariablesChanges(event);
                        }}
                        onClose={() => {
                            if (props.onVariablePopoverClosed) {
                                props.onVariablePopoverClosed(!props.isVariablePopoverOpen);
                            }
                            setVariablesChanges(false);
                        }}
                        isVariablePopoverOpen={props.isVariablePopoverOpen}
                        runbookTriggerType={props.runbookTriggerType} 
                        variant={props.variant} />}
                    placement="right-start"
                    modifiers={{
                        'offset': {
                            enabled: true,
                            options: {
                                offset: [-8, 8]
                            }
                        }
                    }}
                >
                    <div className="variable-definitions-trigger" style={SHOW_VARIABLE_HEADER ? {} : { display: "none" }}>
                        <div>{/* Empty div for alignment */}</div>
                        <span>{STRINGS.runbookEditor.variableDefinitions.label}</span>
                        <Icon icon={APP_ICONS.SECTION_CLOSED}></Icon>
                    </div>
                </Popover2>
                <IconTitle
                    icon={SDWAN_ICONS.RUNBOOK}
                    size={SIZE.m}
                    title={STRINGS.formatString(STRINGS.runbookEditor.dnd.title, {variant: STRINGS.runbookEditor.runbookTextForVariantUc[props.variant || Variant.INCIDENT]})}
                    subText={STRINGS.runbookEditor.dnd.suggestText}
                    className="my-3 text-break"
                />
                <InputGroup
                    id={"nodesFilterInput"}
                    leftIcon="search"
                    rightElement={clearIcon}
                    onChange={handleFilterChange}
                    placeholder={STRINGS.incidents.impactSummaryView.search}
                    value={filterValue}
                />
                <div className="overflow-auto">
                    {nodes.length > 0 ? nodes : STRINGS.runbookEditor.noMatchingNodes}
                </div>
            </aside>
        </>
    );
}

/** the handler for drag start events.
*  @param event the DragEvent being handled.
*  @param nodeType the node type being dragged.*/
function onDragStart(event: DragEvent, node: Object): void {
    const nodeBoundingRectangle = event.currentTarget.getBoundingClientRect();
    event.currentTarget.classList.add("dragging");
    event.dataTransfer.setData('application/reactflow', JSON.stringify({
        node,
        offsets: {
            x: event.clientX - nodeBoundingRectangle.left,
            y: event.clientY - nodeBoundingRectangle.top,
        }
    }));
    event.dataTransfer.effectAllowed = 'move';
}

/** the handler for drag end event.
*  @param event the DragEvent being handled.
*  @param nodeType the node type being dragged.*/
function onDragEnd(event: DragEvent, node: Object): void {
    event.currentTarget.classList.remove("dragging");
}

/** This method contains logic that decides if a node category should be displayed or not
 *  @param category The node library category that that is being checked for
 *  @param isEmbedded a boolean value, true if the ui is embedded, false if not.
 *  @returns a boolean value that is true if the category is allowed, false otherwise. */
function isCategoryAllowed(category: NodeLibraryCategory, isEmbedded: boolean): boolean {
    let allowed = true;
    if (category.name === "config") {
        // As far as I can tell the user must never add config nodes to the visible graph
        allowed = false;
    } else if (
        category.embed !== undefined &&
        ((category.embed && category.embed !== isEmbedded) || (!category.embed && isEmbedded === true))
    ) {
        allowed = false;
    } else if (
        (category.env !== undefined && !category.env.includes(ENV)) ||
        category?.deprecated === true
    ) {
        allowed = false;
    } else if (category.triggerTypes !== undefined) {
        for (const triggerType of category.triggerTypes) {
            if (!getTriggerTypes().includes(triggerType)) {
                allowed = false;
                break;
            }
        }
    }
    return allowed;
}

/** This method contains logic that decides if a specific node should be displayed or not
 *  @param node The node library node definition for the node that that is being checked for.
 *  @param isEmbedded a boolean value, true if the ui is embedded, false if not.
 *  @returns a boolean value, true if the node can be displayed in the list, false otherwise. */
function isNodeAllowed(node: NodeLibraryNode, isEmbedded: boolean): boolean {
    let allowed = true;
    if (
        (node.uiAttrs?.env !== undefined && !node.uiAttrs?.env.includes(ENV)) ||
        node.uiAttrs?.deprecated === true
    ) {
        allowed = false;
    } else if (
        node.uiAttrs?.embed !== undefined &&
        ((node.uiAttrs?.embed && node.uiAttrs?.embed !== isEmbedded) || (!node.uiAttrs?.embed && isEmbedded === true))
    ) {
        allowed = false;
    } else if (dataOceanNodes.includes(node.type) && !isDataOceanNodeAllowed(node)) {
        allowed = false;
    } else if (node.uiAttrs?.triggerTypes !== undefined) {
        for (const triggerType of node.uiAttrs.triggerTypes) {
            if (!getTriggerTypes().includes(triggerType)) {
                allowed = false;
                break;
            }
        }
    }
    return allowed;
}

/** This method contains logic that decides if a specific data ocean node should be displayed or not.
 *  This checks a bunch of properties in the DO node to see if the node is supported.
 *  @param node The node library node definition for the node that that is being checked for
 *  @returns a boolean value, true if the node can be displayed in the list, false otherwise. */
function isDataOceanNodeAllowed(node: NodeLibraryNode): boolean {
    if (node.properties) {
        for (const property of node.properties) {
            if (property.name === "objType") {
                const objType = property.default;
                return DataOceanUtils.isObjectTypeSupported(objType, getTypes());
            }
        }    
    }
    return node.subType === "divider";
}

/** this function takes the URL in STRINGS and converts it to the emedded format.  We really should
 *      find a better way to do this.
 *  @param link a String with the URL link to the UA cotnent for the node.
 *  @param isEmbedded a boolean value, true if we are embedded, false otherwise.
 *  @returns the String in embedded format or the original string if not embedded. */
function updateEmbedHelpLink(link: string, isEmbedded): string {
    if (isEmbedded && link && link.endsWith(".htm")) {
        link = link.split(".htm")[0] + ".embedded.htm";
    }
    return link;
}
