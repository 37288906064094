/** This file defines the BarChart React component.  The BarChart React component renders a
 *  a basic bar chart with n-groups and one metric and alse renders a stacked bar chart with
 *  n-groups and m-metrics where the m-metrics must all be convertible to the same unit.
 *  @module */
import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Classes, Dialog } from '@blueprintjs/core';
import { CHART_COLORS } from 'components/enums';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { DEFAULT_BAR_CHART_OPTIONS } from 'components/reporting/charts/defaults/HighchartDefaults';
import { cloneDeep, merge } from 'lodash';
import { CHART_SERIES_COLORS } from 'components/enums';
import { Unit } from 'reporting-infrastructure/types/Unit.class';
import { precise, scaleMetric } from 'reporting-infrastructure/utils/formatters';
import { THEME, ThemeContext } from 'utils/themes';
import { BaseChartProps, GroupMetricSource } from '../chart-base/ChartBase';
import { STRINGS } from 'app-strings';
import { BarChartSettings, BarOrientation, ChartToolbar, ChartType, LegendPosition, ToolbarAction } from '../chart-base/ChartToolbar';
import 'components/common/chart-base/ChartBase.css'

// This is needed to enable the highcharts no data functionality
NoDataToDisplay(Highcharts);

// These are the default options that all bar charts should use as a starting point
const defaultOptions = DEFAULT_BAR_CHART_OPTIONS;

/** an interface that describes the properties that can be passed in to the bar chart component.*/
export interface BarChartProps extends BaseChartProps {
    /** an array of BarData with the data for each bar. */
    barData: Array<BarData>;
    /** an Array of metrics, that specify the metric for each stacked metric. */
    metrics: Array<string>;
    /** an Array of metrics ids, that specify the metric for each stacked metric. */
    metricIds?: Array<string>;
    /** an array with the units for each metric. */
    units: Array<Unit>;
    /** the BarChartSetings object with the basic settings for the chart such as the orientation and legend position. */
    settings?: BarChartSettings;
    /** the suffix to use in the legend when displaying comparison data. */
    comparisonSuffix?: string;
}

/** an interface that describes the bar data format. */
export interface BarData {
    /** the label for the bar. */
    label: string;
    /** the values for the metrics. */
    values: Array<number>;
    /** the optional comparison values. */
    compValues?: Array<number>;
    /** the data that is passed when there is a selection. */
    group?: any;
}

/** Creates the the bar chart view.
 *  @param props an object with the properties passed to the bar chart view.
 *  @returns JSX with the bar chart component.*/
export const BarChart = (props: BarChartProps): JSX.Element => {
    const chartRef = useRef<HighchartsReact.RefObject>(null);
    const [isOpen, setIsOpen] = useState(false);
    const handleOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);
    const handleClose = useCallback(() => setIsOpen(false), []);

    const [settings, setSettings] = useState<BarChartSettings>(props.settings || {});

    const [widthsAndHeights, setWidthsAndHeights] = useState<Array<number>>([0, 0, 0, 0]);
    useEffect(
        () => {
            if (chartRef.current) {
                setWidthsAndHeights([
                    chartRef.current.chart.plotWidth, chartRef.current.chart.plotHeight,
                    chartRef.current.container.current?.clientWidth || 0, chartRef.current.container.current?.clientHeight || 0
                ]);
            }
            const resizeHandler = (event) => {
                if (chartRef?.current?.chart) {
                    chartRef.current.chart.reflow();
                    chartRef.current.chart.redraw();
                }
            };
            window.addEventListener('resize', resizeHandler);
            return () => {
                window.removeEventListener("resize", resizeHandler);
            }
        },
        []
    );

    let seriesData: Array<any> = [];
    let categories = [] as Array<string>;
    if (props.barData) {
        let numCols;
        for (const bar of props.barData) {
            numCols =
                numCols !== null && numCols !== undefined
                    ? Math.min(numCols, bar.values.length)
                    : bar.values.length;
        }
        for (let index = 0; index < props.barData.length; index++) {
            const bar: BarData = props.barData[index];
            categories.push(bar.label);
        }
        const seriesColors = props.seriesColors ? props.seriesColors : CHART_SERIES_COLORS;
        let colorIndex = 0;
        for (let col = 0; col < numCols; col++) {
            if (seriesColors[colorIndex] === undefined) {
                colorIndex = 0;
            }
            const chartColor = seriesColors[colorIndex++];

            // All data needs to be in the same units, for now convert everything down to the base unit
            const unit = props.units[col];
            const toUnit = unit?.clone();
            if (toUnit) {
                toUnit.prefix = '';
            }

            let series: any = {
                type: "column",
                color: chartColor,
                borderColor: 'transparent',
                name: props.metrics[col],
                data: [] as Array<any>
            };
            if (props.onGroupMetricSelection) {
                series.events = {
                    click: (event) => {
                        const selected = !event.point.selected;
                        event.point.select(selected, false);
                        props.onGroupMetricSelection!({
                            source: GroupMetricSource.SERIES,
                            selected,
//                            groups: [series.point.category],
                            groups: [event.point.groupData],
                            metrics: [event.point.metricData],
                        });
                    },
                    legendItemClick: (event) => {
                        props.onGroupMetricSelection!({
                            source: GroupMetricSource.LEGEND,
                            selected: true,
                            groups: [event.target.groupData],
                            metrics: [event.target.metricData],
                        });
                    },
                };
            }
            for (let index = 0; index < props.barData.length; index++) {
                const bar: BarData = props.barData[index];
                const barSeries: any = {
                    y: unit ? unit.convert(bar.values[col], toUnit) : bar.values[col],
                    unit: toUnit,
                    color: chartColor,
                    metric: props.metrics[col],
                    groupData: props.barData[index].group,
                    metricData: props.metricIds ? props.metricIds[col] : props.metrics[col]
                };
                if (props.comparisonSuffix && bar.compValues && bar.compValues.length === bar.values.length) {
                    barSeries.comparisonSuffix = props.comparisonSuffix;
                    barSeries.comparisonValue = unit ? unit.convert(bar.compValues[col], toUnit) : bar.compValues[col];
                }
                series.data.push(barSeries);
            }
            seriesData.push(series);
        }
    }

    const getChart = (popup: boolean = false) => {
        return (
            <ThemeContext.Consumer>
                {(ctx) => {
                    let bgColor: string | undefined = undefined;
                    if (chartRef.current?.container?.current?.parentElement) {
                        bgColor = getComputedStyle(chartRef.current.container.current.parentElement).getPropertyValue("background-color");
                    }
                    return <div aria-label="barChart card"
                        className={
                            popup ? Classes.DIALOG_BODY : 'flex barChart' + (props.transparent ? '' : ' bg-light') + (props.transparent || props.hideShadow ? '' : ' shadow') + (props.className ? ' ' + props.className : '')
                        }
                    >
                        {props.enableFullScreen && !popup && <ChartToolbar chartType={ChartType.bar}
                            controls={props.controls || []} settings={settings} notifyToolbarAction={(type: ToolbarAction, value: any) => {
                                switch (type) {
                                    case ToolbarAction.SHOW_FULL_SCREEN:
                                        handleOpen();
                                        break;
                                    case ToolbarAction.SETTINGS_CHANGED:
                                        setSettings(value);
                                        break;
                                }
                            }}
                        />}
                        <HighchartsReact
                            highcharts={Highcharts} immutable={true}
                            options={getChartOptions(
                                categories, seriesData, settings, ctx.theme === THEME.dark, props.options,
                                props?.units?.length ? props.units[0] : undefined, widthsAndHeights,
                                props.transparent === true, bgColor
                            )}
                            containerProps={{
                                style: {
                                    width: props.width ? props.width : '100%',
                                    height: popup ? (0.9 * window.innerHeight - 50) + "px" : props.height ? props.enableFullScreen ? parseInt(props.height, 10) - 15 + 'px' : props.height : '100%',
                                    padding: popup ? '10px' : '',
                                },
                            }}
                            ref={chartRef}
                        />
                    </div>;
                }}
            </ThemeContext.Consumer>
        );
    };

    return (
        <>
            <Dialog title={props.fullScreenTitle ? props.fullScreenTitle : ''} isOpen={isOpen} autoFocus={true} canEscapeKeyClose={true}
                canOutsideClickClose={true} enforceFocus={true} usePortal={true} onClose={handleClose}
                style={{ width: 0.75 * window.innerWidth, height: 0.9 * window.innerHeight }}
            >
                {getChart(true)}
            </Dialog>
            {getChart(false)}
        </>
    );
};

/** returns the bar chart options for the specified categories and series.
 *  @param categories a string array with the bar chart categories.  The array of groups that the bar is showing.
 *  @param series the data series to put in the bar chart.
 *  @param settings the BarChartSettings object with some of the settings for the chart like the orientation.
 *  @param darkMode a boolean which specifies whether dark mode is enabled.
 *  @param options additional options that should be merged into the chart options.
 *  @param unit the Unit for the data or undefined if none.
 *  @param widthsAndHeights the height of the charts container [plotWidth, plotHeight, contWidth, contHeight]
 *  @param transparent a boolean value, true if the chart should be transparent.
 *  @param bgColor a string with the background color.
 *  @returns the chart options for the specified categories and series.*/
function getChartOptions(
    categories: Array<string>,
    series: any,
    settings: BarChartSettings,
    darkMode: boolean = false,
    options: Highcharts.Options | undefined,
    unit: Unit | undefined,
    widthsAndHeights: Array<number>,
    transparent: boolean,
    bgColor: string | undefined
): Highcharts.Options {
    const {
        showBarLabels = false, orientation = BarOrientation.horizontal, showLegend = true,
        legendPosition = LegendPosition.top, stacked
    } = settings;

    let optionsCopy: Highcharts.Options = cloneDeep(defaultOptions);

    let toUnit = unit;
    if (toUnit) {
        toUnit = toUnit.clone();
        toUnit.prefix = "";
    }

    let legendLayout = "horizontal";
    let legendAlign = "left";
    let legendVerticalAlign = "top";
    switch (legendPosition) {
        case LegendPosition.top:
            // Defaults are set for top, nothing to do
            break;
        case LegendPosition.bottom:
            legendVerticalAlign = "bottom";
            break;
        case LegendPosition.left:
            legendLayout = "vertical";
            break;
        case LegendPosition.right:
            legendLayout = "vertical";
            legendAlign = "right";
            break;
    }

    merge(optionsCopy, {
        chart: {
            type: "column",
            backgroundColor: (transparent || !bgColor ? "transparent" : bgColor), //!darkMode ? "#f8f9fa" : "#2f3442",
            inverted: orientation === "vertical" ? false : true
        },
        //title: {
        //    text: 'Demo'
        //},
        legend: {
            enabled: showLegend,
            lineHeight: 8,
            symbolRadius: 0,
            layout: legendLayout,
            align: legendAlign,
            verticalAlign: legendVerticalAlign,
            floating: false,
            x: 0, //85
            y: 0,
            labelFormatter: function (this: any) {
                if (this && this.name) {
                    return this.name;
                    //                    return STRINGS.METRICS[this.name];
                }
            },
            itemStyle: {
                color: CHART_COLORS.LEGEND_DEFAULT,
            },
            itemHoverStyle: {
                color: CHART_COLORS.LEGEND_DEFAULT,
            },
        },
        xAxis: {
            categories: categories,
            lineColor: 'transparent',
            tickWidth: 0,
            borderColor: 'transparent',
            labels: {
                style: {
                    color: darkMode ? CHART_COLORS.LEGEND_DARKMODE : CHART_COLORS.LEGEND_DEFAULT,
                },
            },
        },
        yAxis: {
            gridLineColor: 'transparent',
            title: {
                text: toUnit && toUnit.unit !== "" ? "(" + toUnit.getDisplayName() + ")" : null,
                align: "middle"
            },
            grid: {
                enabled: false,
            },
            labels: {
                enabled: !showBarLabels,
                formatter: function (this: any) {
                    return scaleMetric(this.value, new Unit()).formatted;
                }
            },
            minTickInterval: 1,
            minorTicks: false,
            allowDecimals: false
        },
        tooltip: {
            useHTML: true,
            formatter: function (this: any) {
                const compText = getComparisonText(this.point.y, this.point);
                const symbol = '&#9632;';
                let toolTip = '';
                if (this.key && this.point && this.point.y !== null && this.point.y !== undefined && this.point.unit && this.point.color) {
                    toolTip =
                        '<div><span style="font-size:16px;color:' +
                        this.point.color +
                        '">' +
                        symbol +
                        '</span>' +
                        '<b><span> ' +
                        this.key +
                        '</span></b> : <b>' +
                        scaleMetric(this.point.y, this.point.unit).formatted +
                        '</b>' + compText + '</div>';
                }
                return toolTip;
            },
        },
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: showBarLabels,
                    animation: true,
                    rotation: orientation === "vertical" ? -90 : 0,
                    align: "left",
                    verticalAlign: "bottom",
                    x: 0,
                    y: orientation === "vertical" ? -10 : 10,
                    formatter: function (this: any) {
                        if (this.point && this.point.y && this.point.unit) {
                            return scaleMetric(this.point.y, this.point.unit).formatted;
                        }
                    },
                    style: {
                        color: darkMode ? CHART_COLORS.LABEL_DARKMODE : CHART_COLORS.LABEL_DEFAULT,
                    },
                },
            },
        },
        noData: {
            style: {
                color: darkMode ? CHART_COLORS.LABEL_DARKMODE : CHART_COLORS.LABEL_DEFAULT,
            }
        },
        series: series,
    });
    if (stacked) {
        if (series && series.length > 1) {
            // normal, overlap, precent, stream
            optionsCopy.plotOptions!.column!.stacking = 'normal';
        }
    } else {
        let maxPointWidth: number | undefined = 10;
        let groupPadding = 0.2;
        // The group padding specifies the space between the groups of bars.  If this
        // space is too small the bars become really large, so progressively increase
        // the space as the number of bars decrease to hold the size of the bars 
        // relatively constant.
        maxPointWidth = undefined;
        groupPadding = 0.15;
        switch (series.length) {
            case 3:
                groupPadding = 0.2;
                break;
            case 2:
                groupPadding = 0.3;
                break;
            case 1:
                groupPadding = 0.4;
                break;
        }
        optionsCopy.plotOptions!.column!.groupPadding = groupPadding;
        optionsCopy.plotOptions!.column!.pointPadding = 0;
        optionsCopy.plotOptions!.column!.maxPointWidth = maxPointWidth;
        optionsCopy.plotOptions!.column!.minPointLength = 3;
        const plotBarDimension = orientation === BarOrientation.horizontal ? widthsAndHeights[1] : widthsAndHeights[0];
        const containerBarDimension = orientation === BarOrientation.horizontal ? widthsAndHeights[3] : widthsAndHeights[2];
        if (containerBarDimension && plotBarDimension) {
            const numMetrics = series?.length || 0;
            const numGroups = series?.length && series[0].data?.length ? series[0].data.length : 0;
            const marginHeight = (containerBarDimension - plotBarDimension);
            const barWidth = numMetrics === 1 ? 10 : 6;
            const estChartHeight = numMetrics * numGroups * barWidth + 2 * numGroups * groupPadding * barWidth + marginHeight;
            //alert("Est Chart Height: " + estChartHeight + ", Cont Height: " + containerBarDimension + ", plotHeight: " + plotBarDimension);
            if (estChartHeight > containerBarDimension) {
                optionsCopy!.chart!.scrollablePlotArea = {
                    [orientation === BarOrientation.horizontal ? "minHeight" : "minWidth"]: estChartHeight,
                    [orientation === BarOrientation.horizontal ? "scrollPositionY" : "scrollPositionX"]: 0
                };
            }
        }
    }
    if (options) {
        merge(optionsCopy, options);
    }
    return optionsCopy;
}

/** returns a string with the html that contains the comparison text.
 *  @param value the value of bar.
 *  @param point the highcharts point object.
 *  @returns a String with the comparison text or empty string if none. */
function getComparisonText(value: any, point: any): string {
    const compSuffix = point.comparisonSuffix;
    const compValue = point.comparisonValue;
    let compText = "";
    let changeText = "";
    if (compValue !== null && compValue !== undefined) {
        compText = '<br /><b><span>' + compSuffix + '</span></b> : <b>' + scaleMetric(compValue, point.unit).formatted + '</b>';
        if (
            value !== undefined && compValue !== undefined &&
            !Number.isNaN(value) && !Number.isNaN(compValue)
        ) {
            let pctChange: number | undefined = undefined;
            if (compValue !== 0) {
                pctChange = 100 * ((value - compValue) / compValue);
            } else if (value !== 0) {
                // We have number / 0 which is infinity
                pctChange = value > 0 ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
            } else if (compValue === 0 && value === 0) {
                // We have 0 / 0, that is undefined, but that really is no change
                pctChange = 0;
            }
            if (pctChange !== undefined) {
                const arrow = pctChange > 0 ? '&uarr;' : pctChange < 0 ? '&darr;' : '';
                pctChange = pctChange < 0 ? -1.0 * pctChange : pctChange; 
                pctChange = Math.min(pctChange, 1000);
                changeText = '<br /><b><span>' + 
                    STRINGS.incidents.runbookOutputs.changeValueTooltipLabel + 
                    '</span></b> : <b>' + arrow + ' ' + (pctChange >= 1000 ? "&gt; " : "") + precise(pctChange) + ' %</b>';    
            }
        }
    }
    return compText + changeText;
}
