import { useState, useEffect, useRef } from 'react';
import React from 'react';
import { Button, Position, Menu, MenuItem, ButtonGroup } from '@blueprintjs/core';
import { Popover2, Popover2InteractionKind } from '@blueprintjs/popover2';
import { ThirdPartyIntegrationService } from 'utils/services/ThirdPartyIntegrationApiService';
import { Table, IconNames, TableColumnDef } from '@tir-ui/react-components';
import { openModal, openConfirm } from 'components/common/modal';
import { ProfileInterface } from "utils/services/ThirdPartyIntegrationApiService";
import { DataSourceService, CloudIMDataSource } from 'utils/services/DataSourceApiService';
import { DataLoadFacade } from 'components/reporting/data-load-facade/DataLoadFacade';
import { STRINGS } from 'app-strings';

import './CloudIMDataSourceView.scss';

type CloudIMDataSourceDisplay = {
    dataSourceId?: string,
    dataSourceName: string,
    authProfileId: string,
    authProfileName: string,
}

export default function CloudIMDataSourceView() {

    const auth_profiles = useRef<ProfileInterface[]>([]);
    const [displayDataSources, set_displayDataSources] = useState<CloudIMDataSourceDisplay[]>([]);
    const [loading, set_loading] = useState<boolean>(true);
    const [error, set_error] = useState<boolean>(false);

    useEffect(() => {
        const initialize = async () => {
            try {
                set_loading(true);
                auth_profiles.current = await ThirdPartyIntegrationService.getAuthProfiles()
                await refreshDataSources();
            } catch (err) {
                console.error(err);
                set_error(true);
            }
        }
        initialize()
    }, []);

    const refreshDataSources = async () => {
        set_loading(true);
        var dataSources = await DataSourceService.getCloudIMDataSources()
        var displayDataSources = dataSources.map((ds) => {
            let pi = auth_profiles.current.find(ap => ap.id === ds.authProfileId)
            return {
                ...ds,
                authProfileName: pi ? pi.name : "N/A",
            };
        });
        set_displayDataSources(displayDataSources);
        set_loading(false);
    }

    const getColumns = (): Array<TableColumnDef> => {
        return [
            {
                id: 'data_source_name',
                Header: STRINGS.DATA_SOURCES.cloudimDataSources.columns.dataSource,
                accessor: 'data_source_name',
                className: 'ds-table-column',
            },
            {
                id: 'auth_profile_id',
                Header: STRINGS.DATA_SOURCES.cloudimDataSources.columns.authProfile,
                accessor: 'auth_profile_id',
                className: 'ds-table-column',
            },
            {
                id: 'more',
                Header: '',
                accessor: 'more',
                className: 'ds-table-column',
            }
        ];
    };

    const getMoreMenuItems = (
        ds: CloudIMDataSource
    ): Array<JSX.Element> => {
        return [
            <MenuItem
                disabled={false}
                data-testid={`${ds.dataSourceId}-popover-edit-button`}
                text={STRINGS.DATA_SOURCES.cloudimDataSources.menuItems.edit}
                active={false}
                key={'edit_cloudim_datasource'}
                onClick={() => {
                    openModal('cloudIMDataSourceModal', {
                        "auth_profiles": auth_profiles.current,
                        "data_source_name": ds.dataSourceName,
                        "auth_profile_id": ds.authProfileId,
                        "data_source_id": ds.dataSourceId,
                        "submit": ((newDataSource: CloudIMDataSource) => {
                            DataSourceService.updateCloudIMDataSource(newDataSource).then(() => {
                                refreshDataSources();
                            })
                                .catch((error) => {
                                    console.error(error);
                                })
                        })
                    });
                }}
            />,
            <MenuItem
                disabled={false}
                data-testid={`${ds.dataSourceId}-popover-delete-button`}
                text={STRINGS.DATA_SOURCES.cloudimDataSources.menuItems.delete}
                active={false}
                key={'delete_cloudim_datasource'}
                onClick={() => {
                    openConfirm({
                        message: STRINGS.DATA_SOURCES.cloudimDataSources.messages.deleteConfirmMsg,
                        onConfirm: async () => {
                            DataSourceService.deleteCloudIMDataSource(ds)
                                .then(() => {
                                    refreshDataSources()
                                })
                                .catch((err) => {
                                    console.error(err);
                                    set_error(true);
                                })
                        },
                        icon: IconNames.TRASH,
                    });

                }}
            />,
        ];
    };

    const openDataSourceConfigModal = async () => {
        openModal(
            'cloudIMDataSourceModal',
            {
                "auth_profiles": auth_profiles.current,
                "submit": ((ds: CloudIMDataSource) => {
                    DataSourceService.createCloudIMDataSource(ds)
                        .then(() => {
                            refreshDataSources()
                        })
                        .catch((err) => {
                            console.error(err);
                            set_error(true);
                        });
                })
            }
        );
    }

    /** used to create the nested items when a menu is toggled to open */
    const TabularMenuCell = (ds: CloudIMDataSource) => {
        return (
            <div onClick={(e) => { e.stopPropagation() }} data-testid={`${ds.dataSourceId}-cell`}>
                <Popover2
                    position={Position.BOTTOM_RIGHT}
                    interactionKind={Popover2InteractionKind.CLICK}
                    content={
                        <Menu>
                            {getMoreMenuItems(ds)}
                        </Menu>
                    }
                >
                    <Button
                        data-testid={`${ds.dataSourceId}-more-button`}
                        icon={IconNames.MORE}
                        minimal
                        className="cloudim-data-sources-action-icon"
                        disabled={false}
                    />
                </Popover2>
            </div>
        );
    };

    const getDisplayData = (data: CloudIMDataSourceDisplay[]): Array<any> => {
        const displayData =
            data && data.length > 0
                ? data.map((dds) => {
                    return {
                        data_source_name: dds.dataSourceName,
                        auth_profile_id: dds.authProfileName,
                        more: (
                            <TabularMenuCell {...dds} />
                        )
                    };
                })
                : [];
        return displayData;
    };

    return (
        <div>
            <DataLoadFacade loading={loading} key="cloudim_data_sources" showContentsWhenLoading={true} error={error}>
                <ButtonGroup className='mt-3'>
                    <Button
                        data-testid={'add-cloudim-datasource-button'}
                        // minimal
                        icon={IconNames.ADD}
                        // className="user-btn"
                        onClick={openDataSourceConfigModal}
                    >
                        {STRINGS.DATA_SOURCES.cloudimDataSources.dialog.button}
                    </Button>
                </ButtonGroup>

                <Table
                    id="placeholder-id"
                    columns={getColumns()}
                    data={getDisplayData(displayDataSources)}
                    enablePagination={false}
                    bordered={true}
                />
            </DataLoadFacade>

        </div>
    );
}
