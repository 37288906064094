const data = [
    {
        "user_name": "DVANNY",
        "process_id": "1428",
        "process_name": "svchost.exe",
        "app_name": "Firefox",
        "client_ip": "a1b6:ab0d:44d:4558:8fce:3122:49c8:1f57",
        "client_port": "64783",
        "server_ip": "ae80:913e:bb30:8246:a4d6:e3b1:eb88:bef9",
        "server_port": "387",
        "ip_protocol_type": "85",
        "machine_id": "bc3e5e8d-cc92-11ee-964b-5f18dd622fc4",
        "os_type": "Windows 11",
        "os_version": "22H2 - 19045.3324",
        "interface_name": "Ethernet0",
        "network_host": "160.10.100.106",
        "network_client": "192.70.1.111",
        "network_server": "224.0.0.251",
        "application": "SSL",
        "location": "Default-Internet",
        "client_location": "Default-Internet",
        "server_location": "Default-Internet",
        "network_interface": "10.108.0.10:20150",
        "network_device": "10.111.0.3",
        "protocol": "6",
        "dscp": "11"
    },
    {
        "user_name": "FSULLIVAN",
        "process_id": null,
        "process_name": null,
        "app_name": "Excel",
        "client_ip": "5943:a928:209f:5533:984b:2939:8773:4850",
        "client_port": "50102",
        "server_ip": "1723:3db9:c139:9c54:9675:854f:861b:d8a4",
        "server_port": "45",
        "ip_protocol_type": "146",
        "machine_id": "bc3e5e87-cc92-11ee-964b-5f18dd622fc4",
        "os_type": "MacOS 13",
        "os_version": null,
        "interface_name": null,
        "network_host": "160.10.100.105",
        "network_client": "192.70.1.110",
        "network_server": "192.70.0.2",
        "application": "SIP",
        "location": "Default-10.x.x.x",
        "client_location": "Default-10.x.x.x",
        "server_location": "Default-10.x.x.x",
        "network_interface": "10.106.0.13:2073",
        "network_device": "10.111.0.5",
        "protocol": "17",
        "dscp": "11"
    },
    {
        "user_name": "ASTONE",
        "process_id": null,
        "process_name": null,
        "app_name": "SQL Server",
        "client_ip": "d133:3a95:17c5:146:410:9142:15c6:aa56",
        "client_port": "45686",
        "server_ip": "9d68:2db4:f098:d9:31c4:134b:8c8b:64f5",
        "server_port": "291",
        "ip_protocol_type": "125",
        "machine_id": "bc3e5e8b-cc92-11ee-964b-5f18dd622fc4",
        "os_type": "Windows 10 Enterprise",
        "os_version": null,
        "interface_name": null,
        "network_host": "160.10.100.104",
        "network_client": "192.70.1.109",
        "network_server": "10.21.1.21",
        "application": "RTP",
        "location": "Default-Internet",
        "client_location": "Default-Internet",
        "server_location": "Default-Internet",
        "network_interface": "10.126.0.10:3000",
        "network_device": "10.111.0.4",
        "protocol": "50",
        "dscp": "11"
    },
    {
        "user_name": "JSMITH",
        "process_id": null,
        "process_name": null,
        "app_name": "Chrome",
        "client_ip": "a1b6:ab0d:44d:4558:8fce:3122:49c8:1f57",
        "client_port": "32013",
        "server_ip": "bc10:7820:9166:a32b:b1f3:d048:164a:3ffa",
        "server_port": "327",
        "ip_protocol_type": "109",
        "machine_id": "bc3e5e8c-cc92-11ee-964b-5f18dd622fc4",
        "os_type": "Fedora Linux 37",
        "os_version": null,
        "interface_name": null,
        "network_host": "160.10.100.103",
        "network_client": "192.70.1.108",
        "network_server": "1.1.1.1",
        "application": "MDNS",
        "location": "Default-Internet",
        "client_location": "Default-Internet",
        "server_location": "Default-Internet",
        "network_interface": "10.121.0.4:2214",
        "network_device": "10.111.0.2",
        "protocol": "1",
        "dscp": "11"
    },
    {
        "user_name": "DVANNY",
        "process_id": null,
        "process_name": null,
        "app_name": "Firefox",
        "client_ip": "a1b6:ab0d:44d:4558:8fce:3122:49c8:1f57",
        "client_port": "26407",
        "server_ip": "ae80:913e:bb30:8246:a4d6:e3b1:eb88:bef9",
        "server_port": "44",
        "ip_protocol_type": "236",
        "machine_id": "bc3e5e8a-cc92-11ee-964b-5f18dd622fc4",
        "os_type": "Windows 11",
        "os_version": null,
        "interface_name": null,
        "network_host": "160.10.100.10",
        "network_client": "192.70.1.107",
        "network_server": "2.2.2.2",
        "application": "DNS",
        "location": "Default-Internet",
        "client_location": "Default-Internet",
        "server_location": "Default-Internet",
        "network_interface": "10.108.0.29:2169",
        "network_device": "10.111.0.1",
        "protocol": "58",
        "dscp": "11"
    }
]

export default data;
