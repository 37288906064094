import React from "react";
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { ErrorToaster, SuccessToaster } from '@tir-ui/react-components';
import { STRINGS } from 'app-strings';
import { FormModal } from "components/common/modal";
import { InputField } from "components/common/form";
import { Label } from "@blueprintjs/core";
import * as yup from "yup";
import { useApolloClient } from "@apollo/client";

interface SetDataSourcesInput {
    dataSource: {
        id?: string;
        enabled: boolean;
        hostname: string;
        userName: string;
        password?: string;
        account?: {
            id?: number;
            name?: string;
        }
    }
}

export default function EdgeConfigModal(props) {
    const apolloClient = useApolloClient();

    const [setDataSource] = useMutation<any, SetDataSourcesInput>(
        loader('./set-datasource-mutation.graphql'),
        {
            onCompleted: (data) => {
                SuccessToaster({
                    message: STRINGS.DATA_SOURCES.atternityConfig.queryResponse.success,
                });
            },
            onError: (err) => {
                ErrorToaster({
                    message: STRINGS.DATA_SOURCES.atternityConfig.queryResponse.error,
                });
                console.error(err?.message);
            },
        }
    );

    const validationSchema = yup.object().shape({
        hostname: yup
            .string()
            .label('Hostname URL')
            .required(),
        username: yup
            .string()
            .label('Username')
            .required(),
        password: yup
            .string()
            .label('Password')
            .required(),
    });

    const formConfig = {
        initialValues: {
            hostname: props.hostname || '',
            username: props.userName || '',
            password: ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values: any) => {
            try {
                await setDataSource({
                    variables: {
                        dataSource: {
                            enabled: true,
                            hostname: values.hostname,
                            userName: values.username,
                            password: values.password
                        }
                    }
                }); 
                await apolloClient.refetchQueries({
                    include: "active",
                })
            } catch (error) {
                console.log(error)
            }
        }
    }

    return (
        <FormModal
            title={STRINGS.DATA_SOURCES.atternityConfig.dialog.title}
            formikProps={formConfig}
            customLabels={{
                submit: STRINGS.DATA_SOURCES.atternityConfig.dialog.ctas.submit,
                close: STRINGS.DATA_SOURCES.atternityConfig.dialog.ctas.cancel
            }}
            {...props}
        >
            <div className="row">
                <Label className="label-width col-3">
                    {STRINGS.DATA_SOURCES.atternityConfig.dialog.labels.hostname}
                </Label>
                <div className="col-md-8">
                    <InputField
                        className="w-100"
                        aria-label="custom-property-name-field"
                        placeholder={STRINGS.DATA_SOURCES.atternityConfig.dialog.placeholder.hostname}
                        name={"hostname"}
                        required={true}
                    />
                </div>
            </div>

            <div className="row">
                <Label className="label-width col-3">
                    {STRINGS.DATA_SOURCES.atternityConfig.dialog.labels.username}
                </Label>
                <div className="col-8">
                    <InputField
                        className="w-100"
                        aria-label="custom-property-name-field"
                        placeholder={STRINGS.DATA_SOURCES.atternityConfig.dialog.placeholder.username}
                        name={"username"}
                        required={true}
                    />
                </div>
            </div>

            <div className="row">
                <Label className="label-width col-3">
                    {STRINGS.DATA_SOURCES.atternityConfig.dialog.labels.password}
                </Label>
                <div className="col-8">
                    <InputField
                        type="password"
                        className="w-100"
                        aria-label="custom-property-name-field"
                        placeholder={STRINGS.DATA_SOURCES.atternityConfig.dialog.placeholder.password}
                        name={"password"}
                        required={true}
                    />
                </div>
            </div>
        </FormModal>
    )
}
