import React from 'react';
import {
	Button,
	Callout,
	IconName,
	Intent,
	Label,
	Menu,
	MenuItem,
	Position,
} from '@blueprintjs/core';
import DOMPurify from 'dompurify';
import { Popover2, Popover2InteractionKind } from '@blueprintjs/popover2';
import {
	Table,
	Icon,
	IconNames,
	TableColumnDef,
} from '@tir-ui/react-components';
import { parseTimeFromDAL } from 'utils/hooks';
import { ErrorInfo } from 'pages/riverbed-advisor/views/runbook-view/Runbook.type';
import { DataSourceStatus, DATASOURCE_STATUS } from './DataSourceStatus';
import { WrapInTooltip } from 'components/common/wrap-in-tooltip/WrapInTooltip';
import { ElapsedTimeFormatter } from 'reporting-infrastructure/utils/formatters/elapsed-time-formatter/elapsed-time-formatter';
import { dataSourceTypeOptions } from 'utils/services/DataSourceApiService';

import { STRINGS } from 'app-strings';

import './DataSourceView.scss';

const getColumns = (): Array<TableColumnDef> => {
	return [
		{
			id: 'source_name',
			Header: STRINGS.DATA_SOURCES.dataSourceView.columns.sourceName,
			accessor: 'source_name',
			headerClassName: 'text-nowrap bg-secondary text-white',
			className: 'ds-table-column',
		},
		{
			id: 'source_type',
			Header: STRINGS.DATA_SOURCES.dataSourceView.columns.sourceType,
			accessor: 'source_type',
			headerClassName: 'text-nowrap bg-secondary text-white',
			className: 'ds-table-column',
		},
		{
			id: 'source_hostname',
			Header: STRINGS.DATA_SOURCES.dataSourceView.columns.sourceHostname,
			accessor: 'source_hostname',
			headerClassName: 'text-nowrap bg-secondary text-white',
			className: 'ds-table-column',
		},
		{
			id: 'source_info',
			Header: STRINGS.DATA_SOURCES.dataSourceView.columns.sourceInfo,
			accessor: 'source_info',
			headerClassName: 'text-nowrap bg-secondary text-white',
			className: 'ds-table-column',
		},
		{
			id: 'souce_ingestion',
			Header: STRINGS.DATA_SOURCES.dataSourceView.columns.sourceIngestion,
			accessor: 'source_ingestion',
			headerClassName: 'text-nowrap bg-secondary text-white',
			className: 'ds-table-column',
		},
		{
			id: 'souce_enabled',
			Header: STRINGS.DATA_SOURCES.dataSourceView.columns.sourceEnabled,
			accessor: 'source_enabled',
			headerClassName: 'text-nowrap bg-secondary text-white',
			className: 'ds-table-column',
		},
		{
			id: 'more',
			Header: '',
			accessor: 'more',
			headerClassName: 'bg-secondary',
			className: 'ds-table-column',
		},
	];
};

export enum PreferredDataSource {
	APP_RESPONSE = 'APP_RESPONSE',
	NET_PROFILER = 'NET_PROFILER',
}

const DataSourceView = (props): JSX.Element => {
	const { data, preferredDataSource } = props;

	const tableId = 'DataSourcesTbl';

	const getMoreMenuItems = (
		dataSourceId: string,
		dataSourceName: string,
		dataSourceIp: string
	): Array<JSX.Element> => {
		return [
			<MenuItem
				disabled={false}
				text={STRINGS.DATA_SOURCES.dataSourceView.actions.edit}
				active={false}
				key={'edit_data_source'}
				onClick={() => props.editDataSource(dataSourceId)}
			/>,
			<MenuItem
				disabled={false}
				text={
					STRINGS.DATA_SOURCES.dataSourceView.actions
						.setDataSourceCredentials
				}
				active={false}
				key={'set_data_source_credentials'}
				onClick={() =>
					props.setDataSourceCredentials(
						dataSourceId,
						dataSourceName,
						dataSourceIp
					)
				}
			/>,
			<MenuItem
				disabled={false}
				text={STRINGS.DATA_SOURCES.dataSourceView.actions.delete}
				active={false}
				key={'delete_data_source'}
				onClick={() =>
					props.deleteDataSource(dataSourceId, dataSourceName)
				}
			/>,
		];
	};

	/** returns the k/v pairs with the error details.
	 *  @param error the ErrorInfo object with the error information.
	 *  @returns a map of the error details values to their keys.  The keys are referred to in the
	 *      error messages in the STRINGS file. */
	const getErrorDetails = (error: ErrorInfo): Record<string, string> => {
		const details: Record<string, string> = {};
		if (error?.innerError?.properties?.length) {
			for (const prop of error.innerError.properties) {
				// Replace any "." in the key with an __.  The localization API does not support
				// nested objects.
				details[prop.key.replace(/\./g, '__')] = prop.value;
			}
		}
		return details;
	};

	const getIngestionInfo = (source: any): JSX.Element => {
		let updateCredentialsButton;
		const statusDetails: Array<string> = [];
		if (
			String(source.status) === DATASOURCE_STATUS.FAILED &&
			source.info.details
		) {
			source.info.details.forEach((item, index) => {
				const code: string =
					item.code &&
					Object.keys(STRINGS.runbookOutput.errorsAndWarnings).includes(
						item.code
					)
						? item.code
						: 'GENERAL_ERROR';

				const details: Record<string, string> = getErrorDetails(item);
				let message = item.message;

				STRINGS.runbookOutput.errorsAndWarnings[code] &&
					(message = STRINGS.formatString(
						STRINGS.runbookOutput.errorsAndWarnings[code],
						details
					));

				if (item.code === 'AuthInvalidCredentials') {
					updateCredentialsButton = (
						<Button
							minimal
							className={`mt-2 ml-2`}
							icon={IconNames.KEY}
							onClick={() =>
								props.setDataSourceCredentials(
									source.id,
									source.name,
									source.hostname
								)
							}
							text={
								STRINGS.DATA_SOURCES.dataSourceView.actions
									.setDataSourceCredentials
							}
						/>
					);
				}

				source.info.details.length > 1
					? statusDetails.push(index + 1 + '. ' + message)
					: statusDetails.push(message);
			});
		}
		return (
			<React.Fragment>
				<div
					style={{
						display: 'table',
						height: '100%',
						marginBottom: '10px',
					}}
				>
					<span
						style={{
							display: 'table-cell',
							verticalAlign: 'middle',
							width: '10em',
							paddingRight: '10px',
						}}
					>
						{STRINGS.DATA_SOURCES.dataSourceView.lastReceivedData}:
					</span>
					{!source.state ||
					(source.type === dataSourceTypeOptions.AppResponse && //bug 21859
						preferredDataSource &&
						preferredDataSource !==
							PreferredDataSource.APP_RESPONSE) ? (
						<WrapInTooltip
							tooltip={
								STRINGS.DATA_SOURCES.dataSourceView.state
									.disabled
							}
						>
							<Icon
								icon={IconNames.PAUSE}
								intent={Intent.WARNING}
							/>
						</WrapInTooltip>
					) : source.lastReceivedData ? (
						<span>
							<ElapsedTimeFormatter
								time={parseTimeFromDAL(source.lastReceivedData)}
								showOriginal
								suffix={
									STRINGS.DATA_SOURCES.dataSourceView
										.elapsedSuffix
								}
							/>
						</span>
					) : (
						<div style={{ display: 'inline-block' }}>
							<Callout intent={Intent.WARNING}>
								{
									STRINGS.DATA_SOURCES.dataSourceView
										.retreiveErrorMessage
								}
							</Callout>
						</div>
					)}
				</div>
				{((source.type === dataSourceTypeOptions.AppResponse &&
					preferredDataSource &&
					preferredDataSource === PreferredDataSource.APP_RESPONSE) ||
					(source.type === dataSourceTypeOptions.NetProfiler &&
						preferredDataSource &&
						preferredDataSource ===
							PreferredDataSource.NET_PROFILER)) && (
					<div
						style={{ display: 'inline-block', fontWeight: 'bold' }}
					>
						<Label>
							{STRINGS.DATA_SOURCES.setDataSourceSettingsDialog
								.preferredLabel + ' '}
							<Button
								minimal
								small
								className={`bp3-button bp3-outlined ml-2`}
								onClick={() =>
									props.setDataSourceSettings(
										preferredDataSource
									)
								}
								text={
									STRINGS.DATA_SOURCES
										.setDataSourceSettingsDialog.actions
										.change
								}
							/>
						</Label>
					</div>
				)}
				{statusDetails.length > 0 && (
					<div style={{ display: 'inline-block' }}>
						<Callout intent={Intent.WARNING}>
							{
								<div
									dangerouslySetInnerHTML={{
										__html: DOMPurify.sanitize(
											'<p>' +
												statusDetails.join('</p><p>') +
												'</p>',
											{
												USE_PROFILES: {
													html: true,
												},
											}
										),
									}}
								/>
							}
						</Callout>
						<Button
							minimal
							className="mt-2"
							id="reconfigure-data-source"
							icon={IconNames.REFRESH as IconName}
							text={
								STRINGS.DATA_SOURCES.dataSourceView.actions
									.reconfigure
							}
							disabled={false}
							onClick={() =>
								props.reconfigureDataSource(source.id)
							}
						/>
						{updateCredentialsButton}
					</div>
				)}
			</React.Fragment>
		);
	};

	const getEnabledState = (source: any): JSX.Element => {
		return (
			<React.Fragment>
				{source.state ? (
					<WrapInTooltip
						tooltip={
							STRINGS.DATA_SOURCES.dataSourceView.state.enabled
						}
					>
						<Icon icon={IconNames.TICK} intent={Intent.SUCCESS} />
					</WrapInTooltip>
				) : (
					<WrapInTooltip
						tooltip={
							STRINGS.DATA_SOURCES.dataSourceView.state.disabled
						}
					>
						<Icon icon={IconNames.DISABLE} intent={Intent.DANGER} />
					</WrapInTooltip>
				)}
			</React.Fragment>
		);
	};

	const getDisplayData = (data: Array<any>): Array<any> => {
		const displayData =
			data && data.length > 0
				? data.map((item) => {
						return {
							source_name: item.name,
							source_type: item.type,
							source_hostname: item.hostname,
							source_info: <DataSourceStatus dataSource={item} />,
							source_ingestion: getIngestionInfo(item),
							source_enabled: getEnabledState(item),
							more: (
								<div
									onClick={(e) => {
										e.stopPropagation();
									}}
								>
									<Popover2
										position={Position.BOTTOM_RIGHT}
										interactionKind={
											Popover2InteractionKind.CLICK
										}
										content={
											<Menu>
												{getMoreMenuItems(
													item.id,
													item.name,
													item.hostname
												)}
											</Menu>
										}
									>
										<Button
											aria-label="data-source-more-button"
											icon={IconNames.MORE}
											minimal
											className="data_source-action-icon"
											disabled={false}
										/>
									</Popover2>
								</div>
							),
						};
				  })
				: [];
		return displayData;
	};

	return (
		<React.Fragment>
			<div aria-label="data_sources list">
				<Table
					id={tableId}
					columns={getColumns()}
					data={getDisplayData(data)}
					sortBy={[{ id: 'data_source' }]}
					enablePagination={false}
					bordered={true}
				/>
			</div>
		</React.Fragment>
	);
};

export { DataSourceView };
