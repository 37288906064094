/** This module contains the component for creating the list of third party authentication profiles.
 *  @module
 */
import { Button, ButtonGroup, Card, Intent, Menu, MenuItem, Position, Spinner, Switch } from "@blueprintjs/core";
import { Popover2, Popover2InteractionKind } from "@blueprintjs/popover2";
import { Icon, IconNames, Table, TableColumnDef, AutocompleteColumnFilterControl } from "@tir-ui/react-components";
import { STRINGS } from "app-strings";
import { BasicDialog, updateDialogState } from "components/common/basic-dialog/BasicDialog";
import { WrapInTooltip } from "components/common/wrap-in-tooltip/WrapInTooltip";
import { TIME_FORMAT } from "components/enums";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { AuthenticationMethods, getAuthMethod } from "pages/integrations/modals/BasicAuthDetailsPanel";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { formatUnixToLocalTimestamp } from "reporting-infrastructure/utils/formatters";
import { ElapsedTimeFormatter } from "reporting-infrastructure/utils/formatters/elapsed-time-formatter/elapsed-time-formatter";
import { runbookService } from "utils/runbooks/RunbookUtils";
import { ProfileInterface } from "utils/services/ThirdPartyIntegrationApiService";
import { ThirdPartyIntegrationService } from 'utils/services/ThirdPartyIntegrationApiService';
import { AddAuthenticationModal } from "../../modals/AddAuthenticationModal"
import { Variant } from "components/common/graph/types/GraphTypes";
import { PARAM_NAME } from "components/enums/QueryParams";
import { useQueryParams } from "utils/hooks";
import { clearQueryParam } from "utils/hooks/useQueryParams";
import './ThirdPartyAuthenticationListView.scss';

/** Renders the profiles list view.
*  @param props the properties passed into the component.
*  @returns JSX with the profiles list view component.*/
export function ThirdPartyAuthenticationListView(props): JSX.Element {
    let { params } = useQueryParams({ listenOnlyTo: [PARAM_NAME.createNew] });
    const createNew: boolean = Boolean(params[PARAM_NAME.createNew]);

    const [loadingProfiles, setLoadingProfiles] = useState(true);
    const [loadingRunbooks, setLoadingRunbooks] = useState(true);
    const [error, setError] = useState(null);
    const [profiles, setProfiles] = useState<ProfileInterface[]>();
    const [runbooks, setRunbooks] = useState<any[]>();
    const [dialogState, setDialogState] = useState({ showDialog: false, title: "My Dialog", loading: false, dialogContent: <></>, dialogFooter: <></> });
    const [dialogClassName, setDialogClassName] = useState('');
    const currentPageIndex = useRef<number>(0);

    useEffect(
        () => {
            if (createNew) {
                handleAuthProfileOpen();
                clearQueryParam(PARAM_NAME.createNew);
            }
        },
        [createNew]
    )


    // Fetch the existing profiles
    useEffect(() => {
        fetchRunbooks();
        refreshProfiles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchRunbooks = () => {
        setLoadingRunbooks(true);
        runbookService.getRunbooks(Variant.INCIDENT).then(
            (result) => {
                setRunbooks(result)
            },
            (error) => {
                setError(error);
            }
        ).finally(
            () => {
                setLoadingRunbooks(false);
            }
        );
    }

    const refreshProfiles = () => {
        setLoadingProfiles(true);
        ThirdPartyIntegrationService.getAuthProfiles().then(
            (result) => {
                setProfiles(result);
            },
            (error) => {
                if (error?.response?.status !== 404) {
                    handleErrors(error);
                } else {
                    setProfiles([]);
                }
            }
        ).finally(
            () => {
                setLoadingProfiles(false);
                const pageInput: HTMLInputElement | null = document.querySelector(".tir-ui-pagination .bp3-input");
                if (pageInput && currentPageIndex.current > 0) {
                    const nativeInputValueSetter = Object?.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")?.set;
                    if (nativeInputValueSetter) {
                        nativeInputValueSetter.call(pageInput, currentPageIndex.current+1);
                        pageInput.dispatchEvent(new Event('input', { bubbles: true}));
                    }
                }
            }
        )
    }

    const getMoreMenuItems = useCallback((row) => {
        const moreMenuItems: Array<JSX.Element> = [];
        moreMenuItems.push(
            <MenuItem disabled={false} text={STRINGS.thirdPartyIntegrations.more.edit} active={false} key={"edit"}
                onClick={() => {
                    handleAuthProfileEdit(row.id);
                }}
            />
        );
        moreMenuItems.push(
            <MenuItem disabled={!row.runbooks?.length} text={STRINGS.thirdPartyIntegrations.more.referencingRunbooks} active={false} key={"viewReferencingRunbooks"}
                onClick={() => {
                    showRunbooks(row);
                }}
            />
        );
        moreMenuItems.push(
            <MenuItem disabled={false} text={STRINGS.thirdPartyIntegrations.more.delete} active={false} key={"delete"}
                onClick={() => {
                    handleDelete(row);
                }}
            />
        );
        return moreMenuItems;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns: Array<TableColumnDef> = [
        {
            id: "name",
            Header: STRINGS.thirdPartyIntegrations.columns.name,
            accessor: "name",
            sortable: true,
            showFilter: true,
            style: { maxWidth: "350px", overflow: "hidden" },
            Cell: row => <WrapInTooltip tooltip={row.value}><span>{row.value}</span></WrapInTooltip>
        },
        {
            id: "description",
            Header: STRINGS.thirdPartyIntegrations.columns.description,
            accessor: "description",
            style: { maxWidth: "350px", overflow: "hidden" },
            headerClassName: "text-nowrap d-none d-xl-table-cell",
            className: "d-none d-xl-table-cell",
            Cell: row => <WrapInTooltip tooltip={row.value}><span className="d-inline-block text-jusitfy">{row.value}</span></WrapInTooltip>
        },
        {
            id: "method",
            Header: STRINGS.thirdPartyIntegrations.columns.method,
            className: "display-9",
            formatter: row => {
                return <span className="d-inline-block text-jusitfy">{AuthenticationMethods[row.authenticationMethod === 5 ? getAuthMethod(4) : getAuthMethod(row.authenticationMethod)]}</span>
            },
        },
        {
            id: "createdBy",
            Header: STRINGS.thirdPartyIntegrations.columns.createdBy,
            accessor: "createdBy",
            headerClassName: "text-nowrap d-none d-xl-table-cell",
            className: "display-9 d-none d-xl-table-cell",
            showFilter: true,
            filterControl: AutocompleteColumnFilterControl,
        },
        {
            id: "createdOn",
            Header: STRINGS.thirdPartyIntegrations.columns.createdOn,
            accessor: "createdOn",
            headerClassName: "text-nowrap d-none d-xl-table-cell",
            className: "display-9 d-none d-xl-table-cell",
            formatter: row => (row.createdOn ? <ElapsedTimeFormatter time={row.createdOn} showOriginal suffix={STRINGS.thirdPartyIntegrations.elapsedSuffix} /> : ""),
        },
        {
            id: "lastUpdatedBy",
            Header: STRINGS.thirdPartyIntegrations.columns.lastUpdateBy,
            accessor: "lastUpdatedBy",
            headerClassName: "text-nowrap d-none d-xl-table-cell",
            className: "display-9 d-none d-xl-table-cell",
        },
        {
            id: "lastUpdatedOn",
            Header: STRINGS.thirdPartyIntegrations.columns.lastUpdateTime,
            accessor: "lastUpdatedOn",
            headerClassName: "text-nowrap d-none d-xl-table-cell",
            className: "display-9 d-none d-xl-table-cell",
            formatter: row => (row.lastUpdatedOn ? <ElapsedTimeFormatter time={row.lastUpdatedOn} showOriginal suffix={STRINGS.thirdPartyIntegrations.elapsedSuffix} /> : ""),
        },
        {
            id: "isEnabled",
            Header: STRINGS.thirdPartyIntegrations.columns.enabled,
            accessor: "isEnabled",
            formatter: row => {
                return <div onClick={(e) => { e.stopPropagation(); }}>
                    <Switch checked={row.isEnabled} disabled={row.errors || !row.runbooks}
                        className="mb-0"
                        onChange={() => row.isEnabled ? handleDelete(row, true) : toggleEnabled(row.id, row.eTag)}
                    />
                </div>;
            }
        },
        {
            id: "verified",
            Header: STRINGS.thirdPartyIntegrations.columns.verified,
            accessor: "verified",
            formatter: row => (row.isVerified !== undefined && row.isVerified !== null ?
                (row.isVerified ?
                    <Icon aria-label="profile-verified-icon" icon={IconNames.TICK} intent={Intent.SUCCESS} /> :
                    <Icon aria-label="profile-verified-icon" icon={IconNames.DELETE} intent={Intent.DANGER} />) :
                <Icon aria-label="profile-verified-icon" icon={IconNames.HELP} />),
        },
        {
            id: "references",
            Header: STRINGS.thirdPartyIntegrations.columns.references,
            accessor: "references",
            formatter: row => (row.runbooks ? row.runbooks.length : <Spinner size={12} />)
        },
        {
            id: "menu",
            Header: '',
            accessor: "menu",
            formatter: row => {
                return <div onClick={(e) => { e.stopPropagation(); }}>
                    <Popover2 position={Position.BOTTOM_LEFT}
                        interactionKind={Popover2InteractionKind.CLICK}
                        content={
                            <Menu>{getMoreMenuItems(row)}</Menu>
                        } >
                        <Button aria-label="profile-more-button" icon={IconNames.MORE} minimal className="runbook-action-icon"
                            disabled={false} onClick={(e) => { }}
                        />
                    </Popover2>
                </div>;
            }
        }
    ];

    const toggleEnabled = (id: string, etag: string) => {
        ThirdPartyIntegrationService.disableAuthProfile(id, etag).then(
            _ => {
                refreshProfiles();
            },
            (error) => {
                handleErrors(error);
            }
        );
    }

    const handleDelete = (profile: any, disableProfile?: boolean) => {
        const newDialogState = Object.assign({}, dialogState);
        let dialogStrings = STRINGS.thirdPartyIntegrations.deleteAuthProfile.delete;
        if (disableProfile) {
            dialogStrings = STRINGS.thirdPartyIntegrations.deleteAuthProfile.disable;
        }
        newDialogState.showDialog = true;
        newDialogState.title = dialogStrings.title;
        newDialogState.dialogContent = <>
            {profile.runbooks?.length ? STRINGS.formatString(dialogStrings.dialog, profile.runbooks.length) : dialogStrings.dialogNoRunbooks}
        </>;
        newDialogState.dialogFooter = <>
            {profile.runbooks?.length ? <Button outlined={true}
                text={STRINGS.thirdPartyIntegrations.deleteAuthProfile.buttons.referencingRunbooks}
                onClick={async (evt) => {
                    showRunbooks(profile);
                }}
            /> : ''}
            <Button outlined={true}
                text={dialogStrings.action}
                onClick={async (evt) => {
                    const etag = profile.eTag || '';
                    if (!disableProfile) {
                        ThirdPartyIntegrationService.deleteAuthProfile(profile.id, etag).then(
                            _ => {
                                refreshProfiles();
                            },
                            (error) => {
                                handleErrors(error);
                            }
                        );
                    } else {
                        toggleEnabled(profile.id, etag);
                    }
                    setDialogState(updateDialogState(newDialogState, false, false, []));
                }}
            />
            <Button outlined={true}
                text={STRINGS.thirdPartyIntegrations.deleteAuthProfile.buttons.cancel}
                onClick={async (evt) => {
                    setDialogState(updateDialogState(newDialogState, false, false, []));
                }}
            />
        </>;
        setDialogState(newDialogState);
    }

    useEffect(() => {
        if (runbooks && profiles
            && profiles[0] && !profiles[0].runbooks) {
            const httpNodes = runbooks
                .map(runbook => runbook.nodes.map(node => {
                    return { ...node, runbookId: runbook.id, runbookDetails: runbook }
                }))
                .reduce((acc, value) => {
                    return acc.concat(value);
                }, [])
                .filter(node => node.type === 'http');
            const subflowNodes = runbooks
                .map(runbook => runbook.nodes.map(node => {
                    return { ...node, runbookId: runbook.id, runbookDetails: runbook }
                }))
                .reduce((acc, value) => {
                    return acc.concat(value);
                }, [])
                .filter(node => node.type === 'subflow');
            const profilesWithRunbooks = profiles
                .map(profile => {
                    return {
                        ...profile,
                        runbooks: httpNodes
                            .filter(node => node.properties?.syncCall?.request?.authenticationProfileId === profile.id)
                            .filter((node, index, self) => self.findIndex(node2 => node2.runbookId === node.runbookId) === index)
                            .map(node => node.runbookDetails)
                            .concat(subflowNodes.filter(
                                    subflowNode => subflowNode.properties?.in?.find(property => property.outer === profile.id)
                                ).map(subflowNode => subflowNode.runbookDetails))
                    }
                });
            setProfiles(profilesWithRunbooks);
        }
    }, [runbooks, profiles])

    const addAuthProfileModal = useRef();

    const handleAuthProfileEdit = (id: string) => {
        if (addAuthProfileModal.current) {
            // @ts-ignore
            addAuthProfileModal.current.setEdit(true);
            // @ts-ignore
            addAuthProfileModal.current.setProfileId(id);
            // @ts-ignore
            addAuthProfileModal.current.handleOpen();
        }
    }

    const handleAuthProfileOpen = () => {
        if (addAuthProfileModal.current) {
            // @ts-ignore
            addAuthProfileModal.current.setEdit(false);
            // @ts-ignore
            addAuthProfileModal.current.handleOpen();
        }
    }

    const handleErrors = (error) => {
        switch (error.response?.status) {
            case 500:
                setError(error);
                break;
            default:
                const errorDetail = error?.response?.data?.details?.[0]?.message ? error.response.data.details[0].message : "";
                const newDialogState = Object.assign({}, dialogState);
                newDialogState.showDialog = true;
                newDialogState.title = STRINGS.ERRORS.defaultTitle + '!';
                newDialogState.dialogContent = <>
                    <div className="third-party-authentication-error-modal">{STRINGS.thirdPartyIntegrations.errors[error.code] ? STRINGS.thirdPartyIntegrations.errors[error.code] : error.message}{errorDetail && ':'}<br/>{errorDetail}</div>
                </>;
                newDialogState.dialogFooter = <>
                    <Button outlined={true}
                        text="Cancel"
                        onClick={async (evt) => {
                            setDialogState(updateDialogState(newDialogState, false, false, []));
                        }}
                    />
                </>;
                setDialogState(newDialogState);
        }
    }

    const runbookRenderer = (runbook) => {
        const runbookCard = <Card className="w-100">
            <h5 className="font-weight-bold">
                <a href={`create-runbook?fid=${runbook.id}&fname=${runbook.name}&variant=${runbook.variant}`} rel="noreferrer" target="_blank">
                    {runbook.name}
                </a>
            </h5>
            <p className="font-italic font-weight-light">
                {runbook.description}
            </p>
            <p className="font-italic font-weight-normal">
                {STRINGS.formatString(STRINGS.thirdPartyIntegrations.referencingRunbooks.runbookUpdate,
                    {
                        user: runbook.lastUpdatedUser,
                        time: formatUnixToLocalTimestamp(runbook.lastUpdatedTime, TIME_FORMAT.DISPLAY_DATE_ONLY_FORMAT)
                    }
                )}
            </p>
        </Card>
        return runbookCard;
    }

    const showRunbooks = (profile) => {
        if (profile.runbooks) {
            const newDialogState = Object.assign({}, dialogState);
            newDialogState.showDialog = true;
            newDialogState.title = STRINGS.formatString(STRINGS.thirdPartyIntegrations.referencingRunbooks.title, profile.name);
            newDialogState.dialogContent = <>
                {profile.runbooks.map((runbook) => runbookRenderer(runbook))}
            </>;
            setDialogState(newDialogState);
            setDialogClassName('referencing-runbooks-dialog');
        }
    }


    return (<div className="authentication-profiles">
        <BasicDialog portalClassName='error-dialog' className={dialogClassName} dialogState={dialogState} onClose={() => {
            setDialogState(updateDialogState(dialogState, false, false, []));
            setDialogClassName('');
        }} />
        <DataLoadFacade loading={!loadingProfiles && !loadingRunbooks ? false : true} error={error} data={profiles} showContentsWhenLoading={true}>
            <>
                <ButtonGroup>
                    <Button aria-label="profile-new-button" icon={IconNames.ADD}
                        onClick={() => handleAuthProfileOpen()}
                        text={STRINGS.thirdPartyIntegrations.add} />
                </ButtonGroup>
                <Table
                    id="authenticationProfileList"
                    className="display-9 mt-4"
                    columns={columns}
                    columnDefinitionDefaults={{
                        headerClassName: "text-nowrap"
                    }}
                    data={profiles ? profiles : []}
                    defaultPageSize={10}
                    enableSelection={false}
                    selectOnRowClick={false}
                    onPageIndexChange={(pageIndex) => {
                        currentPageIndex.current = pageIndex;
                    }}
                />
            </>
        </DataLoadFacade>
        <AddAuthenticationModal
            title="authentication-modal"
            ref={addAuthProfileModal}
            refreshData={refreshProfiles}
            profileNameList={profiles ? profiles?.map(p => p.name) : []}
            handleErrors={handleErrors}
        />
    </div>)
}
